import React, { useEffect, useState, useCallback, useContext, useImperativeHandle, forwardRef, useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import moment from 'moment';
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Redirect } from "react-router";
import { Link, useHistory } from "react-router-dom";

import { Paper, Typography } from "@material-ui/core";
import Skeleton from 'react-loading-skeleton';
import { Aborter } from '@azure/storage-blob';
import CustomTable from "../../widgets/table/custom-table";
import getCompartmentById from "../../Utils/compartments/getCompartmentById";
import GET_WORKFLOW_BY_ID, { EXECUTE_BULK_WORKFLOW, DELETE_ITEM_BY_ID } from '../../queries/workflow-queries';
import {
  SearchModalActions,
  SearchActions,
  SearchTypes,
  ErrorTypes,
  SearchTable,
  TableOverlayMessage,
  RootTypes,
  up,
  low,
  DateFormat,
  Roles,
  ModalOverlayMessage,
  userSwitchOptions,
  userSwitchValues,
  azureSearchDefaultData,
  membershipLength,
  couponsAvailable,
  SubmitOffersetOptions,
  FieldTypes,
  ExportTypes
} from "../../constants/Constants";
import { SearchErrors } from "../../constants/Errors";
import getCompartmentTypes from "../../Utils/compartments/getCompartmentTypes";
import getCompartmentsByIds from "../../Utils/compartments/getCompartmentsByIds";

import getStepLabel from '../../Utils/workflow/get-step-label';
import { checkStepAccess } from '../../Utils/workflow/check-user-access';
import CustomButton from "../../widgets/buttons/CustomButton/CustomButton";
import useStyles from "./search.styles";
import stringCompare from '../../Utils/stringCompare';
import { filterRowData, sortRowData } from '../../Utils/search/dataManipulation';
import ErrorComponent from '../../widgets/error/ErrorComponent';
import SearchService from '../../setup/search/service.all';
import getSearchFieldMap from '../../Utils/smart-search/getSearchFieldMap';
import getSearchFacets from '../../Utils/smart-search/getSearchFacets';
import getSearchActions from './getSearchActions';
import getSearchDateActions, { DATE_ACTIONS, DATE_FORMAT, DATE_ACTIONS_PROPS } from './getSearchDateActions';
import UserComptContext from '../../setup/compartments/compartment.context';
import SearchModal from './SearchModal/SearchModal';
import promotionPDFService from '../../services/promotions/promotionPDFService';
import bulkPromotionPDFService from '../../services/promotions/bulkPromotionPDFService';
import updateUsersRoleService from '../../services/admin/UpdateUsersRoleService';
import updateRolesService from '../../services/admin/UpdateRolesService';
import assignCouponService from '../../services/admin/AssignCouponService';
import bulkAssignCouponMemberlistUploadService from '../../services/admin/BulkAssignCouponMemberlistUploadService';
import overrideLimitService from '../../services/admin/OverrideLimitService';
import generateCouponService from '../../services/admin/GenerateCouponService';
import submitOffersetsService from '../../services/campaign/submitOffersetsService';
import cancelOffersetsService from "../../services/campaign/cancelOffersetsService";
import downloadURI from '../../Utils/downloadURI';
import SearchTotal from '../../widgets/text/SearchTotal';
import { createSearchWorkflowPath } from '../../Utils/navigation/createPath';
import useLoadingService from '../../hooks/useLoadingService';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import { useBrandingServiceContext } from '../../containers/branding/BrandingProvider';
import getDeepValue from '../../Utils/getDeepValue';
import BackgroundProcessingBar from "../../widgets/bars/BackgroundProcessingBar";
import SelectButton from "../../widgets/buttons/SelectButton/SelectButton";
import checkBiglistValuesService from '../../services/biglist/CheckBiglistValuesService';
import ConfirmationDialog from '../../widgets/Confirmation/ConfirmationDialog';
import { useFileTracker } from '../../containers/biglist/FileTracker/FileTracker';
import sleep from '../../Utils/sleep';
import dateFormat from '../../Utils/date/dateFormat';
import {
  assignCoupn, bulkAssignCouponAction,
  bulkAssignCouponMemberlistUploadAction,
  editLimitAction,
  generateCouponAction
} from './couponActions';
import { checkNumberOnly } from '../../Utils/helpers';
import { getBulkWorkflowRejectionCommentsProps, reject, showDeleteDialog, handleOnErrors, deleteItemFn, submitOffersetsFn, showSubmitOffersetsDialog } from '../../Utils/common';
import { exportOffers } from '../../Utils/export/exportOffers';
import {baseURL} from "../../constants/Endpoints";
import bulkAssignCouponService from "../../services/admin/BulkAssignCouponService";
import canAccessPath from "../../Utils/compartments/canAccessPath";
import { duplicatePromotionFileUpload, duplicatePromotionValidations } from "./duplicatePromotionUtils";

let aborter = new AbortController();

function SearchFlexible(props, ref) {
  const { parentSelected, parentUnselected, parentBiglistName, parentError, subSearchType, isModal, parentSelectedFullValue, handleFlag, handleDisable,fromViewOnly, searchActionCompartments } = props;

  const searchType = props && props.searchType ? props.searchType : props.rootType;
  const selectionParents = props && props.queries ? getDeepValue(props.queries, ['selectionParents']) : null;
  const selectOnlyOne = props && props.queries ? getDeepValue(props.queries, ['selectOnlyOne']) : null;
  const defaultParentChildSelected = {};
  const selectOnlyOneList = [];
  if(selectOnlyOne){
    selectOnlyOne.split(",").forEach(label=>selectOnlyOneList.push(label))
  }
  if (selectionParents) {
    selectionParents.split("~").forEach(labelPair => {
      const [parentLabels, childLabels] = labelPair.split(":");
      parentLabels.split(",").forEach(parentLabel => {
        if (!defaultParentChildSelected[parentLabel]) {
          defaultParentChildSelected[parentLabel] = [];
        }
        if (childLabels) {
          childLabels.split(",").forEach(childLabel => {
            if (defaultParentChildSelected[parentLabel].indexOf(childLabel) === -1) {
              defaultParentChildSelected[parentLabel].push(childLabel);
            }
          });
        }
      });
    });
  }

  const {
    watchDownload,
    prepareDownload,
    downloadFile,
  } = useFileTracker();

  const selectKey = useMemo(() => {
    if (subSearchType) {
      return subSearchType;
    }

    switch (up(searchType)) {
      case(SearchTypes.ITEM):
        return 'itemNumber';
      case(SearchTypes.CLUB):
        return 'storeNumber';
      case(SearchTypes.USERS):
        return 'userId';
      case(SearchTypes.COUPON):
      case(SearchTypes.COUPONGROUPS):
        return 'couponGroupName';
      case (SearchTypes.ACTION):
        return 'actionCode';
      case (SearchTypes.COUPONCODE):
        return 'code';
      case (SearchTypes.TERMSANDCONDITIONS):
        return 'termsAndConditions_name';
      case (SearchTypes.OFFERSET):
        return 'offerset_number';
      case (SearchTypes.GENERICTARGETEDMESSAGE):
        return 'slot_id';
      case SearchTypes.GENERICTARGETEDMESSAGEBYSLOT:
        return 'slot_id';
      default:
        return 'id';
    }

  }, [searchType, subSearchType]);

  const {
    user,
    simulatedUser,
    simulatingUser,
    compartments: allCompartments,
    simulateUserId,
  } = useContext(UserComptContext);

  const currentCompartment = getCompartmentById(allCompartments, props.currentCompt);

  const history = useHistory();
  // We can only use react router location data if we are root level
  // otherwise this will break search screens opened in a modal
  // we know we are root level if we have a currentCompartment
  const hLocation = currentCompartment ? getDeepValue(history, ['location']) : null;
  const previousFilter = hLocation && hLocation.state && hLocation.state.filter;
  const queryParams = hLocation && hLocation.search
  const pathFilter = (queryParams) ? queryParams : null
  //hotfix for cloned offers
  const ifNotCloned = hLocation && hLocation.pathname && hLocation.pathname.split("/").indexOf("clone") === -1
  let queryFilter;
  if (pathFilter && ifNotCloned && [SearchTypes.GENERICTARGETEDMESSAGEBYSLOT, SearchTypes.GENERICTARGETEDMESSAGE].includes(up(searchType))) {
    //eslint-disable-next-line prefer-const
    let [searchKey, id] = pathFilter != null && pathFilter.split("=");
    searchKey = searchKey.split("=")[0].replace("?", "")
    queryFilter = {
      [searchKey]: {
        selected: [id],
        data: {
          type: FieldTypes.STRING
        }
      }
    }
  }
  const previousSwitchValue = hLocation && hLocation.state && hLocation.state.switchValue;

  const queryUser = simulatingUser ? simulatedUser : user;
  let userAccess = {};
  let userRole = "";
  let userOrg = "";
  if (!isEmpty(queryUser)) {
    const workflowSteps = queryUser.workflows.filter(workflow => workflow.name === searchType);
    userAccess = workflowSteps[0] ? workflowSteps[0] : {}
    userRole = queryUser.role? queryUser.role.name : userRole;
    userOrg = userRole === "ROLE_SUPPLIER"? queryUser.org? queryUser.org.name : "" : "";
  }

  const { getAllBranding } = useBrandingServiceContext();
  const { newSnackbar } = useCustomSnackbar();

  const [enableSearchMultiSelect, setEnableSearchMultiSelect] = useState(props.singleSelect || props.multiSelect);

  const [searchModal, setSearchModal] = React.useState({
    open: false,
    action: '',
    items: [],
    bulk: true,
    params: {}
  })



  const [callPromotionPDFService]= useLoadingService(promotionPDFService);
  const [callBulkPromotionPDFService]= useLoadingService(bulkPromotionPDFService, { appLoadingId: 'bulkPromotionPDFService' });
  const [callExportOffersService] = useLoadingService(exportOffers);
  const [callUpdateUsersRoleService] = useLoadingService(updateUsersRoleService);
  const [callUpdateRolesService] = useLoadingService(updateRolesService);
  const [callAssignCouponService,{ loading: loadingAssignment }] = useLoadingService(assignCouponService);
  const [callBulkAssignCouponMemberlistUploadService,{ loading: loadingBulkAssignmentMemberlistUpload }] = useLoadingService(bulkAssignCouponMemberlistUploadService);
  const [callBulkAssignCouponService,{ loading: loadingBulkAssignment }] = useLoadingService(bulkAssignCouponService);
  const [callOverrideLimitService,{ loading: loadingLimitOverride }] = useLoadingService(overrideLimitService);
  const [callgenerateCouponService,{ loading: loadingGenerateCoupons }] = useLoadingService(generateCouponService);
  // const [callSearchOffersByItemService] = useLoadingService(SearchOffersByItemService);
  const [state, setState] = React.useState({
    rowData: [],
    actions: "",
    actionsMultiSelect: "",
    selectAll: false,
    unselectAll: false,
    selectAllActive: true,
    values: [],
    bulkActions: [],
    fieldMap: {},
    multiSelectCheckbox: props.singleSelect ? getSearchActions({ singleSelect: true, funcSelRow: getSelectedRow }) : props.multiSelect ? getSearchActions({ multiSelect: true, funcSelRow: getSelectedRow }) : [],
    currentComptId: props.currentCompt,
    shouldRoute: false,
    navigate: null,
    offset: null,
    selectedRowsarray: parentSelectedFullValue ? parentSelectedFullValue.map(item => !item[selectKey] ? { ...item, [selectKey]: item.id } : item) : (parentSelected ? parentSelected.map(item => ({ [selectKey]: item })) : []),
    unselectedRowsarray: parentUnselected ? parentUnselected.map(item => ({ [selectKey]: item })) : [],
    compartmentType: "",
    filter: previousFilter || queryFilter|| null,
    sort: props.parentSort ? props.parentSort : props.specificFieldSearch ? { property: props.specificFieldSearch, orderBy: 'asc', type: 'STRING' } : null,
    shownData: [],
    globalDate: {},
    dateErrorArray: null,
    loading: false,
    workflowData: null,
    workflowActions: null,
    total: 0
    // ignoredFieldKeys: []
  });


  const [bulkWorkflowDialog, setBulkWorkflowDialog] = React.useState({ open: false });

  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalInputValue, setModalInputValue] = useState("");

  const [fileName, setFileName] = useState('');

  const totalSelected = state.selectAll ? (props.skipService ? state.selectedRowsarray.length : state.total - state.unselectedRowsarray.length) : state.selectedRowsarray.length


  const [noMatch, setNoMatch] = useState([]);

  const [actionWidth,setActionWidth] = useState(props.actionWidth)
  const [showBulkCheckbox,setShowBulkCheckbox] = useState(false)

  const [switchProps, setSwitchProps] = useState(setPropsForSwitch())

  const [showFilter, setShowFilter] = useState((state.filter && !state.filter.searchAll && !pathFilter) || props.showAdvancedInitial);

  const { workflowId, searchActionsItems, searchParams } = useMemo(() => {
    let searchParams = props.searchParams;
    if (currentCompartment) {
      const childCompartment = currentCompartment; // Must be current compartment, only used child because drilling was done wrong
      const templateId = childCompartment && childCompartment.children ? childCompartment.children[0] : null;
      const searchCompt = childCompartment && childCompartment.children ? getCompartmentById(allCompartments, childCompartment.children[0]) : null;
      const searchComptParams = searchCompt && searchCompt.params ? searchCompt.params : null;
      const workflowId = searchCompt ? searchCompt.workflowId : null;
      const searchActionsItems = searchCompt && searchCompt.children ? getCompartmentsByIds(allCompartments, searchCompt.children) : null;

      if (searchComptParams) {
        searchParams = { ...searchParams, ...searchComptParams };
      }

      return { templateId, searchCompt, workflowId, searchActionsItems, searchParams };
    }
    if (searchActionCompartments) {
      const searchActionsItems = getCompartmentsByIds(allCompartments, searchActionCompartments);
      if (searchActionsItems.length > 0) {
        return { searchActionsItems, searchParams };
      }
    }
    return { searchParams };
  }, [currentCompartment, allCompartments, searchActionCompartments]);

  useImperativeHandle(ref, () => ({
    async getSelected() {
      const selectedRows = await getAllSelectedRows();
      const error = false;
      let noMatchList = [];
      const { specificFieldSearch } = props;
      if (specificFieldSearch) {
        const { unselectedRowsarray } = state;
        const search = getDeepValue(state, ['filter', specificFieldSearch, 'search']);
        if (search) {
          const searchList = search.trim().split(/(?:, )|,| /g);
          const tempItems = [...selectedRows];
          const cleanSearchList = [...new Set(searchList)].filter(i => !!i);
          noMatchList = cleanSearchList.reduce((acc, val) => {
            const unselected = unselectedRowsarray.findIndex(item => item[specificFieldSearch] === val.trim());
            if (unselected >= 0) {
              return acc;
            }
            const searchIndex = tempItems.findIndex(item => item[specificFieldSearch] === val.trim());
            if (searchIndex > -1 && val && val.trim()) {
              tempItems.splice(searchIndex, 1);
              return acc;
            }
            // error = true;
            return [...acc, val];
          }, []);
        }
      }
      if (noMatchList && noMatchList.length > 0) {
        newSnackbar({
          title: SearchErrors.KEY_NOT_FOUND(searchType),
          description: noMatchList.join(', '),
          persist: true,
          variant: 'error'
        })
      }


      const hasError = !!selectedRows.find(row => row.error);

      let selectedRowsarray = selectedRows || [];
      let unselectedRowsarray = state.unselectedRowsarray || [];

      if (selectKey !== 'id') {
        selectedRowsarray = selectedRowsarray.map(item => ({ ...item, originalId: item.id, id: item[selectKey] }));
        unselectedRowsarray = unselectedRowsarray.map(item => ({ ...item, originalId: item.id, id: item[selectKey] }));
      }

      return {
        selectedRowsarray,
        unselectedRowsarray,
        unselectAll: state.unselectAll,
        globalDate: state.globalDate,
        dateErrorArray: state.dateErrorArray,
        error: error || hasError
      }
    }
  }));

  useEffect(() => {
    const bulkProps = getDeepValue(state.bulkActions, ['0', 'props', 'items']);
    const hasBulkAction = getDeepValue(bulkProps, ['0', 'actions', '0']);
    const hasBulkWorkflow = getDeepValue(bulkProps, ['1', 'workflow', 'workflowId']);
    const hasBulk = getDeepValue(bulkProps, ['2', 'bulk', '0']);
    if (hasBulk || hasBulkAction || hasBulkWorkflow) {
      setEnableSearchMultiSelect(true);
    }
  }, [state.bulkActions]);

  useEffect(() => {
    const aWidth = getDeepValue(currentCompartment, ['params', 'actionWidth']);
    if (aWidth) {
      if (isNaN(aWidth) === false) {
        setActionWidth(Number(aWidth));
      }
    }
    const bulkCheckbox = getDeepValue(currentCompartment, ['params', 'showBulkCheckbox'])
    if (bulkCheckbox) {
      if (bulkCheckbox === 'false' || bulkCheckbox === 'False' || bulkCheckbox === false) {
        setShowBulkCheckbox(false);
      }
      if (bulkCheckbox === 'true' || bulkCheckbox === 'True' || bulkCheckbox === true) {
        setShowBulkCheckbox(true);
      }
    }
  }, [currentCompartment])

  useEffect(() => {
    setLoading(prevState=>!prevState);
  }, [loadingAssignment, loadingLimitOverride, loadingGenerateCoupons]);


  // function to check if all_Offers switch to show or not
  function showAllOffersSwitch() {
    return searchType === low(SearchTypes.PROMOTION) && searchParams && queryUser && queryUser.role && queryUser.role.name.includes(searchParams['showSwitch']);
  }

  // function which builds default filter for merchant offers switch
  function buildDefaultFilterForSwitch(fromRefresh) {
    if(showAllOffersSwitch()) {
      const filter = {}
      const indexFields = getSearchFieldMap(null, null, { user: queryUser }, searchType)
      const filterKeys = searchParams && searchParams.filterKeys
      filterKeys.split(',').forEach(key => {
        filter[key] = {
          data: indexFields[key],
          search: queryUser && queryUser.userId
        }
      })
      return filter;
    }
    return fromRefresh ? null : state.filter;
  }

  // function which clears default filter when merchant switches back to all offers
  function clearDefaultFilterForSwitch() {
    setState(state => ({ ...state, filter: null, facets: null }));
  }

  // onChange event for merchant offers switch
  function defaultFilterSwitchOnChange(event) {
    const value = event.target.value;
    refresh();
    if(value === userSwitchValues.ALL_OFFERS) {
      clearDefaultFilterForSwitch()
    } else {
      const filter = buildDefaultFilterForSwitch()
      setState(state => ({ ...state, filter, facets: null }));
    }

    setSwitchProps(switchProps => ({
      ...switchProps,
      value: value
    }))
    setShowFilter(false);
  }

  // build options for all offers radio button
  function setPropsForSwitch() {
    const props = {}
    props.metadata = {
      values: userSwitchOptions,
      queries: {
        radioType: "column"
      }
    }
    props.value = previousSwitchValue || userSwitchValues.MY_OFFERS
    props.onChange = defaultFilterSwitchOnChange
    return props;
  }

  // advanced filter component should be closed when merchant dont apply any filter
  function getShowFilter() {
    if(searchParams && searchParams.filterKeys && state.filter) {
      if(searchParams.filterKeys.split(',').length === Object.keys(state.filter).length) {
        return false;
      }
    }
    return showFilter;
  }

  const callClone = (id, params) => {
    //setState(state => onViewButtonClick(state, id, true, params));
  };

  const getRowKey = (key, params, view, clone) => (id, row) => {
    let realId;
    if (row && row[key]) {
      realId = row[key]
    } else {
      realId = id
    }
    setState(state => onViewButtonClick(state, realId, view, clone, row, params));
  }

  const openModal = (key, action, params) => (id, row) => {
    openSearchModal(action, params, [row], false);
  }

  const updateRoles = (rows, { value, name }) => {
    let dialogProps;
    const rowsToBeUpdated = rows.filter((user) => {
      if (Roles.SUPPLIER.indexOf(up(name)) > -1) {
        if (user.userOrgs && user.userOrgs.length === 0) {
          dialogProps = {
            title: ModalOverlayMessage.MISSING_SUPPLIER_INFO_ERROR(ErrorTypes.NO_ORG).title,
            text: ModalOverlayMessage.MISSING_SUPPLIER_INFO_ERROR(ErrorTypes.NO_ORG).text
          }
          return false;
        }
      }
      return true;
    })
    if (dialogProps) {
      setBulkWorkflowDialog({
        ...dialogProps,
        open: true,
        actions: [
          {
            onClick: () => setBulkWorkflowDialog({ open: false }),
            label: 'ok',
            color: 'secondary',
            variant: 'contained'
          }
        ]
      });
    }
    if (rowsToBeUpdated.length) {
      callUpdateUsersRoleService(rowsToBeUpdated, value);
    }

  }

  const bulkFunction = (action) => (rows,event) => {
    if (action && action.params && action.params.mode) {
      switch (action.params.mode) {
        case (SearchActions.EXPORT):
          callExportOffersService(rows, queryUser, setBulkWorkflowDialog, watchDownload);
          break;
        case (SearchActions.ADMIN):
          updateRoles(rows, event.target);
          refresh();
          break;
        case (SearchActions.ENABLE):
          callUpdateRolesService(rows, false).then(refresh);
          break;
        case (SearchActions.DISABLE):
          callUpdateRolesService(rows, true).then(refresh);
          break;
        case (SearchActions.ASSIGN):
          assignCoupon(rows);
          break;
        case (SearchActions.ASSIGN_BULK):
          bulkAssignCoupon(rows);
          break;
        case (SearchActions.BACK_TO_SEARCH):{
          history.push({
            pathname: `/${searchType}/page/redirect`,
          });
          break;
        }
        case(SearchActions.WORKFLOW):
          if (action.params.templateId) {
            openSearchModal(SearchModalActions.TEMPLATE, action.params, rows, true)
          } else {
            bulkWorkflow(rows, action.params);
          }
          break;
      }
    } else {
      // setState(state => onViewButtonClick(state, id, false, false, row));
    }
  }

  useEffect(() => {
    if (props.data) {
      setState(state => {
        return {
          ...state,
          ...props.data,
          shownData: props.data.rowData,
          loading: false,
          actions: (searchType === getCompartmentTypes.BRANDING || searchType === getCompartmentTypes.COUPON || searchActionsItems) && !props.singleSelect && !props.disableActions ? 'actionsForData' : '',
          multiSelectCheckbox: getSearchActions({ multiSelect: true, funcSelRow: getSelectedRow }),
          actionsMultiSelect: 'actionsForMultiSelect',
          values: getSearchActions({ isMetadata: props.isMetadata, rootType: searchType, subSearchType, funcRowId: getRowId, funClone: callClone, funcSelRow: getSelectedRow, funcRowKey: getRowKey, openModal: openModal, workflowActions: state.workflowActions, workflowId: workflowId, searchActions: searchActionsItems, bulkFunction, bulk: false }),
          bulkActions: getSearchActions({ isMetadata: props.isMetadata, rootType: searchType, subSearchType, funcRowId: getRowId, funClone: callClone, funcSelRow: getSelectedRow, funcRowKey: getRowKey, openModal: openModal, workflowActions: state.workflowActions, workflowId: workflowId, searchActions: searchActionsItems, bulkFunction, bulk: true })
        }
      });
    }
  }, [props.data, searchActionsItems]);

  const { data } = useQuery(GET_WORKFLOW_BY_ID, {
    skip: !workflowId,
    onCompleted: handleOnCompletedWorkflow,
    variables: {
      workflowId
    }
  });

  const [executeBulkWorkflow, { loading: loadingBulkExecute }] = useMutation(EXECUTE_BULK_WORKFLOW, {
    onError: handleBulkExecuteError
  });

  function handleBulkExecuteError(data) {
    let errorDesc = 'Something went wrong'
    if(data && data.networkError && data.networkError.result && data.networkError.result.errors && data.networkError.result.errors[0]) {
      errorDesc = data.networkError.result.errors[0].description
    }
    const dialogProps = {
      title: 'Error',
      text: errorDesc,
      open: true,
      actions: [
        {
          onClick: () => setBulkWorkflowDialog({ open: false }),
          label: 'ok',
          color: 'secondary',
          variant: 'contained'
        }
      ]
    }

    setBulkWorkflowDialog(dialogProps);
  }


  function handleOnCompletedWorkflow(data) {
    if (workflowId && data && data.workflow && data.workflow.payload) {
      setState(state => ({ ...state, workflowActions: data.workflow.payload.flow }));
    }
  }

  function handleOnDeleteError(data) {
    handleOnErrors(data, setBulkWorkflowDialog)
  }

  const [
    deleteItem,
    { loading: loadingOnDelete }
  ] = useMutation(DELETE_ITEM_BY_ID, {
    onError: handleOnDeleteError
  });

  const classes = useStyles();
  const [searchData, setSearchData] = useState(null)
  const [indexMap, setIndexMap] = React.useState(null)
  // hook to get index defination
  useEffect(() => {
    if (!props.skipService) {
      fetchIndexDefinition();
    }
  }, [props.currentCompt, searchType, props.skipService])

  // hook to get search data
  useEffect(() => {
    if (!props.skipService && indexMap && !indexMap.error) {
      search(null, state.filter, state.sort);
      if (searchType === low(SearchTypes.PROMOTION)) {
        getAllBranding();
      }
    }
  }, [props.skipService, indexMap, state.sort, state.filter])

  // build search filter
  useEffect(() => {
    if (props.skipService) {
      const rowData = sortRowData(filterRowData(state.rowData, state.filter), state.sort, !props.singleSelect);
      setState(state => ({ ...state, shownData: rowData }));
    }
  }, [state.rowData, state.filter, state.sort]);

  useEffect(() => {
    if(showAllOffersSwitch() && switchProps.value === userSwitchValues.MY_OFFERS) {
      const filter = buildDefaultFilterForSwitch()
      setState(state => ({ ...state, filter: previousFilter || filter || queryFilter}));
      setShowFilter(getShowFilter())
    }
  }, [switchProps.value])

  /* Submit Offersets Logic */
  function handleOnSubmitOffersetsError(data) {
    handleOnErrors(data, setBulkWorkflowDialog)
  }
  const [callSubmitOffersetsService, { loading: loadingSubmitOffersets }] = useLoadingService(submitOffersetsService, { onError: handleOnSubmitOffersetsError });
  const [callCancelOffersetsService, { loading: loadingCancelOffersets }] = useLoadingService(cancelOffersetsService, { onError: handleOnSubmitOffersetsError });
  function submitOffersetsOnDone(row, params) {
    submitOffersetsFn({
      row,
      params,
      onDone: () => setBulkWorkflowDialog({ open: false }),
      setBulkWorkflowDialog,
      submitOffersets: params?.method === SubmitOffersetOptions.CANCEL ? callCancelOffersetsService : callSubmitOffersetsService
    })
  }
  function submitOffersets(row, item) {
    const props = {
      row, item, setBulkWorkflowDialog, submitOffersetsOnDone
    }

    showSubmitOffersetsDialog(props)
  }
  /* End Submit Offersets Logic */


  // for dates
  function remapRows(list, row, eventValue, propName, additionalData = {}) {
    return list.map(rowItem => {
      if (rowItem.storeNumber === row.storeNumber) { // should be id if need to be generic
        return {
          ...rowItem,
          [propName]: eventValue,
          ...additionalData
        }
      }
      return rowItem;
    });
  }

  function setExtraValue(data) {
    setState(state => {
      const { props: dataProps, event, row } = data;
      const eventValue = getDeepValue(event, ['target', 'value']);
      const propName = getDeepValue(dataProps, ['propName']);
      const otherDateActionProps = DATE_ACTIONS.filter(v => v !== propName);
      const { hasOtherValues, hasOtherError } = otherDateActionProps.reduce((obj, otherKey) => {
        const otherVal = getDeepValue(row, [otherKey]);
        return {
          hasOtherValues: !!otherVal || obj.hasOtherValues,
          hasOtherError: (DATE_ACTIONS_PROPS[otherKey].required && !otherVal) || obj.hasOtherError
        };
      }, { hasOtherValues: false, hasOtherError: false });
      const isSelected = state.selectedRowsarray.find(val => row.id === val.id);
      const triggerSelect = hasOtherValues && isSelected ? false : !!eventValue;
      const hasError = hasOtherError || (!eventValue && DATE_ACTIONS_PROPS[propName].required);

      const additionalData = { selected: isSelected || triggerSelect, error: hasError };

      const setSelectedState = triggerSelect ? updatedSelectedRowsList(!!eventValue, row.id, state) : state;
      return {
        ...setSelectedState,
        rowData: remapRows(setSelectedState.rowData, row, eventValue, propName, additionalData),
        shownData: remapRows(setSelectedState.shownData, row, eventValue, propName, additionalData),
        selectedRowsarray: remapRows(setSelectedState.selectedRowsarray, row, eventValue, propName, additionalData)
      }
    });
  }

  useEffect(() => {
    setState((state) => {
      const bulkActions = getSearchActions({ isMetadata: props.isMetadata, rootType: searchType, funcRowId: getRowId, funClone: callClone, funcSelRow: getSelectedRow, funcRowKey: getRowKey, openModal: openModal, workflowActions: state.workflowActions, workflowId: workflowId, searchActions: searchActionsItems, bulkFunction, bulk: true });
      if (state.workflowActions && state.values && userAccess) {
        return {
          ...state,
          values: getSearchActions({ isMetadata: props.isMetadata, rootType: searchType, subSearchType, funcRowId: getRowId, funClone: callClone, funcSelRow: getSelectedRow, funcRowKey: getRowKey, openModal: openModal, workflowActions: state.workflowActions, workflowId: workflowId, searchActions: searchActionsItems, bulkFunction, bulk: false, userAccess, userRole: queryUser, parentCompt: props.currentCompt }),
          bulkActions
        }
      } else {
        return {
          ...state,
          bulkActions
        }
      }
    })
  }, [state.workflowActions])

  async function fetchIndexDefinition() {
    const templateQueries = props.queries;
    const indexFields = getSearchFieldMap(null, null, { user: queryUser, templateQueries }, searchType);
    const arrayFields = Object.keys(indexFields).map(key => ({ name: key, ...indexFields[key] }))

    setIndexMap({ fields: arrayFields });
    setState(state => {
      return {
        ...state,
        fieldMap: indexFields
      };
    });
  }

  // fetch search data
  async function search(offset, filter, sort, next, fromRefresh) {
    if (!offset) {
      setSearchLoading(true);
    }
    if (!next) {
      if(handleFlag){
        await handleFlag(false);
      }
    }
    await fetchSearchData(offset, filter, sort, fromRefresh).then((searchData) => {
      if (searchData && !searchData.error) {
        handleSearchComplete(searchData, indexMap, offset, fromRefresh)
      }
    })
    setSearchLoading(false);
  }
  const closeDialogAction = (label) => {
    return {
      onClick: () => setBulkWorkflowDialog({ open: false }),
      label,
      color: 'secondary'
    }
  }

  function parseBiglistKey(key) {
    return {
      new: key,
      // new: key === 'timezone' ? 'schedule_timezone' : low(key.replace(/(.)([A-Z])/g, '$1_$2')),
      original: key
    };
  }

  function isStringInvalid(val) {
    const invalidStrings = ['null', 'Invalid date'];
    return invalidStrings.includes(val);
  }

  function parseBiglistData(biglistData) {
    return biglistData.map(item => {
      const keys = Object.keys(item).map(parseBiglistKey);
      const newObject = keys.reduce((acc, keyObj) => {
        const value = item[keyObj.original];
        const parsedValue = DATE_FORMAT.includes(keyObj.new) ? dateFormat(value, DateFormat.DATE) : value;
        const validValue = isStringInvalid(parsedValue) ? '' : parsedValue;
        return {
          ...acc,
          [keyObj.new]: validValue
        };
      }, {});
      return newObject;
    });
  }

  async function fetchSearchData(offset, filter, sort, fromRefresh) {
    const additionalFilter = createFilterFromProps();
    const facets = (state.facets && !fromRefresh) || props.searchType === 'item' ? null : getSearchFacets(indexMap);
    const searchOptions = {
      top: SearchTable.LIMIT,
      skip: offset ? offset : 0,
      count: offset ? false : true,
      facets
    }
    const searchData = await SearchService({
      filter,
      sort,
      type: searchType,
      additionalFilter,
      searchOptions,
      aborter,
      clearWithoutFilter: props.clearWithoutFilter,
      user: { role: userRole, org: userOrg, id: queryUser.id },
      queryFromService: props.queries,
      searchParams,
      specificFieldSearch: !!props.specificFieldSearch,
      showAllOffersSwitch,
      switchProps
    });
    if (searchData && searchData.error) {
      setSearchData(searchData);
    } else if (searchData && searchData.value) {
      if (parentBiglistName) {
        try {
          const itemValues = searchData.value.map((item) => {
            return item[selectKey];
          })
          const biglistData = await checkBiglistValuesService(parentBiglistName, itemValues);
          if (biglistData && biglistData.length > 0) {
            const parsedBiglistData = parseBiglistData(biglistData);
            const biglistSearchData = searchDataSelectedFromValues(searchData, biglistData.map(item => item.id), state.unselectedRowsarray.map(item => item[selectKey]), parsedBiglistData);
            if (state.selectedRowsarray.length > 0) {
              return searchDataSelectedFromValues(biglistSearchData, state.selectedRowsarray.map((item) => item[selectKey]), null, parsedBiglistData);
            }
            return biglistSearchData
          } else if (biglistData.error) {
            const newSearchData =  {
              error: 'Error getting values'
            }
            setSearchData(newSearchData);
            return newSearchData;
          } else {
            if (fromViewOnly) {
              setSearchData(azureSearchDefaultData);
              return azureSearchDefaultData;
            }
          }
        } catch (err) {
          setBulkWorkflowDialog({
            open: true,
            title: ModalOverlayMessage.BIGLIST_CHECK_ERROR,
            text: ModalOverlayMessage.BIGLIST_CHECK_MESSAGE(searchType),
            actions: [closeDialogAction('Close')]
          })
        }
      } else if (state.selectedRowsarray.length > 0) {
        return searchDataSelectedFromValues(searchData, state.selectedRowsarray.map((item) => item[selectKey]));
      }
    }
    return searchData;
  }

  function searchDataSelectedFromValues(searchData, values, unselectedValues, biglistData) {
    const value = searchData.value.map((item) => {
      const id = item[selectKey];
      if(unselectedValues && unselectedValues.includes(id)) {
        return item;
      }
      if (values.includes(id)) {
        const extraData = biglistData ? biglistData.find(val => val.id === id) : null;
        return { ...item, ...(extraData || {}), selected: true }
      }
      return fromViewOnly ? null : item;
    })

    if(fromViewOnly) {
      // when item doesnot exist in the biglist, it is captured as null in variable value.
      // That null value is removed from the value with value.filter(e => e)
      const uniqueValues = value.filter(item => item)
      return {
        ...searchData,
        value: uniqueValues,
        '@odata.count': uniqueValues.length
      }
    }

    return {
      ...searchData,
      value: value
    };
  }

  async function fetchAllSearchData() {
    const { filter } = state;
    const key = selectKey === 'id' ? 'created' : selectKey;
    const sort =  {
      property: key,
      orderBy: 'asc'
    }

    const batchSize = 25; // Max 100
    const batches = Math.ceil(state.total / (1000 * batchSize));
    let allResponses = [];
    let lastId;
    for (let i = 0; i < batches; i++) {
      const serviceArray = [];
      const filterQuery = `${lastId ? `${key} gt '${lastId}'` : ''}`;
      const remaining = Math.ceil(state.total / 1000) - (i * batchSize);
      const count = remaining >= batchSize ? batchSize : remaining;
      const additionalFilter = createFilterFromProps();
      const defaultFilter = getDeepValue(props.queries, ['defaultFilter']);
      const filterListConvert = getDeepValue(props.queries, ['filterListConvert']);
      let newFilterQuery = '';
      if (defaultFilter && filterListConvert) {
        newFilterQuery = `(${defaultFilter}) and (${filterListConvert})`;
      } else {
        newFilterQuery = defaultFilter || filterListConvert;
      }
      const newPropQueries = {
        ...props.queries,
        defaultFilter: newFilterQuery
      };
      for (let j = 0; j < count; j++) {
        const searchOptions = {
          skip: j*1000,
          top: 10000
        }
        serviceArray.push(SearchService({
          filter,
          sort,
          type: searchType,
          additionalFilter,
          searchOptions,
          aborter,
          clearWithoutFilter: props.clearWithoutFilter,
          user: {role: userRole, org: userOrg, id: queryUser.id},
          queryFromService: newPropQueries,
          searchParams,
          specificFieldSearch: !!props.specificFieldSearch,
          queryForFilter: filterQuery,
          showAllOffersSwitch,
          switchProps
        })
        )
      }
      const responses = await Promise.all(serviceArray);
      allResponses = allResponses.concat(responses);
      const lastResponse = allResponses && allResponses[allResponses.length - 1]
      const lastValue = lastResponse && lastResponse.value && lastResponse.value[lastResponse.value.length - 1]
      if (lastValue) {
        lastId = lastValue[key]
      }

      await sleep(1000);
    }

    let values = [];
    allResponses.forEach((response) => {
      if (response && response.value) {
        values = values.concat(response.value);
      }
    })

    return values;
  }

  function createFilterFromProps() {
    const { subSearchType, parentFilter } = props;
    let additionalFilter = { ...parentFilter,...queryFilter };
    if (low(searchType) === low(SearchTypes.ITEM)) {
      additionalFilter = { ...additionalFilter, itemStatusCode: { data: { type: "STRING" }, selected: ["A"] } }
    }
    if (low(searchType) === low(SearchTypes.DOWNLOAD)) {
      additionalFilter = {
        ...additionalFilter,
        downloads: { data: { type: "COMPLEX", queryProperty: 'm/userId' }, selected: [queryUser.userId] },
      }
    }
    if (subSearchType) {
      if (stringCompare(searchType, SearchTypes.USERS)) {
        additionalFilter = {
          ...additionalFilter,
          name: {
            search: 'ROLE_' + subSearchType
          }
        }
      }
    }
    return additionalFilter;
  }

  function processSelectedData(items, parentSelectedFullValue) {
    // necessary to pass existing date value to rows made from search service
    if (items && parentSelectedFullValue) {
      return items.map(item => {
        const matchItem = parentSelectedFullValue.find(parentItem =>
          (parentItem.id && item.id && parentItem.id === item.id) ||
          (parentItem[selectKey] && item[selectKey] && parentItem[selectKey] === item[selectKey])
        );
        if (matchItem) {
          return {
            ...item,
            ...matchItem,
            selected: true // item matched selected array
          };
        }
        return item;
      });
    }
    return items;
  }

  function handleSearchComplete(searchData, templateData, prevOffset, fromRefresh) {
    if (searchData.errorMessage) {
      newSnackbar({
        title: searchData.errorMessage,
        variant: 'error'
      })
    }
    if (searchData && templateData) {
      if(handleFlag && searchData.value.length>0){
        handleFlag(true);
      }
      setState(state => {
        const items = processSelectedData(searchData.value, state.selectedRowsarray);
        if (prevOffset) {
          const total = state.total;
          let newItems;
          if (state.selectAll) {
            newItems = state.rowData.concat(items.map((item) => ({ ...item, selected: true })));
          } else {
            newItems = state.rowData.concat(items);
          }
          const newOffset = newItems.length >= total ? null : newItems.length;
          return {
            ...state,
            rowData: newItems,
            shownData: newItems,
            offset: newOffset,
            selectedRowsarray: state.selectAll ? state.selectedRowsarray.concat(newItems) : state.selectedRowsarray,
            loading: false
          };
        } else {
          const total = searchData && searchData['@odata.count'];
          const facets = state.facets && !fromRefresh ? state.facets : searchData && searchData['@search.facets'];
          const newOffset = !items || items.length >= total ? null : items.length;
          const actions = props.disableActions ? '' : "actionsForData";
          const actionsForMultiSelect = "actionsForMultiSelect";
          // get feild map for the header
          const fieldMap = getSearchFieldMap(null, facets, { user: queryUser, templateQueries: props.queries }, searchType);
          // get table actions
          const searchActions = props.dateEnabled
            ? getSearchDateActions({ setExtraValue })
            : getSearchActions({ isMetadata: props.isMetadata, rootType: searchType, subSearchType, funcRowId: getRowId, funClone: callClone, funcSelRow: getSelectedRow, funcRowKey: getRowKey, openModal: openModal, workflowActions: state.workflowActions, workflowId: workflowId, searchActions: searchActionsItems, bulkFunction, bulk: false, userAccess, userRole: queryUser, parentCompt: props.currentCompt });
          const bulkActions = getSearchActions({ isMetadata: props.isMetadata, rootType: searchType, subSearchType, funcRowId: getRowId, funClone: callClone, funcSelRow: getSelectedRow, funcRowKey: getRowKey, openModal: openModal, workflowActions: state.workflowActions, workflowId: workflowId, searchActions: searchActionsItems, bulkFunction, bulk: true, userAccess, userRole: queryUser, parentCompt: props.currentCompt });
          let newState = {
            ...state,
            offset: newOffset,
            rowData: items,
            shownData: items,
            fieldMap,
            actions,
            actionsMultiSelect: actionsForMultiSelect,
            values: searchActions,
            bulkActions: bulkActions,
            loading: false,
            multiSelectCheckbox: getSearchActions({ multiSelect: true, funcSelRow: getSelectedRow }),
            total: total,
            selectAll: false,
            facets
            // ignoredFieldKeys
          };
          if (up(searchType) === SearchTypes.ITEM &&  props.rootType !== RootTypes.ITEMS ) {
            newState = {
              ...newState,
              actions: actions,
              offset: newOffset,
              multiSelectCheckbox: getSearchActions({ multiSelect: true, funcSelRow: getSelectedRow })
            };
          }
          if (props.singleSelect) {
            newState.multiSelectCheckbox = getSearchActions({ singleSelect: props.singleSelect, funcSelRow: getSelectedRow })
            newState.values = null;
            newState.bulkActions = null;
            newState.actions = null;
            newState.actionsForMultiSelect = "actionsForMultiSelect";
          }
          const selectAllStates = props.specificFieldSearch ? updatedOnSelectAll(true, newState) : {};
          return {
            ...newState,
            ...selectAllStates
          };
        }
      });
      if (props.setSelectError) {
        props.setSelectError(false);
        setNoMatch([]);
      }
    }
  }

  async function getSelectedRow(event, value) {
    await setState(state => {
      const newState = handleRowSelection(event, value, state);
      if (props.setEnableParentAction) {
        const { selectedRowsarray: rowsArr, unselectedRowsarray } = newState;
        props.setEnableParentAction((rowsArr && rowsArr.length > 0) || (unselectedRowsarray && unselectedRowsarray.length > 0));
      }
      return newState;
    });

    if (props.callSave) {
      props.callSave();
    }
  }

  function handleRowSelection(event, value, state) {
    let localState = state;
    const selectedId = event.currentTarget.attributes.id.nodeValue;
    if (selectedId === state.actionsMultiSelect) {
      localState = updatedOnSelectAll(value, state);
    } else if (selectedId && selectedId !== "") {
      localState = updatedSelectedRowsList(value, selectedId, state);
    }
    if (handleDisable) {
      handleDisable(localState.selectedRowsarray.length)
    }
    return localState;
  }

  function updatedOnSelectAll(value, state) {
    let locSelectedRowsarray;

    let locRowarray = [...state.rowData];
    if (value) {
      const isItem = up(searchType) === SearchTypes.ITEM;
      locRowarray = state.rowData.map(row => {
        return { ...row, selected: isItem ? !(row.regOther || row.regAlcohol || row.regTobacco) : value };
      });
      locSelectedRowsarray = locRowarray.filter(item => item.selected);
    } else {
      locRowarray = state.rowData.map(row => {
        return { ...row, selected: value };
      });
      locSelectedRowsarray = [];
    }
    if (props.setEnableParentAction) {
      props.setEnableParentAction(locSelectedRowsarray && locSelectedRowsarray.length > 0);
    }
    return {
      ...state,
      rowData: locRowarray,
      selectAll: value,
      unselectAll: state.unselectAll || !value,
      selectedRowsarray: locSelectedRowsarray,
      unselectedRowsarray: []
    };
  }

  function shouldPushLabelRow(selectedRowsarray, selectKey, childLabel) {
    return !selectedRowsarray.some(x => x[selectKey] === childLabel);
  }

  function updatedSelectedRowsList(value, selectedId, state) {
    let selectedRowsarray = [...state.selectedRowsarray];
    let unselectedRowsarray = [...state.unselectedRowsarray];
    const locRowData = [...state.rowData];
    if (value) {
      let selectedRow = {};
      locRowData.forEach(function (row) {
        if (testRowId(row)) {
          row.selected = value;
          selectedRow = row;
          if (!props.singleSelect) {
            return;
          }
        } else {
          if (props.singleSelect) {
            row.selected = false;
          }
        }
      });

      if (props.singleSelect) {
        selectedRowsarray = [selectedRow];
      } else {
        // remove dupe
        const selectedIndex = selectedRowsarray.findIndex(testRowId);
        if (selectedIndex >= 0) {
          selectedRowsarray = selectedRowsarray.slice(0, selectedIndex).concat(selectedRowsarray.slice(selectedIndex+1))
        }
        // remove from unselected
        const unselectedIndex = unselectedRowsarray.findIndex(testRowId);
        if (unselectedIndex >= 0) {
          unselectedRowsarray = unselectedRowsarray.slice(0, unselectedIndex).concat(unselectedRowsarray.slice(unselectedIndex+1))
        }

        selectedRowsarray.push(selectedRow);
        // if (up(searchType) === SearchTypes.ITEM) {
        //   const sameVariants = [];
        //   const x = locRowData.filter((item) => item.productID === selectedRow.productID);
        //   x.forEach((i) => i.selected = value);
        //   selectedRowsarray.push(x)
        // }


        if (selectedRow && selectedRow[selectKey]) {
          if(selectOnlyOneList && selectOnlyOneList.length>0){
            //If clicked row is part of selectOnlyOneList  then unselect rows from selectOnlyOneList and select only clicked row
            if(selectOnlyOneList.includes(selectedRow[selectKey])){
              //clear the selection of selectOnlyOneList rows by doing following.
              //1. push selectOnlyOneList rows to unselectedRowsArray
              //2. remove selectOnlyOneList rows from selectedRowsArray
              selectOnlyOneList.forEach(label=>{
                const childLabel = locRowData.find(x => x[selectKey] === label);
                if(childLabel){
                  childLabel.selected = false;
                  unselectedRowsarray.push(childLabel);
                  const childSelectedIndex = selectedRowsarray.findIndex(x => x[selectKey] === label);
                  if (childSelectedIndex >= 0) {
                    selectedRowsarray = selectedRowsarray.slice(0, childSelectedIndex).concat(selectedRowsarray.slice(childSelectedIndex+1))
                  }
                }
              })
              //Select only row , which was clicked
              //1. push the clicked row to selectedRowsArray
              //2. remove the clicked row from unselectedRowsArray
              const childLabel = locRowData.find(x => x[selectKey] === selectedRow[selectKey]);
              if (shouldPushLabelRow(selectedRowsarray, selectKey, selectedRow[selectKey])) {
                if(childLabel) {
                  childLabel.selected = value;
                  selectedRowsarray.push(childLabel);
                  const childSelectedIndex = unselectedRowsarray.findIndex(x => x[selectKey] === selectedRow[selectKey]);
                  if (childSelectedIndex >= 0) {
                    unselectedRowsarray = unselectedRowsarray.slice(0, childSelectedIndex).concat(unselectedRowsarray.slice(childSelectedIndex+1))
                  }
                } else {
                  selectedRowsarray.push({ [selectKey]: selectedRow[selectKey], selected: true });
                }
              }

            }
          }
          if(defaultParentChildSelected[selectedRow[selectKey]]) {
            defaultParentChildSelected[selectedRow[selectKey]].forEach(childLabelVal => {
              const childLabel = locRowData.find(x => x[selectKey] === childLabelVal);
              if (shouldPushLabelRow(selectedRowsarray, selectKey, childLabelVal)) {
                if(childLabel) {
                  childLabel.selected = value;
                  selectedRowsarray.push(childLabel);
                  // to clear from the unselected rows
                  const childSelectedIndex = unselectedRowsarray.findIndex(x => x[selectKey] === childLabelVal);
                  if (childSelectedIndex >= 0) {
                    unselectedRowsarray = unselectedRowsarray.slice(0, childSelectedIndex).concat(unselectedRowsarray.slice(childSelectedIndex+1))
                  }
                } else {
                  selectedRowsarray.push({ [selectKey]: childLabelVal, selected: true });
                }
              }
            });
          }
        }
      }
    } else {
      let selectedRow = {};
      locRowData.forEach(function (row) {
        if (testRowId(row)) {
          row.selected = value;
          selectedRow = row;
          if (!props.singleSelect) {
            return;
          }
        } else {
          if (props.singleSelect) {
            row.selected = false;
          }
        }
      });

      if (props.singleSelect) {
        selectedRowsarray = [];
      } else {
        // remove from selected
        const index = selectedRowsarray.findIndex(testRowId);
        if (index >= 0) {
          selectedRowsarray = selectedRowsarray.slice(0, index).concat(selectedRowsarray.slice(index+1))
        }

        //remove from the the parent
        if (selectedRow && selectedRow[selectKey]) {
          if(defaultParentChildSelected[selectedRow[selectKey]]) {
            defaultParentChildSelected[selectedRow[selectKey]].forEach(childLabelVal => {
              const childLabel = selectedRowsarray.find(x => x[selectKey] === childLabelVal);
              if (shouldPushLabelRow(unselectedRowsarray, selectKey, childLabelVal)) {
                if(childLabel) {
                  childLabel.selected = false;
                  unselectedRowsarray.push(childLabel);
                  // to clear from the selected rows
                  const childSelectedIndex = selectedRowsarray.findIndex(x => x[selectKey] === childLabelVal);
                  if (childSelectedIndex >= 0) {
                    selectedRowsarray = selectedRowsarray.slice(0, childSelectedIndex).concat(selectedRowsarray.slice(childSelectedIndex+1))
                  }
                } else {
                  unselectedRowsarray.push({ [selectKey]: childLabelVal });
                }
              }
            });
          }
        }
        unselectedRowsarray.push(selectedRow);
      }
    }
    return {
      ...state,
      rowData: locRowData,
      selectAll: state.selectAll ? unselectedRowsarray.length > 0 ? 'some' : true : false,
      selectedRowsarray: selectedRowsarray,
      unselectedRowsarray: unselectedRowsarray
    };

    function testRowId(row) {
      if (row[selectKey]) {
        return row[selectKey] === selectedId;
      } else {
        return row.id === selectedId;
      }
    }
  }

  function rejectionCommentsOnDone(row, value, item) {
    setBulkWorkflowDialog({ open: false })
    history.push({
      pathname: item.path,
      state: {
        loadingComplete: item.autoForward,
        moreParams: {
          autoForward: item.autoForward,
          execute: item.execute,
          move: item.move,
          label: item.label,
          sequence: item.sequence,
          currState: item.currState,
          save: item.save,
          view: item.view,
          viewAll: item.viewAll,
          mode: item.mode,
          path: item.path,
          order: item.order,
          rejectedComments: value
        }
      }
    })
  }

  function handleChangeRejectionInput(event, row, item) {
    const _value = event.target.value;
    const props = {
      row, value: _value, item, handleChangeRejectionInput, setBulkWorkflowDialog, rejectionCommentsOnDone
    }
    const dialogProps = getBulkWorkflowRejectionCommentsProps(props)

    setBulkWorkflowDialog(dialogProps);
  }

  function rejectPromotion(row, item) {
    const props = {
      row, value: '', item, handleChangeRejectionInput, setBulkWorkflowDialog, rejectionCommentsOnDone
    }

    reject(props);
  }

  function refreshOnDelete() {
    refresh();
    setBulkWorkflowDialog({open: false})
  }

  function deleteOnDone(row, item) {
    deleteItemFn({
      row,
      rootType: props.rootType,
      id: row.id,
      onDone: refreshOnDelete,
      setBulkWorkflowDialog,
      deleteItem
    })
  }

  function deleteFn(row, item) {
    const deleteProps = {
      row, item, setBulkWorkflowDialog, deleteOnDone, rootType: props.rootType
    }

    showDeleteDialog(deleteProps)
  }

  async function assignCouponsToMember(value,rows) {
    const props={value,rows,callAssignCouponService,setBulkWorkflowDialog}
    await assignCoupn(props)
    setModalInputValue("");
    refresh()
  }

  function cancelAssignment() {
    setBulkWorkflowDialog({ open: false });
    setModalInputValue("");
  }

  function assignCoupon(rows){
    const allowedLength=17;
    const dialogProps={
      title: `Enter membership number to assign `,
      textArea: {
        placeholder: 'Enter 11, 13 or 17 digit membership number',
        value: modalInputValue,
        onChange: (event) => setModalInputValue(event.target.value),
        row: rows,
        maxLength: allowedLength,
        helperText: { text: 'Membership number is not being validated.Please make sure you enter the correct number.', type: "WARNING" }
      },
      open: true,

      actions: [
        {
          onClick:(value)=>assignCouponsToMember(value,rows),
          label: 'Ok',
          color: 'secondary',
          variant: 'contained',
          disableCondition: (value) => membershipLength.allowedLength.indexOf(value.length) === -1,
        },
        {
          onClick: () => cancelAssignment(),
          label: 'Cancel',
          color: 'secondary',
          variant: 'outlined'
        }
      ]
    }
    setBulkWorkflowDialog(dialogProps)

  }

  async function bulkAssignCouponsToMembers(memberList, couponGroups) {
    const fileName = memberList.name;

    const formData = new FormData();
    formData.append("memberList", memberList);

    let props = {formData, fileName, callBulkAssignCouponMemberlistUploadService, setBulkWorkflowDialog};

    await bulkAssignCouponMemberlistUploadAction(props).then( response => {
      setModalInputValue("");
      refresh();
    });

    const body = {
      memberList: fileName.split(".")[0],
      couponGroups: couponGroups.map(couponGroup => couponGroup.couponGroupName),
      creatorId: simulatingUser ? simulatedUser.id.toLowerCase() : user.id.toLowerCase(),
      lockOnFirstUse: true,
      autoUse: true,
      memberListURL: `${baseURL}blob/coupon/bulkassignment/memberlists/${fileName}`
    };

    props = {body,fileName,callBulkAssignCouponService,setBulkWorkflowDialog};

    await bulkAssignCouponAction(props).then(response => {
      console.log("Upload Response: \n", response);
    });
  }

  function bulkAssignCoupon(rows){
    const dialogProps = {
      title: `Browse member target list filename`,
      fileBrowser: {
        onChange: (files) => setModalInputValue(files && files[0].name ? files[0] : ""),
        row: rows,
      },
      value: modalInputValue,
      open: true,
      actions: [
        {
          onClick:(value)=>bulkAssignCouponsToMembers(value,rows),
          label: 'Start Assignment',
          color: 'secondary',
          variant: 'contained',
          disableCondition: (value) => value === "",
        },
        {
          onClick: () => cancelAssignment(),
          label: 'Cancel',
          color: 'secondary',
          variant: 'outlined'
        }
      ]
    }
    setBulkWorkflowDialog(dialogProps)
  }

  /**
   * Handles the export of a big list.
   *
   * @param {Object} row - The row data to be exported.
   * @param {string} row.name - The name of the row.
   * @param {string} row.type - The type of the row.
   * @returns {Promise<void>} A promise that resolves when the export is complete.
   */
  async function handleExportBiglist(row) {
    await prepareDownload(row.name, false, row.type);
    watchDownload(row.name, true);
  }

  /**
   * Handles the download of a big list.
   *
   * @param {Object} row - The row data containing information about the file to download.
   * @param {string} row.name - The name of the file.
   * @param {string} row.extension - The file extension.
   */
  function handleDownloadBiglist(row) {
    downloadFile(row.name, row)
  }

  const [duplicateCampaign, setDuplicateCampaign] = useState({});
  const [displayDuplicateConfirmation, setDisplayDuplicateConfirmation] = useState(false);
  const [displayMessage,setDisplayMessage] = useState(null)
  useEffect(()=>{
    if(duplicateCampaign && duplicateCampaign.campaign_number && duplicateCampaign.campaign_number !=="" && modalInputValue!=="" && displayDuplicateConfirmation === true){
      const selectedRows = state.rowData.filter(r=>r.selected===true)
      // eslint-disable-next-line react/jsx-one-expression-per-line
      const txt = (<>Duplicate {selectedRows.length} offers with prefix: {modalInputValue} into Campaign #{duplicateCampaign.campaign_number} <br /><b>{duplicateCampaign.campaign_name} {moment(duplicateCampaign.start_date).format('MM/DD/YY')} - {moment(duplicateCampaign.end_date).format('MM/DD/YY')}.</b></>)
      setDisplayMessage(txt);
    }
  },[duplicateCampaign,modalInputValue,displayDuplicateConfirmation])

  const closeDuplicateConfirmation = useCallback(()=>{
    setBulkWorkflowDialog({ open: false, field : {value:''} , value :''})
    setDisplayDuplicateConfirmation(false);
    setModalInputValue('');
    setDuplicateCampaign({});
  },[])

  const createDuplicateCampaignCsv = useCallback(async()=>{
    const returnMsg = await duplicatePromotionFileUpload(state,duplicateCampaign,modalInputValue,user)
    newSnackbar(returnMsg)
  },[duplicateCampaign, modalInputValue, state.rowData])

  const memoizedDialogProps = useMemo(() => {
    if (displayMessage !== null) {
      return {
        title: `Duplicate Offers to Campaign`,
        text: displayMessage,
        open: true,
        actions: [
          {
            onClick: () => {
              closeDuplicateConfirmation();
            },
            label: "Cancel",
            color: "secondary",
            variant: "outlined",
          },
          {
            onClick: async () => {
              closeDuplicateConfirmation();
              await createDuplicateCampaignCsv();
            },
            label: "Start Duplication Now",
            color: "secondary",
            variant: "contained",
            disableCondition: (value) => value === "",
          },
        ],
      };
    }
    return null;
  }, [displayMessage]);

  useEffect(() => {
    if (memoizedDialogProps) {
      setBulkWorkflowDialog(memoizedDialogProps);
      setDisplayDuplicateConfirmation(false);
    }
  }, [memoizedDialogProps]);
  function bulkDuplicateOffers(){
    const valMsg = duplicatePromotionValidations(state);
    if(valMsg!==null){
      newSnackbar(valMsg)
      return;
    }
    const dialogProps = {
      title: `Duplicate Offers to Campaign`,
      field: {
        name: "campaign_name",
        shortDesc: "Campaign",
        longDesc: "Campaign (Duplicated offers will be in this campaign)",
        type: "SEARCH",
        searchType: "campaign",
        onUpdate: (cmpObj) => setDuplicateCampaign(cmpObj),
      },
      textArea: {
        value: modalInputValue,
        onChange: (event) => setModalInputValue(event.target.value),
        customHeight: "20px",
        multiline: false,
        otherProps: {
          label: "Duplicate Offer internal name prefix",
        },
      },
      value: "",
      open: true,
      actions: [
        {
          onClick: () => closeDuplicateConfirmation(),
          label: "Cancel",
          color: "secondary",
          variant: "outlined",
        },
        {
          onClick: () => setDisplayDuplicateConfirmation(true),
          label: "Duplicate...",
          color: "secondary",
          variant: "contained",
          disableCondition: (value, cmpValue) => (value === "" || !cmpValue || cmpValue.campaign_name === ""),
        },
      ],
    };
    setBulkWorkflowDialog(dialogProps);
  }

  function getRowId(id, row, params) {
    if (params && params.moreParams && params.moreParams.mode) {
      switch (params.moreParams.mode) {
        case (SearchActions.PDF):
          downloadPDF(params.moreParams.id, row)
          break;
        case (SearchActions.PDF_BULK):
          downloadPDFBulk(params.moreParams.id, row, params.moreParams)
          break;
        case (SearchActions.EDIT_LIMITS):
          overrideLimit(row);
          break;
        case (SearchActions.MODAL):
          openModal(params.moreParams.id, params.moreParams.action, params)(id, row);
          break;
        case (SearchActions.VIEW):
          getRowKey(params.moreParams.id, params, true, false)(id, row);
          break;
        case (SearchActions.CLONE):
          getRowKey(params.moreParams.id, params, false, true)(id, row);
          break;
        case (SearchActions.EXPORT):
          callExportOffersService([row], queryUser, setBulkWorkflowDialog, watchDownload);
          break;
        case (SearchActions.HISTORY):
          viewHistory(row, params);
          break;
        case (SearchActions.SIMULATE):
          history.push("/home");
          simulateUserId(row.userId);
          break;
        case (SearchActions.PATH):
          history.push({
            pathname: params.moreParams.path,
            state: { campaign_detail: row.values ? {...row.values,campaign_id:row.id} : {} }
          })
          break;
        case (SearchActions.SEARCH_OFFER):
          var fieldMap = getSearchFieldMap(null, null, { user: queryUser, templateQueries: null }, SearchTypes.PROMOTION);
          openSearchModal(SearchModalActions.TABULAR, params, [row], false, fieldMap);
          break;
        case (SearchActions.DELETE):
          deleteFn(row, params);
          break;
        case (SearchActions.SUBMIT_OFFERSETS):
          submitOffersets(row, params?.moreParams);
          break;
        case (SearchActions.GENERATE_COUPONS):
          generateCoupons(row);
          break;
        case (SearchActions.EXPORT_BIGLIST):
          handleExportBiglist(row);
          break;
        case (SearchActions.DOWNLOAD_BIGLIST):
          handleDownloadBiglist(row);
          break;

        default:
          getRowKey(params.moreParams.id, params, false, false)(id, row);
          break;
      }
    } else if (params && params.label && params.label.includes('Reject')) {
      rejectPromotion(row, params)
    } else {
      getRowKey(null, null, false, false)(id, row);
    }
  }

  function bulkWorkflow(rows, action) {
    const input = [];
    rows.map(function (x) {
      const newX = { sequence: action, id: x.id, workflowId };
      input.push(newX)
    });

    const execute = () => {
      executeBulkWorkflow({
        variables: {
          type: up(searchType),
          input
        }
      });
      setBulkWorkflowDialog({ open: false });
    }

    // dialog
    const dialogProps = {
      title: 'Confirm action',
      text: 'Are you sure you want to proceed?',
      open: true,
      actions: [
        closeDialogAction('Cancel'),
        {
          onClick: execute,
          label: 'Continue',
          color: 'secondary',
          variant: 'contained'
        }
      ]
    }

    setBulkWorkflowDialog(dialogProps);
  }

  async function getAllSelectedRows() {
    if (state.selectAll && !props.skipService) {
      const allRows = await fetchAllSearchData();
      let includedRows;
      if (state.selectAll === 'some') {
        includedRows = allRows.filter((row) => {
          const unselectedRows = state.unselectedRowsarray;
          for (let i = 0; i < unselectedRows.length; i++) {
            const unselectedRow = unselectedRows[i];
            if (unselectedRow.id === row.id) {
              return false;
            }
          }
          return true;
        })
      } else {
        includedRows = allRows;
      }
      return includedRows
    } else {
      return state.selectedRowsarray;
    }
  }

  async function downloadPDF(key, row) {
    // const offerData = await promotionPDFService(row[key]);
    let dialogProps;
    const pdf = (await import('@react-pdf/renderer')).pdf;
    const OfferPDF = (await import('../../pdf/offer/offerPDF')).default;
    const offerData = await callPromotionPDFService(row); //bdb62025-43c0-42d0-a10b-d160e54440ae
    const proceedDownload = offerData ? !Object.keys(offerData).reduce((prev,curr) => prev || offerData[curr] === 'error', false) : false;
    if (proceedDownload) {
      const reportName = offerData.promotion && offerData.promotion.values && offerData.promotion.values.promotion_name ? `${offerData.promotion.values.promotion_name}_report.pdf` : 'report.pdf'
      pdf(<OfferPDF data={{ ...offerData }} />).toBlob().then(blob => {
        const url = URL.createObjectURL(blob);
        downloadURI(url, reportName);
      })
      dialogProps = {
        title: ModalOverlayMessage.EXPORT_DEAL_SHEET(proceedDownload).title,
        text:  ModalOverlayMessage.EXPORT_DEAL_SHEET(proceedDownload).text
      }
    } else {
      dialogProps = {
        title: ModalOverlayMessage.EXPORT_DEAL_SHEET(proceedDownload).title,
        text:  ModalOverlayMessage.EXPORT_DEAL_SHEET(proceedDownload).text
      }
    }
    setBulkWorkflowDialog({
      ...dialogProps,
      open: true,
      actions: [
        {
          onClick: () => setBulkWorkflowDialog({ open: false }),
          label: 'ok',
          color: 'secondary',
          variant: 'contained'
        }
      ]
    });
  }


  async function downloadPDFBulk(key, campaignRow, params) {
    let dialogProps;
    const pdf = (await import('@react-pdf/renderer')).pdf;
    const JSZip = (await import('jszip')).default;
    const OfferPDF = (await import('../../pdf/offer/offerPDF')).default;

    try {
      const campaignName = campaignRow.values && campaignRow.values.campaign_name;
      const offerTypes = params && params.templateWhitelist && params.templateWhitelist.split(',');
      const { bulkPDFData, failures } = await callBulkPromotionPDFService(campaignRow, offerTypes);
      dialogProps = {
        title: ModalOverlayMessage.EXPORT_DEAL_SHEET(true, failures).title,
        text:  ModalOverlayMessage.EXPORT_DEAL_SHEET(true, failures).text
      }
      const jszip = new JSZip();
      let sams, supplier;

      const fileName = `${(campaignName || 'NA').replace(/[\\/]+/g,'_')} - Quest Tile Fee Agreements - ${moment().format('YYYY-MM-DD')}`;
      const zip = jszip.folder(fileName)

      for (const [i, offerData] of bulkPDFData.entries()) {
        const promotionName = offerData.promotion && offerData.promotion.values && offerData.promotion.values.promotion_name;
        const promotionNumber = offerData.promotion && offerData.promotion.values && offerData.promotion.values.is_promotion_number;
        const supplierOffer = !!(offerData.promotion && offerData.promotion.values && offerData.promotion.values.supplier_nbr);
        const reportName = `${(campaignName || 'NA').replace(/[\\/]+/g,'_')} - ${promotionNumber || 'NA'} ${(promotionName || 'NA').replace(/[\\/]+/g,'_')} - Quest Time Fee Agreement.pdf`
        const blob = pdf(<OfferPDF data={{ ...offerData }} />).toBlob();

        if (supplierOffer) {
          if (!supplier) {
            supplier = zip.folder('supplier');
          }
          supplier.file(reportName, blob)
        } else {
          if (!sams) {
            sams = zip.folder('sams');
          }
          sams.file(reportName, blob)
        }
      }
      const zipName = `${fileName}.zip`
      jszip.generateAsync({type:"blob"}).then((content) => {
        const url = URL.createObjectURL(content);
        downloadURI(url, zipName);
      });
    } catch(error) {
      dialogProps = {
        title: ModalOverlayMessage.EXPORT_DEAL_SHEET(false).title,
        text:  ModalOverlayMessage.EXPORT_DEAL_SHEET(false).text
      }
    }

    setBulkWorkflowDialog({
      ...dialogProps,
      open: true,
      actions: [
        {
          onClick: () => setBulkWorkflowDialog({ open: false }),
          label: 'ok',
          color: 'secondary',
          variant: 'contained'
        }
      ]
    });
  }

  async function editLimit(value, row) {
    await editLimitAction(value, row, setBulkWorkflowDialog, callOverrideLimitService);
    setModalInputValue('');
    search(null, state.filter, null, null, true);

  }
  async function exportCoupon(value, row) {
    await generateCouponAction(value, row, setBulkWorkflowDialog, callgenerateCouponService);
    setModalInputValue('');
  }

  function overrideLimit(row) {
    const dialogProps = {
      title: `Enter limit to override .`,
      textArea: {
        placeholder: 'Enter the new limit .',
        value: modalInputValue,
        type: "number",
        onChange: (event) => setModalInputValue(event.target.value),
        row: row,
        customHeight: "40px",
        multiline: false,
      },
      open: true,

      actions: [
        {
          onClick: (value) => editLimit(value, row),
          label: 'Ok',
          color: 'secondary',
          variant: 'contained',
          disableCondition: (value) => !checkNumberOnly(value) || ((value.length) === 0 || +value === 0)
        },
        {
          onClick: () => cancelAssignment(),
          label: 'Cancel',
          color: 'secondary',
          variant: 'outlined'
        }
      ]
    }
    setBulkWorkflowDialog(dialogProps)

  }

  function generateCoupons(row) {
    const noOfCouponsAvailable = couponsAvailable(row);
    const dialogProps = {
      title: { primary: "Create and Export Single User Coupons ...", secondary: `For the couponGroup : ${row.couponGroupName}` },
      textArea: {
        placeholder: 'Enter the number of coupons to be generated .',
        value: modalInputValue,
        type: "number",
        multiline: false,
        onChange: (event) => setModalInputValue(event.target.value),
        row: row,
        customHeight: "40px",
        helperText: { text: `Max ${noOfCouponsAvailable} are remaining available for this coupon group`, type: "INFO" },
      },
      open: true,

      actions: [
        {
          onClick: (value) => exportCoupon(value, row),
          label: 'Start Export',
          color: 'secondary',
          variant: 'contained',
          disableCondition: (value) => !checkNumberOnly(value) || ((value.length) === 0 || +value === 0) || (value > noOfCouponsAvailable)
        },
        {
          onClick: () => cancelAssignment(),
          label: 'Cancel',
          color: 'secondary',
          variant: 'outlined'
        }
      ]
    }
    setBulkWorkflowDialog(dialogProps)

  }

  const viewHistory = (row, params) => {
    openSearchModal(SearchModalActions.HISTORY, params, row, false);
  }

  function onViewButtonClick(state, id, view, clone, row, params) {
    const localState = { ...state, view, clone };

    localState.shouldRoute = id ? true : false;
    if ([getCompartmentTypes.COUPON, getCompartmentTypes.COUPONCODE,getCompartmentTypes.TERMSANDCONDITIONS,getCompartmentTypes.GENERICTARGETEDMESSAGE, getCompartmentTypes.BRANDING].includes(searchType)) {
      localState.navigate = {
        id: id,
        params
      };
    } else {
      localState.navigate = {
        id: id + '/' + row.templateId,
        workflowId: row.workflowId,
        params
      }
    }

    return localState;
  }

  function getNavTarget() {
    if (state.navigate) {
      const { params } = state.navigate;
      if (stringCompare(getCompartmentTypes.COUPON, searchType) || stringCompare(getCompartmentTypes.COUPONCODE, searchType) || stringCompare(getCompartmentTypes.TERMSANDCONDITIONS, searchType) || stringCompare(getCompartmentTypes.BRANDING, searchType)) {
        let formId = getDeepValue(params, ['moreParams', 'compartmentId']);
        if (!formId) {
          formId = props.parent.children.filter(id => id !== props.currentCompt)[0];
        }
        return `${props.parentPath}/${formId}?itemId=${state.navigate.id}${state.clone ? '&clone=true' : ''}`;
      }
      if (stringCompare(getCompartmentTypes.TARGETED_MESSAGING, searchType)) {
        const targetedCompartmentId = getDeepValue(params, ['moreParams','targetComponent']);
        const searchKey = getDeepValue(params, ['moreParams','searchKey']);
        const targetedCompartment= getCompartmentById(allCompartments, targetedCompartmentId)
        const childCompartment = targetedCompartment && targetedCompartment.children && getCompartmentById(allCompartments, targetedCompartment.children[0]);
        const [itemId, templateId ] = state.navigate.id.split('/');
        const urlParam= `${targetedCompartmentId}/${childCompartment.id}`
        const searchParam= `?${searchKey}=${itemId}`;
        return {urlParam,searchParam}
      }

      const [itemId, templateId] = state.navigate.id.split('/');
      const workflowIdOverride = state.navigate.workflowId;
      const parentOverride = getDeepValue(params, ['moreParams', 'parent']);
      const pageMode = getDeepValue(params, ['moreParams', 'mode']) || 'edit';
      const sequence = getDeepValue(params, ['sequence']) || '';
      const path = createSearchWorkflowPath({
        rootType: searchType,
        parentId: parentOverride || props.currentCompt,
        itemId,
        templateId, mode: state.clone ? 'clone' : pageMode,
        sequence,
        workflowId: workflowIdOverride || workflowId
      })
      return path;
    }
  }

  async function loadNextPage() {
    if (state.offset) {
      search(state.offset, state.filter, state.sort, true);
    }
    // setState(state => ({ ...state, loading: true }));
  }

  function renderNext() {
    if (state.shouldRoute && state.navigate) {
      return (
        <Redirect
          push
          to={{
            pathname: getNavTarget().urlParam? getNavTarget().urlParam: getNavTarget(),
            search: getNavTarget().searchParam? getNavTarget().searchParam: '',
          }}
        />
      );
    }
  }

  const handleChangeDate = key => event => {
    setState(state => {
      const { dateErrorArray } = state;
      const dateErrors = event.target.error
        ? dateErrorArray
          ? [...dateErrorArray, true]
          : [true]
        : dateErrorArray && dateErrorArray.length > 1
          ? dateErrorArray.slice(1)
          : null;
      return {
        ...state,
        dateErrorArray: dateErrors,
        globalDate: {
          ...(state.globalDate ? state.globalDate : {}),
          [key]: event.target
        }
      };
    });
  }

  const defaultFilter = switchProps.value === userSwitchValues.MY_OFFERS && showAllOffersSwitch() && buildDefaultFilterForSwitch();

  function clearSearch() {
    if (state.offset && !defaultFilter) {
      setState((state) => ({
        ...state,
        rowData: [],
        filter: null,
        offset: null
      }))
      search(null, null, state.sort);
    } else {
      setState(state => ({
        ...state,
        filter: defaultFilter
      }));
    }
  }

  function onChangeSearch({ filter, sort }) {
    const newFilterKeys = filter && Object.keys(filter) || [];
    const existingFilterKeys = state.filter && Object.keys(state.filter) || [];
    const allFilterKeys = [...newFilterKeys, ...existingFilterKeys];
    const newFilterObj = allFilterKeys.reduce((obj, prop) => {
      obj[prop] = (filter && filter[prop]) || (state.filter && state.filter[prop])
      return obj
    }, {});
    const finalFilter = { ...newFilterObj, ...defaultFilter };
    setState(state => ({
      ...state,
      sort: sort || state.sort,
      filter: finalFilter
    }));
  }

  function closeSearchModal() {
    setSearchModal((searchModal) => ({
      ...searchModal,
      open: false
    }))
  }

  function closeSearchModalSuccess() {
    setState((state) => {
      if (searchModal.action === SearchModalActions.DELETE) {
        const rowData = state.rowData.filter((row) => {
          for (let i = 0; i < searchModal.items.length; i++) {
            const item = searchModal.items[i]
            if (item.id === row.id) {
              return false;
            }
          }
          return true;
        })
        return {
          ...state,
          rowData: rowData
        }
      }

    })
    closeSearchModal();
  }

  function openSearchModal(action, params = {}, items = null, bulk = true, fieldMap={}) {
    setSearchModal((searchModal) => ({
      ...searchModal,
      open: true,
      action: action,
      items: items ? items : state.selectedRowsarray,
      bulk: bulk,
      params: params,
      fieldMap: fieldMap
    }))
  }

  function abortSearch() {
    aborter.abort();
    aborter = new AbortController();
  }
  async function onExport(exportType = ExportTypes.EXPORT_BY_ITEM) {
    const fullRowData = state.rowData;
    const selectedRows = await getAllSelectedRows();
    const rowsToBeExported = selectedRows.length > 0 ? selectedRows : fullRowData;
    const currentUser = simulatingUser ? simulatedUser : user;
    const showExportButton = (statsComponent && statsComponent.props && statsComponent.props.leftLabel === "offers");
    callExportOffersService(rowsToBeExported, currentUser, setBulkWorkflowDialog, watchDownload, showExportButton, fetchAllSearchData, exportType);
  }

  function refresh() {
    history.replace({ ...history.location, state: {} });
    const filter = buildDefaultFilterForSwitch(true)
    getAllBranding();
    setState((state) => ({
      ...state,
      rowData: [],
      shownData: [],
      selectedRowsarray: parentSelectedFullValue ? parentSelectedFullValue.map(item => !item[selectKey] ? { ...item, [selectKey]: item.id } : item) : parentSelected ? parentSelected.map(item => ({ [selectKey]: item })) : [],
      unselectedRowsarray: parentUnselected ? parentUnselected.map(item => ({ [selectKey]: item })) : [],
      filter: filter,
      sort: null,
      loading: true
    }))
    setSwitchProps(switchProps => ({
      ...switchProps,
      value: userSwitchValues.MY_OFFERS
    }))
    setShowFilter(false);
    if (props.skipService && props.refresh) {
      props.refresh();
    } else {
      abortSearch();
      search(null, filter, null, null, true);
    }
  }

  function parseCsvFile(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        const result = e.target.result.replace(/[\r\n]/g, ',');
        resolve(result);
      }
      fileReader.onerror = e => {
        reject(e);
      }
      fileReader.readAsText(file);
    });
  }

  async function handleCsvFileSelect(event) {
    const { target } = event;
    if (target && target.files) {
      const { files } = target;
      let fileNames = '';
      const parseFiles = [];
      for (let i = 0; i < files.length; i++) {
        if (!/\.csv$/.test(files[i].name)) {
          continue;
        }
        parseFiles.push(parseCsvFile(files[i]));
        fileNames += (fileNames ? ', ' : '') + files[i].name;
      }
      if (!parseFiles.length) {
        return;
      }
      setFileName(fileNames);

      await Promise.all(parseFiles).then(values => {
        const { specificFieldSearch } = props;
        let result = '';
        for (let i = 0; i < values.length; i++) {
          const value = values[i];
          const noComma = value && (value[value.length - 1] === ',') ? value.slice(0, value.length-1) : value
          if (noComma) {
            result += (result ? ',' : '') + noComma;
          }
        }

        const prevSearch = state.filter ? state.filter[specificFieldSearch] : null;
        const filter = {
          ...(state.filter ? state.filter : {}),
          [specificFieldSearch]: {
            ...(prevSearch ? prevSearch : {}),
            search: result,
            option: 'exact',
            data: { type: 'STRING' }
          }
        };
        setState(state => ({ ...state, filter }));
      });
    }
  }

  const statsComponent = useMemo(renderTotal,  [props.skipService, state.shownData, state.rowData, props.total, state.total])

  function renderTotal() {
    const data = props.skipService ? state.shownData : state.rowData
    if (state.loading) {
      return <SearchTotal loading />
    }
    const type = low(searchType);
    let label;
    switch(type){
      case(RootTypes.PROMOTION):
        label = 'offer';
        break;
      case(low(SearchTypes.GENERICTARGETEDMESSAGE)):
        label = 'GTM slot'
        break;
      case(low(SearchTypes.GENERICTARGETEDMESSAGEBYSLOT)):
        label = 'GTM';
        break;
      default:
        label = type;
        break;
    }
    label = `${label}${type[type.length-1] === 's' ? '' : 's'}`

    let slotName = decodeURI(queryFilter?.slot_id?.selected[0]);
    if(slotName && slotName!=='undefined'){
      slotName = slotName.replace(/_/g,' ');
      label= type === low(SearchTypes.GENERICTARGETEDMESSAGEBYSLOT) ? label+ " for Slot - "+slotName:label
    }

    let component;
    if (props.total) {
      component = <SearchTotal leftLabel={label} leftValue={props.total} />;
    } else {
      component = <SearchTotal leftLabel={label} leftValue={state.total} rightLabel='loaded' rightValue={data ? data.length : 0} />
    }

    return component;
  }

  if ((indexMap && indexMap.error) || (searchData && searchData.error) || parentError) {
    return (
      <ErrorComponent
        message='System Error. Please try again later'
        onClick={refresh}
      />
    )
  }

  if (props.dataLoading || state.loading || loadingBulkExecute || loadingOnDelete || loadingSubmitOffersets || loadingCancelOffersets || (state && state.fieldMap && Object.keys(state.fieldMap).length === 0)) {
    return (
      <div className={classes.skeletonContainer}>
        <Skeleton
          wrapper={({ children }) => (
            <div className={classes.skeletonWrapper}>{children}</div>
          )}
          height={48}
          count={5}
        />
      </div>
    );
  }

  function getOverlayMessage() {
    const { filter, rowData } = state;
    if (!searchLoading) {
      if(!rowData.length && !(props.tabType && !filter)) {
        let typeLabel;
        switch(low(searchType)){
          case(low(SearchTypes.PROMOTION)):
            typeLabel = 'offers';
            break;
          case(low(SearchTypes.GENERICTARGETEDMESSAGE)):
            typeLabel = 'GTM slot'
            break;
          case(low(SearchTypes.GENERICTARGETEDMESSAGEBYSLOT)):
            typeLabel = 'GTM';
            break;
          case(low(SearchTypes.ASSIGNEDCOUPON)):
            typeLabel = 'Assigned Coupon';
            break;
          default:
            typeLabel = searchType;
            break;
        }
        let type = '';
        if (typeLabel) {
          if (typeLabel[typeLabel.length - 1] === 's') {
            type = typeLabel;
          } else {
            type = typeLabel + 's';
          }
        } else {
          type = 'records';
        }

        const message = `No ${type} found`;

        const searchBarValues = filter?.searchAll?.search?.split(',').map(value => value.trim());

        if (searchBarValues && searchBarValues.every(value => !isNaN(value || '')) && low(searchType) === low(SearchTypes.PROMOTION) && searchParams.itemSearchPage && canAccessPath(allCompartments, searchParams.itemSearchPage)) {
          return (
            <div className={classes.overlayMessage}>
              <div>
                {message}
              </div>
              <Link
                className={classes.overlayLink}
                to={{
                  pathname: searchParams.itemSearchPage,
                  state: {
                    filter: {
                      itemNumber: {
                        search: filter?.searchAll?.search,
                        data: { type: 'STRING', siblings: null },
                        option: 'exact',
                      }
                    }
                  }
                }}
              >
                Click here to search by Item Number
              </Link>
            </div>
          )
        }
        return message;
      } else if (props.tabType) {
        const tableOverlayFunc = TableOverlayMessage[props.tabType];
        return tableOverlayFunc ? tableOverlayFunc(searchType, state.fieldMap, props.tableNoteFieldCount) : null;
      }
    }
    return null;
  }

  const bulkWorkflowStates =  state.workflowActions? state.workflowActions.filter(x=> x.bulk && x.uiReadable) : [];
  const bulkStates = state.bulkActions && state.bulkActions[0] && state.bulkActions[0].props && state.bulkActions[0].props.items[2] && state.bulkActions[0].props.items[2].bulk.length > 0? state.bulkActions[0].props.items[2].bulk : null;
  const bulkActionsShown = enableSearchMultiSelect
    // && (state.selectedRowsarray.length > 0 || state.selectAll)
    && !props.singleSelect && ((bulkWorkflowStates && bulkWorkflowStates.length) || (bulkStates && bulkStates.length));



  function renderBulkActions() {

    if (enableSearchMultiSelect && (bulkStates || bulkWorkflowStates.length > 0)) {
      const type = searchType && low(searchType)
      return (
        <div className={classes.bulkActionsWrapper}>
          <Paper className={classes.bulkActionsContainer} style={{bottom: bulkActionsShown ? 0 : -68}} square>
            <div className={classes.bulkActions}>
              <Typography className={classes.selectedCount}>
                {state.rowData.length > 0 && type !== low(SearchTypes.GENERICTARGETEDMESSAGE) && type !== low(SearchTypes.GENERICTARGETEDMESSAGEBYSLOT) &&
                `${totalSelected} ${searchType ? `${type === RootTypes.PROMOTION ? 'offer' : type}${type[type.length - 1] === 's' ? '' : 's'} ` : ''}selected`}
              </Typography>
              <div className={classes.buttons}>
                {
                  bulkWorkflowStates? Object.keys(bulkWorkflowStates).map((key)=>{

                    const isSteAccessible = checkStepAccess(userAccess.stepAccess, bulkWorkflowStates[key].name);
                    if(!isSteAccessible) {
                      return;
                    }

                    const commonProps = {

                      label: getStepLabel(bulkWorkflowStates[key].description, userRole),
                      // description: action.description,
                      onClick: async (event) => bulkWorkflow(await getAllSelectedRows(), bulkWorkflowStates[key].sequence),
                      disabled: !(state.selectAll || (state.selectedRowsarray && state.selectedRowsarray.length > 0)),
                      color: 'secondary',
                      variant: 'contained',
                      className: classes.button,
                      size: 'large'
                    }
                    return (
                      <CustomButton
                        key={bulkWorkflowStates[key].name}
                        {...commonProps}
                      />
                    )
                  }):  null
                }
                {bulkStates ? bulkStates.map((action) => {

                  let label;
                  if (typeof action.label === 'string') {
                    label = action.label;
                  } else if (typeof action.label === 'object') {
                    label = action.label.DEFAULT
                  } else {
                    label = '';
                  }

                  const commonProps = {
                    LeftIcon: action.Icon,
                    label: label,
                    description: action.description,
                    onClick: async (event) => action.onClick(await getAllSelectedRows(), event),
                    disabled: !(state.selectAll || (state.selectedRowsarray && state.selectedRowsarray.length > 0)),
                    color: 'secondary',
                    variant: 'contained',
                    className: classes.button,
                    size: 'large'
                  }

                  const mode = action.moreParams?.mode
                  commonProps.disabled = mode===SearchActions.BACK_TO_SEARCH?false:commonProps.mode;

                  if (action.moreParams && action.moreParams.variant) {
                    const variant = action.moreParams.variant;
                    if (variant === 'SELECT') {
                      return (
                        <SelectButton
                          key={action.label}
                          {...commonProps}
                        />
                      )
                    }
                  }
                  return (
                    <CustomButton
                      key={action.label}
                      {...commonProps}
                    />
                  )
                }) : null }
              </div>
            </div>
          </Paper>
        </div>
      )
    }
  }
  return (
    <React.Fragment>
      <div className={classes.tableWrapper}>
        <CustomTable
          fieldMap={state.fieldMap}
          actions={state.actions}
          actionsMultiSelect={state.actionsMultiSelect}
          selectAll={state.selectAll}
          selectAllActive={state.selectAllActive}
          rowData={props.skipService ? state.shownData : state.rowData}
          fullRowData={state.rowData}
          values={state.values}
          multiSelectCheckbox={state.multiSelectCheckbox}
          loadNextPage={loadNextPage}
          hasNextPage={!!state.offset}
          enableMultiSelect={(showBulkCheckbox||props.singleSelect|| props.multiSelect|| (bulkStates || bulkWorkflowStates.length > 0)) && !props.dateEnabled}
          onChangeSearch={onChangeSearch}
          sort={state.sort}
          hideFields={props.data && props.data.hideFields}
          noDelay={props.data}
          searchType={searchType}
          clearSearch={clearSearch}
          isModal={isModal}
          dateEnabled={props.dateEnabled}
          globalDate={state.globalDate}
          handleChangeDate={handleChangeDate}
          actionWidth={actionWidth}
          actionTitle={props.actionTitle}
          heightAdjust={bulkActionsShown ? 68 : 0}
          disableSelectAll={props.singleSelect || parentBiglistName}
          filterOverride={props.filterOverride}
          showAdvancedInitial={props.showAdvancedInitial}
          onRefresh={!props.skipService || (props.skipService && props.refresh) ? refresh : null}
          specificFieldSearch={props.specificFieldSearch}
          uploadCsvProps={props.uploadCsv ? { handleCsvFileSelect, fileName } : null}
          noAdvanced={props.noAdvanced}
          noSearchBar={props.noSearchBar}
          selectKey={selectKey}
          statsComponent={statsComponent}
          sideSpace={props.sideSpace}
          overlayMessage={getOverlayMessage()}
          searchLoading={searchLoading}
          noMatch={noMatch.length > 0 ? noMatch : null}
          itemMetadata={props.itemMetadata}
          queries={props.queries}
          filter={state.filter}
          showAllOffersSwitch={showAllOffersSwitch}
          switchProps={switchProps}
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          defaultFilter={defaultFilter}
          exportHandler={onExport}
          bulkDuplicateHandler={bulkDuplicateOffers}
          showExportButton={(statsComponent && statsComponent.props && statsComponent.props.leftLabel === "offers")}
        />
        {/* {renderSearchActions(props.searchActions)} */}
        {props.isModal ? null : <BackgroundProcessingBar style={{bottom: bulkActionsShown ? 68 : 0}} />}
        {renderBulkActions()}
        {renderNext()}
      </div>
      {/* <>
        {fileNameError === name ? (
          <Typography color="error" variant="h3">
            Name already exists.
          </Typography>
        ) : null}
      </> */}
      <SearchModal
        open={searchModal.open}
        bulk={searchModal.bulk}
        searchType={searchType}
        items={searchModal.items}
        action={searchModal.action}
        onRequestClose={closeSearchModal}
        onRequestCloseSuccess={closeSearchModalSuccess}
        params={searchModal.params}
        fieldMap={searchModal.fieldMap}
        rowData={searchModal.rowData}
      />
      <ConfirmationDialog {...bulkWorkflowDialog} loading={loading} value={modalInputValue} />
    </React.Fragment>
  );
}

export default forwardRef(SearchFlexible);
