import React, { useState, useRef, useImperativeHandle, forwardRef, useMemo, useEffect, useCallback } from 'react';
import { InputAdornment, Button, Tabs, Tab, Typography } from '@material-ui/core';
import useStyle from './CsvInputStyles';
import Chip from '../filter/Chip';
import MultilineInput from './MultilineInput';
import ClubList from '../ClubList/ClubList';
import ItemList from '../../compartments/item/ItemList';
import UserList from '../../compartments/user/UsersList';
import FullScreenModal from '../modal/FullScreenModal';
import { SearchTypes, TemplateMixTypes, up, MixTypeLabels, MixTabTypes, BlobActions, Status, FieldTypes, ListActionTypes, BlobProcessStatus, low } from '../../constants/Constants';
import CustomButton from '../buttons/CustomButton/CustomButton';
import Search from '../../compartments/search/search';
import getBiglistEditValues from '../../Utils/template/getBiglistEditValues';
import { useFileTracker } from '../../containers/biglist/FileTracker/FileTracker';
import getDeepValue from '../../Utils/getDeepValue';
import { baseFolder } from '../../constants/Endpoints';
import dateFormat from '../../Utils/date/dateFormat';
import SearchNoIndex from '../../compartments/search/SearchNoIndex';
import getCompartmentTypes from "../../Utils/compartments/getCompartmentTypes";
import getAllBiglistData from '../../Utils/services/getAllBiglistData';
import getItemNumbersWithSameSign from '../../Utils/services/getItemNumbersWithSameSign';
import BigListUpload from '../../Components/biglist/BigListUpload/BigListUpload';

const CsvInput = (props, ref) => {
  const classes = useStyle()
  const childSearch = useRef(null);
  const {
    value,
    fullValue,
    parentValue,
    onChange,
    placeholder,
    searchType: searchTypeProp,
    onClear,
    handleFlag,
    viewOnly,
    enableBiglist,
    enableSearch,
    parentMixType,
    biglistContinue,
    editListValue,
    queries,
    isReplace,
    table,
    downloadBiglist,
    noModal,
    excludeClubs,
    biglistStatus,
    biglistName,
    countryFilter,
    closeViewOnly,
    fromViewOnly,
    ...textInputProps
  } = props;
  // club date enabled
  const dateEnabled = getDeepValue(queries, ['dateEnabled']);
  const hideBiglistTabs = getDeepValue(queries, ['hideBiglistTabs']);
  const lockValues = dateEnabled ? true : false;
  const hasParentFilter = parentValue;
  const displayPropValue = getDeepValue(queries, ['displayPropValue']);
  const pullSignId = getDeepValue(queries, ['pullSignId']);

  const displayedValue = value;

  const [openModal, setOpenModal] = useState(false);
  const [enableAction, setEnableAction] = useState(lockValues);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectError, setSelectError] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSignId, setLoadingSignId] = useState(false);
  const [searchType, subSearchType] = searchTypeProp && searchTypeProp.length ? searchTypeProp.split('.') : [];

  const {
    uploadFiles,
    resetUpload,
    files,
  } = useFileTracker();

  const readyFiles = files && files.filter((fileObj) => fileObj.status === Status.READY) || [];

  // ShowModal bydefault with specific tabs of searchType from view screen
  useEffect(() => {
    if (fromViewOnly) {
      onOpenModal();
    }
  }, [fromViewOnly])

  const fetchSignIdData = useCallback(async () => {
    setLoadingSignId(true);
    if (low(parentMixType) === TemplateMixTypes.BIGLIST) {
      let itemNumbers = [];
      const items = await getAllBiglistData(biglistName);
      itemNumbers = items.map((item) => item.id);
      Object.keys(editListValue).forEach((itemNumber) => {
        const action = editListValue[itemNumber];
        if (action === 'add') {
          itemNumbers.push(itemNumber);
        } else {
          // remove item number from list
          itemNumbers = itemNumbers.filter((item) => item !== itemNumber);
        }
      })

      const signItemNumbers = await getItemNumbersWithSameSign(itemNumbers)

      const newEditValue = { ...editListValue }

      signItemNumbers.forEach((signItemNumber) => {
        newEditValue[signItemNumber] = 'add'
      })

      onChange({
        target: {
          value: newEditValue,
          fullValue: fullValue
        }
      }, 'editList');
    } else {
      const itemNumbers = value || [];
      const signItemNumbers = await getItemNumbersWithSameSign(itemNumbers)

      const newItemNumbers = itemNumbers.concat(signItemNumbers);

      onChange({
        target: {
          value: newItemNumbers.join(','),
          fullValue: fullValue
        }
      }, 'csv');
    }

    setLoadingSignId(false);
  }, [loadingSignId, onChange, value, editListValue, biglistName, parentMixType, fullValue])

  const tabs = useMemo(() => {
    function createTab(label, props) {
      return {
        props: props ? props : {},
        component: (
          <Tab
            label={label}
            classes={{
              root: classes.tab,
              wrapper: classes.tabWrapper,
              selected: classes.tabSelected
            }}
          />
        )
      }
    }

    switch (up(searchType)) {
      case (SearchTypes.MEMBER):
        return [
          ...noModal ? [] : [createTab(MixTypeLabels.BIGLIST(searchType), {
            isBiglist: true
          })],
          ...noModal ? [] : [createTab(MixTypeLabels.IMPORT(searchType), {
            uploadCsv: true,
            isBiglist: true
          })]
          // createTab(MixTypeLabels.PASTE, {
          //   noAdvanced: true,
          //   specificFieldSearch: 'storeNumber'
          // })
        ]
      case (SearchTypes.CLUB):
        return [
          createTab(MixTypeLabels.SEARCH(searchType), {
            tabType: MixTabTypes.SEARCH
          }),
          ...(lockValues ? [] : [
            ...noModal ? [] : [createTab(MixTypeLabels.BIGLIST(searchType), {
              isBiglist: true
            })],
            createTab(MixTypeLabels.IMPORT(searchType), {
              noAdvanced: true,
              specificFieldSearch: 'storeNumber',
              uploadCsv: true,
              clearWithoutFilter: true,
              tabType: MixTabTypes.IMPORT
            }),
            createTab(MixTypeLabels.PASTE(searchType), {
              noAdvanced: true,
              specificFieldSearch: 'storeNumber',
              clearWithoutFilter: true,
              tabType: MixTabTypes.PASTE
            })
          ])
        ]
      case (SearchTypes.ITEM):
        // show first 100 items, search, enter / paste tabs when navigated from view screen
        if (hideBiglistTabs === 'true') {
          return [
            createTab(MixTypeLabels.BROWSE(searchType), { // 0 = browse catalog
              noSearchBar: true,
              clearWithoutFilter: true,
              tabType: MixTabTypes.BROWSE
            }),
            createTab(MixTypeLabels.SEARCH(searchType), { // 1 = search items
              noAdvanced: true,
              showNote: true,
              clearWithoutFilter: true,
              tabType: MixTabTypes.SEARCH
            }),
            createTab(MixTypeLabels.PASTE(searchType), { // 4 = enter/paste
              noAdvanced: true,
              specificFieldSearch: 'itemNumber',
              clearWithoutFilter: true,
              tabType: MixTabTypes.PASTE
            })
          ]
        }
        if (fromViewOnly) {
          return [
            createTab(MixTypeLabels.VIEW(searchType), { // 0 = show first 100 items
              noAdvanced: true,
              tabType: MixTabTypes.VIEW
            }),
            createTab(MixTypeLabels.SEARCH(searchType), { // 1 = search items
              noAdvanced: true,
              showNote: true,
              clearWithoutFilter: true,
              tabType: MixTabTypes.SEARCH,
              specificFieldSearch: 'itemNumber',
              tableNoteFieldCount: 1
            }),
            createTab(MixTypeLabels.PASTE(searchType), { // 1 = enter/paste
              noAdvanced: true,
              specificFieldSearch: 'itemNumber',
              clearWithoutFilter: true,
              tabType: MixTabTypes.PASTE
            })
          ]
        }
        return [
          createTab(MixTypeLabels.BROWSE(searchType), { // 0 = browse catalog
            noSearchBar: true,
            clearWithoutFilter: true,
            tabType: MixTabTypes.BROWSE
          }),
          createTab(MixTypeLabels.SEARCH(searchType), { // 1 = search items
            noAdvanced: true,
            showNote: true,
            clearWithoutFilter: true,
            tabType: MixTabTypes.SEARCH
          }),
          ...noModal ? [] : [createTab(MixTypeLabels.BIGLIST(searchType), { // 2 = saved item list
            isBiglist: true
          })],
          createTab(MixTypeLabels.IMPORT(searchType), { // 3 = import item list
            noAdvanced: true,
            specificFieldSearch: 'itemNumber',
            uploadCsv: true,
            clearWithoutFilter: true,
            tabType: MixTabTypes.IMPORT
          }),
          createTab(MixTypeLabels.PASTE(searchType), { // 4 = enter/paste
            noAdvanced: true,
            specificFieldSearch: 'itemNumber',
            clearWithoutFilter: true,
            tabType: MixTabTypes.PASTE
          })
        ]
      default:
        return [];
    }

  }, [noModal, searchType])

  const [tabProps, setTabProps] = useState(tabs && tabs[0] ? tabs[0].props : {});
  const mixType = tabProps && tabProps.isBiglist ? TemplateMixTypes.BIGLIST : TemplateMixTypes.CSV;
  useImperativeHandle(ref, () => ({
    async getData(data) {
      const args = await getArgs(data);
      setSelectError(args && args.error);
      if (args && !args.error) {
        setOpenModal(false);
        setEnableAction(false);
      }
      return args;
    }
  }));

  function handleDelete(val) {
    if (!props.disabled) {
      if (biglistContinue && low(parentMixType) === TemplateMixTypes.BIGLIST) {
        const newValue = editListValue || {};
        newValue[val] = 'remove'
        let listFullValue;
        if (fullValue) {
          if (fullValue.find((item => val === item.id))) {
            listFullValue = fullValue;
          } else {
            listFullValue = fullValue.concat({ id: val })
          }
        } else {
          listFullValue = [{ id: val }];
        }
        onChange({
          target: {
            value: newValue,
            fullValue: listFullValue
          }
        }, 'editList');
      } else {
        const list = value.filter(item => val !== item);
        const listFullValue = fullValue && fullValue.filter(item => val !== item.id);
        onChange({
          target: {
            value: list.join(','),
            fullValue: listFullValue
          }
        }, 'csv');
      }
    }
  }

  function onOpenModal() {
    setOpenModal(true);
    setEnableAction(lockValues);
    setTabIndex(0);
    if (tabs && tabs[0] && tabs[0].props) {
      setTabProps(tabs[0].props);
    } else {
      setTabProps({});
    }
  }

  function handleClose() {
    setOpenModal(false);
    setEnableAction(false);
    resetUpload();
    if (closeViewOnly) {
      closeViewOnly()
    }
  }

  function handleChangeTabs(event, newValue) {
    setTabIndex(newValue);
    if (tabs && tabs[newValue] && tabs[newValue].props) {
      setTabProps(tabs[newValue].props);
    } else {
      setTabProps({});
    }
  }

  async function getArgs(data) {
    if (childSearch && childSearch.current && childSearch.current.getSelected) {
      const { selectedRowsarray: selected, unselectedRowsarray: deselected, unselectAll, error } = await childSearch.current.getSelected();
      if (error && !data.bypassError) {
        return { error };
      }
      const idValues = selected ? selected.map(item => item.id + '') : [];
      const type = data.type === 'update' && unselectAll ? 'replace' : data.type;
      if (low(mixType) === TemplateMixTypes.CSV) {
        let newValues;
        const idValuesDeselect = deselected ? deselected.map(item => item.id + '') : [];
        if (biglistContinue && low(parentMixType) === TemplateMixTypes.BIGLIST) {
          let replace = false;
          newValues = editListValue || {};
          switch (type) {
            case 'add': // add values - no duplicate
              idValues.forEach(itemId => {
                newValues[itemId] = 'add';
              });
              break;
            case 'remove': // filter out values
              idValues.forEach(itemId => {
                newValues[itemId] = 'remove';
              });
              break;
            case 'update':
              idValues.forEach(itemId => {
                newValues[itemId] = 'add';
              });
              idValuesDeselect.forEach(itemId => {
                newValues[itemId] = 'remove';
              });
              break;
            case 'replace': // replace existing values
            default:
              newValues = {};
              idValues.forEach(itemId => {
                newValues[itemId] = 'add';
              });
              // newValues.files = uploadFiles;
              replace = true;
              break;
          }
          return [{ target: { value: newValues, fullValue: selected } }, 'editList', replace];
        } else {
          switch (data.type) {
            case 'add': // add values - no duplicate
              newValues = [...new Set([
                ...idValues,
                ...(value && typeof value === 'object' ? value : [])
              ])];
              break;
            case 'remove': // filter out values
              newValues = value.filter(item => !idValues.find(val => item === val));
              break;
            case 'update':
              newValues = [...new Set([
                ...idValues,
                // add previous values, minus the values that were deselected
                ...(value && typeof value === 'object' ? value.filter((val) => !idValuesDeselect.includes(val)) : [])
              ])];
              break;
            case 'replace': // replace existing values
            default:
              newValues = idValues;
              break;
          }
          // newFullValue pulls the fullValue for each row from selected or the previous fullValue (for club override list)
          const newFullValue = newValues.map((val) => {
            let fullData = selected ? selected.find((full) => full.id === val) : null;
            if (!fullData) {
              fullData = fullValue ? fullValue.find((full) => full.id === val) : null;
            }
            return {
              id: val,
              ...(fullData ? fullData : {})
            }
          });
          return [{ target: { value: newValues.join(','), fullValue: newFullValue } }, mixType];
        }
      } else {
        return [{ target: { value: idValues[0] } }, mixType];
      }
    } else if (readyFiles && readyFiles.length > 0) {
      uploadFiles(baseFolder, BlobActions.NEW);
      const index = readyFiles[0].name.lastIndexOf('.');
      const name = readyFiles[0].name.slice(0, index);
      return [{ target: { value: name } }, mixType]
    }
    return null;
  }

  async function handleSave(data) {
    setLoadingData(true);
    const args = await getArgs(data);
    setLoadingData(false);
    setSelectError(args && args.error);
    if (args && !args.error) {
      onChange(...args);
      setOpenModal(false);
      setEnableAction(false);
    }
  }

  function createCountryFilter() {
    if (!countryFilter) {
      return null;
    }
    let search = '';
    switch (low(countryFilter)) {
      case 'all':
      case 'clubs':
        return null;
      default:
        search = countryFilter;
        break;
    }
    return {
      countryCode: {
        search,
        data: {
          type: FieldTypes.STRING
        }
      }
    }
  }

  function createFilter(isBiglist) {
    const countryCreatedFilter = createCountryFilter();
    if (!hasParentFilter) {
      return countryCreatedFilter;
    }
    let field = subSearchType;
    if (!field) {
      switch (searchType) {
        case SearchTypes.CLUB:
          field = 'storeNumber';
          break;
        default:
          field = 'id';
          break;
      }
    }

    const list = getDeepValue(parentValue, [isBiglist ? 'biglist' : 'csv']);
    const listArr = list ? list.split(',') : [];
    if (!list) {
      return countryCreatedFilter;
    }

    const filterData = up(excludeClubs) === up(ListActionTypes.EXCLUDE) ? { search: list.replace(/,/g, '|'), exclude: true } : { selected: listArr };

    const filter = {
      ...(countryCreatedFilter || {}),
      [field]: {
        ...filterData,
        data: {
          type: FieldTypes.STRING
        }
      }
    }
    return filter;
  }

  function renderSelect(selected) {

    if (biglistStatus) {
      if (biglistStatus === BlobProcessStatus.FAILED) {
        return <Typography className={classes.errorText}>Biglist Processing Failed</Typography>;
      } else if (biglistStatus !== BlobProcessStatus.COMPLETED) {
        return <Typography>Biglist Processing...</Typography>;
      }
    }
    if (selected && selected.length > 0) {
      const labelKey = displayPropValue || 'id';
      return (
        <div className={classes.chips}>
          {selected.slice(0, 50).map(value => {
            const label = fullValue && typeof value === 'object' ? value[labelKey] : value;
            const id = fullValue && typeof value === 'object' ? value.id : value;
            return (
              <Chip data-testid='Chip' disabled={props.disabled} key={id} label={label} className={classes.chip} onDelete={searchType !== SearchTypes.MEMBER ? () => handleDelete(id) : null} />
            )
          })}
        </div>
      );
    } else {
      return <span className={classes.placeholderText}>{placeholder}</span>
    }
  }

  function renderListComponent(searchType, subSearchType, isTable) {
    if (low(mixType) === TemplateMixTypes.BIGLIST) {
      if (tabProps.uploadCsv) {
        return (
          <BigListUpload
            type={low(searchType)}
            isInput
            setEnableAction={setEnableAction}
          />
        )
      }
    }

    const { addList, removeList } = getBiglistEditValues(editListValue);

    let newFullValue = fullValue && fullValue.filter((val) => {
      if (!editListValue || !editListValue[val.id]) {
        return true;
      }
      return editListValue[val.id] !== 'remove'
    });
    if (dateEnabled && fullValue) {
      newFullValue = newFullValue.map(item => {
        return {
          ...item,
          date: {
            start: { value: dateFormat(item.startDate, 'MM/DD/YYYY h:mm:ss a') },
            end: { value: dateFormat(item.endDate, 'MM/DD/YYYY h:mm:ss a') }
          }
        }
      });
    }

    const searchActionCompartments = queries && queries.searchActions && queries.searchActions.split(',');
    const listProps = {
      ...tabProps,
      ...props,
      key: tabIndex,
      isModal: false,
      ref: childSearch,
      searchType,
      handleFlag,
      subSearchType,
      callSave: isTable ? () => handleSave({ type: 'replace' }) : null,
      queries,
      parentSelected: biglistContinue ? (addList && addList.length > 0 ? addList : null) : value,
      parentSelectedFullValue: newFullValue,
      parentUnselected: removeList && removeList.length > 0 ? removeList : null,
      parentBiglistName: isReplace ? null : biglistContinue,
      setEnableParentAction: setEnableAction,
      sideSpace: isTable ? 8 : null,
      parentFilter: createFilter(tabProps.isBiglist),
      noSearchBar: tabProps.noSearchBar,
      actionWidth: dateEnabled ? 525 : undefined,
      setSelectError,
      tableNoteFieldCount: tabProps.tableNoteFieldCount,
      searchActionCompartments: searchActionCompartments
    };
    if (queries && queries.disableMultiSelect) {
      listProps['disableSelectAll'] = true;
      listProps['singleSelect'] = true;
    }

    // Handle "Saved List" selection
    if (low(mixType) === TemplateMixTypes.BIGLIST) {
      listProps.disableSelectAll = true;
      listProps.singleSelect = true;
      listProps.queries = null;

      listProps.searchType = SearchTypes.BIGLIST;
      listProps.subSearchType = 'name'

      listProps.parentFilter = {
        type: { data: { type: "STRING" }, selected: [searchType, low(searchType), up(searchType)] },
        status: { data: { type: "STRING" }, selected: [BlobProcessStatus.COMPLETED] },
      }
      listProps.parentSort = { property: 'lastModified', orderBy: 'desc', type: 'DATE' }
      listProps.parentSelected = [biglistName];
      listProps.parentSelectedFullValue = null;
      listProps.parentUnselected = null;
      return <Search {...listProps} multiSelect />;
    }

    switch (up(searchType)) {
      case SearchTypes.CLUB:
        return <ClubList {...listProps} noDate={!dateEnabled} />;
      case SearchTypes.ITEM:
        if (fromViewOnly && tabProps && tabProps.tabType === MixTabTypes.VIEW) {   // Render SearchNoIndex component to view items on view tab
          return renderSearchNoIndex(listProps);
        }
        return <ItemList {...listProps} />;
      case SearchTypes.USERS:
        return <UserList {...listProps} />
      case SearchTypes.COUPON:
        if (getDeepValue(queries, ['type']) === getCompartmentTypes.COUPONCODE) {
          return <SearchNoIndex {...listProps} multiSelect subtype={queries.type} subSearchType={queries.subSearchType} />
        } else {
          return <Search {...listProps} multiSelect />;
        }
      case SearchTypes.TERMSANDCONDITIONS:
        return <Search {...listProps} multiSelect />;

      default:
        return <Search {...listProps} multiSelect />;
    }
  }

  function renderSearchNoIndex(listProps) {
    const viewListProps = {
      ...listProps,
      noAdvanced: true,
      multiSelect: false,
      searchType: searchType,
      biglistName: biglistName,
      fromViewOnly
    }
    return <SearchNoIndex {...viewListProps} />
  }

  function renderTabs(searchType) {
    if (tabs && tabs.length > 1) {
      return (
        <div className={classes.tabs}>
          <Tabs
            classes={{
              root: classes.tabsRoot,
              indicator: classes.indicator
            }}
            value={tabIndex}
            onChange={handleChangeTabs}
            indicatorColor='secondary'
            textColor='secondary'
            variant='fullWidth'
            aria-label='list tabs'
          >
            {tabs.map(tab => tab ? tab.component : null)}
          </Tabs>
        </div>
      )
    }
  }

  function renderModalAction({ label, props = {} }) {
    return (
      <CustomButton
        key={label}
        className={classes.actionButtons}
        variant='contained'
        color='secondary'
        {...props}
      >
        {label}
      </CustomButton>
    )
  }

  function renderModal(searchType, subSearchType) {
    const disabled = loadingData;
    const cancelAction = { label: 'Cancel', props: { onClick: handleClose, variant: 'text', style: { marginRight: 'left' }, disabled } };
    const actions = low(mixType) === TemplateMixTypes.CSV && low(parentMixType) === TemplateMixTypes.CSV ? [
      cancelAction,
      {
        label: (value ? MixTypeLabels.UPDATE : MixTypeLabels.ADD) + (loadingData ? MixTypeLabels.LOADING : ''),
        props: { onClick: () => handleSave({ type: 'update' }), disabled: !enableAction || disabled, 'data-testid': 'forCsvType' }
      },
      ...(selectError ? [{
        label: 'Continue ' + (value ? MixTypeLabels.UPDATE : MixTypeLabels.ADD) + (loadingData ? MixTypeLabels.LOADING : ''),
        props: { onClick: () => handleSave({ type: 'update', bypassError: true }), disabled: !enableAction || disabled }
      }] : [])
    ] : low(mixType) === TemplateMixTypes.CSV && low(parentMixType) === TemplateMixTypes.BIGLIST ? [
      cancelAction,
      ...(biglistContinue ? [
        {
          label: (value ? MixTypeLabels.UPDATE : MixTypeLabels.ADD) + (loadingData ? MixTypeLabels.LOADING : ''),
          props: { onClick: () => handleSave({ type: 'update' }), disabled: !enableAction || disabled, 'data-testid': 'addSelected' }
        }
      ] : [])
    ] : fromViewOnly ? [cancelAction // Dont select the items when user navigates from view screen
    ] : [
      cancelAction,
      {
        label: MixTypeLabels.ADD_BIGLIST + (loadingData ? MixTypeLabels.LOADING : ''),
        props: { onClick: () => handleSave({ type: 'add' }), disabled: (tabProps.uploadCsv ? readyFiles.length <= 0 : !enableAction) || disabled, 'data-testid': 'addBiglist' }
      }
    ]

    const modal = (
      <div className={classes.modalBody} style={{ height: noModal ? '100%' : 'auto' }}>
        {renderTabs(searchType)}
        <div className={classes.listContainer}>
          {renderListComponent(searchType, subSearchType)}
        </div>
        {noModal ? null : (
          <div className={classes.modalActions}>
            {actions.map(renderModalAction)}
          </div>
        )}
      </div>
    );

    if (noModal) {
      return modal;
    }

    return (
      <FullScreenModal
        key={openModal}
        open={openModal}
        onRequestClose={loadingData ? undefined : handleClose}
        title={searchType + ' list'}
      >
        {modal}
      </FullScreenModal>
    );
  }

  if (noModal) {
    return renderModal(searchType, subSearchType)
  }

  if (table) {
    return (
      <div className={classes.tableWrapper} style={{ height: '60vh' }}>
        {renderListComponent(searchType, subSearchType, true)}
      </div>
    )
  }

  const biglistError = biglistStatus && biglistStatus === BlobProcessStatus.FAILED;

  return (
    <div className={classes.root}>
      {
        !fromViewOnly ? (
          <MultilineInput
            {...textInputProps}
            className={classes.input_text}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...(textInputProps.InputProps || {}),
              endAdornment: props.disabled ? null : (
                <InputAdornment position='end' className={classes.buttonAdornment}>
                  <Button onClick={onOpenModal} color='secondary' variant='contained' size='small' disableElevation disabled={biglistStatus && biglistStatus !== BlobProcessStatus.COMPLETED}>Search</Button>
                  {value ? (
                    <Button onClick={onClear} color='secondary' size='small' disableElevation>Clear all</Button>
                  ) : null}
                </InputAdornment>
              )
            }}
            error={biglistError ? 'Biglist processing failed' : props.error}
            select
            SelectProps={{
              open: false,
              multiple: true,
              renderValue: renderSelect,
              displayEmpty: true,
              value: displayedValue && typeof displayedValue === 'object' && displayedValue.length ? displayedValue : [],
              classes: {
                root: classes.selectRoot,
                select: classes.select
              },
              onClick: value && typeof value === 'object' && value.length ? null : (props.disabled || (biglistStatus && biglistStatus !== BlobProcessStatus.COMPLETED) ? null : onOpenModal)
            }}
          >
            <span />
          </MultilineInput>
        )
          : null
      }
      {biglistName ? (
        <Typography className={classes.biglistNameContainer}>
          {`List Name: `}
          <Typography className={classes.biglistName} component='span'>
            {biglistName}
          </Typography>
        </Typography>
      ) : null}
      {value && pullSignId === 'true' && !props.disabled && process.env.REACT_APP_ENABLE_SIGN_ID === 'true' ? (
        <div>
          <CustomButton onClick={fetchSignIdData} disabled={loadingSignId} color='secondary' variant='contained' className={classes.pullSignButton}>
            Add Other Items Sharing Club Signs
          </CustomButton>
        </div>
      ) : null}
      {/* <CustomButton label='Select List' onClick={onOpenModal} className={classes.search_button} /> */}
      {renderModal(searchType, subSearchType)}
    </div>
  );
}

export default forwardRef(CsvInput);
