import React, { useState } from "react";
import { withStyles, Modal, Button, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import styles from "./Template.styles";

const TemplateHyperlink = props => {
  const { classes, field } = props;
  const {
    shortDesc,
    values
  } = field;

  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const path = values.PATH;
  const setPath = /^\//.test(path) ? process.env.PUBLIC_URL + path : path;

  return (
    <div className={classes.hyperlinkContainer}>
      <Button color='secondary' onClick={handleOpen}>{shortDesc}</Button>
      <Modal open={show} onClose={handleClose} className={classes.modal}>
        <div className={classes.modalContentContainer}>
          <iframe title='iframe' src={setPath} height='100%' width='100%' frameBorder='0' />
          <IconButton className={classes.closeButton} onClick={handleClose}><CloseIcon /></IconButton>
        </div>
      </Modal>
    </div>
  );
};
export { TemplateHyperlink as TestTemplateHyperlink }
export default withStyles(styles)(TemplateHyperlink);
