import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./Template.styles";
import Brand from "../../brand/Brand";
import Colors from '../../../theme/Colors';
// import autoPopulate from '../../../Utils/template/autoPopulate';

const TemplateBrandPreview = props => {
  const { field, classes } = props;
  const {
    shortDesc,
    value
  } = field;

  return (
    <div className={classes.previewContainer}>
      <Brand
        {...value}
        label={shortDesc}
        align='left'
        previewBackgroundColor={Colors.white}
      />
      <Brand
        {...value}
        label={shortDesc}
        labelHidden
        align='left'
        previewBackgroundColor={Colors.grey300}
      />
      <Brand
        {...value}
        label={shortDesc}
        labelHidden
        align='left'
        previewBackgroundColor={Colors.black}
      />
    </div>
  );
};

export default withStyles(styles)(TemplateBrandPreview);
