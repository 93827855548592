import ReportIcon from '@material-ui/icons/Report';
import InfoIcon from '@material-ui/icons/Info';
import { Dialog } from '@material-ui/core';
import getCompartmentTypes from '../Utils/compartments/getCompartmentTypes';
import Colors from '../theme/Colors';

export const low = string => typeof string === 'string' ? string.toLowerCase() : string;
export const up = string => typeof string === 'string' ? string.toUpperCase() : string;
export const couponsAvailable = item => Math.round(Math.pow(31, item.couponSuffixLength) / 100) - (item.couponGeneratedCount || 0);
export const isTrue = value => value === true || low(value) === 'true';


export const Status = {
  READY: 'Ready',
  LOADING: 'Loading',
  COMPLETE: 'Complete',
  FAILURE: 'Failure'
}

export const BlobTypes = {
  ITEM: 'item',
  MEMBER: 'member',
  CLUB: 'club',
  COUPON: 'coupon',
  OFFER: 'offer',
  CLUB_OVERRIDE: 'cluboverride',
  BATCH: 'batch',
  SUC_EXPORT:'suc export',
  PROMO_CLONE:'promo_clone_files'
}

export const BlobActions = {
  APPEND: 'append',
  DELETE: 'delete',
  REPLACE: 'replace',
  NEW: 'upload',
  DELETE_BIGLIST: 'delete_biglist',
  EXIST: 'exist', // used for check if file already exists
}

export const BlobActionsLabels = {
  APPEND: 'Append',
  DELETE: 'Delete',
  REPLACE: 'Replace',
  NEW: 'New'
}

export const FILES = {
  DOWNLOAD_PATH: 'batch/file',
  TPR_SAMPLE: 'TPR SAMPLE',
  TYPE: 'file',
  TPR_SAMPLE_EXTENSION: 'xlsm'
}

export const MembershipTypes = {
  SAVINGS: 'SAVINGS',
  SAVINGSPLUS: 'SAVINGSPLUS',
  BUSINESS: 'BUSINESS',
  BUSINESSPLUS: 'BUSINESSPLUS',
  ALL: "ALL"
}

export const OfferLabels = {
  PURPOSE_MARKETING : "purpose_marketing",
  PURPOSE_MERCH : "purpose_merch",
  PURPOSE_MEMBERSHIP : "purpose_membership",
  PURPOSE_SUPPLIER : "purpose_supplier"
}

export const CampaignTypes = {
  BROADREACH: 'BROADREACH'
}

export const ExportTypes = {
  EXPORT_BY_ITEM : "export_by_item",
  EXPORT_BY_OFFER : "export_by_offer"
}

export const BlobDelimiter = '_'
export const BlobDelimiterReplace = '';
export const BlobNameCharacterWhitelist = new RegExp('([^a-zA-Z\\d\\ \\-])','g');
export const dateConversionFormat= 'YYYY-MM-DD, HH:mm:ss';

export const FieldTypes = {
  NUMBER: 'NUMBER',
  STRING: 'STRING',
  ENUM: 'ENUM',
  MIX: 'MIX',
  DATE: 'DATE',
  DATE2: 'DATETIMEOFFSET',
  COMPARISON: 'COMPARISON',
  SEARCH: 'SEARCH',
  BOOLEAN: 'BOOLEAN',
  MULTI: 'MULTI',
  LABEL: 'LABEL',
  BRAND: 'BRAND',
  BRAND_PREVIEW: 'BRAND_PREVIEW',
  COLOR: 'COLOR',
  DATA: 'DATA',
  NOTE: 'NOTE',
  BUTTON: 'BUTTON',
  RADIO: 'RADIO',
  TABLE: 'TABLE',
  HYPERLINK: 'HYPERLINK',
  STATUS: 'STATUS',
  TIME: 'TIME',
  COMPLEX: 'COMPLEX',
  DIVIDER: 'DIVIDER',
  HTML_EDITOR: 'HTML_EDITOR',
  NORMAL_EDITOR: 'NORMAL_EDITOR',
  EDITOR_PREVIEW_HTML: 'EDITOR_PREVIEW_HTML',
  EDITOR_PREVIEW_TEXT: 'EDITOR_PREVIEW_TEXT',
  EDITOR_HTML: 'EDITOR_HTML',
  EDITOR_TEXT: 'EDITOR_TEXT',
  PROMO_LABEL: 'PROMO_LABEL',
  IMAGE_PREVIEW: 'IMAGE_PREVIEW',
  MESSAGE_PREVIEW: 'MESSAGE_PREVIEW',
}

export const BlobProcessStatus = {
  LOADING: 'LOADING',
  COMPLETED: 'COMPLETED',
  STALE: 'STALE',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  REQUESTED: 'REQUESTED',
  STARTED: 'STARTED',
  READY: 'READY',
  DOWNLOADED: 'DOWNLOADED',
  COMPLETED_WITH_ERRORS:'COMPLETED_WITH_ERRORS'
}

export const DownloadingStatuses = [
  BlobProcessStatus.STALE,
  BlobProcessStatus.FAILED,
  BlobProcessStatus.CANCELLED,
  BlobProcessStatus.REQUESTED,
  BlobProcessStatus.READY,
  BlobProcessStatus.DOWNLOADED
]

export const BlobProcessMeta = {
  [BlobProcessStatus.LOADING]: {
    color: '#9e9e9e',
    label: 'Processing'
  },
  [BlobProcessStatus.STARTED]: {
    color: '#9e9e9e',
    label: 'Processing'
  },
  [BlobProcessStatus.COMPLETED_WITH_ERRORS]: {
    color: '#9e9e9e',
    label: 'Completed With Errors'
  },
  [BlobProcessStatus.COMPLETED]: {
    color: '#66bc15',
    label: 'Completed'
  },
  [BlobProcessStatus.REQUESTED]: {
    color: '#9e9e9e',
    label: 'Processing'
  },
  [BlobProcessStatus.CANCELLED]: {
    color: '#9e9e9e',
    label: 'Cancelled'
  },
  [BlobProcessStatus.STALE]: {
    color: '#9e9e9e',
    label: 'Stale'
  },
  [BlobProcessStatus.DOWNLOADED]: {
    color: '#66bc15',
    label: 'Downloaded'
  },
  [BlobProcessStatus.READY]: {
    color: '#f8b02a',
    label: 'Ready'
  },
  [BlobProcessStatus.FAILED]: {
    color: '#e74517',
    label: 'Failed'
  }
}

export const BOOLEAN_OPERATOR = {
  AND: 'AND',
  OR: 'OR'
}

export const RULES_BOOLEAN = [
  {
    label: BOOLEAN_OPERATOR.AND,
    value:  BOOLEAN_OPERATOR.AND
  },
  {
    label:  BOOLEAN_OPERATOR.OR,
    value:  BOOLEAN_OPERATOR.OR
  }
]

export const CONDITION_TYPES = {
  EVENT: 'event',
  CONDITION: 'condition'
}

export const RULES_BOOLEAN_INIT_VALUE = RULES_BOOLEAN[0].value;

export const CONDITION_FIELD_TYPES = {
  NUMBER: 'NUMBER',
  STRING: 'STRING',
  ENUM: 'ENUM',
  MIX: 'MIX',
  DATE: 'DATE',
  DATE2: 'DATETIMEOFFSET',
  MULTILINE: 'MULTILINE'
}

export const SearchTable = {
  MINIMUM_BATCH_SIZE: 50,
  THRESHOLD: 30,
  LIMIT: 100
}
export const membershipLength = {
  allowedLength:[11, 13, 17]
}

export const FunctionTypes = {
  DIFF: 'DIFF'
}

export const SearchTypes = {
  CLUB: 'CLUB',
  CLUB_OVERRIDE: 'CLUB_OVERRIDE',
  ITEM: 'ITEM',
  COUPON: 'COUPON',
  COUPONGROUPS:'COUPON GROUPS',
  COMPARTMENT: 'COMPARTMENT',
  USERS: 'USERS',
  ROLE: 'ROLE',
  MEMBER: 'MEMBER',
  COUPONGROUP: 'COUPONGROUP',
  ACTION: 'ACTION',
  CAMPAIGN: 'CAMPAIGN',
  PROMOTION: 'PROMOTION',
  OFFERSET: 'OFFERSET',
  ASSIGNEDCOUPON:'ASSIGNED COUPON',
  COUPONCODE:"COUPONCODE",
  CUSTOM:'CUSTOM',
  TERMSANDCONDITIONS: 'TERMSANDCONDITIONS',
  LABEL: 'LABEL',
  EMAILTEMPLATE: 'EMAILTEMPLATE',
  PROMOTIONGROUPS: 'PROMOTIONGROUP',
  GENERICTARGETEDMESSAGE: 'TARGETED_MESSAGING',
  GENERICTARGETEDMESSAGEBYSLOT: 'TARGETED_MESSAGING_BY_SLOT',
  BIGLIST: 'BIGLIST',
  DOWNLOAD: 'DOWNLOAD',
}
export const ErrorTypes = {
  NO_ORG: 'NO_ORG',
  MISSING_SUPPLIER_ID: 'MISSING_SUPPLIER_ID',
}
export const MessageConditions = {
  CHECK_REQUIRED_CHANNEL: 'CHECK_REQUIRED_CHANNEL',
  CHECK_IF_LIVE_OFFER: 'CHECK_IF_LIVE_OFFER',
  CHECK_REQUIRED_CHANNEL_FOR_TRIGGER_TYPE: "CHECK_REQUIRED_CHANNEL_FOR_TRIGGER_TYPE",
  CHECK_CLUBS_FOR_VENDOR_FUNDED: 'CHECK_CLUBS_FOR_VENDOR_FUNDED',
  CHECK_IF_NO_LIMIT_FOR_BASKET_OFF: 'CHECK_IF_NO_LIMIT_FOR_BASKET_OFF',
  CHECK_IF_COUPON_OR_MTL_OR_BARCODE: 'CHECK_IF_COUPON_OR_MTL_OR_BARCODE',
  CHECK_IF_LIMIT_FOR_ALCOHOL_DOLLAR_OFF: 'CHECK_IF_LIMIT_FOR_ALCOHOL_DOLLAR_OFF',
  CHECK_IF_AWARD_VALUE_FOR_JOIN_MEMBERSHIP: 'CHECK_IF_AWARD_VALUE_FOR_JOIN_MEMBERSHIP',
  CHECK_OFFERSET_DISCOUNT_BUCKET: 'CHECK_OFFERSET_DISCOUNT_BUCKET',
  CHECK_MIN_DOLLAR_OFF_FOR_BASKET_OFFER: 'CHECK_MIN_DOLLAR_OFF_FOR_BASKET_OFFER',
  CHECK_IF_SAVING_CLUB_NOT_SAVING_PLUS: 'CHECK_IF_SAVING_CLUB_NOT_SAVING_PLUS',
  CHECK_IF_BUSINESS_CLUB_NOT_BUSINESS_PLUS: 'CHECK_IF_BUSINESS_CLUB_NOT_BUSINESS_PLUS',
  CHECK_IF_SAVING_CLUB_NOT_BUSINESS_CLUB: 'CHECK_IF_SAVING_CLUB_NOT_BUSINESS_CLUB',
  CHECK_IF_SAVING_PLUS_NOT_BUSINESS_PLUS: 'CHECK_IF_SAVING_PLUS_NOT_BUSINESS_PLUS',
  CHECK_OFFERSET_DISCOUNT_BUCKET_WITH_NO_DISCOUNT: 'CHECK_OFFERSET_DISCOUNT_BUCKET_WITH_NO_DISCOUNT',
  CHECK_OFFERSET_DISCOUNT_BUCKET_HAS_SAME_DISCOUNT_VALUES: 'CHECK_OFFERSET_DISCOUNT_BUCKET_HAS_SAME_DISCOUNT_VALUES',
  CHECK_IF_DISCOUNT_BUCKET_LOWER_THAN_LIST_PRICE: 'CHECK_IF_DISCOUNT_BUCKET_LOWER_THAN_LIST_PRICE',
  CHECK_CAMPAIGN_HAVING_EMPTY_CLUB: 'CHECK_CAMPAIGN_HAVING_EMPTY_CLUB',
  CHECK_COUPON_INFINITY_DATE: 'CHECK_COUPON_INFINITY_DATE',
  CHECK_CAMPAIGN_HAVING_PR_CLUBS: 'CHECK_CAMPAIGN_HAVING_PR_CLUBS',
  CHECK_CRAZY_LOW_PRICE_PERCENTAGE_OFF_ITEMS: 'CHECK_CRAZY_LOW_PRICE_PERCENTAGE_OFF_ITEMS',
  CHECK_CRAZY_LOW_PRICE_DOLLAR_OFF_TPR_TEMPLATE_ITEMS: 'CHECK_CRAZY_LOW_PRICE_DOLLAR_OFF_TPR_TEMPLATE_ITEMS',
  CHECK_IF_NO_LABELS: 'CHECK_IF_NO_LABELS',
  CHECK_IF_EXACT_ONE_PURPOSE_LABEL: 'CHECK_IF_EXACT_ONE_PURPOSE_LABEL',
  CHECK_OFFERSET_DATE_RANGE: 'CHECK_OFFERSET_DATE_RANGE',
  CHECK_ITEM_DISCOUNT_AND_SAMS_CASH_AWARDS: 'CHECK_ITEM_DISCOUNT_AND_SAMS_CASH_AWARDS',
  CHECK_ORDER_DISCOUNT_AND_SAMS_CASH_AWARDS: 'CHECK_ORDER_DISCOUNT_AND_SAMS_CASH_AWARDS',
  CHECK_SAMS_CASH_PLUS_REWARDS_FOR_NONPLUS_MEMBERS: 'CHECK_SAMS_CASH_PLUS_REWARDS_FOR_NONPLUS_MEMBERS',
  CHECK_SAMS_CASH_AWARD_AMOUNT_MORE_THAN_35: 'CHECK_SAMS_CASH_AWARD_AMOUNT_MORE_THAN_35',
  CHECK_SAMS_CASH_AWARD_AMOUNT_MORE_THAN_25: 'CHECK_SAMS_CASH_AWARD_AMOUNT_MORE_THAN_25',
  CHECK_SAMS_CASH_AWARD_AMOUNT_MORE_THAN_CHEAPEST_ITEM: 'CHECK_SAMS_CASH_AWARD_AMOUNT_MORE_THAN_CHEAPEST_ITEM',
  CHECK_NONFILLED_IRIS_MESSAGE:"CHECK_NONFILLED_IRIS_MESSAGE"
}
export const IconTypes = {
  WARNING: 'WARNING',
  INFO: 'INFO',
}

/*
  !!WARNING!! offer type is NOT template type. Don't introduce bugs with this!
*/
export const OfferTypes = {
  DOLLAR_OFF_BASKET_OFFER: "$Off Basket",
  PERCENT_OFF_BASKET_OFFER: "%Off Basket",
  MEMBERSHIP_OFF_BASKET: "Membership $Off Basket",
  TRIGGER_OFFER: "User Action Trigger / Freeosk",
  PERCENT_OFF_SELECTED_ITEMS: "%off Selected Items",
  DOLLAR_OFF_SELECTED_INCLUDES_ALCOHOL_ITEMS: "$off Selected Includes Alcohol Items",
  DOLLAR_OFF_SELECTED_ITEMS: "$off Selected Items",
  TPR_TEMPLATE: "TPR Template",
  GENERIC_ITEM_OFFER: "Generic Item Offer",
  GENERIC_BASKET_OFFER: "Generic Basket Offer",
  JOIN_MEMBERSHIP: "Join Membership",
  RENEWAL_MEMBERSHIP: "Renewal Membership",
  UPGRADE_MEMBERSHIP: "Upgrade Membership",
  BASKET_OFFER_LIST: ["$Off Basket", "%Off Basket", "Membership $Off Basket", "Generic Basket Offer"],
  BUY_TOGETHER_DOLLAR_OFF: "Buy Together $off",
  BUY_TOGETHER_PERCENT_OFF: "Buy Together %off",
  OPTICALS_BUY_TOGETHER_PERCENT_OFF: "OPTICALS - Buy Together %Off",
  OPTICALS_PERCENT_OFF_SELECTED_ITEMS: "Opticals - %off Selected Items",
}

export const TemplateIds = {
  DOLLAR_OFF_ALCOHOL_ITEMS: '77f4706a-c315-422c-9e6b-c4f010f41d2a',
}

export const PlusMemberShipItems = {
  SAVING_PLUS: "119",
  BUSINESS_PLUS: "117"
}
export const ChannelTypes = {
  CPU: "CPU",
  CLUB:"CLUB",
  D2H: "D2H",
  DFC: "DELIVERY_FROM_CLUB",
  ALL: "ALL",
  SNG:"SCAN_AND_GO",
  CHANNEL_LIST:["CPU","D2H","DELIVERY_FROM_CLUB","SCAN_AND_GO","CLUB"]
}

export const clubChoices = {
  ALL: "ALL",
  PR_CLUBS_ONLY: "STANDARD CLUBS - PUERTO RICO ONLY",
  US_CLUBS_ONLY: "STANDARD CLUBS - US EXCLUDING PUERTO RICO",
  SPECIFIC_CLUBS_ONLY: "CLUBS"
}

export const ItemProps = {
  CAT: 'category',
  SUBCAT: 'subCategory'
}

export const SearchActions = {
  PDF: 'PDF',
  PDF_BULK: 'PDF_BULK',
  MODAL: 'MODAL',
  VIEW: 'VIEW',
  CLONE: 'CLONE',
  EXPORT: 'EXPORT',
  EDIT: 'EDIT',
  SAVEEDIT: 'SAVEEDIT',
  HISTORY: 'HISTORY',
  ADMIN: 'ADMIN',
  WORKFLOW: 'WORKFLOW',
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
  SIMULATE: 'SIMULATE',
  PATH: 'PATH',
  SEARCH_OFFER: 'SEARCH_OFFER',
  DELETE: 'DELETE',
  ASSIGN:'ASSIGN',
  ASSIGN_BULK:'ASSIGN_BULK',
  EDIT_LIMITS:"EDIT_LIMITS",
  GENERATE_COUPONS:"GENERATE_COUPONS",
  SUBMIT_OFFERSETS:"SUBMIT_OFFERSETS",
  BACK_TO_SEARCH:"BACK_TO_SEARCH",
  EXPORT_BIGLIST: 'EXPORT_BIGLIST',
  DOWNLOAD_BIGLIST: 'DOWNLOAD_BIGLIST'
}

export const SearchQueryType = {
  fullText: 'fullText',
}

export const SearchModalActions = {
  DELETE: 'DELETE',
  SEARCHIN: 'SEARCHIN',
  HISTORY: 'HISTORY',
  TEMPLATE: 'TEMPLATE',
  SEARCHFOR: 'SEARCHFOR',
  TABULAR: 'TABULAR',
  EDIT_BIGLIST: 'EDIT_BIGLIST',
}

export const FieldMap = {
  [getCompartmentTypes.BRANDING]: {
    name: {
      shortDesc: 'Name',
      type: FieldTypes.STRING,
      display: true,
      searchable: true
    },
    badge: {
      shortDesc: 'Description',
      type: FieldTypes.STRING,
      display: true,
      searchable: true
    },
    badgeText: {
      shortDesc: 'Badge',
      type: FieldTypes.BRAND_PREVIEW,
      display: true,
      searchable: true
    }
  },
  [getCompartmentTypes.COUPON]: {
    code: {
      shortDesc: 'Code',
      type: FieldTypes.STRING,
      display: true,
      searchable: true
    },
    description: {
      shortDesc: 'Description',
      type: FieldTypes.STRING,
      display: true,
      searchable: true
    },
    isActive: {
      shortDesc: 'Active',
      type: FieldTypes.BOOLEAN,
      display: true,
      searchable: true
    },
    startDate: {
      shortDesc: 'Start Date',
      type: FieldTypes.DATE,
      advancedFilter: true,
      display: true,
      searchable: true
    },
    endDate: {
      shortDesc: 'End Date',
      type: FieldTypes.DATE,
      advancedFilter: true,
      display: true,
      searchable: true
    }
  },
  [SearchTypes.COMPARTMENT]: {
    name: {
      shortDesc: 'Name',
      type: FieldTypes.STRING
    },
    description: {
      shortDesc: 'Description',
      type: FieldTypes.STRING
    }
  },
  [SearchTypes.ROLE.toLowerCase()]: {
    description: {
      shortDesc: 'Name',
      type: FieldTypes.STRING,
      display: true,
      searchable: true
    },
    readOnly: {
      shortDesc: 'Read Only',
      type: FieldTypes.BOOLEAN,
      display: true,
      searchable: true
    }
  }
}

export const TemplateGroupType = {
  DETAILED: 'DETAILED_DATA',
  ASSIGNMENT: 'ASSIGNMENT_DATA',
  CONDITION: 'CONDITION_DATA',
  AWARD: 'AWARD_DATA',
  PARTIAL_MATCH:"PARTIAL_MATCH_DATA",
  HISTORY: 'HISTORY_DATA',
  ACTION: 'ACTION_DATA',
  ITEM_MESSAGE: 'ITEM_MESSAGE_DATA',
  FLAT_MODAL_DATA: 'FLAT_MODAL_DATA',
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  POS: 'POS',
  MWEB: 'MWEB',
  KIOSK: 'KIOSK',
  SALESBRANDING: 'BRANDING_DATA',
  MESSAGE_DATA: 'MESSAGE_DATA',
  IRIS_SLOTS_DATA: 'IRIS_SLOTS_DATA',
  FILTER_DATA: 'FILTER_DATA',
  OFFER_DETAILS: 'OFFER_DETAILS'
}

export const TemplateSpecialGroupType = [
  TemplateGroupType.ASSIGNMENT,
  TemplateGroupType.CONDITION,
  TemplateGroupType.AWARD,
  TemplateGroupType.HISTORY,
  TemplateGroupType.ITEM_MESSAGE,
  TemplateGroupType.PARTIAL_MATCH,
  TemplateGroupType.MESSAGE_DATA,
  TemplateGroupType.IRIS_SLOTS_DATA,
  TemplateGroupType.FILTER_DATA,
  TemplateGroupType.OFFER_DETAILS
]

export const TemplateNonArraySpecial = [
  TemplateGroupType.MOBILE,
  TemplateGroupType.POS,
  TemplateGroupType.KIOSK,
  TemplateGroupType.MWEB,
  TemplateGroupType.DESKTOP,
  TemplateGroupType.SALESBRANDING
]

export const TemplateMixTypes = {
  BIGLIST: 'biglist',
  CSV: 'csv'
}

export const BinCardTypes = {
  VISA: 'VISA',
  AMERICAN_EXPRESS: 'AMERICAN_EXPRESS',
  DISCOVER: 'DISCOVER',
  MASTERCARD: 'MASTERCARD'
}

export const DateFormat = {
  TABLE: 'YYYY-MM-DD HH:mm:ss',
  DATE: 'YYYY-MM-DD',
  TIME: 'HH:MM',
  INDEX: '',
  DATE_PICKER: 'yyyy-MM-dd'
}

export const RootTypes = {
  PROMOTION: 'promotion',
  ADMIN: 'admin',
  BRANDING: 'branding',
  CAMPAIGN: 'campaign',
  BIGLIST: 'biglist',
  COUPON: 'coupon',
  ITEMS: 'items',
  OFFERSET: 'offerset',
  TARGETED_MESSAGING: 'targeted_messaging',
  TARGETED_MESSAGING_BY_SLOT: 'targeted_messaging_by_slot',
}

export const WorkflowDirections = {
  FORWARD: 'FORWARD',
  BACKWARD: 'BACKWARD',
  STATIC: 'STATIC',
  FOLLOW: 'FOLLOW'
}

export const MixTypeLabels = {
  ADD: 'Add selected',
  UPDATE: 'Update selected',
  ADD_BIGLIST: 'Select',
  REMOVE: 'Remove selected',
  LOADING: ' (Loading...)',
  BROWSE: searchType => {
    switch(searchType.toUpperCase()) {
      case(SearchTypes.ITEM):
        return 'Browse Catalog';
      case(SearchTypes.CLUB):
        return 'Browse Clubs';
      case(SearchTypes.MEMBER):
        return '';
      default:
        return 'Browse'
    }
  },
  SEARCH: searchType => {
    switch(searchType.toUpperCase()) {
      case(SearchTypes.ITEM):
        return 'Search Items';
      case(SearchTypes.CLUB):
        return 'Search Clubs';
      case(SearchTypes.MEMBER):
        return 'Search Members';
      default:
        return 'Search'
    }
  },
  BIGLIST: searchType => {
    switch(searchType.toUpperCase()) {
      case(SearchTypes.ITEM):
        return 'Saved Item Lists';
      case(SearchTypes.CLUB):
        return 'Saved Club Lists';
      case(SearchTypes.MEMBER):
        return 'Saved Member Lists';
      default:
        return 'Saved Lists'
    }
  },
  IMPORT: searchType => {
    switch(searchType.toUpperCase()) {
      case(SearchTypes.ITEM):
        return 'Import Item List';
      case(SearchTypes.CLUB):
        return 'Import Club List';
      case(SearchTypes.MEMBER):
        return 'Import Member List';
      default:
        return 'Import List'
    }
  },
  PASTE: searchType => {
    switch(searchType.toUpperCase()) {
      case(SearchTypes.ITEM):
        return 'Enter/Paste Items';
      case(SearchTypes.CLUB):
        return 'Enter/Paste Clubs';
      case(SearchTypes.MEMBER):
        return 'Enter/Paste Memers';
      default:
        return 'Enter/Paste'
    }
  },
  VIEW: searchType => {
    switch(searchType.toUpperCase()) {
      case(SearchTypes.ITEM):
        return 'View first 100 items';
      default:
        return 'View'
    }
  }
}

export const MixTabTypes = {
  BROWSE: 'BROWSE',
  SEARCH: 'SEARCH',
  BIGLIST: 'BIGLIST',
  IMPORT: 'IMPORT',
  PASTE: 'PASTE',
  VIEW: 'VIEW'
}

export const TableOverlayMessage = {
  BROWSE: searchType => { // items only
    return `Please select category or sub category to browse items`;
  },
  SEARCH: (searchType, fieldMap, fieldCount = 5) => {
    const fieldMapDesc = Object.keys(fieldMap).filter(key => {
      const field = fieldMap[key];
      return !Object.keys(TableImageTypes).includes(field.type) && field.searchable
    }).map(key => fieldMap[key].shortDesc).slice(0,fieldCount);

    const noteText = 'Please search by ' + fieldMapDesc.join(', ').replace(/,(?=[^,]*$)/, ' or');

    return noteText;
  },
  BIGLIST: searchType => {
    return '';
  },
  IMPORT: searchType => {
    return 'Please choose file to import';
  },
  PASTE: searchType => {
    return `Please enter or paste ${searchType} number`
  }
}

export const ModalOverlayMessage = {
  EXPORT_LIST: found => {
    if(found) {
      return {
        title: `Background Export Started`,
        text: `You will get a notice when its finished or you can check status and download it from Admin/Tasks`
      }
    } else {
      return {
        title: `Error exporting list`,
        text: `List was not found`
      }
    }
  },
  EDIT_DONE: 'Your changes have started in background.This process can take some time.You can check the status by going to Home Admin / Tasks.',
  BIGLIST_CHECK_ERROR: 'Error while searching in List',
  BIGLIST_CHECK_MESSAGE: type => `An error occured while checking for ${type} in list.Please try again in a while.`,
  BIGLIST_EDIT_ERROR: 'Failed to edit list',
  BIGLIST_EDIT_ERROR_MESSAGE: biglistName => `An error occured while editing ${biglistName}.`,
  BULK_UPLOAD_DONE: 'Your changes have started in background.This process can take some time.You can check the status by going to Saved Lists / View.',
  EXPORT_DEAL_SHEET: (status, failures) => {
    if(status){
      if (failures) {
        return {
          title: "Warning",
          text: `Deal sheet PDF was downloaded successfully. ${failures} offers were not exported`
        }
      }
      return {
        title: "Success",
        text: "Deal sheet PDF was downloaded successfully."
      }
    } else {
      return {
        title: "Error",
        text: "An error encountered while exporting deal sheet. Please try again."
      }
    }
  },
  ASSIGN_COUPON: (status, value) => {
    if (status) {
      return {
        title: "Success",
        text: `Coupons assigned successfully for the member ${value} .`
      }
    } else {
      return {
        title: "Error",
        text: `Assignment unsuccessful for the member ${value} .Please try again .`
      }
    }
  },
  BULK_ASSIGN_COUPON_MEMBERLIST_UPLOAD: (status, value) => {
    if (status) {
      return {
        title: "Success",
        text: `Memberlist ${value.split(".")[0]} has successfully been uploaded.`
      }
    } else {
      return {
        title: "Error",
        text: `There was an error while uploading Memberlist ${value.split(".")[0]}.Please try again.`
      }
    }
  },
  BULK_ASSIGN_COUPON: (status, value) => {
    if (status) {
      return {
        title: "Success",
        text: `Bulk SUC Assignment process successfully started for the member list ${value.split(".")[0]} .`
      }
    } else {
      return {
        title: "Error",
        text: `Bulk SUC Assignment process failed to start for the member list ${value.split(".")[0]} .Please try again.`
      }
    }
  },
  EDIT_LIMIT: (status, value) => {
    if (status) {
      return {
        title: "Success",
        text: `Limit overridden successfully for the Coupon Code ${value} .`
      }
    } else {
      return {
        title: "Error",
        text: "Override was not successful ."
      }
    }
  },
  EXPORT_COUPONS: (status, value) => {
    if (status) {
      return {
        title: "Success",
        text: `The coupon creation and export process has started.
        You will get an email when it is complete.
        You can also check the status by going to 
        Home > Tasks
        `
      }
    } else {
      return {
        title: "Error",
        text: value
      }
    }
  },
  EXPORT_OFFERS_STARTED_MESSAGE: `Your request has been started. Processing takes a few minutes, depending on the number
  of offers. When your Excel file is ready, a popup will appear and allow you to download it.`,
  MISSING_SUPPLIER_INFO_ERROR: errorType => {
    switch (errorType) {
      case (ErrorTypes.NO_ORG):
        return {
          title: "Error",
          text: "This supplier has no organization. This needs to be fixed in Retail Link."
        }
      default:
        return {
          title: "",
          text: ""
        }

    }
  },
  GUARDRAIL_MESSAGES: (type, subtype) => {
    // const { subtype } = otherProps;
    switch (type) {
      case (MessageConditions.CHECK_IF_LIVE_OFFER):
        return {
          warnMsg: "This offer would not take effect in the clubs until tomorrow.",
          errMsg: '',
        }
      case (MessageConditions.CHECK_REQUIRED_CHANNEL):
        return {
          warnMsg: '',
          errMsg: "Basket offer shouldn't have channels other than CLUB and SCAN & GO.",

        }
      case (MessageConditions.CHECK_REQUIRED_CHANNEL_FOR_TRIGGER_TYPE):
        return {
          warnMsg: '',
          errMsg: `Trigger offer shouldn't be reflected in these selected channels . Please select all Channels to publish.`
        }
      case (MessageConditions.CHECK_CLUBS_FOR_VENDOR_FUNDED):
        return {
          warnMsg: '',
          errMsg: `This supllier funded offer will be live in PR clubs.`,

        }
      case (MessageConditions.CHECK_OFFERSET_DISCOUNT_BUCKET):
        return {
          warnMsg: '',
          errMsg: `Please re-enter a discount bucket amount that is lower than your total budget.`,
        }
      case (MessageConditions.CHECK_IF_NO_LIMIT_FOR_BASKET_OFF):
        return {
          warnMsg: `Basket offer has no limits set.`,
          errMsg: '',
        }
      case (MessageConditions.CHECK_IF_COUPON_OR_MTL_OR_BARCODE):
        return {
          warnMsg: '',
          errMsg: `Basket offers must have coupon OR member target list OR barcode.`,
        }
      case (MessageConditions.CHECK_IF_LIMIT_FOR_ALCOHOL_DOLLAR_OFF):
        return {
          warnMsg: `Per regulations, we must offer alcohol promos to non-members, and the clubs do this using the same dummy membership for all of them. Therefore you can't use limits if this offer is to be given to non-members.`,
          errMsg: '',
        }
      case (MessageConditions.CHECK_IF_AWARD_VALUE_FOR_JOIN_MEMBERSHIP):
        return {
          warnMsg: `Are you sure the value of the awards does not exceed $60?`,
          errMsg: '',
        }
      case (MessageConditions.CHECK_MIN_DOLLAR_OFF_FOR_BASKET_OFFER):
        return {
          warnMsg: '',
          errMsg: `Basket offers should have Min Dollar off to be higher than $off Basket value.`,
        }
      case (MessageConditions.CHECK_IF_SAVING_CLUB_NOT_SAVING_PLUS):
        return {
          warnMsg: `You have selected Savings Club-level members but not Savings Plus-level.`,
          errMsg: '',
        }
      case (MessageConditions.CHECK_IF_BUSINESS_CLUB_NOT_BUSINESS_PLUS):
        return {
          warnMsg: `You have selected Business Club-level members but not Business Plus-level.`,
          errMsg: '',
        }
      case (MessageConditions.CHECK_IF_SAVING_CLUB_NOT_BUSINESS_CLUB):
        return {
          warnMsg: `You have selected Savings Club-level members but not Business Club-level.`,
          errMsg: '',
        }
      case (MessageConditions.CHECK_IF_SAVING_PLUS_NOT_BUSINESS_PLUS):
        return {
          warnMsg: `You have selected Savings Plus members but not Business Plus.`,
          errMsg: '',
        }
      case (MessageConditions.CHECK_OFFERSET_DISCOUNT_BUCKET_WITH_NO_DISCOUNT):
        return {
          warnMsg: '',
          errMsg: `Cannot create OfferSet with 0 Discount.`,
        }
      case (MessageConditions.CHECK_OFFERSET_DISCOUNT_BUCKET_HAS_SAME_DISCOUNT_VALUES):
        return {
          warnMsg: '',
          errMsg: `Cannot have two buckets with the same discount amount.`,
        }
      case (MessageConditions.CHECK_CAMPAIGN_HAVING_PR_CLUBS):
        return {
          warnMsg: `PR clubs will be included.`,
          errMsg: '',
        }
      case (MessageConditions.CHECK_IF_DISCOUNT_BUCKET_LOWER_THAN_LIST_PRICE):
        return {
          warnMsg: '',
          errMsg: `Please re-enter a discount bucket amount that is lower than the minimum list price of the items you have selected ($x).`,
        }
      case (MessageConditions.CHECK_CAMPAIGN_HAVING_EMPTY_CLUB):
        return {
          warnMsg: '',
          errMsg: `Empty Club List`,
        }
      case (MessageConditions.CHECK_IF_NO_LABELS):
        return {
          warnMsg: 'Proper labeling is important for several business processes. This {name} has no labels. Publish anyway?',
          errMsg: '',
        }
      case (MessageConditions.CHECK_IF_EXACT_ONE_PURPOSE_LABEL):
        return {
          warnMsg: '',
          errMsg: `Please set exactly one of the labels having a prefix "purpose_"`,
        }
      case (MessageConditions.CHECK_COUPON_INFINITY_DATE):
        switch (subtype) {
          case 'couponGroup':
            return {
              warnMsg: 'This Coupon Group has a fixed end date. Usually the SUC end date should be unset and the end controlled from the promo end date or the Duration. Are you sure you want an end date?',
              errMsg: '',
            }
          case 'couponCode':
            return {
              warnMsg: 'This MUC has a fixed end date. Usually the MUC end date should be unset and the end controlled from the promo end date. Are you sure you want an end date?',
              errMsg: '',
            }
        }
        break;
      case (MessageConditions.CHECK_CRAZY_LOW_PRICE_PERCENTAGE_OFF_ITEMS):
        return {
          warnMsg: `This is a very steep discount. Please confirm.`,
          errMsg: '',
        }
      case (MessageConditions.CHECK_CRAZY_LOW_PRICE_DOLLAR_OFF_TPR_TEMPLATE_ITEMS):
        return {
          warnMsg: `{discounted_amount} is a very steep discount for Item(s) {item_numbers}. Please confirm.`,
          errMsg: '',
        }
      case (MessageConditions.CHECK_OFFERSET_DATE_RANGE):
        return {
          warnMsg: '',
          errMsg: 'Please choose or set a campaign with start dates 15-90 days from today.',
        }
      case (MessageConditions.CHECK_ITEM_DISCOUNT_AND_SAMS_CASH_AWARDS):
        return {
          warnMsg: '',
          errMsg: 'Due to system limitations, it is not possible to choose item discount combined with Sams Cash in one offer. You can make two separate offers if needed.',
        }
      case (MessageConditions.CHECK_ORDER_DISCOUNT_AND_SAMS_CASH_AWARDS):
        return {
          warnMsg: '',
          errMsg: 'Due to system limitations, it is not possible to choose order discount combined with Sams Cash in one offer. You can make two separate offers if needed.',
        }
      case (MessageConditions.CHECK_SAMS_CASH_PLUS_REWARDS_FOR_NONPLUS_MEMBERS):
        return {
          warnMsg: 'You have chosen to award Sams Cash Plus Rewards but your offer can apply to non-plus members.',
          errMsg: '',
        }
      case (MessageConditions.CHECK_SAMS_CASH_AWARD_AMOUNT_MORE_THAN_35):
        return {
          warnMsg: 'Sams Cash amount is more than $35. Please confirm.',
          errMsg: '',
        }
      case (MessageConditions.CHECK_SAMS_CASH_AWARD_AMOUNT_MORE_THAN_25):
        return {
          warnMsg: 'Sams Cash amount is more than 25% of net price. Please confirm.',
          errMsg: '',
        }
      case (MessageConditions.CHECK_SAMS_CASH_AWARD_AMOUNT_MORE_THAN_CHEAPEST_ITEM):
        return {
          warnMsg: 'Sams Cash amount is more than the cheapest item.',
          errMsg: '',
        }
      case (MessageConditions.CHECK_NONFILLED_IRIS_MESSAGE):
        return {
          warnMsg: "",
          errMsg: 'For at least one of the slots , message has to be filled in .',
        }
      default:
        return {
          warnMsg: '',
          errMsg: '',
        }

    }
  },
}

export const DialogSuccessLabel = (sequence, nonWOrkflowOption, type) => {
  // if sequence is not prersent
  if (!isNaN(+sequence) && !nonWOrkflowOption) {
    return Number(sequence) === 3 ? "Publish" : "Ok"
  } else if (nonWOrkflowOption && type) {
    switch (type) {
      case "coupon":
        return "Yes"
    }
  } else {
    //some fallback we can add later
    return "Ok"
  }

}
export const DialogCancelLabel = (sequence, nonWOrkflowOption, type) => {
  // if sequence is not prersent
  if (!isNaN(+sequence) && !nonWOrkflowOption) {
    return "Cancel"
  } else if (nonWOrkflowOption && type) {
    switch (type) {
      case "coupon":
        return "No"
    }
  } else {
    //some fallback we can add later
    return "Cancel"
  }

}

export const IconRenderer = {
  ICON_COMPONENT: (type) => {
    switch (type) {
      case IconTypes.WARNING:
        return { iconName: ReportIcon, color: Colors.primaryOrange }
      case IconTypes.INFO:
        return { iconName: InfoIcon, color: Colors.primaryBlue }

    }
  }
}

export const UserStatus = {
  REQUESTED: "Requested for Access",
  APPROVED: "Approved Access Request",
  DEACTIVATED: "Deactivated User"
}

export const Roles = {
  OPERATIONS: 'ROLE_OPERATIONS',
  MERCHANT: 'ROLE_MERCHANT',
  SUPPLIER: 'ROLE_SUPPLIER',
  SUPERADMIN: 'ROLE_SUPERADMIN',
  READONLY: 'READONLY',
  UNASSIGNED: 'ROLE_UNASSIGNED'
}

export const RoleIds = {
  UNASSIGNED: '096ab0ca-f840-4a21-8fdf-c8b673374645'
}

export const TemplateSpecialGroupFieldName = TemplateSpecialGroupType.map(item => low(item));

export const SEARCH_MAX_LENGTH = 256;

export const CSV_BIGLIST_LIMIT = 50;

export const CLUB_BIGLIST_LIMIT = 700;

export const BIGLIST_EDIT_LIMIT = 100;

export const DISPLAY_LIMIT = 10;

export const LAST_STEP_PROMO = 'METADATA';

export const ERROR_OVERLAY = {
  TITLE: 'Something went wrong!',
  MESSAGE: 'Please try again later.'
}

export const Regulatory = {
  TOBACCO: 'TOBACCO',
  ALCOHOL: 'ALCOHOL',
  OTHER: 'OTHER'
}

export const TableImageTypes = {
  REGULATORY: 'REGULATORY',
  IMAGE: 'IMAGE'
};

export const TemplateSpecialCaseNames = {
  CLUB_ASSIGN_LIST: 'club_assignment_list',
  CLUB_OVERRIDE: 'club_override',
  AWARD_COUPON_GROUP: 'award_coupon_group'
};

export const ListActionTypes = {
  EXCLUDE: 'True',
  OVERRIDE: 'False'
};

export const workflowSequence = {
  BACKWARD_EDIT_WORKFLOW_SEQ: -1,
  FIRST_WORKFLOW_SEQ: 0,
  LAST_WORKFLOW_SEQ: 3
}

export const LinkedBiglistFields = {
  promotion: 'award_data/item_award_conditions/item_list,condition_data/item_conditions/item_list,assignment_data/club_assignment/club_assignment_list,assignment_data/club_override/club_override_list,assignment_data/member_assignment/member_list',
  campaign: 'assignment_data/club_assignment/club_assignment_list,assignment_data/club_override/club_override_list,assignment_data/member_assignment/member_list'
}

export const notAvailable = 'Not Available'

export const backActions = {
  BACK_TO_SEARCH: 'Back to Search',
  BACK_TO_VIEW: 'Back to View',
  BACK_TO_TEMPLATE: 'Back to Template'
}

export const azureSearchDefaultData = {'@odata.count': 0, value: []}
export const StringFilterTypes = {
  NAME: 'NAME',
  LABEL: 'LABEL',
  USER: 'USER',
}
export const NameWhitelist = /([^a-zA-Z\d-_.])/g;
export const LabelWhitelist = /([^a-zA-Z\d-_.])/g;
export const UserWhitelist = /([^ (\p{L})(\p{M})(\p{N})(\p{P})])/gu;

export const userSwitchOptions = {
  'ALL_OFFERS': 'All Offers',
  'MY_OFFERS': 'My Offers'
}

export const userSwitchValues = {
  ALL_OFFERS: 'ALL_OFFERS',
  MY_OFFERS: 'MY_OFFERS'
}

export const ContentTypes = {
  TEXT: 'text',
  HTML: 'html'
}

export const EditorOptions = {
  FONT_SIZE: ['Smaller', 'Small', 'Medium', 'Large', 'Larger'],
  TOOLBAR_OPTIONS: ['inline','colorPicker', 'fontSize'],
  INLINE_OPTIONS: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript']
}

export const validationMessages = {
  BULK_UPLOAD_ERROR: 'File Name can not have underscores(_) in it. Please rename and upload.',
  UPLOAD_DUPLICATE_ERROR: 'File Name should be unique.'
}

export const trueValues = [0, false]

export const NameKeys = {
  [getCompartmentTypes.PROMOTION]: 'promotion_name',
  [SearchTypes.PROMOTION]: 'promotion_name',
  [getCompartmentTypes.CAMPAIGN]: 'campaign_name',
  [SearchTypes.CAMPAIGN]: 'campaign_name',
  [getCompartmentTypes.OFFERSET]: 'offerset_name',
  [SearchTypes.OFFERSET]: 'offerset_name',
  [getCompartmentTypes.TARGETED_MESSAGING_BY_SLOT]: 'targeted_message_name',
  [SearchTypes.GENERICTARGETEDMESSAGEBYSLOT]: 'targeted_message_name',
}

export const SESSION_WARNING_TIME = 300;

export const ScheduleOptions = {
  DURING: 'during_sale',
  PRECEEDING: 'preceeding_week',
  CUSTOM: 'custom'
};

export const SubmitOffersetOptions = {
  CANCEL: 'CANCEL',
  SUBMIT: 'SUBMIT',
};

export const AwardTypes = {
  ITEM_DISCOUNT: 'ITEM_DISCOUNT',
  ORDER_DISCOUNT: 'ORDER_DISCOUNT',
  SAMS_CASH: 'SAMS_CASH'
}

export const AwardDataKeys = {
  ITEM_DISCOUNT_AWARD: 'item_discount_award',
  ORDER_DISCOUNT_AWARD: 'order_discount_award',
  SAMS_CASH_AWARD: 'sams_cash_award',
}

export const OrderDiscountAwardKeys = {
  ORDER_DISCOUNT_AMOUNT: 'order_discount',
  ORDER_DISCOUNT_UNIT: 'order_discount_unit',
}

export const OrderDiscountUnits = {
  DOLLAR_OFF: '$off',
  PERCENT_OFF: '%off',
}
export const SamsCashAwardKeys = {
  SAMS_CASH_FUNDING: 'sams_cash_funding',
  SAMS_CASH_AMOUNT: 'sams_cash_amount',
  SAMS_CASH_UNIT: 'sams_cash_unit',
}

export const SamsCashFundingTypes = {
  SAMS_CASH_FUNDING_PLUS_REWARDS: 'PLUS REWARDS',
}

export const SamsCashUnits = {
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  PERCENT: 'PERCENT',
}
export const CouponChoices = {
  NO_COUPON: "NO_COUPON"
}

export const TIMEZONE_REGION = {
  'AST': 'America/Puerto_Rico',
  'EST': 'America/New_York',
  'CST': 'America/Chicago',
  'MST': 'America/Denver',
  'PST': 'America/Los_Angeles',
  'AKST': 'America/Anchorage',
  'HST': 'Pacific/Honolulu',
}
