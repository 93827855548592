import React from "react";
import { withStyles } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import styles from './ReorderActions.styles';
import { reducerActions as templateActions } from '../../compartments/templates/templateReducer';

function ReorderActions(props) {
  const { groupData, classes, fullData, mode, dispatch } = props
  const totalFields = fullData[groupData.metadata.name]['fields'].length

  const reorderIndexInGroup = (direction) => {
    dispatch({ type: templateActions.REORDER_GROUP_INDEX, data: { group: groupData, direction } });
  }

  if(groupData.metadata.reOrder && mode !== 'viewOnly' && totalFields > 1) {
    if(groupData.groupIndex === 0) {
      return (
        <div className={classes.reOrderActions}>
          <ArrowDropDownIcon className={classes.reOrderFontSize} onClick={() => reorderIndexInGroup('down')} />
        </div>
      )
    }
    if(groupData.groupIndex === totalFields-1) {
      return (
        <div className={classes.reOrderActions}>
          <ArrowDropUpIcon className={classes.reOrderFontSize} onClick={() => reorderIndexInGroup('up')} />
        </div>
      )
    } else {
      return (
        <div className={classes.reOrderActions}>
          <ArrowDropDownIcon className={classes.reOrderFontSize} onClick={() => reorderIndexInGroup('down')} />
          <ArrowDropUpIcon className={classes.reOrderFontSize} onClick={() => reorderIndexInGroup('up')} />
        </div>
      )
    }
  }

  return null;
}

export default withStyles(styles)(ReorderActions);
