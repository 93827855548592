const styles = () => ({
  root: {
    padding: 0,
    boxSizing: 'border-box',
    display: 'flex'
  },
  fields: {
    padding: '8px 16px',
    flexGrow: 1
  },
  header: {
    padding: '3px 16px',
    backgroundColor: '#f5f5f5',
    textTransform: 'uppercase'
  },
  groupDesc: {
    minWidth: 120,
    maxWidth: 120
  },
  groupDescLabel: {
    marginRight: 'auto',
    fontWeight: '500',
    fontSize: 12
  },
  editdelete: {
    display: "inline-block"
  },
  button: {
    display: 'inline-block',
    width: 120,
    height: 20,
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "center",
    color: "#2a52be"
  },
  divider: {
    margin: 8,
    height: 1,
    borderRadius: 4
  },
  formGroup: {
    '&:not(:first-child)': {
      marginTop: 8
    },
    scrollSnapAlign: 'center'
  },
  paper: {
    height: '100%',
    width: '100%',
    display: 'block',
    boxSizing: 'border-box',
    boxShadow: '0 2px 14px 0 rgba(182, 182, 182, 0.5)',
    borderRadius: 0
  },
  hide: {
    display: 'none'
  }
});

export default styles;
