/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, forwardRef, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import TemplateGroup from '../../widgets/templates/TemplateGroup';
import useStyles from './TemplateCompartment.style';
import { BodySkeleton } from '../../widgets/progress/PageSkeleton';
import { GET_TEMPLATE_BY_ID, GET_BULK_TEMPLATE_BY_ID } from '../../queries/template-querie';
import { TemplateGroupType, TemplateSpecialGroupType, FieldTypes } from '../../constants/Constants';
import ScrollUpButton from '../../widgets/buttons/ScrollUpButton';
import scrollTo from '../../Utils/scrollWindow';
import TemplateNav from '../../widgets/templates/TemplateNav';
import createExecutePayload from '../../Utils/template/createExecutePayload';
import { reducer as templateReducer, init as templateInit, reducerActions as templateActions, getFunctionValues } from './templateReducer';
import getDeepValue from '../../Utils/getDeepValue';
import { templateFuncCheck, functions, parseFunctionParams } from '../../Utils/template/templateFieldFunction';
import CustomButton from '../../widgets/buttons/CustomButton/CustomButton';
import { extractFunctions, functions as viewFieldFunctions } from '../../Utils/view/viewFieldFunction';
import useGetPersistence from '../../hooks/persistence/useGetPersistence';
import useSetPersistence from '../../hooks/persistence/useSetPersistence';


const TemplateCompartment = (props, ref) => {
  React.useImperativeHandle(ref, () => ({
    async callChild(ignoreErrors, itemName) {
      return await createOnClick(ignoreErrors, itemName);
    }
  }));

  const elRef = React.useRef(null);

  const { data, mode, lhs, parentTemplate, scrollUpOffset = 0, loading: loadingProps, validateErrors, rootType, clone, setTemplateMetadata, viewFromSearch, history, historicalData , isContinue, user, setLoadingTemplate, workflowItem, setTemplateModalData, templateModal, parentData, modalVariant, sameScreenModal } = props;

  const templateDataFromServer = data && mode === 'diff' ? (lhs ? data.lhs : data.rhs) : data;
  const templateId = props && props.templateId ? props.templateId : '';
  const parentTemplateData= parentData && parentData.parentTemplateData
  const hasMultipleTemplateId = Array.isArray(templateId);
  const [shownGroup, setShownGroups] = React.useState(null);
  const [showErrors, setShowErrors] = useState(validateErrors ? true : false);
  const [formChanged, setFormChanged] = useState(false);
  const classes = useStyles();
  const [, setHasServerValue] = useState(false);
  const persistenceKey = 'template-' + templateId;
  const persistedDataState = useGetPersistence(persistenceKey); // Get persisted values;
  const [dataState, dispatch] = useReducer(templateReducer, persistedDataState);
  useSetPersistence(persistenceKey, dataState) // set persisted values;

  useEffect(() => {
    function onBeforeUnload(e) {
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }

    if (formChanged) {
      window.addEventListener('beforeunload', onBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', onBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [formChanged])

  useEffect(() => {
    if (parentTemplate) {
      if (dataState) {
        // Data state is created, we do not need to create again
        return;
      }
      dispatch({
        type: templateActions.SET_TEMPLATE_DATA,
        data: {
          template: parentTemplate,
          serverData: templateDataFromServer,
          errors: validateErrors,
          viewFromSearch,
          historicalData: historicalData,
          workflowItem,
          parentData
        }
      });
    }
  }, [parentTemplate, templateDataFromServer, validateErrors, mode, viewFromSearch, historicalData]);

  const { loading: loadingSingle, error: errorSingle } = useQuery(GET_TEMPLATE_BY_ID, {
    skip: parentTemplate || !templateId || hasMultipleTemplateId,
    onCompleted: handleOnTemplateComplete,
    variables: { templateId: templateId }
  });

  const [
    getBulkTemplate,
    { loading: loadingBulk, error: errorBulk }
  ] = useMutation(GET_BULK_TEMPLATE_BY_ID, {
    onCompleted: handleOnBulkTemplateComplete
  });

  const loading = loadingSingle || loadingBulk;
  const error = errorSingle || errorBulk;

  useEffect(() => {
    if (setLoadingTemplate) {
      setLoadingTemplate(loading);
    }
  }, [loading]);

  useEffect(() => {
    if (hasMultipleTemplateId && templateId && templateId.length) {
      const ids = templateId.join(',');
      getBulkTemplate({
        variables: {
          input: {
            queries: {
              ids
            }
          }
        }
      })
    }
  }, []);

  function handleOnBulkTemplateComplete(res) {
    const { template } = res;
    if (template && template.payload) {
      const items = template.payload;
      const mergedTemplate = templateId.reduce((merged, tempId) => {
        const temp = items.find(temp => temp.id === tempId);
        if (!temp) {
          return merged;
        }
        if (!merged) {
          return temp;
        }
        return {
          ...merged,
          metadata: {
            ...merged.metadata,
            ...temp.metadata,
            groups: [
              ...merged.metadata.groups,
              ...temp.metadata.groups
            ]
          }
        };
      }, null);
      handleOnTemplateComplete({
        template: {
          payload: mergedTemplate
        }
      });
    }
  }

  async function handleOnTemplateComplete(res) {
    const { template } = res;
    if (template && template.payload) {
      const { payload } = template;

      if (templateDataFromServer && templateDataFromServer.values) {
        setHasServerValue(true);
      }

      // check for initial function that has service call
      const newMetadata = await metadataInitCall(payload, templateDataFromServer);

      // set to parent
      if (setTemplateMetadata) {
        setTemplateMetadata(payload);
      }

      if (dataState) {
        return;
      }

      dispatch({
        type: templateActions.SET_TEMPLATE_DATA,
        data: {
          template: newMetadata,
          serverData: templateDataFromServer,
          errors: validateErrors,
          clone,
          viewFromSearch,
          historicalData,
          workflowItem,
          parentData
        }
      });
    }
  }

  async function getParsedResult(functionValuesArray, group, serverData, field, initRes) {
    if(functionValuesArray) {
      let newField = field;
      let initHideGroup;
      let initReadableGroup;
      for (const functionValues of functionValuesArray) {
        if (functionValues && functionValues.fieldKey && templateFuncCheck.checkSetOnInit(functionValues.fieldKey)) {
          const { funcName, params, fieldKey: fieldKeySpecial } = functionValues;
          const fieldKey = fieldKeySpecial.replace(/[^\w\s]/gi, '');

          const { name: groupName } = group;
          const serverValue = getDeepValue(serverData, ['values', field.name]);
          const funcParams = parseFunctionParams({ params, fullData: { ...(serverData || {}), user, workflowItem, clone }, groupName, field: { value: serverValue } });
          const result = functions[funcName] && await functions[funcName](...funcParams);
          let key = fieldKey;
          if (fieldKey.includes('value') && fieldKey !== 'values') {
            if (initRes === 'initValue') {
              key = 'initValue';
            } else if (initRes === 'initDisable') {
              key = 'initDisable';
            }
          }
          if (result != null) {
            if (initRes === 'initHideGroup') {
              initHideGroup = result;
            } else if (initRes === "initReadableGroup") {
              initHideGroup = result;
            } else {
              newField = {
                ...newField,
                [key]: result
              }
            }
          }
        }
      }
      if (initReadableGroup != null) {
        return initReadableGroup
      } else if (initHideGroup != null) {
        return initHideGroup
      }
      return newField;
    }
    return initRes === 'initValue' ? field : {};
  }

  async function metadataInitCall(payload, serverData) {
    const { groups } = payload && payload.metadata;
    const newGroups = await Promise.all(groups.map(async group => {
      const { fields } = group;
      const newFields = await Promise.all(fields.map(async field => {
        const functionValuesArray = getFunctionValues(field.function);
        const disableConditionValuesArray = field.queries && getFunctionValues(field.queries.disableCondition);
        const initValue = await getParsedResult(functionValuesArray, group, serverData, field, 'initValue')
        const initDisable = await getParsedResult(disableConditionValuesArray, group, serverData, field, 'initDisable')
        if(initValue && initDisable) {
          return {...initValue, ...initDisable}
        } else if(initValue) {
          return initValue;
        } else if(initDisable) {
          return initDisable;
        }
        return field;
      }));
      return {
        ...group,
        fields: newFields
      };
    }));
    return {
      ...payload,
      metadata: {
        ...payload.metadata,
        groups: newGroups
      }
    };
  }

  useEffect(() => {
    async function updateFilteredGroups() {
      if (dataState) {
        const filteredGroups = await Object.keys(dataState).reduce(async (shown, groupKey) => {
          const group = getDeepValue(dataState, [groupKey]);

          // don't add to shown
          if (!group || !group.metadata || group.metadata.type === TemplateGroupType.ACTION) {
            return shown;
          }

          // parent group check
          const parentGroup = getDeepValue(group, ['metadata', 'parentGroup']);
          const hideCondition = getDeepValue(group, ['metadata', 'groupConditions', 'hideCondition']);
          const readAbleCondition = getDeepValue(group, ['metadata', 'groupConditions', 'readAbleCondition']);
          const hideConditionValuesArray = hideCondition && getFunctionValues(hideCondition);
          const readAbleConditionValuesArray = readAbleCondition && getFunctionValues(readAbleCondition);
          if(hideCondition) {
            let initHideGroup = null;
            for (const hideConditionValues of hideConditionValuesArray) {
              const fieldKey = hideConditionValues.fieldKey.replace(/[^\w\s]/gi, '');
              /* when we need parentTemplateData to manipulate groups in child Template
              e.g if when we want to hide a group in a modal based on parent promotion template */
              const results = await getParsedResult([hideConditionValues], group.metadata, parentTemplateData ? { ...dataState, ...parentTemplateData } : dataState, {}, 'initHideGroup')
              if (initHideGroup === null) {
                initHideGroup = results;
              } else {
                if (fieldKey === 'and') {
                  initHideGroup = initHideGroup && results
                } else {
                  initHideGroup = initHideGroup || results
                }
              }
            }
            if(initHideGroup) {
              return {
                ...await shown,
                [groupKey]: {...group,  initHideGroup: initHideGroup}
              }
            }
          }
          if(readAbleCondition) {
            let initReadableGroup = null;
            for (const readaAbleConditionValues of readAbleConditionValuesArray) {
              const fieldKey = readaAbleConditionValues.fieldKey.replace(/[^\w\s]/gi, '');
              /* when we need parentTemplateData to manipulate groups in child Template
              e.g if when we want to hide a group in a modal based on parent promotion template */
              const results = await getParsedResult([readaAbleConditionValues], group.metadata, parentTemplateData ? { ...dataState, ...parentTemplateData } : dataState, {}, 'initReadableGroup')
              if (initReadableGroup === null) {
                initReadableGroup = results;
              } else {
                if (fieldKey === 'and') {
                  initReadableGroup = initReadableGroup && results
                } else {
                  initReadableGroup = initReadableGroup || results
                }
              }
            }
            if(initReadableGroup) {
              return {
                ...await shown,
                [groupKey]: {...group,  initReadableGroup: initReadableGroup}
              }
            }
          }
          if (parentGroup) {
            const { values: parentFieldValueObj, field: parentField } = parentGroup;
            const [parentGroupName, parentFieldName] = parentField.split('.');

            const parentGroupType = getDeepValue(dataState, [parentGroupName, 'metadata', 'type']);

            const { value, metadata } = getDeepValue(dataState, [parentGroupName, 'fields', ...(TemplateSpecialGroupType.includes(parentGroupType) ? [0] : []), parentFieldName]);
            const { type, multi } = metadata;
            const parentFieldValue = parentFieldValueObj ? Object.keys(parentFieldValueObj) : null;
            if (type === FieldTypes.ENUM && multi) {
              const selectedValues = value && value.split(',') || [];
              for (const selectedValue of selectedValues) {
                if (selectedValue === 'ALL' || (parentFieldValue && parentFieldValue.includes(selectedValue)))  {
                // add to shown if selected value is included
                  return {
                    ...await shown,
                    [groupKey]: group
                  }
                }
              }
            } else {
              if (parentFieldValue && parentFieldValue.includes(value)) {
              // add to shown if parent value match
                return {
                  ...await shown,
                  [groupKey]: group
                }
              }
            }

            // don't add to shown if parent value didn't match
            return shown;
          }

          // otherwise always add to shown
          return {
            ...await shown,
            [groupKey]: group
          }
        }, {});
        setShownGroups(filteredGroups);
      }
    }
    updateFilteredGroups();
  }, [dataState])

  function handleNav(i) {
    if (elRef && elRef.current && typeof i === 'number') {
      if (elRef.current.children[i]) {
        scrollTo(elRef.current.children[i].offsetTop);
      }
    } else {
      scrollTo(0);
    }
  }

  const createOnClick = async (ignoreErrors, itemName) => {
    const tempId = Array.isArray(templateId) ? templateId[0] : templateId;
    const payload = await createExecutePayload({ data: shownGroup, allData: dataState, templateId: tempId, rootType, itemName, mode });
    setShowErrors(!ignoreErrors);
    return payload ? (payload.hasError ? null : payload) : null;
  };

  /**
   * This function extracts the function name and params form the function string and calls the function with processed params value
   * @param {*} disableCondition(the function string defined in respective compartments)
   * @returns the result of respective function from viewFieldFunctions
   */

  const showDisplay = () => {
    if (!shownGroup) {
      return null;
    }
    const groupComponents = Object.keys(shownGroup).reduce((components, groupKey, i) => {
      const group = shownGroup[groupKey];

      // check for required missing
      const requiredMissing = TemplateSpecialGroupType.includes(group.metadata.type)
        ? group.requiredMissing.find(groupReq => groupReq.length > 0)
        : group.requiredMissing.length > 0;

      let navElem;
      if(!templateModal) {
        navElem = (
          <TemplateNav
            handleNav={() => handleNav(i)}
            key={groupKey + i}
            group={group.metadata}
            error={showErrors ? group.error : false}
            isRequired={group.required}
            requiredMissing={requiredMissing}
            classes={classes}
          />
        );
      }

      const groupElem = (
        <TemplateGroup
          key={groupKey}
          groupData={group}
          mode={mode}
          device={group.metadata.device}
          dispatch={dispatch}
          comments={props.comments}
          showErrors={showErrors}
          fullData={parentTemplateData ? { ...shownGroup, ...parentTemplateData } : shownGroup}
          history={history}
          setFormChanged={setFormChanged}
          isContinue={isContinue}
          dataState={parentTemplateData ? { ...dataState, ...parentTemplateData } : dataState}
          setTemplateModalData={setTemplateModalData}
          templateModal={templateModal}
          workflowItem={workflowItem}
          modalVariant={modalVariant}
          sameScreenModal={sameScreenModal}
          parentTemplateData={parentTemplateData}
        />
      );


      return {
        navElements: [...components.navElements, navElem],
        groupElements: [...components.groupElements, groupElem]
      };
    }, { navElements: [], groupElements: [] });
    if (!history && modalVariant !== 'popover' && !sameScreenModal) {
      const filteredActions = props.otherActionsProps && props.otherActionsProps.filter(action => action.params && action.params.function ? viewFieldFunctions[action.params.function](user, data) : action)
      const finalActions = filteredActions && filteredActions.map(action => action.params && action.params.disableCondition ? { ...action, params: { ...action.params, disableCondition: extractFunctions(action.params.disableCondition, props.workflowItem) } } : action)
      const xsGroup = templateModal ? 12 : 10
      const xsNav = templateModal ? 0 : 2
      return (
        <Grid container spacing={4} className={classes.formGroupWrapper} wrap='nowrap'>
          <Grid item xs={xsNav} className={classes.navigatorContainer}>
            {groupComponents.navElements}
            {!clone && props.otherActionsProps ? (
              <div className={classes.otherActionsContainer}>
                {finalActions.map(item => (
                  <CustomButton
                    {...item}
                    key={item.label}
                    variant='outlined'
                    color={item && item.params && item.params.color ? item.params.color : 'secondary'}
                    size='large'
                    className={classes.navActionButtons}
                    disabled={item && item.params && item.params.disableCondition ? item.params.disableCondition : null}
                  />
                ))}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={xsGroup} className={classes.formGroupContainer} ref={elRef}>
            {groupComponents.groupElements}
          </Grid>
        </Grid>
      );
    } else {
      return groupComponents.groupElements;
    }
  };

  if (loading || !dataState || loadingProps) {
    return <BodySkeleton header count={2} />
  } else if (error) {
    return <div style={{ height: '100%' }}>ERROR</div>;
  }

  if (history) {
    return showDisplay()
  }

  return (
    <div className={classes.root}>
      <div>
        {showDisplay()}
      </div>
      <ScrollUpButton scrollUpOffset={scrollUpOffset} />
    </div>
  );
};

export default forwardRef(TemplateCompartment);
