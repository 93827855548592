import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';

const styles = {
  root: {

  },
  marginNormal: {
    marginTop: 16,
    marginBottom: 8
  },
  formError: {
    '& .MuiCheckbox-root': {
      color: Colors.red
    }
  }
}

export default makeStyles(styles);
