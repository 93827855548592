const zeroFill = (upc, width) => {
  width -= upc.toString().length;
  if (width > 0) {
    return new Array(width + (/\./.test(upc) ? 2 : 1)).join('0') + upc;
  }
  return upc + ''; // always return a string
}

const padUpcNumber = (upc) => {
  if (!upc || isNaN(+upc)) {
    return '';
  }

  return zeroFill(upc, 13);
}

export default padUpcNumber;
