import React, { useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import Diff from '../Diff';
import CsvInput from '../../inputs/CsvInput';
import { BlobProcessStatus } from '../../../constants/Constants';
import UserComptContext from '../../../setup/compartments/compartment.context';

function CsvInputDiff({ condition, data, mode, biglistStatus, biglistName, match, queries, ...props }) {
  const history = useHistory();

  const {
    compartments,
  } = useContext(UserComptContext);

  // will be null if user does not have access to the compartment
  const biglistCompartment = compartments.find(compartment => compartment.isRoot && compartment.type === 'biglist')

  function navigateToSavedLists() {
    if (!biglistCompartment) {
      return;
    }

    history.push(`/${biglistCompartment.type}/${biglistCompartment.navTarget}/${biglistCompartment.id}/${biglistCompartment.children[0]}`, {
      filter: {
        name: {
          search: biglistName,
          data: { type: 'STRING', siblings: null },
          option: 'exact',
        }
      }
    })
  }

  function enableEditListButton() {
    return queries && queries.enableEditList && (queries.enableEditList === 'true' || queries.enableEditList === true) &&
      biglistName && biglistStatus === BlobProcessStatus.COMPLETED &&
      props.value && Array.isArray(props.value) && props.value.length > 0 &&
      biglistCompartment
  }

  return (
    <Diff
      enableEditListButton={enableEditListButton()}
      navigateToSavedLists={navigateToSavedLists}
      mode={mode}
    >
      <CsvInput {...props} queries={queries} biglistName={biglistName} biglistStatus={biglistStatus} />
    </Diff>
  );
}

export default withRouter(CsvInputDiff)
