import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./Template.styles";
import ViewOnly from '../../viewOnly/ViewOnly';
import ColorPickerInput from '../../inputs/ColorPickerInput';

const TemplateColor = props => {
  const { classes, field } = props;
  const {
    mode,
    required,
    readOnly,
    initDisable
  } = field;

  const isViewOnly = (mode === "viewOnly");
  const isDisabled = readOnly || isViewOnly ? (readOnly || isViewOnly) : false || initDisable;

  // const [fieldValue, setFieldValue] = useState(firstData);

  const finalProps={
    id:field.name,
    margin:"normal",
    onChange:field.onChange,
    label:field.shortDesc + (required ? ' *' : ''),
    placeholder:field.longDesc,
    value:field.value,
    condition:field.name,
    mode:mode,
    data:field.data,
    disabled:isDisabled,
    required:required,
    error:field.error
  }

  if(isViewOnly){
    return(
      <ViewOnly
        className={`${classes.root} ${classes.flex}`}
        {...finalProps}
      />
    )

  } else{
    return (
      <ColorPickerInput
        className={`${classes.root} ${classes.flex}`}
        {...finalProps}
      />
    );

  }


};
export { TemplateColor as TestTemplateColor }
export default withStyles(styles)(TemplateColor);
