import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./Template.styles";
import BooleanInputDiff from "../../diff/inputs/BooleanInputDiff";
import parseBool from '../../../Utils/template/parseBool';

const TemplateBoolean = props => {
  const { classes, field } = props;
  const {
    longDesc,
    name,
    mode,
    data,
    required,
    shortDesc,
    readOnly,
    value,
    error,
    onChange,
    initDisable
  } = field;

  const isViewOnly = (mode === "viewOnly");

  const parsedValue = parseBool(value);

  return (
    <BooleanInputDiff
      id={name}
      className={classes.root}
      onChange={onChange}
      label={shortDesc}
      placeholder={longDesc}
      value={parsedValue}
      condition={name}
      mode={mode}
      data={data}
      required={required}
      disabled={readOnly || isViewOnly ? (readOnly || isViewOnly) : false || initDisable}
      error={error}
      topPadding={12}
    />
  );
};

export default withStyles(styles)(TemplateBoolean);
