import { FieldTypes, low, TemplateMixTypes } from '../../constants/Constants';
import dateFormat from '../date/dateFormat';
import { DATE_ACTIONS } from '../../compartments/search/getSearchDateActions';
import getDeepValue from '../getDeepValue';

const processServerValue = ({ field, value, specialGroupData, groupName, groupData }) => {
  const assignmentGroupName = field && field.group && field.group.isCopy ? field.group.copyOf : groupName;
  const specialGroup = getDeepValue(specialGroupData, [assignmentGroupName]);
  if (field) {
    switch (field.type) {
      case FieldTypes.DATE:
      case FieldTypes.DATE2:
        return value ? dateFormat(value, 'MM/DD/YYYY') : null;
      case FieldTypes.BOOLEAN:
        return value;
      case FieldTypes.MIX:
      case FieldTypes.TABLE:
        if (field.name === 'club_override_list' && specialGroup && low(specialGroup[0].list_type) === 'csv') {
          const fullValue = specialGroup.reduce((arr, group) => {
            const listValue = group.club_override_list.split(',').map(val => {
              const newObj = { id: val };
              for (let i = 0; i < DATE_ACTIONS.length; i++) {
                const key = DATE_ACTIONS[i];
                newObj[key] = group[key];
              }
              return newObj;
            });
            return [...arr, ...listValue];
          }, []);
          return {
            csv: fullValue.map(val => val.id).join(','),
            fullValue
          };
        } else if (specialGroup || groupData) {
          const arrGroup = groupData ? groupData : specialGroup[field.groupIndex || 0];
          const listType = arrGroup.list_type ? low(arrGroup.list_type) : '';
          return listType ? {
            [listType]: value
          } : value || { csv: value };
        } else if (field) {
          const { enableBiglist } = field;
          const listType = enableBiglist ? TemplateMixTypes.BIGLIST : TemplateMixTypes.CSV;
          if (!value) {
            return value;
          }
          return {
            [listType]: value
          };
        }
        break;
      default:
        return value;
    }
  }
}

export default processServerValue
