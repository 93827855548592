import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';
// import { fontRegular } from '../../theme/theme';

const styles = {
  root: {
    // height: '100%',
    // paddingBottom: 80
  },
  buttonContainer: {
    position: 'fixed',
    bottom: 0,
    width: '100vw',
    backgroundColor: Colors.white,
    zIndex: 3,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttons: {
    height: '100%',
    width: '100%',
    maxWidth: 1600,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    margin: 8
  },
  subHeader: {
    display: 'flex-end',
    marginLeft: 'auto',
    alignItems: "center"
  },
  createdBy: {
    marginLeft: 'auto',
    color: Colors.white
  },
  error: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 4
  },
  message: {
    color: Colors.primaryOrange,
    fontWeight: 300
  },
  icon: {
    color: Colors.primaryOrange,
    padding: 8
  }
};

export default makeStyles(styles);
