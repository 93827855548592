
import Colors from "../../../theme/Colors";

const styles = theme => ({
  root: {
    minWidth: 150,
    display: 'flex',
    "vertical-align": "center",
    // marginRight: 4,
    marginBottom: 0
  },
  templateEnumRoot: {
    display: 'flex',
    "vertical-align": "center",
    // marginRight: 4,
    marginBottom: 0
  },
  flex: {
    display: 'flex'
  },
  label_box: {
    width: "150px",
    display: "inline-block"
  },

  label_text: {
    marginLeft: "20px"
  },
  button: {
    display: "inline-block",
    height: 20,
    marginRight: 10,
    fontFamily: "Roboto",
    fontSize: 19,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "center",
    color: "#2a52be"
  },
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: theme.spacing(1)
  },
  editButton: {
    display: "inline-block"
  },
  templateLabel: {
    marginTop: 16,
    marginBottom: 8
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContentContainer: {
    height: '90vh',
    width: '90vw',
    backgroundColor: Colors.white
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    color: Colors.white
  },
  modalActions: {
    padding: 16,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  modalBody: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  actionButtons: {
    minWidth: 128,
    '&:not(:first-child)': {
      marginLeft: 16
    }
  },
  divider: {
    backgroundColor: '#f5f5f5',
    width: '100%',
    height: '16px',
    margin: '5px 0px 5px -16px',
    padding: '5px 16px',
    color: Colors.grey900,
    fontSize: '13px',
    fontFamily: 'ROBOTO',
    fontWeight: '500',
    boxShadow: '0 4px 7px 0 rgb(182 182 182 / 50%)'
  },
  noneDivider: {
    backgroundColor: '#fff',
    width: '100%',
    margin: '0px 0px 5px -16px',
    padding: '5px 16px',
    color: Colors.grey900,
    fontSize: '13px',
    fontFamily: 'ROBOTO',
    fontWeight: '500'
  },
  popoverRoot: {
    width: 780
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  combinedText: {
    color: '#7F7F7F',
    marginTop: 16
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "stretch",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonContainer:{
    flexGrow:0
  }
});

export default styles;
