import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function fetchAll(url, body) {
  let skip, list;
  const localBody = { ...body, top: 10000 };
  do {
    const response = await fetch(`${baseURL}index/${url}`, {
      method: 'POST',
      headers: baseHeaders(),
      body: JSON.stringify(localBody)
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });
    list = list ? list.concat(response.value) : response.value;
    skip = response && response['@search.nextPageParameters'] && response['@search.nextPageParameters'].skip;
    localBody.skip = skip;
  } while (skip);

  return list;
}

export default fetchAll;
