import { baseURL, baseHeaders } from '../../constants/Endpoints';
import getDeepValue from '../../Utils/getDeepValue'

async function exportOfferService(campaign, user) {
  try {
    const campaignNumber = getDeepValue(campaign, ['values','campaign_number']);
    const userId = user.id;
    const response = await fetch(`${baseURL}exportoffers?campaign=${campaignNumber}&user=${userId}`, {
      method: 'GET',
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });

    return response;
  } catch (error) {
    return error;
  }
}


export default exportOfferService;
