import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TemplateMix from '../templates/inputs/TemplateMix';
import useStyles from '../viewOnly/ViewOnly.styles';
import { FieldTypes } from '../../constants/Constants';

function HistoryText(props) {
  const viewStyles = useStyles();
  const { field, history } = props;

  const createRow = (mixType, modifiedField) => {
    return (
      <TableRow className={viewStyles.historyTableStyles}>
        <TableCell>{field.shortDesc}</TableCell>
        <TableCell>{history.dateModified}</TableCell>
        <TableCell>{history.modifiedBy}</TableCell>
        {mixType
          ? (
            <TableCell>
              <TemplateMix field={field} />
            </TableCell>

          ) :
          (
            <TableCell className={viewStyles.history}>
              {field.oldValue}
            </TableCell>
          )
        }
        <TableCell>{mixType ? <TemplateMix field={modifiedField} /> : field.value}</TableCell>
        <TableCell>NA</TableCell>
      </TableRow>
    )
  }
  const compare = (field) => {
    switch (field.type) {
      case FieldTypes.NUMBER:
      case FieldTypes.STRING:
      case FieldTypes.BOOLEAN:
      case FieldTypes.COLOR:
      case FieldTypes.DATE:
      case FieldTypes.DATE2:
      case FieldTypes.DATA:
      case FieldTypes.ENUM: {
        if (field.oldValue === field.value) {
          return '';
        } else {
          return createRow(/* mixType=*/false)
        }
      }

      case FieldTypes.MIX: {
        const result = compareLists(field.oldValue, field.value);
        if (result) {
          const modifiedField = Object.assign({}, field, {
            value: field.oldValue,
            shortDesc: `Old-${field.shortDesc}`
          });
          return createRow(/* mixType=*/true, modifiedField)
        }
        return null;
      }

      case FieldTypes.LABEL:
        return false;
      default:
        return false;
    }
  };
  const compareLists = (oldList, newList) => {
    if (oldList.hasOwnProperty('csv') && newList.hasOwnProperty('csv')) {
      return oldList.csv !== newList.csv;
    } else if ((oldList.hasOwnProperty('csv') && newList.hasOwnProperty('biglist'))
      || oldList.hasOwnProperty('biglist') && newList.hasOwnProperty('csv')) {
      return true;
    } else {
      if (oldList.biglist !== newList.biglist) {
        return true;
      }
      return false;
    }
  }
  const renderComponent = () => {
    return compare(field);
  };
  return renderComponent();
}

export default HistoryText;
