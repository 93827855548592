/*
  get workflow next actions for search
*/
import getStepLabel from './get-step-label';
import getOverrideSteps from './get-override-steps';
import getImages from './get-images';
import { checkStepAccess } from './check-user-access';
import { WorkflowDirections, SearchActions, RootTypes, LAST_STEP_PROMO } from '../../constants/Constants';
import { stateLogStatus } from './state-log-status';
import stateAutoForward from './state-auto-forward';
import { createSearchWorkflowPath } from "../navigation/createPath";
import { isDiffUser, isMerchant } from './check-if-is-diff-user';

function isStepAccessible(stateName, roleAccess) {
  let stateAccess = false;
  // const userAccess = userRole.stepAccess[stepName.toLowerCase()];
  stateAccess = checkStepAccess(roleAccess.stepAccess, stateName);
  return stateAccess;
}

let rootType = '';
let currentCompt = '';
let id = '';
let templateId = '';
let iSearch = false;
let workflowId = '';

function getAutoForward(loggedInUser, row, iAutoForward, iMove) {
  if (iMove === WorkflowDirections.BACKWARD && !isMerchant(loggedInUser)) {
    return false;
  }
  return iAutoForward;
}

function buildStateParams({ iLabel, iIcon, iSequence, iMove, iParams, iAutoForward = null, iExecute = null, iMode = SearchActions.VIEW, iTemplate = "", iOrder = 0, loggedInUser, row }) {
  const iPath = determinePath(workflowId, rootType, currentCompt, id, iTemplate, iMode, iSequence);

  const params = {
    label: iLabel,
    icon: iIcon,
    sequence: +iSequence,
    move: iMove, // move direcion
    ...iParams,
    autoForward: getAutoForward(loggedInUser, row, iAutoForward, iMove), // move to next step
    execute: iExecute, // execute before moving,
    mode: iMode, // view edit mode,
    path: iPath + (iSearch && (iMode !== SearchActions.VIEW) ? '&search=true' : ''), // navigaion path
    order: iOrder
  }
  return params;
}

function determinePath(workflowId, rootType, currentCompt, id, templateId, mode, sequence) {

  return createSearchWorkflowPath({
    workflowId,
    rootType,
    parentId: currentCompt,
    itemId: id,
    templateId,
    mode,
    sequence
  });
}

function getOverrideStates(state, currStateComplete, isDraft, prevStateComplete, search, row, params, isDiffRole, stateMetadata, loggedInUser, overrideStateArr, roleAccess,isMerchantUser,activeSeq) {
  let canProceed = true;
  const lastTrail = getLastTrail(row);
  const stateName = lastTrail ? stateMetadata.find((x) => x.sequence === lastTrail.sequence) : '';


  // this is to display override step for last step without permission
  if (!isStepAccessible(state.name, roleAccess)) {
    if (stateName && stateName.name !== LAST_STEP_PROMO) {
      return overrideStateArr;
    }
  }

  if ((stateName && stateName.name === LAST_STEP_PROMO) && isMerchantUser) {
    return overrideStateArr
  }
  Object.keys(state.overrides).forEach((keyName) => {

    if (lastTrail && !lastTrail.complete && lastTrail.sequence === activeSeq) {
      return;
    }

    const currStateComplete = row ? stateLogStatus(row.auditTrail, '', +keyName).complete : false;

    // if(isStepAccessible(state.overrides[keyName].name, roleAccess) || keyName === -1){
    // if(!((state.sequence === +keyName && currStateComplete))){
    const overrideState = state.overrides[keyName];

    canProceed = !row ? canProceed : (row && !currStateComplete ? (!isMerchantUser) : canProceed);
    const curateLabel = row && isDraft & !currStateComplete ?
      (search ? 'Edit Draft' : (params && params.view) ? 'Edit Draft' : 'Save Draft') :
      isDiffRole ? getStepLabel(overrideState, 'ROLE_SUPPLIER') : getStepLabel(overrideState, loggedInUser.role.name);
    const currState = stateMetadata.find((state) => state.sequence === +keyName);

    if (curateLabel === 'Reassign Merchant' && +state.sequence === +activeSeq) {
      return;
    }

    if (canProceed && curateLabel) {

      if (!isDraft) {
        const backwardStateComplete = row ? stateLogStatus(row.auditTrail, '', +keyName).complete : false;
        if (!backwardStateComplete) {
          return;
        }
      }

      if (isDraft && prevStateComplete) {
        return;
      }

      const arrKey = isDraft ? -1 : overrideStateArr[keyName] && overrideStateArr[keyName].sequence !== +keyName ? overrideStateArr.length : keyName;
      if (overrideStateArr[arrKey] || !currState.uiReadable) {
        return;
      }

      const workflowDirection = ((state.sequence == keyName) ? WorkflowDirections.STATIC : WorkflowDirections.BACKWARD);
      const overrideStateImage = overrideState.image ? overrideState.image.toUpperCase() : "";
      const searchActionMove = curateLabel.toUpperCase().indexOf('EDIT') === 0 ? SearchActions.SAVEEDIT : SearchActions.EDIT

      overrideStateArr[arrKey] = buildStateParams({
        iLabel: curateLabel,
        iIcon: (overrideState.bulk ? getImages(overrideStateImage) : null),
        iSequence: keyName,
        iMove: workflowDirection,
        iParams: params,
        iAutoForward: (!isDraft),
        iExecute: isDraft ? true : (state.sequence !== +keyName),
        iMode: row && (isDraft && !currStateComplete) || (workflowDirection === WorkflowDirections.BACKWARD)
          ? searchActionMove : SearchActions.VIEW,
        iTemplate: (currState.templateId ? currState.templateId : templateId),
        iOrder: 1,
        loggedInUser: loggedInUser,
        row: row
      })
    }
  })

  return overrideStateArr;
}

export function getLastTrail(row) {
  if (!row || !row.auditTrail) {
    return null
  }

  // This exists so that our current flow for republishing a promotion will still work
  // This flow can only ever be reached by manipulating the document in cosmos
  // This is because sequence 3 is "skipped" as we immediately move to sequence 4 (item creation) when publishing a promotion
  if (+row.currentSequence === 3 && row.workflowId === '673e9b92-f929-4a62-9714-72eb65d9d643') {
    return row.auditTrail[row.auditTrail.length - 1];
  }

  return [...row.auditTrail].reverse().find(audit => audit.sequence === row.currentSequence)
}

export default function getWorkflowSearchActions(loggedInUser, stateMetadata, roleAccess, params, row = null, search = true, root = '', parentCompt = '') {
  // if(row.promotion_name !== 'new supplier offer 102')return;
  const workflowRefined = [];
  rootType = root;
  id = row && row.id ? row.id : '';
  currentCompt = parentCompt;
  templateId = row ? row.templateId : '';
  iSearch = search;
  workflowId = row ? row.workflowId : '';

  const lastTrail = getLastTrail(row);
  let currState = params ? (params.viewAll && lastTrail ? +lastTrail.sequence : (params.currState ? params.currState : 0)) : 0;
  currState = (+currState === -1) ? 0 : currState;

  const isDiffRole = isDiffUser(loggedInUser, row);
  const isMerchantUser = isMerchant(loggedInUser)
  const stateArr = stateMetadata.filter((state) => state.sequence === +currState);
  let overrideStateArr = [];
  //added view state to search
  if (search) {
    overrideStateArr["-2"] = buildStateParams({
      iLabel: "View",
      iIcon: null,
      iSequence: "",
      iMove: WorkflowDirections.STATIC,
      iParams: params,
      iAutoForward: true,
      iExecute: false,
      iMode: SearchActions.VIEW,
      iTemplate: templateId,
      iOrder: 0,
    });
  }

  if (rootType === RootTypes.CAMPAIGN && row && !search) {
    overrideStateArr["-3"] = buildStateParams({
      iLabel: params && !params.view ? "Submit Changes" : "Edit",
      iIcon: null,
      iSequence: "",
      iMove: WorkflowDirections.STATIC,
      iParams: { ...params, "sequence": params.sequence == -3 ? row.currentSequence : -3 },
      iAutoForward: true,
      iExecute: false,
      iMode: SearchActions.EDIT,
      iTemplate: templateId,
      iOrder: 0
    });
  }

  if (stateArr) {
    stateArr.map((state) => {
      const prevStateComplete = row ? stateLogStatus(row.auditTrail, '', (lastTrail ? (+lastTrail.sequence !== +state.sequence) ? +lastTrail.sequence : state.sequence : state.sequence)).complete : false;
      const currStateComplete = row ? stateLogStatus(row.auditTrail, '', +state.sequence).complete : false;
      let isDraft = false;

      if (state.overrides) {

        const sameStateOverride = Object.keys(state.overrides).find((key) => +key === +state.sequence);
        isDraft = sameStateOverride ? true : isDraft;

        overrideStateArr = getOverrideStates(state, currStateComplete, isDraft, prevStateComplete, search, row, params, isDiffRole, stateMetadata, loggedInUser, overrideStateArr, roleAccess, isMerchantUser, currState);
      }
      const canProceedNext = row && isDraft & !prevStateComplete ? (!isMerchantUser ) : true;
      if (canProceedNext) {
        const trailsArr = row && row.auditTrail ? row.auditTrail.filter((x) => x.sequence === +state.sequence).reverse() : null;
        const trailComplete = trailsArr && trailsArr.length > 0 ? trailsArr[0].complete : false;

        const nextState = trailComplete ? stateMetadata.find((x) => x.sequence === (state.sequence + 1)) :
          stateMetadata.find((x) => x.sequence === state.sequence); //(lastTrail  && lastTrail.complete ? (lastTrail.sequence + 1) : (state.sequence + 1))
        const hasNextStateAccess = nextState ? isStepAccessible(nextState.name, roleAccess) : false;
        if (nextState && hasNextStateAccess) {// && isStepAccessible(state.name, roleAccess)){

          let curateLabel = getStepLabel(hasNextStateAccess && nextState ? nextState.description : state.description, loggedInUser.role.name);
          curateLabel = (isDraft && !currStateComplete) ? ((params && params.view || search) ? "" : "Save and ") + curateLabel : ((trailComplete && !hasNextStateAccess) ? "Withdraw" : curateLabel);
          const overrideSeq = !hasNextStateAccess ? state.sequence : search ? nextState.sequence : (trailComplete ? nextState.sequence : state.sequence);  //(search? ((trailComplete && !hasNextStateAccess)? state.sequence: nextState.sequence) : state.sequence); // nextState? nextState.sequence:
          const lastStep = stateMetadata.find((x) => x.sequence === (state.sequence + 1));
          const hasLastStepAccess = lastStep && lastStep.uiReadable && hasNextStateAccess ? isStepAccessible(lastStep.name, roleAccess) : false;

          if (curateLabel !== "" && nextState.uiReadable) {
            overrideStateArr[state.sequence] = buildStateParams({
              iLabel: nextState && nextState.templateId && !search && hasNextStateAccess ? curateLabel + (hasLastStepAccess ? '...' : '!') : curateLabel,
              iIcon: (state.bulk ? getImages(state.image ? state.image.toUpperCase() : "") : null),
              iSequence: overrideSeq,
              iMove: WorkflowDirections.FORWARD,
              iParams: params,
              iAutoForward: stateAutoForward(stateMetadata, overrideSeq, roleAccess),
              iExecute: (!trailComplete || !nextState.templateId || +nextState.templateId === 0), //!stateComplete, // last
              iMode: SearchActions.EDIT,
              iTemplate: (state.templateId ? state.templateId : templateId),
              iOrder: 2,
              loggedInUser: loggedInUser,
              row: row
            })
          }

        }

        if (nextState && nextState.overrides && params && (params.viewAll || search)) {//&& hasNextStateAccess
          const overrideStateComplete = row ? stateLogStatus(row.auditTrail, '', +nextState.sequence).complete : false;

          overrideStateArr = getOverrideStates(nextState, currStateComplete, isDraft, overrideStateComplete, search, row, params, isDiffRole, stateMetadata, loggedInUser, overrideStateArr, roleAccess,isMerchantUser,currState);
        }


        const optionalStates = getOverrideSteps(stateMetadata, state.sequence, roleAccess.stepAccess);
        if (optionalStates && optionalStates.sequence !== state.sequence && isStepAccessible(optionalStates.name, roleAccess)) {

          let curateLabel = getStepLabel(optionalStates.description, loggedInUser.role.name);
          // curateLabel = (isDraft && !currStateComplete) ? ((search && params && params.view) ? "" : "Save and " ) + curateLabel : curateLabel;
          curateLabel = isDraft && !search && params && !params.view ? "Save and " + curateLabel : curateLabel;
          const currState = stateMetadata.find((state) => state.sequence === +optionalStates.sequence);
          const optionalTrailsArr = row && row.auditTrail ? row.auditTrail.filter((state) => state.sequence === +optionalStates.sequence).reverse() : null;
          const optionalTrailComplete = optionalTrailsArr && optionalTrailsArr.length > 0 ? optionalTrailsArr[0].complete : false;
          const optionalStatesImage = optionalStates.image ? optionalStates.image.toUpperCase() : "";

          if (!optionalTrailComplete && currState.uiReadable) {

            overrideStateArr[optionalStates.sequence] = buildStateParams({
              iLabel: currState.templateId ? `${curateLabel}...` : curateLabel,
              iIcon: (optionalStates.bulk ? getImages(optionalStatesImage) : null),
              iSequence: optionalStates.sequence,
              iMove: WorkflowDirections.FORWARD,
              iParams: params,
              iAutoForward: stateAutoForward(stateMetadata, optionalStates.sequence, roleAccess),
              iExecute: !currStateComplete,
              iMode: SearchActions.EDIT,
              iTemplate: (currState.templateId ? currState.templateId : templateId),
              iOrder: 2,
              loggedInUser,
              row
            })
          }

          if (optionalStates && optionalStates.overrides && params && params.viewAll) {
            const optionalStatesStateComplete = row ? stateLogStatus(row.auditTrail, '', +optionalStates.sequence).complete : false;
            overrideStateArr = getOverrideStates(optionalStates, currStateComplete, isDraft, optionalStatesStateComplete, search, row, params, isDiffRole, stateMetadata, loggedInUser, overrideStateArr, roleAccess,isMerchantUser, currState);
          }
        }
      }
    })
  }

  workflowRefined[currState] = overrideStateArr;

  return (workflowRefined && workflowRefined[currState] ? workflowRefined[currState] : null);
}
