import React from 'react';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import Remove from '@material-ui/icons/Remove';
import ClearIcon from '@material-ui/icons/Clear';
import CustomButton  from '../buttons/CustomButton/CustomButton';

const TemplateNav = props => {
  const { group, handleNav, error, isRequired, requiredMissing, classes } = props;

  const renderIcon = () => {
    if (isRequired && error) {
      return (
        <div className={`${classes.templateNavIconWrapper}`}>
          <ClearIcon className={`${classes.templateNavIcon} ${classes.templateNavError}`} />
        </div>
      );
    } else if (isRequired && !requiredMissing) {
      return (
        <div className={`${classes.templateNavIconWrapper}`}>
          <CheckIcon className={`${classes.templateNavIcon} ${classes.templateNavCheck}`} />
        </div>
      );
    } else if (isRequired && requiredMissing) {
      return (
        <div className={`${classes.templateNavIconWrapper}`}>
          <Remove className={`${classes.templateNavIcon} ${classes.templateNavMissing}`} />
        </div>
      );
    }
    return (
      <div className={classes.templateNavIconWrapper}>
        <CheckIcon className={`${classes.templateNavIcon} ${classes.templateNavNonMandatory}`} />
      </div>
    )
  }

  return (
    <div className={clsx(classes.templateNavWrapper, { [classes.hide]: group.hide })}>
      {renderIcon()}
      <CustomButton
        key={group.name}
        color='secondary'
        size='large'
        onClick={handleNav}
        className={classes.templateNav}
      >
        {group.shortDesc}
      </CustomButton>
    </div>
  )
}

export default TemplateNav;
