import React from "react";
import { withStyles, Button } from "@material-ui/core";
import styles from "./Template.styles";

const TemplateButton = props => {
  const { field } = props;
  const {
    longDesc,
    mode,
    readOnly,
    onChange,
    initDisable
  } = field;

  const isViewOnly = (mode === "viewOnly");

  return (
    <Button
      variant='contained'
      size='small'
      color='secondary'
      onClick={onChange}
      disabled={initDisable || readOnly || isViewOnly}
    >
      {longDesc}
    </Button>
  )
};
export { TemplateButton as TestTemplateButton }
export default withStyles(styles)(TemplateButton);
