import React from 'react';
import moment from 'moment-timezone';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import { SearchActions, SearchTypes, low } from '../../constants/Constants';
import getWorkflowSearchActions from '../../Utils/workflow/get-workflow-search-actions';
import LoaderContainer from '../../Utils/skeletonLoader/skeletonLoader';
import getDeepValue from '../../Utils/getDeepValue';
import { extractFunctions, functions } from '../../Utils/view/viewFieldFunction';
import { initObject, initNull } from '../../Utils/helpers';
import checkIsSupplierFunded from '../../Utils/checkIsSupplierFunded';
import checkIsEnded from '../../Utils/checkIsEnded';

/**
 * Sorts on header click
 * @param {string} property
 * @param {{ sort: object, props: object }} param1
 */
export function createSortHandler(property, { sort, props }) {
  const sortType = sort.property === property ? (sort.orderBy === "asc" ? "desc" : "asc") : (sort.orderBy ? sort.orderBy : 'asc')
  const updateSort = { property: property, orderBy: sortType, type: props.fieldMap[property].type };
  // setSort(updateSort);
  props.onChangeSearch({ sort: updateSort });
}

/**
 * loader component as overlay component for <Table>
 * @param {*} classes
 */
export const customLoader = classes => (
  <div className={classes.loaderContainer}>
    <LoaderContainer height={48} count={5} />
  </div>
);

/**
 * handles filter changes
 * @param {*} event
 * @param {boolean} isSelection checkboxes for advanced filter
 * @param {string} choice category
 * @param {*} param3 other params for setting state
 */
export async function handleOnChangeFilter(event, isSelection, choice, { props, setChildOptionsLoading, setFilter, stateFilter, hack, isSearchAll }) {
  const filter = event.target.value;
  if (low(props.searchType) === low(SearchTypes.ITEM) && choice && filter.category) {
    // fetch subcategory for item category
    if (props.itemMetadata.categories.filter(cat => cat.value === choice)[0].childOptions.subCategory.length === 0) {
      setChildOptionsLoading(true);
      await props.itemMetadata.fetchSubCategories(choice);
      setChildOptionsLoading(false);
    }
  }
  if (isSelection) {
    const checkValue = Object.keys(filter).filter(key => !!filter[key]);
    if (!checkValue.length) {
      clearSearch({ setFilter, props, hack });
    } else {
      await setFilter(filter);
      onClickSearch(filter, { filter: stateFilter, props, hack });
    }
  } else {
    await setFilter(filter);
    if (hack.filterSearchTimeout) {
      clearTimeout(hack.filterSearchTimeout);
    }
    if (!isSearchAll) {
      hack.filterSearchTimeout = setTimeout(() => onClickSearch(filter, { filter: stateFilter, props, hack }), 1500)
    }
  }
}

/**
 * handles search on search bar
 * search a specific field key else search all fields
 * @param {{
 *  specificFieldSearch: string,
 *  filter: object,
 *  props: object
 * }} param0
 */
export function onClickSearchAll({ specificFieldSearch, filter, props, hack }) {
  if (specificFieldSearch) {
    onClickSearch(null, { filter, props, hack });
  } else {
    props.onChangeSearch({ filter: { searchAll: filter.searchAll } });
  }
}

/**
 * handles search on advanced filter
 * @param {object} filterParam
 * @param {
 *  filter: object,
 *  props: object
 * } param1
 */
export function onClickSearch(filterParam, { filter, props, hack }) {
  if (hack.filterSearchTimeout) {
    clearTimeout(hack.filterSearchTimeout);
  }
  const searchFilter = filterParam ? filterParam : filter;
  props.onChangeSearch({ filter: { ...searchFilter, searchAll: null } });
}

/**
 * sets empty filter and calls clearSearch from props
 * @param {
 *  setFilter: function,
 *  props: object,
 *  filterSearchTimeout: object
 * } param0
 */
export function clearSearch({ setFilter, props, hack }) {
  const defaultFilterEnabled = props.defaultFilter;
  const customFilter = initNull(defaultFilterEnabled);
  setFilter(initObject(customFilter));
  if (props.noDelay) {
    props.clearSearch();
  } else {
    hack.filterSearchTimeout = setTimeout(() => props.clearSearch(), 1500);
  }
}

/**
 * triggers the click event of the first button on the row
 * @param {array} values
 * @param {object} row
 * @param {object} history
 */
export function extractOnClick(values, row, history, filter, switchValue) {
  let actionList = [];
  let concatList = [];
  if (values && values[0] && values[0].props && values[0].props.items) {
    const items = typeof values[0].props.items === 'function' ? values[0].props.items(row) : values[0].props.items;
    if (items && items[0] && items[0].onClick) {
      if (items[0].disabled || items[0].mode === SearchActions.SELECT) {
        return null;
      }
      return (event) => {
        event.stopPropagation();
        event.preventDefault();
        return items[0].onClick(row.id, row, items[0])
      }
    }

    concatList = extractCommonOptions(items, row);
    actionList = concatList.sort((a, b) => a.order - b.order)
    const item = actionList[0];
    if (item && !(item.hidden || hideIfNoPromotions(item, row))) {
      const hSearch = history.location&&history.location.search?{search:history.location.search}:{}
      item.workflow ? history.push({
        pathname: item.path,
        state: {
          loadingComplete: item.autoForward,
          moreParams: {
            autoForward: item.autoForward,
            execute: item.execute,
            move: item.move,
            label: item.label,
            sequence: item.sequence,
            currState: item.currState,
            save: item.save,
            view: item.view,
            viewAll: item.viewAll,
            mode: item.mode,
            path: item.path,
            order: item.order,
            filter,
            ...hSearch,
            switchValue
          }
        }
      }) : item.onClick(row.id, row, item)
    }
  }
}

/**
 *
 * @param {array} searchActions - list of actions to be shown for each row(not workflow actions)
 * @param {object} row - row data
 */
function checkActions(searchActions, row) {

  // const actionsFilteredArr = searchActions.filter(action => action.moreParams && action.moreParams.function ? functions[action.moreParams.function] && functions[action.moreParams.function](action, row) : action);
  const actionsArr = searchActions.map(action => {

    return {
      ...action,
      disabled: action.moreParams && action.moreParams.function ? (action.moreParams.function && functions[action.moreParams.function] && !functions[action.moreParams.function](action, row)) :
        action.moreParams.disableCondition ? extractFunctions(action.moreParams.disableCondition, row) : null,
      hide: action.moreParams && action.moreParams.hideCondition && extractFunctions(action.moreParams.hideCondition, row),
    };
  });

  const actionsFilteredArr = actionsArr.filter(i => !i.hide)
  return actionsFilteredArr
}

/**
 * returns an array of workflow option list
 * @param {array} actionsList
 * @param {object} row
 */
export function extractCommonOptions(actionsList, row) {
  let optionsList = [];

  optionsList = actionsList[0].actions ? checkActions(actionsList[0].actions, row) : optionsList;
  if (actionsList[1] && actionsList[1].workflow) {
    const { access, role, workflowDetails, rootType, parentCompt } = actionsList[1].workflow;
    const workflowStates = getWorkflowSearchActions(role, workflowDetails, access, { currState: row.currentSequence, ...actionsList[1].workflow }, row, true, rootType, parentCompt);

    if (workflowStates) {
      Object.keys(workflowStates).forEach((value) => {
        optionsList = optionsList.concat(workflowStates[value]);
      })
    }
  }
  return optionsList;

}

/**
 * returns an array of search actions
 * @param {array} receivedActionList
 * @param {object} row
 * @param {object} history
 * @param {object} filter - used for preserve filter
 * @param {string} switchValue - used for preserve filter
 */
export function buildSearchActions(receivedActionList, row, history, filter, switchValue) {
  let actionList = [];
  let concatList = [];

  concatList = extractCommonOptions(receivedActionList, row);
  if (concatList && concatList.length > 0) {
    actionList = concatList.sort((a, b) => a.order - b.order).filter(item => {
      const hidden = item.hidden || hideIfNoPromotions(item, row);
      return !hidden;
    }).map(item => {
      let disabled = item.disabled || disabledIfLocked(item, row) || disabledForSupplierFunded(item, row);
      if (!disabled && item && item.moreParams && item.moreParams.enableField && item.moreParams.enableValue) {
        disabled = !item.moreParams.enableValue.includes(item.moreParams.enableField);
      }

      const startIcon = getButtonIcon(item);
      const hSearch = history.location&&history.location.search?{search:history.location.search}:{}

      return {
        ...item,
        disabled: disabled,
        id: row.id,
        label: item.label,
        startIcon,
        onClick: () => item.workflow && !item.label.includes('Reject')
          ? history.push({
            pathname: item.path,
            state: { loadingComplete: item.autoForward, moreParams: {
              autoForward: item.autoForward,
              execute: item.execute,
              move: item.move,
              label: item.label,
              sequence: item.sequence,
              currState: item.currState,
              save: item.save,
              view: item.view,
              viewAll: item.viewAll,
              mode: item.mode,
              path: item.path,
              order: item.order,
              filter: filter,
              ...hSearch,
              switchValue
            }}
          })
          : item.onClick(row.id, row, item)
      }
    })
  }

  return actionList;
}

/**
 * logic for if buttons should be disabled when the row is locked
 * @param {object} item
 * @param {object} row
 */
export function disabledIfLocked(item, row) {
  if (item && item.workflow && row && row.isLocked) {
    return !(item.label && ['view', 'emergency disable'].includes(item.label.toLowerCase()));
  }
  return false
}
export function disabledForSupplierFunded(item, row) {
  const isEnded = checkIsEnded(row);
  const isLongEnded = checkIsEnded(row, moment().subtract(31, 'days'))
  const isSupplierFunded = checkIsSupplierFunded(row);
  if (item && item.workflow && ((isSupplierFunded && isEnded) || isLongEnded)) {
    return !(item.label && ['view', 'emergency disable'].includes(item.label.toLowerCase()));
  }
  return false
}
/**
 * logic for if buttons should show when the item has a promotion attached
 * @param {object} item
 * @param {object} row
 */
export function hideIfNoPromotions(item, row) {
  if (item && item.moreParams && item.moreParams.canHide) {
    return !(row && row.overlapExist && (row.overlapExist === true || row.overlapExist === 'true'));
  }
  return false;
}

/**
 * logic for if buttons should show when the item has a promotion attached
 * @param {object} item
 * @param {object} row
 */
export function getButtonIcon(item) {
  const icon = getDeepValue(item, ['moreParams', 'icon'])
  const iconColor = getDeepValue(item, ['moreParams', 'iconColor'])
  if (!icon) {
    return null;
  }
  const style = {
    color: iconColor ? iconColor : undefined
  }

  switch(icon) {
    case('error'):
      return <ErrorIcon style={style} />
    case('warning'):
      return <WarningIcon style={style} />
    default:
      return null;
  }
}

/**
 * do the functions on row value
 * @param {array} functionValuesArray
 * @param {object} row
 */
export function getRowValuePostFunctionParse(functionValuesArray, row) {
  for (let i = 0; i < functionValuesArray.length; i++) {
    const functionValues = functionValuesArray[i];
    if (functionValues && functionValues.fieldKey) {
      const { funcName, params, searchInProp, fieldProperty } = functionValues;
      const paramValues = []
      // takes param values from row values
      for (let j = 0; j < params.length; j++) {
        const rowSearchProp = getDeepValue(row, [searchInProp]);
        const propKey = params[j];
        paramValues.push(getDeepValue(rowSearchProp, [propKey]) ? rowSearchProp[propKey] : row[propKey]);
        if (fieldProperty && Array.isArray(row[propKey])) {
          paramValues.push(fieldProperty)
        }
      }
      return functions[funcName](...paramValues);
    }
  }
}

/**
 * filter field map and hide if there's no display
 * @param {object} map
 * @param {array} hide
 */
export function filterFields(map, hide) {
  return Object.keys(map).reduce((acc, key) => {
    return (hide && hide.includes(key)) || !map[key].display
      ? acc
      : { ...acc, [key]: map[key] };
  }, {});
}

/**
 * filter field map and hide
 * @param {object} map
 * @param {array} hide
 */
export function filterFieldsAF(map, hide) {
  return Object.keys(map).reduce((acc, key) => {
    return (hide && hide.includes(key))
      ? acc
      : { ...acc, [key]: map[key] };
  }, {});
}

/**
 * handles row click
 * @param {
 *  index: number,
 *  event: *,
 *  values: array,
 *  rowData: object,
 *  history: *
 * } param0
 */
export function onRowClick({ index, event, values, rowData, history, filter, switchValue }) {
  var selection = window ? window.getSelection() : '';
  if (selection.type != "Range" || selection.toString().length === 0) {
    if (values && rowData) {
      const eventFunction = extractOnClick(values, rowData[index], history, filter, switchValue);
      if (eventFunction) {
        eventFunction(event);
      }
    }
  }
}

/**
 * handles header click
 * @param {
 *  dataKey: string,
 *  actions: string,
 *  actionsMultiSelect: string,
 *  filteredMap: object,
 *  props: object
 * } param0
 */
export function onHeaderClick({ dataKey, actions, actionsMultiSelect, filteredMap, sort, props }) {
  if (!dataKey || dataKey === actions || dataKey === actionsMultiSelect) {
    return;
  }
  if (filteredMap && filteredMap[dataKey] && !filteredMap[dataKey].sortable) {
    return;
  }

  createSortHandler(dataKey, { sort, props });
}

/**
 *  check if filter is empty
 * @param {
 *  specificFieldSearch: string,
 *  filter: object
 * } param0
 */
export function isEmptyFilter({ specificFieldSearch, filter }) {
  const fieldSearch = specificFieldSearch ? specificFieldSearch : 'searchAll';
  const searchField = filter && filter[fieldSearch];
  return !(searchField && searchField.search);
}
