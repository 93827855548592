import { BlobTypes, low, DateFormat } from '../../constants/Constants';
import { DATE_ACTIONS, DATE_FORMAT } from '../../compartments/search/getSearchDateActions';
import dateFormat from '../date/dateFormat';

function arrayToCSV(items, name, type, defaultValues = {}) {
  const headers = getHeaders(type);
  const csvArray = items.map((item) => {
    if (typeof item !== 'object') {
      return item+'\n';
    }
    return headers.reduce((prev, curr) => {
      const currentItem = item[curr] || defaultValues[curr];
      const formattedItem = currentItem ? (DATE_FORMAT.includes(curr) ? dateFormat(currentItem, DateFormat.DATE) : currentItem) : null;
      return prev ? prev+','+formattedItem : formattedItem;
    }, '')+'\n'
  }).filter((item) => !!item)
  const file = new File(csvArray, name, {type: 'text/csv'});
  return file;
}

function getHeaders(type) {
  if(low(type) === BlobTypes.CLUB_OVERRIDE) {
    return ['id', ...DATE_ACTIONS];
  }
  if(low(type) === low(BlobTypes.PROMO_CLONE)){
    return ['ParentPromotion','ParentCampaign','NewCampaign','InternalNamePrefix']
  }
  return ['id'];
}

export default arrayToCSV;
