import Colors from '../../theme/Colors'

const styles = theme => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: 8,
    '@media(min-width: 767px)': {
      justifyContent: 'center',
      padding: 32
    }
  },
  fullScreenModal: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: 0,
    justifyContent: 'flex-end'
  },
  clubListContainer: {
    outline: 'none',
    overflow: 'scroll',
    '@media(max-width: 767px)': {
      flex: 1
    },
    position: 'relative'
  },
  clubListContainerFull: {
    position: 'relative',
    outline: 'none',
    paddingTop: '12px',
    backgroundColor: Colors.white,
    overflow: 'scroll'
  },
  header: {
    backgroundColor: Colors.primaryBlue,
    color: Colors.white,
    padding: 16,
    boxSizing: 'border-box'
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8
  }
});

export default styles;
