import React, { useState } from 'react';
import clsx from 'clsx';
import useStyles from './DateInputStyles';
import TextInput from './TextInput';

function TimeInput(props) {
  const classes = useStyles();
  const { label, value, disabled, size, onChange, enablePast, defaultValue, maxDate, minDate, ...otherProps } = props;
  const [inputType, setinputType] = useState(false);
  const handleChange = (event) => {
    props.onChange({ target: { value: event.target.value } });
  };

  const timeDisabled = disabled || props.timeDisabled;

  return (
    <div className={classes.dateContainer}>
      <TextInput
        {...otherProps}
        label={label}
        disabled={timeDisabled}
        placeholder={label}
        type={(!inputType && !value) ? 'text' : 'time'}
        onFocus={() => setinputType(true)}
        defaultValue={defaultValue}
        value={!value ? '' : value}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        InputAdornmentProps={{
          className: clsx([classes.adornment, { [classes.adornmentSmall]: size === 'small' }])
        }}
        onChange={handleChange}
        inputProps={{
          step: 1,
        }}
      />
    </div>
  );
}

TimeInput.defaultProps = {
  size: 'small'
}

export default TimeInput;
