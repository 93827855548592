import React from 'react';
import {
  List
} from '@material-ui/core'
import useStyles from './FilterSelection.styles';
import FilterChoice from './FilterChoice';

/**
 * Component to render individual choices for a field.
 * @param {*} props
 */
function FilterSelection(props) {
  const { checked, choices, handleSelect, handleChildSelect, filterValue, type, maxLength, disabled } = props;
  const classes = useStyles();
  if (!choices) {
    return null;
  }
  /**
   * @type {array} choices for the field.
   */
  // filter out invalid values
  const renderedChoices = choices.filter((option) => option && option.label && option.value).sort((a, b) => a.label && a.label.localeCompare(b.label));
  const renderedChoicesSlice = maxLength ? renderedChoices.slice(0, maxLength) : renderedChoices;
  return (
    <List className={classes.selectionList} disablePadding>
      {renderedChoicesSlice.map((choice, index) => (
        <FilterChoice
          key={index + ''}
          type={type}
          choice={choice}
          handleSelect={handleSelect}
          checked={checked}
          handleChildSelect={handleChildSelect}
          filterValue={filterValue}
          disabled={disabled}
          childOptionsLoading={props.childOptionsLoading}
        />
      ))}
    </List>
  );
}

export default React.memo(FilterSelection);
