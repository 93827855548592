/* eslint-disable no-fallthrough */
import { locatedError } from 'graphql';
import { FieldTypes, DateFormat, SearchQueryType } from '../../constants/Constants';
import dateFormat from '../date/dateFormat'

/**
 * convertFilterToString is
 * @param {*} filterParam = {
 *  status: {
 *    data: {
 *       type: "STRING"
 *        values: [
 *          {
 *            label: "To Assign/Publish"
              value: "To Assign/Publish"
 *          }
 *        ]
 *    },
 *    selected: ["To Assign/Publish", "New"],
 *    search: 'searched value',
 *  },
 *  searchAll : null
 * }
 * @param {*} useSearch  true always for now
 * @param {*} searchParams = {
 *  condition: '||',
 *  filterKeys: 'assign_merchant, created_by'
 * }
 * @param {*} showAllOffersSwitch - show for merchant role offers
 * @param {*} defaultSearchValue - "merchant_assignment:/.*v0k00lq.*"
 * searchAll prop will have data when user search the data from search input and not by filter
 * filterQuery - variable which appends the previous query and current query - when values from multiple fields are selected
 * @example Ended value from Status field and Tio from Targeting field
 * defaultSearchQuery - variable to build default search query in View Merchant Offers
 */
function convertFilterToString({ filterParam, useSearch, searchParams, showAllOffersSwitch, defaultSearchValue }) {
  if (!filterParam) {
    return {};
  }
  const filter = { ...filterParam };

  let filterQuery = '';
  let searchQuery = '';
  let defaultSearchQuery = '';

  for (const filterKey in filter) {
    const currentFilter = filter[filterKey];
    const { childOptions } = currentFilter || {};
    // childOptions prop exists when offer is created with item selection (when category is selected)
    if (childOptions) {
      for (const childKey in childOptions) {
        const mainFilter = filter[childKey] || {};
        const childFilter = childOptions[childKey] || {};
        filter[childKey] = {
          ...childFilter,
          ...mainFilter,
          selected: [...(mainFilter.selected || []), ...(childFilter.selected || [])]
        };
      }
    }
  }

  Object.keys(filter).forEach((key) => {
    if (filter[key] && filter[key].data && filter[key].data.type) {
      const type = filter[key].data.type;
      const option = filter[key].option;
      const exclude = filter[key].exclude;
      const isFullTextSearch = filter[key].data.searchType === SearchQueryType.fullText;

      switch (type) {
        case (FieldTypes.DATE):
        case (FieldTypes.DATE2): {
          let query;

          const before = filter[key].before;
          const after = filter[key].after;
          switch (option) {
            case ('before'):
              if (before) {
                query = `(${key} lt ${dateFormat(before, DateFormat.INDEX)})`
              }
              break;
            case ('range'):
              if (before && after) {
                query = `(${key} lt ${dateFormat(before, DateFormat.INDEX)} and ${key} gt ${dateFormat(after, DateFormat.INDEX)})`
              } else {
                return;
              }
              break;
            case ('after'):
            default:
              if (after) {
                query = `(${key} gt ${dateFormat(after, DateFormat.INDEX)})`
              }
              break;
          }

          filterQuery = filterQuery ? `(${filterQuery}) and (${query})` : query;
        } break;
        case (FieldTypes.NUMBER):
        case (FieldTypes.SEARCH):
        case (FieldTypes.STRING): {
          /**
           * searchQuerySplit - variable used to form query for each searched word in search input
           * @example filter[key].search = "first second". For each search iteration, query is generated for first and second.
           */
          let filterQuerySplit = '';
          let searchQuerySplit = '';
          // search property is the value of the search input box
          const filterSearch = filter[key].search && filter[key].search.trim();
          if (filterSearch) {
            // split search value by space
            let search = isFullTextSearch ? [filterSearch] : filterSearch.split(/(?:, )|,| /g);
            search = search.filter(e => e != '');
            if (search.some(isNaN)) {
              search = search.length > 1 && filterSearch.includes(',') ? search : [filterSearch];
            }

            // exact search with filter to handle more items
            if (option === 'exact') {
              search = search.map(text => text.replace(/[']/g, `'$&`));  // escape single quotes (special character in filter)
              const selectedString = search.join(',');
              const query = `search.in(${key.replace(/\./g, '/')}, '${selectedString}')`;

              filterQuerySplit = filterQuerySplit ? `${filterQuerySplit} or ${query}` : query;
            } else {
              search.forEach((searchValue, index) => {
                let value = searchValue.trim();
                if (searchValue && value) {
                  if (useSearch) {
                    value = value.replace(/["/.*+\-?^${}()|[\]\\]/g, '\\$&'); // escape strings used for regular expressions
                    let query;
                    const isSearchAll = key === 'searchAll';
                    if (isSearchAll) {
                      if (+value) {   // When seachValue has number
                        query = `${value}`;
                      } else {
                        query = `.*${value}.*`;
                      }
                    } else {
                      switch (option) {
                        case ('endsWith'):
                          query = `.*${value}`;
                          break;
                        case ('startsWith'):
                          query = `${value}.*`;
                          break;
                        case ('contains'):
                        default:
                          query = `.*${value}.*`;
                          break;
                      }
                    }
                    searchQuerySplit = searchQuerySplit
                      ? `${searchQuerySplit}|${query}`
                      : (
                        isSearchAll
                          // eslint-disable-next-line no-useless-escape
                          ? `/${query}`
                          : `${key}:/${query}`
                      );
                    if (searchQuerySplit) {
                      if (search.length - 1 === index) {
                        searchQuerySplit += '/';
                      }
                    }
                  } else {
                    value = value.replace(/[']/g, `'$&`);  // escape single quotes (special character in filter)
                    const query = `search.ismatch('"${value}"', '${key}')`;

                    filterQuerySplit = filterQuerySplit ? `${filterQuerySplit} or ${query}` : query;
                  }
                }
              })
            }
          }
          const ex = exclude ? '/.*/-' : '';
          if (searchQuerySplit) {
            if (searchParams && searchParams.condition && showAllOffersSwitch() && searchParams.filterKeys.indexOf(key) >= 0) {
              if (!defaultSearchValue) {
                defaultSearchQuery = defaultSearchQuery ? `${defaultSearchQuery} ${searchParams.condition} ${ex}(${searchQuerySplit})` : `${ex}(${searchQuerySplit})`;
                const searchParamsArr = searchParams.filterKeys.split(',')
                if (searchParamsArr[searchParamsArr.length - 1] === key) {
                  defaultSearchQuery = `(${defaultSearchQuery})`
                }
              }
            } else {
              searchQuery = searchQuery ? `${searchQuery} && ${ex}(${searchQuerySplit})` : `${ex}(${searchQuerySplit})`;
            }
          }
          if (filterQuerySplit) {
            filterQuery = filterQuery ? `(${filterQuery}) and (${filterQuerySplit})` : `(${filterQuerySplit})`;
          }

          if (filter[key] && filter[key].selected && filter[key].selected.length) {
            let selectedString = filter[key].selected.reduce((prev, curr) => {
              if (prev) {
                /**
                 * if selected property has more than 1 value
                 * @example filter[key].selected = ["To Assign/Publish", "New"]
                 */
                return `${prev},${curr}`
              } else {
                return `${curr}`
              }

            }, null)
            selectedString = selectedString.replace(/[']/g, `'$&`);  // escape single quotes (special character in filter)
            const query = `search.in(${key.replace(/\./g, '/')}, '${selectedString}', ',')`;

            filterQuery = filterQuery ? `(${filterQuery}) and (${query})` : query;
          }
        } break;
        case (FieldTypes.COMPLEX): {
          /** This is the case for supplier field. Also used for Task screen */
          if (filter[key] && filter[key].selected && filter[key].selected.length) {
            const queryProperty = filter[key]?.data?.queryProperty || key
            const parentNode = queryProperty.split('/')[0]
            const selectedString = filter[key].selected.reduce((prev, curr) => {
              if (prev) {
                return `${prev} or ${queryProperty} eq '${curr}'`
              } else {
                return `${queryProperty} eq '${curr}'`
              }

            }, null)
            const query = `${filter[key]?.data?.queryProperty ? key : parentNode}/any(${parentNode}: ${selectedString})`;
            filterQuery = filterQuery ? `(${filterQuery}) and (${query})` : query;
          }
          break;
        }
        case (FieldTypes.PROMO_LABEL):
        {
          const searchType = filter[key].data.option;
          let searchIndex;
          if ( key.search(":") > 0) {
            const filterKey = key.split( ":" );
            searchIndex = filterKey[ 0 ];
          }

          let filterQuerySplit = '';

          // having any labels v. having all labels
          const conjunction = searchType === "any" ? "or" : "and";

          // search property is the value of the search input box
          const filterSearch = filter[ key ].search && filter[ key ].search.trim();
          if ( filterSearch ) {
            // remove whitespaces and split search value by comma
            let search = filterSearch.replace(/\s/g, "").split( ',' );
            search = search.filter( e => e != '' );
            if ( search.some( isNaN ) ) {
              search = search.length > 1 && filterSearch.includes( ',' )
                ? search
                : [ filterSearch ];
            }

            search.forEach( ( searchValue ) => {
              let value = searchValue.trim();
              if ( searchValue && value ) {
                if ( useSearch ) {
                  value = value.replace( /["/.*+\-?^${}()|[\]\\]/g, '\\$&' ); // escape strings used for regular expressions
                }

                value = value.replace( /[']/g, `'$&` );  // escape single quotes (special character in filter)
                const query = `search.ismatch('${value}', '${searchIndex}')`;

                filterQuerySplit = filterQuerySplit
                  ? `${filterQuerySplit} ${conjunction} ${query}`
                  : query;
              }
            } )
          }

          if ( filterQuerySplit ) {
            filterQuery = filterQuery
              ? `(${filterQuery}) and (${filterQuerySplit})`
              : `(${filterQuerySplit})`;
          }

          break;
        }
      }
    }
  })

  return { search: searchQuery, filter: filterQuery, defaultSearchQuery: defaultSearchQuery }
}

export default convertFilterToString;
