import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';

const styles = {
  radioLabel: {
    color: 'rgba(36, 37, 61, 0.5) !important',
    fontSize: 12
  },
  radioGroup: {
    display: 'flex'
  },
  radioGroupError: {
    '& .MuiRadio-root': {
      color: `${Colors.red} !important`
    }
  },
  radioColumn: {
    flexDirection: 'column'
  },
  formHelperError: {
    color: '#f44336',
    margin: '-3px 22px'
  }
};

export default makeStyles(styles);
