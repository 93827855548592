/*
  get override steps on filtered workflow for the role
*/

import { AccessLevels } from '../../constants/access-levels';

export default function getOverrideSteps(workflow, activeStep, userStepAcess){
  // let lastSkip = activeStep;
  // let isFound = false;
  let foundItem = null;

  const nextState = workflow.find((state) => state.sequence === (activeStep + 1) && state.uiReadable);

  if(nextState &&  userStepAcess && userStepAcess[nextState.name.toLowerCase()] === AccessLevels.OVERRIDE) {
    const findLastOptionalState = workflow.filter((state)=> state.sequence > nextState.sequence && state.name && state.uiReadable && userStepAcess[state.name.toLowerCase()] === AccessLevels.ADMIN );
    if(findLastOptionalState && findLastOptionalState.length >0){
      const optionalLength = findLastOptionalState.length;
      const foundStateDetails = findLastOptionalState[optionalLength -1 ];
      const foundState = {};
      foundState.sequence = foundStateDetails.sequence;
      foundState.name = foundStateDetails.name;
      foundState.template = foundStateDetails.templateId;
      foundState.description = foundStateDetails.description;
      foundItem = foundState;
      foundState.overrides = foundStateDetails.overrides;
    }
  }
  return foundItem;
}
