import { makeStyles } from '@material-ui/core/styles'
import Colors from '../../theme/Colors';
import { fontRegular } from '../../theme/theme';

const styles = () => ({
  fullScreenModal: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: 0,
    justifyContent: 'flex-end'
  },
  closeButton: {
    padding: 6
  },
  modalContainer: {
    width: '100vw',
    position: 'relative',
    outline: 'none',
    backgroundColor: Colors.white,
    boxSizing: 'border-box',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  childContainer: {
    width: '100%',
    flex: 1,
    overflow: 'hidden'
  },
  barContainer: {
    // height: 116,
    // minHeight: 116,
    width: '100%',
    borderBottom: `1px solid ${Colors.blueGrey300}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 4
  },
  titleText: {
    fontWeight: fontRegular,
    marginLeft: 16,
    color: Colors.grey500,
    textTransform: 'capitalize'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  swipeView: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto'
  },
  closeIcon: {
    color: Colors.grey900
  },
  subHeader: {
    height: 60,
    minHeight: 60,
    // backgroundColor: 'red',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 4
  },
  secondaryTitle: {
    marginLeft: 16,
    marginTop: 2,
    fontSize: 16,
    color: Colors.grey500
  }
});

export default makeStyles(styles);
