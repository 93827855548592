import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import useStyles from './CardInfo.styles';
import Circle from '../../icons/Circle';
import getCompartmentTypes from '../../../Utils/compartments/getCompartmentTypes';


function CardInfo({ item, searchType }) {
  const classes = useStyles();

  function renderDetail(title, value) {
    if (value) {
      return (
        <>
          <ListItem className={classes.detail}>
            <Typography>{title}</Typography>
            <Typography className={classes.detailValue}>{value}</Typography>
          </ListItem>
          <Divider />
        </>
      )
    }
    return null;
  }

  function renderColor(value) {
    if (value) {
      return (
        <>
          <Circle color={value} />
          <Typography noWrap component='span'>{value}</Typography>
        </>
      )
    }
    return null;
  }

  function renderInfo() {
    switch(searchType) {
      case(getCompartmentTypes.BRANDING):
        return renderBranding();
      default:
        return 'No Info'
    }
  }

  function renderBranding() {
    return (
      <>
        {renderDetail('Name', item.name)}
        {renderDetail('Description', item.description)}
        {renderDetail('Badge Text', item.badgeText)}
        {renderDetail('Foreground Color', renderColor(item.foregroundColor))}
        {renderDetail('Background Color', renderColor(item.backgroundColor))}
        {renderDetail('Outline Color', renderColor(item.outlineColor))}
        {renderDetail('Corner Radius', item.cornerRadius)}
      </>
    )
  }

  return renderInfo();


}

export default CardInfo;
