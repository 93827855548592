import React from "react";
import DateInput from '../../widgets/inputs/DateInput';
import TimeInput from '../../widgets/inputs/TimeInput';
import SelectInput from '../../widgets/inputs/SelectInput';
import BooleanInput from '../../widgets/inputs/BooleanInput';

export const DATE_ACTIONS_PROPS = {
  startDate: {
    key: 'start_date',
    dataType: 'date',
    propName: 'startDate',
    required: true,
    size: 'small',
    label: 'start date',
    placeholder: 'start date'
  },
  startTime: {
    key: 'start_time',
    dataType: 'time',
    propName: 'startTime',
    size: 'small',
    label: 'start time',
    defaultValue: '12:00:00 am',
    placeholder: 'start time'
  },
  endDate: {
    key: 'end_date',
    dataType: 'date',
    propName: 'endDate',
    required: true,
    size: 'small',
    label: 'end date',
    placeholder: 'end date'
  },
  endTime: {
    key: 'end_time',
    dataType: 'time',
    propName: 'endTime',
    size: 'small',
    label: 'end time',
    defaultValue: '11:59:59 pm',
    placeholder: 'end time'
  },
  timezone: {
    key: 'schedule_timezone',
    dataType: 'select',
    propName: 'timezone',
    required: true,
    size: 'small',
    label: 'Timezone',
    placeholder: 'Timezone',
    options: [{value: "CST", label: "Central Standard Time"}]
  }
}
export const DATE_ACTIONS = Object.keys(DATE_ACTIONS_PROPS);
export const DATE_FORMAT = ['startDate', 'endDate'];

const getSearchActions = ({ setExtraValue }) => {
  const commonStyle = { margin: 2 };
  return [
    {
      props: {
        ...DATE_ACTIONS_PROPS.startDate,
        onChange: setExtraValue,
        style: { width: 100, ...commonStyle }
      },
      actionType: 'setExtraValue',
      comp: <DateInput />
    },
    {
      props: {
        ...DATE_ACTIONS_PROPS.startTime,
        onChange: setExtraValue,
        style: { width: 100, ...commonStyle }
      },
      actionType: 'setExtraValue',
      comp: <TimeInput />
    },
    {
      props: {
        ...DATE_ACTIONS_PROPS.endDate,
        onChange: setExtraValue,
        style: { width: 100, ...commonStyle }
      },
      actionType: 'setExtraValue',
      comp: <DateInput />
    },
    {
      props: {
        ...DATE_ACTIONS_PROPS.endTime,
        onChange: setExtraValue,
        style: { width: 100, ...commonStyle }
      },
      actionType: 'setExtraValue',
      comp: <TimeInput />
    },
    // {
    //   props: {
    //     dataType: 'boolean',
    //     propName: 'clubLocalTime',
    //     onChange: setExtraValue,
    //     size: 'small',
    //     label: 'Club local time',
    //     style: { ...commonStyle, width: 230 }
    //   },
    //   actionType: 'setExtraValue',
    //   comp: <BooleanInput />
    // }
    {
      props: {
        ...DATE_ACTIONS_PROPS.timezone,
        onChange: setExtraValue,
        style: { ...commonStyle, width: 200 }
      },
      actionType: 'setExtraValue',
      comp: <SelectInput />
    }
  ];
}

export default getSearchActions;
