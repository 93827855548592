import React from 'react';
import {
  Typography,
  IconButton
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import RefreshIcon from '@material-ui/icons/Refresh';
import useStyle from './ErrorComponent.style';

function ErrorComponent(props) {
  const classes = useStyle();
  const { message, onClick } = props;

  return (
    <div className={classes.root}>
      <ErrorIcon className={classes.icon} />
      <Typography variant='h4' className={classes.message}>{message}</Typography>
      {onClick ? (
        <IconButton className={classes.button} onClick={onClick}>
          <RefreshIcon className={classes.buttonIcon} />
        </IconButton>
      ) : null }
    </div>
  )
}

export default ErrorComponent;
