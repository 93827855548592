import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import {
  withStyles
} from '@material-ui/core';
import styles from './UsersList.styles';
import Search from '../search/search';
import FullScreenModal from '../../widgets/modal/FullScreenModal';

function UsersList({ classes, isModal = true, save, cancel, open, hasActions, subSearchType, callSave, queries, ...otherProps }, ref) {
  const childSearch = useRef(null);

  useImperativeHandle(ref, () => ({
    async getSelected() {
      const selectedProps = await childSearch.current.getSelected();

      return selectedProps;
    }
  }));

  const component = (
    <Search
      ref={childSearch}
      {...otherProps}
      searchType='users'
      multiSelect
      isModal={isModal}
      disableActions
      subSearchType={subSearchType}
      callSave={callSave}
      queries={queries}
    />
  )

  if (isModal) {
    return (
      <FullScreenModal
        open={open}
        onRequestClose={cancel}
        title='Users List'
      >
        {component}
      </FullScreenModal>
    );
  } else {
    return component
  }
}

const testComponent = forwardRef(UsersList)
export { testComponent as TestUsersList }
export default withStyles(styles)(forwardRef(UsersList));
