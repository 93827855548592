import React from 'react';
import MaterialRadio from '@material-ui/core/Radio';

function Radio(props) {

  function onClick(event) {
    event.stopPropagation();
    if (props.onClick) {
      props.onClick();
    }
  }

  return (
    <MaterialRadio
      {...props}
      onClick={onClick}
    />
  )
}

export default Radio;
