import getDeepValue from '../../getDeepValue';
import { changeFieldData } from '../../../compartments/templates/templateReducer';

//When selecting parent enum select all child enum
function setEnumChildren(state, parentTemplateData = {}, { value }, key, ...fieldsAndIds) {
  let newState = { ...state };
  for (let i = 0; i < fieldsAndIds.length; i = i + 2) {
    const field = fieldsAndIds[i];
    const id = fieldsAndIds[i + 1];
    const [groupName, fieldName] = field.split('.');
    const metadata = getDeepValue(state, [groupName, 'fields', fieldName, 'metadata']);
    const { values, queries } = metadata;
    const group = getDeepValue(state, [groupName]);
    const fieldObj = getDeepValue(group, ['fields', fieldName]);
    if (value === 'ALL' || (value && value.split(',').includes(id))) {
      let newValue;
      if (queries && queries.sendAll) {
        newValue = 'ALL';
      } else {
        newValue = Object.keys(values).join(',');
      }
      newState = changeFieldData(newState,  { field: fieldObj, group, [key]: newValue }, true)
    } else {
      newState = changeFieldData(newState,  { field: fieldObj, group, [key]: '' }, true)
    }
  }
  return newState;
}

export default setEnumChildren;
