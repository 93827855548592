import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import SearchCard from '../../../widgets/card/SearchCard/SearchCard';
import CompartmentCard from '../../../widgets/card/SearchCard/CompartmentCard';
import { FieldMap, SearchTypes } from '../../../constants/Constants';
import useStyles from './CardSearch.styles';
import { filterRowData, sortRowData } from '../../../Utils/search/dataManipulation';
import SelectInput from '../../../widgets/inputs/SelectInput';
import SearchInput from '../../../widgets/search/search';
import CardInfo from '../../../widgets/search/CardInfo/CardInfo';
import BackgroundProcessingBar from '../../../widgets/bars/BackgroundProcessingBar';


function CardSearch({ setEnableParentAction, searchList, searchType, rootType, currentCompartment, loading, isModal, ...otherProps }, ref) {
  const classes = useStyles();
  const fieldMap = otherProps.fieldMap ? otherProps.fieldMap : FieldMap[searchType];
  const [selected, setSelected] = useState({});
  const [rowData, setRowData] = useState([]);
  const [search, setSearch] = useState({
    filter: null,
    sort: null
  })
  const [searchAll, setSearchAll] = useState('');
  const [infoModal, setInfoModal] = useState({
    anchorEl: null,
    item: null,
    open: false
  });

  useImperativeHandle(ref, () => ({
    getSelected() {
      if (selected.id) {
        return {
          selectedRowsarray: [selected]
        };
      } else {
        return {
          selectedRowsarray: []
        };
      }
    }
  }));

  const sortOptions = React.useMemo(() => {
    const options = [];
    for (const field in fieldMap) {
      options.push({
        value: field,
        label: fieldMap[field].shortDesc
      })
    }
    return options;
  }, [fieldMap])

  useEffect(() => {
    const newRowData = sortRowData(filterRowData(searchList, search.filter), search.sort);
    setRowData(newRowData);
  }, [search.filter, search.sort, searchList]);

  function onChangeSearch({ filter, sort }) {
    setSearch(search => ({
      sort: sort || search.sort,
      filter: filter || search.filter
    }));
  }

  function onChangeSearchAll(event) {
    const value = event.target.value;
    setSearchAll(value);
  }

  function onChangeSort(event) {
    const value = event.target.value;
    onChangeSearch({
      sort: {
        property: value,
        orderBy: 'asc',
        type: fieldMap[value] ? fieldMap[value].type : null
      }
    })
  }

  function onChangeFilter() {
    const field = 'searchAll';
    const newFilter = { ...search.filter }
    const fieldMapData = {
      siblings: Object.keys(fieldMap),
      type: 'STRING'
    };
    newFilter[field] = { ...newFilter[field], search: searchAll, data: { ...fieldMapData } };
    onChangeSearch({filter: newFilter})
  }

  const openInfoModal = (item) => (event) => {
    event.stopPropagation();
    setInfoModal({
      item: item,
      anchorEl: event.currentTarget,
      open: true
    })
  }

  function closeInfoModal() {
    setInfoModal((infoModal) => ({
      item: infoModal.item,
      anchorEl: null,
      open: false
    }))
  }

  const cards = React.useMemo(() => {
    if (rowData) {
      return rowData.map((item, index) => (
        <Grid item key={index + item.name} className={classes.item}>
          {searchType === SearchTypes.COMPARTMENT ? (
            <CompartmentCard
              item={item}
              rootType={rootType}
              campaignDetails={otherProps.campaignDetails}
              currentCompartment={currentCompartment}
            />
          ) : (
            <SearchCard
              item={item}
              selected={selected.id === item.id}
              onClick={() => {
                setSelected(item)
                if (setEnableParentAction) {
                  setEnableParentAction(true);
                }
              }}
              onInfoClicked={openInfoModal(item)}
              searchType={searchType}
            />
          )}
        </Grid>
      ))
    }
    return [];
  }, [rowData, selected.id, searchType]);

  function renderInfoModal() {
    return (
      <Paper
        elevation={3}
        square
        className={classes.infoContainer}
      >
        <div className={classes.closeInfoContainer}>
          <IconButton onClick={closeInfoModal}>
            <Close />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.cardInfoContainer}>
          {infoModal && infoModal.item ? <CardInfo item={infoModal.item} searchType={searchType} /> : null}
        </div>
      </Paper>
    )

  }

  if (loading || (fieldMap && Object.keys(fieldMap).length === 0)) {
    return (
      <div className={classes.skeletonContainer}>
        <Skeleton
          wrapper={({ children }) => (
            <div className={classes.skeletonWrapper}>{children}</div>
          )}
          height={132}
          count={3}
        />
      </div>
    );
  }

  return (
    <>
      <div className={classes.root}>
        <div className={clsx(classes.container, classes.topContainer)}>
          <Grid container spacing={3} direction='row' justify='center' alignItems='center' className={classes.searchContainer}>
            <Grid item className={classes.item}>
              <div className={classes.gridItem}>
                <SelectInput
                  value={search.sort ? search.sort.property : null}
                  onChange={onChangeSort}
                  fullWidth
                  placeholder='Select a value to sort on'
                  label='Sort'
                  options={sortOptions}
                />
              </div>
            </Grid>
            <Grid item className={classes.item}>
              <div className={classes.gridItem}>
                <SearchInput onChangeSearch={onChangeSearchAll} onClickSearch={onChangeFilter} className={classes.searchInput} />
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={3} direction='row' justify='flex-start' alignItems='flex-start' className={classes.container}>
          {cards}
        </Grid>
      </div>
      <Popover
        anchorEl={infoModal.anchorEl}
        onClose={closeInfoModal}
        open={infoModal.open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {renderInfoModal()}
      </Popover>
      {isModal ? null : <BackgroundProcessingBar style={{bottom: 0}} />}
    </>
  );
}

export default forwardRef(CardSearch);
