import React, { useState, useEffect } from 'react';
import {
  Menu,
  MenuItem,
  Checkbox
} from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import useStyles from './ChipDropdown.styles';
import Chip from './Chip';
import getValueFromOptions from '../../Utils/select/getValueFromOptions'

function ChipDropdown(props) {
  const classes = useStyles();
  const { onDelete, onChange, options, value, label, iconOnly, icon, disabled, multi, firstAll } = props;
  const isDisabled = disabled || !options || options.length === 0;
  const [anchorEl, setAnchorEl] = useState(null);

  const firstValue = options && options[0] ? options[0].value : ''

  let selected
  if (multi) {
    selected = value ? value : [];
  } else {
    selected = value ? value : firstValue;
  }

  const setValue = (value) => () => {
    if (multi) {
      let newValue;
      if (props.value) {
        newValue = [...props.value];
      } else {
        newValue = [];
      }

      if (firstAll && value === firstValue) {
        newValue = [];
      } else {
        const index = newValue.indexOf(value);
        if (index >= 0) {
          newValue = newValue.slice(0, index).concat(newValue.slice(index+1));
        } else {
          newValue.push(value);
        }
      }

      onChangeSelect(newValue);
    } else {
      closeMenu();
      onChangeSelect(value);
    }
  }

  function showMenu(event) {
    setAnchorEl(event.target);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function onChangeSelect(value) {
    onChange({target: {value}});
  }

  function multiSelected(value, index) {
    if (firstAll) {
      if (index === 0) {
        return selected.length === 0 || selected.includes(firstValue)
      } else {
        return selected.includes(value)
      }
    } else {
      return selected.includes(value)
    }
  }

  function makeLabel() {
    let selectedDesc;
    if (multi) {
      if (firstAll && selected.length === 0) {
        selectedDesc = options && options.length > 0 ? getValueFromOptions(options, firstValue).label : firstValue
      } else {
        selectedDesc = ''
        for (let i = 0; i < selected.length; i++) {
          const desc =  options && options.length > 0 ? getValueFromOptions(options, selected[i]).label : selected[i]
          selectedDesc = selectedDesc ? selectedDesc + ', ' + desc : desc
        }
      }
    } else {
      selectedDesc = options && options.length > 0 ? getValueFromOptions(options, selected).label : selected
    }
    return selectedDesc ? selectedDesc : label;
  }

  return (
    <div>
      <Chip
        label={makeLabel()}
        onDelete={onDelete}
        icon={icon ? icon : <KeyboardArrowDown />}
        onClick={showMenu}
        iconOnly={iconOnly}
        disabled={isDisabled}
      />
      {!isDisabled ? (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeMenu}>
          {options.map(({label,value}, index) => (
            <MenuItem
              key={index}
              classes={{
                root: classes.menuItem,
                selected: classes.menuItemSelected
              }}
              selected={multi ? undefined : value === selected}
              onClick={setValue(value)}
            >
              {multi ? <Checkbox checked={multiSelected(value, index)} /> : null}
              {label}
            </MenuItem>
          ))}
        </Menu>
      ) : null }
    </div>
  )
}

export default React.memo(ChipDropdown);
