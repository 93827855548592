import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function checkBiglistValuesService(biglistName, values) {
  try {
    const body = {
      queries: {
        ids: values.join(',')
      }
    }
    const response = await fetch(`${baseURL}biglist/${biglistName}/search/check`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export default checkBiglistValuesService
