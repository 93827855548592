import { FieldTypes } from '../../constants/Constants';
import dateFormat from '../date/dateFormat';

export function filterRowFromKeys(row, filterValue) {
  const filterKeys = Object.keys(filterValue);
  let found = 0;

  const emptyFilters = !filterKeys.reduce((prev, cur) => prev && filterValue[cur], true);
  if (emptyFilters) {
    return true;
  }

  filterKeys.forEach((key) => {
    const rowValue = (row.values ? row.values[key] : row[key]) + '';
    const value = rowValue.toLowerCase();
    const filterKeyValue = filterValue[key];
    if (!filterKeyValue) {
      return;
    }
    if (!filterKeyValue.data) { // no type (i.e. number, string, etc.)
      found++;
      return;
    }
    const searchValue = filterKeyValue.search;
    const search = searchValue && searchValue.toLowerCase();
    const siblings = filterKeyValue.data && filterKeyValue.data.siblings;
    const selection = filterKeyValue.selected && filterKeyValue.selected.length ? filterKeyValue.selected : null;
    switch (filterKeyValue.data.type) {
      case FieldTypes.DATE:
      case FieldTypes.DATE2: {
        const { before, after, option } = filterKeyValue;
        const rowDate = new Date(rowValue).getTime();
        switch (option) {
          case 'before':
            if (!before || (
              rowDate && new Date(before).getTime() > rowDate
            )) {
              found++;
            }
            break;
          case 'after':
            if (!after || (
              rowDate && new Date(after).getTime() < rowDate
            )) {
              found++;
            }
            break;
          case 'range': {
            if (!before || !after || (
              rowDate && new Date(after).getTime() < rowDate && new Date(before).getTime() > rowDate
            )) {
              found++;
            }
            break;
          }
          default: {
            const dayMs = 8.64e+7;
            if (!option || (rowDate && (new Date().getTime() - (dayMs * (+option))) < rowDate)) {
              found++;
            }
            break;
          }
        }
        break;
      }
      case FieldTypes.NUMBER:
        switch (filterKeyValue.option) {
          case 'under':
            if (+searchValue > +value) {
              found++;
            }
            break;
          case 'over':
            if (+searchValue < +value) {
              found++;
            }
            break;
          case 'range':
            if (+rowValue > +filterKeyValue.lowRange && +rowValue < +filterKeyValue.highRange) {
              found++;
            }
            break;
          case 'contains':
          default:
            if (
              (search && value.includes(search)) // if search is found or
              || (!searchValue && !selection) // if search and selection is empty
            ) {
              found++;
            }
            break;
        }
        break;
      case FieldTypes.STRING:
      default:
        if (search) {
          let regex;
          switch (filterKeyValue.option) {
            case 'startsWith':
              regex = new RegExp('^' + search, 'i');
              break;
            case 'endsWith':
              regex = new RegExp(search + '$', 'i');
              break;
            case 'contains':
            default:
              regex = new RegExp(search, 'i');
              break;
          }
          if (
            regex.test(rowValue) // if search is found
            || (
              siblings // if search is found on siblings
              && siblings.length
              && siblings.find(sib => {
                const rowSiblingValue = row.values ? row.values[sib] + '' : row[sib] + '';
                const rowSiblingDateValue = dateFormat(rowSiblingValue, 'MM-DD-YYYY') //handle date
                if (rowSiblingDateValue === 'Invalid date') {
                  return regex.test(rowSiblingValue);
                } else {
                  return regex.test(rowSiblingValue) || regex.test(rowSiblingDateValue);
                }

              })
            )
          ) {
            found++;
          }
        } else {
          if (
            (!searchValue && !selection) // if search and selection is empty
            || (selection && rowValue && selection.find(selected => new RegExp(rowValue, 'i').test(selected))) // check selection (checkboxes on filter)
          ) {
            found++;
          }
        }
        break;
    }
  });

  return found === filterKeys.length;
}

export function filterRowData(dataList, filterValue) {
  if (filterValue && dataList) {
    return dataList.filter((row) => filterRowFromKeys(row, filterValue));
  }
  return dataList;
}

export function sortRowData(dataList, sortValue, sortSelected) {
  if (dataList && ((sortValue && sortValue.property) || sortSelected)) {
    const sortedRows = [...dataList];
    const { property, orderBy, type } = sortValue || {};
    sortedRows.sort((left, right) => {
      if (sortSelected && !property) { // no property, just sort by selected
        if (left.selected !== right.selected) {
          return left.selected ? -1 : 1;
        }
        return 0;
      }
      const leftVal = left.values ? left.values[property] : left[property];
      const rightVal = right.values ? right.values[property] : right[property];
      const leftString = leftVal + '';
      const rightString = rightVal + '';
      if (sortSelected && left.selected !== right.selected) { // sort by selected
        return left.selected ? -1 : 1;
      } else if (orderBy === 'asc') {
        if (type === FieldTypes.DATE || type === FieldTypes.DATE2) {
          return new Date(leftVal).getTime() - new Date(rightVal).getTime();
        }
        return leftString.localeCompare(rightString);
      } else {
        if (type === FieldTypes.DATE || type === FieldTypes.DATE2) {
          return new Date(rightVal).getTime() - new Date(leftVal).getTime();
        }
        return rightString.localeCompare(leftString);
      }
    });
    return sortedRows;
  }
  return dataList;
}

export function filterDataToInputSearchGql(filter) {
  const keys = Object.keys(filter);
  return keys.reduce((acc, key) => {
    const filterData = filter[key];
    if (filterData.selected) {
      const search = filterData.search ? filterData.search + ', ' : '';
      return {
        ...acc,
        [key]: {
          search: search + filterData.selected.join(', ')
        }
      }
    }
    return {
      ...acc,
      [key]: { search: filterData.search }
    };
  }, {});
}
