import React, { useState } from 'react';
import clsx from 'clsx';
import ChromePicker from 'react-color/lib/Chrome';
import Popover from '@material-ui/core/Popover';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Palette from '@material-ui/icons/Palette';
// import clsx from 'clsx';
import useStyles from './ColorPickerInputStyles';
import TextInput from './TextInput';

function ColorPickerInput(props) {
  const classes = useStyles();
  const { value = '', disabled, onChange, ...otherProps } = props;
  const [open, setOpen] = useState(null);

  function handleOnChange(color) {
    onChange({target: { value: color.hex}});
  }

  function handleClose() {
    setOpen(null);
  }

  function handleOpen(event) {
    setOpen(event.currentTarget);
  }

  function handleChangeTextInput(event) {
    const _value = event.target.value;
    const newValue = _value.replace(new RegExp('[^(a-f)(A-F)(0-9)]', 'g'), '');
    if (_value) {
      if (newValue.length <= 6) {
        onChange({target: { value: '#'+newValue}});
      }
    } else {
      onChange({target: { value: ''}});
    }
  }

  return (
    <>
      <TextInput
        {...otherProps}
        value={value}
        onChange={handleChangeTextInput}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' classes={{root: classes.iconRoot}}>
              <IconButton onClick={handleOpen} disabled={disabled}>
                <Palette className={clsx(classes.paletteIcon, { [classes.paletteIconDisabled]: disabled })} />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Popover
        anchorEl={open}
        open={!!open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <ChromePicker
          color={value}
          onChange={handleOnChange}
          // onChangeComplete={handleOnChangeComplete}
          disableAlpha
        />
      </Popover>
    </>
  );
}

ColorPickerInput.defaultProps = {
  size: 'normal'
}

export default ColorPickerInput;
