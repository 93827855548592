const styles = () => ({
  pageSkeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    height: '100%',
    padding: 40,
    boxSizing: 'border-box'
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
    width: '60vw'
  },
  headerItem: {
    margin: 16,
    flex: 1
  },
  pageBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    marginTop: 64,
    width: '70vw'
  },
  bodyHeaderItem: {
    margin: '32px 0px',
    width: '16em',
    maxWidth: '100%'
  },
  bodyItem: {
    margin: '16px 0px'
  }
});

export default styles;
