import getDeepValue from '../../getDeepValue';
import { changeFieldData } from '../../../compartments/templates/templateReducer';

//Uncheck enum when all child values are unselected
function setEnumParent(state, parentTemplateData={}, { value }, key, id, ...fields) {
  let newState = { ...state };
  if (!value) {
    for (let i = 0; i < fields.length; i++) {
      const [groupName, fieldName] = fields[i].split('.');
      const metadata = getDeepValue(state, [groupName, 'fields', fieldName, 'metadata']);
      const { queries } = metadata;
      const oldValue = getDeepValue(state, [groupName, 'fields', fieldName, key]);
      if (oldValue) {
        let newValue;
        const group = getDeepValue(state, [groupName]);
        const field = getDeepValue(group, ['fields', fieldName]);
        if (oldValue === 'ALL' && queries && queries.sendAll) {
          const values = getDeepValue(field, ['metadata', 'values']);
          const valuesKeys = Object.keys(values);
          newValue = valuesKeys.filter((valKey) => valKey !== id).join(',');
        } else {
          const oldValueSplit = oldValue.split(',');
          const index = oldValueSplit.indexOf(id);
          const newValueSplit = oldValueSplit.slice(0, index).concat(oldValueSplit.slice(index + 1));
          newValue = newValueSplit.join(',');
        }
        newState = changeFieldData(newState,  { field, group, [key]: newValue }, true)
      }
    }
  }
  return newState;
}

export default setEnumParent;
