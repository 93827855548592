
import { baseURL, baseHeaders } from '../../constants/Endpoints';
import getSearchIndex from '../../Utils/smart-search/getSearchIndex';
import convertFilterToString from '../../Utils/search/convertFilterToQuery';
import getDeepValue from '../../Utils/getDeepValue';
import parseQuery from '../../Utils/search/parseQuery';
import getCompartmentTypes from "../../Utils/compartments/getCompartmentTypes";
import { low } from "../../constants/Constants";
import getCouponService from '../../services/admin/GetCouponService';
import { checkForUndefined } from '../../Utils/helpers';
import searchIndexMapper from './searchIndexDataMapper';


export default async function SearchService(searchServiceObj) {
  const {
    filter,
    sort,
    type,
    additionalFilter,
    searchOptions = {},
    aborter,
    clearWithoutFilter,
    user,
    queryFromService,
    searchParams = null,
    queryForFilter,
    showAllOffersSwitch
  } = searchServiceObj || {};

  if (clearWithoutFilter && !(filter && Object.keys(filter))) {
    return null;
  }
  const index = getSearchIndex(type);
  const body = {
    ...searchOptions,
    orderby: searchParams ? searchParams['orderBy'] : '',
    searchFields: searchParams ? searchParams['searchFields'] : '',
    queryType: 'full'
  }
  // build default search query for view merchant offers
  if (showAllOffersSwitch && showAllOffersSwitch() && filter) {
    body.search = convertFilterToString({
      filterParam: filter,
      useSearch: true,
      searchParams,
      showAllOffersSwitch,
      userId: user && user.id
    }).defaultSearchQuery
  }

  if (sort && sort.property) {
    body.orderby = `${sort.property.replace(/\./g, '/')} ${sort.orderBy}`
  }

  if (filter || additionalFilter) {
    const { filter: filterQuery, search: searchQuery } = convertFilterToString({
      filterParam: filter,
      useSearch: true,
      searchParams,
      showAllOffersSwitch,
      defaultSearchValue: body.search,
    })
    const { filter: additionalFilterQuery, search: additionalSearchQuery } = convertFilterToString({
      filterParam: additionalFilter,
      useSearch: true,
      searchParams,
      showAllOffersSwitch,
      defaultSearchValue: body.search,
    })

    /* Don't call search API when undefined is present in either of the below strings */
    if (checkForUndefined([filterQuery, additionalFilterQuery, searchQuery, additionalSearchQuery])) {
      return null
    }

    if (searchQuery || additionalSearchQuery) {
      if (searchQuery && additionalSearchQuery) {
        const combined = `${searchQuery} && ${additionalSearchQuery}`;
        body.search = body.search ? `${body.search} && ${combined}` : combined;
      } else if (searchQuery) {
        body.search = body.search ? `${body.search} && ${searchQuery}` : searchQuery;
      } else if (additionalSearchQuery) {
        body.search = body.search ? `${body.search} && ${additionalSearchQuery}` : additionalSearchQuery;
      }
    }
    if (filterQuery && additionalFilterQuery) {
      const decodedFilterQuery = decodeURI(filterQuery)
      const decodedAdditionalFilterQuery = decodeURI(additionalFilterQuery)
      body.filter = filterQuery !== additionalFilterQuery ? `(${decodedFilterQuery}) and (${decodedAdditionalFilterQuery})` : `(${decodedFilterQuery})`;
    } else if (filterQuery) {
      body.filter = filterQuery;
    } else if (additionalFilterQuery) {
      body.filter = additionalFilterQuery;
    }

    const defaultSearch = searchParams && searchParams['default'];
    body.filter = defaultSearch
      ? body.filter
        ? `(${body.filter}) and (${defaultSearch})`
        : defaultSearch
      : body.filter;

    if (user) {
      const roleQueries = searchParams && searchParams[user.role];
      const modifiedRoleQueries = roleQueries ? parseQuery(roleQueries, user) : '';
      body.filter = modifiedRoleQueries
        ? body.filter
          ? `(${body.filter}) and (${modifiedRoleQueries})`
          : modifiedRoleQueries
        : body.filter;
    }

    // get default query
    const defaultQuery = getDeepValue(queryFromService, ['default']);
    const defaultFilter = getDeepValue(queryFromService, ['defaultFilter']);
    const defaultSort = getDeepValue(queryFromService, ['defaultSort']);
    if (defaultQuery) {
      if (body.search) {
        body.search += ` && ${defaultQuery}`;
      } else {
        body.search = defaultQuery;
      }
    }
    if (defaultSort && !body.orderby) {
      body.orderby = defaultSort;
    }
    /*
     * default filter comes from queries
     */
    if (defaultFilter) {
      if (body.filter) {
        body.filter = `(${body.filter}) and (${defaultFilter})`;
      } else {
        body.filter = defaultFilter;
      }
    }
  }

  if (queryForFilter) {
    if (body.filter) {
      body.filter = `(${body.filter}) and (${queryForFilter})`;
    } else {
      body.filter = queryForFilter;
    }

  }

  try {
    const promotion = type === low(getCompartmentTypes.PROMOTION)
    const assignedCoupon = type === getCompartmentTypes.ASSIGNEDCOUPON
    if (assignedCoupon) {
      return await getCouponService(body);
    }
    const url = promotion ? baseURL + 'promotions/search' : `${baseURL}index/${index}`
    const data = await fetch(url, {
      method: 'POST',
      headers: baseHeaders(),
      body: JSON.stringify(body),
      signal: aborter ? aborter.signal : null
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });

    if (data.value && Array.isArray(data.value)) {
      data.value = data.value.map(searchIndexMapper(type, { user }));
    }

    return data;
  } catch (error) {
    if (error && error.name === 'AbortError') { //Request aborted, do nothing
      return null;
    }
    return { error: error };
  }
}
