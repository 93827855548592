import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Typography from '@material-ui/core/Typography';
import useStyles from './SearchTotal.styles';
import notNull from '../../Utils/notNull';

function SearchTotal({ leftValue, leftLabel, loading }) {
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.statsContainer}>
        <Skeleton height={20} width={100} />
      </div>
    );
  }

  if (notNull(leftValue) && notNull(leftLabel)) {
    return (
      <div className={classes.statsContainer} id='search-total-stats' testId='search-total-stats'>
        <Typography variant='h1' component='span' className={classes.statsValue}>{leftValue}</Typography>
        <Typography variant='h1' component='span' className={classes.statsLabel}>{leftLabel}</Typography>
      </div>
    )
  }

  return <div className={classes.statsContainer} />;
}

export default SearchTotal
