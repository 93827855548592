import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../theme/Colors';

const styles = {
  icon: {
    zIndex: 1,
    color: Colors.primaryBlue,
    marginRight: 8
  },
  adornment: {
  },
  iconSmall: {
    // padding: 0,
    // // left: -16,
    // zIndex: 2
    padding: 4,
    marginRight: 0
  },
  iconDisabled: {
    color: Colors.grey300
  },
  adornmentSmall: {
    marginLeft: 0,
    '& svg': {
      width: '0.8em',
      height: '0.8em'
    }
  },
  input: {
    paddingRight: 0
  },
  dateContainer: {
    display: 'flex'
  },
  timeContainer: {
    marginLeft: 8
  },
  timeDiv: {
    margin: 'auto 2px 6px 2px'
  },
  label: {
    // color: 'rgba(36, 37, 61, 0.5) !important',
    fontSize: 16,
    paddingBottom: 4
  },
  formHelperError: {
    color: '#f44336',
    margin: '-3px 22px'
  },
  staticDateWrapper: {
    borderColor: Colors.primaryBlue,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    overflow: 'hidden'
  },
  staticDateContainer: {
    width: '100%'
  }
};

export default makeStyles(styles);
