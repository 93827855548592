/*
  checks the audit trail if step is complete
*/

export const stateLogStatus = (auditTrail, stepName='', sequence = '') => {
  const status = {complete: false, started: false};
  if (auditTrail) {
    let logsArr = null;
    if(stepName) {
      logsArr = auditTrail.filter(audit => audit.event === stepName);
    } else {
      logsArr = auditTrail.filter(audit => audit.sequence === sequence)
    }

    if(logsArr && logsArr.length > 0){
      status.complete = logsArr[(logsArr.length - 1)].complete;
      status.started = true;
    }
  }
  return status;
}
