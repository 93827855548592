import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./Template.styles";
import ViewOnly from '../../viewOnly/ViewOnly';

const TemplateLabel = props => {
  const { field } = props;
  const {
    longDesc,
    shortDesc,
    value,
    required
  } = field;

  return (
    <ViewOnly label={shortDesc} value={value || longDesc} required={required} />
    // <div className={classes.templateLabel}>
    //   <Typography variant='body1'>{shortDesc} {field.required ? ' *' : null} : {value || longDesc}</Typography>
    // </div>
  );
}

export default withStyles(styles)(TemplateLabel);
