import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Circle({color, ...props}) {
  return (
    <SvgIcon viewBox='0 0 100 100' {...props}>
      <circle cx="50" cy="50" r="25" fill={color} />
    </SvgIcon>
  );
}
