import moment from 'moment';
import getDeepValue from './getDeepValue';
import {
  workflowSequence,
  ModalOverlayMessage,
  MessageConditions,
  OfferTypes,
  ChannelTypes,
  clubChoices,
  ScheduleOptions,
  DateFormat,
  PlusMemberShipItems,
  CampaignTypes,
  MembershipTypes,
  SubmitOffersetOptions,
  SearchTypes,
  low,
  TemplateIds,
  RootTypes,
  DialogSuccessLabel,
  DialogCancelLabel,
  AwardTypes,
  AwardDataKeys,
  SamsCashAwardKeys,
  SamsCashFundingTypes,
  SamsCashUnits, OrderDiscountAwardKeys, OrderDiscountUnits, CouponChoices,
  TemplateGroupType,
  Roles
} from "../constants/Constants";
import { checkNonZeroArray } from "./helpers";
import notNull from './notNull';
import SearchService from '../setup/search/service.all';
import getAllBiglistValuesService from '../services/biglist/GetAllBiglistValuesService';


export const getBulkWorkflowRejectionCommentsProps = ({ row, value, item, handleChangeRejectionInput, setBulkWorkflowDialog, rejectionCommentsOnDone, stateKey }) => {
  return {
    title: `Are you sure you want to reject '${row && row.values && row.values.promotion_name}' promotion ?`,
    textArea: {
      placeholder: 'Enter Rejection Comments',
      value: value,
      onChange: handleChangeRejectionInput,
      row: row,
      item: item,
      stateKey: stateKey
    },
    open: true,
    actions: [
      {
        onClick: () => setBulkWorkflowDialog({ open: false, cancelExecute: true }),
        label: 'Cancel',
        color: 'secondary',
        variant: 'outlined'
      },
      {
        onClick: () => rejectionCommentsOnDone(row, value, item, stateKey),
        label: 'Confirm Rejection',
        color: 'secondary',
        variant: 'contained'
      }
    ]
  }
}

export const reject = (props) => {
  const dialogProps = getBulkWorkflowRejectionCommentsProps(props)

  props.setBulkWorkflowDialog(dialogProps);
}

const getDeletionDialogProps = ({ row, item, setBulkWorkflowDialog, deleteOnDone, rootType }) => {
  const itemName = rootType + '_name'
  const rowData = row && row.values ? row.values : row
  const name = getDeepValue(rowData, [itemName]) || 'Not Set';
  return {
    title: `Delete`,
    text: `Are you sure you want to delete '${name}' ?`,
    open: true,
    actions: [
      {
        onClick: () => setBulkWorkflowDialog({ open: false }),
        label: 'Cancel',
        color: 'secondary',
        variant: 'outlined'
      },
      {
        onClick: () => deleteOnDone(row, item),
        label: 'Confirm',
        color: 'secondary',
        variant: 'contained'
      }
    ]
  }
}

export const showDeleteDialog = (props) => {
  const dialogProps = getDeletionDialogProps(props)

  props.setBulkWorkflowDialog(dialogProps);
}

export const handleOnErrors = (data, setBulkWorkflowDialog) => {
  let errorDesc = 'Something went wrong'
  if (data && data.networkError && data.networkError.result && data.networkError.result.errors && data.networkError.result.errors[0]) {
    errorDesc = data.networkError.result.errors[0].description
  }
  const dialogProps = {
    title: 'Error',
    text: errorDesc,
    open: true,
    actions: [
      {
        onClick: () => setBulkWorkflowDialog({ open: false, cancelExecute: true }),
        label: 'ok',
        color: 'secondary',
        variant: 'contained'
      }
    ]
  }

  setBulkWorkflowDialog(dialogProps);
}

const handleOnDeleteSuccess = ({ row, rootType, onDone, setBulkWorkflowDialog }) => {
  const name = row && row.values && row.values[rootType + '_name'] || 'Not Set'
  setBulkWorkflowDialog({
    title: 'Success',
    text: `${rootType} '${name}' deleted successfully`,
    open: true,
    actions: [
      {
        onClick: () => onDone(),
        label: 'ok',
        color: 'secondary',
        variant: 'contained'
      }
    ]
  });
}

export const deleteItemFn = ({ row, id, rootType, onDone, setBulkWorkflowDialog, deleteItem }) => {
  setBulkWorkflowDialog({ open: false });
  deleteItem({
    variables: {
      type: rootType === 'promotion' ? 'promotions' : rootType,
      id
    }
  }).then((res) => {
    if (res && res.data && res.data.workflowItem) {
      handleOnDeleteSuccess({ row, rootType, onDone, setBulkWorkflowDialog })
    }
  });
}

/* This function checks whether the promotion of $Basket Off or %Off Basket having any limit be live after publishing*/
const checkIfNoLimit = (values) => {
  const { customer_redemption_limit, membership_redemption_limit, primary_household_redemption_limit, template_type } = values;
  const condition = (OfferTypes.BASKET_OFFER_LIST.includes(template_type)) && (!customer_redemption_limit || customer_redemption_limit === null) && (!membership_redemption_limit || membership_redemption_limit === null) && (!primary_household_redemption_limit || primary_household_redemption_limit === null);
  return condition ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_IF_NO_LIMIT_FOR_BASKET_OFF) : null;
};

/* This function checks whether the promotion of $Basket Off or %Off Basket having coupon or member target list or barcode*/
const checkIfBarcodePresentForBroadReachAndBasketOffer = (values, condition_data) => {
  const isBasketOffer = OfferTypes.BASKET_OFFER_LIST.includes(values.template_type)
  const isBroadReachCampaign = values.assignment_type === 'BROADREACH'
  let isBarcodePresent = false
  let isCouponPresent = false

  if (condition_data !== undefined && condition_data.scan_conditions !== undefined) {
    if (Array.isArray(condition_data.scan_conditions)) {
      if (condition_data.scan_conditions.length >= 1) {
        isBarcodePresent = true;
      }
    }
  }
  if (Object.keys(values).includes("coupon_choice") && values.coupon_choice !== CouponChoices.NO_COUPON) {
    isCouponPresent = true;
  }

  if (isBroadReachCampaign && isBasketOffer && !isBarcodePresent && !isCouponPresent) {
    return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_IF_COUPON_OR_MTL_OR_BARCODE)
  }
  return null;
};


/* This function checks if there is a limit set on the Alcohol $off template and displays a warning */
export const checkIfLimitAlcohol = (values, templateId) => {
  const { customer_redemption_limit, membership_redemption_limit, order_redemption_limit, primary_household_redemption_limit } = values;
  const condition = ([TemplateIds.DOLLAR_OFF_ALCOHOL_ITEMS].includes(templateId)) && (customer_redemption_limit || membership_redemption_limit || order_redemption_limit || primary_household_redemption_limit);
  return condition ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_IF_LIMIT_FOR_ALCOHOL_DOLLAR_OFF) : null;
};

/* This function checks whether the join membership template have membership type of Saving Plus / Business Plus */
const checkAwardValueForJoinMemberShip = (membershipData, values) => {
  const { membership_items } = membershipData;
  const validPlusMembershipItems = [
    PlusMemberShipItems.BUSINESS_PLUS,
    PlusMemberShipItems.SAVING_PLUS
  ];
  const membershipItemsExist = membership_items && membership_items[0].membership_items;
  const hasValidPlusMembership = membershipItemsExist && membershipItemsExist.split(",").some((value) => validPlusMembershipItems.includes(value));
  const hasNonStandardMembershipAwardOptions = values.awards && values.awards.split(",").every(value => value === "MEMBERSHIP_LENGTH");
  return hasValidPlusMembership && !hasNonStandardMembershipAwardOptions ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_IF_AWARD_VALUE_FOR_JOIN_MEMBERSHIP) : null;
};

/* Submit offersets logic */
const getSubmitOffersetsDialogProps = ({ row, item, setBulkWorkflowDialog, submitOffersetsOnDone }) => {
  const rowData = row && row.values ? row.values : row
  const name = getDeepValue(rowData, ['campaign_number']) || 'Not Set';
  const params = item;
  const isCancel = params?.method === SubmitOffersetOptions.CANCEL;
  return {
    title: isCancel ? `Cancel Offersets` : `Submit Offersets`,
    text: isCancel ? `Are you sure you want to cancel all offersets for campaign ${name}?` : `Are you sure you want to submit all offersets for campaign ${name}?`,
    open: true,
    actions: [
      {
        onClick: () => setBulkWorkflowDialog({ open: false }),
        label: 'Cancel',
        color: 'secondary',
        variant: 'outlined'
      },
      {
        onClick: () => submitOffersetsOnDone(row, params),
        label: 'Confirm',
        color: 'secondary',
        variant: 'contained'
      }
    ]
  }
}

export const showSubmitOffersetsDialog = (props) => {
  const dialogProps = getSubmitOffersetsDialogProps(props)

  props.setBulkWorkflowDialog(dialogProps);
}

const handleOnSubmitOffersetsSuccess = ({ row, params, onDone, setBulkWorkflowDialog }) => {
  const name = row && row.values && row.values.campaign_number || 'Not Set'
  const isCancel = params?.method === SubmitOffersetOptions.CANCEL;
  setBulkWorkflowDialog({
    title: 'Success',
    text: isCancel ? `Offersets canceled successfully for campaign ${name}` : `Offersets submitted successfully for campaign ${name}`,
    open: true,
    actions: [
      {
        onClick: () => onDone(),
        label: 'ok',
        color: 'secondary',
        variant: 'contained'
      }
    ]
  });
}

export const submitOffersetsFn = ({ row, params, onDone, setBulkWorkflowDialog, submitOffersets }) => {
  setBulkWorkflowDialog({ open: false });
  submitOffersets(row, params?.method).then((response) => {
    if (response.error) {
      return;
    }
    handleOnSubmitOffersetsSuccess({ row, params, onDone, setBulkWorkflowDialog })
  });
}
/* End Submit offersets logic */

/* This function checks whether the discount is higher than the bucket discount*/
const checkOffsetDiscountBucket = (values) => {
  const { total_budget, value_off } = values;
  const arrValueOff = value_off.split("|");
  const condition = arrValueOff.filter(value => value > total_budget);
  return condition.length > 0 ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_OFFERSET_DISCOUNT_BUCKET) : null
}

/* This function checks whether the discount bucket values are 0 or empty*/
const checkOffersetDiscountBucketWithZeroDiscount = (values) => {
  const { value_off } = values;
  const arrValueOff = value_off.split("|");
  const condition = arrValueOff.filter(value => +value === 0 || !notNull(value));
  return condition.length > 0 ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_OFFERSET_DISCOUNT_BUCKET_WITH_NO_DISCOUNT) : null
}

/* This function checks whether the discount bucket has the same discount values*/
const checkOffersetDiscountBucketHasSameDiscount = (values) => {
  const { value_off } = values;
  let arrValueOff = value_off.split("|");
  arrValueOff = arrValueOff.map((discountVal) => parseFloat(discountVal));
  return new Set(arrValueOff).size !== arrValueOff.length ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_OFFERSET_DISCOUNT_BUCKET_HAS_SAME_DISCOUNT_VALUES) : null
}

/* This function checks the dates to create Offerset for campaign, whose start date is less than 14 days or more than 90 days from today.*/
const checkOffersetDateRange = (values) => {
  const { start_date, start_time } = values;
  const campaignStartDate = new Date(start_date + " " + start_time).getTime();
  const currentDate = new Date();
  const minDate = currentDate.getTime() + 15 * 24 * 60 * 60 * 1000; // 15 days from now
  const maxDate = currentDate.getTime() + 90 * 24 * 60 * 60 * 1000; // 90 days from now
  const condition = (campaignStartDate <= minDate || campaignStartDate >= maxDate);
  return condition ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_OFFERSET_DATE_RANGE) : null
}

/* This function checks whether the promotion will be live after publishing*/
const checkIfLiveOffer = (values) => {
  const { start_date, end_date, start_time, end_time, club_channel, is_promotion_number } = values;
  const offerStatTime = new Date(start_date + " " + start_time).getTime();
  const offerEndTime = new Date(end_date + " " + end_time).getTime();
  const currentDate = Date.now();
  const condition = (currentDate > offerStatTime && currentDate < offerEndTime) && !!club_channel && !is_promotion_number;
  return condition ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_IF_LIVE_OFFER) : null
}

const ifPRclubExists = (values, assignmentData) => {
  if (!assignmentData || !assignmentData?.club_assignment) {
    return;
  }
  const { club_assignment } = assignmentData;
  const { vendor_funding_percent } = values;
  const clubChoice = club_assignment && checkNonZeroArray(club_assignment) && club_assignment[0].club_choice;
  const condition = vendor_funding_percent === "100.00" && (clubChoice === clubChoices.ALL || clubChoice === clubChoices.PR_CLUBS_ONLY);
  return condition ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_CLUBS_FOR_VENDOR_FUNDED) : null

}

/* This function checks whether the basket offer has channels other than club and sng*/
const checkRequiredChannel = (values, offerType) => {
  const isBasketOffer = OfferTypes.BASKET_OFFER_LIST.filter(offerType => offerType !== OfferTypes.GENERIC_BASKET_OFFER).includes(offerType);
  const isGBOAndOrderDiscount = offerType === OfferTypes.GENERIC_BASKET_OFFER && (values.awards && values.awards.split(",").includes(AwardTypes.ORDER_DISCOUNT));
  const channelCondition = ifStringHas(values.channel, ChannelTypes.CPU) || ifStringHas(values.channel, ChannelTypes.D2H) || ifStringHas(values.channel, ChannelTypes.DFC) || ifStringHas(values.channel, ChannelTypes.ALL)
  const isChannelAll = checkChannels(values, "cpu_channel") || checkChannels(values, "d2h_channel") || checkChannels(values, "dfc_channel")
  const condition = (isBasketOffer && (channelCondition || isChannelAll)) || (isGBOAndOrderDiscount && (channelCondition || isChannelAll));
  return condition ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_REQUIRED_CHANNEL) : null

}

/* This function checks whether the trigger offer has faulty channels like CPU,D2H & SNG */
const checkChannelsForTriggerType = (values, offerType) => {

  const isTriggerOffer = offerType === OfferTypes.TRIGGER_OFFER;
  const allowedString = ifStringHas(values.channel, ChannelTypes.ALL) || ifStringHas(values.channel, ChannelTypes.CHANNEL_LIST)
  const wrongChannelString = (ifStringHas(values.channel, ChannelTypes.CLUB) || (ifStringHas(values.channel, ChannelTypes.CPU) || ifStringHas(values.channel, ChannelTypes.D2H) || ifStringHas(values.channel, ChannelTypes.SNG)) ||
    ifStringHas(values.channel, ChannelTypes.DFC)) && (!allowedString);
  const allChannelValuesCondition = checkChannels(values, "cpu_channel") && checkChannels(values, "d2h_channel") && checkChannels(values, "sng_channel") && checkChannels(values, "club_channel") && checkChannels(values, "dfc_channel")
  const wrongChannelValues = (checkChannels(values, "cpu_channel") || checkChannels(values, "d2h_channel") || checkChannels(values, "club_channel") || checkChannels(values, "sng_channel") || checkChannels(values, "dfc_channel"))
    && (!allChannelValuesCondition);
  const condition = isTriggerOffer && (wrongChannelString || wrongChannelValues);
  return condition ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_REQUIRED_CHANNEL_FOR_TRIGGER_TYPE) : null

}

/* This function checks  whether a specific channel is present or not*/
const checkChannels = (parent, value) => {
  const channel = parent[value];
  return !!(channel && channel === "ALL");

}

/* This function checks  whether the parent string  contains a substring */
const ifStringHas = (parent, value) => {
  if (Array.isArray(value)) {
    return value.every(i => parent.indexOf(i) !== -1)
  }
  return parent.indexOf(value) !== -1
}

/* This function checks whether the promotion of $Basket Off having Min Dollar off should always to be higher than $off Basket value*/
const checkMinDollarOff = (values, awardData) => { //check for GBO
  const { template_type, value_off, minimum_subtotal } = values;
  const condition = (([OfferTypes.DOLLAR_OFF_BASKET_OFFER, OfferTypes.MEMBERSHIP_OFF_BASKET].includes(template_type)
    && (notNull(value_off) && notNull(minimum_subtotal) && minimum_subtotal < value_off))
    || (template_type === OfferTypes.GENERIC_BASKET_OFFER && Object.keys(awardData).includes(AwardDataKeys.ORDER_DISCOUNT_AWARD)
      && awardData[AwardDataKeys.ORDER_DISCOUNT_AWARD][0][OrderDiscountAwardKeys.ORDER_DISCOUNT_UNIT] === OrderDiscountUnits.DOLLAR_OFF
      && notNull(minimum_subtotal)
      && minimum_subtotal < awardData[AwardDataKeys.ORDER_DISCOUNT_AWARD][0][OrderDiscountAwardKeys.ORDER_DISCOUNT_AMOUNT]));
  return condition ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_MIN_DOLLAR_OFF_FOR_BASKET_OFFER) : null;
};

/* This function checks whether the campaign has specific membership types*/
const checkCampaignMembershipTypes = (values, membershipType) => {
  const { member_type } = values;
  if (membershipType) {
    switch (membershipType) {
      case ("S_NOT_SP"):
        if (member_type.split(",").includes(MembershipTypes.SAVINGS) && !(member_type.split(",").includes(MembershipTypes.SAVINGSPLUS))) {
          return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_IF_SAVING_CLUB_NOT_SAVING_PLUS);
        }
        break;
      case ("B_NOT_BP"):
        if (member_type.split(",").includes(MembershipTypes.BUSINESS) && !(member_type.split(",").includes(MembershipTypes.BUSINESSPLUS))) {
          return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_IF_BUSINESS_CLUB_NOT_BUSINESS_PLUS);
        }
        break;
      case ("S_NOT_B"):
        if (member_type.split(",").includes(MembershipTypes.SAVINGS) && !(member_type.split(",").includes(MembershipTypes.BUSINESS))) {
          return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_IF_SAVING_CLUB_NOT_BUSINESS_CLUB);
        }
        break;
      case ("SP_NOT_BP"):
        if (member_type.split(",").includes(MembershipTypes.SAVINGSPLUS) && !(member_type.split(",").includes(MembershipTypes.BUSINESSPLUS))) {
          return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_IF_SAVING_PLUS_NOT_BUSINESS_PLUS);
        }
        break;
      default:
        return null;
    }
    return null;
  }
  return null;
};

/* This function calls an API to get the biglist*/
const getAllPRClubs = async () => {
  const biglistData = await getAllBiglistValuesService(clubChoices.PR_CLUBS_ONLY, 1000);
  if (biglistData && biglistData?.total > 0) {
    return biglistData.items.map(x => x.id);
  }

  return null;
}

/* This function checks whether the campaign has pr clubs*/
const checkCampaignClubs = async (assignmentData, blobs) => {
  try {
    const clubAssignment = assignmentData.club_assignment[0];
    const exclusionList = clubAssignment.exclusion_list.toUpperCase();
    const clubAssigmentList = clubAssignment?.club_assignment_list;
    const prClubList = await getAllPRClubs();
    let blobData = "";
    const filteredBlob = clubAssigmentList && clubAssigmentList !== "" ? blobs.filter(x => x?.name?.includes(clubAssigmentList) || x?.file?.name?.includes(clubAssigmentList)) : blobs;
    if (filteredBlob.length > 0) {
      for (const blob of filteredBlob) {
        const getCSV = await parseCsvFile(blob.file);
        blobData += getCSV;
      }
    }
    let selectedClubs;
    if (blobData !== "") {
      selectedClubs = blobData.split(",").filter(x => x.trim() !== "");
    } else if (clubAssignment?.club_assignment_list !== "") {
      selectedClubs = await getCampaignClubList(clubAssignment);
    }
    if (isPrClubValid(clubAssignment, exclusionList, selectedClubs, prClubList) || isExcludeUSClubValid(clubAssignment, exclusionList, selectedClubs, prClubList)) {
      return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_CAMPAIGN_HAVING_PR_CLUBS);
    }
  } catch (error) {
    console.error(error);
  }
  return null;
};

/* This function checks whether the campaign has pr clubs*/
const isPrClubValid = (clubAssignment, exclusionList, selectedClubs, prClubList) => {
  return exclusionList === "FALSE" && ([clubChoices.ALL, clubChoices.PR_CLUBS_ONLY].includes(clubAssignment.club_choice) ||
    (notNull(selectedClubs) && prClubList.filter(x => selectedClubs?.includes(x)).length > 0)
  );
}

/* This function checks whether the campaign has pr clubs*/
const isExcludeUSClubValid = (clubAssignment, exclusionList, selectedClubs, prClubList) => {
  return exclusionList === "TRUE" && ([clubChoices.US_CLUBS_ONLY].includes(clubAssignment.club_choice) ||
    (notNull(selectedClubs) && !(prClubList.every(x => selectedClubs?.includes(x))))
  );
}

/* This function calls an API to get the biglist*/
const getCampaignClubList = async (clubAssignmentList) => {
  const biglistData = await getAllBiglistValuesService(clubAssignmentList?.club_assignment_list, 1000);
  if (biglistData && biglistData?.total > 0) {
    return biglistData.items.map(x => x.id);
  }
  return null;
}

/* This function checks whether the campaign has empty clubs*/
const checkCampaignHavingEmptyClubs = (assignmentData) => {
  try {
    const clubAssignment = assignmentData.club_assignment[0];
    const emptyClubCondition = [clubChoices.SPECIFIC_CLUBS_ONLY].includes(clubAssignment.club_choice);
    return (emptyClubCondition && !notNull(clubAssignment?.club_assignment_list)) ? ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_CAMPAIGN_HAVING_EMPTY_CLUB) : null
  } catch (error) {
    console.error(error);
  }
  return null;
};

/* This function checks whether the Campaign/Promotion has Labels, Also checks if it has exactly one label with prefix "purpose" while creating Promotion */
const checkIfNoLabels = (values, name) => {
  if (values.labels === null || values.labels === 'undefined' || values.labels === '' && name === 'Campaign') {
    const warnMsgNoLabel = ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_IF_NO_LABELS);
    warnMsgNoLabel.warnMsg = warnMsgNoLabel.warnMsg.replace('{name}', name)
    return warnMsgNoLabel;
  } else if (!values.labels == '' && name === 'Campaign') {
    return null;
  } else if (!values.labels || values.labels === '' || !values.labels == '' || values.labels !== undefined || values.labels !== 'undefined' && name === 'Offer') {
    const prefixArray = values.labels.match(/purpose/gi);
    let prefixArrayLength = 0;
    prefixArrayLength = prefixArray === null ? prefixArrayLength : prefixArray.length;
    if (prefixArrayLength === 0 || prefixArrayLength > 1) {
      const errMsgForExactLabel = ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_IF_EXACT_ONE_PURPOSE_LABEL);
      errMsgForExactLabel.warnMsg = errMsgForExactLabel.warnMsg.replace("{name}", name);
      return errMsgForExactLabel;
    }
  }
  return null;
}

/* This function checks whether a discount bucket amount is lower than the minimum list price of the items selected*/
const checkDiscountBucketwithListPrice = async (values, itemDataManager) => {
  const itemListPrice = await getItemPrices(itemDataManager);
  if (!itemListPrice) {
    return;
  }
  const { value_off } = values;
  const arrValueOff = value_off.split("|").map((discountVal) => +(discountVal));
  const getMaxfromarrValueOff = Math.max(...arrValueOff);
  const getMinfromlistPrice = Math.min(...itemListPrice || 0);
  const condition = getMinfromlistPrice > 0 && getMaxfromarrValueOff > getMinfromlistPrice;
  const errMsgforListPrice = ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_IF_DISCOUNT_BUCKET_LOWER_THAN_LIST_PRICE);
  errMsgforListPrice.errMsg = errMsgforListPrice.errMsg.replace('$x', '$' + getMinfromlistPrice);
  return condition ? errMsgforListPrice : null
}

/* This function checks , whether the SUC/MUC end dates are non-infinite */

const checkCouponEndDate = (values, type, subtype) => {
  if (values && type === RootTypes.COUPON) {
    const { endDate } = values;
    if (type === RootTypes.COUPON && endDate) {
      const currentDate = new Date(endDate).toISOString()
      const consideredInfinity = new Date("2069-12-31").toISOString();
      if (new Date(currentDate).getTime() !== new Date(consideredInfinity).getTime() && new Date(currentDate).getTime() <= new Date(consideredInfinity).getTime()) {
        return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_COUPON_INFINITY_DATE, subtype)
      } else {
        return null
      }
    } else {
      return null
    }
  }

}

/* This function calls an API to get the Item Prices*/
const getItemPrices = async (itemDataManager, returnType = "priceOnly", groupName = null) => {
  await itemDataManager?.load();

  let itemData;
  if (groupName) {
    itemData = itemDataManager.getGroupItems(groupName);
  } else {
    itemData = itemDataManager.getGroupTypeItems('AWARD');
  }

  if (itemData.length > 0) {
    if (returnType === "fullList") {
      return itemData;
    } else {
      return itemData.map(x => +x.unit_retail_price);
    }
  } else {
    return null;
  }
}

export const parseCsvFile = async (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = e => {
      const result = e.target.result.replace(/[\r\n]/g, ',');
      resolve(result);
    }
    fileReader.onerror = e => {
      reject(e);
    }
    fileReader.readAsText(file);
  });
}

/* This function checks whether the percentage offer items having more than 50% off - crazy low price*/
const checkCrazyLowPrice = async (values, offerType, itemDataManager, awardData) => {
  const { value_off, new_value } = values;
  const isPercentageOffer = [OfferTypes.PERCENT_OFF_SELECTED_ITEMS].includes(offerType);
  const isDollarOffer = [OfferTypes.DOLLAR_OFF_SELECTED_ITEMS].includes(offerType);
  const isTPRTemplate = [OfferTypes.TPR_TEMPLATE].includes(offerType);
  const isGenericItemTemplate = [OfferTypes.GENERIC_ITEM_OFFER].includes(offerType);
  if (isPercentageOffer && value_off > 50) {
    return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_CRAZY_LOW_PRICE_PERCENTAGE_OFF_ITEMS);
  } else if (isDollarOffer || isTPRTemplate || isGenericItemTemplate) {
    const itemList = await getItemPrices(itemDataManager, 'fullList');
    if (!itemList) {
      return;
    }
    const itemListwithDiscPrice = itemList.map((item) => ({
      ...item,
      unit_retail_price_with_disc: (+item.unit_retail_price * 50) / 100,
    }));
    const warnMsgforCrazyLowPrice = ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_CRAZY_LOW_PRICE_DOLLAR_OFF_TPR_TEMPLATE_ITEMS);
    if (isGenericItemTemplate && Object.keys(awardData).includes(AwardDataKeys.ITEM_DISCOUNT_AWARD)) {
      const itemDiscountUnit = awardData.item_discount_award[0].item_discount_unit.toUpperCase();
      const itemDiscount = awardData.item_discount_award[0].item_discount;
      const genericdollarOfferCondition = itemListwithDiscPrice.filter(x => x.unit_retail_price_with_disc < itemDiscount);
      const genericNewPriceCondition = itemListwithDiscPrice.filter(x => x.unit_retail_price_with_disc > itemDiscount);
      const isNewPriceGeneric = ['NEW PRICE'].includes(itemDiscountUnit) ? true : false;
      if (itemDiscountUnit === "%OFF" && itemDiscount > 50) {
        return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_CRAZY_LOW_PRICE_PERCENTAGE_OFF_ITEMS);
      } else {
        if ((['$OFF', '$OFF PRORATED'].includes(itemDiscountUnit) && genericdollarOfferCondition.length > 0) || (['NEW PRICE'].includes(itemDiscountUnit) && genericNewPriceCondition.length > 0)) {
          warnMsgforCrazyLowPrice.warnMsg = warnMsgforCrazyLowPrice.warnMsg.replace('{discounted_amount}', '$' + itemDiscount);
          warnMsgforCrazyLowPrice.warnMsg = warnMsgforCrazyLowPrice.warnMsg.replace('steep discount', isNewPriceGeneric ? 'low price' : 'steep discount');
          warnMsgforCrazyLowPrice.warnMsg = warnMsgforCrazyLowPrice.warnMsg.replace('{item_numbers}', '#' + (isNewPriceGeneric ? genericNewPriceCondition.map(x => x.itemNumber).join(", #") : genericdollarOfferCondition.map(x => x.itemNumber).join(", #")));
          return warnMsgforCrazyLowPrice;
        }
      }
    } else {
      const dollarOfferCondition = itemListwithDiscPrice.filter(x => x.unit_retail_price_with_disc < value_off);
      const TPROfferCondition = itemListwithDiscPrice.filter(x => x.unit_retail_price_with_disc > new_value);
      if ((isDollarOffer && dollarOfferCondition.length > 0) || (isTPRTemplate && TPROfferCondition.length > 0)) {
        warnMsgforCrazyLowPrice.warnMsg = warnMsgforCrazyLowPrice.warnMsg.replace('{discounted_amount}', '$' + (isDollarOffer ? value_off : new_value));
        warnMsgforCrazyLowPrice.warnMsg = warnMsgforCrazyLowPrice.warnMsg.replace('steep discount', isDollarOffer ? 'steep discount' : 'low price');
        warnMsgforCrazyLowPrice.warnMsg = warnMsgforCrazyLowPrice.warnMsg.replace('{item_numbers}', '#' + (isDollarOffer ? dollarOfferCondition.map(x => x.itemNumber).join(", #") : TPROfferCondition.map(x => x.itemNumber).join(", #")));
        return warnMsgforCrazyLowPrice;
      }
    }
    return null;
  }
  return null;
}

/*
* This function checks whether nonplus members are included in a Sams Cash Plus rewards offer for GIO and GBO templates
* and warns if so.
*/
const checkSamsCashPlusRewardsForNonPlusMembers = (values, offerType, awardData) => {
  const { member_type } = values;

  if ((offerType === OfferTypes.GENERIC_ITEM_OFFER || offerType === OfferTypes.GENERIC_BASKET_OFFER) && awardData
    && Object.keys(awardData).includes(AwardDataKeys.SAMS_CASH_AWARD) &&
    awardData[AwardDataKeys.SAMS_CASH_AWARD][0][SamsCashAwardKeys.SAMS_CASH_FUNDING] === SamsCashFundingTypes.SAMS_CASH_FUNDING_PLUS_REWARDS
    && (member_type && (member_type.split(",").includes(MembershipTypes.SAVINGS)
      || member_type.split(",").includes(MembershipTypes.BUSINESS) || member_type === MembershipTypes.ALL))) {
    return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_SAMS_CASH_PLUS_REWARDS_FOR_NONPLUS_MEMBERS);
  }
  return null;
}

/* This function checks whether item discount award and sams cash award are both selected in GIO offers and shows an error message.*/
const checkItemDiscountAndSamsCashAward = (values, offerType) => {
  const { awards } = values;
  const awardsList = awards ? awards.split(",") : [];
  if (offerType === OfferTypes.GENERIC_ITEM_OFFER && awardsList.includes(AwardTypes.ITEM_DISCOUNT) && awardsList.includes(AwardTypes.SAMS_CASH)) {
    return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_ITEM_DISCOUNT_AND_SAMS_CASH_AWARDS);
  }
  return null
}

/* This function checks whether order discount award and sams cash award are both selected in GBO offers and shows an error message.*/
const checkOrderDiscountAndSamsCashAward = (values, offerType) => {
  const { awards } = values;
  const awardsList = awards ? awards.split(",") : [];
  if (offerType === OfferTypes.GENERIC_BASKET_OFFER && awardsList.includes(AwardTypes.ORDER_DISCOUNT) && awardsList.includes(AwardTypes.SAMS_CASH)) {
    return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_ORDER_DISCOUNT_AND_SAMS_CASH_AWARDS);
  }
  return null
}

/* This function derives warning or error messages to be shown before publishing a promotion during the review step */
/* This function checks whether the Sams Cash award amount is more than $35 (Sams Cash Unit - Fixed Amount) or 25 % net price (Sams Cash Unit - PERCENT) for Join, Renew, Upgrade, GIO and GBO templates and warns if so. */
const checkSamsCashAwardAmountMoreThan25_OR_35 = (offerType, awardData) => {
  if ((offerType === OfferTypes.JOIN_MEMBERSHIP || offerType === OfferTypes.RENEWAL_MEMBERSHIP
    || offerType === OfferTypes.UPGRADE_MEMBERSHIP || offerType === OfferTypes.GENERIC_ITEM_OFFER
    || offerType === OfferTypes.GENERIC_BASKET_OFFER) && awardData) {
    if (awardData && Object.keys(awardData).includes(AwardDataKeys.SAMS_CASH_AWARD)
      && awardData[AwardDataKeys.SAMS_CASH_AWARD][0][SamsCashAwardKeys.SAMS_CASH_UNIT] === SamsCashUnits.FIXED_AMOUNT
      && awardData[AwardDataKeys.SAMS_CASH_AWARD][0][SamsCashAwardKeys.SAMS_CASH_AMOUNT] > 35) {
      return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_SAMS_CASH_AWARD_AMOUNT_MORE_THAN_35);
    } else if (awardData && Object.keys(awardData).includes(AwardDataKeys.SAMS_CASH_AWARD) &&
      awardData[AwardDataKeys.SAMS_CASH_AWARD][0][SamsCashAwardKeys.SAMS_CASH_UNIT] === SamsCashUnits.PERCENT &&
      awardData[AwardDataKeys.SAMS_CASH_AWARD][0][SamsCashAwardKeys.SAMS_CASH_AMOUNT] > 25) {
      return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_SAMS_CASH_AWARD_AMOUNT_MORE_THAN_25);
    }
  }
  return null;
};

const checkSamsCashAwardAmountMoreThanCheapestItem = async (offerType, itemDataManager, awardData) => {
  if (offerType === OfferTypes.GENERIC_ITEM_OFFER && awardData && Object.keys(awardData).includes(AwardDataKeys.SAMS_CASH_AWARD)
    && awardData[AwardDataKeys.SAMS_CASH_AWARD][0][SamsCashAwardKeys.SAMS_CASH_UNIT] === SamsCashUnits.FIXED_AMOUNT) {
    const samsCashAwardAmount = awardData[AwardDataKeys.SAMS_CASH_AWARD][0][SamsCashAwardKeys.SAMS_CASH_AMOUNT];
    const itemList = await getItemPrices(itemDataManager, 'fullList', AwardDataKeys.SAMS_CASH_AWARD);
    if (!itemList) {
      return;
    }
    itemList.sort((a, b) => a.unit_retail_price - b.unit_retail_price);
    const itemsWithKnownPrices = itemList.filter(item => item.unit_retail_price && item.unit_retail_price > 0);
    const cheapestItem = itemsWithKnownPrices ? itemsWithKnownPrices[0] : null;
    const isAwardGreaterThanCheapestItem = cheapestItem && cheapestItem !== null ? cheapestItem.unit_retail_price < samsCashAwardAmount : false;
    if (isAwardGreaterThanCheapestItem) {
      return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_SAMS_CASH_AWARD_AMOUNT_MORE_THAN_CHEAPEST_ITEM);
    }
  }
  return null;
}

const checkIRISMessages = (values, templateGroupType) => {

  if (templateGroupType === low(TemplateGroupType.IRIS_SLOTS_DATA) && Object.keys(values).length > 0) {
    const { join_now_form_step_1_top_slot, join_now_landing_top_slot, join_now_thank_you_slot_1, join_now_thank_you_slot_2 } = values[templateGroupType];
    const noMessagePresent = !(checkNonZeroArray(join_now_form_step_1_top_slot) || checkNonZeroArray(join_now_landing_top_slot) || checkNonZeroArray(join_now_thank_you_slot_1) || checkNonZeroArray(join_now_thank_you_slot_2))

    if (noMessagePresent) {
      return ModalOverlayMessage.GUARDRAIL_MESSAGES(MessageConditions.CHECK_NONFILLED_IRIS_MESSAGE)
    }
  }

}

const checkSignId = async (itemDataManager) => {
  if (!itemDataManager || !(process.env.REACT_APP_ENABLE_SIGN_ID === 'true')) {
    return;
  }

  await itemDataManager.load();
  if (itemDataManager.getItems().length > 0) {
    await itemDataManager.loadSignIdItems();
    return itemDataManager.isMissingSignIdItems()
  }
}

/* This function derives warning or error messages to be shown before publishing a promotion during the review step */
export const showWarning = async (options) => {
  try {
    const { executeParams, step, setBulkWorkflowDialog, changeTrial } = options;
    const { input, blobs, biglistData, itemDataManager } = executeParams;

    const { values, offerType, assignment_data, currentSequence, isOfferset, condition_data, isCampaign, nonWOrkflowOption, type, subtype, templateId, award_data, templateGroupType } = input;

    let conditionObject = {};
    if (nonWOrkflowOption) {
      conditionObject = {
        checkCouponEndDate: checkCouponEndDate(values, type, subtype),
        checkIRISMessages: checkIRISMessages(input, templateGroupType)
      }
    } else if (notNull(isOfferset) && isOfferset === 1) {
      if (await checkSignId(itemDataManager)) {
        showSignIdMissingModal(setBulkWorkflowDialog, changeTrial, executeParams);
        return true;
      }

      conditionObject = {
        checkOffsetDiscountBucket: checkOffsetDiscountBucket(values),
        checkOffersetDiscountBucketWithZeroDiscount: checkOffersetDiscountBucketWithZeroDiscount(values),
        checkOffersetDiscountBucketHasSameDiscount: checkOffersetDiscountBucketHasSameDiscount(values),
        checkOffersetDateRange: checkOffersetDateRange(values),
        checkDiscountBucketwithListPrice: await checkDiscountBucketwithListPrice(values, itemDataManager),
      }
    } else if (notNull(isCampaign) && isCampaign === 1) {
      if (notNull(values?.assignment_type)) {
        if ([CampaignTypes.BROADREACH].includes(values.assignment_type) && notNull(values.member_type)) {
          if (values.member_type === MembershipTypes.ALL) {
            conditionObject = {
              checkSamsCashPlusRewardsForNonPlusMembers: checkSamsCashPlusRewardsForNonPlusMembers(values, offerType ? offerType : values.template_type, award_data),
            };
          } else {
            conditionObject = {
              checkCampaignMembershipTypes_S_NOT_SP: checkCampaignMembershipTypes(values, 'S_NOT_SP'),
              checkCampaignMembershipTypes_B_NOT_BP: checkCampaignMembershipTypes(values, 'B_NOT_BP'),
              checkCampaignMembershipTypes_S_NOT_B: checkCampaignMembershipTypes(values, 'S_NOT_B'),
              checkCampaignMembershipTypes_SP_NOT_BP: checkCampaignMembershipTypes(values, 'SP_NOT_BP'),
              checkSamsCashPlusRewardsForNonPlusMembers: checkSamsCashPlusRewardsForNonPlusMembers(values, offerType ? offerType : values.template_type, award_data),
            };
          }
        }
        if (notNull(assignment_data)) {
          conditionObject["checkCampaignClubs"] = await checkCampaignClubs(assignment_data, blobs);
          conditionObject["checkCampaignHavingEmptyClubs"] = checkCampaignHavingEmptyClubs(assignment_data);
          conditionObject["checkIfNoLabels"] = checkIfNoLabels(values, 'Campaign');
        }
      }
    } else {
      if (workflowSequence.FIRST_WORKFLOW_SEQ === +step && (currentSequence === undefined || (currentSequence && workflowSequence.BACKWARD_EDIT_WORKFLOW_SEQ === currentSequence))) {
        if (await checkSignId(itemDataManager)) {
          showSignIdMissingModal(setBulkWorkflowDialog, changeTrial, executeParams);
          return true;
        }

        if (condition_data && condition_data.membership_items) {
          conditionObject = {
            checkSamsCashAwardAmountMoreThan25_OR_35: checkSamsCashAwardAmountMoreThan25_OR_35(offerType ? offerType : values.template_type, award_data),
            checkAwardValueForJoinMemberShip: checkAwardValueForJoinMemberShip(condition_data, values)
          };
        } else {
          conditionObject = {
            checkSamsCashAwardAmountMoreThan25_OR_35: checkSamsCashAwardAmountMoreThan25_OR_35(offerType ? offerType : values.template_type, award_data),
            checkSamsCashAwardAmountMoreThanCheapestItem: await checkSamsCashAwardAmountMoreThanCheapestItem(offerType ? offerType : values.template_type, itemDataManager, award_data),
            checkIfNoLimit: checkIfNoLimit(values),
            checkItemDiscountAndSamsCashAward: checkItemDiscountAndSamsCashAward(values, offerType ? offerType : values.template_type),
            checkOrderDiscountAndSamsCashAward: checkOrderDiscountAndSamsCashAward(values, offerType ? offerType : values.template_type),
            checkIfLimitAlcohol: checkIfLimitAlcohol(values, templateId),
            checkMinDollarOff: checkMinDollarOff(values, award_data),
            checkCrazyLowPrice: await checkCrazyLowPrice(values, offerType, itemDataManager, award_data),
            checkIfBarcodePresentForBroadReachAndBasketOffer: checkIfBarcodePresentForBroadReachAndBasketOffer(values, condition_data)
          };
        }
      } else if (workflowSequence.LAST_WORKFLOW_SEQ === +step) {
        if (notNull(values.assignment_type)) {
          conditionObject["checkIfLiveOffer"] = checkIfLiveOffer(values);
          conditionObject["checkRequiredChannel"] = checkRequiredChannel(values, offerType);
          conditionObject["checkChannelsForTriggerType"] = checkChannelsForTriggerType(values, offerType);
          conditionObject["ifPRclubExists"] = ifPRclubExists(values, assignment_data);
          if ([CampaignTypes.BROADREACH].includes(values.assignment_type) && notNull(values.member_type)) {
            if (values.member_type === MembershipTypes.ALL) {
              conditionObject["checkSamsCashPlusRewardsForNonPlusMembers"] = checkSamsCashPlusRewardsForNonPlusMembers(values, offerType ? offerType : values.template_type, award_data);

            } else {
              conditionObject["checkCampaignMembershipTypes_S_NOT_SP"] = checkCampaignMembershipTypes(values, 'S_NOT_SP');
              conditionObject["checkCampaignMembershipTypes_B_NOT_BP"] = checkCampaignMembershipTypes(values, 'B_NOT_BP');
              conditionObject["checkCampaignMembershipTypes_S_NOT_B"] = checkCampaignMembershipTypes(values, 'S_NOT_B');
              conditionObject["checkCampaignMembershipTypes_SP_NOT_BP"] = checkCampaignMembershipTypes(values, 'SP_NOT_BP');
              conditionObject["checkSamsCashPlusRewardsForNonPlusMembers"] = checkSamsCashPlusRewardsForNonPlusMembers(values, offerType ? offerType : values.template_type, award_data);
            }
          }
          if (notNull(assignment_data)) {
            conditionObject["checkCampaignClubs"] = await checkCampaignClubs(assignment_data, blobs);
            conditionObject["checkCampaignHavingEmptyClubs"] = checkCampaignHavingEmptyClubs(assignment_data);
            conditionObject["checkIfNoLabels"] = checkIfNoLabels(values, 'Offer')
          }
        }
      }
    }
    if (conditionObject !== undefined && Object.keys(conditionObject).length !== 0) {
      const messages = Object.keys(conditionObject).reduce((i, j) => {
        const item = conditionObject[j];
        if (item) {
          i.warningMsg = item.warnMsg ? [...i.warningMsg, item.warnMsg] : i.warningMsg;
          i.errorMsg = item.errMsg ? [...i.errorMsg, item.errMsg] : i.errorMsg;
        }
        return i;
      }, { warningMsg: [], errorMsg: [] })
      const ifWarningsPresent = messages.warningMsg.length > 0;
      const ifErrorsPresent = messages.errorMsg.length > 0;
      if (ifErrorsPresent) {
        showErrors(messages.errorMsg, setBulkWorkflowDialog);
        return ifErrorsPresent;

      } else if (ifWarningsPresent) {
        showWarnings(messages.warningMsg, setBulkWorkflowDialog, changeTrial, executeParams, nonWOrkflowOption, type)
        return ifWarningsPresent;
      } else {
        return ifErrorsPresent || ifWarningsPresent
      }
    }
  } catch (error) {
    showErrors(['Unexpected error occured. Please try again or reach out to support.'], options?.setBulkWorkflowDialog);
    console.error(error)
    return true;
  }

  return false;
}

/* This function shows the warning messages to the user in review step */
const showWarnings = (messages, setBulkWorkflowDialog, changeTrial, executeParams, nonWOrkflowOption, type) => {
  const { input } = executeParams;
  const dialogProp = {
    title: "Warning",
    text: messages,
    open: true,
    actions: [
      {
        onClick: () => changeTrial(executeParams),
        label: input && DialogSuccessLabel(input.sequence, nonWOrkflowOption, type),
        color: 'secondary',
        variant: 'contained'
      },
      {
        onClick: () => setBulkWorkflowDialog({ open: false, cancelExecute: true }),
        label: input && DialogCancelLabel(input.sequence, nonWOrkflowOption, type),
        color: 'secondary',
        variant: 'outlined'
      },
    ]
  }
  setBulkWorkflowDialog(dialogProp);
}

/* This function shows the error messages to the user in review step */
const showErrors = (messages, setBulkWorkflowDialog) => {
  const dialogProp = {
    title: "Error",
    text: messages,
    open: true,
    actions: [
      {
        onClick: () => setBulkWorkflowDialog({ open: false, cancelExecute: true }),
        label: 'Close',
        color: 'secondary',
        variant: 'contained'
      },
    ]
  }
  setBulkWorkflowDialog(dialogProp);

}

/* This function shows the warning messages to the user in review step */
const showSignIdMissingModal = (setBulkWorkflowDialog, changeTrial, executeParams) => {
  const { itemDataManager, input, user } = executeParams;
  const isSupplierCreated = Array.isArray(input?.owners) && input?.owners?.length > 0;
  const isSupplierUser = user?.role?.name === Roles.SUPPLIER;
  console.log(user);
  const dialogProp = {
    title: 'Missing Item Numbers',
    text: isSupplierCreated && !isSupplierUser ? `The item list is missing some items grouped on a single club sign. This is no longer allowed. Contact the supplier to add the required items.` : `The item list is missing some items grouped on a single club sign. This is no longer allowed. Click 'Add Other Items' to have QuEST add all the items represented by those club signs.`,
    open: true,
    actions: [
      ...(isSupplierCreated && !isSupplierUser ? [] : [{
        onClick: async () => {
          await itemDataManager.mapSignIdItemsToPayload(); // Update payload directly with the itemDataManager
          changeTrial(executeParams, true)
        },
        label: 'Add Other Items',
        color: 'secondary',
        variant: 'contained'
      }]),
      {
        onClick: () => setBulkWorkflowDialog({ open: false, cancelExecute: true }),
        label: 'Cancel',
        color: 'secondary',
        variant: 'outlined'
      },
    ]
  }
  setBulkWorkflowDialog(dialogProp);
}

/* Function will handle date math for custom dates selected in custom ipm for presale messaging */
export const presaleMessaging = (value, { data, itemMessage, refData }) => {
  if (!data || !value || !itemMessage || !refData || !refData.values) {
    return;
  }
  switch (value) {
    case (ScheduleOptions.CUSTOM): // Custom, copy from the custom data
      data.start_time = itemMessage.start_time
      data.start_date = itemMessage.start_date
      data.end_time = itemMessage.end_time
      data.end_date = itemMessage.end_date
      break;
    case (ScheduleOptions.PRECEEDING): // subtract 7 days, up to start
      data.start_time = refData.values.start_time
      data.start_date = moment(refData.values.start_date).subtract(7, 'days').format(DateFormat.DATE)
      data.end_time = refData.values.start_time
      data.end_date = refData.values.start_date
      break;
    case (ScheduleOptions.DURING): // during sale, copy from the promotion
    default:
      data.start_time = refData.values.start_time
      data.start_date = refData.values.start_date
      data.end_time = refData.values.end_time
      data.end_date = refData.values.end_date
      break;
  }
}
