import React from "react";
import Actions from './search-actions';
import getCompartmentTypes from "../../Utils/compartments/getCompartmentTypes";
import ButtonList from '../../widgets/buttons/ButtonList/ButtonList';
import getImages from '../../Utils/workflow/get-images';
import Checkbox from '../../widgets/checkbox/Checkbox';
import Radio from '../../widgets/checkbox/Radio';

const getSearchActions = (props) => {
  const { isMetadata, rootType, funcRowId, funcSelRow, actionProps, multiSelect, singleSelect, workflowActions, workflowId, searchActions, bulkFunction, bulk = false, userAccess, userRole, parentCompt } = props;
  let values;
  let items = [];
  let workflowSteps = '';
  let searchSteps = '';

  // search actions from compartments
  if(searchActions){
    searchSteps = searchActions.filter(x=> x.type === "action" && !!x.bulk === bulk).map(x=>{
      return {
        onClick: x.bulk ? bulkFunction(x) : funcRowId,
        label: x.name,
        description: x.description,
        Icon: x.bulk ? getImages(x.image? x.image.toUpperCase() : "") : null,
        workflowId: x.workflowId,
        templateId: x.templateId,
        moreParams: x.params,
        order: 3,
        role: userRole
      }
    })
  }

  items = items.concat({"actions": searchSteps})

  // search actions from workflow
  if(workflowActions){
    workflowSteps = {
      onClick: funcRowId,
      workflow: true,
      workflowId: workflowId,
      access: userAccess,
      role: userRole,
      workflowDetails: workflowActions,
      rootType,
      parentCompt
    }

  }

  items = items.concat({"workflow":workflowSteps});

  // if (bulk) {
  const bulkStates = searchActions? searchActions.filter(x=> x.type === "action" && x.bulk ).map(x=>{
    return {
      onClick: bulkFunction(x),
      label: x.name,
      description: x.description,
      Icon: x.bulk ? getImages(x.image? x.image.toUpperCase() : "") : null,
      workflowId: x.workflowId,
      templateId: x.templateId,
      moreParams: x.params
    }
  }): [];
  items = items.concat({"bulk": bulkStates});


  if (multiSelect) {
    values = [
      Actions({ actionProps: { onChange: (event, val) => funcSelRow(event, val), ...actionProps }, button: <Checkbox /> })
    ]
  } else if (singleSelect) {
    values = [
      Actions({ actionProps: { onChange: funcSelRow, ...actionProps }, button: <Radio /> })
    ]
  } else {
    switch (rootType) {
      case getCompartmentTypes.CAMPAIGN:
        values = (isMetadata) ? [
          Actions({ actionText: "", actionIcon: "", func: funcSelRow, button: <Radio /> })
        ] : [
          {
            actionType: 'buttonList',
            props: {
              items: items
            },
            comp: <ButtonList />
          }
        ]
        break;
      case getCompartmentTypes.PROMOTION:
      case getCompartmentTypes.COUPON:
      case getCompartmentTypes.BRANDING:
      case getCompartmentTypes.ADMIN:
      case getCompartmentTypes.USERS:
      case getCompartmentTypes.ITEMS:
      case getCompartmentTypes.COUPONGROUPS:
      case getCompartmentTypes.ASSIGNEDCOUPON:
      case getCompartmentTypes.COUPONCODE:
      case getCompartmentTypes.OFFERSET:
      case getCompartmentTypes.TERMSANDCONDITIONS:
      case getCompartmentTypes.TARGETED_MESSAGING:
      case getCompartmentTypes.TARGETED_MESSAGING_BY_SLOT:
      case getCompartmentTypes.BIGLIST:
      case getCompartmentTypes.DOWNLOAD:
        if (items && items.length > 0) {
          values = [
            {
              actionType: 'buttonList',
              props: {
                items: items
              },
              comp: <ButtonList />
            }
          ]
        } else {
          values = []
        }
        break;
      case 'item':
      default:
        values = []
    }
  }
  return values;
}

export default getSearchActions;
