import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function updateUsersRoleService(users, roleId) {
  try {
    const requests = users.map((user) => {
      const userId = user.userId;
      return fetch(`${baseURL}users/${userId}/roles/${roleId}`, {
        method: 'PUT',
        headers: baseHeaders()
      }).then(function (res) {
        return res.json();
      }).then(function (data) {
        return data;
      });
    })
    const responses = await Promise.all(requests);
    return responses;
  } catch (error) {
    return error;
  }
}

export default updateUsersRoleService
