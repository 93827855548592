import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core';
import styles from './Template.styles';
import SuggestInput from '../../inputs/SuggestInput';
import { GET_ALL_ITEMS } from '../../../queries/template-querie';
import getCompartmentTypes from '../../../Utils/compartments/getCompartmentTypes';
import Brand from '../../brand/Brand';


function getItem(item, type) {
  if (item) {
    switch (type) {
      case (getCompartmentTypes.BRANDING):
        return {
          value: item.name,
          label: (
            <Brand
              badgeText={item.badgeText}
              backgroundColor={item.backgroundColor}
              foregroundColor={item.foregroundColor}
              outlineColor={item.outlineColor}
              cornerRadius={item.cornerRadius}
              align='left'
            />
          ),
          sort: item.badgeText
        }
      default:
        return {
          value: item.id,
          label: item.id,
          sort: item.id
        }
    }
  }

  return {
    value: '',
    label: '',
    sort: ''
  }
}

const TemplateData = props => {
  const { field, classes } = props;
  const {
    longDesc,
    name,
    mode,
    data,
    required,
    shortDesc,
    readOnly,
    searchType,
    value,
    error,
    onChange,
    initDisable
  } = field;

  const [options, setOptions] = useState();
  const [loading, setLoading] = useState(true);

  const [searchQuery, { error: errorItems }] = useMutation(GET_ALL_ITEMS, {
    onCompleted: handleOnComplete,
    variables: {
      input: {},
      searchType
    }
  });

  useEffect(() => {
    searchQuery();
  }, [])


  function handleOnComplete(res) {
    if (res && res.getAllItems && res.getAllItems.payload) {
      const payload = res.getAllItems.payload;
      const items = payload.map((item) => {
        return getItem(item, searchType);
      })
      // if (payload.offset) {
      //   setOptions((options = []) => options.concat(items));
      //   searchQuery({
      //     variables: {
      //       input: {
      //         offset: payload.offset
      //       }
      //     }
      //   })
      // } else {
      setOptions((options = []) => options.concat(items).sort((left, right) => {
        if (left.sort && right.sort) {
          return left.sort.localeCompare(right.sort);
        }

        return 0;
      }));
      setLoading(false);
      // }
    }
  }

  // const onChange = event => {
  //   if (event && event.target) {
  //     const value = event.target.value;
  //     const { field } = props;
  //     const { name, onChange, groupName } = field;
  //     onChange(groupName, name, value);
  //   }
  // };


  // const { value, error } = getFieldData(
  //   templateData,
  //   groupName,
  //   name,
  //   templateDataFromServer,
  //   field
  // );

  if (errorItems) {
    return <div>error</div>
  }

  // if (loading || !options) {
  //   return <div>loading</div>
  // }

  return (
    <SuggestInput
      id={name}
      className={classes.root}
      margin='normal'
      label={shortDesc}
      InputLabelProps={{ shrink: true }}
      placeholder={longDesc}
      value={value}
      options={options}
      onChange={onChange}
      condition={name}
      mode={mode}
      data={data}
      required={required}
      disabled={(loading || !options) || (readOnly ? readOnly : false) || initDisable}
      error={error}
    />
  );
};
export { TemplateData as TestTemplateData }
export default withStyles(styles)(TemplateData);
