import { makeStyles } from '@material-ui/core'
import Colors from '../../theme/Colors';

const styles = {
  root: {

  },
  marginNormal: {
    marginTop: 16,
    marginBottom: 8
  },
  iconRoot: {
    zIndex: 2
  },
  paletteIcon: {
    color: Colors.primaryBlue
  },
  paletteIconDisabled: {
    color: 'rgba(0, 0, 0, 0.26)'
  }
}

export default makeStyles(styles);
