import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import useStyles from './SearchModal.styles';
import CustomButton from '../../../widgets/buttons/CustomButton/CustomButton';
import { GET_COUPON_CODE } from '../../../queries/coupon-queries';
import SearchInput from '../../../widgets/search/search';
import notNull from '../../../Utils/notNull';
import TitleText from '../../../widgets/text/TitleText';
import TemplateCompartment from '../../templates/TemplateCompartment';
import objectToTemplate from '../../../Utils/template/objectToTemplate';

const defaultData = { found: null, item: null };

function SearchIn(props) {
  const { items, close, params } = props;
  const classes = useStyles();

  const item = items && items[0] ? items[0] : {};

  const [data, setData] = useState(defaultData);
  const [searchInput, setSearchInput] = useState(null);

  const [searchQuery, { loading }] = useMutation(GET_COUPON_CODE, {
    onCompleted: handleOnComplete,
    onError: handleOnError,
    variables: {
      input: {}
    }
  });

  function handleOnComplete(res) {
    if (res && res.coupon && res.coupon.payload && !res.coupon.errors) {
      const payload = res.coupon.payload;
      setData({
        found: true,
        item: { values: objectToTemplate(payload, true) }
      });

    } else {
      notFound();
    }
  }

  function handleOnError() {
    notFound();
  }

  function notFound() {
    setData({
      found: false,
      item: null
    })
  }

  function search(searchTerm) {
    searchQuery({
      variables: {
        input: {},
        couponId: searchTerm
      }
    })
  }

  function onChangeSearchInput(event) {
    const value = event.target.value;
    setSearchInput(value);
  }

  function onClickSearch() {
    setData(defaultData)
    search(searchInput);
  }

  function renderButtons() {
    return (
      <div className={classes.buttonContainer}>
        <div container className={classes.gridContainer}>
          <div className={classes.gridItem}>
            <CustomButton label='Close' onClick={close} color='secondary' variant='contained' fullWidth />
          </div>
        </div>
      </div>
    )
  }

  function renderSuccess(loadingData) {
    const itemData = data.item;
    return (
      <TemplateCompartment
        templateId={params.templateId}
        // parentTemplate={template}
        // ref={formRef}
        data={itemData}
        mode='viewOnly'
        loading={loadingData}
      />
    )
  }

  function renderNotFound() {
    return 'not found'
  }

  function renderLoading() {
    return renderSuccess(loading);
  }

  return (
    <div className={classes.root}>
      <div className={classes.filterContainer}>
        <TitleText className={classes.titleText}>
          {`Search for coupon code `}
        </TitleText>
        <SearchInput
          onChangeSearch={onChangeSearchInput}
          onClickSearch={onClickSearch}
          className={classes.searchInput}
          disableSearchBtn={!searchInput}
        />
      </div>
      <div className={classes.searchItemContainer}>
        {loading ? renderLoading() : (
          notNull(data.found) ? (
            data.found ? (
              <>
                {renderSuccess()}
              </>
            ) : (
              <>
                {renderNotFound()}
              </>
            )
          ) : null
        )}
      </div>
      {renderButtons()}
    </div>
  );
}

export default React.memo(SearchIn);
