import { AccessRanks } from '../../constants/access-levels';

const getAccessibleLevels = level => {
  const role = AccessRanks[level];
  const otherRoles = Object.keys(AccessRanks);
  return role > 0 && otherRoles && otherRoles.reduce((acc, val) => {
    if (role > AccessRanks[val] || level === val) {
      return acc + val;
    }
    return acc;
  }, '');
}

export const accessCheck = level => {
  const accessRoles = getAccessibleLevels(level);
  return accessRoles && accessRoles.includes(level);
}

export const checkStepAccess = (stepAccess, stepName) => {
  if (stepAccess && stepName) {
    const level = stepAccess[stepName.toLowerCase()];
    return level && accessCheck(level);
  }
  return false;
}

export const filterNoAccessWorkflow = (workflow, stepAccess) => {
  if (workflow && stepAccess) {
    const userSteps = Object.keys(stepAccess);
    return workflow.filter(flow => userSteps.findIndex(key => key === flow.name.toLowerCase()) > -1 && flow.uiReadable);
  } else if (workflow) {
    return workflow.filter(flow => flow.uiReadable);
  }
  return null;
}
