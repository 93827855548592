import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import Skeleton from 'react-loading-skeleton';
import clsx from 'clsx';
import useStyles from './BigListLinked.styles';
import CustomButton from '../../../widgets/buttons/CustomButton/CustomButton';
import { BlobActions, SearchTypes, low, LinkedBiglistFields } from '../../../constants/Constants';
import ErrorComponent from '../../../widgets/error/ErrorComponent';
import searchService from '../../../setup/search/service.all';
import Search from '../../../compartments/search/search';
import getSearchFieldMap from '../../../Utils/smart-search/getSearchFieldMap';


function BigListLinked(props) {
  const { nextStep, action, selectedFile, close, deleteBlob, loadingDeleteBigList } = props;
  const classes = useStyles();
  const [data, setData] = useState({ campaign: null, promotion: null });
  const [tabIndex, setTabIndex] = React.useState(0);

  const loading = !data.campaign || !data.promotion;

  useEffect(() => {
    init();
  }, [selectedFile])

  async function init() {
    const [promotionData, campaignData] = await Promise.all([search(low(SearchTypes.PROMOTION)), search(low(SearchTypes.CAMPAIGN))]);
    if (promotionData.value && campaignData.value) {
      const count = promotionData.value.length + campaignData.value.length;
      if (action !== BlobActions.DELETE_BIGLIST && count <= 1) {
        nextStep();
      }
    }
  }

  async function search(type) {
    const searchData = await searchService({
      sort: null,
      type,
      queryForFilter: `(current eq 'true' or current eq 'True')`,
      searchOptions: {
        // This makes azure search match anything with the PREFIX selectedFile
        // This is a bit of a lazy solution and can fail in some cases, but I do not have the time to do it properly
        // If we want a true EXACT match, we need to refactor this to be field specific filters
        search: (selectedFile+'*').replace(/-/g, ' '),
        searchFields: LinkedBiglistFields[low(type)],
        searchMode: 'all',
      }
    });

    setData((_data) => ({
      ..._data,
      [low(type)]: searchData.error ? searchData : searchData.value
    }))

    return searchData;
  }

  function refresh(type) {
    setData((_data) => ({
      ..._data,
      [low(type)]: null
    }));
    search(type);
  }

  const handleChangeTabs = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleChangeSwipe = index => {
    setTabIndex(index);
  };

  const error = (data.campaign && data.campaign.error) || (data.promotion && data.promotion.error);

  const disabledNext = loading || loadingDeleteBigList || error;

  async function nextAction() {
    if (action === BlobActions.DELETE_BIGLIST) {
      await deleteBlob({ name: selectedFile });
      close();
    } else {
      nextStep();
    }
  }

  function renderScreen(type) {
    if (!data[low(type)] || data[low(type)].error) {
      return <ErrorComponent message={`Error getting ${low(type)}s`} onClick={() => search(type)} />
    }

    return (
      <Search
        searchType={type}
        data={{
          fieldMap: getSearchFieldMap(null, null, {}, type),
          rowData: data[low(type)]
        }}
        dataLoading={loading}
        skipService
        isModal
        refresh={() => refresh(type)}
        total={data[low(type)].length}
      />
    )
  }

  if ((loading || !data.promotion || !data.campaign) && !error) {
    return (
      <div className={classes.skeletonContainer}>
        <Skeleton
          wrapper={({ children }) => (
            <div className={classes.skeletonWrapper}>{children}</div>
          )}
          height={48}
          count={3}
        />
      </div>
    )
  }


  const swipeableChildren = [
    renderScreen(SearchTypes.CAMPAIGN),
    renderScreen(SearchTypes.PROMOTION)
  ];

  return (
    <div className={classes.root}>
      <div className={clsx(classes.tabs)}>
        <Tabs
          classes={{
            root: clsx(classes.tabsRoot),
            indicator: classes.indicator
          }}
          value={tabIndex}
          onChange={handleChangeTabs}
          indicatorColor='secondary'
          textColor='secondary'
          variant='fullWidth'
          aria-label='big list tabs'
        >
          <Tab
            label='Campaigns'
            classes={{
              root: clsx(classes.tab),
              wrapper: classes.tabWrapper,
              selected: classes.tabSelected
            }}
          />
          <Tab
            label='Promotions'
            classes={{
              root: clsx(classes.tab),
              wrapper: classes.tabWrapper,
              selected: classes.tabSelected
            }}
          />
        </Tabs>
      </div>
      <SwipeableViews
        index={tabIndex}
        onChangeIndex={handleChangeSwipe}
        containerStyle={{ height: '100%', width: '100%' }}
        className={classes.swipeView}
        disabled
      >
        {swipeableChildren}
      </SwipeableViews>
      <div className={classes.buttonContainer}>
        <div container className={classes.gridContainer}>
          <CustomButton className={classes.gridItem} label='Cancel' onClick={close} color='secondary' size='large' variant='outlined' />
          <CustomButton
            className={classes.gridItem}
            label={action === BlobActions.DELETE_BIGLIST ? 'Delete' : 'Next'}
            onClick={nextAction}
            color='secondary'
            size='large'
            variant='contained'
            disabled={disabledNext && false}
          />
        </div>
      </div>
    </div>
  )
}

export default BigListLinked;
