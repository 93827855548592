import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Brand from './Brand';
import { useBrandingContext } from '../../containers/branding/BrandingProvider'


// Pulls branding data from the branding context

function BrandSmart({ name, ...tooltipProps }, ref) {
  const { brandingList } = useBrandingContext();

  const brand = useMemo(() => {
    if (brandingList) {
      for (const singleBrand of brandingList) {
        if (singleBrand && singleBrand.name === name) {
          return singleBrand;
        }
      }
    }
  }, [brandingList, name])

  if (brand) {
    return (
      <Brand
        ref={ref}
        {...tooltipProps}
        badgeText={brand.badgeText || brand.badge}
        backgroundColor={brand.backgroundColor}
        foregroundColor={brand.foregroundColor}
        outlineColor={brand.outlineColor}
        cornerRadius={brand.cornerRadius}
        align='left'
      />
    );
  }

  return null;
}
BrandSmart.propTypes = {
  name: PropTypes.string.isRequired
}
export default forwardRef(BrandSmart);
