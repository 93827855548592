import React, { useMemo } from "react";
import { withStyles } from "@material-ui/core";
import styles from "./Template.styles";
import SelectInput from '../../inputs/SelectInput';
import ViewOnly from '../../viewOnly/ViewOnly';
import parseOptions, { sortLabel, parseLabel } from '../../../Utils/input/parseOptions';

const TemplateMulti = props => {
  const { field, classes } = props;
  const {
    values,
    mode,
    value,
    onChange,
    queries
  } = field;

  const handleChange = event => {
    if (event && event.target) {
      const { value: targetValue, checked } = event.target;
      const valuesKeys = Object.keys(values);
      let newValue;
      if (targetValue === 'ALL') {
        newValue = checked
          ? valuesKeys.join(',')
          : '';
      } else {
        if (checked) {
          newValue = [...(value ? value.split(',') : []), targetValue].join(',')
        } else {
          if (value === 'ALL') {
            newValue = valuesKeys.filter(item => item !== targetValue).join(',');
          } else {
            newValue = value.split(',').filter(item => item !== targetValue).join(',');
          }
        }
      }
      if (queries && queries.sendAll &&  newValue && values) {
        const allValues = newValue.split(',');

        if (valuesKeys.filter((key) => allValues.includes(key)).length === valuesKeys.length) {
          newValue = 'ALL';
        }
      }
      onChange({
        target: {
          value: newValue
        }
      });
    }
  };

  const inputValue = useMemo(() => {
    if (value) {
      if (value === 'ALL' && values && queries && queries.sendAll) {
        return Object.keys(values)
      }
      return value.split(',');
    }
    return [];
  }, [value])


  const options = parseOptions(values)
    .sort(sortLabel).map(option => ({ // Sort types so we have control over the order
      ...option,
      label: parseLabel(option.label)
    }));

  if(queries && !queries.hideAllOption) {
    options.unshift({ value: 'ALL', label: 'All', selected: inputValue.length === Object.keys(values).length });
  }

  const isViewOnly = (mode === "viewOnly");
  const viewOnlyValue = values && inputValue.length > 0 ? inputValue.reduce((acc, key) => parseLabel(values[key]) ? [...acc, parseLabel(values[key])] : acc, []).join(', ') : value;


  const finalProps = {
    id: field.name,
    className: classes.root,
    margin: 'normal',
    label: field.shortDesc,
    placeholder: field.longDesc,
    value: inputValue,
    options: options,
    onChange: handleChange,
    condition: field.name,
    mode: mode,
    data: field.data,
    required: field.required,
    disabled: field.readOnly || isViewOnly ? (field.readOnly || isViewOnly) : false || field.initDisable,
    error: field.error,
    viewOnlyValue: viewOnlyValue,
    showNoWrapColumn: queries&&queries.showNoWrapColumn&&(queries.showNoWrapColumn===true||queries.showNoWrapColumn==="true")?true:false
  }

  if(isViewOnly){
    return(
      <ViewOnly
        {...finalProps}
        multiple

      />
    )

  } else{
    return (
      <SelectInput
        {...finalProps}
        multiple

      />
    );

  }


};
export { TemplateMulti as TestTemplateMulti}
export default withStyles(styles)(TemplateMulti);
