import React from "react";
import { Avatar } from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import { Regulatory } from '../../../constants/Constants';

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    paddingTop: 16,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom:12
  }
}));

export const getRegulatoryType = item => {
  if (item.regOther) {
    return Regulatory.OTHER;
  }
  if (item.regAlcohol) {
    return Regulatory.ALCOHOL;
  }
  if (item.regTobacco) {
    return Regulatory.TOBACCO;
  }
  return '';
}

const RegulatoryCell = props => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const zoomed =  Boolean(anchorEl);
  const zoomedImgSize = 65;
  const classes = useStyles();

  const getImage = (type, ownStyle) => {
    const common = {
      variant: 'square',
      classes: ownStyle ? null : props.classes
    };
    switch (type) {
      case Regulatory.ALCOHOL:
        return <Avatar {...common} style={ownStyle} src='/alcohol.png' />;
      case Regulatory.TOBACCO:
        return <Avatar {...common} style={ownStyle} src='/tobacco.png' />;
      case Regulatory.OTHER:
        return <Avatar {...common} style={ownStyle} src='/regulatory.png' />;
      default:
        return '';
    }
  }

  return (
    <div>
      <div
        aria-owns={zoomed ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        onMouseLeave={() => {
          setAnchorEl(null);
        }}
      >
        {getImage(getRegulatoryType(props.item))}
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={zoomed}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        disableRestoreFocus
      >
        {getImage(getRegulatoryType(props.item), {width: zoomedImgSize, height: zoomedImgSize})}
      </Popover>
    </div>
  )
};

export default RegulatoryCell;
