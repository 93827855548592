import { makeStyles } from '@material-ui/core'

const styles = {
  selectionList: {
    '@media (max-width: 768px) and (min-width: 426px)': {
      display: 'flex',
      flexWrap: 'wrap',
      '& > div': {
        width: 'auto'
      }
    }
  },
  selectionLabel: {
    textTransform: 'capitalize'
  },
  childSelectionContainer: {
    marginLeft: 16
  },
  innerList: {
    padding: 0
  },
  iconButton: {
    fontSize: 14,
    margin: 'auto 8px'
  },
  alignLoader: {
    display:'flex',
    flexDirection:'column',
    width:'140%',
    marginLeft: '10%'
  }
}

export default makeStyles(styles);
