import React, { Fragment } from "react";
import { withStyles, Typography, Divider, Paper } from "@material-ui/core";
import clsx from 'clsx';
import styles from "./TemplateGroup.style";
import TemplateList from "./TemplateList";
import { TemplateSpecialGroupType } from '../../constants/Constants';
import getDeepValue from '../../Utils/getDeepValue';

const TemplateGroup = props => {
  const {
    classes,
    groupData,
    mode,
    dispatch,
    showErrors,
    fullData,
    comments,
    device,
    history,
    setFormChanged,
    isContinue,
    dataState,
    setTemplateModalData,
    templateModal,
    workflowItem,
    modalVariant,
    sameScreenModal,
    parentTemplateData
  } = props;
  const groupMetadata = groupData.metadata;

  if (!groupMetadata || !groupData.fields) {
    return null;
  }
  const hide = groupMetadata.hide || groupData.initHideGroup;
  const viewMode = groupData.initReadableGroup && !mode ;

  const showTitleInTemplate = groupMetadata.groupConditions && groupMetadata.groupConditions.showInTemplate;

  const renderTemplateList = groupData => {
    const commonProps = {
      mode,
      viewMode,
      dispatch,
      showErrors,
      comments,
      device,
      fullData,
      history,
      setFormChanged,
      isContinue,
      dataState,
      setTemplateModalData,
      workflowItem,
      parentTemplateData
    };
    if (TemplateSpecialGroupType.includes(groupData.metadata.type)) {
      // Sometimes we only want to show a set number of these duplicated groups
      // this is used for multiple eligibile item groups when we only want to display one award
      const showLimit = parseInt(getDeepValue(groupData, ['metadata', 'groupConditions', 'showLimit']));
      return groupData.fields.map((data, i) => (
        <Fragment key={groupData.name + '' + i}>
          {i > 0 && (!showLimit || i < showLimit) ? <Divider variant='middle' className={classes.divider} /> : null}
          <TemplateList
            groupData={{ fields: data, metadata: { ...groupData.metadata, hide: (!showLimit || i < showLimit) ? groupData.metadata.hide : true }, groupIndex: i }}
            {...commonProps}
          />
        </Fragment>
      ));
    }
    return (
      <TemplateList
        key={groupData.name}
        groupData={groupData}
        {...commonProps}
      />
    )
  }
  const renderTemplateHeader = () => {
    if(!templateModal || showTitleInTemplate) {
      return (
        <div className={classes.header}>
          <div className={classes.groupDesc}>
            <Typography variant="h4" className={classes.groupDescLabel}>
              {groupMetadata.shortDesc}
            </Typography>
          </div>
        </div>
      )
    }
    return null;
  }

  if (history || modalVariant === 'popover' || sameScreenModal) {
    return renderTemplateList(groupData);
  } else {
    return (
      <div className={clsx(classes.formGroup, { [classes.hide]: hide})}>
        <Paper className={classes.paper}>
          <div className={classes.root}>
            {renderTemplateHeader()}
            <div className={classes.fields}>{renderTemplateList(groupData)}</div>
          </div>
        </Paper>
      </div>
    );
  }

};

export default withStyles(styles)(TemplateGroup);
