import React from 'react';
import Diff from '../Diff';
import RadioInput from '../../inputs/RadioInput';

export default function RadioInputDiff({ condition, data, mode, ...props }) {
  return (
    <Diff
      mode={mode}
      viewOnlyComponent={<RadioInput {...props} disabled />}
    >
      <RadioInput {...props} />
    </Diff>
  );
}
