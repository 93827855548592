const { Index } = require("../../constants/Endpoints");
const { default: fetchAll } = require("./fetchAll");

async function getItemNumbersWithSameSign(itemNumbers) {

  // get ItemNumber -> SignId mapping
  const batchSize = 10000;
  let lastOffset = 0;
  let batch;
  let itemData = []
  batch = itemNumbers.slice(lastOffset, lastOffset + batchSize);
  while (batch.length > 0) {
    const batchItemData = await fetchAll(Index.ITEMS, {
      select: 'itemNumber,itemSignId',
      filter: `search.in(itemNumber, '${batch.join(',')}')`
    })

    itemData = itemData.concat(batchItemData);

    lastOffset += batchSize;
    batch = itemNumbers.slice(lastOffset, lastOffset + batchSize);
  }

  const signIds = Array.from(new Set(itemData.map((item) => item.itemSignId))).filter(signId => !!signId);

  // get missing item numbers with the same sign id
  lastOffset = 0;
  let signItemData = []
  batch = signIds.slice(lastOffset, lastOffset + batchSize);
  while (batch.length > 0) {
    const batchItemData = await fetchAll(Index.ITEMS, {
      select: 'itemNumber,itemSignId',
      filter: `search.in(itemSignId, '${batch.join(',')}')`
    })

    signItemData = signItemData.concat(batchItemData);

    lastOffset += batchSize;
    batch = signIds.slice(lastOffset, lastOffset + batchSize);
  }

  return signItemData.filter((item) => !itemNumbers.includes(item.itemNumber)).map((item) => item.itemNumber);
}

export default getItemNumbersWithSameSign;
