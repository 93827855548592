import React from 'react';
import useStyles from './search-filter.styles';
import SearchBar from '../search/search';

function returnSearchValue(value, specificFieldSearch) {
  const searchField = value && value[specificFieldSearch];
  return searchField && searchField.search ? searchField.search : '';

}
function SearchFilter({ fieldMap = {}, value, onChange, style, onClickSearch, specificFieldSearch = 'searchAll', disabled, disableSearchBtn/*, ignoredFieldKeys = []*/ }) {
  const classes = useStyles();
  const notSearchAll = specificFieldSearch !== 'searchAll';
  const search = returnSearchValue(value, specificFieldSearch);
  const textForNotSearchAll = fieldMap[specificFieldSearch]
    ? fieldMap[specificFieldSearch].shortDesc
    : specificFieldSearch

  function handleOnChangeSearch(event) {
    if (event && event.target) {
      const search = event.target.value;
      const newValue = { ...value }
      const fieldMapData = {
        siblings: notSearchAll ? null : Object.keys(fieldMap),
        type: 'STRING'
      };
      const additionalField = notSearchAll ? { option: 'exact' } : {};
      newValue[specificFieldSearch] = { ...newValue[specificFieldSearch], search: search, data: { ...fieldMapData }, ...additionalField };
      onChange({ target: { value: newValue } });
    }
  }

  const placeholderText = 'Please enter ' + (
    notSearchAll
      ? textForNotSearchAll
      : (Object.keys(fieldMap).reduce((acc, key) => {
        const field = fieldMap[key];
        return field.shownField ? acc : [...acc, field.shortDesc];
      }, []).slice(0, 3).join(', ') + '...')
  );

  return (
    <SearchBar
      searchState={search}
      style={style}
      className={classes.root}
      placeholderTitle={placeholderText}
      onClickSearch={onClickSearch}
      onChangeSearch={handleOnChangeSearch}
      // maxLength={SEARCH_MAX_LENGTH}
      disabled={disabled}
      disableSearchBtn={disableSearchBtn}
    />
  )
}

export default React.memo(SearchFilter);
