/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect, useRef } from 'react'
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import clsx from 'clsx';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResetToDefaultOption from './ResetToDefaultOption';
import TokenPickerFunc from './TokenPickerFunc';
import EditorActions from './EditorActions';
import useStyles from './ContentEditor.styles';
import { convertContentToHTML, getStateFromHTML, onEditorStateChange, trimParagraphTags } from '../../Utils/editor/editor-utils';
import { ContentTypes, EditorOptions } from '../../constants/Constants';

const HTMLContentEditor = ({ value, onCancel, onContentUpdate, defaultValue, required, disablePreview, templateDropdownOptions = {} }) => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [enableSave, setEnableSave] = useState(true);
  const [focused, setFocused] = useState(false);
  const editorReference = useRef();

  const onSave = async () => {
    setFocused(false)
    const markup = convertContentToHTML(editorState);
    const html = trimParagraphTags(markup);
    onContentUpdate(html);
  }
  useEffect(() => {
    if (value) {
      updateFromHTML(value)
    }
  }, [value])

  useEffect(() => {
    editorReference?.current?.focusEditor();
  }, [editorReference]);

  const updateFromHTML = (htmlContent) => {
    const updatedEditorState = getStateFromHTML(htmlContent);
    onChange(updatedEditorState);
  }

  const focusHandler = () => {
    setFocused(true);
    editorReference?.current?.focusEditor();
  }
  const onChange = (newEditorState) => {
    onEditorStateChange(newEditorState, setEnableSave, enableSave, { required })
    setEditorState(newEditorState);
  }

  const toolBarCustomOptions = defaultValue ? [
    <ResetToDefaultOption default={defaultValue} key="reset" contentType={ContentTypes.HTML} />,
    <TokenPickerFunc key="tokenPicker" templateDropdownOptions={templateDropdownOptions} />] : [<TokenPickerFunc key="tokenPicker" templateDropdownOptions={templateDropdownOptions} />]
  return (
    <div data-testid="html-editor" className={classes.mh4}>
      <div
        className={classes.editorContainer}
        onClick={focusHandler}
        data-testid="editor-container"
      >
        <Editor
          toolbarClassName={classes.toolbarClassName}
          wrapperClassName={clsx(classes.editorArea, { [classes.editorAreaFocused]: !!focused })}
          editorClassName={`${classes.messageArea} ${classes.backgroundWhite}`}
          ariaLabel="aria label"
          editorState={editorState}
          onEditorStateChange={onChange}
          ref={editorReference}
          onBlur={(event) => !disablePreview ? event.preventDefault() : onSave()}
          toolbar={{
            options: EditorOptions.TOOLBAR_OPTIONS,
            inline: {
              inDropdown: false,
              options: EditorOptions.INLINE_OPTIONS
            },
            fontSize: {
              options: EditorOptions.FONT_SIZE,
            }
          }}
          toolbarCustomButtons={toolBarCustomOptions}
        />
      </div>
      {!disablePreview && (
        <div className={classes.buttonArea}>
          <EditorActions onSave={onSave} onCancel={onCancel} enableSave={enableSave} />
        </div>
      )}
    </div>
  )
}
export default HTMLContentEditor;
