import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {
  ListItem,
  ListItemText,
  Collapse,
  Grid,
  InputAdornment
} from '@material-ui/core'
import {
  ExpandMore,
  ExpandLess,
  Search
} from '@material-ui/icons';
import TextInput from '../inputs/TextInput';
import DateInput from '../inputs/DateInput';
import ChipDropdown from './ChipDropdown';
import FilterSelection from './FilterSelection';
import useStyles from './FilterCategory.styles';
import { FieldTypes, SEARCH_MAX_LENGTH, userSwitchValues } from '../../constants/Constants';

/**
 *
 * Render Filter details for single field on basis of field type.
 */
function FilterCategory(props) {
  const { field, choices, handleOnChange, handleOnChangeSearch, handleOnChangeSelection, handleOnChangeOption, showAdvanced, expandInitial, onClickSearch, value, disabled } = props;
  const classes = useStyles();

  /**
   * @type {array} contains all selected options for this field,
   * not valid for DATE type fields.
   */
  const [checked, setChecked] = React.useState([]);
  /**
   * @type {boolean} if field should be expanded or not.
   */
  const [expand, setExpand] = React.useState(expandInitial);
  /**
   * @type {string} string entered by user in the searchbox.
   */
  const [choiceSearch, setChoiceSearch] = useState('');
  /**
   * @type {array} - containing objects of choices filtered by user search string in advancedFilters Search box.
   */
  const [filteredChoices, setFilteredChoices] = React.useState(choices);

  /**
   * Update the checked array with the selection from value object
   */
  useEffect(() => {
    if (value && value[field.key] && value[field.key].selected) {
      setChecked(value[field.key].selected)
    }
  }, [value, field.key])

  /**
   * Filter available choices to select, depending on user search input.
   */
  useEffect(() => {
    if (choices) {
      setFilteredChoices(() => {
        return choices.reduce((acc, option) => {
          if (!option) {
            return acc;
          }

          const regex = new RegExp(choiceSearch, 'i');
          const checkMatch = arr => arr.find(item => regex.test(item));

          if (checkMatch([option.value, option.label])) { // check if it match
            return [...acc, option];
          } else if (option.childOptions) { // check if any of the child match, if yes filter it
            const newOption = { ...option };
            let childMatch = false;
            for (const key in newOption.childOptions) {
              const filterChild = newOption.childOptions[key].filter(childOption => checkMatch([childOption.value, childOption.label]));
              if (filterChild.length) {
                childMatch = true;
                newOption.childOptions[key] = filterChild;
              }
            }
            if (childMatch) {
              return [...acc, newOption];
            }
          }
          return acc;
        }, []);
      });
    }
  }, [choiceSearch, choices])

  /**
   * If no value is selected for this field, and checked is also empty
   * then re-set the checked array.
   */
  useEffect(() => {
    if (!field.selected && checked.length > 0) {
      setChecked([]);
    }
  }, [field]);

  /**
   * Toggle expand bool
   */
  function handleExpand() {
    setExpand(expanded => !expanded);
  }

  /**
   * Set user type choice string in the state.
   * @param {object} event
   */
  function handleSearchChoice(event) {
    setChoiceSearch(event.target.value);
  }

  /**
   * Handle select/unselect of a choice and calls
   * props.handleOnChangeSelection with the field.key(status), updated selected array["Live", "Ended"], null, selected/unselected value.
   * @param {string} choice
   */
  function handleSelect(choice) {
    const currentIndex = checked.indexOf(choice);
    /**
     * Updates the checked array, if value already present splice it
     * else add it
     */
    setChecked(checked => {
      const newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(choice);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      handleOnChangeSelection(field.key, newChecked, null, choice);
      return newChecked;
    });
  }

  function handleChildSelect(selection, key) {
    handleOnChangeSelection(field.key, selection, key);
  }

  /**
   * Call search on enter press.
   * @param {object} event
   */
  function onKeyPress(event) {
    if (event.key === 'Enter') {
      onClickSearch();
    }
  }

  /**
   * Renders the operator selection for Date type fields.
   * Renders the Date selection controls and directly calls the functions passed from props on any update.
   * @param {object} field
   */
  function renderDate(field) {
    const { label, key } = field;
    /**
     * create the operator options
     */
    const options = [
      { label: 'After', value: 'after' },
      { label: 'Before', value: 'before' },
      { label: 'Range', value: 'range' }
    ];
    const option = field.option ? field.option : options[0].value;
    return (
      <>
        {/* Render operator options */}
        <ChipDropdown
          onChange={handleOnChangeOption(field, true)}
          value={option}
          label={label}
          options={options}
          disabled={disabled}
        />
        {/* Render date Input field
          If range operator is selected, then show this as Start date, with after operator selected
        */}
        <div className={classes.inputsContainer}>
          <DateInput
            value={option === 'range' ? field.after : field[option]}
            label={option === 'range' ? 'Start' : ''}
            className={classes.field}
            onChange={handleOnChange(key, option === 'before' ? 'before' : 'after')}
            size='small'
            margin={option === 'range' ? 'normal' : undefined}
            enablePast
            onKeyPress={onKeyPress}
            disabled={disabled}
          />
          {
            /**
             * only shown when Range is selected, with before as operator
             */
            field.option === 'range' ? (
              <DateInput
                value={field.before}
                label={option === 'range' ? 'End' : ''}
                className={classes.field}
                onChange={handleOnChange(key, 'before')}
                size='small'
                margin={option === 'range' ? 'normal' : undefined}
                enablePast
                onKeyPress={onKeyPress}
                disabled={disabled}
              />
            ) : null
          }
        </div>
      </>
    )
  }

  /**
   * NOT IN USE!
   * @param {*} field
   */
  function renderNumberOption(field) {
    switch (field.option) {
      case 'range':
        return (
          <Grid container spacing={1} className={classes.rangeContainer}>
            <Grid item xs>
              <TextInput
                value={(field && field.lowRange) ? field.lowRange : ''}
                className={classes.field}
                placeholder='Start'
                type='number'
                onKeyPress={onKeyPress}
                onChange={handleOnChangeSearch(field.key, true, 'lowRange')}
                disabled={disabled}
              />
            </Grid>
            <Grid item disablePadding>~</Grid>
            <Grid item xs>
              <TextInput
                value={(field && field.highRange) ? field.highRange : ''}
                className={classes.field}
                placeholder='End'
                type='number'
                onKeyPress={onKeyPress}
                onChange={handleOnChangeSearch(field.key, true, 'highRange')}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        );
      case 'over':
      case 'under':
      default:
        return (
          <TextInput
            value={(field && field.search) ? field.search : ''}
            className={classes.field}
            placeholder='Search'
            type='number'
            onChange={handleOnChangeSearch(field.key, true)}
            onKeyPress={onKeyPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Search style={{ zIndex: 3 }} />
                </InputAdornment>
              )
            }}
            disabled={disabled}
          />
        );
    }
  }

  /**
   * NOT IN USE!
   * @param {*} field
   */
  function renderNumber(field) {
    return (
      <>
        <Collapse in={showAdvanced}>
          <ChipDropdown
            onChange={handleOnChangeOption(field)}
            value={field.option}
            label={field.shortDesc}
            options={[
              { label: 'Contains', value: 'contains' },
              { label: 'Range', value: 'range' },
              { label: 'Over', value: 'over' },
              { label: 'Under', value: 'under' }
            ]}
            disabled={disabled}
          />
        </Collapse>
        {renderNumberOption(field)}
      </>
    );
  }

  function renderEnum(field) {
    return null;
  }

  /**
   * Renders the search box for the given field
   * Only for String type field.
   * @param {object} field
   */
  function renderSearch(field) {
    let searchValue = choiceSearch;
    if (!choiceSearch && value && value[field.key] && value[field.key].search) {
      searchValue = value[field.key].search;
    }
    return (
      <>
        <TextInput
          value={searchValue}
          className={classes.field}
          placeholder='Search'
          onChange={handleOnChangeSearch(field.key, true)}
          onKeyPress={onKeyPress}
          maxLength={SEARCH_MAX_LENGTH}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Search style={{ zIndex: 3 }} />
              </InputAdornment>
            )
          }}
          disabled={(props.switchValue === userSwitchValues.MY_OFFERS && field.defaultSearchForMerchant) || disabled}
        />
      </>
    )
  }

  /**
   * Renders the search box for the given field
   * Only for String type field.
   * @param {object} field
   */
  function renderString(field) {
    return (
      <>
        <TextInput
          value={choiceSearch}
          className={classes.field}
          placeholder='Search'
          onChange={handleSearchChoice}
          maxLength={SEARCH_MAX_LENGTH}
          // onKeyPress={onKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Search style={{ zIndex: 3 }} />
              </InputAdornment>
            )
          }}
          disabled={disabled}
        />
      </>
    )
  }

  function renderFilter(field) {
    switch (field.type) {
      case FieldTypes.ENUM:
        return renderEnum(field);
      case FieldTypes.DATE:
      case FieldTypes.DATE2:
        return renderDate(field);
      case FieldTypes.NUMBER:
        return renderNumber(field);
      case FieldTypes.SEARCH:
      case FieldTypes.PROMO_LABEL:
      case FieldTypes.COMPLEX:
        return renderSearch(field);
      case FieldTypes.STRING:
      default:
        return renderString(field);
    }
  }

  const filterValue = value ? value[field.key] : null;

  return (
    <>
      <ListItem disabled={disabled} button onClick={handleExpand} classes={{ root: classes.mainCatItem }}>
        <ListItemText primary={field.shortDesc} classes={{ primary: classes.shortDesc }} />
        {expand ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={expand}>
        <div className={classes.filterContainer}>
          {renderFilter(field)}
        </div>
        <FilterSelection
          type={field.key}
          maxLength={field.maxLength}
          choices={filteredChoices}
          checked={checked}
          handleSelect={handleSelect}
          handleChildSelect={handleChildSelect}
          filterValue={filterValue}
          disabled={disabled}
          childOptionsLoading={props.childOptionsLoading}
        />
      </Collapse>
    </>
  )
}

export default React.memo(FilterCategory);

FilterCategory.propTypes = {
  /**
   * Boolean to indicate if this field should be expanded on initial render.
   * */
  expandInitial: PropTypes.bool,
  /**
   * If field should be disabled.
   * */
  disabled: PropTypes.bool,
  /**
   * Object passed by filter colum, contains the data of all user selections
   * @example
   * {
   *  start_date: {"data":{"afOrder":1,"advancedFilter":true,"display":false,"filterable":true,"key":false,"name":"start_date","searchable":true,"sortable":true,"type":"DATETIMEOFFSET","displayName":"Campaign Start Date","width":"200","shortDesc":"Campaign Start Date","values":[{"value":"2021-12-21T00:00:00Z","label":"2021-12-21T00:00:00Z"},{"value":"2021-01-01T00:00:00Z","label":"2021-01-01T00:00:00Z"},{"value":"2020-12-05T00:00:00Z","label":"2020-12-05T00:00:00Z"},{"value":"2020-12-17T00:00:00Z","label":"2020-12-17T00:00:00Z"},{"value":"2021-01-06T00:00:00Z","label":"2021-01-06T00:00:00Z"},{"value":"2020-10-15T18:30:00Z","label":"2020-10-15T18:30:00Z"},{"value":"2020-10-19T18:30:00Z","label":"2020-10-19T18:30:00Z"},{"value":"2020-10-29T00:00:00Z","label":"2020-10-29T00:00:00Z"},{"value":"2020-11-12T00:00:00Z","label":"2020-11-12T00:00:00Z"},{"value":"2021-01-07T00:00:00Z","label":"2021-01-07T00:00:00Z"}]},"option":"before","before":"2021-01-19"},
   *  status: {"data":{"advancedFilter":true,"filterable":true,"key":false,"name":"status","display":true,"searchable":true,"sortable":true,"type":"STRING","displayName":"Status","width":"180","shortDesc":"Status","values":[{"value":"Ended","label":"Ended"},{"value":"Publish Failed","label":"Publish Failed"},{"value":"Open to Offers","label":"Open to Offers"},{"value":"Live and Closed to Offers","label":"Live and Closed to Offers"},{"value":"New","label":"New"},{"value":"Live","label":"Live"},{"value":"Closed to Offers","label":"Closed to Offers"},{"value":"Reopened to Offers","label":"Reopened to Offers"},{"value":"Live and Open to Offers","label":"Live and Open to Offers"}]},"selected":["Ended"]}
   * }
   * */
  value: PropTypes.object,
  /**
   * Object containing metadata of the field currently rendered.
   * @example (end_date metadata object)
   * {"afOrder":2,"advancedFilter":true,"display":false,"filterable":true,"key":"end_date","name":"end_date","searchable":true,"sortable":true,"type":"DATETIMEOFFSET","displayName":"Campaign End Date","width":"200","shortDesc":"Campaign End Date","values":[{"value":"2021-12-25T00:00:00Z","label":"2021-12-25T00:00:00Z"},{"value":"2021-01-31T00:00:00Z","label":"2021-01-31T00:00:00Z"},{"value":"2020-12-05T00:00:00Z","label":"2020-12-05T00:00:00Z"},{"value":"2020-10-16T18:29:59Z","label":"2020-10-16T18:29:59Z"},{"value":"2020-12-17T00:00:00Z","label":"2020-12-17T00:00:00Z"},{"value":"2020-12-31T00:00:00Z","label":"2020-12-31T00:00:00Z"},{"value":"2021-01-07T00:00:00Z","label":"2021-01-07T00:00:00Z"},{"value":"2020-10-19T18:30:00Z","label":"2020-10-19T18:30:00Z"},{"value":"2020-10-29T00:00:00Z","label":"2020-10-29T00:00:00Z"},{"value":"2020-12-25T00:00:00Z","label":"2020-12-25T00:00:00Z"}]}
   * */
  field: PropTypes.object
}
