import { ModalOverlayMessage } from '../../constants/Constants';

export function generateDialog({ title, text }, clickHandler) {
  return {
    title: title,
    text: text,
    open: true,
    actions: [
      {
        onClick: () => clickHandler({ open: false }),
        label: 'ok',
        color: 'secondary',
        variant: 'contained'
      }
    ]
  }

}

export async function assignCoupn({ value, rows, callAssignCouponService, setBulkWorkflowDialog }) {
  const res = await callAssignCouponService(value, rows);
  const dialogProps = (res.error || res.errors) ? generateDialog(ModalOverlayMessage.ASSIGN_COUPON(false, value), setBulkWorkflowDialog) :
    generateDialog(ModalOverlayMessage.ASSIGN_COUPON(true, value), setBulkWorkflowDialog);
  setBulkWorkflowDialog(dialogProps);

}

export async function bulkAssignCouponMemberlistUploadAction({ formData, fileName, callBulkAssignCouponMemberlistUploadService, setBulkWorkflowDialog }) {
  const res = await callBulkAssignCouponMemberlistUploadService(formData, fileName);
  const dialogProps = (res.error || res.errors) ? generateDialog(ModalOverlayMessage.BULK_ASSIGN_COUPON_MEMBERLIST_UPLOAD(false, fileName), setBulkWorkflowDialog) :
    generateDialog(ModalOverlayMessage.BULK_ASSIGN_COUPON_MEMBERLIST_UPLOAD(true, fileName), setBulkWorkflowDialog);
  setBulkWorkflowDialog(dialogProps);

  return res;
}

export async function bulkAssignCouponAction({ body, fileName, callBulkAssignCouponService, setBulkWorkflowDialog }) {
  const res = await callBulkAssignCouponService(body);
  const dialogProps = (res.error || res.errors) ? generateDialog(ModalOverlayMessage.BULK_ASSIGN_COUPON(false, fileName), setBulkWorkflowDialog) :
    generateDialog(ModalOverlayMessage.BULK_ASSIGN_COUPON(true, fileName), setBulkWorkflowDialog);
  setBulkWorkflowDialog(dialogProps);

  return res;
}

export async function editLimitAction(value, row, setBulkWorkflowDialog, callOverrideLimitService) {
  const couponValue = { ...row, totalLimit: +value };
  delete couponValue.coupon_group;
  const res = await callOverrideLimitService(couponValue);
  const dialogProps = (res.error || res.errors) ? generateDialog(ModalOverlayMessage.EDIT_LIMIT(false), setBulkWorkflowDialog) :
    generateDialog(ModalOverlayMessage.EDIT_LIMIT(true, res.couponCode), setBulkWorkflowDialog);
  setBulkWorkflowDialog(dialogProps);
}

export async function generateCouponAction(value, row, setBulkWorkflowDialog, callgenerateCouponService) {
  const noOfCouponsToBeGenerated = +value;
  const res = await callgenerateCouponService(noOfCouponsToBeGenerated, row.couponGroupName);
  const dialogProps = (res.error || res.errors) ? generateDialog(ModalOverlayMessage.EXPORT_COUPONS(false, (res.errors && res.errors[0].description || res.message)), setBulkWorkflowDialog) :
    generateDialog(ModalOverlayMessage.EXPORT_COUPONS(true, res.couponCode), setBulkWorkflowDialog);
  setBulkWorkflowDialog(dialogProps);
}
