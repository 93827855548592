import { makeStyles } from '@material-ui/core'
import Colors from '../../theme/Colors';
import { fontSizeMed } from '../../theme/theme';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 60px)',
    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  divider: {
    margin: '0px 4px'
  },
  field: {
    marginRight: 4
  },
  filterItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 0',
    boxSizing: 'border-box',
    justifyContent: 'space-between'
  },
  advancedSwitch: {
    margin: 'auto',
    width: '100%',
    '& .MuiTypography-body1': {
      fontSize: fontSizeMed,
      color: Colors.primaryBlue
    }
  }
}

export default makeStyles(styles);
