import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function getRolesService() {
  try {
    const response = await fetch(`${baseURL}users/roles`, {
      method: 'GET',
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });
    return response;
  } catch (error) {
    return error;
  }
}

export default getRolesService
