const actions = props => {
  const { actionText , actionIcon, func, button, actionProps = {}} = props;
  const actionData = {
    props: {
      ...actionProps,
      text: actionText,
      icon: actionIcon,
      onClick: (id, params) => {
        func(id, params);
      }
    },
    comp: button
  }
  return actionData;
}
export default actions;
