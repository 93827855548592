import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from 'react-loading-skeleton';
import styles from './PageSkeletonStyles';

const PageSkeleton = ({
  classes,
  containerClasses = '',
  bodyClasses = '',
  headerCount = 3,
  bodySectionsCount = 2
}) => {

  return (
    <div className={`${classes.pageSkeletonContainer} ${containerClasses}`}>
      <div className={classes.pageHeader}>
        <HeaderSkeleton count={headerCount} />
      </div>
      <div className={`${classes.pageBody} ${bodyClasses}`}>
        <BodySkeleton count={bodySectionsCount} header />
      </div>
    </div>
  );
}

const Body = ({ classes, header, bodyRows = 2, count = 1 }) => new Array(count).fill(0).map((_, i) => (
  <Fragment key={i}>
    {header ? (
      <Skeleton
        wrapper={({ children }) => <div className={classes.bodyHeaderItem}>{children}</div>}
        height={40}
      />
    ) : null}
    <Skeleton
      wrapper={({ children }) => <div className={classes.bodyItem}>{children}</div>}
      height={40}
      count={bodyRows}
    />
  </Fragment>
));

const Header = ({ classes, count = 1 }) => new Array(count).fill(0).map((_, i) => (
  <div key={i} className={classes.headerItem}>
    <Skeleton
      height={40}
    />
  </div>
));

const BodySkeleton = withStyles(styles, { withTheme: true })(Body)
const HeaderSkeleton = withStyles(styles, { withTheme: true })(Header)

export { BodySkeleton, HeaderSkeleton };

export default withStyles(styles, { withTheme: true })(PageSkeleton);
