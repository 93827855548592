/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';


export default function TokenPickerFunc(props) {
  const [open, setOpen] = useState(false);
  const openPlaceholderDropdown = () => setOpen(!open)

  const addPlaceholder = (placeholder) => {
    const { editorState, onChange } = props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ` ${placeholder} `,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  var placeholderOptions = [];
  if (props && props.templateDropdownOptions && Object.keys(props.templateDropdownOptions).length !== 0) {
    for (const [key, value] of Object.entries(props.templateDropdownOptions)) {
      placeholderOptions.push({ value: key, text: value })
    }
  }

  const listItem = placeholderOptions.map(item => (
    <li key={item.value} className="rdw-dropdownoption-default placeholder-li">
      <div
        onClick={() => addPlaceholder(item.value)}
        role="button"
        tabIndex="0"
      >
        {item.text}
      </div>
    </li>
  ))

  return (
    <div
      onClick={openPlaceholderDropdown}
      tabIndex="0"
      role="button"
      className="rdw-block-wrapper"
      aria-label="rdw-block-control"
    >
      <div className="rdw-dropdown-wrapper" aria-label="rdw-dropdown">
        <div className="rdw-dropdown-selectedtext" style={{ whiteSpace: 'nowrap', paddingRight: '20px' }} title="Token Picker Dropdown">
          <span>Click to enter variable value</span>
          <div style={{right:"1%"}} className={`rdw-dropdown-caretto${open ? "close" : "open"}`} />
        </div>
        {open ? (
          <ul className="rdw-dropdown-optionwrapper placeholder-ul" data-testid="tokens-list">
            {listItem}
          </ul>
        ) : null}
      </div>
    </div>
  );
}
TokenPickerFunc.propTypes = {
  onChange: PropTypes.func
};
