import React from 'react';
import Diff from '../Diff';
import BooleanInput from '../../inputs/BooleanInput';

export default function BooleanInputDiff({condition, data, mode, ...props}){
  return (
    <Diff
      mode={mode}
      viewOnlyComponent={(<BooleanInput {...props} disabled />)}
    >
      <BooleanInput {...props} />
    </Diff>
  );
}
