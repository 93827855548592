import React from 'react';
import { FormControlLabel, Switch, Checkbox } from '@material-ui/core';
import clsx from 'clsx';
import useStyle from './BooleanInputStyles';

function BooleanInput(props) {
  const classes = useStyle();
  const { value, onChange, label, labelPlacement = 'end', variant = 'checkbox', margin, disabled, error, required, topPadding = 0 } = props;

  function handleChange(event) {
    const value = event.target.checked;
    onChange({
      target: {
        value
      }
    });
  }

  const sharedProps = {
    checked: !!value,
    onChange: handleChange,
    disabled
  }

  const component = variant === 'checkbox' ? (
    <Checkbox
      {...sharedProps}
    />
  ) : (
    <Switch
      {...sharedProps}
    />
  );

  const containerClasses = clsx([classes.root, { [classes.marginNormal]: margin === 'normal' }, error ? classes.formError : {}])

  if (label) {
    return (
      <FormControlLabel
        className={containerClasses}
        control={component}
        label={label + (required ? ' *' : '')}
        labelPlacement={labelPlacement}
        style={{ paddingTop: topPadding }}
      />
    )
  }

  return <div className={containerClasses}>{component}</div>;
}

export default BooleanInput;
