import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./Template.styles";
import ViewOnly from '../../viewOnly/ViewOnly';
import TextInput from '../../inputs/TextInput';
import getDeepValue from "../../../Utils/getDeepValue";

const TemplateNumber = props => {
  const { field, classes } = props;
  const {
    mode,
    readOnly,
    limit,
    onChange,
    minimum: minimumField,
    decimalPlaces,
    initDisable
  } = field;

  const minimum = minimumField || getDeepValue(field, ['queries', 'minimum']);
  const isAWholeNumber =  getDeepValue(field, ['queries', 'isWholeNumber']);

  const truncateValue = (value) => {
    return value && isAWholeNumber && (!isNaN(value)) ? Math.trunc(value) : value
  }

  /**
     * Rounds a number to a specified number of decimal places without rounding.
     *
     * @param {number} value - The number to be rounded.
     * @param {number} decimalPlaces - The number of decimal places to round to.
     * @returns {number} - The rounded number.
  */
  function toFixedWithoutRounding(value, decimalPlaces) {
    const power = Math.pow(10, decimalPlaces);
    const newValue = Math.floor(value * power) / power;
    return +newValue.toFixed(decimalPlaces);
  }

  const handleChange = event => {
    if (event && event.target) {
      const value = event.target.value;
      const numValue = +value;
      const minValue = minimum ? +minimum : null;
      if (value === '' || /[^0-9.]/.test(value)) { // If null or not a number
        onChange({
          target: {
            value: ''
          }
        });
      } else if ((minimum || minimum === 0) && value && numValue < minValue) { // If below minimum value
        onChange({
          target: {
            value: '' + truncateValue(minValue)
          }
        });
      } else if (decimalPlaces && value.includes('.') && value.split(".")[1].length > decimalPlaces &&
          !isNaN(decimalPlaces) && decimalPlaces > 0){ // if decimal places need to be restricted
        const newValue = toFixedWithoutRounding(+value, decimalPlaces);
        onChange({
          target: {
            value: '' + truncateValue(newValue)
          }
        });
      } else {
        const newValue = limit && !isNaN(limit) && numValue > limit ? limit : value; // prevent from going over limit
        onChange({
          target: {
            value: '' + truncateValue(newValue)
          }
        });
      }
    }
  };

  const handleMouseEvent = (event) => ["e", "E", "+", "-"].includes(event.key) && event.preventDefault()


  const isViewOnly = (mode === "viewOnly");
  const isDisabled = readOnly || isViewOnly || initDisable;

  const finalProps = {
    id: field.name,
    margin: "normal",
    onChange: handleChange,
    onKeyDown: handleMouseEvent,
    value: truncateValue(field.value),
    label: field.shortDesc,
    placeholder: field.longDesc,
    condition: field.name,
    mode: mode,
    data: field.data,
    required: field.required,
    disabled: isDisabled,
    type: 'number',
    error: field.error,

  }

  if (isViewOnly) {
    return (
      <ViewOnly
        className={`${classes.root} ${classes.flex}`}
        {...finalProps}
      />
    )
  } else {
    return (
      <TextInput
        className={`${classes.root} ${classes.flex}`}
        {...finalProps}
      />
    );

  }


};
export { TemplateNumber as TestTemplateNumber }

export default withStyles(styles)(TemplateNumber);
