import gql from "graphql-tag";

const GET_TEMPLATE_BY_ID = gql`
  query getTemplate($templateId: String!) {
    template(templateId: $templateId)
      @rest(
        type: "template"
        path: "template/{args.templateId}"
      ) {
      payload
    }
  }
`;

const GET_BULK_TEMPLATE_BY_ID = gql`
  mutation getBulkTemplate {
    template(input: $input)
      @rest(
        type: "template"
        path: "template/search"
        method: "POST"
      ) {
      status
      errors
      payload
    }
  }
`;

const SUBMIT_TEMPLATE = gql`
  mutation submitTemplate {
    submitTemplate(input: $input, workflowId: $workflowId)
      @rest(
        type: "template"
        path: "workflow/{args.workflowId}/execute"
        method: "POST"
      ) {
      status
      errors
      payload
    }
  }
`;

const ASSIGN_QUERY = gql`
fragment Assignment on Promoion {
  assignId
  type
 }
`;

const GET_ALL_ITEMS = gql`
mutation getAllItems($searchType: String!){
  getAllItems(input: $input, searchType: $searchType) @rest(type: "getAllItems", path: "{args.searchType}/search", method: "POST") {
    status,
    header,
    errors,
    payload
  }
}`;

export { GET_TEMPLATE_BY_ID, GET_BULK_TEMPLATE_BY_ID, SUBMIT_TEMPLATE, ASSIGN_QUERY, GET_ALL_ITEMS };
