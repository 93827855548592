import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./Template.styles";
import SelectInput from '../../inputs/SelectInput';
import ViewOnly from '../../viewOnly/ViewOnly';
import parseOptions, { sortLabel, parseLabel } from '../../../Utils/input/parseOptions';

const TemplateEnum = props => {
  const { field, classes } = props;
  const {
    values,
    mode,
    options: otherOptions,
    initDisable,
    queries
  } = field;

  const defaultOptions = parseOptions(values);

  const additionalOptions = parseOptions(otherOptions);

  const options = [
    ...(defaultOptions ? defaultOptions : []),
    ...(additionalOptions ? additionalOptions : [])
  ].sort(sortLabel).map(option => ({ // Sort types so we have control over the order
    ...option,
    label: parseLabel(option.label)
  }));

  const isViewOnly = (mode === "viewOnly");
  const finalProps={
    id:field.name,
    className:classes.templateEnumRoot,
    margin:"normal",
    label:field.shortDesc,
    placeholder:field.longDesc,
    value:field.value,
    options:options,
    onChange:field.onChange,
    condition:field.name,
    mode:mode,
    data:field.data,
    required:field.required,
    disabled:field.readOnly || isViewOnly ? (field.readOnly || isViewOnly) : false || initDisable,
    error:field.error,
    viewOnlyValue:field.values ? parseLabel(field.values[field.value]) : field.value,
    showNoWrapColumn: queries&&queries.showNoWrapColumn&&(queries.showNoWrapColumn===true||queries.showNoWrapColumn==="true")?true:false
  }

  if(isViewOnly){
    return(
      <ViewOnly
        fullWidth
        {...finalProps}
      />

    )
  } else{
    return (
      <SelectInput
        fullWidth
        style={{
          minWidth: 0
        }}
        {...finalProps}
      />
    );

  }

};

export default withStyles(styles)(TemplateEnum);
