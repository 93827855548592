import React, { useState, isValidElement } from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import useStyles from './ViewOnly.styles';
import FullScreenModal from '../modal/FullScreenModal';
import Search from '../../compartments/search/search';
import ClubList from '../ClubList/ClubList';
import ItemList from '../../compartments/item/ItemList';
import UserList from '../../compartments/user/UsersList';
import CustomButton from '../buttons/CustomButton/CustomButton';
import { SearchTypes, FieldTypes, DISPLAY_LIMIT, BlobProcessStatus, up } from '../../constants/Constants';
import getDeepValue from '../../Utils/getDeepValue';
import CsvInput from "../inputs/CsvInput";

function ViewOnly(props) {
  const classes = useStyles();
  const {
    value,
    label,
    required,
    downloadBiglist,
    searchType: _searchType,
    parentMixType,
    viewOnlyValue,
    biglistStatus,
    biglistName,
    queries,
    enableEditListButton,
    navigateToSavedLists,
    dateEnabled
  } = props;
  const [searchType, subSearchType] = _searchType ? _searchType.split('.') : []
  const [open, setOpen] = useState(null);
  const viewOnlyEmptyValue = getDeepValue(queries, ['viewOnlyEmptyValue']);
  const [viewList, setViewList] = useState(null);


  const isArray = Array.isArray(value) && value.length > 0;
  const isMixType = !!parentMixType;
  const isLongArray = isMixType && Array.isArray(value) && value.length > DISPLAY_LIMIT;
  const isBiglist = isMixType && up(parentMixType) === 'BIGLIST';
  const isCSV = isMixType && up(parentMixType) === 'CSV';

  function openMenu(event) {
    event.stopPropagation();
    event.preventDefault();
    if (downloadBiglist && value) {
      downloadBiglist();
      return;
    }
    setOpen(true);
  }


  function createFilter() {
    let field = subSearchType
    if (!field) {
      switch(searchType) {
        case SearchTypes.ITEM:
          field = 'itemNumber';
          break;
        default:
          field = 'id';
          break;
      }
    }

    const filter = {
      [field]: {
        selected: value,
        data: {
          type: FieldTypes.STRING
        }
      }
    }

    return filter;
  }

  function renderTable() {

    const commonProps = {
      searchType: searchType,
      isModal: false,
      parentFilter: createFilter()
    }
    // Show modal with three tabs of view, search, enter/paste for items on View List
    if(viewList) {
      const commonProps = {
        fromViewOnly: true,
        closeViewOnly: closeMenu,
        searchType: searchType,
        multiSelect: false,
        biglistName,
        biglistContinue: biglistName
      }
      return <CsvInput {...commonProps} />
    }

    switch (up(searchType)) {
      case SearchTypes.CLUB:
        return <ClubList {...commonProps} noDate={!dateEnabled} />;
      case SearchTypes.ITEM:
        return <ItemList {...commonProps} />;
      case SearchTypes.USERS:
        return <UserList {...commonProps} />
      case SearchTypes.COUPON:
      default:
        return <Search {...commonProps} />;
    }
  }

  function closeMenu() {
    setOpen(false);
    setViewList(false);
  }

  function showList() {
    setOpen(true);
    setViewList(true);
  }

  let child = null;
  if (viewOnlyValue && (isValidElement(viewOnlyValue) || typeof viewOnlyValue === 'string')) {
    child = viewOnlyValue;
  } else if (isValidElement(value)) {
    child = value;
  } else if (isArray) {
    const val = parentMixType ? value.slice(0,DISPLAY_LIMIT) : value;
    child = val.join(', ');
  } else {
    if (viewOnlyValue) {
      child = viewOnlyValue;
    } else if (value === 0 || value) {
      child = value;
    } else if (viewOnlyEmptyValue) {
      child = viewOnlyEmptyValue;
    } else {
      child = 'Not Set';
    }
  }

  if (biglistName) {
    child = (
      <>
        <div className={classes.biglistName}>{`${biglistName}`}</div>
        {child ? <span>{child}</span> : null}
      </>
    )
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.label}>
        {`${label ? label : ''}${label && required ? '*' : ''}`}
      </Typography>
      <Typography className={clsx(classes.value, { [classes.valueError]: biglistStatus === BlobProcessStatus.FAILED })}>
        {child}
        { (isCSV && isLongArray) || (downloadBiglist && value) ? (
          <>
            <Button
              classes={{
                root: classes.moreButton,
                label: classes.moreLabel
              }}
              onClick={openMenu}
              color='secondary'
              disabled={biglistStatus === BlobProcessStatus.LOADING || biglistStatus === BlobProcessStatus.FAILED || isValidElement(value)}
            >
              <MoreHoriz className={classes.moreIcon} />
              {downloadBiglist ? (
                <span className={classes.moreText}>
                  Export
                </span>
              ) : (
                <span className={classes.moreText}>
                  More
                </span>
              )}
            </Button>
            {
              isBiglist && searchType && up(searchType) === SearchTypes.ITEM ? (
                <>
                  |
                  <Button
                    data-testid="viewList"
                    color="secondary"
                    onClick={showList}
                    classes={{
                      root: classes.editList
                    }}
                    disabled={biglistStatus === BlobProcessStatus.LOADING || biglistStatus === BlobProcessStatus.FAILED || isValidElement(value)}
                  >
                    View List
                  </Button>
                </>
              )
                : null
            }
          </>
        ) : null }
        {
          enableEditListButton && (
            <>
              |
              <Button
                color="secondary"
                onClick={() => navigateToSavedLists()}
                classes={{
                  root: classes.editList
                }}
              >
                Edit List
              </Button>
            </>
          )}
      </Typography>
      { isMixType ? (
        <FullScreenModal
          open={open}
          onRequestClose={closeMenu}
          title={searchType + ' list'}
        >
          <div className={classes.modalBody}>
            <div className={classes.listContainer}>
              {renderTable()}
            </div>
            <div className={classes.modalActions}>
              <CustomButton
                key={label}
                className={classes.actionButtons}
                variant='text'
                color='secondary'
                onClick={closeMenu}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
        </FullScreenModal>
      ) : null}
    </div>
  )
}

export default ViewOnly;
