import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import useStyles from './SelectButton.styles';
import CustomButton from '../CustomButton/CustomButton';
import SelectInput from '../../inputs/SelectInput';
import getRolesService from '../../../services/admin/GetRolesService';
import { Roles } from '../../../constants/Constants';
// import getValueFromOptions from '../../../Utils/select/getValueFromOptions';

function SelectButton(props) {
  const classes = useStyles();
  const {
    onClick,
    className,
    disabled,
    description,
    ...buttonProps
  } = props;

  // const options = [
  //   { label: 'ROLE_SUPPLIER', value: 'c8fdd545-b1c1-4b10-b6ba-d0d3b3f76aaa' },
  //   { label: 'ROLE_MERCHANT', value: 'c703fd3d-2701-4584-9a3f-fced7b06c233' },
  //   { label: 'ROLE_OPERATIONS', value: '518b9016-d132-4935-8932-8a21cb0df55d' },
  //   { label: 'ROLE_SUPERADMIN', value: '4df45a28-4f2e-4150-b5cb-0bd2146b1331' }
  // ]

  const [options, setOptions] = useState([])

  const [value, setValue] = useState(null);
  const [name, setName] = useState(null);

  useEffect(() => {
    getRoles();
  }, [])

  function handleOnClick() {
    onClick({
      target: {
        value: value,
        name:name
      }
    })
  }

  async function getRoles() {
    const roles = await getRolesService();
    const options = roles.filter((role) => role.name !== Roles.SUPERADMIN).map((role) => ({
      label: role.description,
      value: role.id
    }))
    setOptions(options);
  }

  function handleOnChange(event,name) {
    if (event && event.target) {
      const value = event.target.value;
      setValue(value);
      setName(name)
    }
  }

  return (
    <div className={clsx(classes.root, className)}>
      <SelectInput
        value={value}
        onChange={handleOnChange}
        options={options}
        // disabled={disabled}
        placeholder={description}
        className={classes.select}
      />
      <CustomButton
        {...buttonProps}
        onClick={handleOnClick}
        disabled={disabled || !value}
      />
    </div>
  );
}

export default SelectButton;
