import { makeStyles } from '@material-ui/core'
import Colors from '../../theme/Colors'
import { fontRegular, fontMedium } from '../../theme/theme'

const styles = {
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  modal: {
    position: 'absolute',
    width: '100vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    outline: 'none',
    transform: `translate(-50%, -50%)`,
    '@media (min-width: 1024px)': {
      '&': {
        width: '90vw'
      }
    }
  },
  biglistContainer: {
    paddingRight: 16
  },
  searchContainer: {
    paddingRight: 16,
    display: 'flex',
    alignItems: 'center'
  },

  label_box: {
    width: '150px',
    display: 'inline-block'
  },
  label_text: {
    marginLeft: '20px'
  },
  input_text: {
    // marginRight: 16
  },
  search_button: {
    marginTop: 14,
    marginBottom: 10,
    marginLeft: 8,
    alignSelf: 'center'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 108,
    overflowY: 'auto'
  },
  chip: {
    margin: 2
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  selectRoot: {
    height: '100%',
    width: '100%',
    '&.MuiSelect-select': {
      padding: 8,
      paddingRight: 0
    }
  },
  placeholderText: {
    color: Colors.grey300
  },
  searchAdornment: {
    zIndex: 3
  },
  buttonAdornment: {
    display: 'flex',
    zIndex: 3,
    maxHeight: 'fit-content',
    height: 'fit-content',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      margin: 4
    }
  },
  modalBody: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
    // height: 'calc(100% - 68px)'
  },
  modalActions: {
    padding: 16,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionButtons: {
    minWidth: 128,
    '&:not(:first-child)': {
      marginLeft: 16
    }
  },
  listContainer: {
    flex: 1,
    overflow: 'hidden'
  },
  indicator: {
    height: 4
  },
  tabs: {
    width: '100%',
    maxWidth: 1260,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  tabWrapper: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: fontRegular
  },
  tabSelected: {
    '& $tabWrapper': {
      fontWeight: fontMedium
    }
  },
  tab: {
    borderBottom: `4px solid ${Colors.grey100}`,
    maxWidth: 240
  },
  tabsRoot: {
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  uploadContainer: {
    width: '80%',
    margin: 'auto',
    padding: 16,
    boxSizing: 'border-box'
  },
  biglistNameContainer: {
    overflow: 'hidden',
    padddingLeft: 4
  },
  biglistName: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    fontWeight: 'bold'
  },
  errorText: {
    color: Colors.red
  },
  pullSignButton: {
    marginTop: 4,
    padding: 8,
    height: 'auto',
  }
}

export default makeStyles(styles);
