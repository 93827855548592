import React, { forwardRef } from 'react';
import Typography from '@material-ui/core/Typography';
import Lock from '@material-ui/icons/LockOutlined';
import useStyles from './custom-table.styles';
import { FieldTypes, DateFormat } from '../../constants/Constants';
import dateFormat from '../../Utils/date/dateFormat';
import Brand from '../brand/Brand';
import BrandSmart from '../brand/BrandSmart'
import notNull from '../../Utils/notNull';
import getLabel from '../../Utils/biglist/getLabel';

function TableData(props, ref) {
  const classes = useStyles();

  const { rowValue, field, fieldKey, row, className, ...tooltipProps } = props;

  const values = row.values ? row.values : row;

  let displayed = "";
  if (field.type === FieldTypes.DATE || field.type === FieldTypes.DATE2) {
    displayed = dateFormat(rowValue, DateFormat.TABLE, true);
  } else if (field.type === FieldTypes.BOOLEAN) {
    displayed = notNull(rowValue) ? rowValue.toString() : rowValue;
  } else if (field.type === FieldTypes.STATUS) {
    displayed = notNull(rowValue) ? getLabel(rowValue) : rowValue;
  } else {
    displayed = rowValue;
  }

  if (field.type === FieldTypes.BRAND_PREVIEW) {
    return (
      <Brand
        ref={ref}
        {...tooltipProps}
        badgeText={values.badgeText || values.badge}
        backgroundColor={values.backgroundColor}
        foregroundColor={values.foregroundColor}
        outlineColor={values.outlineColor}
        cornerRadius={values.cornerRadius}
        align='left'
      />
    )
  }

  if (fieldKey === 'event_sales_branding' && notNull(rowValue)) {
    return (
      <BrandSmart ref={ref} {...tooltipProps} name={displayed} />
    )
  }

  return (
    <Typography ref={ref} variant='body1' noWrap {...tooltipProps} className={className}>
      {notNull(rowValue) ? displayed : ""}
      {field.showLock && row.isLocked ? <Lock className={classes.lockIcon} /> : null}
    </Typography>
  )

}

export default forwardRef(TableData);
