import { baseURL, baseHeaders } from '../../constants/Endpoints';
import { SearchTypes, up } from '../../constants/Constants';

async function historyService(searchType, parentId) {
  const historyFor = up(searchType) === SearchTypes.CAMPAIGN ? searchType : `${searchType}s`
  try {
    return await fetch(`${baseURL}${historyFor}/history/${parentId}`, {
      method: "GET",
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });
  } catch (error) {
    return error;
  }
}
export default historyService;
