const { SearchTypes, low, BlobProcessStatus } = require("../../constants/Constants");

const searchIndexMapper = (type, params) => (item) => {
  const { user } = params;
  switch(low(type)) {
    case low(SearchTypes.DOWNLOAD):
      item = {
        ...item,
        exportStatus: item?.downloads?.find(download => download.userId === user.id)?.data?.status || BlobProcessStatus.REQUESTED,
      }
      break;
  }

  return item;

}

export default searchIndexMapper;
