import {makeStyles} from '@material-ui/core/styles';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  select: {
    marginRight: 8,
    width: 200
  }
};

export default makeStyles(styles)
