import { DownloadPDFErrors } from '../../constants/Errors';
import { Index } from '../../constants/Endpoints';
import fetchAll from '../../Utils/services/fetchAll';
import promotionPDFService from './promotionPDFService';

async function getBulkPDFData(campaignRow, offerTypes) {
  const campaignNumber = campaignRow.values.campaign_number;
  const body = {
    filter: `values/campaign_number eq '${campaignNumber}' and (current eq 'true' or current eq 'True')`
  }
  try {
    let promotions = await fetchAll(Index.PROMOTION, body);
    if (!promotions) {
      throw new Error(DownloadPDFErrors.SERVICE_ERROR)
    }
    if (offerTypes) {
      promotions = promotions.filter(promotion => offerTypes.includes(promotion.offerType))
    }
    const bulkPDFData = [];
    let failures = 0;
    for (const promotion of promotions) {
      const offerData = await promotionPDFService(promotion, campaignRow)
      const error = offerData ? Object.keys(offerData).reduce((prev,curr) => prev || offerData[curr] === 'error', false) : false
      if (error) {
        console.error('Error creating dealsheet for promotion:', promotion)
        failures++;
        continue;
      }
      bulkPDFData.push(offerData);
    }
    return { bulkPDFData, failures };
  } catch (error) {
    console.error(error)
    throw new Error(DownloadPDFErrors.SERVICE_ERROR)
  }
}


export default getBulkPDFData;
