import React, { useState } from "react";
import { withStyles, Button } from "@material-ui/core";
import styles from "./Template.styles";
import TextInput from '../../inputs/TextInput';
import ViewOnly from '../../viewOnly/ViewOnly';
import getDeepValue from '../../../Utils/getDeepValue';
import { initString } from '../../../Utils/helpers';
import { StringFilterTypes, LabelWhitelist, NameWhitelist, UserWhitelist, FieldTypes } from '../../../constants/Constants';
import TemplateModal from './TemplateModal';

const TemplateString = props => {
  const { classes, field, dispatch, group, dataState, setTemplateModalData, workflowItem } = props;
  const {
    mode,
    readOnly,
    options: autocompleteOptions,
    queries,
    initDisable
  } = field;

  const [openModal, setOpenModal] = useState(false);
  const isViewOnly = (mode === "viewOnly");
  const autocomplete = getDeepValue(queries, ['autocomplete']);
  const filterType = getDeepValue(queries, ['filterType']);
  let options = null;
  if (autocomplete) {
    options = autocompleteOptions
      ? Object.keys(autocompleteOptions).map(key => ({
        value: key,
        label: autocompleteOptions[key]
      }))
      : null;
  }


  /*saveDataInParent - It would enable the input to have a child modal and the output of the modal can be set used for setting up the value of that input
 w/o using a field Function.
 hasMultipleGroups- It signifies if the child modal has  mutiple groups
  */

  const modalProps = {
    btnName: isViewOnly ? 'View..' : 'Edit..',
    rootType: queries && queries.rootType,
    templateId: queries && queries.templateId,
    templateGroupType: queries && queries.templateGroupType,
    modalVariant: queries && queries.modalVariant,
    hasMultipleGroups: queries && queries.hasMultipleGroups,
    saveDataInParent: queries && queries.saveDataInParent,
    sameScreenModal: queries && queries.sameScreenModal,
    childValueType: queries && queries.childValueType,
    parentData: { parentTemplateType: queries && queries.parentTemplateType, childDefaultValues: queries && queries.childDefaultValues }
  }

  const isEditorType = modalProps.childValueType === FieldTypes.EDITOR_HTML || modalProps.childValueType === FieldTypes.EDITOR_TEXT

  const isDisabled = (readOnly || isViewOnly ? (readOnly || isViewOnly) : false || initDisable) || (isViewOnly && isEditorType && field.serverValue);

  /* we are aetting up the click handlers here if we want the modal to be controlled from this page */
  const handleOpen = () => {
    setOpenModal(true);
  }
  const handleClose = () => {
    setOpenModal(false);
  }

  async function onChange(event) {
    const oldValue = event.target.value;
    let newValue;

    if (filterType) {
      switch (filterType) {
        case (StringFilterTypes.NAME):
          newValue = oldValue.replace(new RegExp(NameWhitelist, 'g'), '');
          break;
        case (StringFilterTypes.LABEL):
          newValue = oldValue.replace(new RegExp(LabelWhitelist, 'g'), '');
          break;
        case (StringFilterTypes.USER):
          newValue = oldValue.replace(UserWhitelist, '');
          break;
        default:
          newValue = oldValue.replace(new RegExp(filterType, 'g'), '');
          break;
      }
    } else {
      newValue = oldValue;
    }

    newValue = newValue.replace(/\s+/g, ' ') // Collapse spaces

    await field.onChange({
      target: {
        value: newValue
      }
    })
  }



  const evaluateValue = (field) => {
    if (typeof field.value === 'object' && typeof field.serverValue === 'object') {
      if ((field.value && field.serverValue) && (Object.keys(field.serverValue).length === 0 && Object.keys(field.value).length === 0)) {
        return "";
      } else if (field.serverValue && Object.keys(field.serverValue).length > 0 && isViewOnly && queries && queries.childFieldName) {
        return initString(field.serverValue[queries.childFieldName]);
      } else {
        return "";
      }
    } else {
      return initString(field.value);
    }
  }

  const finalProps = {
    id: field.name,
    margin: "normal",
    onChange: onChange,
    label: field.shortDesc,
    placeholder: field.longDesc,
    value: evaluateValue(field),
    condition: field.name,
    mode: mode,
    data: field.data,
    disabled: isDisabled,
    required: field.required,
    error: field.error,
    multiline: field.multi,
    rows: 4,
    options: options,
    maxLength: field.limit
  }


  const renderModal = () => {
    return queries && queries.childTemplate && queries.saveDataInParent
      ? (
        <>
          <div className={classes.buttonContainer}>
            {/* {' '} */}
            {!(!finalProps.value && isViewOnly) && <Button color='secondary' variant='text' style={{ marginLeft: '8px' }} onClick={handleOpen}>{modalProps.btnName}</Button>}
          </div>
          <TemplateModal
            modalProps={modalProps}
            classes={classes}
            dispatch={dispatch}
            field={field}
            group={group}
            dataState={dataState}
            setTemplateModalData={setTemplateModalData}
            isViewOnly={isViewOnly}
            workflowItem={workflowItem}
            openModalFromParent={openModal}
            closeModalInparent={handleClose}
          />
        </>
      )
      : null
  }


  if (isViewOnly && !(isEditorType && finalProps.value)) {
    return (
      <div>
        <ViewOnly
          className={classes.root}
          {...finalProps}
        />
        {renderModal()}
      </div>
    )
  } else {
    return (
      <div className={classes.textContainer}>
        <TextInput
          className={classes.root}
          {...finalProps}
          style={{ flexGrow: 1 }}
        />
        {renderModal()}
      </div>
    )

  }




};

export default withStyles(styles)(TemplateString);
