import moment from 'moment-timezone';
import { TIMEZONE_REGION } from '../constants/Constants';


/**
 * Checks if a workflow item has ended based on the current date and time.
 *
 * @param {Object} workflowItem - The workflow item to check.
 * @param {Object} workflowItem.values - The values of the workflow item.
 * @param {string} workflowItem.values.schedule_timezone - The timezone of the schedule.
 * @param {boolean} workflowItem.values.d2h_channel - Indicates if the item is online.
 * @param {string} workflowItem.values.end_date - The end date of the workflow item.
 * @param {string} workflowItem.values.end_time - The end time of the workflow item.
 * @param {Object} [date=moment()] - The current date and time to compare against.
 * @returns {boolean} - Returns true if the workflow item has ended, otherwise false.
 */
function checkIsEnded(workflowItem, date = moment()) {
  if (!workflowItem || !workflowItem.values) {
    return false;
  }

  const item = workflowItem.values;

  let timezone = TIMEZONE_REGION[item.schedule_timezone] || 'America/Chicago';

  // is online
  if (item.d2h_channel) {
    timezone = TIMEZONE_REGION['CST'];
  }

  return moment.tz(item.end_date + ' ' + item.end_time, timezone).isBefore(date)
}

export default checkIsEnded;
