function templateToObject(templateObject, payloadKey="values") {
  const object = {};
  if (templateObject && templateObject[payloadKey]) {
    const values = templateObject[payloadKey];
    for (const key in values) {
      const path = key.split(':');
      let objectPath = object;
      for (let i = 0; i < path.length; i++) {
        if (i === path.length - 1) {
          objectPath[path[i]] = values[key];
        } else {
          if ( objectPath[path[i]]) {
            objectPath[path[i]] = { ...objectPath[path[i]] };
          } else {
            objectPath[path[i]] = {};
          }
          objectPath = objectPath[path[i]];
        }
      }
    }
    return object;
  }

  return null;
}

export default templateToObject;
