import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  IconButton,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Tooltip,
  Paper
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import clsx from 'clsx';
import CustomButton from '../../../widgets/buttons/CustomButton/CustomButton';
import { BlobActions, Status, BlobTypes, TemplateMixTypes, low, DateFormat, ModalOverlayMessage, BIGLIST_EDIT_LIMIT } from '../../../constants/Constants';
import SelectFileButton from '../../../widgets/buttons/SelectFileButton/SelectFileButton';
import { UploadListErrors } from '../../../constants/Errors';
import updateBiglistService from '../../../services/biglist/UpdateBiglistService';
import useLoadingService from '../../../hooks/useLoadingService';
import useStyles from './BigListEdit.styles';
import useScreen from '../../../hooks/useScreen';
import dateFormat from '../../../Utils/date/dateFormat';
import arrayToCSV from '../../../Utils/biglist/arrayToCSV';
import SearchBar from '../../../widgets/search/search';
import TitleText from '../../../widgets/text/TitleText';
import CsvInput from '../../../widgets/inputs/CsvInput';
import getBiglistEditValues from '../../../Utils/template/getBiglistEditValues';
import ConfirmationDialog from '../../../widgets/Confirmation/ConfirmationDialog';
import checkBiglistValuesService from '../../../services/biglist/CheckBiglistValuesService';
import { useFileTracker } from '../../../containers/biglist/FileTracker/FileTracker';
import { baseFolder } from '../../../constants/Endpoints';


/**
 * BiglistEdit component handles the editing of big lists, including adding or removing single members or batches of members using files.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.type - The type of the big list being edited.
 * @param {string} props.selectedFile - The name of the selected file for editing.
 * @param {Function} [props.close=() => undefined] - Function to close the modal.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <BiglistEdit type="MEMBER" selectedFile="example.csv" close={() => console.log('Modal closed')} />
 */
function BiglistEdit(props) {
  const editComponent = useRef(null);
  /**
   * @type {array} List of all single member added/removed entries.
   */
  const [entryList, setEntryList] = useState([]);
  /**
   * @type {object} - result for single member entry search.
   */
  const [entry, setEntry] = useState({
    id: '',
    startDate: null,
    endDate: null,
    searchFound: false
  });
  const [closeModalState, setCloseModalState] = useState(false);
  const [, setEntryError] = useState(null);
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState('');
  /**
   * @type {boolean} Signifies if the search query has completed
   */
  const [hasSearched, setHasSearched] = useState(false);
  /**
   * @type {object} - fileObj with entries to be added or removed from selected Member list for edit.
   */
  const [fileName, setFileName] = useState('');
  const [dialog, setDialog] = useState({ show: false, message: '', title: '' });
  const { isMobile, isPortrait } = useScreen();
  const [updateBigList, { loading: loadingUpdate }] = useLoadingService(updateBiglistService);
  const [removeBigList, { loading: loadingRemove }] = useLoadingService(updateBiglistService);
  const [isDataFetching, setDataFetching] = useState(false);

  const {
    type,
    selectedFile,
    close = () => undefined,
  } = props;

  const {
    files,
    uploadFileObjs,
    removeFile,
    updateFile,
    uploadFile,
    addFileList,
  } = useFileTracker();

  const readyFiles = files.filter((fileObj) => fileObj.status === Status.READY) || [];
  const dateEnabled = low(type) === BlobTypes.CLUB_OVERRIDE;
  const searchType = low(type) === BlobTypes.CLUB_OVERRIDE ? BlobTypes.CLUB : type;
  const typeCheck = [BlobTypes.ITEM, BlobTypes.CLUB, BlobTypes.CLUB_OVERRIDE].includes(type);

  useEffect(() => {
    if (closeModalState) {
      if (readyFiles.length === 0 && entryList.length === 0) {
        close();
        setCloseModalState(false);
      }
    }
  }, [closeModalState, readyFiles, entryList, close])


  /**
   * Handles the completion of a search operation by updating the entry state and search status.
   *
   * @param {Object} searchData - The data returned from the search operation.
   * @param {string} searchData.id - The ID of the search entry.
   * @param {string} [searchData.startDate] - The start date of the search entry.
   * @param {string} [searchData.endDate] - The end date of the search entry.
   * @param {boolean} isMemberPresent - Indicates if the member is present in the search results.
   */
  function handleSearchComplete(searchData, isMemberPresent) {
    const { id: entryId, startDate, endDate } = searchData;
    setEntry((oldEntry) => ({
      ...oldEntry,
      id: isMemberPresent ? entryId : searchValue,
      startDate: startDate ? dateFormat(startDate, DateFormat.DATE) : null,
      endDate: endDate ? dateFormat(endDate, DateFormat.DATE) : null,
      searchFound: isMemberPresent
    }));
    setHasSearched(true);
  }

  /**
   * Closes the dialog by setting its state to hide it and clearing its message and actions.
   */
  function closeDialog() {
    setDialog({ show: false, message: '', actions: [] })
  }


  /**
   * Handles the search error by displaying a dialog with an error message
   * and setting the search state to false.
   *
   * @function
   * @name handleSearchError
   */
  function handleSearchError() {
    setDialog({
      show: true,
      title: ModalOverlayMessage.BIGLIST_CHECK_ERROR,
      message: ModalOverlayMessage.BIGLIST_CHECK_MESSAGE(type),
      action: [{ label: "Close", onClick: closeDialog }]
    });
    setHasSearched(false);
  }

  /**
   * Handles the update error by displaying a dialog with an error message.
   * The dialog includes a title, a message, and an action button to close the dialog.
   */
  function handleUpdateOnError() {
    setDialog({
      show: true,
      title: ModalOverlayMessage.BIGLIST_EDIT_ERROR,
      message: ModalOverlayMessage.BIGLIST_EDIT_ERROR_MESSAGE(selectedFile),
      action: [{ label: "Close", onClick: closeDialog }]
    });
  }

  /**
   * Handles the completion of an update operation.
   *
   * @param {Object} [response={}] - The response object from the update operation.
   * @param {boolean} [response.error] - Indicates if there was an error in the response.
   * @param {Array} [response.errors] - An array of error messages, if any.
   */
  function handleUpdateOnComplete(response = {}) {
    if (!response.error && !response.errors) {
      setEntryList([]);
      setDialog({
        show: true,
        message: ModalOverlayMessage.EDIT_DONE,
        action: [{ label: "Close", onClick: closeDialog }]
      });
    } else {
      handleUpdateOnError()
    }
  }

  /**
   * Removes an entry from the list at the specified index.
   *
   * @param {number} index - The index of the entry to remove.
   * @returns {Function} A function that updates the entry list by removing the specified entry.
   */
  const removeListEntry = (index) => () => {
    setEntryList((oldEntryList) => {
      return oldEntryList.slice(0, index).concat(oldEntryList.slice(index + 1))
    })
  }


  /**
   * Handles the file selection event.
   *
   * @param {Event} event - The file input change event.
   */
  const handleFileSelect = (event) => {
    const target = event.target;
    if (target) {
      const selectedFiles = [...target.files];
      setFileName(selectedFiles);
      target.value = null;
    }
  }

  /**
   * Asynchronously searches for a value in the selected file.
   *
   * This function checks if the `searchValue` is present in the selected file
   * using the `checkBiglistValuesService`. If the value is found, it calls
   * `handleSearchComplete` with the found value and a success flag. If the value
   * is not found, it calls `handleSearchComplete` with an empty object and a
   * failure flag. In case of an error during the search, it calls
   * `handleSearchError`. Finally, it resets the `searchValue` to an empty string.
   *
   * @async
   * @function searchForValue
   * @returns {Promise<void>} A promise that resolves when the search is complete.
   */
  async function searchForValue() {

    try {
      if (searchValue) {
        const memberPresentList = await checkBiglistValuesService(selectedFile, [searchValue])

        if (memberPresentList && memberPresentList.length > 0) {
          handleSearchComplete(memberPresentList[0], true);
        } else {
          handleSearchComplete({}, false);
        }
      }
    } catch (err) {
      handleSearchError()
    } finally {
      setSearchValue('');
    }
  }

  /**
   * Handles the change event for the search input.
   * Updates the search value state and resets the entry state.
   *
   * @param {Event} event - The change event triggered by the search input.
   */
  function handleSearchChange(event) {
    const target = event.target;
    if (target) {
      const value = target.value;
      setSearchValue(value);
    }
    setEntry({ id: '', startDate: null, endDate: null, searchFound: false });
  }

  /**
   * Adds a new entry to the entry list if the entry has a valid ID and is not a duplicate.
   * If the entry ID is missing, sets an error indicating the ID is missing.
   * If the entry ID is a duplicate, sets an error indicating the ID is a duplicate.
   * If the entry is valid and not a duplicate, adds the entry to the list with its start date, end date, and action.
   * Resets the entry after adding it to the list.
   */
  function addListEntry() {
    if (entry.id) {
      if (entryList.filter((oldEntry) => oldEntry.id === entry.id).length > 0) {
        setEntryError(UploadListErrors.ID_DUPLICATE);
      } else {
        const entryId = entry.id;
        const startDate = entry.startDate ? new Date(entry.startDate) : undefined;
        const endDate = entry.endDate ? new Date(entry.endDate) : undefined;
        const entryAction = entry.searchFound ? 'remove' : 'update';

        setEntryList(entryList.concat([{ id: entryId, startDate, endDate, action: entryAction }]));
        resetEntry();
      }
    } else {
      setEntryError(UploadListErrors.ID_MISSING);
    }
  }


  /**
   * Resets the entry state to its initial values.
   *
   * This function performs the following actions:
   * - Sets the entry state to an object with default values:
   *   - id: an empty string
   *   - startDate: null
   *   - endDate: null
   *   - searchFound: false
   * - Sets the hasSearched state to false.
   * - Clears the search value.
   * - Resets any entry error to null.
   */
  function resetEntry() {
    setEntry({
      id: '',
      startDate: null,
      endDate: null,
      searchFound: false
    });
    setHasSearched(false);
    setSearchValue('');
    setEntryError(null);
  }


  /**
   * Asynchronously uploads files by updating the selected member file with entries from other selected member files
   * and then uploading it to the cloud.
   *
   * @async
   * @function uploadFiles
   * @returns {Promise<void>} A promise that resolves when the files have been uploaded and the modal is closed.
   */
  async function uploadFiles() {
    /** Update the member file selected for edit with the entries from other selected member files.
     * and upload it to the cloud.
    */
    for (const readyFile of readyFiles) {
      const fileObj = { ...readyFile }
      fileObj.name = selectedFile + '.csv';
      fileObj.type = type;
      await updateFile(fileObj);
      uploadFile(fileObj, baseFolder);
    }
    closeModal();
  }


  /**
   * Submits the list by updating or removing entries based on the type of list.
   *
   * @async
   * @function submitList
   * @returns {Promise<void>}
   *
   * @description
   * This function handles the submission of a list by updating or removing entries. It differentiates between member and non-member type lists and processes the entries accordingly. For non-member type lists, it retrieves data from the editComponent and processes the entries to be added or removed. For member type lists, it filters the entries based on their action and formats the dates. The function then uploads the files and updates the list as necessary.
   *
   * @example
   * // Example usage:
   * submitList().then(() => {
   *   console.log('List submitted successfully');
   * }).catch((error) => {
   *   console.error('Error submitting list:', error);
   * });
   */
  async function submitList() {
    /** itemsUpdate @type {array} - contains object of all entries, that should be added into the list. */
    /** itemsRemove @type {array} - contains object of all entries, that should be removed from the list. */
    let itemsUpdate = [], itemsRemove = [];
    if (typeCheck) {
      /** update for non member type lists.
       * editComponent is a ref to searchContainer data.
      */

      if (editComponent && editComponent.current && editComponent.current.getData) {
        /**
         * args is an object with the entries needed to added or removed
         * @example <caption>args sample object</caption>
         * args = [
         *  target: {
         *    value: {entry1: "add", entry2: "remove"},
         *    fullValue: [{ //object of entries needed to add.
         *      endDate: undefined
                endTime: undefined
                id: "entry1"
                startDate: undefined
                startTime: undefined
                timezone: undefined
              }]
         *  },
            "editList",
            false
         * ]
         */
        const args = await editComponent.current.getData({ type: 'update' })
        if (args && !args.error) {
          const [event, listType = TemplateMixTypes.BIGLIST, replace] = args;
          if (event && event.target) {
            const { value: eventValue, fullValue } = event.target;
            let targetValue;
            const prevValue = {};
            if (listType === 'editList') {
              targetValue = { ...prevValue, [listType]: eventValue, replace: prevValue.replace || replace };
            } else {
              targetValue = { [listType]: eventValue }
            }
            /**
             * @example targetValue.editList: {entry1: "add", entry2: "remove"}
             * @type {array} addList - array of entries need to be added
             * @type {array} removeList - array of entries need to be removed
             */
            const { addList, removeList } = getBiglistEditValues(targetValue.editList);
            if (addList && addList.length > 0) {
              itemsUpdate = addList.map((item) => {
                const itemValues = fullValue.find(val => val.id === item);
                const newItemValues = Object.keys(itemValues).reduce((acc, val) => {
                  return {
                    ...acc,
                    [val]: itemValues[val] || ''
                  }
                }, {});
                return {
                  ...newItemValues,
                  id: item
                };
              })
            }
            if (removeList && removeList.length > 0) {
              itemsRemove = removeList.map((item) => ({
                id: item
              }))
            }
          }
        }
      }

    } else {

      /** update for member type list. */

      itemsUpdate = entryList.filter((oneEntry) => oneEntry.action === 'update').map((item) => {
        return {
          id: item.id,
          startDate: item.startDate ? +dateFormat(item.startDate, 'x') : null,
          endDate: item.endDate ? +dateFormat(item.endDate, 'x') : null
        }
      });

      itemsRemove = entryList.filter((oneEntry) => oneEntry.action === 'remove').map((item) => {
        return {
          id: item.id,
          startDate: item.startDate ? +dateFormat(item.startDate, 'x') : null,
          endDate: item.endDate ? +dateFormat(item.endDate, 'x') : null
        }
      });
      if (itemsUpdate.length <= 0 && itemsRemove.length <= 0) {
        uploadFiles().then(handleUpdateOnComplete)
      } else {
        uploadFiles()
      }
    }

    const fileObjs = [];
    let addList = [], removeList = [];
    if (itemsUpdate && itemsUpdate.length > BIGLIST_EDIT_LIMIT) {
      fileObjs.push(createEditBiglist(itemsUpdate, BlobActions.APPEND));
    } else {
      addList = itemsUpdate;
    }
    if (itemsRemove && itemsRemove.length > BIGLIST_EDIT_LIMIT) {
      fileObjs.push(createEditBiglist(itemsRemove, BlobActions.DELETE));
    } else {
      removeList = itemsRemove;
    }

    const promises = [];
    const biglistName = selectedFile;
    if (addList.length > 0 && addList.length <= BIGLIST_EDIT_LIMIT) {
      promises.push(updateBigList(biglistName, addList));
    }
    if (removeList.length > 0 && removeList.length <= BIGLIST_EDIT_LIMIT) {
      promises.push(removeBigList(biglistName, removeList, 'remove'));
    }

    if (fileObjs.length > 0) {
      promises.push(uploadFileObjs(fileObjs, baseFolder));
    }
    const responses = await Promise.all(promises);
    const response = { error: responses.reduce((a, resp) => a || (resp ? resp.error : undefined), null) };
    handleUpdateOnComplete(response);
  }


  /**
   * Creates an object representing an edited big list.
   *
   * @param {Array} list - The list of items to be converted to CSV.
   * @param {string} csvAction - The action to be performed on the CSV.
   * @returns {Object} An object containing details about the edited big list.
   * @property {File} file - The generated CSV file.
   * @property {string} name - The name of the CSV file.
   * @property {string} type - The type of the list, converted to lowercase.
   * @property {string} action - The action to be performed on the CSV.
   * @property {string} status - The status of the big list, set to 'READY'.
   * @property {number} total - The size of the CSV file.
   * @property {number} time - The time taken to process the list, initialized to 0.
   * @property {number} loaded - The amount of data loaded, initialized to 0.
   * @property {number} lastLoaded - The last amount of data loaded, initialized to 0.
   * @property {number} lastSpeed - The last speed of data processing, initialized to 0.
   * @property {number} speed - The current speed of data processing, initialized to 0.
   * @property {boolean} lockedType - Indicates if the type is locked, set to true.
   * @property {boolean} silent - Indicates if the process should be silent, set to true.
   */
  function createEditBiglist(list, csvAction) {
    const biglistName = selectedFile + '.csv';
    const csv = arrayToCSV(list, biglistName, type)
    return {
      file: csv,
      name: biglistName,
      type: type.toLowerCase(),
      action: csvAction,
      status: Status.READY,
      total: csv.size,
      time: 0,
      loaded: 0,
      lastLoaded: 0,
      lastSpeed: 0,
      speed: 0,
      lockedType: true,
      silent: true
    }
  }

  /**
   * Updates the action of the first file object in the array and adds the updated file list.
   *
   * @param {Array} fileObj - An array of file objects.
   * @param {string} csvAction - The action to be assigned to the first file object.
   */
  function changeAction(fileObj, csvAction) {
    fileObj[0].action = csvAction
    const newFileObj = [...fileObj]
    addFileList(newFileObj);
    setFileName('');
  }

  /**
   * Closes the modal and stops data fetching.
   *
   * This function sets the state to close the modal and stops any ongoing data fetching.
   */
  function closeModal() {
    setCloseModalState(true);
    setDataFetching(false);
  }

  /**
   * Renders a list of member entries as table rows.
   *
   * This function maps over the `entryList` array and returns a `TableRow` for each member entry.
   * Each row contains a description of the action performed on the member and a button to remove the entry.
   *
   * @returns {JSX.Element[]} An array of `TableRow` elements representing the member entries.
   */
  function renderSingleMemberEntries() {
    return (
      entryList.map((singleMemberEntry, index) => {
        const text = singleMemberEntry.action === 'remove' ? 'removed from the' : 'added to the';
        return (
          <TableRow key={singleMemberEntry.id}>
            <TableCell className={classes.entryCell}><Typography>{`Member ${singleMemberEntry.id} will be ${text} target member list`}</Typography></TableCell>
            <TableCell className={classes.entryCell} align='right' size='small'>
              <IconButton data-testid='remove' onClick={removeListEntry(index)}>
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        )
      })
    )
  }


  /**
   * Renders a container for adding or removing a batch of members using a file.
   *
   * This component includes:
   * - A heading that describes the purpose of the component.
   * - A button to select a CSV or TXT file from the user's computer.
   * - Additional actions related to the selected member file.
   *
   * @returns {JSX.Element} The rendered container component.
   */
  function listContainer() {
    return (
      <Paper className={classes.paper} elevation={3}>
        <div className={classes.listContainer}>
          <TitleText className={classes.editHeading}>Add or remove a batch of members using a file that lists them.</TitleText>
          <div className={classes.flexed} style={{ flexDirection: 'column', paddingLeft: '16px' }}>
            <div className={classes.importButtonContainer}>
              <TitleText className={classes.editText}>Choose a CSV or TXT file on your computer</TitleText>
              <SelectFileButton fileId='biglist-edit-raised-button-file' onChange={handleFileSelect} multiple={false} className={classes.importButton} accept='.csv,.txt'>
                <CustomButton color='secondary' variant="contained" label='Browse Files' component='div' />
              </SelectFileButton>

            </div>
            {memberFileActions()}
          </div>
        </div>
      </Paper>
    )
  }


  /**
   * Renders a list of entries that are queued to be updated.
   * Displays a message if there are no entries to update.
   *
   * @returns {JSX.Element} A JSX element containing the list of entries to be updated.
   */
  function renderEntriesToUpdate() {
    return (
      <div className={classes.entries}>
        <div className={classes.entriesHeader}>
          <p>List of changes to be made</p>
          <p className={classes.smallFont}>
            You can queue as many changes as you want in this list using controls above.They will be applied together when you click Begin Changes Now.
          </p>
        </div>
        {(readyFiles && readyFiles.length > 0) || (entryList && entryList.length > 0) ? (
          <div className={classes.entryList}>
            <Table className={classes.table}>
              <TableBody>
                {entryList && entryList.length > 0 ? renderSingleMemberEntries() : null}
                {readyFiles.map((fileObj) => {
                  return (
                    <TableRow key={fileObj.name}>
                      <TableCell className={classes.entryCell}>
                        <Tooltip title={fileObj.name} arrow>
                          <Typography className={classes.fileNameTable}>
                            {`All members in file ${fileObj.name} will be ${fileObj.action === 'delete' ? 'removed from list' : 'added to list'}`}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.entryCell} align='right'>
                        <IconButton onClick={() => removeFile(fileObj)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </div>
    )
  }


  /**
   * Renders the member file actions section.
   *
   * This section includes instructions for the file format and buttons to add or remove members
   * based on the provided file. The file should have one member number per line, without a header line.
   *
   * @returns {JSX.Element} The JSX element representing the member file actions section.
   */
  function memberFileActions() {
    return (
      <div style={{ marginTop: '16px' }}>
        <p style={{
          fontSize: '14px',
          fontWeight: 200
        }}
        >
          The file should have one member number per line, without a header line.You can make them in notepad or by exporting to CSV or TXT from Excel.
        </p>
        {fileName && fileName.length > 0 ? (
          <>
            <CustomButton
              className={classes.actionSelectButtons}
              variant="contained"
              label='Add Everyone in this file'
              color='secondary'
              onClick={() => changeAction(fileName, BlobActions.APPEND)}
            />
            <CustomButton
              className={classes.actionSelectButtons}

              color="secondary"
              label='Remove Everyone in this file'
              onClick={() => changeAction(fileName, BlobActions.DELETE)}
            />
          </>
        ) : null
        }
      </div>
    )
  }


  /**
   * Renders a search container with a CsvInput component.
   *
   * @returns {JSX.Element} The rendered search container component.
   */
  function searchContainer() {
    return (
      <div className={classes.searchTableContainer}>
        <CsvInput
          margin="normal"
          ref={editComponent}
          fullWidth
          name={name}
          value={null}
          searchType={searchType}
          limit={0}
          enableSearch
          enableBiglist
          parentMixType='biglist'
          biglistContinue={selectedFile}
          noModal
          queries={{ dateEnabled }}
          handleFlag={setDataFetching}
        />
      </div>
    )

  }


  /**
   * Renders a container with action buttons for the BigListEdit component.
   *
   * @returns {JSX.Element} The button container with Cancel and Update selected buttons.
   *
   * @remarks
   * - The Cancel button triggers the `close` function.
   * - The Update selected button is conditionally disabled based on various conditions:
   *   - `typeCheck` must be true.
   *   - `entryList` must not be empty.
   *   - `readyFiles` must not be empty.
   *   - `allActions` must be false.
   *   - `readyFiles` must not contain any file objects without an action.
   *   - `loadingUpdate` and `loadingRemove` must be false.
   *   - `isDataFetching` must be true.
   *
   * @example
   * // Usage in a React component
   * return (
   *   <div>
   *     {buttonContainer()}
   *   </div>
   * );
   */
  function buttonContainer() {
    const allActions = Array.isArray(entryList) && entryList.filter(oneEntry => !oneEntry.action).length > 0
    return (
      <div className={classes.buttonContainer} style={{ maxWidth: '100%', padding: 16, boxSizing: 'border-box' }}>
        <div container className={classes.gridContainer} style={{ flexDirection: 'row' }}>
          <div className={classes.gridItem} style={{ width: 'auto' }}><CustomButton label='Cancel' style={{ minWidth: 128 }} onClick={close} color='secondary' /></div>
          <div className={clsx(classes.submitButtonContainer, classes.gridItem)} style={{ width: 'auto' }}>
            <CustomButton
              disabled={!(typeCheck) &&
                (!entryList || entryList.length === 0) &&
                (!readyFiles || readyFiles.length === 0) ||
                allActions || (readyFiles && readyFiles.filter((fileObj) => !fileObj.action).length > 0) ||
                loadingUpdate || loadingRemove || !isDataFetching}
              variant='contained'
              style={{ minWidth: 128, whiteSpace: 'nowrap' }}
              label='Update selected'
              onClick={submitList}
              color='secondary'
            />
          </div>
        </div>
      </div>
    )
  }


  /**
   * Renders the edit member buttons component.
   *
   * This component includes a "Cancel" button and a "Begin Changes Now" button.
   * The "Begin Changes Now" button is disabled if there are no entries in the entryList or readyFiles,
   * or if loadingUpdate or loadingRemove is true.
   *
   * @returns {JSX.Element} The rendered component.
   */
  function editMemberButtons() {
    return (
      <Paper className={classes.bottom}>
        <div className={classes.flexed} style={{ marginTop: '8px' }}>
          <CustomButton label='Cancel' onClick={close} color='secondary' style={{ marginRight: '30%' }} data-testid="cancel-edit-button" />
          <div className={`${classes.editBtnContainer} ${classes.flexed}`}>
            <p className={classes.smallFont} style={{ marginRight: '16px' }}>
              Click `Begin Changes Now!` once you have all the changes you want to make in the List of Changes above.
              Changes are made in background and may take several minutes.You can check on the status in Admin/Tasks.
            </p>
            <CustomButton
              disabled={(!entryList || entryList.length === 0) &&
                (!readyFiles || readyFiles.length === 0) || loadingUpdate || loadingRemove}
              variant='contained'
              label='Begin Changes Now'
              onClick={submitList}
              color='secondary'
              data-testid="begin-changes-button"
            />
          </div>

        </div>
      </Paper>
    )
  }


  /**
   * Renders the search result component.
   *
   * This component displays a message indicating whether a member is already in the list or not,
   * and provides buttons to either add/remove the member from the list or cancel the action.
   *
   * @returns {JSX.Element} The rendered search result component.
   */
  function renderSearchResult() {

    const primaryLabel = entry.searchFound ? 'Remove' : 'Add';
    const titleText = entry.searchFound ? `Member ${entry.id} is already in the list` : `Member ${entry.id} was not found in the list`;
    return (
      <div className={classes.searchResults}>
        <TitleText className={classes.editText}>{titleText}</TitleText>
        {/**
         * CTA to move the search result into List of Updates view(entryList).
         */}
        <CustomButton
          color='secondary'
          label={primaryLabel}
          iconSize='medium'
          variant='contained'
          onClick={addListEntry}
          className={clsx(
            { [classes.buttonHidden]: !entry.id }, { [classes.leftMargin]: true })}
          data-testid={entry.searchFound ? 'remove-button' : 'add-button'}
        />
        {/**
         * CTA to cancel, reset the entry state.
         */}
        <CustomButton
          color="secondary"
          label='Cancel'
          onClick={resetEntry}
          className={clsx(
            { [classes.buttonHidden]: !entry.id })}
          data-testid="cancel-button"
        />
      </div>
    )
  }


  /**
   * Renders the search component for adding or removing a single member.
   *
   * @returns {JSX.Element} The rendered search component.
   */
  function renderSearch() {
    const mobileView = isMobile && isPortrait;
    return (
      <Paper className={classes.paper} elevation={3}>
        <div className={classes.searchContainer}>
          <TitleText className={classes.editHeading}>Add or remove a single member</TitleText>
          <div className={mobileView ? classes.entryMobile : classes.entry}>
            <p>Enter member number to add or remove</p>
            <SearchBar data-testid='search' className={classes.searchbar} onChangeSearch={handleSearchChange} value={searchValue} onClickSearch={searchForValue} />
          </div>
          {hasSearched ? renderSearchResult() : null}
        </div>
      </Paper>
    )
  }


  /**
   * Renders the member edit information text.
   *
   * @returns {JSX.Element} A JSX element containing the instructions for editing the list.
   */
  function renderMemberEditInfo() {
    return (
      <TitleText className={classes.mainText}>
        {
          `First create a list of chages you want to make.
        When you have the full list of changes, click
        "Begin Changes Now! to apply them.
        You have 4 ways to make changes to the list.
        You can use any methods repeatedly to queue up your list of changes.`
        }
      </TitleText>
    )
  }

  /**
   * Renders the appropriate container based on the type check.
   *
   * If `typeCheck` is true, it renders the search container for other types of lists.
   * If `typeCheck` is false, it renders the member edit container for member lists.
   *
   * @returns {JSX.Element} The rendered container element.
   */
  function renderContainer() {
    if (typeCheck) {
      /**Edit for other type of lists. */
      return searchContainer();
    } else {
      /** Edit for member list */
      return (
        <div className={classes.editMemberRoot}>
          {memberEditContainer()}
        </div>
      )
    }
  }

  /**
   * Renders the member edit container.
   *
   * This function returns a JSX fragment that includes:
   * - Member edit information.
   * - A search container with a search component, a divider, and a list container.
   * - A bottom border.
   * - A container for member entries to update.
   *
   * @returns {JSX.Element} The member edit container.
   */
  function memberEditContainer() {
    return (
      <>
        {renderMemberEditInfo()}
        <div className={classes.memberSearchContainer}>
          {renderSearch()}
          <div style={{ alignSelf: 'center' }}>OR</div>
          {listContainer()}
        </div>
        <div className={classes.borderBtm} />
        <div className={classes.memberEntriesContainer}>
          {renderEntriesToUpdate()}
        </div>
      </>
    )
  }

  const isMemberEdit = type === BlobTypes.MEMBER && selectedFile;

  return (

    <div
      className={!isMemberEdit ? classes.root : ''}
      style={typeCheck ? {
        padding: 0,
        margin: 0,
        maxWidth: '100%'
      } : null}
    >
      {renderContainer()}
      {isMemberEdit ? editMemberButtons() : buttonContainer()}
      <ConfirmationDialog
        open={dialog.show}
        title={dialog.title}
        text={dialog.message}
        actions={dialog.action}
      />
    </div>
  )
}

export default React.memo(BiglistEdit);
