import { makeStyles } from '@material-ui/core'
import Colors from '../../theme/Colors';
import { fontSizeReg, fontMedium } from '../../theme/theme';

const styles = {
  root: {
    display: 'flex',
    // flexWrap: 'wrap',
    flexDirection: 'column',
    color: Colors.grey300,
    width: '100%',
    wordBreak: 'break-word'
  },
  chip: {
    margin: 2
  },
  label: {
    minHeight: 14,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(36, 37, 61, 0.5)',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 16,
    paddingLeft: 8
  },
  value: {
    minHeight: 19,
    fontFamily: 'Roboto',
    fontSize: fontSizeReg,
    fontWeight: fontMedium,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#424242',
    paddingTop: 0,
    paddingRight: 16,
    paddingLeft: 8,
    whiteSpace: 'pre-wrap'
  },
  valueError: {
    color: Colors.red
  },
  moreButton: {
    marginTop: -16,
    marginBottom: -16,
    padding: 2,
    marginLeft: 8
  },
  moreIcon: {
    marginTop: -12,
    marginBottom: -12,
    paddingRight: 4
  },
  moreLabel: {
    textTransform: 'none',
    fontSize: 12
  },
  moreText: {
    marginTop: 1,
    marginRight: 4
  },
  history: {
    textDecoration: 'line-through',
    color: Colors.red
  },
  noChange: {
    fontSize: '12px',
    fontWeight: 200
  },
  listContainer: {
    flex: 1,
    overflow: 'hidden'
  },
  modalActions: {
    padding: 16,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  modalBody: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  historyTableStyles: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: "repeat(auto-fit, minmax(16%, 1fr))"
  },
  biglistName: {
    fontWeight: 'bold'
  },
  editList: {
    padding: 0,
    fontSize: 13
  }
};

export default makeStyles(styles);
