export default Object.freeze(Object.seal({
  SEARCH: 'search',
  FORM: 'form',
  BLOBSEARCH: 'blob_search',
  UPLOAD: 'upload',
  CARD: 'card',
  APPROVE: 'approve',
  COUPON: 'coupon',
  CAMPAIGN: 'campaign',
  PROMOTION: 'promotion',
  OFFERSET: 'offerset',
  BLOB: 'blob',
  BRANDING: 'branding',
  COUPONCODE: 'COUPONCODE',
  COUPONGROUP: 'couponGroup',
  COUPONID:"couponId",
  CARDLIST: 'cardlist',
  ADMIN: 'admin',
  ROLE: 'role',
  USERS: 'users',
  DOWNLOAD: 'download',
  ITEMS: 'item',
  VIDEO: 'video',
  ASSIGNEDCOUPON:"ASSIGNED COUPON",
  COUPONGROUPS:"COUPON GROUPS",
  TERMSANDCONDITIONS: 'termsAndConditions',
  MRN: 'mrn',
  RESELLERS: 'reseller',
  TARGETED_MESSAGING: 'targeted_messaging',
  TARGETED_MESSAGING_BY_SLOT: 'targeted_messaging_by_slot',
  GTMSLOT: 'gtmslot',
  BIGLIST: 'biglist',
  UPLOAD_BIGLIST: 'upload_biglist',
  CREATE_BIGLIST: 'create_biglist',
}));
