import gql from "graphql-tag";

const FILTER_COUPONS = gql`
query getcoupons($couponId: String!) {
     coupon(couponId : $couponId ) @rest(type: "coupon", path: "coupon/couponcode/{args.couponId}") {
          payload
    }
}`

export default FILTER_COUPONS

export const UPDATE_COUPONS = gql`
query($couponId: String!) {
  approvalResend(couponId: $couponId, input: $input) @rest(type: "coupon", path: "coupon/couponcode/notify/{args.couponId}", method: "PUT") {
    status,
    header,
    errors,
    payload
  }
}`;


export const CREATE_COUPON_GROUP = gql`
mutation createCoupon {
  createCoupon(input: $input)
    @rest(
      type: "coupon"
      path: "coupon/coupongroup"
      method: "POST"
    ) {
    status
    errors
    payload
  }
}`;


export const GET_COUPON_CODE = gql`
mutation getcoupons($couponId: String!) {
  coupon(couponId: $couponId, input: $input) @rest(type: "coupon", path: "coupon/searchSuc/{args.couponId}") {
    status,
    header,
    errors,
    payload
  }
}`;






