import React, { useEffect } from "react";
// import { connect } from 'react-redux';
import { withStyles, Fab } from "@material-ui/core";
import { Add, Delete } from '@material-ui/icons';
import isEqual from 'lodash/isEqual';
import TemplateCell from "./TemplateCell";
import styles from "./TemplateList.styles";
import {
  doFunction,
  templateFuncCheck,
  doChangeFunction,
  evaluateValue
} from '../../Utils/template/templateFieldFunction';
import { TemplateGroupType, FieldTypes } from '../../constants/Constants';
import { reducerActions as templateActions } from '../../compartments/templates/templateReducer';
import getDeepValue from '../../Utils/getDeepValue';
import { TemplateErrors } from '../../constants/Errors';
import HistoryText from '../text/HistoryText';
import parseRepeateableName from '../../Utils/template/parseRepeatableName'
import parseRepeateableRequired from '../../Utils/template/parseRepeateableRequired'
import TemplateButton from './inputs/TemplateButton';
import RepeatableHeader from "../repeatable/RepeatableHeader";


const TemplateList = props => {
  const {
    classes,
    groupData,
    device,
    comments,
    dispatch,
    mode,
    viewMode,
    showErrors,
    fullData,
    history,
    setFormChanged,
    isContinue,
    dataState,
    setTemplateModalData,
    workflowItem,
    parentTemplateData
  } = props;

  useEffect(() => {
    const { fields } = groupData;
    if (fields) {
      for (const fieldKey in fields) {
        const field = fields[fieldKey];
        if (field.functionProps) {
          for (let i = 0; i < field.functionProps.length; i++) {
            const functionProps = field.functionProps[i];
            const propKey = functionProps.fieldKey;
            if (propKey && templateFuncCheck.checkSet(propKey)) {
              let functionOldValue = undefined;
              const { key, value: functionValue } = doFunction({ field, group: groupData, fullData, functionProps });
              if (history) {
                const result = doFunction({ field, group: groupData, fullData, functionProps }, 'oldValue');
                functionOldValue = result.value;
              }
              const functionParentProps = doFunction({ field, group: groupData, fullData, functionProps }, 'functionProps');
              const functionParentPropsKey = getDeepValue(functionParentProps, ['value', i, 'fieldKey']);
              const isFunctionParentSetInit = templateFuncCheck.checkSetOnInit(functionParentPropsKey);
              const { fromFunc, serverValue } = field;
              const isNewValue = (serverValue !== undefined && serverValue !== null ? !isFunctionParentSetInit : true) && !isEqual(functionValue, fromFunc && fromFunc[i]);

              if (isNewValue) {
                const functionSet = []
                functionSet[i] = true;
                dispatch({
                  type: templateActions.CHANGE_FIELD_DATA, data: {
                    group: groupData,
                    field: { ...field, hide: propKey === 'hide' ? functionValue : field.hide },
                    value: functionValue,
                    oldValue: functionOldValue,
                    history: history,
                    functionSet: functionSet,
                    parentTemplateData,
                    key
                  }
                });
              }
            } else if(propKey && templateFuncCheck.checkSetOthers(propKey)) {

              const { fromFunc, value } = field;
              const isNewValue = !isEqual(value, fromFunc && fromFunc[i]);

              if (fromFunc && fromFunc[i] && isNewValue) {
                const functionSet = []
                functionSet[i] = true;
                dispatch({
                  type: templateActions.CHANGE_FIELD_DATA, data: {
                    group: groupData,
                    field: field,
                    value,
                    parentTemplateData,
                    functionSet: functionSet
                  }
                });
              }
            }
          }
        }
      }
    }
  }, [groupData, dispatch, fullData, history]);


  const onChange = ({ group, field }) => async event => {
    const { value: eventValue, error, otherParams } = event.target;
    const { functionProps } = field;

    let value = eventValue;
    let otherError;

    if (functionProps) {
      for (let i = 0; i < functionProps.length; i++) {
        const functionPropsValue = functionProps[i];

        if (functionPropsValue.fieldKey && templateFuncCheck.checkChange(functionPropsValue.fieldKey)) {
          const { value: functionValue } = doChangeFunction({ field: { ...field, value, ...(otherParams || {}) }, group, fullData, functionProps: functionPropsValue });
          value = await functionValue;
          otherError = getDeepValue(value, ['errors', 0, 'description']);
          if (otherError) {
            value = '';
          }
        }
      }
    }
    dispatch({ type: templateActions.CHANGE_FIELD_DATA, data: { group, field, value,parentTemplateData, error: error || otherError, ...(otherParams || {}) } });

    setFormChanged(true);
  }

  const setOthers = ({ field, value }) => {
    dispatch({
      type: templateActions.SET_OTHERS_ONLY, data: {
        group: groupData,
        field: field,
        value
      }
    });
  }

  const onClick = ({ group, field }) => async event => {
    dispatch({ type: templateActions.CALL_CHANGE_FUNCTION, data: { group, field } });
  }

  const addRepeatable = () => {
    dispatch({ type: templateActions.ADD_REPEATABLE, data: { group: groupData } });
  }

  const removeRepeatable = () => {
    dispatch({ type: templateActions.REMOVE_REPEATABLE, data: { group: groupData } });
  }

  /* Dividing Grid */
  const divStyleGrid = cells => {
    if (device && device.toLowerCase() === "mobile") {
      return {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
        gridGap: '8px 16px'
      };
    }

    let cellValue = 2;
    if (cells) {
      cellValue = cells;
    }

    cellValue = `repeat(${cellValue}, 1fr)`;
    return {
      display: "grid",
      gridTemplateColumns: cellValue,
      gridGap: '8px 16px',
      // alignItems: 'center'
      alignItems: 'flex-start'
    };
  };

  /* Dividing input and fit */
  const divStyleCells = (cells, totalCells) => {

    if (cells && typeof cells === "string") {
      const cellValue = cells.split("-");

      let totalCellsValue = 2;
      if (totalCells) {
        totalCellsValue = totalCells;
      }

      const column1 = parseInt(cellValue[0]);
      const column2 = parseInt(cellValue.pop());

      const rowValue = (column1 === totalCellsValue) ? 1 : Math.floor(column1 / totalCellsValue) + (column1 % totalCellsValue === 0 ? 0 : 1);
      const firstCell = (column1 % totalCellsValue === 0
        ? totalCellsValue
        : column1 % totalCellsValue);
      const secondCell =
        (column2 % totalCellsValue === 0
          ? totalCellsValue
          : column2 % totalCellsValue) + 1;

      return {
        gridColumn: ` ${firstCell} / ${secondCell}`,
        gridRow: `${rowValue} / ${rowValue + 1}`
      };
    }
  };

  const renderRepeatableAction = (groupData, fullData) => {
    if (groupData.metadata.repeatableCondition && mode !== 'viewOnly' && !groupData.metadata.limit) {
      const groupName = groupData.metadata.name;
      const first = groupData.groupIndex < 1;
      const lastIndex = fullData[`${groupName}`].fields.length - 1;
      const last = groupData.groupIndex === lastIndex;
      const reverseAdd = groupData.metadata.groupConditions && groupData.metadata.groupConditions.reverseAdd ? evaluateValue(groupData.metadata.groupConditions.reverseAdd) : false;

      const action = first ? addRepeatable : removeRepeatable;
      const components = [];
      const fields = [];

      if(groupData.metadata.repeatableLabel) {
        if (reverseAdd){ // groupConditions includes reverseAdd (the +/Add attaches only to the last grouping item)
          if (first){
            fields.push({
              longDesc: last ? groupData.metadata.repeatableLabel[0] : groupData.metadata.repeatableLabel[1],
              mode: mode,
              onChange: last ? addRepeatable : removeRepeatable
            });
            components.push(<Add />);
          } else if (last){
            fields.push({
              longDesc: groupData.metadata.repeatableLabel[0],
              mode: mode,
              onChange: addRepeatable
            },{
              longDesc: groupData.metadata.repeatableLabel[1],
              mode: mode,
              onChange: removeRepeatable
            });
            components.push(<Add />) && components.push(<Delete />);
          } else {
            components.push(<Delete />);
            fields.push({
              longDesc: groupData.metadata.repeatableLabel[1],
              mode: mode,
              onChange: removeRepeatable
            });
            components.push(<Delete />);
          }
        } else { // the +/Add attaches only to the first grouping item
          first ? components.push(<Add />) : components.push(<Delete />);
          fields.push({
            longDesc: first ? groupData.metadata.repeatableLabel[0] : groupData.metadata.repeatableLabel[1],
            mode: mode,
            onChange: first ? addRepeatable : removeRepeatable
          });
        }
      }

      return (
        <div className={groupData.metadata.repeatableLabel ? groupData.metadata.repeatableLabel.indexOf("+OR")!== -1 ? classes.actionsContainerWithLeftAlignLabel : classes.actionsContainerWithLabel : classes.actionsContainerWithLabel}>
          {
            groupData.metadata.repeatableLabel ? (first || !last || !reverseAdd) ?
              <TemplateButton field={fields[0]} /> : (
                <div>
                  <div>
                    <TemplateButton field={fields[0]} />
                    <TemplateButton field={fields[1]} />
                  </div>
                </div>
              )
              : (
                <Fab className={classes.fab} color='secondary' onClick={action} size='small'>
                  {components}
                </Fab>
              )
          }
        </div>
      );
    }
    return null;
  }

  const renderRepeatableHeader = groupData => {
    return <RepeatableHeader groupData={groupData} fullData={fullData} mode={mode} dispatch={dispatch} />
  }

  return (
    <div className={!history ? classes.fieldsWrapper : ''} style={{display: groupData.metadata.repeatableLabel ? 'block' : 'flex'}}>
      {renderRepeatableHeader(groupData)}
      <div style={!history ? divStyleGrid(groupData.metadata.cells) : {}} className={!history ?classes.gridContainer:''}>
        {Object.keys(groupData.fields).map((fieldKey, index) => {
          const field = groupData.fields[fieldKey];
          const { metadata } = field;
          if (!metadata || field.hide ) {
            return null;
          }
          if (metadata.parentField) {
            const [parentGroupKey, parentFieldKey] = metadata.parentField.field.split('.');
            const parentValue = getDeepValue(fullData, [
              parentGroupKey,
              'fields',
              ...(isNaN(groupData.groupIndex) ? [] : [groupData.groupIndex]),
              parentFieldKey,
              'value'
            ]);
            const parentFieldValueObj = getDeepValue(metadata, ['parentField', 'values']);
            const parentFieldValue = parentFieldValueObj ? Object.keys(parentFieldValueObj)[0] : null;
            if (parentFieldValue ? parentFieldValue !== parentValue : !parentValue) {
              return null;
            }
          }

          const fieldData = {
            ...metadata,
            ...field,
            error: showErrors ? field.error : '',
            mode,
            readOnly:viewMode? viewMode:field.readOnly,
            onChange: FieldTypes.BUTTON === metadata.type ? onClick({ group: groupData, field }) : onChange({ group: groupData, field }),
            groupName: groupData.metadata.name,
            isContinue,
            shortDesc: groupData.metadata.repeatableCondition ? parseRepeateableName(groupData.groupIndex, metadata.shortDesc) : metadata.shortDesc,
            longDesc: groupData.metadata.repeatableCondition ? parseRepeateableName(groupData.groupIndex, metadata.longDesc) : metadata.longDesc,
            required: groupData.metadata.limit > 1 ?
              parseRepeateableRequired(metadata.queries && metadata.queries.requiredCondition, metadata.required, groupData.groupIndex) :
              metadata.required
          };

          const fieldType = metadata.type && metadata.type.toLowerCase()

          const noGrid = true;
          // hide if group is hidden
          const hideGroup = getDeepValue(groupData, ['metadata', 'hide'])
          const cellStyle = divStyleCells(metadata.sequence, groupData.metadata.cells);
          const historyComments = groupData.metadata.type === TemplateGroupType.HISTORY && comments.length ? comments : null;
          if (!history) {
            return (
              <TemplateCell
                key={fieldKey + '' + index + groupData.groupIndex}
                field={fieldData}
                noGrid={noGrid}
                cellStyle={{
                  ...cellStyle,
                  display: hideGroup ? 'none' : undefined
                }}
                comments={historyComments}
                fieldType={fieldType}
                classes={classes}
                setOthers={setOthers}
                dispatch={dispatch}
                group={groupData}
                dataState={dataState}
                setTemplateModalData={setTemplateModalData}
                workflowItem={workflowItem}
              />
            );
          } else {
            return <HistoryText field={fieldData} history={history} />;
          }
        })}
      </div>
      {renderRepeatableAction(groupData, fullData)}
    </div>
  );
};

export default withStyles(styles)(TemplateList);
