import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';
import { fontLight, fontMedium, fontSizeMed } from '../../theme/theme';

const styles = {
  statsContainer: {
    display: 'flex',
    height: 20,
    alignItems: 'center',
    margin: 'auto'
  },
  divider: {
    height: 24,
    width: 2,
    marginLeft: 4,
    marginRight: 4,
    backgroundColor: Colors.grey900
  },
  statsValue: {
    color: Colors.blueGrey500,
    fontSize: fontSizeMed,
    fontWeight: fontMedium
  },
  statsLabel: {
    marginLeft: 4,
    color: Colors.grey900,
    fontSize: fontSizeMed,
    fontWeight: fontLight
  }
}

export default makeStyles(styles);
