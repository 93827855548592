import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { GET_ALL_BRANDING} from '../../queries/searchQueries';
import { FieldMap } from '../../constants/Constants';
import getCompartmentTypes from '../../Utils/compartments/getCompartmentTypes';
import CardSearch from '../search/CardSearch/CardSearch';

function BrandingSearch({ ...otherProps }, ref) {
  const [searchList, setSearchList] = useState([]);
  const childSearch = useRef(null);

  useImperativeHandle(ref, () => ({
    getSelected() {
      return childSearch.current.getSelected();
    }
  }));

  const [searchQuery, { loading, error }] = useMutation(GET_ALL_BRANDING, {
    onCompleted: handleOnComplete
  });

  useEffect(() => {
    searchQuery();
  }, [])

  function handleOnComplete(res) {
    if (res && res.searchNoIndex && res.searchNoIndex.payload) {
      const payload = res.searchNoIndex.payload;
      const dataList = payload.map((item) => ({ ...item, id: item.name })); //Get requests require name instead of id
      setSearchList(dataList)
    }
  }

  if (error) {
    return <div>error</div>;
  }

  return (
    <CardSearch
      ref={childSearch}
      searchList={searchList}
      searchType={getCompartmentTypes.BRANDING}
      fieldMap={FieldMap[getCompartmentTypes.BRANDING]}
      loading={loading}
      {...otherProps}
    />
  )
}

export default forwardRef(BrandingSearch);
