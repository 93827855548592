import { TemplateSpecialGroupType, FieldTypes, low, trueValues, TemplateGroupType } from '../../constants/Constants';
import { TemplateErrors } from '../../constants/Errors';
import getDeepValue from '../../Utils/getDeepValue';
import { templateFuncCheck, functions, parseDefaultValueCondition, handleTypeValue } from '../../Utils/template/templateFieldFunction';
import processServerValue from '../../Utils/template/check-template-input';
import isRequiredValueMissing from '../../Utils/template/isRequiredValueMissing';
import updateGroupRequiredMissing from '../../Utils/template/updateGroupRequiredMissing';
import { checkNonZeroArray } from '../../Utils/helpers';
import parseRepeateableRequired from '../../Utils/template/parseRepeateableRequired';

// ****** Action constants ******
const reducerActions = {
  SET_TEMPLATE_DATA: 'SET_TEMPLATE_DATA',
  CHANGE_FIELD_DATA: 'CHANGE_FIELD_DATA',
  ADD_REPEATABLE: 'ADD_REPEATABLE',
  REMOVE_REPEATABLE: 'REMOVE_REPEATABLE',
  CALL_CHANGE_FUNCTION: 'CALL_CHANGE_FUNCTION',
  SET_OTHERS_ONLY: 'SET_OTHERS_ONLY',
  SET_MODAL_DATA: 'SET_MODAL_DATA',
  REORDER_GROUP_INDEX: 'REORDER_GROUP_INDEX'
};

function init({ data, errors }) {
  // template hasn't loaded yet so do nothing
  return null;
}

export function getGroupData(state, { groupName }) {
  return state[groupName];
}

export function getFieldData(state, { groupName, fieldName, groupIndex = 0 }) {
  const groupData = getGroupData(state, { groupName });

  if (groupData && Array.isArray(groupData.fields)) {
    return getDeepValue(groupData, ['fields', groupIndex, fieldName]);
  } else {
    return getDeepValue(groupData, ['fields', fieldName]);
  }
}

/**
 *
 * @param {string} functionsString - function string from template metadata
 * @param {object} queries - queries map from template metadata
 * @param {object} serverVal - server value of field
 * @param {boolean} parentGroupSpecial - if parent or source group is also special
 * @returns object of function props.
 */
export function getFunctionValues(functionsString, queries, serverVal, parentGroupSpecial) {
  const preventFunctionIfTheresValue = getDeepValue(queries, ['preventFunctionIfTheresValue']);
  if (!functionsString || (preventFunctionIfTheresValue && serverVal)) {
    return null;
  }
  const functionsSplit = functionsString.split('~');
  return functionsSplit.map((functionString, index) => {
    const assignmentIndex = functionString.indexOf('=');
    const fieldKey = functionString.slice(0, assignmentIndex);
    const func = functionString.slice(assignmentIndex + 1).replace(')', '');
    const [funcName, params] = func.split('(');
    const paramsArr = params ? params.split(',').map(string => string.trim()) : [];
    return {
      fieldKey,
      index,
      funcName: funcName.replace('$', ''),
      params: paramsArr,
      getFromDifferentGroupType: /^\$/.test(funcName),
      parentGroupSpecial
    };
  })
}

function checkGroupError(group) {
  const groupFields = group.fields;
  if (TemplateSpecialGroupType.includes(group.metadata.type)) {
    for (const specialGroupField of groupFields) {
      for (const fieldKey in specialGroupField) {
        if (specialGroupField[fieldKey].error) {
          return true;
        }
      }
    }
  } else {
    for (const fieldKey in groupFields) {
      if (groupFields[fieldKey].error) {
        return true;
      }
    }
  }
  return false;
}

function getChildDefaultValue(childDefaultValues, field) {
  const childDefVals = JSON.parse(childDefaultValues);
  return childDefVals[field.name];
}

function getDefaultValue(field, workflowItem, indexOfRepeatable, childDefaultValues) {
  let defaultValue = (field && field.defaultValue) || (childDefaultValues && getChildDefaultValue(childDefaultValues, field));
  const defaultValueCondition = field && field.queries && field.queries.defaultValueCondition;
  if (defaultValueCondition) {
    defaultValue = parseDefaultValueCondition(defaultValueCondition, workflowItem, indexOfRepeatable, defaultValue);
    defaultValue = handleTypeValue(defaultValue, { field, valueField: 'value', fieldKey: 'value' })
    return defaultValue;
  }
  defaultValue = handleTypeValue(defaultValue, { field, valueField: 'value', fieldKey: 'value' })
  switch (field.type) {
    case FieldTypes.DATE:
      // set default only if full date
      if (defaultValue) {
        const [date, time, mer] = defaultValue.split(' ');
        const regex = /[0-9]/;
        if ((regex.test(date) && regex.test(time) && mer) || regex.test(date)) {
          return defaultValue;
        }
      }
      return null;
    default:
      if (defaultValue) {
        if (defaultValue === 'null') {
          return null;
        }
        return defaultValue;
      }
      return '';
  }
}
/**
 * function which calculates value for the field. If any funciton exists on the field, then value is derived and returned.
 * Else if there is value from server, it returns that value. Else it will return default value from the template
 * @param {*} processedServerFieldValue - value from server
 * @param {*} ignoreServerValue - ignore server value
 * @param {*} workflowItem - template server data
 * @param {*} indexOfRepeatable - group index
 * @param {*} childDefaultValues  - default values passed from parent template
 * @returns
 */
function getValue(processedServerFieldValue, ignoreServerValue, field, workflowItem, indexOfRepeatable, childDefaultValues) {
  if (field.initValue) {
    return field.initValue;
  }
  return ((processedServerFieldValue !== null && processedServerFieldValue !== undefined) || trueValues.indexOf(processedServerFieldValue) !== -1) && !ignoreServerValue ? processedServerFieldValue : getDefaultValue(field, workflowItem, indexOfRepeatable, childDefaultValues);
}

function checkImmutable(immutable, mutableCondition, workflowItem) {
  if (!immutable) {
    return false;
  }
  if (!mutableCondition) {
    return true;
  }
  const evalResult = eval(mutableCondition);
  return !evalResult;
}

const readOnlyForNonWorkflow = (field, processedServerFieldValue) => {
  if (field.readOnly) {
    return true;
  }
  if (processedServerFieldValue && field.immutable) {
    return true;
  }
  return false;
}

function checkReadOnly(clone, field, workflowItem, processedServerFieldValue) {
  return workflowItem ?
    (!clone && (checkImmutable(field.immutable, field.mutableCondition, workflowItem)) || field.readOnly) :
    readOnlyForNonWorkflow(field, processedServerFieldValue);
}

function initFuncStrSpecialGroup(field, isSpecial) {
  if (isSpecial && field.function && field.function.indexOf('&&') !== -1) {
    return field.function.split('&&');
  }
  return [field.function];
}
/**
 *
 * @param {*} groupMetadata - each template group
 * @param {*} field - each field in the template group
 * @param {*} value - initial value of the field
 * @param {*} i - repeatable index of field
 * @returns boolean
 * function which checks for mandatory fields and return the required fields if value is not present.
 */
function getRequiredValueMissing(groupMetadata, field, value, i) {
  return groupMetadata.limit > 1
    ? parseRepeateableRequired(field.queries && field.queries.requiredCondition, field.required, i) && isRequiredValueMissing(field, value)
    : field.required && isRequiredValueMissing(field, value);
}

function swapFields(newState, groupName, index1, index2) {
  const temp = newState[groupName].fields[index1]
  newState[groupName].fields[index1] = newState[groupName].fields[index2]
  newState[groupName].fields[index2] = temp
}

function getFunctionPropSpecialType(funcStrArrSpecialGroup, i, field, serverGroupValue) {
  if (checkNonZeroArray(funcStrArrSpecialGroup)) {
    const funcForGivenIndex = funcStrArrSpecialGroup[i] ? funcStrArrSpecialGroup[i] : funcStrArrSpecialGroup[0];
    return getFunctionValues(funcForGivenIndex, field.queries, serverGroupValue, /*parent group is also special*/true);
  }
  return null;
}

function getProcessedServerValue(serverGroupValue, i, field, groupMetadata) {
  const serverSpecialGroupValue = getDeepValue(serverGroupValue, [i]);
  const serverSpecialFieldValue = getDeepValue(serverSpecialGroupValue, [field.name]);
  return processServerValue({
    field,
    value: serverSpecialFieldValue,
    groupName: groupMetadata.name,
    groupData: serverSpecialGroupValue
  });
}

// ****** Reducer actions ******
function setTemplateData(state, { template, serverData, errors, clone, viewFromSearch, historicalData, workflowItem, parentData }) {
  const { groups } = template.metadata;
  const childDefaultValues = parentData && parentData.childDefaultValues;
  // iterates through the template and sets the server data or the default value (values[0]) sample structure on the bottom
  if (groups) {
    const newState = groups.reduce((groupObject, groupMetadata) => {
      let typeKey = 'values';
      let isSpecial = false;
      let hasError = false;
      let isRequired = false;
      let requiredMissing = [];
      let reduceInit = {};
      let funcStrArrSpecialGroup;
      if (TemplateSpecialGroupType.includes(groupMetadata.type)) {
        typeKey = low(groupMetadata.type);
        isSpecial = true;
        const serverGroupValue = getDeepValue(serverData, [typeKey, groupMetadata.name]);
        if (groupMetadata.limit) {
          reduceInit = new Array(groupMetadata.limit).fill().map(_ => ({}))
        } else if (serverGroupValue && serverGroupValue.length > 0) {
          reduceInit = new Array(serverGroupValue.length).fill().map(_ => ({}))
        } else {
          reduceInit = [{}]
        }
      }

      const serverGroupValue = getDeepValue(serverData, [typeKey, groupMetadata.name]);
      const fieldValues = groupMetadata.fields.reduce((fieldsObject, field, reduceIndex) => {
        const error = errors ? errors.find(error => error.field === field.name) : null;
        /**
         * Get the function Array to be used across indexes in a special group
         * eg. We want to initialize same field with different functions in
         * different indexes in a special group(repeatable)
         */
        funcStrArrSpecialGroup = initFuncStrSpecialGroup(field, isSpecial);
        let functionProps = getFunctionValues(field.function, field.queries, serverGroupValue);
        const fromServer = serverGroupValue && fieldsObject.length < 1;
        const ignoreServerValue = clone && !(field.cloneable);
        const hide = field.hide || (viewFromSearch && field.hideToView);

        if (field.required) {
          isRequired = true;
        }
        if (error) {
          hasError = true;
        }
        if (reduceIndex < 1 && isSpecial) {
          requiredMissing = fromServer || (serverGroupValue && serverGroupValue.length > 0)
            ? new Array(serverGroupValue.length).fill().map(_ => new Array())
            : groupMetadata.limit
              ? new Array(groupMetadata.limit).fill().map(_ => new Array())
              : [[]];
        }

        // special groups iterates through an array
        if (isSpecial) {
          return fromServer
            ? serverGroupValue.map((group, i) => {
              /**
               * If we want to initiliaze array type group with different values
               * for different ojects in the array
               * Get the function prop for every object in the array.
               */
              functionProps = getFunctionPropSpecialType(funcStrArrSpecialGroup, i, field, serverGroupValue);
              const serverSpecialFieldValue = getDeepValue(group, [field.name]);
              const processedServerFieldValue = processServerValue({
                field,
                value: serverSpecialFieldValue,
                groupName: groupMetadata.name,
                groupData: group
              });
              //get default value in case of multiple objects in repeatable
              //eg in IPM calculate field default value is true.
              const value = getValue(processedServerFieldValue, ignoreServerValue, field, workflowItem, null, childDefaultValues);
              const defValue = getDefaultValue(field, workflowItem, i, childDefaultValues);

              // get child template data if it exists
              const { childTemplateData, parentSelectedValue } = getChildTemplateData({field, value, serverData, groupValues: serverGroupValue && serverGroupValue[i]})

              // missing
              const requiredValueMissing = field.required && isRequiredValueMissing(field, value);
              if (requiredValueMissing) {
                requiredMissing[i].push(field.name);
              }

              // error
              const newError = error ? error : (requiredValueMissing ? TemplateErrors.MISSING_INPUT(field.shortDesc) : null);
              if (newError) {
                hasError = true;
              }

              return {
                [field.name]: {
                  value,
                  metadata: field,
                  required: field.required,
                  error: newError,
                  readOnly: checkReadOnly(clone, field, workflowItem, processedServerFieldValue),
                  functionProps,
                  serverValue: processedServerFieldValue,
                  hide,
                  defaultValue: defValue,
                  ...(childTemplateData ? {
                    [parentSelectedValue]: childTemplateData
                  } : {})
                }
              };
            })
            : fieldsObject.map((group, i) => {
              /**
               * If we want to initiliaze array type group with different values
               * for different ojects in the array
               * Get the function prop for every object in the array.
               */
              functionProps = getFunctionPropSpecialType(funcStrArrSpecialGroup, i, field, serverGroupValue);
              let oldValue;
              if (historicalData) {
                const oldServerGroupValue = getDeepValue(historicalData, [typeKey, groupMetadata.name]);
                const oldProcessedServerFieldValue = getProcessedServerValue(oldServerGroupValue, i, field, groupMetadata);
                oldValue = getValue(oldProcessedServerFieldValue, ignoreServerValue, field, workflowItem, null, childDefaultValues);
              }
              const processedServerFieldValue = getProcessedServerValue(serverGroupValue, i, field, groupMetadata);
              //get default value in case of multiple objects in repeatable
              const value = getValue(processedServerFieldValue, ignoreServerValue, field, workflowItem, i, childDefaultValues);
              // missing
              const defValue = getDefaultValue(field, workflowItem, null, childDefaultValues);

              // get child template data if it exists
              const { childTemplateData, parentSelectedValue } = getChildTemplateData({field, value, serverData, groupValues: serverGroupValue && serverGroupValue[i]})

              const requiredValueMissing = getRequiredValueMissing(groupMetadata, field, value, i)
              if (requiredValueMissing) {
                requiredMissing[i].push(field.name);
              }

              // error
              const newError = error ? error : (requiredValueMissing ? TemplateErrors.MISSING_INPUT(field.shortDesc) : null);
              if (newError) {
                hasError = true;
              }
              return {
                ...(group || {}),
                [field.name]: {
                  value,
                  metadata: field,
                  required: field.required,
                  error: newError,
                  readOnly: checkReadOnly(clone, field, workflowItem, processedServerFieldValue),
                  functionProps,
                  serverValue: processedServerFieldValue,
                  hide,
                  oldValue: oldValue,
                  defaultValue: defValue,
                  ...(childTemplateData ? {
                    [parentSelectedValue]: childTemplateData
                  } : {})
                }
              };
            })
        } else {
          // regular type field
          let oldValue
          if (historicalData) {
            const serverOldFieldValue = getDeepValue(historicalData, [typeKey, field.name]);
            const processedServerOldFieldValue = processServerValue({
              field,
              value: serverOldFieldValue,
              groupName: groupMetadata.name
            });
            oldValue = getValue(processedServerOldFieldValue, ignoreServerValue, field, workflowItem, null, childDefaultValues);
          }
          const serverCommonFieldValue = getDeepValue(serverData, [typeKey, field.name]);
          const processedServerFieldValue = processServerValue({
            field,
            value: serverCommonFieldValue,
            groupName: groupMetadata.name
          });
          let value = getValue(processedServerFieldValue, ignoreServerValue, field, workflowItem, null, childDefaultValues);
          const defValue = getDefaultValue(field, workflowItem, null, childDefaultValues);
          let options = field.options;
          const setOptionsFromValue = getDeepValue(field, ['queries', 'setOptionsFromValue'])
          if (value && typeof value === 'object' && Object.keys(value).length > 0 && setOptionsFromValue) {
            options = value
            value = Object.keys(options)[0]
          }
          if (typeof options === 'object' && Object.keys(options).length > 0 && getDeepValue(field, ['queries', 'setValueFromOptions'])) {
            value = value || Object.keys(options)[0]
          }


          // get child template data if it exists
          const { childTemplateData, parentSelectedValue } = getChildTemplateData({field, value, serverData, groupValues: getDeepValue(serverData, [typeKey])})

          // missing
          const requiredValueMissing = field.required && isRequiredValueMissing(field, value);
          if (requiredValueMissing) {
            requiredMissing.push(field.name);
          }

          // error
          const newError = error ? error : (requiredValueMissing ? TemplateErrors.MISSING_INPUT(field.shortDesc) : null);
          if (newError) {
            hasError = true;
          }
          return {
            ...fieldsObject,
            [field.name]: {
              value,
              options,
              metadata: field,
              required: field.required,
              error: newError,
              readOnly: checkReadOnly(clone, field, workflowItem, processedServerFieldValue),
              functionProps,
              serverValue: processedServerFieldValue,
              hide,
              oldValue: oldValue,
              defaultValue: defValue,
              ...(childTemplateData ? {
                [parentSelectedValue]: childTemplateData
              } : {})
            }
          }
        }
      }, reduceInit);

      // return for group
      return {
        ...groupObject,
        [groupMetadata.name]: {
          metadata: groupMetadata,
          fields: fieldValues,
          error: hasError,
          required: isRequired,
          requiredMissing: requiredMissing
        }
      }
    }, {});

    return { ...newState, workflowItem }
  }
  return state;
}

function getChildTemplateData({ field, value, serverData, groupValues }) {
  let childTemplateData;
  const childTemplate = getDeepValue(field, ['queries', 'childTemplate']);
  let parentSelectedValue = getDeepValue(field, ['queries', 'parentSelectedValue']);
  const saveDataInParent = getDeepValue(field, ['queries', 'saveDataInParent']);
  const templateGroupType = getDeepValue(field, ['queries', 'templateGroupType']);
  const hasMultipleGroups = getDeepValue(field, ['queries', 'hasMultipleGroups']);
  const ifSpecialModalDataType = (templateGroupType && TemplateSpecialGroupType.includes(templateGroupType.toUpperCase())) || hasMultipleGroups;
  //If the modal is  setting up the value of an input , we can use field Name or templateGroupType as parent selected value and use it as the key to save childTemplateDta
  if (serverData && childTemplate && saveDataInParent) {
    parentSelectedValue = ifSpecialModalDataType ? templateGroupType : field.name;
    childTemplateData = value;
    field[parentSelectedValue] = childTemplateData;
  }
  if (serverData && childTemplate && parentSelectedValue && parentSelectedValue === value && !saveDataInParent) {
    const templateGroupType = getDeepValue(field, ['queries', 'templateGroupType']);
    // If we need to unflatten the server data (Custom Award flow)
    if (low(templateGroupType) === low(TemplateGroupType.FLAT_MODAL_DATA)) {
      // get server value keys that are flattened fields
      const noPrefix = getDeepValue(field, ['queries', 'noPrefix'])
      const valuePrefix = noPrefix ? '' : `${field.name}.`;
      const serverValuesKeys = Object.keys(groupValues)
        .filter((valueKey) => valueKey.startsWith(valuePrefix))

      // unflatten data
      childTemplateData = {};
      serverValuesKeys.forEach((valueKey) => {
        const value = groupValues[valueKey];
        childTemplateData[valueKey.slice(valuePrefix.length)] = value
      })
    } else { // Data is stored directly on the server data (Custom IPM)
      childTemplateData = getDeepValue(serverData, [parentSelectedValue]);
    }
    field[parentSelectedValue] = childTemplateData;
  }
  return { childTemplateData, parentSelectedValue }
}

export function changeFieldData(state, { field, group, value, error,parentTemplateData={}, ...otherParams }, inFunction = false) {
  const { name: fieldName } = field.metadata;
  const { name: groupName, type: groupType } = group.metadata;
  const { functionSet = [], key, oldValue, history } = otherParams || {};
  const { groupIndex } = group;
  const { functionProps } = field;
  let newState = { ...state };


  let newError = error ? error : null;
  const notValue = key && key !== 'value';
  const fullValue = value && value.fullValue ? value.fullValue : otherParams.fullValue;

  // set other values
  if (functionProps && !inFunction) {
    for (let i = 0; i < functionProps.length; i++) {
      const functionPropsValue = functionProps[i];
      if (functionPropsValue.fieldKey && templateFuncCheck.checkSetOthers(functionPropsValue.fieldKey)) {
        newState = functions[functionPropsValue.funcName] && functions[functionPropsValue.funcName](newState, parentTemplateData, { value, fullValue, field, group }, ...functionPropsValue.params);
        functionSet[i] = true;
      }
    }
  }

  // update group's required missing
  newError = updateGroupRequiredMissing(newState, { field, group, currentGroupIndex: group?.groupIndex || 0,  value, error: newError, ...otherParams });

  // if notValue use the key provided
  let objectToUpdate;
  if (TemplateSpecialGroupType.includes(groupType)) {
    objectToUpdate = newState[groupName].fields[groupIndex][fieldName];
  } else {
    objectToUpdate = newState[groupName].fields[fieldName]
  }
  if (notValue) {
    objectToUpdate[key] = value;
    if (objectToUpdate?.metadata && ['required'].includes(key)) {
      objectToUpdate.metadata[key] = value;
      newError = updateGroupRequiredMissing(newState, { ...otherParams, field: objectToUpdate, group, currentGroupIndex: group?.groupIndex || 0, value: objectToUpdate.value, error: newError, key: 'value', resetRequired: !value });
      objectToUpdate.error = newError;
    }
  } else {
    if (history) {
      objectToUpdate.oldValue = oldValue;
    }
    if (fullValue) {
      objectToUpdate.fullValue = fullValue;
    }
    objectToUpdate.value = value;
    objectToUpdate.error = newError;
  }
  if (functionSet && functionSet.length > 0) {
    if (!objectToUpdate.fromFunc) {
      objectToUpdate.fromFunc = [];
    }
    functionSet.forEach((set, index) => objectToUpdate.fromFunc[index] = set ? value : null);
  }
  if (group.initHideGroup || group.initReadableGroup) {
    newState[groupName].required = false;
  }
  newState[groupName].error = checkGroupError(newState[groupName]);

  return newState;
}

function callChangeFunction(state, { field, group, parentTemplateData={}, ...otherParams }) {
  const { functionProps } = field;
  let newState = { ...state };
  // const {  parentTemplateData={} } = otherParams || {};

  // set other values
  if (functionProps) {
    for (let i = 0; i < functionProps.length; i++) {
      const functionPropsValue = functionProps[i];
      if (functionPropsValue.fieldKey && templateFuncCheck.checkSetOthers(functionPropsValue.fieldKey)) {
        newState = functions.setFuncValues(newState,parentTemplateData, ...functionPropsValue.params);
      }
    }
  }

  return newState;
}

/* If the modal has multiple groups and a special type , we will be using the templateGroup type as a fieldValue and set the modalData to that,
if the modal is setting the values of an input(saveDataInParent), then we are setting the modalData in the field with field Name as key*/

function setModalData(state, { field, modalData, group, ...other }) {
  const newState = { ...state };
  const { name: groupName } = group.metadata;
  const { templateGroupType: modalTemplateType = "", saveDataInParent = false, hasMultipleGroups = false } = other
  const ifSpecialModalDataType = (TemplateSpecialGroupType.includes(modalTemplateType.toUpperCase()) && hasMultipleGroups) || (TemplateSpecialGroupType.includes(modalTemplateType.toUpperCase()) && saveDataInParent);
  const fieldValue = ifSpecialModalDataType ? modalTemplateType : field.value;

  let newValue;
  if (modalData[fieldValue] && !ifSpecialModalDataType ) {
    const modalDataArr = [...modalData[fieldValue]]
    modalDataArr.forEach((data, index) => {
      data['rank'] = index + 1
    })
    newValue = modalDataArr;
  } else if (ifSpecialModalDataType) {
    newValue = modalData[fieldValue.toLowerCase()]
  } else {
    newValue = modalData.values
  }


  // If template is special type, it is stored in an array and we need to get by index
  const groupTemplateType = getDeepValue(group, ['metadata', 'type'])
  let fieldState;
  if (TemplateSpecialGroupType.includes(groupTemplateType)) {
    fieldState = newState[group.metadata.name].fields[group.groupIndex || 0][field.name]
  } else {
    fieldState = newState[group.metadata.name].fields[field.name]
  }

  if (saveDataInParent) {
    if (TemplateSpecialGroupType.includes(modalTemplateType.toUpperCase())) {
      const type = modalTemplateType.toLowerCase()
      fieldState[type] = newValue;
    } else {
      fieldState[fieldState.metadata.name] = newValue;
    }

  } else {
    fieldState[fieldValue] = newValue
  }

  fieldState.error = updateGroupRequiredMissing(newState, { field: fieldState, group, value: fieldValue });
  newState[groupName].error = checkGroupError(newState[groupName]);
  return newState;
}

function reorderGroupIndex(state, { group, direction }) {
  const newState = { ...state }

  if (direction === 'up') {
    swapFields(newState, group.metadata.name, group.groupIndex, group.groupIndex - 1)
  } else if (direction === 'down') {
    swapFields(newState, group.metadata.name, group.groupIndex, group.groupIndex + 1)
  }

  return newState;
}

function addRepeatable(state, { group = {} }) {
  const newState = { ...state };
  const template = {
    metadata: {
      groups: [group.metadata]
    }
  }
  const updatedState = setTemplateData(newState, { template });
  newState[group.metadata.name].fields.push(updatedState[group.metadata.name].fields[0])
  newState[group.metadata.name].requiredMissing.push(updatedState[group.metadata.name].requiredMissing[0])
  return newState;
}

function removeRepeatable(state, { group }) {
  const newState = { ...state };
  const { name: groupName } = group.metadata;
  const { groupIndex } = group;
  newState[groupName].fields.splice(groupIndex, 1);
  newState[groupName].requiredMissing.splice(groupIndex, 1);

  return newState;
}

function setOthersOnly(state, { group, field, value, error, ...otherParams }) {
  const { functionProps } = field;
  let newState = { ...state };
  const fullValue = value && value.fullValue ? value.fullValue : otherParams.fullValue;
  const {  parentTemplateData={} } = otherParams || {};

  // set other values
  if (functionProps) {
    for (let i = 0; i < functionProps.length; i++) {
      const functionPropsValue = functionProps[i];
      if (functionPropsValue.fieldKey && templateFuncCheck.checkSetOthers(functionPropsValue.fieldKey)) {
        newState = functions[functionPropsValue.funcName](newState, parentTemplateData,{ value, fullValue, field, group }, ...functionPropsValue.params);
      }
    }
  }

  return newState;
}

// ****** END of Reducer actions ******

function reducer(state, action) {
  switch (action.type) {
    case reducerActions.SET_TEMPLATE_DATA:
      return setTemplateData(state, action.data);
    case reducerActions.CHANGE_FIELD_DATA:
      return changeFieldData(state, action.data);
    case reducerActions.ADD_REPEATABLE:
      return addRepeatable(state, action.data);
    case reducerActions.REMOVE_REPEATABLE:
      return removeRepeatable(state, action.data);
    case reducerActions.CALL_CHANGE_FUNCTION:
      return callChangeFunction(state, action.data);
    case reducerActions.SET_OTHERS_ONLY:
      return setOthersOnly(state, action.data);
    case reducerActions.SET_MODAL_DATA:
      return setModalData(state, action.data);
    case reducerActions.REORDER_GROUP_INDEX:
      return reorderGroupIndex(state, action.data);
    default:
      throw new Error();
  }
}

export { reducer, init, reducerActions };
