import { makeStyles } from '@material-ui/core';
import Colors from '../../../theme/Colors';

const largeWidth = 564;

const styles = {
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    // paddingTop: 152,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // height: '100%',
    boxSizing: 'border-box',
    width: '100%',
    margin: 'auto'
  },
  searchRoot: {
    paddingTop: 16,
    paddingBottom: 16,
    // paddingTop: 152,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // height: '100%',
    boxSizing: 'border-box',
    width: '100%'
  },
  buttonContainer: {
    paddingTop: 16,
    width: '100%',
    height: 64,
    maxWidth: largeWidth
    // marginTop: 'auto'
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  },
  gridItem: {
    width: '100%',
    marginTop: 4,
    marginBottom: 4
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: largeWidth,
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 24,
    height: 300
  },
  entryCell: {
    height: 24,
    alignItems: 'center'
  },
  tableSticky: {
    position: 'sticky',
    backgroundColor: Colors.primaryBlue,
    top: 0,
    zIndex: 4
  },
  table: {
    position: 'relative',
    borderCollapse: 'separate'
  },
  skeletonContainer: {
    width: '100%',
    marginTop: 16,
    padding: 32,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 'auto',
    boxSizing: 'border-box'
  },
  skeletonWrapper: {
    marginTop: 16,
    padding: 4
  },
  status: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerText: {
    color: 'white'
  },
  titleText: {
    marginBottom: 16
  },
  tableContainer: {
    width: '100%',
    overflow: 'auto',
    marginBottom: 'auto'
  },
  descriptionBox: {
    marginTop: 16
  },
  searchContentContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    height: 520,
    overflow: 'hidden'
  },
  searchContainer: {
    flex: 1,
    overflow: 'hidden',
    width: '100%'
  },
  searchItemContainer: {
    display: 'flex',
    width: '100%',
    // maxWidth: largeWidth,
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 24,
    paddingTop: 16,
    height: 400
  },
  filterContainer: {
    // height: 60,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    // border: `1px solid ${Colors.grey100}`,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    backgroundColor: Colors.white
  },
  searchInput: {
    margin: 'auto'
  },
  historyContainer: {
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%'
  },
  historyTableStyles: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: "repeat(auto-fit, minmax(16%, 1fr))",
    position: 'sticky',
    top: 0,
    'z-index': 2,
    backgroundColor: Colors.primaryBlue
  },
  headerCellStyles: {
    color: Colors.white
  }
};

export default makeStyles(styles);
