import React from "react";
import clsx from 'clsx';
import { FormControl, FormLabel, Radio, RadioGroup, FormControlLabel, FormHelperText } from "@material-ui/core";
import useStyles from "./RadioInputStyles";
import getDeepValue from '../../Utils/getDeepValue';
import parseOptions from '../../Utils/input/parseOptions';
import { low } from "../../constants/Constants";

const TemplateRadio = props => {
  const classes = useStyles();

  const {
    label,
    value,
    metadata,
    onChange,
    required,
    disabled,
    error,
    parentValue
  } = props;

  const { values } = metadata;
  const isColumn = getDeepValue(metadata, ['queries', 'radioType']) === 'column';

  const options = parseOptions(values, { isListAction: true, parentValue: parentValue && parentValue.fullValue });

  const RadioButton = val => (
    <FormControlLabel
      key={val.value}
      value={val.value}
      control={<Radio color="secondary" />}
      label={val.label}
      labelPlacement="end"
      disabled={disabled || val.disabled}
      checked={low(val.value) === low(value)}
    />
  );

  return (
    <FormControl component="fieldset">
      {label ? <FormLabel required={required} component="legend" className={classes.radioLabel}>{label}</FormLabel> : null}
      <RadioGroup onChange={onChange} row aria-label="position" name={name} className={clsx(classes.radioGroup, { [classes.radioGroupError]: error, [classes.radioColumn]: isColumn })}>
        {options.map(RadioButton)}
      </RadioGroup>
      <FormHelperText className={classes.formHelperError} style={{display : error ? 'block' : 'none'}}>{error}</FormHelperText>
    </FormControl>
  )
};

export default TemplateRadio;
