import React, { useContext, useMemo, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import './index.css';
import Navigations from './Components/Common/Navigations';
import Home from './compartments/root/home';
import RequestAccess from './compartments/user/RequestAccess';
import Background from './widgets/background/background';
import FourOFour from './Components/FourOFour/FourOFour';
import FAQComponent from './Components/FAQ/FAQComponent';
import UserComptContext from './setup/compartments/compartment.context';
import { Roles, RoleIds } from './constants/Constants';
import BulkUpload from './Components/biglist/BigListUpload/BulkUpload';
import SessionRestore from './widgets/persistence/SessionRestore';

const VideoTutorial = lazy(() => import('./Components/VideoTutorial/VideoCompartment'));
const PageCompartment = lazy(() => import('./compartments/page/page'));
const Workflow = lazy(() => import('./compartments/workflow/metadata/workflow'));


function Routes() {
  // Background would have to be changed at some point
  const { user, simulatedUser, simulatingUser } = useContext(UserComptContext);


  const noAccess = useMemo(() => {
    const currentUser = simulatingUser ? simulatedUser : user
    if (currentUser && ((currentUser.role && (!currentUser.role.id || currentUser.role.id === RoleIds.UNASSIGNED || currentUser.role.name === Roles.UNASSIGNED))) || (Object.keys(currentUser).length > 0 && !currentUser.role)) {
      return true;
    }
    return false;
  }, [user, simulatedUser, simulatingUser]);

  return (
    <Router>
      <SessionRestore />
      <Route path={noAccess ? '*' : '/:rootType?'} component={Navigations} />
      <div className="routes-container">
        {noAccess ? (
          <RequestAccess />
        ) : (
          <Switch>
            <Route path="/" exact>
              <Redirect to="/home" />
            </Route>
            <Route path="/home" component={Home} exact />
            <Route>
              <Background>
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    <Route
                      path="/:rootType/page/redirect"
                      component={(props) => <PageCompartment {...props} redirect />}
                    />
                    <Route path="/:rootType/page/:ids+" component={PageCompartment} />
                    <Route
                      path="/:rootType/workflow/:workflowId/:parentId/:itemId?/:templateId?/:mode?"
                      component={(props) => <Workflow {...props} key={props.match.url} />}
                      exact
                    />
                    <Route
                      path="/bulkupload/:name"
                      component={() => <BulkUpload />}
                      exact
                    />
                    <Route path="/FAQ" component={FAQComponent} exact />
                    <Route
                      path="/tutorials"
                      component={VideoTutorial}
                      exact
                    />
                    <Route component={FourOFour} />
                  </Switch>
                </Suspense>
              </Background>
            </Route>
          </Switch>
        )}
      </div>
    </Router>
  );
}

export default Routes;
