import React, { useState, useEffect } from 'react';
import { Fab, Fade } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';
import scrollTo from '../../Utils/scrollWindow';

const ScrollUpButton = ({
  size = 'small',
  color = 'primary',
  scrollUpOffset = 0
}) => {

  const [showUp, setShowUp] = useState(false);

  useEffect(() => {
    function handleScroll() {
      setShowUp(window.scrollY >= 500);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <Fade
      in={showUp}
      style={{
        position: 'fixed',
        bottom: 32 + scrollUpOffset,
        right: 32,
        zIndex: 2
      }}
    >
      <Fab color={color} size={size} onClick={() => scrollTo(0)}>
        <ArrowUpward />
      </Fab>
    </Fade>
  )
}

export default ScrollUpButton;
