import { DownloadPDFErrors } from '../../constants/Errors';
import { baseURL, Index, baseHeaders } from '../../constants/Endpoints';
import getUserService from '../user/getUserService';
import fetchAll from '../../Utils/services/fetchAll';
import getAllFieldSiblings from '../../Utils/services/getAllFieldSiblings';
import { TemplateMixTypes, low } from '../../constants/Constants';
import fetchAllBiglistValuesService from '../biglist/FetchAllBiglistValuesService';
import { checkNonZeroArray } from '../../Utils/helpers';

async function getPDFData(row, knownCampaign) {
  const offerData = {
    promotion: null,
    items: null,
    campaign: null,
    user: null,
    approver: null
  };

  await getPromotion(row.id);
  return offerData;

  async function getCampaign(id) {
    if (knownCampaign) {
      offerData.campaign = knownCampaign;
      return;
    }
    if (id) {
      const body = {
        filter: `values/campaign_number eq '${id}' and (current eq 'true' or current eq 'True')`
      }
      try {
        const campaigns = await fetchAll(Index.CAMPAIGN, body);
        const campaign = campaigns ? campaigns[0] : null;
        if (campaign) {
          offerData.campaign = campaign;
        } else {
          offerData.campaign = DownloadPDFErrors.SERVICE_ERROR;
        }
      } catch (error) {
        offerData.campaign = DownloadPDFErrors.SERVICE_ERROR;
      }
    } else {
      offerData.campaign = DownloadPDFErrors.SERVICE_ERROR;
    }
  }

  function checkIfCampaign(promotion, promises) {
    if (promotion.values && promotion.values.campaign_number) {
      promises.push(getCampaign(promotion.values.campaign_number));
    } else {
      offerData.campaign = DownloadPDFErrors.SERVICE_ERROR;
    }
  }

  function checkUserData(promotion, promises) {
    if (promotion.createdBy) {
      const approvalLogs = promotion.auditTrail.filter((auditLog) => auditLog.event === "REVIEW");
      const approverLog = approvalLogs.length > 0 ? approvalLogs[approvalLogs.length - 1] : {};
      offerData.approver = { log: approverLog }
      promises.push(getUser(promotion.createdBy, approverLog && approverLog.createdByUser ? approverLog.createdByUser : null));
    } else {
      offerData.user = DownloadPDFErrors.SERVICE_ERROR;
      offerData.approver = DownloadPDFErrors.SERVICE_ERROR;
    }
  }

  async function getPromotion(id) {
    if (id) {
      try {
        const promotion = await fetch(`${baseURL}promotions/${id}`, {
          method: 'GET',
          headers: baseHeaders()
        }).then(function (res) {
          return res.json();
        }).then(function (data) {
          return data;
        });
        if (promotion) {
          offerData.promotion = promotion;
          const promises = [];
          promises.push(getItems(promotion));
          checkIfCampaign(promotion, promises);
          checkUserData(promotion, promises);
          await Promise.all(promises);
        } else {
          offerData.promotion = DownloadPDFErrors.SERVICE_ERROR;
          offerData.user = DownloadPDFErrors.SERVICE_ERROR;
          offerData.campaign = DownloadPDFErrors.SERVICE_ERROR;
          offerData.approver = DownloadPDFErrors.SERVICE_ERROR;
          offerData.items = DownloadPDFErrors.SERVICE_ERROR;
        }
      } catch (error) {
        offerData.promotion = DownloadPDFErrors.SERVICE_ERROR;
        offerData.user = DownloadPDFErrors.SERVICE_ERROR;
        offerData.campaign = DownloadPDFErrors.SERVICE_ERROR;
        offerData.approver = DownloadPDFErrors.SERVICE_ERROR;
        offerData.items = DownloadPDFErrors.SERVICE_ERROR;
      }
    } else {
      offerData.promotion = DownloadPDFErrors.SERVICE_ERROR;
      offerData.user = DownloadPDFErrors.SERVICE_ERROR;
      offerData.campaign = DownloadPDFErrors.SERVICE_ERROR;
      offerData.approver = DownloadPDFErrors.SERVICE_ERROR;
      offerData.items = DownloadPDFErrors.SERVICE_ERROR;
    }
  }

  async function getUser(id, approverId) {
    if (id) {
      try {
        const [user, approver] = await Promise.all([getUserService(id), getUserService(approverId)])
        if (user) {
          offerData.user = user;
        } else {
          offerData.user = DownloadPDFErrors.SERVICE_ERROR;
        }

        if (approverId && !approver) {
          offerData.approver = DownloadPDFErrors.SERVICE_ERROR;
        } else {
          offerData.approver.user = approver ? approver : {}
        }
      } catch (error) {
        offerData.user = DownloadPDFErrors.SERVICE_ERROR;
        offerData.approver = DownloadPDFErrors.SERVICE_ERROR;
      }
    } else {
      offerData.user = DownloadPDFErrors.SERVICE_ERROR;
      offerData.approver = DownloadPDFErrors.SERVICE_ERROR;
    }
  }

  async function getItems(promotion) {
    let itemObjs = [];
    const siblings = getAllFieldSiblings(promotion, 'item_list');
    if (siblings && siblings[0]) {
      const itemConditions = siblings.filter((item) => item.item_type);
      const biglists = [];
      itemConditions.forEach((item) => {
        if (low(item.list_type) === TemplateMixTypes.CSV) {
          item.item_list.split(',').forEach((itemId) => {
            itemObjs.push({
              itemNumber: itemId,
              item_mpq_limit: item.item_mpq_limit,
              item_type: item.item_type
            })
          })
        } else if (low(item.list_type) === TemplateMixTypes.BIGLIST) {
          biglists.push(getBiglistItem(item))
        }
      })
      const biglistItems = await Promise.all(biglists);
      biglistItems.forEach((biglistItem) => {
        itemObjs = itemObjs.concat(biglistItem);
      })
    }
    await getItemsIndex(itemObjs);
  }

  async function getBiglistItem(item) {
    const biglistValues = await fetchAllBiglistValuesService(item.item_list)
    return biglistValues.map((biglistValue) => ({
      itemNumber: biglistValue.id,
      item_mpq_limit: item.item_mpq_limit,
      item_type: item.item_type
    }));
  }

  async function getItemsIndex(items) {
    const key = 'itemNumber';
    const indexKey = 'itemNumber';

    if (checkNonZeroArray(items)) {
      const itemList = items.reduce((prevItems, currentItem) => {
        if (currentItem[key]) {
          return prevItems ? `${prevItems}, ${currentItem[key]}` : currentItem[key];
        } else {
          return prevItems
        }
      }, null)
      const body = {
        filter: `search.in(itemNumber, '${itemList}')`
      }

      try {
        const itemsIndex = await fetchAll(Index.ITEMS, body);
        if (itemsIndex) {
          const finalItems = items.map((item) => {
            const itemIndexFound = itemsIndex.find((itemIndex) => itemIndex[key] === item[indexKey]);
            return {
              ...item,
              ...itemIndexFound
            }
          });
          offerData.items = finalItems;
        } else {
          offerData.items = DownloadPDFErrors.SERVICE_ERROR;
        }
      } catch (error) {
        offerData.items = DownloadPDFErrors.SERVICE_ERROR;
      }
    } else {
      offerData.items = DownloadPDFErrors.SERVICE_ERROR;
    }
  }
}


export default getPDFData;
