import React from 'react';
import TemplateMix from './inputs/TemplateMix';
import TemplateNumber from './inputs/TemplateNumber';
import TemplateString from './inputs/TemplateString';
import TemplateEnum from './inputs/TemplateEnum';
import TemplateDate from './inputs/TemplateDate';
import TemplateTime from './inputs/TemplateTime';
import TemplateData from './inputs/TemplateData';
import TemplateBoolean from './inputs/TemplateBoolean';
import TemplateMulti from './inputs/TemplateMulti';
import TemplateLabel from './inputs/TemplateLabel'
import TemplateBrandPreview from './inputs/TemplateBrandPreview'
import TemplateImagePreview from './inputs/TemplateImagePreview';
import TemplateMessagePreview from './inputs/TemplateMessagePreview';
import TemplateColor from './inputs/TemplateColor'
import TemplateSearch from './inputs/TemplateSearch'
import Note from '../message/Note';
import TemplateButton from './inputs/TemplateButton';
import TemplateRadio from './inputs/TemplateRadio';
import TemplateHyperlink from './inputs/TemplateHyperlink';
import TemplateDivider from './inputs/TemplateDivider';
import { FieldTypes } from '../../constants/Constants';
import EditorPreview from '../editor/EditorPreview';


const Template = props => {

  const { field, setOthers, dispatch, group, dataState, setTemplateModalData, workflowItem } = props;

  switch (field.type) {
    case FieldTypes.NOTE: return <Note title={field.shortDesc} spacing={8} spaceTop={0}>{field.longDesc}</Note>
    case FieldTypes.NUMBER: return <TemplateNumber field={field} />;
    case FieldTypes.ENUM:
      if(field.multi) {
        return <TemplateMulti field={field} />;
      } else {
        return <TemplateEnum field={field} />;
      }
    case FieldTypes.MIX: return <TemplateMix field={field} setOthers={setOthers} />;
    case FieldTypes.TABLE: return <TemplateMix field={field} table />;
    case FieldTypes.DATE:
    case FieldTypes.DATE2: return <TemplateDate field={field} />;
    case FieldTypes.TIME: return <TemplateTime field={field} />;
    case FieldTypes.BOOLEAN: return <TemplateBoolean field={field} />;
    case FieldTypes.LABEL: return <TemplateLabel field={field} />;
    case FieldTypes.SEARCH: return <TemplateSearch field={field} />;
    case FieldTypes.BRAND_PREVIEW: return  <TemplateBrandPreview field={field} />;
    case FieldTypes.IMAGE_PREVIEW: return <TemplateImagePreview field={field} />;
    case FieldTypes.MESSAGE_PREVIEW: return <TemplateMessagePreview field={field} />;
    case FieldTypes.COLOR: return <TemplateColor field={field} />;
    case FieldTypes.DATA: return <TemplateData field={field} />;
    case FieldTypes.BUTTON: return <TemplateButton field={field} />
    case FieldTypes.RADIO: return (
      <TemplateRadio
        field={field}
        dispatch={dispatch}
        group={group}
        dataState={dataState}
        setTemplateModalData={setTemplateModalData}
        workflowItem={workflowItem}
      />
    )
    case FieldTypes.HYPERLINK: return <TemplateHyperlink field={field} />;
    case FieldTypes.DIVIDER: return <TemplateDivider field={field} />
    case FieldTypes.EDITOR_PREVIEW_HTML: return <EditorPreview field={field} valueType="html" />
    case FieldTypes.EDITOR_PREVIEW_TEXT: return <EditorPreview field={field} valueType="text" />
    case FieldTypes.EDITOR_HTML: return <EditorPreview field={field} disablePreview valueType="html" />
    case FieldTypes.EDITOR_TEXT: return <EditorPreview field={field} disablePreview valueType="text" />
    case FieldTypes.STRING:
    default: return (
      <TemplateString
        field={field}
        dispatch={dispatch}
        group={group}
        dataState={dataState}
        setTemplateModalData={setTemplateModalData}
        workflowItem={workflowItem}
      />
    );
  }
}

export default Template;
