import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import {
  withStyles
} from '@material-ui/core';
import styles from './ItemList.styles';
import Search from '../search/search';
import FullScreenModal from '../../widgets/modal/FullScreenModal';
import { ItemContext } from './ItemContext';

function ItemList({ classes, isModal = true, save, cancel, open, hasActions, specificFieldSearch, uploadCsv, subSearchType, clearWithoutFilter, multiSelect = true, ...otherProps  }, ref) {
  const childSearch = useRef(null);

  useImperativeHandle(ref, () => ({
    async getSelected() {
      const selectedProps = await childSearch.current.getSelected();

      return selectedProps;
    }
  }));

  function processSave(params) {
    const items = params.selectedRowsarray;
    save(items);
  }

  const component = (
    <ItemContext.Consumer>
      {(itemData) => (
        <Search
          ref={childSearch}
          {...otherProps}
          searchType='item'
          subSearchType={subSearchType}
          itemMetadata={itemData}
          actionWidth={120}
          actionTitle='Overlapping Offers'
          searchActions={hasActions ? [
            {
              label: 'Save',
              onClick: processSave,
              onClickParams: ['selectedRowsarray'],
              buttonProps: {
                color: 'secondary',
                variant: 'contained'
              }
            },
            {
              label: 'Cancel',
              onClick: cancel,
              buttonProps: {
                color: 'secondary'
              }
            }
          ] : null}
          isModal={isModal}
          showAdvancedInitial={!specificFieldSearch}
          specificFieldSearch={specificFieldSearch}
          uploadCsv={uploadCsv}
          multiSelect={multiSelect}
          clearWithoutFilter={clearWithoutFilter}
        />
      )}
    </ItemContext.Consumer>
  )

  if (isModal) {
    return (
      <FullScreenModal
        open={open}
        onRequestClose={cancel}
        title='Item List'
      >
        {component}
      </FullScreenModal>
    );
  } else {
    return component
  }
}

export default withStyles(styles)(forwardRef(ItemList));
