import { makeStyles } from '@material-ui/core';
import Colors from '../../../theme/Colors';
// import { fontMedium } from '../../../theme/theme';

const styles = {
  root: {
    height: 136,
    width: 360,
    backgroundColor: Colors.white,
    boxShadow: '0px 1px 0px 0px rgba(0,0,0,0.05)',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    flexDirection: 'column',
    padding: 16,
    display: 'flex',
    boxSizing: 'border-box',
    cursor: 'pointer'
    // borderRadius: 4
  },
  selected: {
    // outlineColor: Colors.primaryBlue
    boxShadow: `0px 1px 0px 0px rgba(0,0,0,0.05), inset 0px 0px 0px 2px ${Colors.primaryBlue}9f`
  },
  brandContainer: {
    marginLeft: 'auto'
  },
  fieldContainer: {
    flex: 1,
    overflow: 'none'
  },
  name: {
    textAlign: 'left',
    fontSize: 16,
    color: '#666666'
  },
  description: {
    textAlign: 'left',
    fontSize: 14,
    color: '#9c9c9c',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal'
  },
  topContainer: {
    height: 76,
    display: 'flex',
    flexDirection: 'row'
  },
  descriptionContainer: {
    height: 42
  },
  checkbox: {
    padding: 0
  },
  buttonContainer: {
    marginLeft: 'auto'
  },
  bottomContainer: {
    width: '100%',
    display: 'flex'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  infoIconButton: {
    padding: 0
  },
  infoIcon: {
    color: '#9c9c9c'
  }
};

export default makeStyles(styles);
