import React, { useEffect, useState, useCallback } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  Typography
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import useStyles from './Confirmation.styles';
import TextInput from '../inputs/TextInput';
import { checkNonZeroArray } from '../../Utils/helpers';
import { IconRenderer } from '../../constants/Constants';
import MemberListUpload from "../../Components/bulkSUC/MemberListUpload";
import TemplateSearch from '../templates/inputs/TemplateSearch';
import CustomButton from '../buttons/CustomButton/CustomButton';



const ConfirmationDialog = ({ open, title, text, fileBrowser = {}, actions, loading = true, value, textArea = {}, field = {} }) => {
  const [fieldValue, setFieldValue] = useState(null)
  const classes = useStyles();
  useEffect(() => {
    if (open === false) {
      setFieldValue(null);
    }
  }, [open])
  const onChange = useCallback(async (event) => {
    const { value: eventValue, error, otherParams } = event.target || {};

    const value = eventValue;
    if (value?.values?.campaign_name) {
      setFieldValue(value.values.campaign_name);
      if (field && typeof field === 'object' && typeof field.onUpdate === 'function') {
        field.onUpdate({ ...value.values, ...{ id: value.id } });
      }
    } else {
      setFieldValue('');
    }
  }, [field, setFieldValue]);
  if (field && typeof field === 'object') {
    field.onChange = onChange;
    field.value = fieldValue;
  }
  if (!open) {
    return null;
  }

  const renderButton = ({ label, onClick, disableCondition, ...props }, index) => {
    const disabled = disableCondition ? disableCondition(value, field.value) : false;
    return (
      <CustomButton key={`action${index}`} disabled={disabled || !loading} onClick={() => onClick(value)} {...props}>
        {label}
      </CustomButton>
    );
  }

  const Icon = ({ type }) => {
    const IconComponent = IconRenderer.ICON_COMPONENT(type).iconName;
    const iconColor = IconRenderer.ICON_COMPONENT(type).color;
    return (<IconComponent className={classes.infoIcon} fontSize="small" style={{ fill: iconColor }} />)

  }

  const renderTitle = (title) => {
    if (title) {
      if (typeof title === 'object') {
        return (
          <>
            <DialogTitle className={classes.customTitle}>{title.primary}</DialogTitle>
            <Divider />
            <DialogTitle className={classes.customTitle}>
              <Typography variant='body1'>
                {title.secondary}
              </Typography>
            </DialogTitle>
          </>
        )
      } else {
        return (<DialogTitle>{title}</DialogTitle>)
      }

    }
    return null

  }
  const renderTextContent = () => {
    if (checkNonZeroArray(text)) {
      return (
        <ul>
          {text.map((i) => (
            <DialogContentText key={i}>
              <li>{i}</li>
            </DialogContentText>
          ))}
        </ul>
      )
    } else {
      return (<DialogContentText>{text}</DialogContentText>)
    }
  }
  const { multiline = true } = textArea;
  return (
    <Dialog
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{
        paper: classes.dialogConfirmation
      }}
    >
      {renderTitle(title)}
      {text && (
        <DialogContent>
          <DialogContentText>{renderTextContent()}</DialogContentText>
        </DialogContent>
      )}
      {Object.keys(fileBrowser).length > 0 ? (
        <DialogContent>
          <MemberListUpload fileBrowser={fileBrowser} />
        </DialogContent>
      ) : null}
      {Object.keys(textArea).length > 0 ? (
        <DialogContent>
          <DialogContentText style={{ padding: '15px' }}>
            {Object.keys(field).length > 0 && field.type === "SEARCH" ? (<div style={{ marginBottom: '25px' }}><TemplateSearch field={field} /></div>) : null}
            <TextInput
              {...textArea.otherProps}
              placeholder={textArea.placeholder}
              required
              rows={3}
              fullWidth
              type={textArea.type}
              value={value || textArea.value}
              maxLength={textArea.maxLength}
              multiline={multiline}
              inputProps={{ style: { height: textArea.customHeight } }}
              onChange={(event) => textArea.onChange(event, textArea.row, textArea.item, textArea.stateKey)}
            />
            {textArea.helperText && (
              <div className={classes.info}>
                <Icon type={textArea.helperText.type} />
                <Typography className={classes.iText} variant="subtitle2">{textArea.helperText.text}</Typography>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
      ) : null}
      {actions ? (
        <DialogActions>
          {actions.map(renderButton)}
        </DialogActions>
      ) : null}
    </Dialog>
  );
}

export default ConfirmationDialog;
