import React, { useState, useEffect } from 'react';
import {
  Modal,
  IconButton,
  Backdrop,
  Slide,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SwipeableViews from 'react-swipeable-views';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import clsx from 'clsx';
import useStyles from './FullScreenModal.styles';
import useScreen from '../../hooks/useScreen';

function FullScreenModal(props) {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [transition, setTransition] = useState(true);
  const [ignoredElements, setIgnoredElements] = useState([]);
  const { open, children, height = '100vh', className, title, secondaryTitle, onBackButtonPress, direction = 'up', onRequestClose, onRequestCloseSuccess, ...modalProps } = props;

  const { isMobile } = useScreen();

  const elements = React.Children.toArray(children);

  useEffect(() => {
    if (!transition) {
      nextStep();
    }
  }, [transition])

  useEffect(() => {
    const timeout = setTimeout(() => setTransition(true), 200);

    return () => clearTimeout(timeout);
  }, [step])

  function close() {
    setTransition(true);
    setIgnoredElements([]);
    setStep(0);
    if (onRequestClose) {
      onRequestClose();
    }
  }

  function closeSuccess() {
    setTransition(true);
    setIgnoredElements([]);
    setStep(0);
    if (onRequestCloseSuccess) {
      onRequestCloseSuccess();
    }
  }

  function nextStep() {
    setStep((step) => {
      const newStep = step + 1;
      if (newStep >= elements.length) {
        return step;
      } else {
        return newStep;
      }
    })
  }

  function nextStepNoAnim() {
    setIgnoredElements((ignoredElements) => {
      return ignoredElements.concat([step]);
    });
    setTransition(false);
  }

  function prevStep() {
    setStep((step) => {
      const newStep = step - 1;
      if (newStep < 0) {
        close();
        return step;
      } else {
        return newStep;
      }
    })
  }

  const childProps = { nextStep, prevStep, close, setStep, closeSuccess, nextStepNoAnim }

  return (
    <Modal
      open={open}
      disableAutoFocus
      disableBackdropClick
      disableEscapeKeyDown
      disableRestoreFocus
      className={classes.fullScreenModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      onBackdropClick={close}
      {...modalProps}
    >
      <Slide direction={direction} in={open}>
        <div style={{ height: height }} className={clsx(className, classes.modalContainer)}>
          <div className={classes.barContainer}>
            <div className={classes.titleContainer}>
              {elements.length > 1 ? (
                <IconButton disabled={step === 0 || ignoredElements.includes(step-1)} className={classes.closeButton} onClick={onBackButtonPress ? onBackButtonPress : prevStep}>
                  <KeyboardArrowLeft />
                </IconButton>
              ) : null}
              {isMobile ? (
                <div>
                  <Typography variant='h6' className={classes.titleText}>{title}</Typography>
                  {secondaryTitle ? <Typography className={classes.secondaryTitle}>{secondaryTitle}</Typography> : null}
                </div>
              ) :
                <Typography variant='h6' className={classes.titleText}>{secondaryTitle ? `${title} - ${secondaryTitle}` : title}</Typography>
              }
            </div>
            <IconButton className={classes.closeButton} onClick={close}>
              <CloseIcon className={classes.closeIcon} fontSize='small' />
            </IconButton>
          </div>
          <div className={clsx(classes.childContainer)}>
            <SwipeableViews
              index={step}
              onChangeIndex={setStep}
              containerStyle={{ height: '100%', width: '100%' }}
              disabled
              slideClassName={clsx(classes.swipeView)}
              style={{ height: '100%', width: '100%' }}
              animateTransitions={transition}
            >
              {elements.map((element) => {
                return React.cloneElement(element, childProps)
              })}
            </SwipeableViews>
          </div>
        </div>
      </Slide>
    </Modal>
  );
}

export default FullScreenModal;
