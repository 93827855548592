import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function assignCouponService(membershipId,items) {
  try {
    const body = items.map(i=>i.couponGroupName)
    return await fetch(`${baseURL}coupon/membership/${membershipId}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export default assignCouponService;
