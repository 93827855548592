import { makeStyles } from '@material-ui/core';
import Colors from '../../../theme/Colors';
// import { fontRegular } from '../../theme/theme';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.backgroundColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'auto'
  },
  item: {
  },
  container: {
    width: 384,
    margin: 0,
    [theme.breakpoints.down(768)]: {
      width: 384
    },
    [theme.breakpoints.between(768, 1152)]: {
      width: 768
    },
    [theme.breakpoints.between(1152, 1536)]: {
      width: 1152
    },
    [theme.breakpoints.up(1536)]: {
      width: 1536
    }
  },
  searchContainer: {
    backgroundColor: Colors.white,
    width: '100%',
    margin: 0
  },
  topContainer: {
    padding: 12,
    boxSizing: 'border-box'
  },
  gridItem: {
    width: 336,
    height: 96,
    boxSizing: 'border-box',
    padding: 16,
    display: 'flex',
    alignItems: 'center'
  },
  searchInput: {
    marginBottom: 0
  },
  infoContainer: {
    // height: '100%',
    width: 360,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column'
  },
  closeInfoContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cardInfoContainer: {
    maxHeight: 400,
    overflow: 'auto'
  },
  skeletonContainer: {
    width: '100%',
    maxWidth: 1260,
    marginTop: 16,
    padding: 32,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 'auto',
    boxSizing: 'border-box'
  },
  skeletonWrapper: {
    marginTop: 16,
    padding: 4
  }
});

export default makeStyles(styles);
