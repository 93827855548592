import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'block',
    padding: 16,
    boxSizing: 'border-box',
    position: "relative"
  },
  stepRoot: {
    width: '100%',
    boxSizing: 'border-box'
  },

  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  redColor: {
    color: "red"
  },
  greenColor: {
    color: "green"
  },
  paper: {
    height: '100%',
    width: '100%',
    padding: 16,
    display: 'block',
    boxSizing: 'border-box',
    backgroundColor: '#00000004'
    //boxShadow: 'none'
  },
  gridItem: {
    display: 'block',
    padding: 10,
    boxSizing: 'border-box'
  },
  gridName: {
    // float: "right"
    display: 'grid',
    gridTemplateColumns: "1fr max-content",
    alignItems: 'center'
  },
  gridContainer: {
    display: "block"
  },
  scrollUpButton: {
    background: `${theme.palette.primary.main} !important`,
    outline: 'none',
    padding: 8,
    boxSizing: 'border-box',
    borderRadius: '50%',
    boxShadow: theme.shadows[5]
  },
  formGroupWrapper: {
    padding: '0px 48px'
  },
  formGroupContainer: {
    flexGrow: 1
  },
  formGroup: {
    marginTop: 32,
    scrollSnapAlign: 'center'
  },
  navigatorContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: 0,
    justifyContent: 'center',
    alignItems: 'stretch'
  },
  templateNavWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  templateNav: {
    justifyContent: 'flex-start',
    textAlign: 'start',
    textTransform: 'capitalize',
    padding: 10,
    height: 'auto',
    flex: 1
  },
  templateNavIconWrapper: {
    height: 24,
    minWidth: 24,
    marginRight: 8
  },
  templateNavIcon: {
    color: Colors.white,
    fontSize: 20,
    height: 20,
    width: 20,
    borderRadius: '50%',
    padding: 2
  },
  templateNavCheck: {
    backgroundColor: Colors.primaryGreen
  },
  templateNavError: {
    backgroundColor: theme.palette.error.main
  },
  templateNavMissing: {
    backgroundColor: Colors.grey200
  },
  templateNavNonMandatory: {
    color: Colors.primaryGreen,
    border: `2px solid ${Colors.primaryGreen}`,
    padding: 0
  },
  hide: {
    display: 'none'
  },
  otherActionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: 'auto'
  },
  navActionButtons: {
    padding: 8,
    margin: '2px 0px'
  }
}));

export default useStyles;
