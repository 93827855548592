/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect, useRef } from 'react'
import { EditorState } from 'draft-js';
import clsx from 'clsx';
import { Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResetToDefaultOption from './ResetToDefaultOption';
import TokenPickerFunc from './TokenPickerFunc';
import EditorActions from './EditorActions';
import useStyles from './ContentEditor.styles';
import { convertContentToText, onEditorStateChange } from '../../Utils/editor/editor-utils';
import { ContentTypes } from '../../constants/Constants';

const NormalContentEditor=({ value, onCancel, onContentUpdate, defaultValue, required,disablePreview, templateDropdownOptions={} })=> {

  const classes = useStyles();
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [enableSave, setEnableSave] = useState(true);
  const [focused, setFocused] = useState(false);
  const editorReference = useRef();

  useEffect(() => {
    if(value) {
      initWithText(value);
    }
  }, [value]);

  useEffect(() => {
    editorReference?.current?.focusEditor();
  }, [editorReference]);

  const initWithText = (text) => {
    onChange(EditorState.createWithText(text))
  }
  const onSave = async () => {
    setFocused(false)
    const rawContentState = convertContentToText(editorState)
    onContentUpdate(rawContentState);
  }

  const focusHandler = () => {
    setFocused(true);
    editorReference?.current?.focusEditor();
  }
  const onChange = (newEditorState) => {
    onEditorStateChange(newEditorState, setEnableSave, enableSave, { required })
    setEditorState(newEditorState);
  }
  const toolBarCustomOptions = defaultValue ? [
    <ResetToDefaultOption default={defaultValue} key="reset" contentType={ContentTypes.HTML} />,
    <TokenPickerFunc key="tokenPicker" templateDropdownOptions={templateDropdownOptions} />] : [<TokenPickerFunc key="tokenPicker" templateDropdownOptions={templateDropdownOptions} />]
  return(
    <div data-testid="normal-editor">
      <div
        className={classes.editorContainer}
        onClick={focusHandler}
        data-testid="editor-container"
      >
        <Editor
          editorState={editorState}
          wrapperClassName={clsx(classes.editorArea, { [classes.editorAreaFocused]: !!focused })}
          editorClassName={`${classes.messageArea} ${classes.backgroundWhite}`}
          ariaLabel="aria label"
          ref={editorReference}
          onEditorStateChange={onChange}
          onBlur={(event)=> !disablePreview ? event.preventDefault(): onSave()}
          toolbar={{
            options: []
          }}
          toolbarCustomButtons={toolBarCustomOptions}
          stripPastedStyles
        />
      </div>
      {!disablePreview && (
        <div className={classes.buttonArea}>
          <EditorActions onSave={onSave} onCancel={onCancel} enableSave={enableSave} />
        </div>
      )}
    </div>
  )
}
export default NormalContentEditor;
