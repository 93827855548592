import moment from 'moment';
import processServerValue from "./check-template-input";
import {
  FieldTypes,
  OfferLabels,
  OfferTypes,
  TemplateSpecialGroupType,
  low,
  up,
  SearchTypes,
  trueValues,
  DateFormat,
  BinCardTypes
} from "../../constants/Constants";
import getDeepValue from '../getDeepValue';
import { getFieldData, changeFieldData, getGroupData, getFunctionValues } from '../../compartments/templates/templateReducer';
import { baseURL, baseHeaders } from '../../constants/Endpoints';
import setEnumChildren from './functions/setEnumChildren';
import setEnumParent from './functions/setEnumParent';
import getBiglistEditValues from './getBiglistEditValues';
import updateGroupRequiredMissing from './updateGroupRequiredMissing';
import dateFormat from '../date/dateFormat';
import { TemplateErrors } from "../../constants/Errors";
import { isDiffUser } from "../workflow/check-if-is-diff-user";
import notNull from '../notNull';
import { checkNonZeroArray } from "../helpers";
import { fetchCategories } from "../../compartments/item/ItemContextProvider";
import SearchService from "../../setup/search/service.all";

/**
   * compare values based on the operator provided and returns true when mateches the condition

  * @operator {string} based on the operator we compare values(e.g 'equal')
  * @valueRange {array} range of values against which the current value will be compared
  * @currentValue {string | number} the value which will be compared with valueRange array values.
  * @example currentValue('equal',[0,5],0)"
  */
function compareValues(operator, valueRange, currentValue) {
  const finalOpearator = operator.replace(/['"]+/g, '');
  switch (finalOpearator) {
    case 'equal':
      return valueRange.some((element) => currentValue === element)
    case 'greaterThan':
      return valueRange.some((element) => +currentValue > +element)
    case 'smallerThan':
      return valueRange.some((element) => +currentValue < +element)
    case 'between':
      return currentValue > valueRange[0] && currentValue < valueRange[1]
    default: return false
  }

}
export const functions = {
  preview: (badgeText, foregroundColor, backgroundColor, outlineColor, cornerRadius) => {
    return {
      badgeText: badgeText,
      foregroundColor: foregroundColor,
      backgroundColor: backgroundColor,
      outlineColor: outlineColor,
      cornerRadius: +cornerRadius
    }
  },
  /**
   *Set Default Label as PURPOSE_MARKETTING or PURPOSE_MER or PURPOSE_MEMBERSHIP as as per template group
   * @param {*} templateType  - Type of template
   * @param {*} serverValues - existing labels
   * @param {*} owners - supplier information
   * @returns - an Object
   */
  addDefaultLabels: (templateType, serverValues,owners,vendorFundingPercent) => {
  //set automatically for Basket templates. Ops can also set and change it
    const PURPOSE_MARKETTING_TEMPLATES = OfferTypes.BASKET_OFFER_LIST.map(low)
    //set automatically for $off each, %off and optical templates templates for non-supplier-funded offers. Ops can also set and change it
    const PURPOSE_MERCH_AND_NON_SUPPLIER_TEMPLATES = [OfferTypes.DOLLAR_OFF_SELECTED_ITEMS,OfferTypes.PERCENT_OFF_SELECTED_ITEMS,OfferTypes.BUY_TOGETHER_DOLLAR_OFF,OfferTypes.BUY_TOGETHER_PERCENT_OFF,OfferTypes.OPTICALS_BUY_TOGETHER_PERCENT_OFF,OfferTypes.OPTICALS_PERCENT_OFF_SELECTED_ITEMS,OfferTypes.GENERIC_ITEM_OFFER,OfferTypes.TPR_TEMPLATE,OfferTypes.DOLLAR_OFF_SELECTED_INCLUDES_ALCOHOL_ITEMS].map(low)
    //set automatically for Join, Renew and Upgrade templates.Ops can also set and change it
    const PURPOSE_MEMBERSHIP_TEMPLATES = [OfferTypes.JOIN_MEMBERSHIP,OfferTypes.RENEWAL_MEMBERSHIP,OfferTypes.UPGRADE_MEMBERSHIP].map(low)

    const isPurposeLabel = (label)=>{
      if(label.indexOf("purpose_")>=0){
        return true
      }else{
        return false
      }
    }

    const hasPurposeLabel = (uSet)=>{
      let hasAnyPurposeLabels = false;
      uSet.forEach((v) => {
        if (isPurposeLabel(v)) {
          hasAnyPurposeLabels = true;
        }
      });
      return hasAnyPurposeLabels
    }

    let existingValues = serverValues
    existingValues = (typeof existingValues === 'string' || existingValues instanceof String)?existingValues: ( existingValues && existingValues.csv && existingValues.csv!==''? existingValues.csv :'')


    const uniqueLabels = new Set();

    if(existingValues!==''){
      existingValues.split(',').forEach(v=>uniqueLabels.add(v))
    }

    if (hasPurposeLabel(uniqueLabels) === false) {
      if (PURPOSE_MARKETTING_TEMPLATES.includes(low(templateType))) {
        uniqueLabels.add(OfferLabels.PURPOSE_MARKETING);
      }
      if (PURPOSE_MEMBERSHIP_TEMPLATES.includes(low(templateType))) {
        uniqueLabels.add(OfferLabels.PURPOSE_MEMBERSHIP);
      }
      if (PURPOSE_MERCH_AND_NON_SUPPLIER_TEMPLATES.includes(low(templateType))) {
        if(owners && Array.isArray(owners) && owners.length>0 || parseFloat(vendorFundingPercent) > 0.00){
          uniqueLabels.add(OfferLabels.PURPOSE_SUPPLIER)
        }else{
          uniqueLabels.add(OfferLabels.PURPOSE_MERCH)
        }
      }
    }

    return {csv:[...uniqueLabels].join(',')}

  },
  /**
   *Provides the Preview of an Image based on the URL provided
   * @param {*} imageUrl - URL for Image
   * @param {*} altText - Alternate Text
   * @returns - an Object
   */
  imagePreview: (imageUrl, altText) => {
    return {
      imageUrl:imageUrl,
      altText:altText
    }
  },
  /**
   *Provides the Preview of an message based on the fields provided
   * @param {*} title - Slot information
   * @param {*} appText - Application text in case of mobile
   * @param {*} htmlText - HTML text in case of desktop
   * @param {*} ctaUrl - CTA url for image
   * @param {*} ctaUrlText - CTA url alt text for image
   * @param {*} imageUrl - Image URL
   * @param {*} endDate - Offer end date
   * @returns - an Object
   */
  messagePreview: (title,appText,htmlText,ctaUrl,ctaUrlText,imageUrl,endDate,offerDetailsPlain,offerDetailsHtml) => {
    return {
      title:title,
      appText:appText,
      htmlText:htmlText,
      ctaUrl:ctaUrl,
      ctaUrlText:ctaUrlText,
      imageUrl:imageUrl,
      endDate:endDate,
      offerDetailsPlain:offerDetailsPlain,
      offerDetailsHtml:offerDetailsHtml
    }
  },
  /**
   * transforms the current field value based on input provided

  * @currentValue {string} current Field value(can take also other field value)
  * @operator {object} type of comparison to be performed with other values(e.g 'equal','greaterThan','smallerThan')
  * @newValue {string} the new value for the current field value
  * @valueRange {array} range of values to which the current value will be compaired
  * @example function:"value=transform(limits.membership_redemption_limit,'equal','UNLIMITED',0,'')"
  */
  transform: (currentValue, operator, newValue, ...valueRange) => {
    const checkValues = compareValues(operator, valueRange, currentValue)
    return checkValues ? newValue : currentValue
  },
  /**
   * To be used with transform function. It provides a way to change other field value based on current field value.
  * @state {Object} full group data
  * @value {string | any } vaue of the field where the fucntion exists(e.g order_redemption_limit value "5" )
  * @currentValue {string | any} value of the current field which you want to change (e.g
  *     membership_redemption_limit.value "0" )
  * @operator {string } type of comparison to be performed with other values(e.g 'equal','greaterThan','smallerThan')
  *     (e.g 'smallerThan' )
  * @newValue {string} the revised value for the current field (e.g "6")
  * @values {array } range of values to which the current value will be compaired (if the parent field value exists it
  *     will consider it as the valueRange otherwise the last comma separated values of arguments, here it is [5])
  * @example function:"value=transform(limits.order_redemption_limit,'equal','5','0','')~%value=transformOther(limits.membership_redemption_limit.value,'smallerThan','6')"
  *     Here the membership redemption value can be changed based on order_redemption_limit value
  *
  */
  transformOther: (state, parentTemplateData={}, { value, group }, currentValue, operator, newValue, ...values) => {
    const changedValue = newValue.replace(/['"]+/g, '');
    const valueRange = values.length ? values : [...value];
    const changedValueRange = valueRange.map(i => i.replace(/['"]+/g, ''))
    const newState = { ...state };
    const isGroupDataPresents = Object.keys(newState).length > 0;
    if (isGroupDataPresents) {
      const [groupName, fieldName, fieldKey] = currentValue.split('.');
      const newField = getFieldData(newState, { fieldName, groupName, groupIndex: group?.groupIndex });
      const currentFieldValue = newField[fieldKey];
      const checkValues = compareValues(operator, changedValueRange, currentFieldValue)
      newField[fieldKey] =  checkValues ? changedValue : currentFieldValue;
      return newState;
    }
  },

  callGetItem: async (type, id) => {
    if (type && id) {
      const url = (type.toUpperCase() === SearchTypes.COUPON) ? `${baseURL}${type}/${SearchTypes.COUPONGROUP.toLowerCase()}/${id}` : `${baseURL}${type}/${id}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: baseHeaders()
      });
      const jsonRes = await response.json();
      if (jsonRes) {
        return {
          ...jsonRes,
          ...(jsonRes.values || {})
        };
      }
      return null;
    }
    return null;
  },
  diff: (val1, val2, type, val3, val4) => {
    if (type && up(type) === FieldTypes.DATE) {
      const startTime = moment(val2 +' '+ val4, 'YYYY/MM/DD HH:mm:ss');
      const endTime = moment(val1 +' '+ val3, 'YYYY/MM/DD HH:mm:ss');
      const days = Math.ceil(endTime.diff(startTime, 'days', true));
      return (isNaN(days) ? '--' : (days + (days > 1 ? ' days' : ' day')));
    } else {
      return val1 - val2;
    }
  },
  sum: (type, val1, val2) => {
    if (up(type) === FieldTypes.MIX) {
      const newVal = {};
      // merge based on val1
      const uniqueKeys = [...new Set([...Object.keys(val1), ...Object.keys(val2)])];
      for (const key of uniqueKeys) {
        const fVal1 = val1[key];
        const fVal2 = val2[key];
        if (fVal1 || fVal2) {
          newVal[key] = (fVal1 ? fVal1 : '') + (fVal2 ? `${fVal1 ? ',' : ''}${fVal2}` : '');
        } else {
          newVal[key] = null;
        }
      }
      return newVal;
    }
    return val1 + val2;
  },
  max: (type, ...nums) => {
    if (nums.length === 0) {
      return null;
    }
    return Math.max(...nums);
  },
  copy: (type, val) => {
    if (!val) {
      return null;
    }
    return val;
  },

  /**This function checks the current field value with other field values,
   * and returns true if the current field is not filled but other field values are present,
   * returns false if all the values are empty
   */
  checkEmptyGroupValue: (...fieldValues) => {

    const [currentFieldValue, ...otherFieldValues] = fieldValues
    // Count zero as truthy value also
    const othersFilled = otherFieldValues.some((value) => (value !== null && value !== undefined && value !== ""))

    const ifCurrentValueFilled = (currentFieldValue !== null && currentFieldValue !== undefined && currentFieldValue !== "");

    return !!(!ifCurrentValueFilled && othersFilled);
  },
  /**
   * @value {object} {biglist, fullValue, uuid}
   * @field {object} Field from template
   * @key {string} Field which triggers the function
   * @fields {enum} Fields that the function is triggered on
   * @example function:
   *     "%value=setOthers(value,parentValue,club_override.club_override_list,club_assignment.exclusion_list)"
   */
  setOthers: (state, parentTemplateData={}, { value, field, group: fieldGroup }, type, key, ...fields) => {
    const newValue = parseValueFromType(type, value, field, {...state,...parentTemplateData});
    const newState = { ...state };
    // find the field type
    const fieldType = getDeepValue(field, ['metadata', 'type']);
    // find the biglist name
    const biglist = getDeepValue(newValue, ['biglist']);
    // is list in edit mode
    const editList = getDeepValue(newValue, ['editList']);
    for (const element of fields) {
      //splits into group name and field name inisde the group
      const [groupName, fieldName] = element.split('.');
      let clearValue = false;
      const group = getDeepValue(newState, [groupName]);
      const newField = getDeepValue(newState, [groupName, 'fields', fieldGroup?.groupIndex || 0, fieldName]) || getDeepValue(state, [groupName, 'fields', fieldName]);

      if(!type.includes("clearValue")) {
        if (up(key) === up('excludeClubs')) {
          clearValue = true;
        }
        if (newField && newValue !== "Not Set") {
          let currentFieldValue;
          let customReset;
          // gets the csv list of all the parent values
          const oldCsv = getDeepValue(newField, [key, 'csv'])
          // build values for the current template field from the parent value
          if (key === 'parentValue' && fieldType === FieldTypes.MIX && biglist && editList && oldCsv) {
            const {addList = [], removeList = []} = getBiglistEditValues(editList);
            const csv = oldCsv.split(',').filter((val) => !(removeList.includes(val) || addList.includes(val))).concat(addList).join(',');
            newField[key] = {csv};
          } else {
            if (group?.metadata?.groupConditions?.customRequiredCondition) {
              const functionValuesArray = getFunctionValues(group.metadata.groupConditions.customRequiredCondition);
              //here we filters out current field being checked in the array and send other field values as params
              const params = functionValuesArray[0].params.filter((fieldProp) => fieldProp.split(".").indexOf(newField.metadata.name) == -1)
              const funcName = functionValuesArray[0].funcName
              //If the filtered params contains the field on which onChange is fired , we send current input as opposed to the intial field value
              const funcParams = params.reduce((finalValue, param) => {
                if (param.split(".").indexOf(field.metadata.name) == -1) {
                  finalValue = [...finalValue, ...parseFunctionParams({
                    params: [param],
                    fullData: {...state},
                    groupName,
                    field
                  })];
                } else {
                  finalValue = [...finalValue, value]
                }
                return finalValue
              }, [])
              currentFieldValue = newField.metadata.name === field.metadata.name ? value : newField.value
              const result = functions[funcName] && functions[funcName](currentFieldValue, ...funcParams);
              customReset = result !== !!(newField.required)
              newField[key] = result;
              const newError = updateGroupRequiredMissing(newState, {
                group,
                field: newField,
                value: currentFieldValue,
                error: newField.error,
                resetRequired: (customReset && !newField.required)
              })
              newField.error = newError

            } else if (key === 'required') {
              newField[key] = newValue;
              if (newField.metadata) {
                newField.metadata[key] = newValue;
              }
              const newError = updateGroupRequiredMissing(newState, {
                group,
                field: newField,
                currentGroupIndex: fieldGroup?.groupIndex || 0,
                value: newField.value,
                resetRequired: !newValue
              })
              newField.error = newError;

            } else {
              newField[key] = newValue;
            }

          }
          if (clearValue) {
            field.value = '';
          }
        }
      } else {
        newField.value = newValue === "clear" ? '' : newField.value;
        const newError = updateGroupRequiredMissing(newState, {
          group,
          field: newField,
          currentGroupIndex: fieldGroup?.groupIndex || 0,
          value: newField.value,
          resetRequired: !newField.value,
          key: 'value'
        })
        newField.error = newError;
      }
    }
    return newState;
  },

  setOptions: (state, parentTemplateData={}, value, array, path, ...fields) => {
    if (!value || !array) {
      return state;
    }
    const arr = getDeepValue(value, [array]);
    const newOptions = arr ? arr.reduce((acc, arrVal) => {
      const pathArr = path.split('.');
      const val = getDeepValue(arrVal, pathArr);
      if (!val) {
        return acc;
      }
      return { ...acc, [val]: val }
    }, {}) : {};
    let newState = { ...state };
    for (let i = 0; i < fields.length; i++) {
      const [groupName, fieldName] = fields[i].split('.');
      const newOptionKeys = Object.keys(newOptions);
      const emptyNewOption = newOptionKeys.length < 1;
      const setDefault = emptyNewOption || newOptionKeys.length === 1;
      const defaultOption = emptyNewOption || !setDefault ? null : newOptions[newOptionKeys[0]];
      const group = getDeepValue(state, [groupName]);
      const isSpecial = TemplateSpecialGroupType.includes(group.metadata.type);
      const field = getDeepValue(group, ['fields', ...(isSpecial ? [0] : []), fieldName]);
      if (isSpecial) {
        newState[groupName].fields[0][fieldName].options = emptyNewOption ? null : newOptions;
      } else {
        newState[groupName].fields[fieldName].options = emptyNewOption ? null : newOptions;
      }
      if (setDefault) {
        newState = changeFieldData(newState, { field, group, value: defaultOption });
      }
    }
    return newState;
  },
  setFuncValues: (state, parentTemplateData={}, type, key, ...fields) => {
    const newState = { ...state };
    for (let i = 0; i < fields.length; i++) {
      const [groupName, fieldName] = fields[i].split('.');
      if (getDeepValue(state, [groupName, 'fields', 0, fieldName])) {
        const field = newState[groupName].fields[0][fieldName];
        let fromFunc;
        if (field && field.functionProps && field.fromFunc) {
          const index = field.functionProps.index;
          fromFunc = field.fromFunc[index];
        }
        const funcValue = parseValueFromType(type, fromFunc);
        newState[groupName].fields[0][fieldName][key] = funcValue;
      } else if (getDeepValue(state, [groupName, 'fields', fieldName])) {
        const field = newState[groupName].fields[fieldName];
        let fromFunc;
        if (field && field.functionProps && field.fromFunc) {
          const index = field.functionProps.index;
          fromFunc = field.fromFunc[index];
        }
        const funcValue = parseValueFromType(type, fromFunc);
        newState[groupName].fields[fieldName][key] = funcValue;
      }
    }
    return newState;
  },
  setFromUser: (roleMatch, userRole, fieldValue, serverValue) => {
    if (serverValue) {
      return serverValue;
    }
    if (roleMatch.includes(userRole)) {
      return fieldValue;
    }
    return null;
  },
  empty: (value) => {
    return !value;
  },
  // function which checks if value exists
  exists: (value) => {
    return !!value;
  },
  dateFormat: (dateStr) => {
    return dateFormat(dateStr);
  },
  /**
   * To return value from Object
   * @param {string} value - Selected field value
   * @param {object} values - Fields values of type ENUM
   * @returns Value from Object mapping
   * Example - "function": "value=getValueFromObj(gift_with_purchase_test, @metadata.values)"
   * gift_with_purchase_test is field name from which value should be taken
   * @metadata.values is the values object of the current field
   */
  getValueFromObj: (value, values) => {
    return values ? values[value] : '';
  },
  /**
   * provides functionality to check wheather a given date exists between startDate and endDate

  * @orgsValue {array} the userOrgs(speicific for supplier case only)
  * @startDate {object} startDate
  * @startTime {string} startTime
  * @endDate {array}  endDate
  * @endTime {string} endTime
  * @operator {string} type of comparison to be performed with other values(e.g 'equal','greaterThan','smallerThan')
  * @currDate {string} the date provided , otherwise it takes the currentDate as default value
  */
  dateRange: (orgsValue, startDate, startTime, endDate, endTime,currDate = new Date()) => {
    if (!functions.empty(orgsValue)) {
      const currentDate = new Date(currDate).getTime();
      const dateStarted = new Date(`${startDate} ${startTime}`).getTime();
      const dateEnded = new Date(`${endDate} ${endTime}`).getTime();
      return compareValues('between', [dateStarted, dateEnded], currentDate);
    }
    return false;
  },

  /**
 * concatenate - Function which returns stringified JSON of field's key and value pairs
 * Example - "value=concatenate('text', htmlText:index, 'price_to_show', price_to_show:index, 'hide_add_to_cart',
 * hide_add_to_cart:index, 'show_offer_countdown_timer', show_offer_countdown_timer:index)"
 * @param {string} fieldsWithValues 'price_to_show', 'ListPrice', 'hide_add_to_cart', true
 * @returns "{\"price_to_show\":\"ListPrice\",\"hide_add_to_cart\":true}"
 *
 * This function creates JSON with field name and its value and returns the stringified JSON.
 */
  concatenate: (...fieldsWithValues) => {
    const result = {}
    let currentIndex = 0;
    while(currentIndex < fieldsWithValues.length) {
      if(fieldsWithValues[currentIndex+1]) {
        result[fieldsWithValues[currentIndex]] = fieldsWithValues[currentIndex+1]
      }
      currentIndex = currentIndex + 2
    }

    return JSON.stringify(result);
  },
  /**
    * This function returns customized display string for End date
    * @endDate {string} - End date
    * @endTime {string} - End time
    * @displayString {string} - Existing value of display string
    * @isCustomized {string} - Value of customized checkbox
    * @returns - Value of End date display string
  */
  endDateDisplayString: (endDate, endTime, displayString, isCustomized) => {
    if (!endDate || !endTime){
      return "";
    }
    const time = new Date(`${endDate} ${endTime}`).getTime();
    const retString =  "Ends " + moment(time).format('MMM DD');
    if(isCustomized === 'true' || isCustomized === true){
      //customized checkbox is clicked and existing display string is not matching calculated return string. so return existing display string
      if(displayString !== retString){
        return displayString
      }
    }
    return retString
  },
  /**
    * This function returns customized display string for End date
    * @endDate {string} - End date
    * @endTime {string} - End time
    * @campaign - Value of campaign object
    * @end_date_display_string - {string} - new value of display string which can be overridden at promo over ride section
    * @customize_end_date_display_string {string} - Value of customized checkbox
    * @returns - Value of End date display string
  */
  overrideEndDateDisplayString: (endDate, endTime, campaign, end_date_display_string, customize_end_date_display_string) => {
    if (campaign) {
      if (customize_end_date_display_string === true || customize_end_date_display_string === 'true' || (customize_end_date_display_string === '' && (campaign.customize_end_date_display_string === true || campaign.customize_end_date_display_string === 'true'))) {
        if (end_date_display_string === null || end_date_display_string === undefined) {
          return campaign.end_date_display_string;
        }
        return end_date_display_string;
      } else {
        const time = new Date(`${endDate} ${endTime}`).getTime();
        const retString = "Ends " + moment(time).format('MMM DD');
        return retString
      }
    } else {
      return end_date_display_string;
    }
  },
  /**
    * This function returns customized display string for limits. for e.g "Limit x" - where x is minimum of all input limit values.
    * @isCustomized {string} - value of customized check box
    * @displayString {string} - Existing value of display string
    * @values {array} - Array of the Input Limits
    * @returns - Value of Limit display string
  */
  limitDisplayString: (isCustomized, displayString, ...values) => {
    //get array of non empty integer values which are greater than zero
    const nonEmptyArr = values.map(a => +a).filter(a => a > 0);
    //get the return string based on the minimum from array
    const retString = nonEmptyArr.length === 0 ? '' : 'Limit ' + Math.min(...nonEmptyArr)
    if(isCustomized === 'true' && displayString !== retString){
      //customized checkbox is clicked and existing display string is not matching calculated return string. so return existing display string
      return displayString
    }
    return retString
  },

  setEnumChildren: setEnumChildren,
  setEnumParent: setEnumParent,
  checkRole: (userRoleObj, ...roles) => {
    const roleName = userRoleObj && userRoleObj.name;
    return roles.includes(roleName);
  },
  /** Check if the current template is present in the restricted template list
   * and take action accordingly
   */
  restrictTemplate: (currentTemplate, ...restrictedTemplates) => {
    return restrictedTemplates.includes(currentTemplate);
  },

  readAbleGroup: (userRoleObj, stateData) => {
    const isDiffRole = isDiffUser(userRoleObj, stateData);
    return isDiffRole;
  },
  /**
   * Function which returns boolean by comparing one field's value with required value
   * @param {*} fieldValue - value of any field
   * @param {*} conditionValue - value to be compared with fieldValue
   * @param {*} serverValue - server value
   * @returns boolean
   *
   * Example - "value=getBooleanOnCondition(assignment.assignment_type,'PACKAGE',@value)"
   */
  getBooleanOnCondition: (fieldValue, conditionValue, serverValue) => {
    if(serverValue || trueValues.indexOf(serverValue) !== -1) {
      return serverValue;
    }
    return fieldValue === conditionValue;
  },
  convertToUpperCase: (fieldValue) => {
    if (fieldValue && fieldValue.length > 0) {
      fieldValue = fieldValue.toUpperCase();
    }
    return fieldValue;
  },

  checkMinimumRestriction: (fieldValue, restricedValue) => {
    if (isNaN(+fieldValue) && fieldValue.length < +restricedValue) {
      return  TemplateErrors.MINIMUM_VALUE_STRING_TYPE(restricedValue);
    } else if (!isNaN(+fieldValue) && +fieldValue < +restricedValue) {
      return TemplateErrors.MINIMUM_VALUE_NUMBER_TYPE(restricedValue);
    } else {
      return ""
    }
  },

  checkMaximumRestriction: (fieldValue, restricedValue) => {
    if (isNaN(+fieldValue) && fieldValue.length >= +restricedValue) {
      return TemplateErrors.MAXIMUM_VALUE_STRING_TYPE(restricedValue);
    } else if (!isNaN(+fieldValue) && +fieldValue >= +restricedValue) {
      return TemplateErrors.MAXIMUM_VALUE_NUMBER_TYPE(restricedValue);
    } else {
      return ""
    }

  },
  /**
   *
   * @param {*} fieldValue (current field value)
   * @param {*} restricedValue (value to be compared with)
   * @param {*} restrictionCondition ("minimum", "maximum" or nothing, if 'minimum' is there as restrictionCondition it would only check if the field value is lesser than restricted value
   * similar check will be there  for 'maximum' argument , if no restrictionCondition it would compare if the given is less than or greater than restricted value)
   * @returns boolean
   */
  restrictSetValue: (fieldValue, restrictionCondition, ...restricedValue) => {

    let evaluatedValue = restricedValue;
    if (checkNonZeroArray(restricedValue)) {
      evaluatedValue = restricedValue.reduce((value1, value2) => value1 || value2)
    }

    if (fieldValue && evaluatedValue) {
      if (restrictionCondition === 'minimum') {
        return functions.checkMinimumRestriction(fieldValue, evaluatedValue)
      } else if (restrictionCondition === 'maximum') {
        return functions.checkMaximumRestriction(fieldValue, evaluatedValue)
      } else {
        if (!functions.checkMinimumRestriction(fieldValue, evaluatedValue)) {
          return functions.checkMaximumRestriction(fieldValue, evaluatedValue);
        } else {
          return functions.checkMinimumRestriction(fieldValue, evaluatedValue)
        }
      }
    }
    return "";
  },

  /**
   * Function which validates a range for a check's routing number
   * @param {string} routingNumber - value of the check's routing number range
   * @returns boolean
   *
   * Example - "errorCondition": "validateRoutingNumber(routing_num_end)"
   */
  validateRoutingNumber: (routingNumber) => {
    if ((!isNaN(+routingNumber) && routingNumber === "") || routingNumber.length === 9) { // input length check
      // Check digit validation
      if (routingNumber === "" || (3*((+routingNumber[0]) + (+routingNumber[3]) + (+routingNumber[6])) + 7*((+routingNumber[1]) + (+routingNumber[4]) + (+routingNumber[7]))) % 10 === 0){
        return;
      }
    }
    return TemplateErrors.INVALID_ROUTING_NUMBER;
  },
  /**
   * Function which validates a range for a card's BIN
   * @param {string} cardType - type of card (i.e. VISA, MASTERCARD, etc.)
   * @param {string} bin - start value of the card's BIN range
   * @returns boolean
   *
   * Example - "errorCondition": "validateBin(payment_method_options, bin_num_end)"
   */
  validateBin: (cardType, bin) => {
    if(cardType !== "" && !isNaN(+bin)){
      if(bin !== ""){
        if (bin.length === 4 || bin.length === 6){
          switch (cardType){
            case BinCardTypes.VISA:
              if(+bin[0] !== 4){
                return TemplateErrors.INVALID_BIN_PREFIX_VISA
              }
              break;
            case BinCardTypes.AMERICAN_EXPRESS:
              if(+bin[0] !== 3){
                return TemplateErrors.INVALID_BIN_PREFIX_AMERICAN_EXPRESS
              }
              break;
            case BinCardTypes.DISCOVER:
              if(+bin[0] !== 6){
                return TemplateErrors.INVALID_BIN_PREFIX_DISCOVER
              }
              break;
            case BinCardTypes.MASTERCARD:
              if(+bin[0] !== 5){
                return TemplateErrors.INVALID_BIN_PREFIX_MASTERCARD
              }
              break;
            default:
              return;
          }
        } else {
          return TemplateErrors.INVALID_BIN_LENGTH
        }
      } else{
        return;
      }
    } else if (cardType === "" && bin === ""){
      return;
    } else {
      return TemplateErrors.INVALID_BIN_LENGTH
    }
  },
  /**
     * Function which validates a Non ASCII or Non printing Characters
     * @param {string} value - HTML/Plain text content
     * @returns string
     *
     * Example - "errorCondition": "validateNonAscii(customAward.appText)"
     */
  validateNonAscii: (value) => {
    if(value !== "" || value !== undefined){
      const regexForASCII = /[^\x20-\x7E]/g;
      const valid = value.match(regexForASCII);
      if (!valid || valid == null || valid == undefined) {
        return "";
      }
      return TemplateErrors.INVALID_NON_ASCII;
    }
  },
  /**
   * Function which tells whether or not an offer is split-funded.
   * @param {string} vendorFundingPercent - value of the vendor funding percentage
   * @param {string} memberFundingPercent - value of the member funding percentage
   * @returns boolean
   *
   * Example - "function": "readOnly=notSplitFunded(financial.vendor_funding_percent,
   * financial.member_funding_percent)~value=resetSplitFunded(financial.offer_split_funded,
   * financial.vendor_funding_percent, financial.member_funding_percent)"
   */
  notSplitFunded: (vendorFundingPercent, memberFundingPercent) => {
    if (vendorFundingPercent === "0.00" && memberFundingPercent === "0.00") {
      return false;
    }
    return true;
  },

  /**
   * Function which resets the split-funded flag/checkbox, IF NEEDED.
   * @param {string} currentValue - value of the offer_split_funded field
   * @param {string} vendorFundingPercent - value of the vendor funding percentage
   * @param {string} memberFundingPercent - value of the member funding percentage
   * @returns {string || boolean}
   *
   * Example - "function": "readOnly=notSplitFunded(financial.vendor_funding_percent,
   * financial.member_funding_percent)~value=resetSplitFunded(financial.offer_split_funded,
   * financial.vendor_funding_percent, financial.member_funding_percent)"
   */
  resetSplitFunded: (currentValue, vendorFundingPercent, memberFundingPercent) => {
    if (vendorFundingPercent === "0.00" && memberFundingPercent === "0.00") {
      if (currentValue !== ""){
        return evaluateValue(currentValue);
      }
      return false;
    }
    return "";
  },

  extractChildTemplateValue: (value, childFieldName) => {
    if (value && Object.keys(value)) {
      return value[childFieldName]
    }
  },

  /**
   * @state {object} template reducer state
   * @field {object} current field
   * @value {string} current field value
   * @dateField {string} path of date field
   * @example function:
   *     "%value=addToDate(assignment.end_date,workflowItem.templateId,0a78291d-ce54-447d-93d8-66613d48b7b1)"
   */
  addToDate: (state, parentTemplateData={}, { field, value, group }, dateField, compareValue, ...templateTypes) => {
    const newState = { ...state };

    compareValue = getDeepValue(state, compareValue.split('.'));

    // if template is a redemption template
    if (!compareValue || !templateTypes.includes(compareValue)) {
      return state;
    }

    // get date field
    const [groupName, fieldName] = dateField.split('.');
    dateField = getDeepValue(newState, [groupName, 'fields', group?.groupIndex || 0, fieldName]) || getDeepValue(state, [groupName, 'fields', fieldName]);
    if (!dateField || !value) {
      return state;
    }

    // get durations serverValue
    const serverValue = getDeepValue(field, ['serverValue']);

    //get actual campaign values
    const campaign = getDeepValue(newState, [groupName, 'fields', "campaign_id"])?.value;
    const campaignAssignmentData = campaign?.assignment_data;
    const packageAssignment = Array.isArray(campaignAssignmentData?.package_assignment) ? campaignAssignmentData.package_assignment[0] : null;
    const triggerAssignment = Array.isArray(campaignAssignmentData?.trigger_assignment) ? campaignAssignmentData.trigger_assignment[0] : null;
    const campaignCouponDuration = packageAssignment?.coupon_duration ?? triggerAssignment?.coupon_duration ?? null;
    const lastPackageDurationVal = getDeepValue(field, ['value']);
    const overrideCampaign = getDeepValue(newState, [groupName, 'fields', "override_campaign"])?.value;

    if ((!dateField.serverValue && dateField.value)) { // If there is no server value, this is our first time doing the math
      const dateValue = moment(dateField.value).add(value, 'days').format(DateFormat.DATE);
      dateField.value = dateValue;
      dateField.serverValue = dateValue; //save as server value
    } else if (dateField.serverValue) { //
      if(campaign && (!overrideCampaign && moment(campaign.end_date).format(DateFormat.DATE) === moment(dateField.value).format(DateFormat.DATE)) || +campaignCouponDuration !== +value || (overrideCampaign && +lastPackageDurationVal !== +campaignCouponDuration)) {
        dateField.value = moment(dateField.serverValue)
          .subtract(serverValue || 0, 'days')
          .add(value, 'days')
          .format(DateFormat.DATE);
      }
    }

    return newState;
  },
  // For enum values, copy values that are unselected
  // If value has a |pipe| then treat it as a separate value
  // Used for Join Membership membership type selector
  unselected: (value, values) => {
    if (!values) {
      return value;
    }
    if (!value) {
      value = ''
    }
    let selectedValues = []
    const valueSplit = value.split(',');
    valueSplit.forEach((value) => {
      value.split('|').forEach((val) => {
        selectedValues.push(val)
      })
    })
    selectedValues = [...new Set(selectedValues)];
    const unselectedValues = [];
    for (const valueKey in values) {
      if (valueKey) {
        const keySplit = valueKey.split('|')
        keySplit.forEach((valueKey) => {
          if (!selectedValues.includes(valueKey)) {
            if (valueKey) {
              unselectedValues.push(valueKey)
            }
          }
        })
      }
    }

    return [...new Set(unselectedValues)].join(',')
  },
  // For enum values, copy values that are selected
  // If value has a |pipe| then we copy the postion from the pipe
  // Used for Join Membership membership type selector
  selected: (value, pos = 0) => {
    if (!value) {
      return value
    }

    const values = value.split(',').map((value) => {
      if (!value) {
        return value;
      }
      return value.split('|')[+pos]
    }).filter((value) => value)

    return [...new Set(values)].join(',')
  },

  /**
    Evaluates if min value is lesser than the max value
  **/

  evaluateMinMax: (minValue, operator, maxValue, trueValue, falseValue) => {
    return ((minValue && maxValue) && functions.conditionalSet(minValue, operator, maxValue, trueValue, falseValue)) ? TemplateErrors.MIN_VALUE_ERROR() : ""
  },

  /**
    Evaluates if max value is higher than the min value
  **/
  evaluateMaxMin: (minValue, operator, maxValue, trueValue, falseValue) => {
    return ((maxValue && minValue) && functions.conditionalSet(minValue, operator, maxValue, trueValue, falseValue)) ? TemplateErrors.MAX_VALUE_ERROR() : ""
  },

  evaluateOtherFields: (otherFieldValue, desiredValue) => {
    return desiredValue !== otherFieldValue
  },
  /**
    * This function is used to evaluate a conditon and set to a specific value if true
    * @leftValue - left value of the condition
    * @operator - condition operator to evaluate
    * @rightValue - right value of the condition
    * @trueValue - current value of the field
    * @falseValue - the value that is set if the condition is true
    * evaluates this expression
    * (leftValue [operator] rightValue ) ? trueValue : falseValue
  **/
  conditionalSet: (leftValue, operator, rightValue, trueValue, falseValue) => {
    let evaluation;
    leftValue = notNull(leftValue) ? leftValue : '';
    rightValue = notNull(rightValue) ? rightValue : '';
    if (!isNaN(+leftValue) && leftValue !== '') {
      leftValue = +leftValue;
    }
    if (!isNaN(+rightValue) && rightValue !== '') {
      rightValue = +rightValue;
    }
    switch(operator) {
      case('=='):
      case('='):
      // To include leftValue = "", which is the default value if none is set, end the rightValue with a |
      // i.e. hide=conditionalSet(sams_cash_award.sams_cash_funding:index, '==', 'APPEASEMENT|MARKETING|MEMBERSHIP',
      // 'true', 'false') will return true if leftValue is ""
        if (typeof rightValue === 'string' && rightValue.includes("|")){
          const options = rightValue.split("|");
          evaluation = options.includes(leftValue);
        } else{
          evaluation = leftValue === rightValue;
        }
        break;
      case('!='):
        // To include leftValue = "", which is the default value if none is set, end the rightValue with a |
        // i.e. hide=conditionalSet(sams_cash_award.sams_cash_funding:index, '!=', 'MERCHANDISE|PLUS REWARDS', 'true',
        // 'false') will return false if leftValue is ""
        if (typeof rightValue === 'string' && rightValue.includes("|")){
          const options = rightValue.split("|");
          evaluation = !options.includes(leftValue);
        } else {
          evaluation = leftValue != rightValue
        }
        break;
      case('>='):
        evaluation = leftValue >= rightValue
        break;
      case('>'):
        evaluation = leftValue > rightValue
        break;
      case('<='):
        evaluation = leftValue <= rightValue
        break;
      case('<'):
        evaluation = leftValue < rightValue
        break;
    }

    if (evaluation) {
      return evaluateValue(trueValue);
    }

    return evaluateValue(falseValue);
  },
  // exists, but takes in multiple values
  lockCampaign: (assignmentData, clone) => {
    if (!!assignmentData && !clone) {
      return true
    }
    return false;
  },

  /**
   * Retrieves the list of departments and creates a map of department values (i.e. department numbers) to labels.
   * @returns {Object} - A map of department values to labels.
   * @example "function" : "!values=getDepartments()"
   */
  async getDepartments() {
    const departmentMap = {};
    departmentMap[0] = " GET FROM ITEM";
    const departments = await fetchCategories([], () => {
    })();
    for (const department of departments) {
      departmentMap[department.value] = `${department.label} (${department.value})`;
    }
    return departmentMap;
  },

  /**
   * Retrieves the list of email templates and creates a map of email template ids to email template names.
   * @returns {Object} - A map of email template ids to email template names.
   * @example "function" : "!values=getEmailTemplates()"
   */
  async getEmailTemplates() {
    const emailTemplateMap = {};
    try{
      const emailTemplates = await SearchService({
        "type": low(SearchTypes.EMAILTEMPLATE),
        "searchOptions": {
          "facets": null,
          "queryType": "full",
          "filter": "awardType eq 'samsCash'"
        },
        "searchParams":{
          "orderBy": "name asc",
        }
      });
      for (const emailTemplate of emailTemplates.value) {
        emailTemplateMap[emailTemplate.extId] = emailTemplate.name;
      }

      return emailTemplateMap;
    } catch{
      return emailTemplateMap
    }

  }
}

export function evaluateValue(value){
  if ( value === "true") {
    return true;
  } else if (value === "false"){
    return false
  } else{
    return value;
  }
}

export function parseValueFromType(type, value, field, state) {
  if (type.includes('===')) { //when type is value===CLUB
    const [operation, typeValue] = type.split('===');
    const fieldType = getDeepValue(field, ['metadata', 'type']);
    // i.e. "function": "%value=setOthers(clearValue===APPEASEMENT|MARKETING|MEMBERSHIP,value,sams_cash_award.department)"
    if (operation === "clearValue"){
      let evaluation;
      if (typeValue.includes('|')){
        const values = typeValue.split("|");
        evaluation = values.includes(value);
      } else{
        evaluation = value.toString() === typeValue;
      }
      return evaluation ? 'clear' : 'do not clear';
    }
    if (fieldType === FieldTypes.ENUM) {
      if (value === 'ALL') {
        return true;
      }
      if (!value || !typeValue) {
        return false
      }
      const values = value.split(',');
      const matches = typeValue.split(' ').filter((val) => {
        return values.includes(val)
      })
      return matches.length > 0;
    }
    if (operation === "selectedValue" && value) {
      return typeValue === value
    }

    return typeValue === value;
  }
  if (type === "copy" && Object.keys(value).length > 0) {
    const parentValue = field?.metadata?.queries?.copyFromField;
    const objectMapper = JSON.parse(parentValue)
    if (objectMapper && objectMapper[value]) {
      const fParam = objectMapper[value]
      const returnValue = parseFunctionParams({ fullData: state, params: [fParam] });
      if (returnValue && Array.isArray(returnValue) && returnValue.length) {
        return returnValue[0]
      } else {
        return "Not Set"
      }
    } else {
      // Not setting null , because sometimes we can set null as a value for some fields
      return "Not Set"
    }

  }

  if (type.includes('!==')) {
    const [operation, typeValue] = type.split('!==');
    // i.e. "function": "%value=setOthers(clearValue===APPEASEMENT|MARKETING|MEMBERSHIP,value,sams_cash_award.department)"
    if (operation === "clearValue"){
      let evaluation;
      if (typeValue.includes('|')){
        const values = typeValue.split("|");
        evaluation = !values.includes(value);
      } else{
        evaluation = value.toString() !== typeValue;
      }
      return evaluation ? 'clear' : 'do not clear';
    }
    return typeValue !== value;
  }
  switch (type) {
    case 'notValue':
      return !value;
    case 'countryBool':
      return low(value) !== 'clubs';
    case 'countryFilter':
      return getDeepValue(field, ['metadata', 'queries', value]) || value;
    case 'boolean':
      return !!value;
    default:
      return value;
  }
}

export function parseFunctionParams({ fullData, params, groupName, field, gIndex }) {
  return params.map((param) => {
    return parseParam({ param, fullData, groupName, field, gIndex })
  });
}

function parseParam({ param: fParam, fullData, groupName, field, gIndex }) {
  const [param, groupIndexFromParam] = fParam.split(':');
  const groupIndex = groupIndexFromParam && groupIndexFromParam === 'index' ? gIndex : groupIndexFromParam
  if (/^'.+'$/.test(param) || param === '\'\'') { // take param as string, or empty string
    let options = [];
    if (param.includes(' | ')) { // when multiple values/constants are separated by a pipe i.e. ('VISA|MASTERCARD')
      options = param.split(' | ');
      for (let i = 0; i < options.length; i++) {
        options[i].replace(/'/g, '');
      }
      return options;
    }
    return param.replace(/'/g, '');
  } else if (param === 'index' && groupIndexFromParam) { // Returns just the index of the group
    return groupIndex;
  } else if (param === 'lastIndex' && groupIndexFromParam) { // Returns last index of group
    const groupData = getGroupData(fullData, { groupName });
    return groupData.fields.length - 1;
  } else if (/^@!/.test(param)) { // take direct field prop from other field
    const prop = param.replace('@', '').replace('!', '');
    const [groupName, fieldName, ...deepProps] = prop.split('.');
    const fieldData = getFieldData(fullData, { fieldName, groupName, groupIndex });
    return getDeepValue(fieldData, deepProps);
  } else if (/^@/.test(param)) { // take field prop
    const prop = param.replace('@', '');
    const arrProp = prop.split('.');
    return getDeepValue(field, arrProp);
  } else if (/^!/.test(param)) { // take direct path
    const prop = param.replace('!', '').split('|')
    const value = getDeepValue(fullData, prop[0].toString().split('.'));
    return (Array.isArray(value) && prop[1]) ? concatValuesToMap(value, prop[1]) : value
  } else if (param.includes('.')) { // take param from different group
    const [groupName, fieldName, fieldProp] = param.split('.');
    const fieldData = getFieldData(fullData, { fieldName, groupName, groupIndex });
    const val = fieldData ? fieldData.value : '';
    const deepField = fieldProp ? fieldProp.split('.') : null;
    return deepField && val ? getDeepValue(val, deepField) : val;
  } else { // take param from same group
    const fieldData = getFieldData(fullData, { fieldName: param, groupName, groupIndex });
    return fieldData ? fieldData.value : '';
  }
}

function concatValuesToMap(arrOfObj, prop) {
  const resObj = {}
  arrOfObj.forEach(field => {
    resObj[field[prop]] = field[prop]
  })

  return resObj;
}

export function doFunction({ field, group, fullData, functionProps }, valueField = 'value') {
  const { fieldKey, funcName, params, getFromDifferentGroupType, parentGroupSpecial } = functionProps;
  const { name: groupName, type: groupType } = group.metadata;
  const fieldData = { ...field.metadata, ...field };
  const isFunctionProps = valueField === 'functionProps';
  if (!params.length) {
    const [parentGroup, fieldName, fieldProp, ...deepProp] = funcName.split('.');
    const groupDataName = low(groupType);
    const specialGroup = group && TemplateSpecialGroupType.includes(groupType);
    const value = getDeepValue(fullData, [parentGroup, 'fields', fieldName, valueField]);
    // process special group type like assignment_data (e.g. value=assignment.campaign_number.club_assignment.club_assignment_list)
    if (isFunctionProps) {
      return { key: fieldKey, value: handleTypeValue(value, { field, valueField, fieldKey }) };
    } else if (deepProp && deepProp.length) {
      const groupData = getDeepValue(value, [groupDataName]);
      if (specialGroup && field) {
        const deepValue = getDeepValue(groupData, [
          fieldProp,
          group.groupIndex || 0,
          deepProp
        ]);
        const processedServerData = deepValue ? processServerValue({ field: fieldData, value: deepValue, specialGroupData: groupData, groupName }) : deepValue;

        return { key: fieldKey, value: handleTypeValue(processedServerData, { field, valueField, fieldKey }) };
      } else {
        const deepValue = getDeepValue(value, [
          fieldProp,
          deepProp
        ]);
        const processedServerData = deepValue ? processServerValue({ field: fieldData, value: deepValue, specialGroupData: groupData, groupName }) : deepValue;

        return { key: fieldKey, value: handleTypeValue(processedServerData, { field, valueField, fieldKey }) };
      }
    } else {
      let index = group.groupIndex || 0;
      /**
       * If parent group is also special,
       * get from 0th index only and not from target groupIndex.
       */
      if (specialGroup && parentGroupSpecial) {
        index = 0;
      }
      if ((specialGroup && !getFromDifferentGroupType) || (!specialGroup && getFromDifferentGroupType)) {
        const fieldValue = getDeepValue(fullData, [
          parentGroup,
          'fields',
          index,
          fieldName,
          valueField
        ]);

        return { key: fieldKey, value: handleTypeValue(fieldValue, { field, valueField, fieldKey }) };
      } else {
        const fieldValue = fieldProp && value ? value[fieldProp] : value;

        return { key: fieldKey, value: handleTypeValue(fieldValue, { field, valueField, fieldKey }) };
      }
    }
  } else {
    const funcParams = parseFunctionParams({ fullData, params, groupName, field, gIndex: group.groupIndex || 0 });
    if (functions && functions[funcName]) {
      const functionValue = functions[funcName](...funcParams);
      return { key: fieldKey, value: handleTypeValue(functionValue, { field, valueField, fieldKey }) };
    } else {
      return { key: fieldKey, value: handleTypeValue(funcName, { field, valueField, fieldKey }) };
    }
  }
}

// Handle comples types when copying from basic types
// Handle setting default values from string for complex types
// For mix types: "value" will turn into {"csv": "value"}
export function handleTypeValue(value, { field, valueField, fieldKey }) {
  if (valueField !== 'value' || fieldKey !== 'value') {
    return value;
  }
  switch (up(getDeepValue(field, ['metadata', 'type']) || getDeepValue(field, ['type']))) {
    case FieldTypes.MIX:
      if (!value || typeof value !== 'string') {
        return value;
      }
      // We need to parse the string into JSON
      // JSON.parse() will throw an error if we are only passed the value and it is not an object
      // We try catch to handle this case, and store it in csv if it is just a normal string value
      try {
        return JSON.parse(value)
      } catch(e) {
        return { csv: value }
      }
    // Default actual date value to now
    case FieldTypes.DATE:
      if (value === 'Date.now()') {
        return moment().format(DateFormat.DATE)
      }
      return value;
    default:
      return value;
  }
}

export function doChangeFunction({ field, group, fullData, functionProps }) {
  const { fieldKey, funcName, params } = functionProps;
  const { name: groupName } = group.metadata;
  const funcParams = parseFunctionParams({ params, fullData, groupName, field });

  const result = functions[funcName](...funcParams);
  return { key: fieldKey, value: result };
}

function defaultCheck(val, defaultVal) {
  return val === defaultVal ? '' : val
}

/**
 * parseDefaultValueCondition - Function which returns the default value based on the template condition
 * @param {string} defaultValueCondition "owners?100.00:0.00"
 * @param {object} workflowItem {owners: {}}
 * @returns 0 or 100 based on condition
 */
export function parseDefaultValueCondition(defaultValueCondition, workflowItem, indexOfRepeatable, defaultValue) {
  if(defaultValueCondition.indexOf('&&') !== -1) {
    const defaultValuesArr = defaultValueCondition.split('&&');
    return defaultValuesArr[indexOfRepeatable] ? defaultValuesArr[indexOfRepeatable]: defaultValue;
  }
  const condition = defaultValueCondition.split('?')[0];
  const valuesArr = defaultValueCondition.split('?')[1].split(':');
  return workflowItem[condition]
    ? defaultCheck(valuesArr[0], '-1')
    : defaultCheck(valuesArr[1], '-1');
}

export const templateFuncCheck = {
  checkChange: funcString => /^@/.test(funcString),
  checkSetOthers: funcString => /^%/.test(funcString),
  checkSet: funcString => /^[^@%!]/.test(funcString),
  checkSetOnInit: funcString => /^!/.test(funcString)
}
