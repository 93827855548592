import { makeStyles } from '@material-ui/core'

const styles = {
  root: {
    marginLeft: 'auto',
    marginRight: 4,
    width: "50%",
    alignSelf: 'center'
  }
}
export default makeStyles(styles);
