import { makeStyles } from "@material-ui/core/styles";
import Colors from '../../theme/Colors';

const SearchStyles = {
  root: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    // marginBottom: "10px",
    border: `1px solid ${Colors.primaryBlue}`,
    boxShadow: 'none',
    padding: '4px 8px',
    height: '40px'
  },
  input: {
    marginLeft: 8,
    flex: 1,
    opacity: 1,
    marginRight: 8
  },
  searchIcon: {
    color: Colors.grey200
  },
  searchButton: {
  },
  inputField: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export default makeStyles(SearchStyles);
