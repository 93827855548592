import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function generateCouponService(noOfCouponsToBeGenerated, couponGroupName) {
  const body = {}
  try {
    return await fetch(`${baseURL}coupon/coupongroup/${couponGroupName}/couponcode/${noOfCouponsToBeGenerated}/generate`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });
  } catch (error) {
    console.error(error)
    throw error;
  }
}
export default generateCouponService;
