import React from "react";
import { withStyles } from "@material-ui/core";
import styles from './RepeatableHeader.styles';
import ReorderActions from "./ReorderActions";


function RepeatableHeader(props) {
  const { groupData, classes } = props

  function renderReorderActions() {
    return <ReorderActions {...props} />
  }

  if(groupData.metadata.repeatableCondition && !groupData.metadata.limit && groupData.metadata.repeatableLabel) {
    return (
      <div className={classes.repeatableHeader}>
        {groupData.metadata.longDesc}
        {' '}
        {groupData.groupIndex + 1}
        {renderReorderActions()}
      </div>
    )
  }
  return null;
}

export default withStyles(styles)(RepeatableHeader);
