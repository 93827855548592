export default function parseRepeateableRequired(requiredCondition, required, index) {
  if(!requiredCondition) {
    return required;
  }

  if (requiredCondition.indexOf('|') === -1) {
    return eval(requiredCondition);
  }

  const requiredSplit = requiredCondition.split('|');

  return eval(requiredSplit[index]);
}
