import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function fetchAllBiglistValuesService(biglistName, limit = 1000) {
  try {
    let offset, list;
    do {
      const body = {
        offset: offset
      }
      const response = await fetch(`${baseURL}biglist/${biglistName}/search?limit=${limit}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: baseHeaders()
      }).then(function (res) {
        return res.json();
      }).then(function (data) {
        return data;
      });
      list = list ? list.concat(response.items) : response.items;
      offset = response.offset
    } while (offset);

    return list;
  } catch (error) {
    return error;
  }
}

export default fetchAllBiglistValuesService
