import React from 'react';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Info from '@material-ui/icons/Info';
import useStyles from './SearchCard.styles';
import Brand from '../../brand/Brand';
import getCompartmentTypes from '../../../Utils/compartments/getCompartmentTypes';

function SearchCard(props) {
  const classes = useStyles();
  const { item, selected, onClick, onInfoClicked, searchType } = props;
  const { badgeText, foregroundColor, backgroundColor, outlineColor, cornerRadius, name, description, badge } = item;
  return (
    <Paper
      className={clsx(classes.root, { [classes.selected]: selected } )}
      onClick={onClick}
      square
    >
      <div className={classes.topContainer}>
        <div className={classes.textContainer}>
          <Typography className={classes.name} noWrap>
            {name}
          </Typography>
          <div className={classes.descriptionContainer}>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <IconButton className={classes.infoIconButton} onClick={onInfoClicked}>
            <Info className={classes.infoIcon} />
          </IconButton>
        </div>
      </div>
      <div className={classes.bottomContainer}>
        <div className={classes.brandContainer}>
          {searchType === getCompartmentTypes.BRANDING ? (
            <Brand
              badgeText={badgeText}
              foregroundColor={foregroundColor}
              backgroundColor={backgroundColor}
              outlineColor={outlineColor}
              cornerRadius={cornerRadius}
              badge={badge}
              align='left'
            />
          ) : (
            <div />
          )}
        </div>
      </div>
    </Paper>
  );

}


export default SearchCard;
