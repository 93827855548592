import React, { useContext } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Info from '@material-ui/icons/Info';
import useStyles from './SearchCard.styles';
import createPath from '../../../Utils/navigation/createPath';

function CompartmentCard(props) {
  const classes = useStyles();

  const history = useHistory();

  const { item, selected, onInfoClicked, rootType, currentCompartment } = props;
  const { name, description } = item;

  const mainPath = createPath(item, currentCompartment, rootType);

  function handleOnClick(event) {
    navigateTo(mainPath, event);
  }

  function navigateTo(path, event) {
    if (event) {
      event.stopPropagation()
    }

    history.push({
      pathname: path,
      state: { loadingComplete: true, campaignInfo: props.campaignDetails && props.campaignDetails.campaign_detail }
    })

  }

  return (
    <Paper
      className={clsx(classes.root, { [classes.selected]: selected } )}
      onClick={handleOnClick}
      square
    >
      <div className={classes.topContainer}>
        <div className={classes.textContainer}>
          <Typography className={classes.name} noWrap>
            {name}
          </Typography>
          <div className={classes.descriptionContainer}>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          {onInfoClicked ? (
            <IconButton className={classes.infoIconButton} onClick={onInfoClicked}>
              <Info className={classes.infoIcon} />
            </IconButton>
          ) : null}
        </div>
      </div>
    </Paper>
  );

}


export default CompartmentCard;
