import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function bulkAssignCouponService(body) {
  try {
    return await fetch(`${baseURL}coupon/bulkassign`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export default bulkAssignCouponService;
