import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function updatebiglistService(biglistName, items, type = 'update') {
  //type: update || remove
  try {
    const body = {
      biglistName,
      items
    }
    const response = await fetch(`${baseURL}biglist`, {
      method: type === 'update' ? 'PUT' : 'DELETE',
      body: JSON.stringify(body),
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });

    return response;
  } catch (error) {
    return error;
  }
}

export default updatebiglistService
