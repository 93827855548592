import React from 'react';
import ViewOnly from '../viewOnly/ViewOnly';

export default function Diff({mode, children, viewOnlyComponent, enableEditListButton, navigateToSavedLists}) {
  if (mode === 'viewOnly') {
    if (viewOnlyComponent) {
      return viewOnlyComponent;
    } else {
      return (
        <ViewOnly
          enableEditListButton={enableEditListButton}
          navigateToSavedLists={navigateToSavedLists}
          {...children.props}
        />
      );
    }
  }

  return children;
}
