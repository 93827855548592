import { makeStyles } from '@material-ui/core/styles';

const styles = () => ({
  skeletonContainer: {
    width: '100%',
    maxWidth: 1260,
    marginTop: 16,
    padding: 32,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 'auto',
    boxSizing: 'border-box'
  },
  skeletonWrapper: {
    marginTop: 16,
    padding: 4
  },


})
export default makeStyles(styles)
