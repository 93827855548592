import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(theme => ({
  messageArea: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    margin: '0px',
    display: 'flex',
    position: 'relative',
    paddingLeft: '14px'
  },
  backgroundWhite: {
    backgroundColor: '#FFF'
  },
  noMessageArea: {
    position: 'relative',
    margin: '0',
    padding: '0',
  },
  addMessageButton: {
    position: 'absolute',
    right: theme.spacing(),
    bottom: theme.spacing(),
  },
  editMessageButton: {
    position: 'absolute',
    right: theme.spacing(),
    bottom: theme.spacing(),
  },
  collapsed: {
    display: 'block',
    minWidth: '100%',
    maxHeight: '5em',
    overflow: 'hidden',
  },
  expanded: {
    minWidth: '100%',
  },
  collapseButton: {
    margin: 'auto',
    padding: theme.spacing(0.5),
  },
  buttonText: {
    color: theme.palette.common.bloomingtonGrey,
  },
  editorArea: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid rgba(0, 0, 0, 0.38)',
    borderRadius: '2px'
  },
  editorAreaError: {
    border: `1px solid ${Colors.primaryOrange}`,
  },
  editorAreaFocused: {
    border: `1px solid ${Colors.primaryBlue}`,
  },
  cancelButton: {
    textTransform: 'none',
    margin: theme.spacing(0.5),
  },
  clearButton: {
    margin: theme.spacing(),
  },
  saveButton: {
    margin: theme.spacing(),
  },
  buttonArea: {
    margin: '1em 2em',
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '1fr 1fr'
  },
  'public-DraftStyleDefault-block': {
    '& div': {
      margin: '0.5em 0'
    }
  },
  editorLabel:{
    marginLeft: "33px",
    marginTop: "20px"

  },
  editor:{
    height:'225px'

  },
  editorContainer: {
    margin: '2em',
    minWidth: '700px'
  },
  labelStyles: {
    color: 'rgba(36, 37, 61, 0.5)',
    fontSize: '12px'
  },
  labelStylesError: {
    color: Colors.primaryOrange,
    fontSize: '12px'
  },
  mh4: {
    minHeight: '280px',
    marginTop: '24px'
  }
}));

export default useStyles;
