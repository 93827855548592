import { makeStyles } from '@material-ui/core'
import Colors from '../../theme/Colors';

const styles = {
  root: {
    backgroundColor: Colors.subtleBlue,
    color: Colors.primaryBlue,
    margin: 4
  },
  deleteIcon: {
    color: Colors.primaryBlue,
    pointerEvents: 'none',
    '&:hover': {
      color: Colors.primaryBlue
    }
  },
  icon: {
    fontSize: 20,
    color: Colors.primaryBlue,
    cursor: 'pointer',
    height: 'auto',
    margin: '0 -8px 0 5px',
    '-webkit-tap-highlight-color': 'transparent',
    zIndex: 1,
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.4)'
    }
  },
  clickable: {
    '&:hover': {
      backgroundColor: Colors.subtleBlue
    },
    '&:active': {
      backgroundColor: Colors.subtleBlue
    },
    '&:focus': {
      backgroundColor: Colors.subtleBlue
    }
  },
  deletable: {
    '&:focus': {
      backgroundColor: Colors.subtleBlue
    }
  },
  label: {
    zIndex: 0
  },
  labelIconOnly: {
    paddingLeft: 0,
    paddingRight: 0
  },
  iconIconOnly: {
    margin: '0 8px 0 8px'
  },
  disabled: {
    color: Colors.grey900,
    backgroundColor: Colors.grey100,
    '&.Mui-disabled': {
      opacity: 1
    }
  },
  deletableDisabled: {
    '&:focus': {
      backgroundColor: Colors.grey100
    }
  },
  iconDisabled: {
    color: Colors.grey300
  },
  deleteIconDisabled: {
    color: Colors.grey300,
    '&:hover': {
      color: Colors.grey300
    }
  }
}

export default makeStyles(styles);
