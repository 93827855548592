import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../theme/Colors';
import { fontSizeReg, fontSizeSmall } from '../../theme/theme';

const imageCellWidth = 80;

const styles = theme => ({
  root: {
    maxWidth: '100%',
    boxSizing: 'border-box',
    height: '100%',
    borderBottom: `1px solid #f0f0f0`,
    backgroundColor: Colors.backgroundColor,
    transition: 'height 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
  },
  rootPaper: {
    // margin: 35,
    // padding: 20,
    // paddingLeft: 120,
    // paddingRight: 120,
    // backgroundColor: 'white',
    // height: 'auto',
    // borderRadius: 4,
    // boxShadow: theme.shadows[5]
  },
  tableContainer: {
    position: "relative",
    maxWidth: '100%',
    minHeight: 300,
    overflow: "auto",
    transition: 'all 0.2s',
    '& .ReactVirtualized__Table__headerRow': {
      backgroundColor: Colors.primaryBlue

    },
    '& .ReactVirtualized__Table__headerRow > $rowCell': {
      boxShadow: 'inset 1px 0 0 0 rgba(255, 255, 255, 0.3)'
    },
    '& .ReactVirtualized__Table__headerRow > $rowCell:last-child': {
      boxShadow: 'inset 1px 0 0 0 rgba(255, 255, 255, 0.3), inset -1px 0 0 0 rgba(255, 255, 255, 0.3)'
    },
    height: '100%'
  },
  tableContainerMultiSelect: {
    '& .ReactVirtualized__Table__headerRow > $rowCell:nth-child(2)': {
      boxShadow: 'none'
    },
    '& .ReactVirtualized__Table__headerRow > $rowCell:first-child': {
      boxShadow: 'inset 1px 0 0 0 rgba(255, 255, 255, 0.3), inset -1px 0 0 0 rgba(255, 255, 255, 0.3)'
    }
  },
  tableContainerModal: {

  },
  table: {
    // border: `1px solid rgba(224, 224, 224, 1)`,
    // '& .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer': {
    //   borderBottom: `1px solid rgba(224, 224, 224, 1)`
    // },
    // '& .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer > $tableRow:last-child > $rowCell > $tableCell': {
    //   borderBottomWidth: 0
    // }
    '& .ReactVirtualized__Grid__innerScrollContainer': {
      margin: 'auto'
    },
    height: '100%'
  },
  tableModal: {

  },
  tableHead: {
    top: theme.spacing(2),
    overflow: "hidden"
  },

  tableBody: {

  },

  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  deActiveBtn: {
    background: "#eee",
    border: 0,
    borderRadius: 3,
    color: "#555",
    height: 28,
    padding: "0 12px",
    marginLeft: "10px"
  },
  activeBtn: {
    borderRadius: 3,
    border: 0,
    color: "white",
    maxWidth: "60px",
    padding: "3px 10px",
    backgroundColor: "blue",
    textAlign: "center"
  },
  inActiveBtn: {
    borderRadius: 3,
    border: 0,
    color: "white",
    maxWidth: "60px",
    padding: "3px 10px",
    background: "grey",
    textAlign: "center"
  },
  activeSortIcon: {
    opacity: 1,
    color: "#fff!important",
    fontSize: 24
  },
  inactiveSortIcon: {
    opacity: 0.4,
    color: "#fff!important",
    fontSize: 24
  },
  inputGroup: {
    position: "relative",
    display: 'flex',
    justifyContent: 'flex-end'
  },
  searchIcon: {

  },
  searchIconContainer: {
    zIndex: 2
  },
  micIcon: {
    position: "absolute",
    left: "13rem",
    cursor: "pointer",
    top: "8px"
  },
  field: {
    // border: "none",
    // padding: ".6rem 1rem .6rem 2rem",
    // borderRadius: 6,
    // fontSize: "13px",
    // width: "12rem",
    // backgroundColor: "#1565c0"
  },
  disableDeActivatebtn: {
    background: "#555",
    border: 0,
    borderRadius: 3,
    color: "#fff",
    height: 28,
    cursor: "not-allowed",
    padding: "0 12px"
  },
  headerCell: {
    backgroundColor: Colors.primaryBlue,
    color: Colors.white,
    '&:hover $sortIcon': {
      opacity: 1
    },
    '& .Mui-checked': {
      color: Colors.subtleBlue
    },
    '&:focus': {
      outline: 0
    }
  },
  headerText: {
    color: "white",
    lineHeight: 1.1,
    fontSize: fontSizeSmall
  },
  'headerCell:last-child': {
    backgroundColor: Colors.primaryBlue,
    minWidth: "400px",
    color: "white"
  },
  headerRow: {
    backgroundColor: "white"
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box"
  },
  tableRowDisable: {
    backgroundColor: theme.palette.grey[400]
  },
  tableRow: {
    cursor: "pointer",
    backgroundColor: Colors.white,
    '& > $rowCell:last-child': {
      boxShadow: 'inset 1px -1px 0 0 rgba(0, 0, 0, 0.1), inset -1px 0 0 0 rgba(0, 0, 0, 0.1)'
    },
    '&:focus': {
      outline: 0
    }
  },
  tableRowMultiSelect: {
    '& > $rowCell:nth-child(2)': {
      boxShadow: 'inset 0px -1px 0 0 rgba(0, 0, 0, 0.1)'
    },
    '& > $rowCell:first-child': {
      boxShadow: 'inset 1px -1px 0 0 rgba(0, 0, 0, 0.1), inset -1px 0 0 0 rgba(0, 0, 0, 0.1)'
    }
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: Colors.subtleBlue
    }
  },
  tableCell: {

  },
  dataTableCell: {
    '& .MuiTypography-noWrap:hover': {
      // whiteSpace: 'normal'
    }
  },
  noClick: {
    cursor: "initial"
  },
  rowCell: {
    flex: 1,
    position: 'relative',
    border: 0,
    boxShadow: 'inset 1px -1px 0 0 rgba(0, 0, 0, 0.1)',
    padding: 8,
    '& .MuiInputBase-root': {
      margin: 0
    }
  },
  headerRowCellStickyRight: {
    position: 'sticky',
    right: 0,
    backgroundColor: 'inherit',
    zIndex: 1
  },
  rowCellStickyRight: {
    position: 'sticky',
    right: 0,
    backgroundColor: 'inherit',
    zIndex: 1,
    padding: 0
  },
  rowCellStickyLeft: {
    position: 'sticky',
    left: 0,
    backgroundColor: 'inherit',
    zIndex: 1
  },
  selectedHeaderCell: {
    backgroundColor: Colors.highlightBlue
  },
  cellAlignRight: {
    textAlign: 'right',
    justifyContent: 'flex-end'
  },
  sortIcon: {
    transition: 'opacity 0.1s linear',
    opacity: 0,
    fontSize: 16
  },
  filterContainer: {
    transition: 'all 0.2s',
    backgroundColor: Colors.white
  },
  hideFilter: {
    opacity: 0,
    width: 0,
    flex: '0 1 0',
    maxWidth: 0,
    overflow: 'hidden',
    padding: '0 !important',
    '& *': {
      zIndex: 0
    }
  },
  showFilter: {
    marginBottom: 8,
    minWidth: 120
  },
  fullScreenModal: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: 0,
    justifyContent: 'flex-end'
  },
  filterContainerWrapper: {
    position: 'relative',
    outline: 'none',
    backgroundColor: Colors.white,
    overflow: 'scroll',
    padding: 12,
    paddingTop: 64
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8
  },
  tableActionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: 'auto',
    width: '100%',
    alignItems: 'center',
    transition: 'all 0.2s'
  },
  tableContainerGrid: {
    height: 'calc(100% - 74px)',
    margin: 'auto',
    width: '100%',
    transition: 'all 0.2s'
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'stretch'
  },
  dateDivider: {
    height: 'auto',
    margin: '0px 8px'
  },
  advancedSwitch: {
    '& .MuiTypography-body1': {
      fontSize: fontSizeReg,
      color: Colors.primaryBlue
    },
    marginRight: 16
  },
  container: {
    display: 'flex',
    border: '1px solid #f0f0f0',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    backgroundColor: Colors.white,
    flexDirection: 'column',
    padding: 4
  },
  noteContainer: {
  },
  noteWrapper: {
    margin: '2px 8px auto 8px'
  },
  tableActionsContainerModal: {

  },
  tableDataText: {
    fontSize: fontSizeSmall
  },
  refreshIcon: {
    color: Colors.primaryBlue
  },
  refreshIconButton: {
    marginRight: 'auto',
    marginLeft: 8,
    color: Colors.primaryBlue
  },
  tableRowSelected: {
    backgroundColor: Colors.subtleBlue
  },
  browseFileContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    maxWidth: '60%',
    marginLeft: 'auto'
  },
  browseFileButton: {

  },
  browseFileNameContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  browseFileName: {
    margin: 'auto 8px'
  },
  browseFileNameBox: {
    minHeight: 40,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    // marginBottom: "10px",
    border: `2px solid ${Colors.grey200}`,
    boxShadow: 'none',
    padding: '4px 8px',
    margin: 'auto 8px'
  },
  hidden: {
    visibility: 'hidden'
  },
  headerImageCell: {
    flex: 0,
    maxWidth: imageCellWidth
  },
  imageCell: {
    display: 'flex',
    flex: 0,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: `${imageCellWidth}px !important`
  },
  img: {
    maxHeight: 30,
    maxWidth: 30
  },
  imgZoomed: {
    maxHeight: 65,
    maxWidth: 65
  },
  rowCheckbox: {
    display: 'flex',
    flex: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    paddingTop: 16,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 12,
  },
  loaderContainer: {
    margin: 'auto',
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '1260px',
    paddingTop: '0px'
  },
  exportButton: {
    marginRight: '16px',
    marginLeft: 'auto'
  },
  lockIcon: {
    fontSize: 16,
    verticalAlign: 'text-bottom',
    marginLeft: 2
  }
})

export default makeStyles(styles);
