import React, { useState } from 'react';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  panel: {
    borderRadius: 4,
    backgroundColor: '#0071E90D',
    padding: 16,
    '&:before': {
      content: 'none'
    },
    '&.MuiExpansionPanel-root.Mui-expanded': {
      margin: 0
    },
    boxShadow: 'none'
  },
  panelError: {
    backgroundColor: '#FF00000D'
  },
  summary: {
    padding: 0,
    minHeight: 'auto',
    '&.MuiExpansionPanelSummary-root.Mui-expanded': {
      minHeight: 'auto'
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: 0
    },
    '& .MuiExpansionPanelSummary-expandIcon': {
      padding: '16px 16px',
      margin: '-16px 0px',
      fontSize: 16
    }
  },
  details: {
    padding: 0,
    margin: 0,
    marginTop: 12,
    flexDirection: 'column'
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: '#00000080'
  },
  content: {
    fontSize: 16,
    color: '#00000080'
  }
});

function Note({ className, children, title, variant, spacing = 16, spaceTop = null }) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const hasChildren = children && children.length;
  const toggle = () => hasChildren ? setShow(show => !show) : null;
  const marginTop = spaceTop !== null ? spaceTop : spacing;

  const childrenArray = React.Children.toArray(children);

  return (
    <ExpansionPanel expanded={hasChildren && show} onChange={toggle} className={clsx(classes.panel, {[classes.panelError]: variant === 'error'}, className)} style={{ padding: spacing, marginTop }}>
      <ExpansionPanelSummary
        expandIcon={hasChildren ? <ExpandMore style={{visibility: hasChildren ? 'visible' : 'hidden' }} /> : null}
        className={classes.summary}
      >
        <Typography className={classes.title}>{title}</Typography>
      </ExpansionPanelSummary>
      {hasChildren ? (
        <ExpansionPanelDetails className={classes.details} style={{ marginTop: spacing }}>
          {childrenArray.map((child, index) => {
            return <Typography key={index} style={{paddingTop: index === 0 ? 0 : spacing }} className={classes.content}>{child}</Typography>
          })}
        </ExpansionPanelDetails>
      ) : null}
    </ExpansionPanel>
  )
}

export default Note;
