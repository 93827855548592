import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddCircle from '@material-ui/icons/AddCircle';
import Edit from '@material-ui/icons/Edit';
import { useQuery, useMutation } from "@apollo/react-hooks";
import ErrorIcon from '@material-ui/icons/Error';
import useStyles from './TemplateForm.style';
import { CREATE_ITEM, UPDATE_BRANDING, UPDATE_ITEM } from '../../queries/formQueries';
import { GET_ITEM_BY_ID } from '../../queries/workflow-queries';
import PageSkeleton from '../../widgets/progress/PageSkeleton';
import CustomButton from "../../widgets/buttons/CustomButton/CustomButton";
import templateToObject from '../../Utils/template/templateToObject';
import objectToTemplate from '../../Utils/template/objectToTemplate';
import TemplateCompartment from "../templates/TemplateCompartment";
import getCompartmentTypes from "../../Utils/compartments/getCompartmentTypes";
import BackgroundProcessingBar from "../../widgets/bars/BackgroundProcessingBar";
import UserComptContext from '../../setup/compartments/compartment.context';
import { useAppInsightsContext } from '../../setup/application-insights/app-insights.context';
import trackRecents from '../../setup/application-insights/track-recents';
import { Roles, low, TemplateGroupType, TemplateSpecialGroupType } from '../../constants/Constants';
import HeaderTitle from '../../widgets/title/HeaderTitle';
import { useBrandingServiceContext } from '../../containers/branding/BrandingProvider';
import { reducerActions as templateActions } from "../templates/templateReducer";
import getDeepValue from "../../Utils/getDeepValue";
import { showWarning } from "../../Utils/common";
import ConfirmationDialog from '../../widgets/Confirmation/ConfirmationDialog';

function TemplateForm(props) {
  const { rootType, itemId, clone, templateId, subtype, dispatch, field, group, dataState, templateGroupType = "", isViewOnly, setTemplateModalData, workflowItem = {}, modalVariant, saveDataInParent, hasMultipleGroups, sameScreenModal } = props;
  const classes = useStyles();
  const [viewOnly, setViewOnly] = useState(isViewOnly || (!!itemId && !clone));
  const formRef = useRef(null);
  const { user, simulatedUser, simulatingUser } = useContext(UserComptContext);
  const { getAllBranding } = useBrandingServiceContext();
  const [serviceErrors, setServiceErrors] = React.useState([]);
  const history = useHistory();
  const reactPlugin = useAppInsightsContext();
  const servicePath = rootType + (rootType === getCompartmentTypes.COUPON ? `/${subtype.toLowerCase()}` : '');
  const [forceDisableWorkflowActions, setForceDisableWorkflowActions] = useState(false);


  const [bulkWorkflowDialog, _setBulkWorkflowDialog] = React.useState({ open: false });

  // We want to be able to handle canceling the execute from the bulk workflow dialog
  const setBulkWorkflowDialog = useCallback((params) => {
    const { cancelExecute, ...dialogParams } = params;

    if (cancelExecute) {
      setForceDisableWorkflowActions(false);
    }

    _setBulkWorkflowDialog(dialogParams)
  }, [setForceDisableWorkflowActions, _setBulkWorkflowDialog]);

  useEffect(() => {
    setViewOnly(isViewOnly || (!!itemId && !clone));
  }, [itemId, clone])

  const { loading: loadingItem, data: dataItem } = useQuery(GET_ITEM_BY_ID, {
    skip: !itemId || !rootType || rootType === getCompartmentTypes.USERS,
    variables: {
      type: servicePath,
      id: itemId
    },
    fetchPolicy: 'no-cache',
    onCompleted: handleCompletedGetItem
  });

  function handleCompletedGetItem(res) {
    if (res) {
      const payload = res.workflowItem.payload;
      trackRecents({
        reactPlugin,
        rootType,
        payload,
        location: history.location,
        user
      });
    }
  }

  /* Here if a modal has multiple groups , we are extracting the modal Data using the templateGroupType and  if a modal is setting values for an input , we are saving the modal data with the name as key and extract
  here using the field name, suppose if modal templateGroupType belongs to a special group, we will use templateGroupType to extract the data */

  let itemData, templateData;
  const ifSpecialModalDataType = TemplateSpecialGroupType.includes(templateGroupType.toUpperCase()) && hasMultipleGroups;
  if (props.templateModal) {
    // If template is special type, it is stored in an array and we need to get by index
    const groupTemplateType = getDeepValue(group, ['metadata', 'type'])
    const extractModalData = ifSpecialModalDataType ? low(templateGroupType) : saveDataInParent && !ifSpecialModalDataType ? TemplateSpecialGroupType.includes(templateGroupType.toUpperCase()) ? low(templateGroupType) : field.name : field.value;
    if (TemplateSpecialGroupType.includes(groupTemplateType)) {
      templateData = dataState[group.metadata.name].fields[group.groupIndex || 0][field.name][extractModalData] || (workflowItem && workflowItem[extractModalData])
    } else {
      templateData = dataState[group.metadata.name].fields[field.name][extractModalData] || (workflowItem && workflowItem[extractModalData])
    }
  }
  if (dataItem && dataItem.workflowItem && dataItem.workflowItem.payload) {
    itemData = dataItem.workflowItem.payload;
    templateData = objectToTemplate(itemData);
  } else if (rootType === getCompartmentTypes.USERS) {
    itemData = simulatingUser ? simulatedUser : user;
    templateData = objectToTemplate(itemData);
  }

  const handleOnError = (e) => {
    if (e && e.networkError && e.networkError.result && e.networkError.result.errors) {
      setServiceErrors(e.networkError.result.errors);
    } else {
      setServiceErrors([{ description: 'Something went wrong' }])
    }
  }

  const [createItem, { loading: loadingCreate, error: errorCreate }] = useMutation(CREATE_ITEM, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    variables: {
      type: servicePath
    }
  });

  const updateQuery = (rootType === getCompartmentTypes.BRANDING || rootType === getCompartmentTypes.TERMSANDCONDITIONS) ? UPDATE_BRANDING : UPDATE_ITEM

  const [updateItem, { loading: loadingUpdate, error: errorUpdate }] = useMutation(updateQuery, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    variables: {
      type: servicePath
    }
  });

  let validateErrors;
  const errorSubmit = errorUpdate || errorCreate;

  if (errorSubmit && errorSubmit.networkError && errorSubmit.networkError.result && errorSubmit.networkError.result.errors) {
    validateErrors = errorSubmit.networkError.result.errors;
  }

  const buttons = renderButtons();
  const hasButtons = buttons && buttons.length > 0;

  function handleOnCompleted(data) {
    if (low(rootType) === low(getCompartmentTypes.BRANDING)) {
      getAllBranding();
    }

    setViewOnly(true);

    if (data && data.createItem && data.createItem.payload) {
      const payload = data.createItem.payload;
      if (history && history.location) {
        const [path] = history.location.pathname.split('?')
        history.push(`${path}?itemId=${getItemId(payload)}`)
      }
    }
  }

  function getItemId(item) {
    switch (rootType) {
      case (getCompartmentTypes.BRANDING):
        return item.name;
      case (getCompartmentTypes.COUPON):
        return item.couponId;
      default:
        return item.id;
    }
  }

  function renderErrors() {
    return serviceErrors.map((error, index) => (
      <div className={classes.error} key={`error-${index}`}>
        <ErrorIcon className={classes.icon} />
        <Typography variant='h6' className={classes.message}>{error.description}</Typography>
      </div>
    ))
  }

  if (loadingItem) {
    return <PageSkeleton />;
  }

  async function handleOnClick() {
    let data;
    try {
      const payload = await formRef.current.callChild();
      data = payload && payload.finalPost ? payload.finalPost : payload;
    } catch (e) {
      /* Will change after everything clear */
      console.error("error", e);
    }

    // Force workflow actions to be disabled
    setForceDisableWorkflowActions(true)

    const waitForWarnings = await checkWarnings(data);

    if (!waitForWarnings) {
      executeOptions(data)
    }
  }

  const executeOptions = (data) => {
    // skip executing if already executing
    if (loadingCreate || loadingUpdate) {
      return;
    }
    if (props.templateModal && data) {
      dispatch({ type: templateActions.SET_MODAL_DATA, data: { field, modalData: data, group, templateGroupType, saveDataInParent, hasMultipleGroups } });
      if (low(templateGroupType) !== low(TemplateGroupType.FLAT_MODAL_DATA)) {
        props.setTemplateModalData(data)
      }
      props.handleModalClose()
      return;
    }

    const input = {
      ...itemData,
      auditTrail: itemData && itemData.auditTrail ? itemData.auditTrail : [],
      id: clone ? '' : itemData ? itemData.id : undefined,
      ...templateToObject(data)
    };

    if (rootType === getCompartmentTypes.BRANDING) {
      input.mobile = templateToObject(data, low(TemplateGroupType.MOBILE))
      input.desktop = templateToObject(data, low(TemplateGroupType.DESKTOP))
      input.pos = templateToObject(data, low(TemplateGroupType.POS))
      input.kiosk = templateToObject(data, low(TemplateGroupType.KIOSK))
      input.mweb = templateToObject(data, low(TemplateGroupType.MWEB))
    }

    if (data !== null) {
      if (itemId && !clone) {
        updateItem({
          variables: {
            id: itemId,
            type: servicePath,
            input: input
          }
        });
      } else {
        createItem({
          variables: {
            type: servicePath,
            input: input
          }
        });
      }
    }

    // Let loading state handle disabled buttons
    setForceDisableWorkflowActions(false)
  }

  const changeTrial = ({ input }) => {
    setBulkWorkflowDialog(false);
    executeOptions(input)
  }

  async function checkWarnings(data) {
    if (data) {
      const executeParams = { input: { ...data, nonWOrkflowOption: true, type: rootType, subtype: subtype, templateGroupType } }
      const customProps = {
        executeParams, setBulkWorkflowDialog, changeTrial
      }
      return await showWarning(customProps);

    } else {
      return false;
    }
  }


  function handleEditButton() {
    setViewOnly(false);
  }

  function renderForm() {
    let data = { 'values': templateData }
    // if a modal has multiple groups , belongs to special Group type and setting the value of an input , templateGroupType will used as the key, otherwise field value cab be used
    if (props.templateModal && TemplateSpecialGroupType.includes(templateGroupType.toUpperCase())) {
      data = ifSpecialModalDataType || (saveDataInParent && TemplateSpecialGroupType.includes(templateGroupType.toUpperCase())) ? {
        [low(templateGroupType)]: templateData

      } : {
        [low(templateGroupType)]: {
          [field.value]: templateData
        }
      }
    }
    switch (rootType) {
      default: return (
        <TemplateCompartment
          templateId={templateId}
          // parentTemplate={template}
          ref={formRef}
          rootType={rootType}
          data={data}
          mode={viewOnly ? 'viewOnly' : ''}
          scrollUpOffset={hasButtons ? 80 : 0}
          validateErrors={validateErrors}
          templateModal={props.templateModal}
          parentData={props.parentData}
          modalVariant={modalVariant}
          sameScreenModal={sameScreenModal}
          setTemplateModalData={setTemplateModalData}
        />
      )
    }
  }

  function renderButtons() {
    const children = [];
    const editButton = <CustomButton LeftIcon={Edit} color='secondary' variant='contained' label='Edit' className={classes.button} size='large' onClick={handleEditButton} disabled={loadingCreate || loadingUpdate || forceDisableWorkflowActions} />;
    const createButton = <CustomButton LeftIcon={AddCircle} color='secondary' variant='contained' label={itemId && !clone ? 'Update' : 'Create'} className={classes.button} size='large' onClick={handleOnClick} disabled={loadingCreate || loadingUpdate || forceDisableWorkflowActions} />
    const cancelButton = <CustomButton color='secondary' variant='outlined' label="Cancel" className={classes.button} size='large' onClick={props.handleModalClose} disabled={loadingCreate || loadingUpdate || forceDisableWorkflowActions}  />
    const saveButton = <CustomButton color='secondary' variant='contained' label="Save" className={classes.button} size='large' onClick={handleOnClick} disabled={loadingCreate || loadingUpdate || forceDisableWorkflowActions} />
    const userDetails = simulatingUser ? simulatedUser : user;
    if (userDetails.role.name && userDetails.role.name.includes(Roles.READONLY)) {
      return children;
    }
    if (props.templateModal) {
      if (!viewOnly) {
        children.push(cancelButton, saveButton);
      }
      return children;
    }
    switch (rootType) {
      case (getCompartmentTypes.BRANDING):
      case (getCompartmentTypes.COUPON):
      case (getCompartmentTypes.TERMSANDCONDITIONS):
      case (getCompartmentTypes.TARGETED_MESSAGING):
      case (getCompartmentTypes.TARGETED_MESSAGING_BY_SLOT):
        if (viewOnly) {
          children.push(editButton);
        } else {
          children.push(createButton);
        }
        break;
    }

    return children;
  }

  function showTitleBar() {
    return itemId && (rootType === getCompartmentTypes.COUPON || rootType === getCompartmentTypes.BRANDING)
  }

  function getNumberTitle(num) {
    if (!num) {
      return '';
    }
    return `#${num} | `;
  }

  function getHeaderTitle() {
    if (templateData) {
      if (rootType === getCompartmentTypes.COUPON) {
        return `${getNumberTitle(templateData.couponId)}${templateData.couponGroupName || templateData.code || 'Not Set'}`;
      } else if (rootType === getCompartmentTypes.BRANDING) {
        return `${templateData.name}`;
      }
    }
  }

  if (modalVariant === 'popover' || sameScreenModal) {
    return (
      <>
        {renderForm()}
        <div className={classes.buttons}>
          {buttons}
        </div>
      </>
    )
  }

  return (
    <div className={classes.root} style={{ paddingBottom: hasButtons ? 80 : 0 }}>
      {
        showTitleBar() && templateData ? (
          <>
            <br />
            <HeaderTitle title={getHeaderTitle()} blue>
              <div className={classes.subHeader}>
                <Typography variant='h6' className={classes.createdBy} style={{ display: templateData.createdBy ? 'flex-end' : 'none' }}>
                  Created By:
                  {templateData.createdBy}
                </Typography>
              </div>
            </HeaderTitle>
          </>
        ) : null
      }
      {(serviceErrors && serviceErrors.length > 0) ? renderErrors() : null}
      {renderForm()}
      {hasButtons ? (
        <>
          <BackgroundProcessingBar style={{ bottom: 80 }} />
          <Paper square elevation={6} className={classes.buttonContainer}>
            <div className={classes.buttons}>
              {buttons}
            </div>
          </Paper>
        </>
      ) : null}
      <ConfirmationDialog {...bulkWorkflowDialog} />
    </div>
  );
}

export default TemplateForm;
