export default function checkDateBoundary({ value, minDate, maxDate}) {
  if (value) {
    const dateValue = new Date(value).getTime();
    return (
      (minDate && dateValue < new Date(minDate).getTime()) ||
      (maxDate && dateValue > new Date(maxDate).getTime())
    ) ? true : false;
  }
  return false;
}
