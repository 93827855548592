export const AccessLevels = {
  READ: 'R',
  WRITE: 'W',
  ADMIN: 'A',
  SKIP: 'S',
  OVERRIDE: 'O',
  VALIDAE: 'V'
}

export const AccessRanks = {
  R: 0,
  W: 2,
  A: 3,
  S: 1,
  O: 4
}
