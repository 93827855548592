import React, { useState, useRef } from 'react';
import { InputAdornment, Button } from '@material-ui/core';
import useStyle from './CsvInputStyles';
// import CustomButton from '../../Components/Buttons/CustomButton/CustomButton';
import Chip from '../filter/Chip';
import MultilineInput from './MultilineInput';
import FullScreenModal from '../modal/FullScreenModal';
import Search from '../../compartments/search/search';
import BrandingSearch from '../../compartments/branding/BrandingSearch';
import getCompartmentTypes from '../../Utils/compartments/getCompartmentTypes';
import BrandSmart from '../brand/BrandSmart';
import CustomButton from '../buttons/CustomButton/CustomButton';
import getDeepValue from '../../Utils/getDeepValue';



function getItem(value, type) {
  if (value) {
    switch (type) {
      case (getCompartmentTypes.BRANDING):
        return (
          <BrandSmart
            name={value}
          />
        )
      default:
        return value
    }
  }

  return '';
}

const SearchInput = props => {
  const classes = useStyle()
  const childSearch = useRef(null);
  const { value, fullValue, onChange, viewOnly, placeholder, children, searchType, label, getAllData, isButton, queries, ...textInputProps } = props;
  const [openModal, setOpenModal] = useState(false);
  const [enableAction, setEnableAction] = useState(false);

  const displayPropValue = getDeepValue(queries, ['displayPropValue']);
  const shouldGetDeepValue = displayPropValue && fullValue;
  const displayValue = shouldGetDeepValue? getDeepValue(fullValue, [displayPropValue]) : value;

  function setSelected(selected) {
    const value = selected;
    handleClose();
    if (!props.disabled) {
      onChange({
        target: {
          value: {
            ...value,
            fullValue: value
          }
        }
      })
    }
  }

  function handleDelete() {
    setSelected(null);
  }

  function onOpenModal() {
    setOpenModal(true);
  }

  function handleClose() {
    setOpenModal(false);
    setEnableAction(false);
  }

  async function handleSave() {
    setEnableAction(false);
    if (childSearch && childSearch.current && childSearch.current.getSelected) {
      const selected = await childSearch.current.getSelected();
      if (selected && selected.selectedRowsarray && selected.selectedRowsarray[0]) {
        if (getAllData) {
          // needed on workflow (i.e. campaign_assignment to get start_date and end_date)
          setSelected(selected.selectedRowsarray[0]);
        } else {
          setSelected(selected.selectedRowsarray[0].id);
        }
      } else {
        setSelected('');
      }
    }
  }

  function renderSelect() {
    if (value && value.length > 0) {
      return (
        <div className={classes.chips}>
          <Chip disabled={props.disabled} key={value} label={getItem(displayValue, searchType)} className={classes.chip} onDelete={() => handleDelete()} />
        </div>
      );
    } else {
      return <span className={classes.placeholderText}>{placeholder}</span>
    }
  }


  const [mainSearchType, subSearchType] = searchType.split('.');
  const component = mainSearchType === getCompartmentTypes.BRANDING ? (
    <BrandingSearch
      isModal
      ref={childSearch}
      singleSelect
      setEnableParentAction={setEnableAction}
    />
  ) : (
    <Search
      rootType={mainSearchType}
      subSearchType={subSearchType}
      ref={childSearch}
      singleSelect={!props.isButton}
      disableActions
      isModal
      setEnableParentAction={setEnableAction}
      parentFilter={props.parentFilter}
      queries={queries}
    />
  );


  function getSearchLine() {
    return (
      <MultilineInput
        {...textInputProps}
        className={classes.input_text}
        placeholder={placeholder}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          ...(textInputProps.InputProps || {}),
          endAdornment: props.disabled ? null : (
            <InputAdornment position='end' className={classes.buttonAdornment}>
              <Button data-testid='search' onClick={props.disabled ? undefined : onOpenModal} color='secondary' variant='contained' size='small' disableElevation>
                Search
              </Button>
            </InputAdornment>
          )
        }}
        select
        SelectProps={{
          // onOpen: onOpenModal,
          open: false,
          multiple: false,
          renderValue: renderSelect,
          displayEmpty: true,
          value: value,
          classes: {
            root: classes.selectRoot,
            select: classes.select
          },
          onClick: value ? null : (props.disabled ? null : onOpenModal)
        }}
      >
        <span />
      </MultilineInput>
    )
  }

  function getActionButton() {
    return (
      <CustomButton disabled={props.disabled} onClick={props.disabled ? undefined : onOpenModal} color='secondary' variant='contained' size='small' label={props.label} />
    )
  }

  return (
    <div className={classes.root}>
      {
        (props.isButton) ? getActionButton() : getSearchLine()
      }
      {/* <CustomButton label='Select List' onClick={onOpenModal} className={classes.search_button} /> */}
      <FullScreenModal
        open={openModal}
        onRequestClose={handleClose}
        title={placeholder ? placeholder : 'Select Value'}
      // title={label}
      // secondaryTitle={placeholder}
      >
        <div className={classes.modalBody}>
          {component}
          <div className={classes.modalActions}>
            {
              (!props.isButton || props.buttonSelectable)
                ? <CustomButton data-testid='select' onClick={handleSave} label='Select' className={classes.actionButtons} variant='contained' color='secondary' disabled={!enableAction} />
                : ""
            }
            <CustomButton
              onClick={handleClose}
              label={
                (props.isButton && !props.buttonSelectable)
                  ? 'Close'
                  : 'Cancel'
              }
              className={classes.actionButtons}
              variant='outlined'
              color='secondary'
              data-testid='cancel'

            />
          </div>
        </div>
      </FullScreenModal>
    </div>
  );

}

export default SearchInput;
