/**
 * Checks if the given workflow item is supplier funded.
 *
 * @param {Object} workflowItem - The workflow item to check.
 * @param {Array} workflowItem.owners - The owners of the workflow item.
 * @returns {boolean} - Returns true if the workflow item has owners and is supplier funded, otherwise false.
 */
function checkIsSupplierFunded(workflowItem) {
  if (!workflowItem || !workflowItem.owners) {
    return false;
  }

  const owners = workflowItem.owners;

  return Array.isArray(owners) && owners.length > 0;
}

export default checkIsSupplierFunded;
