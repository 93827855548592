import getCompartmentbyId from "./getCompartmentById";

// Checks if the user can access a certain path given their list of compartments
// very basic, may not support all all cases
export default function canAccessPath(allCompartments, path) {
  if (!path) {
    return false;
  }

  const [rootType, , ...compartmentIds] = path.split('/').filter(item => item);

  const rootCompartments = allCompartments.filter((compartment) => compartment.type === rootType && compartment.isRoot);

  if (rootCompartments.length === 0) {
    return false;
  }

  for (const compartment of compartmentIds) {
    if (!getCompartmentbyId(allCompartments, compartment)) {
      return false
    }
  }

  return true;
}
