import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';
import { fontBold } from '../../theme/theme';

const styles = theme => ( {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
  },
  modalSearchContainer: {
    paddingTop: 16,
    width: '100%',
    marginTop: 'auto',
    height: '34px'
  },
  paper: {
    flex: 1,
    margin: 80,
    display: 'inline-flex'
  },
  modal: {
    position: 'absolute',
    width: '100%',
    maxWidth: 300,
    padding: 16,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  skeletonContainer: {
    width: '100%',
    maxWidth: 1260,
    marginTop: 16,
    padding: 32,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 'auto',
    boxSizing: 'border-box'
  },
  skeletonWrapper: {
    marginTop: 16,
    padding: 4
  },
  tableWrapper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  totalWrapper: {
    width: '100%',
    maxWidth: 1260,
    margin: 'auto'
  },
  actions: {
    padding: 16,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionButtons: {
    marginLeft: 16,
    minWidth: 128
  },
  buttons: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  button: {
    margin: 8
  },
  bulkActionsContainer: {
    height: 68,
    width: '100vw',
    position: 'absolute',
    backgroundColor: Colors.white,
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'bottom 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${Colors.grey100}`
  },
  bulkActionsWrapper: {
    position: 'relative',
    top: 68
  },
  bulkActions: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 16,
    marginRight: 16,
    maxWidth: 1260
  },
  selectedCount: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    color: '#6a7187'
  },
  overlayMessage: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  overlayLink: {
    color: '#409dff',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
})
export default makeStyles(styles);
