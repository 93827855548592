import React from 'react';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';
import Close from '@material-ui/icons/Close'
import useStyles from './Chip.styles';

function CustomChip(props) {
  const { clickable, onClick, deleteIcon, onDelete, icon, label, iconOnly, disabled, ...otherProps } = props;
  const classes = useStyles();

  function onDeleteHandler(event) {
    event.stopPropagation();
    event.preventDefault();
    onDelete(event);
  }

  return (
    <Chip
      {...otherProps}
      disabled={disabled}
      label={iconOnly ? undefined : label}
      classes={{
        root: clsx(classes.root, {[classes.disabled]: disabled}),
        deleteIcon: clsx(classes.deleteIcon, {[classes.iconIconOnly]: iconOnly, [classes.deleteIconDisabled]: disabled}),
        icon: clsx( classes.icon, {[classes.iconDisabled]: disabled}),
        clickable: classes.clickable,
        deletable: clsx(classes.deletable, {[classes.deletableDisabled]: disabled}),
        label: clsx(classes.label, {[classes.labelIconOnly]: iconOnly})
      }}
      clickable={!!onClick && !disabled}
      onClick={disabled ? undefined : onClick}
      onDelete={icon ? ()=>null : undefined}
      deleteIcon={icon}
      icon={onDelete && !disabled ? (deleteIcon ? deleteIcon : <Close onClick={onDeleteHandler} data-testid="delete" />) : undefined}
    />
  )
}

export default React.memo(CustomChip);
