import { Aborter } from "@azure/storage-blob";
import { BlobTypes, Status } from "../../constants/Constants";
import arrayToCSV from "../../Utils/biglist/arrayToCSV";
import addBlobService from "../../services/biglist/AddBlobService";
import createFilesMetadataService from "../../services/biglist/createFilesMetadataService";

export const duplicatePromotionFileUpload = async (
  state,
  duplicateCampaign,
  modalInputValue,
  user
) => {
  const selectedRows = state.rowData.filter((r) => r.selected === true);
  const ids = selectedRows.map((row) => row.id);
  const parentCmpIds = selectedRows
    .map((row) => row.values.campaign_id)
    .filter((row) => row !== null && row !== "");
  const parentCmpTypes = selectedRows
    .map((row) => row.values.assignment_type)
    .filter((row) => row !== null && row !== "");
  if (parentCmpIds.length === 0) {
    return {
      title: "campaign_id is not available",
      variant: "error",
      persist: true,
    };
  }
  if (parentCmpTypes.length === 0) {
    return {
      title: "campaign_type is not available",
      variant: "error",
      persist: true,
    };
  }
  if (
    !duplicateCampaign ||
    !duplicateCampaign.assignment_type ||
    duplicateCampaign.assignment_type === "" ||
    duplicateCampaign.assignment_type !== parentCmpTypes[0]
  ) {
    return {
      title: "Parent campaign type is not same as new campaign type",
      variant: "error",
      persist: true,
    };
  }
  const type = BlobTypes.PROMO_CLONE;
  if (
    duplicateCampaign &&
    duplicateCampaign.campaign_number &&
    duplicateCampaign.campaign_number !== "" &&
    modalInputValue !== ""
  ) {
    const csvData = [];
    csvData.push({
      ParentPromotion: "Parent Promotion",
      ParentCampaign: "Parent Campaign",
      NewCampaign: "New Campaign",
      InternalNamePrefix: "Internal Name Prefix",
    });
    ids.forEach((id) => {
      csvData.push({
        ParentPromotion: id == null || id === undefined || id === "" ? "" : id,
        ParentCampaign: parentCmpIds[0],
        NewCampaign: duplicateCampaign.id,
        InternalNamePrefix: modalInputValue,
      });
    });
    const timestamp = Date.now();
    const extension = ".csv";
    const name = `clonePromotion${timestamp}${extension}`;
    const csv = arrayToCSV(csvData, name, type);

    const fileObj = {
      file: csv,
      name: name,
      type: "file",
      action: "",
      status: Status.READY,
      total: csv.size,
      time: 0,
      loaded: 0,
      lastLoaded: 0,
      lastSpeed: 0,
      speed: 0,
      lockedType: true,
      silent: true,
      sequence: 0,
    };

    const aborter = Aborter.none;

    try {
      const name = `clonePromotion${timestamp}`;
      await createFilesMetadataService(name, name + "_" + user.userId);
      await addBlobService(
        fileObj,
        "batch/" + BlobTypes.PROMO_CLONE,
        "",
        user,
        aborter,
        (progress) => console.log(progress)
      );
      return {
        title: "Success Uploading File.",
        variant: "success",
        persist: true,
      };
    } catch (error) {
      if (error.code !== "REQUEST_ABORTED_ERROR") {
        return {
          title: "Error Uploading file.",
          variant: "error",
          persist: true,
        };
      }
    }
  }
};

export const duplicatePromotionValidations = (state) => {
  const selectedRows = state.rowData.filter((r) => r.selected === true);
  const cmpSet = new Set(selectedRows.map((row) => row.campaign_number));
  const offerTypeSet = new Set(selectedRows.map((row) => row.offerType));
  const statusSet = new Set(selectedRows.map((row) => row.status));
  const allowedStatus = [
    "Published",
    "Live",
    "Ended",
    "To Assign/Publish",
    "Pending Merchant Verification",
  ];
  const allowedOfferType = ["$off Selected Items", "%off Selected Items"];
  let notAllowedStatus = false;
  let notAllowedOfferType = false;
  statusSet.forEach((s) => {
    if (allowedStatus.includes(s) === false) {
      notAllowedStatus = true;
    }
  });
  offerTypeSet.forEach((s) => {
    if (allowedOfferType.includes(s) === false) {
      notAllowedOfferType = true;
    }
  });
  if (cmpSet.size > 1) {
    return {
      title: "Please select offers containing same campaign",
      variant: "error",
      persist: true,
    };
  }
  if (notAllowedStatus === true) {
    return {
      title: "Please select offers containing valid status",
      variant: "error",
      persist: true,
    };
  }
  if (notAllowedOfferType === true) {
    return {
      title: "Please select valid offer type",
      variant: "error",
      persist: true,
    };
  }
  return null;
};
