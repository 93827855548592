import React from 'react';
import Diff from '../Diff';
import SearchInput from '../../inputs/SearchInput';
import ViewOnly from '../../viewOnly/ViewOnly';
import getCompartmentTypes from '../../../Utils/compartments/getCompartmentTypes';
import BrandSmart from '../../brand/BrandSmart';

export default function SearchInputDiff({ condition, data, mode, ...props }) {
  return (
    <Diff
      mode={mode}
      viewOnlyComponent={props.searchType === getCompartmentTypes.BRANDING && props.value ? (
        <ViewOnly
          {...props}
          value={<BrandSmart name={props.value} />}
        />
      ) : (
        <ViewOnly {...props} />
      )}
    >
      <SearchInput {...props} />
    </Diff>
  );
}
