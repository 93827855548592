import React from 'react';
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Collapse
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import useStyles from './FilterSelection.styles';
import notNull from '../../Utils/notNull';
import getDeepValue from '../../Utils/getDeepValue';
import { ItemProps } from '../../constants/Constants';
import getSkeleton from '../../Utils/skeleton/getSkeleton';

/**
 * Component to render a individual choice/ also the child choices if any.
 */
function FilterChoice(props) {
  const { choice, checked, handleSelect, handleChildSelect, filterValue, type, disabled } = props;
  const classes = useStyles();
  /**
   * @type {array} - selected children for this choice
   */
  const [childChecked, setChildChecked] = React.useState([]);

  const { label, value, childOptions } = choice && typeof choice === 'object'
    ? choice
    : { label: choice, value: choice };
  /**
   * @type {boolean} - signifies if this option is selected from all options selected for a field.
   */
  const isChecked = checked ? checked.indexOf(value) !== -1 : null;


  /**
   *
   * @param {string} selectedValue - value of selected choice
   * @param {string} key - only present for child choice, value is key of the child (eg subCategory)
   */
  function handleOnClick(selectedValue, key) {
    if (key) {
      /**
       * @type {array} selected children value from filterValue property.
       */
      const childSelected = [...(getDeepValue(filterValue, ['childOptions', key, 'selected']) || [])];
      //If value is already present in childSelected array, then remove it, else add it to the array.
      const childIndex = childSelected.indexOf(selectedValue);
      if (childIndex === -1) {
        childSelected.push(selectedValue);
      } else {
        childSelected.splice(childIndex, 1);
      }
      // Call the props.handleChildSelect function with updated array, then update the state with new childSelected array.
      setChildChecked(checked => {
        const newChecked = [...checked];
        const currentIndex = checked.indexOf(selectedValue);
        if (currentIndex === -1) {
          newChecked.push(selectedValue);
        } else {
          newChecked.splice(currentIndex, 1);
        }

        handleChildSelect(childSelected, key);
        return newChecked;
      });
    } else {
      /* * Runs when parent choice selection is toggled.
        * If already checked, then set all child to unselected.
        * else, select the parent by calling props function handleSelect
      * */
      if (isChecked) {
        setChildChecked([]);
        handleChildSelect([], '*');
      }
      handleSelect(value);
    }
  }

  /**
   * Function to render child choice, and check it if it's present in childChecked state.
   * @param {object} choice - choice object for child @example {label:'child1' , value: 'child1'}
   * @param {*} index
   */
  function renderChildChoice(choice, index) {
    if(!choice) {
      return null;
    }
    const { label, value, key } = typeof choice === 'object'
      ? choice
      : { label: choice, value: choice };
    return (
      <ListItem key={index + ''} disabled={disabled} dense button onClick={() => handleOnClick(value, key)}>
        <ListItemIcon>
          <Checkbox checked={childChecked.indexOf(value) !== -1} />
        </ListItemIcon>
        <ListItemText primary={notNull(label)  ? `${label.toString().toLowerCase()} ${notNull(value) ?`(${value})`:""}` : label} className={classes.selectionLabel} />
      </ListItem>
    );
  }

  /**
   * Returns null if choice not present.
   */
  if (!choice) {
    return null;
  }

  const childOptionsList = childOptions ? Object.keys(childOptions).reduce((acc, key) => {
    // filter out invalid values
    const childCategory = childOptions[key].filter((option) => option && option.label && option.value).sort((a, b) => a.label.localeCompare(b.label));
    const listItems = childCategory.map((choice) => ({ ...choice, key }));
    return [...acc, ...listItems];
  }, []) : null;

  const emptyChildOptions = childOptionsList && childOptionsList.length === 0; //Check if childList is empty.

  return (
    <>
      <ListItem disabled={disabled} dense button onClick={() => handleOnClick(value)}>
        {/* Renders the choice/parent Choice  */}
        <ListItemIcon>
          {
            childOptionsList ? (
              isChecked ? <Remove className={classes.iconButton} /> :  <Add className={classes.iconButton} />
            ) : (
              <Checkbox checked={isChecked} />
            )
          }
        </ListItemIcon>
        {/* Label for the choice. */}
        <ListItemText primary={notNull(label) ? `${label.toString().toLowerCase()}${type===ItemProps.CAT? '(' + value + ')' :''}` : label} className={classes.selectionLabel} />
      </ListItem>
      {/* Render child if parent is selected and childOptions are there,
      If childOptions are loading or childList is empty then show loader
      else show children options */}
      {childOptionsList && isChecked ? (
        (props.childOptionsLoading && emptyChildOptions) ?
          <div className={classes.alignLoader}>{getSkeleton(3, 30)}</div> : (
            <Collapse in={isChecked} className={classes.childSelectionContainer}>
              <List className={classes.innerList}>
                {childOptionsList.map((choice, index) => renderChildChoice(choice, index))}
              </List>
            </Collapse>
          )
      ) : null}
    </>
  );
}

export default React.memo(FilterChoice);

FilterChoice.propTypes = {
  /**
   * value of field key variable (@example status,event_sales_branding )
  * */
  type: PropTypes.string,
  /**
   * boolean value if select/unselect for hthis field should be disabled or not.
  */
  disabled: PropTypes.bool,
  /**
   * Object that contains the label/value, childOptions(if any) for the rendered choice
   * @example without childOptions - {"value":"default_IS_branding","label":"default_IS_branding"}
   * @example with childOptions - {"value":"default_IS_branding","label":"default_IS_branding",
   *  childOptions: {subCategory: [{label: 'child1', value: 'child1'}]
   * }}
   */
  choice: PropTypes.object,
  /**
   * Object that contains metadata of the field for which this choice is rendered, and the selected values
   * Also selected values of any children of this field(if any).
   * @example without childOptions - {metadata, selected: ["Live"]}
   * @example with childOptions - {
   *    data: {metadata}
   *    selected: [] //parent selected,
   *    childOptions : {subCategory: {selected: [2,3], metadata}}
   * }
   * */
  filterValue: PropTypes.object,
  /**
   * Array that has all selected choices for the field, of which this choice is a option.
   * In this example, checked has two values selected for branding field, but this choice only renders default_is_branding option.
   * @example checked: ["default_IS_branding","TECHSAVINGS_ESB"]
   */
  checked: PropTypes.array

}
