import getAllBiglistValuesService from '../../services/biglist/GetAllBiglistValuesService';

async function getAllBiglistData(biglistName) {
  let offset, list;
  do {
    const response = await getAllBiglistValuesService(biglistName, 1000, offset);
    offset = response.offset;
    list = list ? list.concat(response.items) : response.items;
  } while (offset);

  return list;
}

export default getAllBiglistData;
