export const UploadListErrors = {
  ID_MISSING: 'ID_MISSING',
  ID_DUPLICATE: 'ID_DUPLICATE',
  UPLOAD_ERROR: 'There was an error updating big list.'
}

export const BiglistSearchErrors = {
  GET_ERROR: 'Error getting biglist'
}

export const TemplateErrors = {
  MISSING_INPUT: name => `${name || 'Value'} is required.`,
  OUT_OF_BOUNDS: 'End Date should be after Start Date ',
  MINIMUM_VALUE_STRING_TYPE: value => `minimum length of ${value} is required.`,
  MINIMUM_VALUE_NUMBER_TYPE: value => `minimum value of ${value} is required.`,
  MAXIMUM_VALUE_NUMBER_TYPE: value => `value cann't be more than or equals to ${value} set.`,
  MAXIMUM_VALUE_STRING_TYPE: value => `length cann't be more than or equals to ${value} set.`,
  MIN_VALUE_ERROR: value => `minimum value should be lesser than max value`,
  MAX_VALUE_ERROR: value => `maximum value should be higher than min value`,
  INVALID_ROUTING_NUMBER: `Invalid routing number; 9 digits required`,
  INVALID_BIN_LENGTH: `4 or 6 digits required`,
  INVALID_BIN_PREFIX_VISA: `Visa must start with 4`,
  INVALID_BIN_PREFIX_AMERICAN_EXPRESS: `American Express must start with 3`,
  INVALID_BIN_PREFIX_DISCOVER: `Discover must start with 6`,
  INVALID_BIN_PREFIX_MASTERCARD: `Mastercard must start with 5`,
  INVALID_NON_ASCII:`Your plain text description or instructions has invalid characters. Some possible culprits may include typographical quotes (replace with the keyboard  "  or ' ), or long dashes ( replace with the regular keyboard  '-' ).`
}


export const DownloadPDFErrors = {
  SERVICE_ERROR: 'error'
}

export const ExportOffersErrors = {
  SERVICE_ERROR: 'error'
}

export const SearchErrors = {
  KEY_NOT_FOUND: (key) => {
    key = key || 'values';
    key = key.toLowerCase();
    key = key[key.length === 's'] ? key : key+'s';
    key = key.charAt(0).toUpperCase() + key.slice(1);
    return `${key} were not found.`;
  }
}
