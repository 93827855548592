import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import historyService from '../../../services/promotions/historyService';
import useStyles from './SearchModal.styles';
import TemplateCompartment from '../../templates/TemplateCompartment';
import PageSkeleton from '../../../widgets/progress/PageSkeleton';
import dateFormat from '../../../Utils/date/dateFormat';
import useViewStyles from '../../../widgets/viewOnly/ViewOnly.styles';
import GET_WORKFLOW_BY_ID from '../../../queries/workflow-queries';
import { DateFormat } from "../../../constants/Constants";


function History(props) {
  const [versionsData, setVersionsData] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const { items, params } = props;
  const searchType = props.searchType || params.searchType
  const classes = useStyles();
  const viewStyles = useViewStyles();
  const { loading: loadingWorkflow, data: workflowData } = useQuery(GET_WORKFLOW_BY_ID, {
    skip: !items.workflowId,
    variables: {
      workflowId: items.workflowId
    }
  });
  const dateFormatStr = 'YYYY-MM-DD HH:mm';

  useEffect(() => {
    async function getVersions() {
      setLoading(true);
      try {
        const historyData = await historyService(searchType,items.parentId)
        const promotionsVersionsData = historyData;
        promotionsVersionsData.sort((version1, version2) => version2.version - version1.version);
        setVersionsData(promotionsVersionsData);
      } catch (err) {
        setLoading(false);
      } finally {
        setLoading(false);
      }

    }
    if (items.parentId) {
      getVersions()
    }
  }, [items.parentId, params, searchType]);

  const createdDate = (item) => item.createdTS ? dateFormat(item.createdTS, dateFormatStr, true) : '';
  const renderUsersTrail = () => {
    return items.auditTrail.map((item) => (
      <p key={item.createdTS}>
        {item.event ? (
          <Typography display="block">
            {item.event}
            on
            {createdDate(item)}
          </Typography>
        ) : (
          ''
        )}
        {item.comment ? (
          <Typography display="block">
            {item.comment}
            on
            {createdDate(item)}
          </Typography>
        ) : (
          ''
        )}
      </p>
    ));
  }
  const renderDiff = (versionDetails, oldVersionDetails, i) => {
    return (
      <TemplateCompartment
        key={i}
        templateId={versionDetails.templateId}
        data={versionDetails}
        mode="viewOnly"
        history={{
          modifiedBy: versionDetails.modifiedBy,
          dateModified: dateFormat(versionDetails.lastModified, dateFormatStr, true)
        }}
        historicalData={oldVersionDetails}
      />
    )
  }
  const isStepUiReadable = (trail) => {
    if (workflowData && workflowData.workflow && workflowData.workflow.payload && workflowData.workflow.payload.flow) {
      const stepInfo = workflowData.workflow.payload.flow.filter(step => step.name === trail.event);
      return stepInfo && stepInfo[0] && stepInfo[0].uiReadable;
    }
    return [];
  }

  const renderTableRows = (trail, index) => {
    return (
      <TableRow className={viewStyles.historyTableStyles} key={trail.createdTS + index}>
        <TableCell>{trail.flow || trail.event}</TableCell>
        <TableCell>{dateFormat(trail.createdTS, DateFormat.TABLE, true)}</TableCell>
        <TableCell>{trail.createdByUser}</TableCell>
        <TableCell>
          NA
        </TableCell>
        <TableCell>NA</TableCell>
        <TableCell>{trail.comment}</TableCell>
      </TableRow>
    )
  }

  const renderAuditTrail = () => {
    if(versionsData.length === 0) {
      return renderTableData();
    }

    const eventsData = versionsData.map(versionData => versionData.auditTrail).map(trails => {
      return trails.filter(isStepUiReadable)
    });
    const diff = renderVersions();
    return eventsData.reduce((acc, currentVal, currentIndex) => {
      if (currentVal || acc[currentIndex]) {
        acc[currentIndex] = [currentVal.map((trail, index) => renderTableRows(trail, index)),
          acc[currentIndex] ? renderDiff(acc[currentIndex].new, acc[currentIndex].old, currentIndex) : null];
      }
      return acc;
    }, diff);
  }

  const renderTableData = () => {
    if (items.auditTrail && items.auditTrail.length > 0) {
      return items.auditTrail && items.auditTrail.map((trail, index) => {
        return renderTableRows(trail, index)
      })
    } else {
      return renderNotFound()
    }
  }

  const renderNotFound = () => {
    return (
      <Box
        padding="100px"
        display="flex"
        alignContent="center"
        justifyContent="center"
      >
        AuditTrail Not Found
      </Box>
    )
  }

  const renderVersions = () => {
    const versionsTemplates = [];
    for (let versionNum = 0; versionNum < versionsData.length - 1; versionNum++) {
      versionsTemplates.push({ new: versionsData[versionNum], old: versionsData[versionNum + 1] });
    }
    return versionsTemplates
  }

  if (searchType !== 'users') {
    return (
      <div className={classes.historyContainer}>
        {loading || loadingWorkflow ? (
          <PageSkeleton headerCount={0} bodySectionsCount={2} />
        ) : (
          <Table>
            <TableHead>
              <TableRow className={classes.historyTableStyles}>
                {['Name', 'Change Time', 'Change By', 'Old Value', 'New Value', 'Comments'].map(val => {
                  return (
                    <TableCell key={val} className={classes.headerCellStyles}>
                      {val}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderAuditTrail()}
            </TableBody>
          </Table>
        )}
      </div>
    );
  } else {
    return <div style={{ marginLeft: '16px' }}>{renderUsersTrail()}</div>;
  }
}

export default React.memo(History);
