import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    boxSizing: 'border-box',
    padding: 16
  },
  message: {
    color: Colors.primaryOrange
  },
  icon: {
    color: Colors.primaryOrange,
    padding: 8
  },
  buttonIcon: {
    color: Colors.blueGrey900
  }
}

export default makeStyles(styles);
