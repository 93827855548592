import React from "react";
import Skeleton from 'react-loading-skeleton';
import useStyles from './skeleton-loader.styles';

const LoaderContainer = ({ height, count }) => {
  const classes = useStyles();
  return (
    <div className={classes.skeletonContainer}>
      <Skeleton
        wrapper={({ children }) => (
          <div className={classes.skeletonWrapper}>{children}</div>
        )}
        height={height}
        count={count}
      />
    </div>
  )
};
export default LoaderContainer;
