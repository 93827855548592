import React, { useState, useEffect, useCallback, useMemo, Fragment, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { TableSortLabel, Typography, Grid, Paper, Modal, Backdrop, Slide, IconButton, Tooltip, FormControlLabel, Switch, Avatar, Button, Popover, Menu, MenuItem } from "@material-ui/core";
import { Column, InfiniteLoader } from 'react-virtualized'
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import clsx from 'clsx';
import { SearchTable, FieldTypes, DateFormat, TableImageTypes, SearchTypes, ItemProps, low, ExportTypes } from '../../constants/Constants';
import Table from './virtualized-table';
import useStyles from './custom-table.styles';
import FilterColumn from '../filter/FilterColumn';
import dateFormat from '../../Utils/date/dateFormat';
import TableData from './TableData';
import parseUpc from '../../Utils/item/parseUpc';
import SearchFilter from '../filter/search-filter';
import { itemImageUrl } from '../../constants/Endpoints';
import SelectFileButton from '../buttons/SelectFileButton/SelectFileButton';
import CustomButton from '../buttons/CustomButton/CustomButton';
import Note from '../message/Note';
import RegulatoryCell, { getRegulatoryType } from './RegulatoryCell/RegulatoryCell';
import getDeepValue from '../../Utils/getDeepValue';
import getScrollbarWidth from '../../Utils/getScrollbarWidth';
import useScreen from '../../hooks/useScreen';
import headerRowRenderer from './headerRowRenderer';
import notNull from '../../Utils/notNull';
import getLabel from '../../Utils/biglist/getLabel';
import { getFunctionValues } from '../../Utils/view/viewFieldFunction';
import {
  customLoader,
  handleOnChangeFilter,
  onClickSearchAll,
  onClickSearch,
  clearSearch,
  buildSearchActions,
  getRowValuePostFunctionParse,
  filterFields,
  filterFieldsAF,
  onRowClick,
  onHeaderClick,
  isEmptyFilter
} from './custom-table-functions';
import TemplateRadio from "../inputs/RadioInput";

const scrollBarWidth = getScrollbarWidth();

const hack = {};

const rowStyle = {
  overflow: 'visibile',
  paddingRight: 0
};

const doNothing = () => { };


const CustomTable = props => {
  const headerHeight = 44
  const rowHeight = 32
  const sideSpace = (props.sideSpace ? props.sideSpace : 48) * 2; // *2 cause left and right
  // const mobileView = useMediaQuery('(max-width:960px)');
  const { isMobile: mobileView } = useScreen();

  const sort = useMemo(() => {
    return props.sort ? props.sort : {}
  }, [props.sort])

  // const [sort, setSort] = useState({ property: "", orderBy: "asc" })
  const [filter, setFilter] = useState(props.filter ? props.filter : {});
  const [tableDim, setTableDim] = useState({
    width: 0,
    height: 1000
  });
  const [uniqueChoices, setUniqueChoices] = useState({});
  const classes = useStyles();

  const history = useHistory();

  const [avatarZoomAnchorEl, setAvatarZoomAnchorEl] = React.useState(null);
  const avatarZoomed = Boolean(avatarZoomAnchorEl);
  const [avatarZoomSrc, setAvatarZoomSrc] = React.useState(null);
  const [filterChoicesLoading, setFilterChoicesLoading] = React.useState(false);
  const [childOptionsLoading, setChildOptionsLoading] = React.useState(true);

  const POPOVER_IMG_ZOOMED_HEIGHT = 55;

  const noAdvanced = Object.keys(props.fieldMap).filter(key => props.fieldMap[key].advancedFilter).length === 0 || props.noAdvanced;
  const switchPropsValue = props.switchProps && props.switchProps.value

  useEffect(() => {
    return () => clearTimeout(hack.filterSearchTimeout);
  }, []);

  // useEffect(() => {
  //   if (props.sort) {
  //     setSort(props.sort);
  //   }
  // }, [props.sort]);

  useEffect(() => {
    async function createSelection(map, data) {
      let itemCategory;
      if (low(props.searchType) === low(SearchTypes.ITEM)) {
        setFilterChoicesLoading(true);
        if (props.itemMetadata) {
          itemCategory = await props.itemMetadata.fetchCategories();
        }
        setFilterChoicesLoading(false);
      }

      const keys = Object.keys(map);
      return keys.reduce((acc, key) => {
        if ([FieldTypes.DATE, FieldTypes.DATE2].includes(map[key].type)) {
          return { ...acc }
        }
        if (map[key].type === FieldTypes.ENUM || map[key].values) {
          return {
            ...acc,
            [key]: map[key].values
          };
        }
        if (low(props.searchType) === low(SearchTypes.ITEM)) {

          if (low(key) === low(ItemProps.CAT)) {
            return {
              ...acc,
              [key]: itemCategory
            }
          }
          if (low(key) === low(ItemProps.SUBCAT)) {
            return acc;
          }
        }
        const mappedData = data.map(item => getDeepValue(item, ['values', key]) ? item.values[key] : item[key]);
        const uniqueSet = [...new Set(mappedData)];
        if (!(uniqueSet.length < data.length) || (uniqueSet.length === 1 && !uniqueSet[0])) {
          return { ...acc };
        }
        return {
          ...acc,
          [key]: uniqueSet.length < data.length ? uniqueSet : null
        }
      }, {});
    }
    createSelection(props.fieldMap, props.fullRowData).then(setUniqueChoices);

  }, [props.fieldMap, props.fullRowData, props.searchType]);

  function headerCell(fieldKey, i, actions, actionsMultiSelect, multiSelectCheckbox, selectAll) {
    if (fieldKey === actionsMultiSelect) {
      return multiSelectCheckbox.map((obj, index) => {
        //obj.props.icon = obj.icon
        obj.props.id = fieldKey;
        const changeParams = { // making the params instead of relying on event target
          currentTarget: { // keeping the structure like this so I don't mess with other stuff
            attributes: {
              id: {
                nodeValue: obj.props.id
              }
            }
          }
        };
        if (props.disableSelectAll) {
          return null;
        }
        return (
          <Fragment key={fieldKey + index}>
            {React.Children.map(obj.comp, child =>
              React.cloneElement(child, {
                id: obj.props.id,
                checked: selectAll,
                onChange: () => obj.props.onChange(changeParams, !selectAll)
              })//disabled:!selectAllActive
            )
            }
          </Fragment>
        );
      })
    } else if (fieldKey === actions) {
      if (!props.actionTitle) {
        return null;
      }
      return (
        <Tooltip title={props.actionTitle} arrow>
          <Typography className={classes.headerText}>
            {props.actionTitle}
          </Typography>
        </Tooltip>
      )
    } else {
      const field = fieldMap[fieldKey];
      const updatedColumn = field.shortDesc;
      return (
        <Fragment key={fieldKey + (fieldKey === sort.property)}>
          <Tooltip title={updatedColumn} arrow>
            <Typography className={classes.headerText}>
              {updatedColumn}
            </Typography>
          </Tooltip>
          {field.sortable ? (
            <TableSortLabel
              active={fieldKey === sort.property}
              direction={sort.orderBy}
              style={{ opacity: fieldKey === sort.property ? 1 : undefined }}
              className={classes.sortIcon}
              classes={{
                icon:
                  sort.property === fieldKey
                    ? classes.activeSortIcon
                    : classes.inactiveSortIcon
              }}
              IconComponent={ArrowDropDown}
            />
          ) : null}
        </Fragment>
      );
    }
  }

  function rowCell(row, fieldKey, value, multiSelectCheckbox, actions, actionsMultiSelect, unknown, index, searchType) {
    // const onClickRow = extractOnClick(values, row);
    if (row && row.auditTrail && fieldKey === "auditTrail") {
      return;
    }
    if (fieldKey === actionsMultiSelect) {
      const disableSelect = getDeepValue(props.queries, ['disableSelect']);
      const regulatory = getRegulatoryType(row);
      const disabled = (regulatory && disableSelect && disableSelect.includes(low(regulatory))) || (searchType === "label" && !row?.isSelectable);
      return multiSelectCheckbox.map((obj) => {
        //obj.props.icon = obj.icon
        if (props.selectKey) {
          obj.props.id = row[props.selectKey];
        } else {
          obj.props.id = row.id;
        }
        const changeParams = { // making the params instead of relying on event target
          currentTarget: { // keeping the structure like this so I don't mess with other stuff
            attributes: {
              id: {
                nodeValue: obj.props.id
              }
            }
          }
        };
        return (
          <React.Fragment key={row.id + fieldKey}>
            {React.Children.map(obj.comp, child => {
              const selected = row.selected || (obj.props.id && props.selectedId === obj.props.id)
              return React.cloneElement(child, {
                id: obj.props.id,
                checked: !!selected,
                disabled,
                onChange: () => obj.props.onChange(changeParams, !selected)
              })//disabled:!row.isActive
            })}
          </React.Fragment>
        );
      })
    } else if (fieldKey === actions) {
      return value.map((obj, i) => {
        if (obj.actionType === 'setExtraValue') {
          const { propName, required } = obj.props;
          const value = row[propName];
          const error = required && row.selected && !value;
          return (
            <Fragment key={i}>
              {React.Children.map(obj.comp, child =>
                React.cloneElement(child, {
                  ...obj.props,
                  value,
                  error,
                  onChange: event => obj.props.onChange({ props: obj.props, event, row, index })
                })
              )}
            </Fragment>
          );
        }
        if (obj.actionType === 'buttonList') {
          return (
            <Fragment key={i}>
              {React.Children.map(obj.comp, child =>
                React.cloneElement(child, {
                  ...obj.props,
                  items: typeof obj.props.items === 'function' ? obj.props.items(row) :
                    buildSearchActions(obj.props.items, row, history, props.filter, switchPropsValue)
                })
              )}
            </Fragment>
          );
        }
        obj.props.id = row.id;
        return (
          <Fragment key={i}>
            {React.Children.map(obj.comp, child =>
              React.cloneElement(child, { ...obj.props, onClick: () => obj.props.onClick(row.id, row) })
            )}
          </Fragment>
        );
      })
    }

    const field = fieldMap[fieldKey];
    let rowValue = ''
    const functionValuesArray = getFunctionValues(field.function);
    if (functionValuesArray) {
      rowValue = getRowValuePostFunctionParse(functionValuesArray, row)
    } else {
      rowValue = getDeepValue(row, ['values', fieldKey]) ? row.values[fieldKey] : row[fieldKey];
    }

    if (field.type === TableImageTypes.IMAGE) {
      const imgPath = getDeepValue(row, ['values', field.param]) ? row.values[field.param] : row[field.param];

      return (
        <div
          aria-owns={avatarZoomed ? 'avatar-zoom-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={(e) => {
            setAvatarZoomAnchorEl(e.currentTarget);
            setAvatarZoomSrc(itemImageUrl(parseUpc(imgPath)));
          }}
          onMouseLeave={() => {
            setAvatarZoomAnchorEl(null);
            setAvatarZoomSrc(null);
          }}
        >
          <Avatar variant='square' src={itemImageUrl(parseUpc(imgPath))} classes={{ img: classes.img }} />
        </div>
      )

    }

    if (field.type === TableImageTypes.REGULATORY) {
      return <RegulatoryCell item={row} classes={{ img: classes.img }} />;
    }

    let displayed = "";
    if (field.type === FieldTypes.DATE || field.type === FieldTypes.DATE2) {
      displayed = dateFormat(rowValue, DateFormat.TABLE, true);
    } else if (field.type === FieldTypes.BOOLEAN) {
      displayed = notNull(rowValue) ? (rowValue ? 'Yes' : 'No') : rowValue;
    } else if (field.type === FieldTypes.STATUS) {
      displayed = notNull(rowValue) ? getLabel(rowValue) : rowValue;
    } else {
      displayed = rowValue;
    }

    let innerComponent;
    if (displayed) {
      innerComponent = (
        <Tooltip title={displayed} arrow>
          <TableData rowValue={displayed} field={field} fieldKey={fieldKey} row={row} className={classes.tableDataText} />
        </Tooltip>
      );
    } else {
      innerComponent = <TableData rowValue={rowValue} field={field} row={row} className={classes.tableDataText} />;
    }

    return innerComponent;
  }

  const { fieldMap, actions, rowData, values, loadNextPage = doNothing, hasNextPage, isNextPageLoading,
    actionsMultiSelect, multiSelectCheckbox, selectAll, selectAllActive, enableMultiSelect, hideFields,
    searchType, globalDate, specificFieldSearch /*, ignoredFieldKeys */ } = props;
  const actionsArr = actions && values && values.length > 0 ? [actions] : [];

  const filteredMap = filterFields(fieldMap, hideFields);
  const filteredMapAF = filterFieldsAF(fieldMap, hideFields);
  // const uniqueChoices = createSelection(filteredMap, fullRowData);
  const filteredKeys = Object.keys(filteredMap);
  const fieldMapKeys = enableMultiSelect ? [actionsMultiSelect, ...filteredKeys, ...actionsArr] : [...filteredKeys, ...actionsArr]
  const screenWidth = tableDim.width;
  const screenWidthScroll = screenWidth - scrollBarWidth;
  const minColumnWidth = 200;
  const firstColumnWidth = 40;
  const effectiveWidth = screenWidth - (enableMultiSelect ? firstColumnWidth : 0);
  const calcColumnWidth = props.actionWidth ? (effectiveWidth - props.actionWidth) / filteredKeys.length : (effectiveWidth / (filteredKeys.length + (actions ? 1 : 0)));
  const columnWidth = calcColumnWidth < minColumnWidth ? minColumnWidth : calcColumnWidth;
  const customWidth = searchType === SearchTypes.ASSIGNEDCOUPON ? '120' : 110;
  const actionWidth = props.actionWidth ? props.actionWidth : customWidth;
  const height = '100%';
  const rowWidth = screenWidthScroll;
  // const rowWidth = (columnWidth * filteredKeys.length) + (enableMultiSelect ? firstColumnWidth : 0) + actionWidth;
  // const fullScreenWidth = rowWidth > screenWidth;

  const getRowClassName = useCallback(({ index }) => {
    return clsx(classes.tableRow, classes.flexContainer, { [classes.headerRow]: index < 0, [classes.tableRowHover]: index >= 0, [classes.tableRowSelected]: rowData && rowData[index] && rowData[index].selected, [classes.tableRowMultiSelect]: props.enableMultiSelect })
  }, [classes, rowData]);

  const rowGetter = useCallback(({ index }) => rowData[index], [rowData]);

  function renderSearch() {
    if (props.noSearchBar) {
      return null;
    }
    const { uploadCsvProps } = props;
    if (uploadCsvProps) {
      const { handleCsvFileSelect, fileName } = uploadCsvProps;
      return (
        <div className={classes.browseFileContainer}>
          <Paper className={classes.browseFileNameBox}>
            <div className={classes.browseFileNameContainer}>
              <Typography variant='body1'>CSV/TXT File Name:</Typography>
              <Typography variant='body1' className={classes.browseFileName}>{fileName}</Typography>
            </div>
            <SelectFileButton fileId='upload-csv-file' onChange={handleCsvFileSelect} accept='.csv,.txt'>
              <CustomButton
                className={classes.actionButtons}
                variant='contained'
                color='secondary'
                component='div'
                fullWidth
              >
                Browse File
              </CustomButton>
            </SelectFileButton>
          </Paper>
        </div>
      );
    }
    return (
      <SearchFilter
        fieldMap={filteredMap}
        value={filter}
        onChange={(event, isSelection, choice) => handleOnChangeFilter(event, isSelection, choice, { props, setChildOptionsLoading, setFilter, hack, isSearchAll: true })}
        onClickSearch={() => onClickSearchAll({ specificFieldSearch, filter, props, hack })}
        height={height}
        clearSearch={clearSearch}
        style={{ width: '51%', visibility: props.noSearchBar ? 'hidden' : 'visible' }}
        specificFieldSearch={specificFieldSearch}
        disabled={noAdvanced ? false : props.showFilter}
        disableSearchBtn={isEmptyFilter({ specificFieldSearch, filter })}
      />
    )
  }

  function renderNote(message) {
    return (
      <div className={classes.noteContainer}>
        <Note title={message} spacing={8} spaceTop={0} />
      </div>
    );
  }


  function renderFilter() {
    return (
      <FilterColumn
        fieldMap={filteredMapAF}
        uniqueChoices={uniqueChoices}
        value={filter}
        onChange={(event, isSelection, choice) => handleOnChangeFilter(event, isSelection, choice, { props, setChildOptionsLoading, setFilter, stateFilter: filter, hack })}
        height={height}
        clearSearch={() => clearSearch({ setFilter, props, hack })}
        onClickSearch={() => onClickSearch(null, { filter, props, hack })}
        loading={filterChoicesLoading}
        childOptionsLoading={childOptionsLoading}
        searchLoading={props.searchLoading}
        filter={props.filter}
        switchValue={props.showAllOffersSwitch() && switchPropsValue}
      /*ignoredFieldKeys={ignoredFieldKeys}*/
      />
    )
  }

  function renderFilterWrapper() {
    if (noAdvanced) {
      return null;
    }
    if (mobileView) {
      return (
        <Modal
          open={props.showFilter}
          disableAutoFocus
          disableBackdropClick
          disableEscapeKeyDown
          disableRestoreFocus
          className={classes.fullScreenModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          keepMounted
        >
          <Slide direction='up' in={props.showFilter}>
            <div className={classes.filterContainerWrapper}>
              {renderFilter()}
              <IconButton className={classes.closeButton} onClick={() => props.setShowFilter(false)}>
                <CloseIcon fontSize='medium' />
              </IconButton>
            </div>
          </Slide>
        </Modal>
      );
    }
    return (
      <Grid item xs={12} md={2} className={`${classes.filterContainer} ${!props.showFilter ? classes.hideFilter : ''}`} style={{ height, minWidth: props.showFilter && !noAdvanced ? minColumnWidth * 0.8 : 0 }}>
        {renderFilter()}
      </Grid>
    );
  }

  function renderRefresh(variant) {
    if (props.onRefresh) {
      return (
        <Button
          onClick={props.onRefresh}
          className={classes.refreshIconButton}
          style={{
            visibility: 'visible',
            marginRight: 16,
            marginLeft: 'auto'
          }}
          endIcon={<RefreshIcon className={classes.refreshIcon} />}
        >
          Refresh
        </Button>
      )
    }
  }

  function renderMyOffersSwitch() {
    const { showAllOffersSwitch, switchProps } = props;
    if (showAllOffersSwitch && showAllOffersSwitch()) {
      return <TemplateRadio {...switchProps} />
    }
    return null;
  }
  const [disableExportAfterClick, setDisableExportAfterClick] = React.useState(false);
  const BulkOption = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
      setSubMenuAnchorEl(null)
    };
    const handleSubMenuOpen = (event) => {
      setSubMenuAnchorEl(event.currentTarget)
    };
    const handleSubMenuClose = () => {
      setSubMenuAnchorEl(null)
    };
    return (
      <div style={{ marginRight: '16px', marginLeft: 'auto', display: 'block' }}>
        <Button
          id="basic-button"
          className={classes.exportButton}
          aria-controls={open ? "basic-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={<ArrowDropDown />}
          color="secondary"
          data-testid="bulk-export-button"
        >
          Bulk
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          anchorPosition={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          {/* <MenuItem disabled={props.duplicateDisable} onClick={props.bulkDuplicateHandler}>Duplicate to Campaign...</MenuItem> */}
          <MenuItem disabled={props.exportDisable} onClick={() => props.exportHandler(ExportTypes.EXPORT_BY_ITEM)}>Export By Item</MenuItem>
          <MenuItem disabled={props.exportDisable} onClick={() => props.exportHandler(ExportTypes.EXPORT_BY_OFFER)}>Export By Offer</MenuItem>
          <MenuItem onClick={handleClose}>Cancel</MenuItem>
          {/* <MenuItem onClick={handleSubMenuOpen}>
            Export
            <ArrowDropDown />
          </MenuItem>
          <Popover
            open={Boolean(subMenuAnchorEl)}
            anchorEl={subMenuAnchorEl}
            onClose={handleSubMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            anchorPosition={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              style: { width: 210 }
            }}
            disableRestoreFocus
          >
            <menu
              anchorEl={subMenuAnchorEl}
              open={Boolean(subMenuAnchorEl)}
              onClose={handleSubMenuClose}
              data-testid="bulk-export-submenu"
            >
              <MenuItem disabled={props.exportDisable} onClick={() => props.exportHandler(ExportTypes.EXPORT_BY_ITEM)}>Export By Item</MenuItem>
              <MenuItem disabled={props.exportDisable} onClick={() => props.exportHandler(ExportTypes.EXPORT_BY_OFFER)}>Export By Offer</MenuItem>
              <MenuItem onClick={handleSubMenuClose}>Cancel</MenuItem>
            </menu>
          </Popover> */}
        </Menu>
      </div>
    );
  }
  function renderExportButton() {
    const { fullRowData, showExportButton, exportHandler, bulkDuplicateHandler } = props;
    if (showExportButton) {
      let exportDisable = false;
      let duplicateDisable = false;

      const filtered = fullRowData.filter(r => r.selected && r.selected === true)
      if (filtered.length === 0) {
        duplicateDisable = true;
        exportDisable = true;
      } else {
        duplicateDisable = false;
        exportDisable = false;
      }
      return (
        <BulkOption exportHandler={exportHandler} exportDisable={exportDisable} duplicateDisable={duplicateDisable} bulkDuplicateHandler={bulkDuplicateHandler} />
      )
    }
  }

  const childrenMemo = useMemo(() => {
    return fieldMapKeys.map((key, index) => {
      let calcColumnWidth, cellWidth = 1, headerWidth = 1, shrink = 1, setWidth;
      let minWidth;
      const field = fieldMap[key];
      if (field && field.width) {
        // set shrink and grow to 0, set fixed width
        calcColumnWidth = 0;
        shrink = 0;
        setWidth = +field.width;
      } else if (field && field.minWidth) {
        calcColumnWidth = 1;
        shrink = 0;
        minWidth = +field.minWidth;
      } else {
        if (field && field.shortDesc) {
          headerWidth = field.shortDesc.split(' ').reduce((previousValue, currentValue) => {
            if (currentValue && currentValue.length > previousValue.length) {
              return currentValue;
            }
            return previousValue;
          }).length;
        }
        if (rowData && rowData[0] && rowData[0][key]) {
          cellWidth = rowData[0][key].length;
        }

        calcColumnWidth = cellWidth > headerWidth ? cellWidth : headerWidth;
      }

      const last = props.actions && index === fieldMapKeys.length - 1;
      const checkbox = props.enableMultiSelect && index === 0;
      const imageType = field ? Object.keys(TableImageTypes).includes(field.type) : false;
      const width = setWidth ? setWidth : (last ? actionWidth : checkbox ? firstColumnWidth : 1);
      const maxMinWidth = width === 1 ? undefined : width;

      return (
        <Column
          width={width}
          maxWidth={maxMinWidth}
          minWidth={minWidth ? minWidth : maxMinWidth}
          flexGrow={calcColumnWidth}
          flexShrink={shrink}
          key={key + index + (key === sort.property)}
          label={key}
          dataKey={key}
          headerRenderer={() => {
            return (
              headerCell(key, index, actions, actionsMultiSelect, multiSelectCheckbox, selectAll)
            )
          }}
          headerClassName={clsx(
            classes.rowCell,
            classes.flexContainer,
            classes.headerCell,
            {
              [classes.rowCellStickyLeft]: props.enableMultiSelect && index === 0,
              [classes.headerRowCellStickyRight]: last && props.actionTitle,
              [classes.rowCellStickyRight]: last && !props.actionTitle,
              [classes.selectedHeaderCell]: key === sort.property,
              [classes.headerImageCell]: imageType,
              [classes.rowCheckbox]: checkbox
            }
          )}
          className={clsx(
            classes.rowCell,
            classes.flexContainer,
            {
              [classes.rowCellStickyLeft]: props.enableMultiSelect && index === 0,
              [classes.rowCellStickyRight]: last,
              [classes.imageCell]: imageType,
              [classes.rowCheckbox]: checkbox
            }
          )}
          headerStyle={{
            height: headerHeight,
            cursor: key !== actionsMultiSelect && key !== actions ? 'pointer' : undefined
          }}
          style={{
            height: rowHeight,
            maxWidth: '100%'
          }}
          cellRenderer={({ rowIndex }) => {
            return (
              rowCell(rowData[rowIndex], key, values, multiSelectCheckbox, actions, actionsMultiSelect, null, index, searchType)
            )
          }}
        />
      )
    })
  }, [
    fieldMap,
    props.actions,
    props.enableMultiSelect,
    props.actionWidth,
    props.selectedId,
    props.searchLoading,
    rowWidth,
    actionWidth,
    columnWidth,
    // classes.rowCell,
    // classes.rowCellStickyRight,
    // classes.flexContainer,
    // headerCell,
    actions,
    actionsMultiSelect,
    // multiSelectCheckbox,
    selectAll,
    selectAllActive,
    searchType,
    // rowCell,
    rowData,
    values,
    sort
  ]);

  const tableMemo = useMemo(() => {
    const rowDataLength = rowData?.length || 0;
    const itemCount = hasNextPage ? rowDataLength + 1 : rowDataLength;
    const loadMoreItems = isNextPageLoading ? doNothing : loadNextPage;
    const isItemLoaded = ({ index }) => (!hasNextPage || index < rowDataLength);

    return (
      <InfiniteLoader
        isRowLoaded={isItemLoaded}
        rowCount={itemCount}
        minimumBatchSize={SearchTable.MINIMUM_BATCH_SIZE}
        threshold={SearchTable.THRESHOLD}
        loadMoreRows={loadMoreItems}
      >
        {({ onRowsRendered, registerChild }) => {
          return (
            <Table
              // orderBy={sort.orderBy} // just here so it knows when to rerender
              // sortProp={sort.property} // same here
              globalDate={globalDate} // same here
              className={clsx(classes.table, { [classes.tableModal]: props.isModal })}
              height={0}
              gridHeight={tableDim.height}
              ref={registerChild}
              onRowsRendered={onRowsRendered}
              rowGetter={rowGetter}
              rowHeight={rowHeight}
              headerHeight={headerHeight}
              rowCount={rowDataLength}
              rowWidth={rowWidth}
              width={screenWidth}
              // columnWidth={columnWidth}
              rowClassName={getRowClassName}
              rowStyle={rowStyle}
              rowData={rowData}
              onRowClick={({ index, event }) => onRowClick({ index, event, values, rowData, history, filter: props.filter, switchValue: switchPropsValue })}
              onHeaderClick={({ dataKey }) => onHeaderClick({ dataKey, actions, actionsMultiSelect, filteredMap, sort, props })}
              headerRowRenderer={headerRowRenderer}
              overlayComponent={props.searchLoading ? customLoader(classes) : renderNote(props.overlayMessage)}
              noRowsRenderer={props.noRowsRenderer}
              searchLoader={props.searchLoading}
            >
              {childrenMemo}
            </Table>
          )
        }}
      </InfiniteLoader>
    )
  }, [childrenMemo, rowData, hasNextPage, loadNextPage, globalDate, props.overlayMessage, props.searchLoading, screenWidth])

  return (
    <div
      ref={el => {
        if (!el) {
          return;
        }

        const rect = el.getBoundingClientRect();
        const width = rect.width - sideSpace;
        const height = rect.height;
        if (width !== tableDim.width) {
          setTableDim({ width, height });
        }
      }}
      className={clsx(classes.root, { [classes.hidden]: !tableDim.width })}
      style={{ height: `calc(100% - ${props.heightAdjust}px)` }}
    >
      {props.filterOverride ? null : (
        <div className={classes.container}>
          <div className={clsx(classes.tableActionsContainer, { [classes.tableActionsContainerModal]: props.isModal })} style={{ maxWidth: screenWidth }}>
            {noAdvanced || (props.noSearchBar && props.showAdvancedInitial) ? null : (
              <FormControlLabel
                value='advancedFilter'
                control={<Switch color='secondary' size='small' checked={props.showFilter} />}
                label='Advanced filters'
                labelPlacement='start'
                className={classes.advancedSwitch}
                onChange={() => props.setShowFilter(show => !show)}
              />
            )}
            {props.statsComponent ? props.statsComponent : null}
            {renderSearch()}
            {renderExportButton()}
            {renderRefresh('right')}
            {renderMyOffersSwitch()}
          </div>
          {props.noMatch ? (
            <div className={classes.noteWrapper}>
              {renderNote('Could not find ' + props.noMatch.join(', '))}
            </div>
          ) : null}
        </div>
      )}
      <Grid container className={classes.tableContainerGrid} style={{ maxWidth: screenWidth, height: props.filterOverride ? '100%' : `calc(100% - 60px)` }} wrap='nowrap'>
        {renderFilterWrapper()}

        <Grid
          item
          md={props.showFilter && !noAdvanced ? 10 : 12}
          className={clsx(classes.tableContainer, { [classes.tableContainerModal]: props.isModal, [classes.tableContainerMultiSelect]: props.enableMultiSelect })}
        >
          {tableMemo}
        </Grid>
      </Grid>
      <Popover
        id="avatar-zoom-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={avatarZoomed}
        anchorEl={avatarZoomAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        onClose={() => {
          setAvatarZoomAnchorEl(null);
        }}
        disableRestoreFocus
      >
        {
          avatarZoomSrc ?
            <img height={POPOVER_IMG_ZOOMED_HEIGHT} src={avatarZoomSrc} alt="avatar" /> :
            null
        }
      </Popover>
    </div>
  );
};

export default React.memo(CustomTable);
