import gql from "graphql-tag";

const GET_WORKFLOW_BY_ID = gql`
query getWorkflow($workflowId: String!) {
     workflow(workflowId : $workflowId ) @rest(type: "workflow", path: "workflow/{args.workflowId}") {
          payload
    }
}`

export default GET_WORKFLOW_BY_ID

export const RESEND_APPROVAL = gql`
mutation($workflowId: String!) {
  approvalResend(workflowId: $workflowId, input: $input) @rest(type: "approvalResend", path: "workflow/notify/{args.workflowId}", method: "POST") {
    status,
    header,
    errors,
    payload
  }
}`;

export const GET_ITEM_BY_ID = gql`
query($id: String!, $type: String!) {
  workflowItem(id: $id, type: $type) @rest(type: "workflowItem", path: "{args.type}/{args.id}", method: "GET") {
    status,
    header,
    errors,
    payload
  }
}`;

export const DELETE_ITEM_BY_ID = gql`
mutation($id: String!, $type: String!) {
  workflowItem(id: $id, type: $type) @rest(type: "workflowItem", path: "{args.type}/{args.id}", method: "DELETE") {
    status,
    header,
    errors,
    payload
  }
}`;

export const EXECUTE_WORKFLOW = gql`
mutation ($workflowId: String!, $type: String!) {
  executeWorkflow(workflowId: $workflowId, input: $input, type: $type, save: $save) @rest(type: "executeWorkflow", path: "workflow/execute/{args.type}?save={args.save}", method: "POST") {
    status,
    header,
    errors,
    payload
  }
}`;

export const EXECUTE_BULK_WORKFLOW = gql`
mutation ($workflowId: String!, $type: String!) {
  executeWorkflow(workflowId: $workflowId, input: $input, type: $type, save: $save) @rest(type: "executeWorkflow", path: "workflow/execute/bulk/{args.type}", method: "POST") {
    status,
    header,
    errors,
    payload
  }
}`;

export const LOCAL_ROLE = gql`
fragment WorkflowRole on Workflow {
  role
 }
`;



