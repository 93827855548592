import React, { useEffect, useState, useMemo } from "react";
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import { v4 as uuid } from 'uuid';
import styles from "./TemplateMix.styles";
import CsvInput from "../../diff/inputs/CsvInputDiff";
import { TemplateMixTypes, CSV_BIGLIST_LIMIT, CLUB_BIGLIST_LIMIT, BlobProcessStatus, low, SearchTypes } from '../../../constants/Constants';
import { useFileTracker } from '../../../containers/biglist/FileTracker/FileTracker';
import getAllBiglistValuesService from '../../../services/biglist/GetAllBiglistValuesService';
import getBiglistService from '../../../services/biglist/GetBiglistService';
import getBiglistEditValues from '../../../Utils/template/getBiglistEditValues';
import getDeepValue from '../../../Utils/getDeepValue';

const TemplateMix = props => {
  const { classes, field, setOthers } = props;
  const {
    longDesc,
    name,
    searchType,
    enableSearch,
    enableBiglist,
    mode,
    data,
    required,
    shortDesc,
    readOnly,
    limit,
    onChange,
    value,
    error,
    serverValue,
    queries,
    parentValue,
    excludeClubs,
    countryFilter,
    initDisable
  } = field;

  const convertBiglist = getDeepValue(queries, ['convertBiglist']);
  const isViewOnly = (mode === "viewOnly");

  const [localValues, setLocalValues] = useState(null);
  const [biglistStatus, setBiglistStatus] = useState(null);

  const parentMixType = (value && value.biglist) ? TemplateMixTypes.BIGLIST : TemplateMixTypes.CSV;

  useEffect(() => {
    if (!localValues && low(parentMixType) === TemplateMixTypes.BIGLIST && value && value.biglist && !value.replace) {
      const biglistName = value.biglist;

      getBiglistService(biglistName).then(async (biglist) => {
        if (biglist && !biglist.errors) {
          biglist.status = biglist.status && biglist.status.toUpperCase();
          if (biglist.status === BlobProcessStatus.COMPLETED) {
            const limit = low(SearchTypes.CLUB) === low(searchType) ? CLUB_BIGLIST_LIMIT : CSV_BIGLIST_LIMIT;
            const response = await getAllBiglistValuesService(biglistName, limit)
            if (response && response.items) {
              const values = response.items.map((item) => {
                return item.id;
              })
              setLocalValues(values);

              // set parentValue for club_override_list
              setOthers({ field, value: { csv: values.join(',') } });
            }
          }
          setBiglistStatus(biglist.status === BlobProcessStatus.COMPLETED || biglist.status === BlobProcessStatus.FAILED ? biglist.status : BlobProcessStatus.LOADING);
        } else {
          setBiglistStatus(BlobProcessStatus.LOADING);
        }
      }).catch(() => {
        setBiglistStatus(BlobProcessStatus.LOADING);
      })
    } else {
      // setLocalValues(null)
    }
  }, [value && value.biglist])

  const prepareDownload = useFileTracker().prepareDownload;

  const biglistContinue = (value && value.biglist) || (!value && serverValue && serverValue.biglist);

  const [showLimit, setShowLimit] = useState(false);

  const listValue = useMemo(() => {
    if (value) {
      if (value.csv) {
        return value.csv.split(',')
      } else if (value.biglist) {
        if (isViewOnly) {
          if (localValues) {
            return localValues;
          } else {
            if (biglistStatus === BlobProcessStatus.FAILED) {
              return <span>Upload Failed</span>
            } else if (biglistStatus === BlobProcessStatus.LOADING || !biglistStatus) {
              return <span>Loading...</span>
            }
            return <span>Empty list</span>;
          }
        } else {
          if (localValues || value.editList) {
            if (value.editList) {
              let list = [];
              const { addList, removeList } = getBiglistEditValues(value.editList);
              if (addList && addList.length > 0) {
                list = addList;
              }

              if (localValues) {
                if (removeList && removeList.length > 0) {
                  list = list.concat(localValues.filter((val) => !removeList.includes(val)))
                } else {
                  list = list.concat(localValues)
                }
              }
              return list;
            } else {
              return localValues;
            }

          }
          return []
        }
      }
    }
  }, [value, localValues, isViewOnly, biglistStatus]);

  const editListValue = useMemo(() => {
    if (value) {
      if (value.editList) {
        return value.editList;
      } else {
        return {};
      }
    }
  }, [value]);

  const isReplace = value && value.replace;

  function downloadBiglist() {
    if (prepareDownload) {
      prepareDownload(value.biglist, true)
    }
  }

  const handleChange = (event, type = TemplateMixTypes.BIGLIST, replace) => {
    if (biglistStatus) {
      setBiglistStatus(null);
    }
    if (event && event.target) {
      const { value: eventValue, fullValue } = event.target;
      let targetValue;
      const prevValue = value || {};
      if (type === 'editList') {
        if (replace) {
          setLocalValues([])
        }
        targetValue = { ...prevValue, [type]: eventValue, replace: prevValue.replace || replace, uuid: uuid(), fullValue };
      } else {
        targetValue = { [type]: eventValue, uuid: uuid(), fullValue }
      }
      onChange({
        target: { value: targetValue }
      });
    }
  };

  const onClear = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (biglistStatus) {
      setBiglistStatus(null);
    }
    onChange({
      target: {
        value: {}
      }
    });
  }

  const closeDialog = () => {
    setShowLimit(false);
  }

  const isDisabled = readOnly || isViewOnly || initDisable;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.csvContainer}>
          <CsvInput
            margin="normal"
            placeholder={longDesc}
            fullWidth
            name={name}
            value={listValue}
            fullValue={value ? value.fullValue : ''}
            onChange={handleChange}
            required={required}
            disabled={isDisabled}
            error={error}
            label={shortDesc}
            searchType={searchType}
            onClear={onClear}
            limit={limit}
            enableSearch={enableSearch}
            enableBiglist={enableBiglist}
            mode={mode}
            data={data}
            parentMixType={parentMixType}
            biglistContinue={biglistContinue}
            editListValue={editListValue}
            downloadBiglist={prepareDownload && low(parentMixType) === TemplateMixTypes.BIGLIST ? downloadBiglist : null}
            table={props.table}
            queries={queries}
            isReplace={isReplace}
            parentValue={parentValue}
            excludeClubs={excludeClubs}
            biglistStatus={biglistStatus}
            biglistName={value ? (convertBiglist && value.editList ? '' : value.biglist) : ''}
            countryFilter={countryFilter}
          // extraDisplayValues={extraDisplayValues}
          />
        </div>
      </div>
      <Dialog
        open={showLimit}
        onClose={closeDialog}
      >
        <DialogTitle>{`${longDesc} CSV selection over the limit of ${limit}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`The ${longDesc} selection will automatically be converted to biglist when promotion is created.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color='secondary'>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export { TemplateMix as TestTemplateMix }
export default withStyles(styles)(TemplateMix);
