function removeKey(object, key) {
  const newObject = {};
  const objectKeys = Object.keys(object);
  objectKeys.forEach((objKey) => {
    if (objKey !== key) {
      newObject[objKey] = object[objKey]
    }
  })
  return newObject;
}

export default removeKey;
