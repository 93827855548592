import { RootTypes } from '../../constants/Constants';

function getWorkflowName(type, item) {
  if (!type || !item) {
    return null;
  }
  switch (type) {
    case RootTypes.CAMPAIGN:
      return item.values.campaign_name;
    case RootTypes.PROMOTION:
      return item.values.promotion_name;
    case RootTypes.COUPON:
      return item.couponGroupName || item.code;
    default:
      return item.name;
  }
}

function getLocation(location) {
  if (!location) {
    return null;
  }
  const { pathname, search } = location;
  return `${pathname}${search ? search : ''}`;
}

export default function trackEvent({ reactPlugin, rootType, payload, location, user }) {
  reactPlugin.trackEvent({ name: 'recents' }, {
    location: getLocation(location),
    name: rootType ? `${rootType} ${getWorkflowName(rootType, payload)}` : null,
    userId: (typeof user.id !== "undefined") ? user.id: null
  })
}
