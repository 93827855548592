
import { FieldTypes } from '../../constants/Constants';

export default (indexMap) => {
  if (!(indexMap && indexMap.fields)) {
    return null;
  }
  return indexMap.fields.reduce((acc, val) => {
    if ((val.advancedFilter && !val.skipFacetable) && val.type && [FieldTypes.STRING].includes(val.type.toUpperCase())) {
      if (val.count) {
        return [...acc, `${val.name.replace(/\./g, '/')},count:${val.count}`]
      } else {
        return [...acc, val.name.replace(/\./g, '/')]
      }
    }
    return acc;
  }, []);
}
