import FileCopy from '@material-ui/icons/FileCopy';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import CloudDownload from '@material-ui/icons/CloudDownload';

const getImages = (imageId) => {
  switch(imageId){
    case 'CLOUDDOWNLOAD': return CloudDownload;
    case 'DELETE': return Delete;
    case 'CREATE': return Create;
    case 'FILECOPY': return FileCopy;
    default: return null;
  }
}
export default getImages;
