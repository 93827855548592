import React, { useState } from 'react';
import {
  KeyboardDatePicker
} from '@material-ui/pickers';
import { FormControl, FormLabel, FormHelperText } from "@material-ui/core";
import moment from 'moment';
import clsx from 'clsx';
import useStyles from './DateInputStyles';
import TextInput from './TextInput';
import { DateFormat } from '../../constants/Constants';

function DateInput(props) {
  const classes = useStyles();
  const { label, value, disabled, size, onChange, enablePast, defaultValue, maxDate, minDate, variant, required, error, ...otherProps } = props;
  const [show, setShow] = useState(false);

  let dateValueProps = { inputValue: '', value: null };
  let date;
  if (value) {
    const dateValue = moment(value);
    date = dateValue.format(DateFormat.DATE);
    if (dateValue.isValid()) {
      dateValueProps = { inputValue: date };
    }
  }

  function handleOnChange(_, dateString) {
    if (dateString) {
      const date = moment(dateString);
      const formatDate = date.format(DateFormat.DATE);
      props.onChange({ target: { value: formatDate } });
    } else {
      props.onChange({ target: { value: '' } });
    }
  }

  // Hide selected if nothing is selected (not sure why it displays today selected)
  function renderDay(_day, _selectedDates, _dayProps, component) {
    if (dateValueProps && dateValueProps.inputValue) {
      return component
    } else {
      return React.cloneElement(component, {selected: false})
    }
  }

  const dateComponent = (
    <KeyboardDatePicker
      maxDate={maxDate ? moment(maxDate).format() : undefined}
      minDate={minDate ? moment(minDate).format() : undefined}
      {...otherProps}
      required={required}
      error={error}
      variant={variant}
      open={show}
      onClose={() => setShow(false)}
      label={label}
      placeholder={label}
      renderDay={variant === 'static' ? renderDay : null}
      format={DateFormat.DATE_PICKER}
      KeyboardButtonProps={{
        tabIndex: -1,
        className: clsx([
          classes.icon,
          {
            [classes.iconSmall]: size === 'small',
            [classes.iconDisabled]: disabled
          }
        ])
      }}
      disablePast={!enablePast && !disabled}
      disabled={disabled}
      TextFieldComponent={(props) => <TextInput {...props} onClick={() => disabled ? null : setShow(true)} />}
      onChange={handleOnChange}
      invalidDateMessage=''
      {...dateValueProps}
      InputAdornmentProps={{
        className: clsx([classes.adornment, {[classes.adornmentSmall]: size === 'small'}])
      }}
      clearable
    />
  )

  if (variant === 'static') {
    return (
      <FormControl component="fieldset" classes={{ root: classes.staticDateContainer }}>
        {label ? <FormLabel component="legend" className={classes.label}>{label}</FormLabel> : null}
        <div className={classes.staticDateWrapper}>
          {dateComponent}
        </div>
        <FormHelperText className={classes.formHelperError} style={{display : error ? 'block' : 'none'}}>{error}</FormHelperText>
      </FormControl>
    )
  }

  return (
    <div className={classes.dateContainer}>
      {dateComponent}
    </div>
  );
}

DateInput.defaultProps = {
  size: 'small'
}

export default DateInput;
