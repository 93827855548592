import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function getUserService(userId) {
  if (userId) {
    try {
      const user = await fetch(`${baseURL}users/${userId}`, {
        method: 'GET',
        headers: baseHeaders()
      }).then(function (res) {
        return res.json();
      }).then(function (data) {
        return data;
      });

      return user;
    } catch(error) {
      return null;
    }
  }

  return null;
}

export default getUserService
