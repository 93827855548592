import Colors from '../../theme/Colors';

const styles = () => ({
  repeatableHeader: {
    backgroundColor: Colors.primaryBlue,
    width: '100%',
    height: '16px',
    margin: '0 0 0 -16px',
    padding: '5px 16px',
    color: Colors.white,
    fontSize: '13px',
    fontFamily: 'ROBOTO',
    fontWeight: '500',
    display: 'flex',
    justifyContent: 'space-between'
  }
})

export default styles;
