import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core";
import styles from "./Template.styles";
import DateInput from '../../inputs/DateInput';
import ViewOnly from '../../viewOnly/ViewOnly';
import checkDateBoundary from '../../../Utils/date/checkDateBoundary';
import { TemplateErrors } from "../../../constants/Errors";
import getDeepValue from "../../../Utils/getDeepValue";

const TemplateDate = props => {

  const { field, classes } = props;
  const {
    groupName,
    name,
    mode,
    readOnly,
    minDate,
    maxDate,
    onChange,
    value,
    error,
    initDisable
  } = field;

  useEffect(() => {
    const outOfBounds = checkDateBoundary({ value, minDate, maxDate });
    if (outOfBounds && !error) {
      onChange({
        target: { value, error: TemplateErrors.OUT_OF_BOUNDS }
      });
    }
  }, [value, minDate, maxDate, onChange, name, groupName, error]);
  const isViewOnly = (mode === "viewOnly");
  const isDisabled = readOnly || isViewOnly ? (readOnly || isViewOnly) : false || initDisable;

  const moreProps = {
    placeholder: field.longDesc,
    label: field.shortDesc,
    className: classes.root,
    disabled: isDisabled,
    margin: "normal",
    onChange: onChange,
    mode: mode,
    data: field.data,
    value: value,
    required: field.required,
    error: error,
    minDate: minDate,
    maxDate: maxDate,
    defaultValue: field.defaultValue,
    condition: name,
    variant: getDeepValue(field, ['queries', 'variant'])
  }

  if (isViewOnly) {
    return (
      <ViewOnly
        {...moreProps}
        fullWidth
      />

    )
  } else {

    return (
      <DateInput
        {...moreProps}
        fullWidth
      />
    );
  }
};
export { TemplateDate as TestTemplateDate }

export default withStyles(styles)(TemplateDate);
