import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./Template.styles";
import ViewOnly from '../../viewOnly/ViewOnly';
import TimeInput from "../../inputs/TimeInput";

const TemplateTime = props => {

  const { field, classes } = props;
  const {
    mode,
    readOnly,
    initDisable
  } = field;

  const isViewOnly = (mode === "viewOnly");
  const isDisabled = readOnly || isViewOnly ? (readOnly || isViewOnly) : false || initDisable;

  const finalProps = {
    placeholder: field.longDesc,
    label: field.shortDesc,
    className: classes.root,
    disabled: isDisabled,
    margin:"normal",
    onChange:field.onChange,
    value:field.value,
    condition:field.name,
    mode:mode,
    data:field.data,
    required:field.required,
    error:field.error,
    minDate:field.minDate,
    maxDate:field.maxDate,
    defaultValue:field.defaultValue
  }

  if(isViewOnly){
    return(
      <ViewOnly
        fullWidth
        {...finalProps}
      />
    )
  } else{
    return (
      <TimeInput
        fullWidth
        {...finalProps}
      />
    );
  }

};

export default withStyles(styles)(TemplateTime);
