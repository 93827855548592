import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function updateRolesService(roles, readOnly) {
  try {
    const body = {
      ids: roles.map((role) => role.id)
    }
    const response = await fetch(`${baseURL}users/roles?readOnly=${readOnly}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });
    return response;
  } catch (error) {
    return error;
  }
}

export default updateRolesService
