import React from 'react';
import PropTypes from "prop-types";
import CustomButton from '../buttons/CustomButton/CustomButton';

export default function EditorActions(props) {
  return (
    <>
      <CustomButton onClick={props.onCancel} label='Cancel' color='secondary' variant="outlined" />
      <CustomButton variant="contained" label='Save' color='secondary' onClick={props.onSave} disabled={!props.enableSave} />
    </>
  )
}

EditorActions.propTypes = {
  /** @type {function} add created entries file to the files state in FileTracker. */
  onCancel: PropTypes.func,
  /** @type {function} open the modal with created file to upload it to cloud. */
  onSave: PropTypes.func
}
