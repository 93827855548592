import React from 'react';
import FullScreenModal from '../../../widgets/modal/FullScreenModal';
import { SearchModalActions } from '../../../constants/Constants';
import SearchIn from './SearchIn';
import History from './History';
import SearchFor from './SearchFor';
import parseItemString from '../../../Utils/parseItemString';
import SearchNoIndex from '../SearchNoIndex';
import getCompartmentTypes from '../../../Utils/compartments/getCompartmentTypes';
import BigListEdit from '../../../Components/biglist/BigListEdit/BigListEdit';
import BigListLinked from '../../../Components/biglist/BigListLinked/BigListLinked';

function SearchModal(props) {
  const { open, onRequestClose, onRequestCloseSuccess, items, action, params, fieldMap } = props;

  const item = items && items[0] ? items[0] : items;

  const label = params && params.label
  const description = parseItemString(item, params && params.description);

  function renderContents() {
    switch (action) {
      case (SearchModalActions.SEARCHIN):
        return <SearchIn {...props} />;
      case (SearchModalActions.HISTORY):
        return <History {...props} />;
      case (SearchModalActions.SEARCHFOR):
        return <SearchFor {...props} />;
      case (SearchModalActions.TABULAR):
        return (
          <SearchNoIndex
            item={item}
            searchActionCompartments={params && params.moreParams && params.moreParams.actions && params.moreParams.actions.split(',')}
            searchType={getCompartmentTypes.PROMOTION}
            rootType='items'
            fieldMap={fieldMap}
            noAdvanced
          />
        )
      case (SearchModalActions.EDIT_BIGLIST):
        return [
          <BigListLinked
            type={item.type}
            selectedFile={item.name}
            close={onRequestClose}
            key='biglistlinked'
          />,
          <BigListEdit
            type={item.type}
            selectedFile={item.name}
            close={onRequestClose}
            key='biglistedit'
          />
        ]
    }

    return null;
  }

  return (
    <FullScreenModal
      open={open}
      onRequestClose={onRequestClose}
      onRequestCloseSuccess={onRequestCloseSuccess}
      title={label}
      secondaryTitle={description}
    >
      {renderContents()}
    </FullScreenModal>
  );
}

export default React.memo(SearchModal);
