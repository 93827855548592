import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { Aborter } from '@azure/storage-blob';
import { Status, BlobProcessStatus, ModalOverlayMessage, BlobActions, BlobDelimiter, BlobTypes, validationMessages, low } from '../../../constants/Constants';
import ProgressMenu from '../../../Components/biglist/ProgressMenu/ProgressMenu';
import getIndexOfFileName from '../../../Utils/getIndexOfFileName';
import addBlobService from '../../../services/biglist/AddBlobService';
import useCustomSnackbar from '../../../hooks/useCustomSnackbar';
import { baseURL, getHeaders, Index } from '../../../constants/Endpoints';
import { HIDE_DOWNLOAD_NOTIFICATION } from '../../../queries/biglistQueries';
import downloadURI from '../../../Utils/downloadURI';
import charWhitelist from '../../../Utils/biglist/charWhitelist';
import { useProcessingDispatchContext, removeProcess } from '../../processing/BackgroundProcessingProvider';
import prepareDownloadService from '../../../services/biglist/PrepareDownloadService';
import getBigListService from '../../../services/biglist/GetBiglistService';
import createBiglistMetadataService from '../../../services/biglist/CreateBiglistMetadataService';
import UserComptContext from '../../../setup/compartments/compartment.context';
import ConfirmationDialog from '../../../widgets/Confirmation/ConfirmationDialog';
import createFilesMetadataService from '../../../services/biglist/createFilesMetadataService';
import { getFileNameAndExtension } from '../../../Utils/helpers';
import fetchAll from '../../../Utils/services/fetchAll';

const DOWNLOAD_NOTIFICATION_PROCESS = 'DOWNLOAD_NOTIFICATION_PROCESS'

export const FileTrackerContext = createContext({});
export const useFileTracker = () => useContext(FileTrackerContext);

function onBeforeUnload(e) {
  e.preventDefault();
  // Chrome requires returnValue to be set
  e.returnValue = '';
}

// let downloadInterval;
let statusTimeout;

/**
 *
 * Component provide methods to upload/download files,
 * show notification and progress for file downloads/uploads and poll biglist metadata API for status changes of REQUESTED downloads.
 * Returns a context provider.
 */
function FileTracker(props) {
  /**
   * @type {object} - files to be uploaded, overall status and speed.
   * @example {
   * "speed":0,
   * "status":"Ready",
   * "totalBytes":0,
   * "totalLoaded":0,
   * "files":[{
   * "file":{"action":"append"},"status":"Ready","total":9604,"time":0,"loaded":0,"lastLoaded":0,"lastSpeed":0,"speed":0,"name":"86906MEMBERBIGLIST.csv","action":"append"},
   * {"file":{"action":"delete"},"status":"Ready","total":533806,"time":0,"loaded":0,"lastLoaded":0,"lastSpeed":0,"speed":0,"name":"89538MEMBERBIGLIST.csv","action":"delete"}
   * ]}
   */
  const [state, setState] = useState({
    speed: 0,
    status: Status.READY,
    totalBytes: 0,
    totalLoaded: 0,
    files: []
  });
  const [autoDownload, setAutoDownload] = useState({})
  /**
   * @type {object} - store the download progress of a file.
   * @example <caption>Sample fileDownloads object</caption>
   * {
   * "88430MEMBERBIGLIST": {
   *  "file":{"name":"88430MEMBERBIGLIST"},
   *  "status":"Loading",
   *  "total":96877060,
   *  "time":1649453.4600000042,
   *  "loaded":19488768,
   *  "lastLoaded":19488768,
   *  "lastSpeed":107.83875364258277,
   *  "speed":108.36047879311354,
   *  "name":"88430MEMBERBIGLIST"
   *  }
   * }
   */
  const [fileDownloads, setFileDownloads] = useState({})
  const [bulkWorkflowDialog, setBulkWorkflowDialog] = useState({ open: false });
  const dispatch = useProcessingDispatchContext();
  const { user: contextUser, simulatedUser, simulatingUser } = useContext(UserComptContext);
  const { newSnackbar } = useCustomSnackbar();

  /**
   * Array of file downloads objects.
   * @type {array}
   * @example [
   * {"file":{"name":"88430MEMBERBIGLIST"},"status":"Loading","total":96877060,"time":1649453.4600000042,"loaded":19488768,"lastLoaded":19488768,"lastSpeed":107.83875364258277,"speed":108.36047879311354,"name":"88430MEMBERBIGLIST"}
   * ]
   */

  const downloadFiles = useMemo(() => {
    return Object.keys(fileDownloads).map((key) => fileDownloads[key])
  }, [fileDownloads])

  /**
   * Overall loaded and total of all files in download progress.
   */
  const downloadStatus = useMemo(() => {
    let loaded = 0;
    let total = 0;
    downloadFiles.forEach((download) => {
      loaded += download.loaded;
      total += download.total;
    })

    return {
      loaded,
      total
    }
  }, [downloadFiles])

  const [displayedNotifications, setDisplayedNotifications] = useState([]);

  const user = simulatingUser ? simulatedUser : contextUser;

  const { id: userId, role } = user;

  const [downloadList, setDownloadList] = useState([]);

  async function getDownloadBigList() {
    // We use fetch all... but this is more for convenience then because we need to pull multiple pages
    let userDownloads = await fetchAll(Index.BIGLIST, {
      // we only grab REQUESTED and READY statuses and then ignore if the request to download was a week ago as it is definitely stuck
      filter: `downloads/any(m: m/userId eq '${userId}' and (m/data/status eq 'READY' or (m/data/status eq 'REQUESTED' and m/data/request gt ${moment().subtract(1, 'week').toISOString()})))`
    })

    userDownloads = userDownloads.map(list => ({
      ...list,
      downloadStatus: list?.downloads?.find(download => download.userId === userId)?.data?.status,
    })).filter(list => !!list.downloadStatus)

    setDownloadList(userDownloads);
  }

  function delayedGetDownloadBigList() {
    // prevent duplicate timeouts
    if (statusTimeout) {
      clearTimeout(statusTimeout);
    }
    statusTimeout = setTimeout(() => {
      getDownloadBigList();
    }, 10000);
  }

  useEffect(() => {
    /** Fetch Al biglists metadata with downloads, if userId is present.*/
    if (userId && role && role.id && role.name != 'ROLE_FULLFILLMENT') {
      getDownloadBigList();

      return () => {
        clearTimeout(statusTimeout)
        dispatch(removeProcess(DOWNLOAD_NOTIFICATION_PROCESS))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  /** Check the downloads/ array in blobListDownload
   * For READY downloads, if autoDownload is true download them directly or
   * show the notification that file is ready.
   * FOR REQUESTED downloads, sets a timer that polls for change in status of the blob.
  */
  useEffect(() => {
    let loading = false;
    const newDisplayedNotifications = []
    /** Iterate through all lists requested/ready/completed for download. */
    downloadList.forEach((blob) => {
      if (blob.downloadStatus === BlobProcessStatus.READY) {
        if (!displayedNotifications.includes(blob.name)) {
          const biglistName = blob.name;

          if (autoDownload && autoDownload[biglistName]) {
            downloadFile(biglistName, blob)
            setAutoDownload((autoDownload) => ({
              ...autoDownload,
              [biglistName]: false
            }))
          } else {
            /** Notification to allow download of READY files. */
            newDisplayedNotifications.push(blob.name);
            newSnackbar({
              title: 'File ready for download',
              description: biglistName,
              persist: true,
              variant: 'info',
              actions: [
                {
                  label: 'Download',
                  onClick: () => {
                    downloadFile(biglistName, blob)
                  },
                  dismiss: true
                },
                {
                  label: 'Dismiss',
                  onClick: () => {
                    hideDownloadNotification(biglistName, blob)
                  },
                  dismiss: true
                }
              ]
            })
          }
        }
      } else if ([BlobProcessStatus.REQUESTED].includes(blob.downloadStatus)) {
        loading = true;
      }
    })
    setDisplayedNotifications((displayedNotifications) => {
      if (newDisplayedNotifications.length > 0) {
        return displayedNotifications.concat(newDisplayedNotifications);
      } else {
        return displayedNotifications
      }
    })
    /** polling if any list in download REQUESTED */
    if (loading) {
      delayedGetDownloadBigList();
    }

  }, [downloadList]);

  const [hideDownloadNotificationService] = useMutation(HIDE_DOWNLOAD_NOTIFICATION, {
    variables: {
      input: {}
    }
  })

  /**
   * @param {string} biglistName
   * Call download API with GET which updates the blob readyToDownload to true
   * and status in downloads object to DOWNLOADED.
   */
  function hideDownloadNotification(biglistName, { type }) {
    type = low(type);
    const updatedType = type === BlobTypes.BATCH ? BlobTypes.BATCH : type === BlobTypes.SUC_EXPORT ? 'task' : 'biglist'
    hideDownloadNotificationService({
      variables: {
        name: biglistName,
        type: updatedType
      }
    })
  }

  /**
   * Update file object in files array with the passed object.
   * @param {object} fileObj - file object in files array
   * @param {string} oldName
   */
  function updateFile(fileObj, oldName) {
    setState((state) => {
      const index = getIndexOfFileName(state.files, oldName || fileObj.name);
      const files = [...state.files];
      files[index] = fileObj;
      return {
        ...state,
        files
      }
    })
  }

  /**
   * Function tkaes a biglist name, and if the metadata of biglist is found,
   * it checks if download is ready for this user, if yes then download it.
   * If no, then prepare the list for download, and set autoDownload for that list.
   * @param {string} biglistName
   * @param {boolean} auto - dowload automatically once ready
   */
  async function prepareDownload(biglistName, auto, type) {
    const name = biglistName;
    /** Fetch metadata for the biglist */
    const biglist = await getBigListService(name, type);
    const commonProps = {
      open: true,
      actions: [
        {
          onClick: () => setBulkWorkflowDialog({ open: false }),
          label: 'ok',
          color: 'secondary',
          variant: 'contained'
        }
      ]
    }

    let found = false;
    if (biglist) {
      found = true;
      /** If list is ready for this user, then download it.*/
      if (biglist.downloads && biglist.downloads[userId] && [BlobProcessStatus.DOWNLOADED, BlobProcessStatus.READY].includes(biglist.downloads[userId].status)) {
        downloadFile(name, { type })
      } else {
        /** prepare for download, update the biglist metadata */
        await prepareDownloadService(name, type);
        if (auto) {
          setAutoDownload((autoDownload) => ({
            ...autoDownload,
            [name]: true
          }))
        }
        /** query the biglist metadata */
        delayedGetDownloadBigList();
      }
    }
    /** Notify user about export started process. */
    setBulkWorkflowDialog({
      title: ModalOverlayMessage.EXPORT_LIST(found).title,
      text: ModalOverlayMessage.EXPORT_LIST(found).text,
      ...commonProps
    });

  }

  /**
   * Updates the autoDownload flag for a biglist with the provided name.
   * @param {string} biglistName
   * @param {boolean} auto
   */
  async function watchDownload(biglistName, auto = true) {
    if (auto) {
      setAutoDownload((autoDownload) => ({
        ...autoDownload,
        [biglistName]: true
      }))
    }

    delayedGetDownloadBigList();
  }

  /**
   * downloads the file
   * @param {name} biglistName
   */
  function downloadFile(biglistName, blob, extension = 'csv') {
    var xhr = new XMLHttpRequest();
    const path = blob && blob.path ? blob.path : 'download';
    const correctExt = blob && blob.extension ? blob.extension : extension;
    const downloadName = blob && blob.internalName ? blob.internalName : biglistName;
    xhr.open('GET', `${baseURL}blob/${path}/${downloadName}.${correctExt}`, true);
    getHeaders().forEach((value, key) => {
      xhr.setRequestHeader(key, value)
    })
    xhr.withCredentials = true;
    xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, max-age=0');
    xhr.setRequestHeader('Expires', 'Thu, 1 Jan 1970 00:00:00 GMT');
    xhr.setRequestHeader('Pragma', 'no-cache');
    xhr.responseType = 'blob';
    /** Called when List download is in progress. */
    xhr.onprogress = (event) => {
      const loaded = event.loaded; //file loaded
      const total = event.total; // file total size
      const timestamp = event.timeStamp;
      const complete = loaded >= total;
      setFileDownloads((fileDownloads) => {
        if (complete) {
          const newFileDownloads = {}
          Object.keys(fileDownloads).forEach((key) => {
            if (key === biglistName) {
              return;
            }
            newFileDownloads[key] = fileDownloads[key];
          })

          return newFileDownloads;
        }
        /**
         * Maintain speed, loaded of the file.
         */
        const oldSpeed = fileDownloads[biglistName] && fileDownloads[biglistName].speed;
        const oldLoaded = fileDownloads[biglistName] && fileDownloads[biglistName].loaded;
        const oldTime = fileDownloads[biglistName] && fileDownloads[biglistName].time;
        const SMOOTHING_FACTOR = 0.05;
        const lastSpeed = (loaded - oldLoaded) / (timestamp - oldTime);
        return {
          ...fileDownloads,
          [biglistName]: {
            file: {
              name: biglistName
            },
            status: Status.LOADING,
            total: total,
            time: timestamp,
            loaded: loaded,
            lastLoaded: loaded,
            lastSpeed: oldSpeed,
            speed: oldSpeed ? SMOOTHING_FACTOR * lastSpeed + (1 - SMOOTHING_FACTOR) * oldSpeed : lastSpeed,
            name: biglistName
          }
        }
      })
    }
    /** List is ready to be downloaded. */
    xhr.onload = (event) => {
      const target = event.currentTarget;
      const status = target.status;
      if (status === 200) {
        const blob = target.response;
        const url = URL.createObjectURL(blob);
        downloadURI(url, `${biglistName}.${correctExt}`);
      } else {
        newSnackbar({
          title: `Error downloading file.`,
          variant: 'error',
          description: biglistName,
          persist: true
        })
      }
    }
    xhr.send(null);
    /** Update biglist metadata with DOWNLOADED status */
    hideDownloadNotification(biglistName, blob);
  }

  async function uploadFiles(path, action, { sequence } = {}) {
    const files = state.files;
    await uploadFileObjs(files, path, action, { sequence })
  }

  function createInternalName({ name, sequence }) {
    return name + BlobDelimiter + userId + BlobDelimiter + sequence;
  }

  /**
   * Function to upload files to aure
   * remove the file from state, once upload is successful.
   * Fetch metadata again once upload is done.
   * @param {array} fileObjs - files to be uploaded
   * @param {string} path - path in cloud where file should be uploaded
   * @param {string} action - upload/null
   */
  async function uploadFileObjs(fileObjs, path, action, { sequence }) {
    const files = fileObjs;
    window.addEventListener('beforeunload', onBeforeUnload);
    for (const fileObj of files) {
      if (fileObj.status === Status.READY) {
        const aborter = Aborter.none;
        const [name, extension] = getFileNameAndExtension(fileObj.name);
        await initProgress(fileObj, aborter); // Prepare file for upload.
        //Azure Service where file will be uploaded.
        if (sequence) {
          fileObj.sequence = sequence;
        }

        // Create metadata file because blob storage process takes a few minutes to display
        if (isNewFile(action, fileObj)) {
          const response = fileObj.type === 'file' ?
            await createFilesMetadataService(name, createInternalName({ name, sequence: fileObj.sequence }), extension) :
            await createBiglistMetadataService(name, fileObj.type, fileObj.auto);
          if (response && response.errors && response.errors.length > 0) {
            console.error(response)
            // Display a warning that files wont show until the process starts if this service fails for some reason
            newSnackbar({
              title: `Error creating biglist`,
              description: `File will not be uploaded for ${name}`,
              variant: 'error',
              persist: true
            });
            removeFile(fileObj);
            continue;
          }
        }

        addBlobService(fileObj, path, action, user, aborter, (progress) => handleProgress(fileObj, progress)).then(async () => {
          removeFile(fileObj);
        }).catch((error) => {
          /** Shows popup with error in uploading file. */
          if (error.code !== 'REQUEST_ABORTED_ERROR') {
            window.removeEventListener('beforeunload', onBeforeUnload);
            newSnackbar({
              title: 'Error uploading file.',
              variant: 'error',
              persist: true
            });
          }
        });
      }
    }
  }

  const isNewFile = (action, fileObj) => {
    return action === BlobActions.NEW || (!action && fileObj.action === BlobActions.NEW)
  }

  const containsBiglistName = async (name) => {
    const sameNames = await fetchAll(Index.BIGLIST, {
      filter: `name eq '${name}'`
    })

    return sameNames.length > 0;
  }

  const isInvalidFile = async (name) => {
    let errorMsg = '';
    if (name && name.includes('_')) {
      errorMsg = validationMessages.BULK_UPLOAD_ERROR
    } else if (await containsBiglistName(name)) {
      errorMsg = validationMessages.UPLOAD_DUPLICATE_ERROR;
    }

    return errorMsg;
  }
  /**
   * SImilar to uploadFileObjs, but for single file.
   * @param {object} fileObj - file object to be uploaded.
   * @param {string} path - path in cloud (biglist/)
   * @param {string} action - (upload/)
   */
  async function uploadFile(fileObj, path, action) {
    window.addEventListener('beforeunload', onBeforeUnload);
    if (fileObj.status === Status.READY) {
      const aborter = Aborter.none;
      await initProgress(fileObj, aborter);
      addBlobService(fileObj, path, action, user, aborter, (progress) => handleProgress(fileObj, progress)).then(() => {
        removeFile(fileObj);
      }).catch((error) => {
        if (error.code !== 'REQUEST_ABORTED_ERROR') {
          window.removeEventListener('beforeunload', onBeforeUnload);
          console.error('error sending file', error);
          newSnackbar({
            title: 'Error importing file.',
            variant: 'error',
            persist: true
          })
        }
      });
    }
  }

  /**
   * Function to prepare the file for upload.Set the status to Loading
   * and add the timestamp to the file object.
   * @param {object} fileObj - file to be uploaded
   * @param {object} aborter
   */
  async function initProgress(fileObj, aborter) {
    await setState((state) => {
      const files = [...state.files];
      const index = getIndexOfFileName(files, fileObj.name);
      if (index < 0) {
        files.push({ ...fileObj, aborter, status: Status.LOADING, time: Date.now() })
      }
      files[index] = { ...files[index], aborter, status: Status.LOADING, time: Date.now() };
      return {
        ...state,
        status: Status.LOADING,
        files
      };
    })
  }

  /** Progress for upload of a file to blob. */
  function handleProgress(fileObj, progress) {
    setState((state) => calculateProgress({ state, fileObj, progress, key: 'files' }));
  }

  /**
   *
   * @param {object} param0 - {
   *  state - state object to
   *  fileObj - file which is being uploaded.
   *  progress - object passed by azure callbak fn.
   *  key - 'files' string
   * }
   */
  function calculateProgress({ state, fileObj, progress, key }) {
    if (!progress) {
      return state;
    }
    const newFiles = [...state[key]];
    const speed = state.speed;
    //find for file object index in the state.files array.
    const index = getIndexOfFileName(newFiles, fileObj.name);
    const file = newFiles[index];
    if (!file) {
      return state;
    }
    const loaded = progress.loadedBytes;
    const time = Date.now();
    /** Run it when current time is greater than file uploaded time by 1s.
     * And update file object in state.
     */
    if (time > file.time + 1000) {
      //Calculate new speed
      const SMOOTHING_FACTOR = 0.05;
      const lastSpeed = (loaded - file.lastLoaded) / (time - file.time);
      //Update file's speed, lastSpeed, time and STATUS.
      newFiles[index] = {
        ...file,
        loaded,
        lastLoaded: loaded,
        time,
        lastSpeed,
        speed: file.speed ? SMOOTHING_FACTOR * lastSpeed + (1 - SMOOTHING_FACTOR) * file.speed : lastSpeed,
        status: (loaded >= file.total) ? Status.COMPLETE : Status.LOADING
      };
      let totalSpeed = 0;
      let totalBytes = 0;
      let totalLoaded = 0;
      let silent = true;
      //calculate total speed and totalLoaded for all files in state, that are READY.
      for (const i in newFiles) {
        if (newFiles[i].status !== Status.READY) {
          totalSpeed += newFiles[i].lastSpeed ? newFiles[i].lastSpeed : 0;
          totalBytes += newFiles[i].total ? newFiles[i].total : 0;
          totalLoaded += newFiles[i].loaded ? newFiles[i].loaded : 0;
          silent = silent && newFiles[i].silent;
        }
      }


      const newSpeed = speed ? SMOOTHING_FACTOR * totalSpeed + (1 - SMOOTHING_FACTOR) * speed : totalSpeed;
      // const estimatedTimeLeft = (totalBytes - totalLoaded) / newSpeed;
      const status = calcStatus(totalLoaded, totalBytes, silent);

      //update totalSpeed and totalLoaded for all files in the state.
      return {
        ...state,
        [key]: newFiles,
        speed: newSpeed,
        status,
        totalBytes,
        totalLoaded
      }
    } else {
      newFiles[index] = { ...file, loaded, status: (loaded >= file.total) ? Status.COMPLETE : Status.LOADING };
      let totalBytes = 0;
      let totalLoaded = 0;
      let silent = true;
      for (const i in newFiles) {
        if (newFiles[i].status !== Status.READY) {
          totalBytes += newFiles[i].total ? newFiles[i].total : 0;
          totalLoaded += newFiles[i].loaded ? newFiles[i].loaded : 0;
          silent = silent && newFiles[i].silent;
        }
      }
      const status = calcStatus(totalLoaded, totalBytes, silent);
      return {
        ...state,
        [key]: newFiles,
        status,
        totalBytes,
        totalLoaded
      }
    }
  }

  /**
   *
   * @param {number} totalLoaded - totla uploaded bytes of all files.
   * @param {number} totalBytes - totalBytes to be uploaded.
   * @param {boolean} silent - notification should be shown or not.
   */
  function calcStatus(totalLoaded, totalBytes, silent = false) {
    if (totalLoaded >= totalBytes) {
      window.removeEventListener('beforeunload', onBeforeUnload);
      if (!silent) {
        newSnackbar({
          title: 'Import Complete',
          variant: 'success',
          persist: true
        })
      }
      return Status.COMPLETE;
    } else {
      return Status.LOADING;
    }
  }

  /**
   * Add new files in the state.files, if not already exists for that name.
   * Check for extension, if supported.
   * @param {array} files - files to be added
   * @param {array} fileArray - existing files in the state.
   */
  function concatWithoutDuplicates(files, fileArray, { type, lockedType } = {}) {
    const newFiles = [...files];
    for (const file of fileArray) {
      let found = false;
      for (let j = 0; j < newFiles.length; j++) {
        //break if file with this name already exists in state.
        if (newFiles[j].name.toUpperCase() === file.name.toUpperCase()) {
          found = true;
          break;
        }
      }
      //If file doesnot alreaady exists in state
      if (!found) {
        const index = file.name.lastIndexOf('.');
        const extension = index >= 0 ? file.name.slice(index) : '';
        if (['.csv', '.xlsx', '', '.txt', '.xlsm'].includes(extension)) { //validate extension, if valid update the state.files witht his file object.
          const fileName = charWhitelist(file.name, file.name, type);
          const fileObj = {
            file,
            status: Status.READY,
            total: file.size,
            time: 0,
            loaded: 0,
            lastLoaded: 0,
            lastSpeed: 0,
            speed: 0,
            type,
            name: extension ? fileName : fileName + '.csv',
            lockedType: lockedType,
            action: file.action,
            sequence: file.sequence
          }
          if (file.action) {
            fileObj.action = file.action
          }
          newFiles.push(fileObj)
        } else {
          newSnackbar({
            title: file.name,
            description: `Can not import ${extension} files.`,
            variant: 'error'
          })
        }
      }
    }
    return newFiles;
  }

  /**
   *
   * @param {array} addingFiles - array of file object to be added to files state.
   * @param {*} param1
   */
  async function addFileList(addingFiles, { type, lockedType } = {}) {
    const fileArray = [...addingFiles];
    setState((state) => {
      const files = concatWithoutDuplicates(state.files, fileArray, { type, lockedType });
      return {
        ...state,
        files
      }
    })
  }

  async function addFile(addingFile, { type, lockedType } = {}) {
    const fileArray = [...addingFile];
    setState((prevState) => {
      const files = concatWithoutDuplicates([], fileArray, { type, lockedType });
      return {
        ...prevState,
        files
      }
    })
  }

  /** Remove  file from uploading.
   * Doesn't work for download.
   */
  function removeFilePrompt(fileObj) {
    if (fileObj.status === Status.LOADING) {
      if (confirm(`Stop uploading ${fileObj.file.name}?`)) {
        removeFile(fileObj);
      }
    } else {
      removeFile(fileObj);
    }
  }

  /**
   * Remove file from state.files,
   * and if there are no files left, reset state.
   * @param {object} fileObj
   */
  function removeFile(fileObj) {
    if (fileObj.aborter) {
      fileObj.aborter.abort();
    }
    setState((state) => {
      const files = state.files;
      const index = getIndexOfFileName(files, fileObj.name);
      if (fileObj.total > fileObj.loaded) {
        window.removeEventListener('beforeunload', onBeforeUnload);
      }
      const newFiles = files.slice(0, index).concat(files.slice(index + 1));

      if (newFiles.length === 0) {
        return {
          ...state,
          files: newFiles,
          status: Status.COMPLETE,
          speed: 0,
          totalBytes: 0,
          totalLoaded: 0
        }
      } else {
        return {
          ...state,
          files: newFiles
        }
      }

    })

  }

  /**
   * Remove all READY files from state.files
   */
  function resetUpload() {
    setState((state) => {
      const files = state.files;
      const newFiles = [];
      files.forEach((fileObj) => {
        if (fileObj.status !== Status.READY) {
          newFiles.push(fileObj);
        }
      })

      return {
        ...state,
        files: newFiles
      }
    })
  }

  const { children } = props;
  /**
   * @type {number} Amount of download/upload completed.
   */
  const percent = (state.totalLoaded + downloadStatus.loaded) / (state.totalBytes + downloadStatus.total) * 100;
  const visibleFiles = state.files ? state.files.filter((fileObj) => !fileObj.silent) : [];
  return (
    <>
      <FileTrackerContext.Provider
        value={{
          addFileList,
          removeFile: removeFilePrompt,
          uploadFiles,
          uploadFileObjs,
          updateFile,
          resetUpload,
          uploadFile,
          files: state.files,
          hideDownloadNotification,
          prepareDownload,
          watchDownload,
          downloadFile,
          addFile,
          isInvalidFile,
          containsBiglistName
        }}
      >
        {children}
      </FileTrackerContext.Provider>
      <ConfirmationDialog {...bulkWorkflowDialog} />
      {/** Progress Bar when files are in progress for download / upload
      */}
      {(state.status === Status.LOADING && visibleFiles && visibleFiles.length > 0) || (downloadFiles && downloadFiles.length > 0) ? (
        <ProgressMenu
          percent={percent}
          files={visibleFiles}
          downloadFiles={downloadFiles}
          removeFile={removeFilePrompt}
          status={state.status}
        />
      ) : null}
    </>
  );
}

export default FileTracker;
