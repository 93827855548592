import {
  FieldTypes,
  TemplateSpecialGroupType,
  low,
  BlobActions,
  Status,
  TemplateMixTypes,
  TemplateGroupType,
  CSV_BIGLIST_LIMIT,
  BIGLIST_EDIT_LIMIT,
  TemplateSpecialCaseNames,
  BlobTypes,
  DateFormat,
  SearchTypes,
  LinkedBiglistFields,
  TemplateNonArraySpecial,
  NameKeys
} from '../../constants/Constants';
import dateFormat from '../date/dateFormat';
import arrayToCSV from '../biglist/arrayToCSV';
import getDeepValue from '../getDeepValue';
import getCompartmentTypes from '../compartments/getCompartmentTypes';
import getBiglistEditValues from './getBiglistEditValues';
import notNull from '../notNull';
import { DATE_ACTIONS, DATE_ACTIONS_PROPS } from '../../compartments/search/getSearchDateActions';
import getAllBiglistValuesService from '../../services/biglist/GetAllBiglistValuesService';
import searchService from '../../setup/search/service.all';
import { checkNonZeroArray } from '../helpers';

export function createBiglistFromCsv({ value, uuidCache, metadata, csvArray, uploadBlobs, type: _type, csvDefault }) {
  let newValue, blob;
  if (value?.uuid && uuidCache && uuidCache[value.uuid]) {
    newValue = uuidCache[value.uuid];
  } else {
    const type = _type ? _type : (metadata?.searchType && metadata.searchType.length ? metadata.searchType.split('.')[0] : '');
    const cleanName = `${type ? type : 'BIGLIST'}-auto-${dateFormat(Date.now(), 'YYYYMMDDHHmmssSSS')}${uploadBlobs.length}`;
    newValue = cleanName.toUpperCase();
    if (value?.uuid && uuidCache) {
      uuidCache[value.uuid] = newValue;
    }
    const biglistName = newValue + '.csv';
    const csv = arrayToCSV(csvArray, biglistName, type, csvDefault);
    const fileObj = {
      file: csv,
      name: biglistName,
      type: type.toLowerCase(),
      action: BlobActions.NEW,
      status: Status.READY,
      total: csv.size,
      time: 0,
      loaded: 0,
      lastLoaded: 0,
      lastSpeed: 0,
      speed: 0,
      lockedType: true,
      silent: true,
      auto: true
    }
    blob = fileObj;
  }
  const mixType = TemplateMixTypes.BIGLIST;
  return { newValue, blob, mixType };
}

// Returns true if biglist is connected to a promotion/campaign
async function isConnectedBiglist(biglist, name, rootType) {
  // Search the type of index for the biglist, return true if biglist is found
  if (!biglist) {
    return false;
  }

  async function search(type) {
    const searchData = await searchService({
      filter: null,
      sort: null,
      type,
      additionalFilter: null,
      searchOptions: {
        search: biglist,
        searchFields: LinkedBiglistFields[low(type)],
        searchMode: 'all'
      }
    });

    // Use name key for each type
    const key = NameKeys[type];
    // Find item with different name OR same name but different type
    if (searchData && searchData.value) {
      return !!searchData.value.find((item) => item.values[key] !== name || (item.values[key] === name && type !== rootType))
    } else {
      return false;
    }
  }

  // Search for biglist in any promotion/campaign
  const [promotions, campaigns] = await Promise.all([
    search(SearchTypes.PROMOTION),
    search(SearchTypes.CAMPAIGN)
  ]);

  return promotions || campaigns;
}

async function processFrontEndData(field, name, uploadBlobs, uuidCache, biglistData, rootType) {
  const { value, metadata } = field;
  let blob;
  let addBlob;
  let removeBlob;
  let date;
  let newValue;
  let mixType;
  let modalData;
  let fieldName;
  switch (metadata.type) {
    case FieldTypes.NUMBER:
      //This is to fix the return Value when it's empty string
      if (value !== "") {
        if (getDeepValue(field, ['metadata', 'queries', 'repeatable'])) {
          newValue = value;
        } else {
          newValue = +value;
        }
      } else {
        newValue = null
      }
      break;
    case FieldTypes.DATE:
    case FieldTypes.DATE2:
      date = dateFormat(value, DateFormat.DATE);
      if (['INVALID DATE', 'NOT SET'].includes(date.toUpperCase())) {
        newValue = null;
      } else {
        newValue = date;
      }
      break;
    case FieldTypes.ENUM:
      newValue = value;
      break;
    case FieldTypes.RADIO:
      newValue = value;
      break;
    case FieldTypes.BOOLEAN:
      newValue = value;
      break;
    case FieldTypes.MIX:
    case FieldTypes.TABLE:
      if (getDeepValue(value, ['csv'])) {
        const csvArray = value.csv.split(',').map((value) => ({ id: value }));
        const enableBiglist = getDeepValue(field, ['metadata', 'enableBiglist'])
        if (enableBiglist) {
          if ((notNull(metadata.limit) && csvArray.length > metadata.limit) || csvArray.length > CSV_BIGLIST_LIMIT) {
            const { newValue: rNewVal, blob: rBlob, mixType: rMixType } = createBiglistFromCsv({ value, uuidCache, metadata, csvArray, uploadBlobs, name });
            newValue = rNewVal;
            blob = rBlob;
            mixType = rMixType
          } else {
            newValue = value.csv;
            mixType = TemplateMixTypes.CSV;
          }
        } else {
          newValue = value.csv;
          mixType = TemplateMixTypes.CSV;
        }
      } else if (getDeepValue(value, ['biglist'])) {
        if (value.editList) {
          const { addList, removeList } = getBiglistEditValues(value.editList);
          const convertBiglist = getDeepValue(metadata, ['queries', 'convertBiglist']);

          const createEditBiglist = (list, action) => {
            const [type] = metadata.searchType && metadata.searchType.length ? metadata.searchType.split('.') : [];
            newValue = value.biglist;
            if (value.uuid && uuidCache) {
              uuidCache[value.uuid] = newValue;
            }
            const biglistName = newValue + '.csv';
            const csv = arrayToCSV(list, biglistName, type)
            return {
              file: csv,
              name: biglistName,
              type: type.toLowerCase(),
              action: action,
              status: Status.READY,
              total: csv.size,
              time: 0,
              loaded: 0,
              lastLoaded: 0,
              lastSpeed: 0,
              speed: 0,
              lockedType: true,
              silent: true,
              auto: true
            }
          }

          if (convertBiglist && await isConnectedBiglist(value.biglist, name, rootType)) {
            const response = await getAllBiglistValuesService(value.biglist, 10000);
            if (response && response.items) {
              const values = response.items.map((item) => {
                return item.id;
              })
              let csvArray = [];
              if (addList && addList.length) {
                addList.forEach((item) => {
                  if (!values.includes(item)) {
                    csvArray.push(item);
                  }
                })
              }
              if (removeList && removeList.length) {
                csvArray = csvArray.concat(values.filter((item) => !removeList.includes(item)));
              } else {
                csvArray = csvArray.concat(values);
              }

              if (csvArray.length) {
                const { newValue: rNewVal, blob: rBlob } = createBiglistFromCsv({ value, uuidCache, metadata, csvArray, uploadBlobs, name });
                newValue = rNewVal;
                blob = rBlob;
              } else {
                newValue = null;
              }
            }
          } else {
            if (value.replace) {
              if (value.uuid && uuidCache && uuidCache[value.uuid]) {
                newValue = uuidCache[value.uuid];
              } else {
                blob = createEditBiglist(addList, BlobActions.REPLACE);
              }
            } else {
              if (addList && addList.length > BIGLIST_EDIT_LIMIT) {
                addBlob = createEditBiglist(addList, BlobActions.APPEND);
              }
              if (removeList && removeList.length > BIGLIST_EDIT_LIMIT) {
                removeBlob = createEditBiglist(removeList, BlobActions.DELETE);
              }
              if (!biglistData || !biglistData[value.biglist]) {
                biglistData[value.biglist] = {
                  update: addBlob ? null : addList,
                  remove: removeBlob ? null : removeList
                }
              }
            }
            newValue = value.biglist;
          }
        } else {
          newValue = value.biglist;
        }
        mixType = TemplateMixTypes.BIGLIST;
      } else {
        newValue = '';
      }
      break;
    case FieldTypes.SEARCH:
      // needed for campaign assignment, because value is an object on templateInputs
      // logic for campaign_number
      fieldName = metadata.name === TemplateSpecialCaseNames.AWARD_COUPON_GROUP ? 'couponGroupName' : metadata.name; // Needed to set coupon value correctly
      newValue = getDeepValue(value, [fieldName]) || getDeepValue(value, ['id']) || value;
      break;
    case FieldTypes.STRING:
      //If the modal is setting some value using saveDataInParent prop, we would be sending the childTemplateValue as payload not the field value
      if (getDeepValue(field, ['metadata', 'queries', 'saveDataInParent'])) {
        const templateGroupType = getDeepValue(field, ['metadata', 'queries', 'templateGroupType'])

        newValue = TemplateSpecialGroupType.includes(templateGroupType.toUpperCase()) ? field[templateGroupType.toLowerCase()] : field[field.metadata.name];
      } else {
        newValue = value ? value.trim() : value;
      }

      break;
    default:
      newValue = value;
      break;
  }

  // Handle and flatten child modal data
  const childTemplate = getDeepValue(field, ['metadata', 'queries', 'childTemplate']);
  const templateGroupType = getDeepValue(field, ['metadata', 'queries', 'templateGroupType']);
  // If field as modal data that should be flattened
  if (newValue && childTemplate && childTemplate === 'true' && low(templateGroupType) === low(TemplateGroupType.FLAT_MODAL_DATA)) {
    const parentSelectedValue = getDeepValue(field, ['metadata', 'queries', 'parentSelectedValue']);
    let modalValues;
    if (newValue === parentSelectedValue) { // If selected value matches Custom
      modalValues = getDeepValue(field, [parentSelectedValue])
    } else { // Populate with childDefaultValues if Custom is not selected
      const childDefaultValues = getDeepValue(field, ['metadata', 'queries', 'childDefaultValues'])
      modalValues = JSON.parse(childDefaultValues);
    }

    // Insert flattend data into modalData
    if (modalValues) {
      const modalKeys = Object.keys(modalValues)
      modalData = {};
      const noPrefix = getDeepValue(field, ['metadata', 'queries', 'noPrefix'])
      const fieldName = getDeepValue(field, ['metadata', 'name'])
      modalKeys.forEach((modalKey) => {
        const modalValue = modalValues[modalKey];
        if (noPrefix) {
          modalData[modalKey] = modalValue
        } else {
          modalData[`${fieldName}.${modalKey}`] = modalValue
        }
      })
    }
  }

  return { newValue, blob, addBlob, removeBlob, mixType, modalData };
}
/**
 * Function to get the source and target to copy one group into another
 * Created for IPM (copy assignment_data to item_message_conditions)
 * @param {object} metadata of field
 * @returns Array of object [{target: item_message_conditions,
 * targetField: assignment_data, source: assignment_data,sourceField: club_assignment }]
 */
function checkUpdateOtherGroups({ metadata }) {
  const updateOtherGroup = [];
  if (metadata.queries && metadata.queries.copyGroupToAnotherGroup) {
    const groups = metadata.queries.copyGroupToAnotherGroup.split('|')
    groups.forEach(group => {
      const [target, source, targetField, sourceField] = group.split('.');
      updateOtherGroup.push({ target, source, targetField, sourceField });
    })
  }
  return updateOtherGroup;
}

async function mapRegularGroupType({ groupData, name, uploadBlobs, uuidCache, biglistData, rootType, mode, updateOtherGroup }) {
  const blobs = [];
  let error = false;
  // map only the 'value' and set to values
  const mappedValues = await Object.keys(groupData.fields).reduce(async (valAcc, fieldKey) => {
    const fieldData = groupData.fields[fieldKey];
    const val = await valAcc;

    //check error
    if (mode !== "viewOnly" && fieldData.error) {
      error = true;
    }
    const otherGroupsArr = checkUpdateOtherGroups(fieldData);
    if (checkNonZeroArray(otherGroupsArr)) {
      updateOtherGroup.push(...otherGroupsArr);
    }

    // ignore
    if (fieldData.metadata.ignore) {
      return Promise.resolve(val);
    }

    const { newValue, mixType, blob, addBlob, removeBlob, modalData } = await processFrontEndData(fieldData, name, uploadBlobs, uuidCache, biglistData, rootType);

    // don't add empty value except for 0 and false
    // if (!newValue && newValue !== 0 && newValue !== false) return val;

    if (blob) { // push blob for csv over limit
      blobs.push(blob);
    }
    if (addBlob) { // push blob for csv over limit
      blobs.push(addBlob);
    }
    if (removeBlob) { // push blob for csv over limit
      blobs.push(removeBlob);
    }
    let acc = val;
    if (mixType) {
      if (newValue) {
        acc = {
          ...acc,
          [fieldKey]: newValue,
          list_type: mixType
        }
      }
    } else if (modalData) {
      acc = {
        ...acc,
        ...modalData,
        [fieldKey]: newValue
      }
    } else {
      acc = {
        ...acc,
        [fieldKey]: newValue
      }
    }
    return Promise.resolve(acc);
  }, Promise.resolve({}));

  return { mappedValues, blobs, error };
}

async function mapClubOverrideGroupType({ groupData, uuidCache, uploadBlobs, data }) {
  const blobs = [];
  const mappedValues = await groupData.fields.reduce(async (groupsArr, group) => {
    const listData = group.club_override_list;
    if (getDeepValue(listData, ['value', 'biglist']) && !getDeepValue(listData, ['value', 'editList'])) {
      return [{
        club_override_list: getDeepValue(listData, ['value', 'biglist']),
        list_type: TemplateMixTypes.BIGLIST
      }];
    }
    const fullValue = getDeepValue(listData, ['value', 'fullValue']);

    const defaultValues = {};
    for (const key of DATE_ACTIONS) {
      const defaultValue = getDeepValue(data, ['dates', 'fields', DATE_ACTIONS_PROPS[key].key, 'value']);
      defaultValues[key] = defaultValue;
    }
    if (!fullValue) {
      return groupsArr;
    }
    const metadata = listData.metadata;
    const value = listData.value;
    if ((notNull(metadata.limit) && fullValue.length > metadata.limit) || fullValue.length > CSV_BIGLIST_LIMIT) {
      let fullValueList = fullValue;
      if (value.editList) {
        const { addList, removeList } = getBiglistEditValues(value.editList);
        const convertBiglist = getDeepValue(metadata, ['queries', 'convertBiglist']);
        if (convertBiglist && value.biglist) {
          const response = await getAllBiglistValuesService(value.biglist, 10000);
          if (response && response.items) {
            const values = response.items;
            let csvArray = [];
            if (addList && addList.length) {
              addList.forEach((item) => {
                if (!values.includes(item)) {
                  const fullItem = fullValue.find((_fullItem) => item === _fullItem.id);
                  csvArray.push(fullItem);
                }
              })
            }
            if (removeList && removeList.length) {
              csvArray = csvArray.concat(values.filter((item) => !removeList.includes(item.id)));
            } else {
              csvArray = csvArray.concat(values);
            }

            if (csvArray.length > 0) {
              fullValueList = csvArray
            } else {
              return []
            }

          }
        }
      }

      const { newValue: rNewVal, blob: rBlob, mixType: rMixType } =
        createBiglistFromCsv({ value, uuidCache, metadata, csvArray: fullValueList, uploadBlobs, name, type: BlobTypes.CLUB_OVERRIDE, csvDefault: defaultValues });
      const newValue = rNewVal;
      const blob = rBlob;
      const mixType = rMixType

      if (blob) { // push blob for csv over limit
        blobs.push(blob);
      }

      return [{
        club_override_list: newValue,
        list_type: mixType
      }];

    } else {
      const newObj = {};
      for (const club of fullValue) {
        const uniqueGroup = DATE_ACTIONS.reduce((acc, key) => {
          return acc + club[key];
        }, '');
        const newObjVal = newObj[uniqueGroup];
        newObj[uniqueGroup] = newObjVal ? [...newObjVal, club] : [club];
      }

      return Object.keys(newObj).reduce((groups, dateStringKey) => {
        const newObjData = newObj[dateStringKey];
        let hasValue = false;
        const otherProps = DATE_ACTIONS.reduce((acc, key) => {
          const val = newObjData[0][key];
          if (val) {
            hasValue = true;
          }
          return {
            ...acc,
            [key]: (val || defaultValues[key]) + ''
          };
        }, {});
        if (!hasValue) {
          return groups;
        }
        const createdObj = {
          ...otherProps,
          list_type: TemplateMixTypes.CSV,
          club_override_list: newObjData.map(item => item.id).join(',')
        };
        return [
          ...groups,
          createdObj
        ];
      }, []);
    }
  }, [])

  return { mappedValues, blobs };
}

async function mapSpecialGroupType({ groupData, name, uuidCache, uploadBlobs, biglistData, rootType, mode, updateOtherGroup }) {
  const blobs = [];
  let error = false;
  const mappedValues = await Promise.all(groupData.fields.map(async group => {
    // map only the 'value' and set to special group
    return await Object.keys(group).reduce(async (newFieldAcc, fieldKey) => {
      const fieldData = group[fieldKey];
      const newField = await newFieldAcc;

      const otherGroupsArr = checkUpdateOtherGroups(fieldData);
      if (checkNonZeroArray(otherGroupsArr)) {
        updateOtherGroup.push(...otherGroupsArr);
      }
      // check error
      if (mode !== "viewOnly" && fieldData.error) {
        error = true;
      }

      // ignore
      if (fieldData.metadata.ignore) {
        return Promise.resolve(newField);
      }

      const { newValue, mixType, blob, addBlob, removeBlob, modalData } = await processFrontEndData(fieldData, name, uploadBlobs, uuidCache, biglistData, rootType);

      // don't add empty value except for 0 and false
      // if (!newValue && newValue !== 0 && newValue !== false) return newField;

      // push blob for csv over limit
      if (blob) {
        blobs.push(blob);
      }
      if (addBlob) {
        blobs.push(addBlob);
      }
      if (removeBlob) {
        blobs.push(removeBlob);
      }
      let acc = newField;
      if (mixType) {
        acc = {
          ...acc,
          [fieldKey]: newValue,
          list_type: mixType
        };
      } else if (modalData) {
        acc = {
          ...acc,
          ...modalData,
          [fieldKey]: newValue
        }
      } else {
        acc = {
          ...acc,
          [fieldKey]: newValue
        };
      }
      return Promise.resolve(acc);
    }, Promise.resolve({}));
  }));
  return { blobs, mappedValues, error };
}

export default async function createExecutePayload({ data, allData, templateId, rootType, itemName, mode }) {
  // clone and remove workflowItem
  data = { ...data }
  delete data.workflowItem;
  allData = { ...allData }
  delete allData.workflowItem;

  const uploadBlobs = [];
  const biglistData = {};
  const uuidCache = {};
  const hiddenValues = [];
  const updateOtherGroup = [];
  let hasError = false;

  let name = '';
  const dataKeys = Object.keys(data);
  const hiddenDataKeys = Object.keys(allData).filter((key) => !dataKeys.includes(key));

  hiddenDataKeys.forEach((groupKey) => {
    const groupData = allData[groupKey];
    const type = getDeepValue(groupData, ['metadata', 'type']);

    if (!TemplateSpecialGroupType.includes(type)) {
      Object.keys(groupData.fields).forEach((fieldKey) => {
        hiddenValues.push(fieldKey);
      })
    }
  })

  if (itemName) {
    name = itemName;
  } else {
    const nameKey = NameKeys[rootType];
    for (const dataKey of dataKeys) {
      if (data[dataKey].fields && data[dataKey].fields[nameKey]) {
        name = data[dataKey].fields[nameKey].value;
        break;
      }
    }
  }

  const requestPayload = await dataKeys.reduce(async (acc, groupKey) => {
    const payload = await acc;
    const groupData = data[groupKey];

    // Ignore if no group data exists
    if (!groupData || !groupData.metadata) {
      return Promise.resolve(payload);
    }
    const { type: groupType, name: groupName } = groupData.metadata;

    // ignore ACTION_DATA type
    if (groupType === TemplateGroupType.ACTION) {
      return Promise.resolve(payload);
    }

    if (!TemplateSpecialGroupType.includes(groupType)) {

      const { mappedValues, blobs, error } = await mapRegularGroupType({ groupData, name, uploadBlobs, uuidCache, biglistData, rootType, mode, updateOtherGroup });
      if (blobs.length) {
        uploadBlobs.push(...blobs);
      }
      if (error) {
        hasError = true;
      }
      let key = "values";
      if (TemplateNonArraySpecial.includes(groupType)) {
        key = groupName
      }
      // set to values
      return Promise.resolve({
        ...payload,
        [key]: {
          ...(payload[key] || {}),
          ...mappedValues
        }
      });
    }

    let mappedSpecial = [];
    let mappedBlobs = [];
    if (groupKey === TemplateSpecialCaseNames.CLUB_OVERRIDE) {
      const { mappedValues, blobs } = await mapClubOverrideGroupType({ groupData, uuidCache, uploadBlobs, data });
      mappedSpecial = mappedValues;
      mappedBlobs = blobs;
    } else {
      const { mappedValues, blobs, error } = await mapSpecialGroupType({ groupData, name, uuidCache, uploadBlobs, biglistData, rootType, mode, updateOtherGroup });
      mappedSpecial = mappedValues;
      mappedBlobs = blobs;
      if (error) {
        hasError = true;
      }
    }
    if (mappedBlobs.length) {
      uploadBlobs.push(...mappedBlobs);
    }

    const filteredMappedSpecial = mappedSpecial.reduce((acc, group) => {
      const fieldKeys = Object.keys(group);
      let hasValue = false;
      const newGroupObj = fieldKeys.reduce((obj, fieldKey) => {
        const fieldValue = group[fieldKey];
        if (fieldValue !== null && fieldValue !== undefined) {
          hasValue = true;
          return {
            ...obj,
            [fieldKey]: fieldValue
          }
        }
        return obj;
      }, {});

      // if newGroupObj is an object that has values but EVERY value is empty string, then set hasValue to false
      // we do this because BE jackson parser doesn't know how to handle otherwise
      if (Object.keys(newGroupObj).length > 0) {
        const isEmpty = Object.keys(newGroupObj).every((key) => newGroupObj[key] === '');
        if (isEmpty) {
          hasValue = false;
        }
      }

      if (hasValue) {
        return [...acc, newGroupObj];
      }
      return acc;
    }, []);

    // set to group type
    const groupTypeField = low(groupType);
    const getGroupTypePayload = () => {
      if (groupType !== TemplateGroupType.ITEM_MESSAGE) {
        return {
          [groupTypeField]: {
            ...(payload && payload[groupTypeField] ? payload[groupTypeField] : {}),
            [groupKey]: filteredMappedSpecial
          }
        }
      }
      return {
        ...(payload && payload[groupTypeField] ? payload[groupTypeField] : {}),
        [groupKey]: filteredMappedSpecial
      }
    }
    return Promise.resolve({
      ...payload,
      ...getGroupTypePayload()
    });
  }, Promise.resolve({}));
  // given value=item_conditions.item_list
  // award_data.item_award_conditions[0].item_list = condition_data.item_conditions[0].item_list
  return {
    finalPost: {
      templateId,
      ...requestPayload
    },
    hiddenValues,
    uploadBlobs,
    biglistData,
    hasError,
    updateOtherGroup
  };
}
