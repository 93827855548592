import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function bulkAssignCouponMemberlistUploadService(formData, fileName) {
  const headers = baseHeaders();
  headers.delete('Content-Type');

  try {
    return await fetch(`${baseURL}coupon/upload/bulkassignment/memberlists/${fileName}`, {
      credentials: 'include',
      method: 'PUT',
      body: formData,
      headers: headers
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export default bulkAssignCouponMemberlistUploadService;
