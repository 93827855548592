import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function overrideLimitService(couponValue) {
  const body = {...couponValue}
  try {
    return await fetch(`${baseURL}coupon/suc`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });
  } catch (error) {
    console.error(error)
    throw error;
  }
}
export default overrideLimitService;
