import React, { useState } from 'react';
import {
  IconButton,
  Menu
} from '@material-ui/core';
import clsx from 'clsx';
import CustomButton from '../CustomButton/CustomButton';
import useStyles from './MenuButton.styles';

function MenuButton(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { Icon, menuItems, iconProps = {}, hidden, iconButtonProps = {}, disabled } = props;

  function handleClick(event) {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setAnchorEl(null);
  }

  const handleMenuClick = (onClick) => (event, ...args) => {
    event.stopPropagation();
    handleClose();
    if (onClick) {
      event.preventDefault();
      onClick(event, ...args);
    }
  }

  const fileInput = [];
  menuItems.filter(({file}) => file).forEach(({file}) => fileInput.push(file));

  function renderMenuItem({ Icon, label, onClick, file, disabled, Comp, FullComp, id }, index) {
    if (FullComp) {
      return FullComp;
    }
    if (Comp) {
      return <Comp Icon={Icon} label={label} disabled={disabled} id={id} onClick={handleClose} variant='menu' />
    }
    const component = (
      <CustomButton
        variant='menu'
        key={index}
        onClick={handleMenuClick(onClick)}
        disabled={disabled}
        LeftIcon={Icon}
        label={label}
      />
    )
    if (file) {
      return (
        <label htmlFor={file.fileId} key={index}>
          {component}
        </label>
      )
    } else {
      return component;
    }
  }
  return (
    <>
      <IconButton {...iconButtonProps} onClick={handleClick} style={{visibility: hidden ? 'hidden' : 'inherit'}} disabled={disabled}>
        <Icon {...iconProps} className={clsx(classes.icon, { [classes.iconDisabled]: disabled })} />
      </IconButton>
      {hidden ? null : (
        <>
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
          >
            {menuItems.map(renderMenuItem)}
          </Menu>
          {fileInput.map(({fileId, onChange}) => (
            <input
              key={fileId}
              id={fileId}
              multiple
              type='file'
              onChange={onChange}
              hidden
            />
          ))}
        </>
      )}
    </>
  )
}

export default MenuButton;
