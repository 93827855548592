import React from "react";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import clsx from 'clsx';
import useStyles from "./search.styles";
import CustomButton from '../buttons/CustomButton/CustomButton';

/*
  Search takes the onchange & onclick action
  Also the placeholder description in props
  & the search state
*/
export default function Search(props) {
  const classes = useStyles();
  const { placeholderTitle, searchState, onChangeSearch, onClickSearch, className, style, maxLength, disabled, disableSearchBtn = false } = props;

  function onKeyPress(event) {
    if(event.key === 'Enter'){
      onClickSearch(event);
    }
  }

  return (
    <Paper className={clsx(classes.root, className)} style={style}>
      <SearchIcon className={classes.searchIcon} />
      <InputBase
        className={classes.input}
        classes={{
          input: classes.inputField
        }}
        placeholder={placeholderTitle}
        inputProps={{
          "aria-label": "User Search",
          maxLength: maxLength
        }}
        value={searchState}
        onChange={onChangeSearch}
        onKeyUp={onKeyPress}
        disabled={disabled}
      />
      {onClickSearch ? (
        <CustomButton
          label='Search'
          onClick={onClickSearch}
          variant='contained'
          color='secondary'
          size='large'
          className={classes.searchButton}
          disabled={disabled || disableSearchBtn}
        />
      ) : null }
    </Paper>
  );
}
