function getBiglistEditValues(editList) {
  if(!editList) {
    return {addList: [], removeList: []};
  }

  const itemNumbers = Object.keys(editList);
  if (!itemNumbers.length){
    return {addList: [], removeList: []};
  }
  const result = itemNumbers.reduce((res, key) => {
    if (editList[key] === 'add') {
      return {
        ...res,
        addList: [
          ...(res.addList || []),
          key
        ]
      };
    } else {
      return {
        ...res,
        removeList: [
          ...(res.removeList || []),
          key
        ]
      };
    }
  }, {});

  return result;
}

export default getBiglistEditValues;
