/**
 * takes an array of ids
 * returns filtered compartments array
 **/
export default function getCompartmentsByIds(compartments, ids = []) {
  // change from filter to map to keep the order of ids
  return ids.reduce((acc, id) => {
    const item = compartments.find(compartment => compartment.id === id);
    if (item) {
      return [...acc, item];
    }
    return acc;
  }, []);
}
