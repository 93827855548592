import { makeStyles } from '@material-ui/core';
import Colors from '../../../theme/Colors';

const styles = {
  icon: {
    color: Colors.primaryBlue
  },
  iconDisabled: {
    color: 'rgba(0, 0, 0, 0.26)'
  }
}

export default makeStyles(styles);
