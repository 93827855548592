function objectToTemplate(object, ignorePath) {
  const templateObject = {};
  if (object) {
    flattenObject(object, templateObject, ignorePath);
    return templateObject;
  }
  return null;
}

function flattenObject(object, values, ignorePath, path = '') {
  for (const key in object) {
    const name = path && !ignorePath ? path + ':' + key : key
    if (typeof object[key] === 'object' && !Array.isArray(object[key])) {
      flattenObject(object[key], values, ignorePath, name)
    } else if (Array.isArray(object[key])) {
      values[name] = object[key][0];
    } else {
      values[name] = object[key];
    }
  }
}

export default objectToTemplate;
