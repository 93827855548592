import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  Paper, Typography
} from '@material-ui/core';
import CustomButton from '../../../widgets/buttons/CustomButton/CustomButton';
import TitleText from '../../../widgets/text/TitleText';
import useStyles from './BigListUpload.styles';
import SelectFileButton from '../../../widgets/buttons/SelectFileButton/SelectFileButton';
import { useFileTracker } from '../../../containers/biglist/FileTracker/FileTracker';
import TextInput from '../../../widgets/inputs/TextInput';
import SelectInput from '../../../widgets/inputs/SelectInput';
import { BlobActions, FILES, ModalOverlayMessage } from '../../../constants/Constants';
import ConfirmationDialog from '../../../widgets/Confirmation/ConfirmationDialog';
import { getFileNameAndExtension } from '../../../Utils/helpers';

/**
 * BulkUpload component handles the bulk upload functionality for the application.
 * It allows users to select an Excel file, validate it, and upload it to the server.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <BulkUpload />
 *
 * @function
 * @name BulkUpload
 *
 * @description
 * This component uses several hooks and context:
 * - `useHistory` from `react-router-dom` to navigate back.
 * - `useParams` from `react-router-dom` to get route parameters.
 * - `useStyles` for styling.
 * - `UserComptContext` to get user information.
 * - `useFileTracker` for file-related operations.
 *
 * @property {Object} history - The history object from `useHistory`.
 * @property {Object} params - The parameters from `useParams`.
 * @property {Object} classes - The styles from `useStyles`.
 * @property {Object} user - The user object from `UserComptContext`.
 * @property {string} userId - The user ID from the user object.
 * @property {string} selectedFile - The state for the selected file.
 * @property {string} error - The state for error messages.
 * @property {string} sequence - The state for the sequence selection.
 * @property {Object} dialog - The state for the dialog box.
 * @property {string} path - The download path for files.
 * @property {string} action - The action type for file operations.
 *
 * @method closeDialog - Closes the dialog box.
 * @method handleSelectSequence - Handles the sequence selection change.
 * @method close - Navigates back to the previous page.
 * @method uploadContainer - Renders the upload container.
 * @method renderSequenceSelection - Renders the sequence selection dropdown.
 * @method handleFileSelect - Handles the file selection and validation.
 * @method downloadSampleFile - Downloads a sample file.
 * @method uploadFileToStorage - Uploads the selected file to the server.
 */
export default function BulkUpload() {
  const history = useHistory();
  const { name } = useParams();
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState('');
  const [error, setError] = useState('');
  const [sequence, setSequence] = useState('3');
  const [dialog, setDialog] = useState({ show: false, message: '', title: '' });
  const path = FILES.DOWNLOAD_PATH;
  const action = BlobActions.NEW
  const {
    addFile,
    uploadFiles,
    downloadFile,
    removeFile,
    isInvalidFile
  } = useFileTracker();

  React.useEffect(() => {
    return () => {
      if (selectedFile && selectedFile.name) {
        removeFile(selectedFile);
      }
    }
  }, [])

  function closeDialog() {
    setDialog({ show: false, message: '', actions: [] })
  }

  function handleSelectSequence(event) {
    const value = event.target.value;
    setSequence(value);
  }

  function close() {
    history.goBack();
  }

  function uploadContainer() {
    return (
      <div className={classes.bulkUploadContainer}>
        <div>
          {error ? <Typography className={classes.searchFailIcon}>{error}</Typography> : null}
        </div>
        <div>
          <TitleText className={classes.bulkText}>Excel File</TitleText>
        </div>
        <div className={classes.editField}>
          <TextInput disabled value={selectedFile && selectedFile.name} style={{ width: '80%' }} />
          <SelectFileButton fileId='bulkupload-raised-button-file' onChange={handleFileSelect} multiple={false} className={classes.importButton} accept='.xlsm, .xlsx, .xls'>
            <CustomButton color='secondary' variant="contained" label='Browse' component='div' />
          </SelectFileButton>
        </div>
      </div>
    )
  }

  function renderSequenceSelection() {
    return (
      <div style={{ padding: '8px' }}>
        <SelectInput
          onChange={handleSelectSequence}
          value={sequence}
          options={[
            { label: 'Save Draft', value: '0' },
            { label: 'Pending Merchant Verification', value: '1' },
            { label: 'Publish Immediately', value: '3' }
          ]}
          label='Import As'
          placeholder='Select status'
        />
      </div>
    )
  }

  const handleFileSelect = async (event) => {
    const target = event.target;
    if (target && target.files && target.files.length > 0) {
      const fileSelectedByUser = target.files[0];
      setSelectedFile(fileSelectedByUser);
      const [fileName] = getFileNameAndExtension(fileSelectedByUser.name);
      const errorMsg = await isInvalidFile(fileName);
      if (errorMsg) {
        setError(errorMsg);
        return;
      }
      fileSelectedByUser.action = BlobActions.NEW;
      setError('');
      addFile([fileSelectedByUser], { type: 'file', lockedType: 'file' });
    }
  }

  const downloadSampleFile = () => {
    downloadFile(FILES.TPR_SAMPLE, { path: FILES.DOWNLOAD_PATH }, FILES.TPR_SAMPLE_EXTENSION);
  }

  async function uploadFileToStorage() {
    await uploadFiles(path, action, { sequence });
    setSelectedFile('');
    setDialog({
      show: true,
      message: ModalOverlayMessage.BULK_UPLOAD_DONE,
      action: [{ label: "Close", onClick: closeDialog }]
    });
  }

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.bulkContainer} elevation={3}>
          <TitleText className={classes.editHeading}>
            {name}
          </TitleText>
          {uploadContainer()}
          {renderSequenceSelection()}
        </Paper>
      </div>
      <Paper className={classes.bulkBtnContainer}>
        <div>
          <CustomButton variant="outlined" label='Export CSV Batch Sample File' onClick={downloadSampleFile} color='secondary' />
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <CustomButton variant="outlined" label='Cancel' onClick={close} color='secondary' style={{ margin: '0 8px' }} />
          <CustomButton
            variant='contained'
            label='Import Now'
            disabled={!selectedFile || !!error}
            onClick={uploadFileToStorage}
            color='secondary'
            style={{
              marginRight: '48px',
              marginLeft: '16px'
            }}
          />
        </div>

      </Paper>
      <ConfirmationDialog
        open={dialog.show}
        title={dialog.title}
        text={dialog.message}
        actions={dialog.action}
      />
    </>
  )
}
