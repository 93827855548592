import getDeepValue from '../getDeepValue';
import { SearchTypes, ItemProps } from '../../constants/Constants';
import campaignHeaders from './campaignViewDefinition.json';
import promotionHeaders from './promotionViewDefinition.json';
import promotionGroupsHeaders from './promotionGroupsViewDefinition.json';
import genericTargetedMessagesHeaders from './genericTargetedMessagesViewDefinition.json';
import gtmslotHeaders from './gtmSlotsViewDefinition.json';
import couponHeaders from './couponViewDefinition.json';
import userHeaders from './userViewDefinition.json';
import clubHeaders from './clubViewDefinition.json';
import itemHeaders from './itemViewDefinition.json';
import offersetHeaders from './offersetViewDefinition.json';
import labelHeaders from './labelViewDefinition.json';
import editCouponAssignmentHeaders from './editAssignmentViewDefinition.json'
import couponAssignmentHeaders from './couponGroupsAssignmentViewDefinition.json';
import { getPropValueOrDefault, initObject, initString } from '../helpers';
import couponCodeHeaders from './couponCodeViewDefinition.json';
import termsAndConditionsHeaders from './termsAndConditionsViewDefinition.json';
import biglistHeaders from './biglistViewDefinition.json';
import downloadHeaders from './downloadViewDefinition.json';

export default function getSearchFieldMap(fieldsData, facets, { user, templateQueries }, searchType = '', fromViewOnly) {
  const fieldMap = {};

  let headerData = {};

  switch (searchType.toUpperCase()) {
    case SearchTypes.ITEM:
      headerData = itemHeaders;
      fieldMap['img'] = { ...(getPropValueOrDefault(headerData, 'img')) };
      fieldMap['regulatory'] = { ...(getPropValueOrDefault(headerData, 'regulatory')) };
      break;
    case SearchTypes.CAMPAIGN:
      headerData = campaignHeaders;
      break;
    case SearchTypes.PROMOTION:
      headerData = promotionHeaders;
      break;
    case SearchTypes.USERS:
      headerData = userHeaders;
      break;
    case SearchTypes.CLUB:
      headerData = clubHeaders;
      break;
    case SearchTypes.COUPON:
      headerData = couponHeaders;
      break;
    case SearchTypes.ASSIGNEDCOUPON:
      headerData = editCouponAssignmentHeaders;
      break;
    case SearchTypes.COUPONGROUPS:
      headerData = couponAssignmentHeaders;
      break;
    case SearchTypes.COUPONCODE:
      headerData = couponCodeHeaders;
      break;
    case SearchTypes.TERMSANDCONDITIONS:
      headerData = termsAndConditionsHeaders;
      break;
    case SearchTypes.OFFERSET:
      headerData = offersetHeaders;
      break;
    case SearchTypes.LABEL:
      headerData = labelHeaders;
      break;
    case SearchTypes.PROMOTIONGROUPS:
      headerData = promotionGroupsHeaders;
      break;
    case SearchTypes.GENERICTARGETEDMESSAGE:
      headerData = gtmslotHeaders;
      break;
    case SearchTypes.GENERICTARGETEDMESSAGEBYSLOT:
      headerData = genericTargetedMessagesHeaders;
      break;
    case SearchTypes.BIGLIST:
      headerData = biglistHeaders;
      break;
    case SearchTypes.DOWNLOAD:
      headerData = downloadHeaders;
      break;
    default:
      break;
  }

  let fieldsRaw = fieldsData ? fieldsData.fields : Object.keys(headerData).map(key => ({ ...headerData[key], name: key }));
  const keysToHideArray = getFieldKeyToHide(templateQueries, user, fieldsRaw);
  fieldsRaw = fieldsRaw.filter(field => !keysToHideArray.includes(field.name));

  for (let i = 0; i < fieldsRaw.length; i++) {
    const field = fieldsData ? {
      ...(getPropValueOrDefault(fieldsRaw, i)),
      ...(getPropValueOrDefault(headerData, fieldsRaw[i].name))
    } : fieldsRaw[i];
    const facet = facets && facets[field.name.replace(/\./g, '/')];
    const values = facet && facet.map(({ value }) => ({ value, label: value }));

    // Removing field.type condition as test operator return true only when value exists and contains specified regex.
    const fieldType = /\./.test(field.type) ? field.type.split('.')[1] : field.type;

    fieldMap[field.name] = {
      ...(getPropValueOrDefault(fieldMap, field.name)),
      ...(initObject(field)),
      shortDesc: initString(field.displayName),
      type: fieldType.toUpperCase(),
      searchable: (field.sortable && (field.searchable || field.filterable)) || field.shownField || false,
      values
    }
    if (SearchTypes.ITEM === searchType.toUpperCase() && field.name === ItemProps.CAT) {
      const currentFieldMap = fieldMap[field.name];
      fieldMap[field.name] = {
        ...currentFieldMap,
        childOptions: {
          [ItemProps.SUBCAT]: {
            shortDesc: 'Sub Category',
            type: 'STRING',
            searchable: true,
            advancedFilter: true
          }
        }
      }
    }
  }

  return fieldMap;
}

// Export function to determine which field keys should be hidden based on user role and template queries.
export function getFieldKeyToHide(templateQueries, user, fieldsRaw) {
  const hideKeys = []; // Array to store keys of fields needs to be hidden
  const role = getDeepValue(user, ["role", "name"]);
  if (templateQueries && templateQueries.hideVendorNbr) {

    if (templateQueries.hideVendorNbr.includes(role)) {
      hideKeys.push("vendorNbr");
    }
  }

  //Get all fields which has hideForRoles set
  const filteredFields = fieldsRaw.filter(
    (field) => field.hideForRoles
  );
  if (filteredFields.length > 0) {
    filteredFields.forEach((field) => {
      const rolesToHide = field.hideForRoles.split(",")
      rolesToHide.forEach(r => {
        //If Current role is specified in hideForRoles then insert the field into hideKeys
        if (r === role) {
          hideKeys.push(field.name)
        }
      })
    });
  }
  return hideKeys;   // Return the array of keys that should be hidden.
}
