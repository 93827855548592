import { low } from '../../constants/Constants';

export default (values, otherParams = {}) => {
  const { isListAction, parentValue } = otherParams;
  return values
    ? Object.keys(values).map(key => {
      const value = key;
      if (isListAction && !parentValue && low(key) === 'exclude') {
        return {
          value,
          label: values[key],
          disabled: true
        };
      }
      return {
        value,
        label: values[key]
      }
    }) : [];
}

// Sort function to sort labels alphabetically, if the label has a pipe, use the string before the pipe to sort first
// Eg.
// Input => "2|Label 2", "1|Label 1"
// Sorted => "1|Label 1", "2|Label 2"
export function sortLabel(l,r) {
  if (l && r) {
    const [lOrd, lLabel] = l.label.split('|')
    const [rOrd, rLabel] = r.label.split('|')
    return (lOrd === rOrd ? 0 : (lOrd > rOrd ? 1 : -1)) ||
      (lLabel === rLabel ? 0 : (lLabel > rLabel ? 1 : -1)) ||
      0
  }
  return 0
}


// If label has pipe (1|Label) then we remove the number before the pipe
export function parseLabel(label) {
  if (!label) {
    return label
  }
  return label.includes('|') ? label.slice(label.indexOf('|')+1) : label
}

