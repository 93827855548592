import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./Template.styles";

const TemplateDivider = (props) => {
  const { field, classes } = props;
  const {
    shortDesc, groupName
  } = field;

  return (
    <div className={groupName === "payment_conditions" ? classes.noneDivider : classes.divider}>
      {shortDesc}
    </div>
  )
}

export default withStyles(styles)(TemplateDivider);
