import {
  BlobURL,
  BlockBlobURL,
  ContainerURL,
  ServiceURL,
  StorageURL,
  AnonymousCredential,
  AnonymousCredentialPolicy,
  uploadBrowserDataToBlockBlob,
  Aborter
} from '@azure/storage-blob';
import { BlobDelimiter, FILES, BlobTypes } from '../../constants/Constants';
import { baseURL, containerName } from '../../constants/Endpoints';
import env from '../../config/env';
import { getFileNameAndExtension } from '../../Utils/helpers';


class ApimCredentialsPolicy extends AnonymousCredentialPolicy {
  async sendRequest(request) {
    request.withCredentials = true;
    request.headers.set('Ocp-Apim-Subscription-Key', process.env.REACT_APP_BASE_SUBSCRIPTION_KEY)
    return super.sendRequest(request);
  }
}
class ApimCredentials extends AnonymousCredential {
  create(nextPolicy, options) {
    return new ApimCredentialsPolicy(nextPolicy, options);
  }
}

function getType(type) {
  return type?`${type}/`:'';
}

const createBlobName = (fileObj, action, path, user) => {
  const timestamp = Date.now();
  const userId = user.userId;
  const fileAction = fileObj.action ? fileObj.action : action;
  const type = getType(fileObj.type);
  const [name, extension] = getFileNameAndExtension(fileObj.name);
  const addSeq = fileObj.sequence ? `${BlobDelimiter}${fileObj.sequence}`: ``
  return fileObj.type !== FILES.TYPE ?
    `${path}${type}${name}${BlobDelimiter}${fileAction}${BlobDelimiter}${timestamp}${BlobDelimiter}${userId}${extension}` :
    `${path}/${name}${BlobDelimiter}${userId}${addSeq}${extension}`;
}

export default async function addBlob(fileObj, path, action, user, aborter, progress) {
  if(env === 'mock') {
    return;
  } else {
    const file = fileObj.file;
    const apimCredential = new ApimCredentials();
    const pipeline = StorageURL.newPipeline(apimCredential);
    const serviceURL = new ServiceURL(
      path.indexOf(BlobTypes.PROMO_CLONE)>=0?`${baseURL}blob/upload/`:`${baseURL}biglist/upload/`,
      pipeline
    );

    // // Create a container
    const containerURL = ContainerURL.fromServiceURL(serviceURL, containerName);

    // Create a blob
    const blobName = createBlobName(fileObj, action, path, user);
    const blobURL = BlobURL.fromContainerURL(containerURL, blobName);
    const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);

    const uploadBlobResponse = await uploadBrowserDataToBlockBlob(
      aborter ? aborter : Aborter.none,
      file,
      blockBlobURL,
      {
        metadata: {},
        blockSize: file.length,
        parallelism: 20,
        progress: progress ? progress : () => null
      }
    );
    console.log(
      `Upload block blob ${blobName} successfully`,
      uploadBlobResponse.requestId
    );
  }
}
