import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, Grid, Typography } from '@material-ui/core';
import { EditorState } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import useStyles from './ContentEditor.styles';
import CustomButton from '../buttons/CustomButton/CustomButton';
import HTMLContentEditor from './HTMLContentEditor';
import NormalContentEditor from './NormalContentEditor';
import { convertContentToHTML, convertContentToText, getUpdatedEditorState } from '../../Utils/editor/editor-utils';
import { ContentTypes } from '../../constants/Constants';


function EditorPreview({field, valueType, disablePreview}) {
  const classes = useStyles();
  const { value, shortDesc: label, defaultValue, required, mode, values, error } = field;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showEditor, setShowEditor] = useState(false);
  const isViewOnly = (mode === "viewOnly");
  const isPreviewDisabled = disablePreview && !isViewOnly ;


  useEffect(() => {
    update(valueType, value)
  }, [value, valueType])

  const updateState = (updatedEditorState, updatedContent) => {
    onChange(updatedEditorState);
    field.onChange({target: {value: updatedContent}})
  }

  const update = (contentType, content) => {
    const defaultValue = EditorState.createEmpty();
    const newEditorState = getUpdatedEditorState(content, contentType, defaultValue);
    if (newEditorState) {
      updateState(newEditorState, content)
    }
  }

  const onContentUpdate = (content) => {
    update(valueType, content);
    setShowEditor(false);
  }

  const customBlockStyleFn = (contentBlock) => {
    return classes['public-DraftStyleDefault-block'];
  }
  const onChange = (newEditorState) => setEditorState(newEditorState);


  const evaluateEditor = (valueType) => {
    if (valueType === ContentTypes.HTML) {
      return (
        <HTMLContentEditor
          value={convertContentToHTML(editorState)}
          onCancel={() => setShowEditor(false)}
          onContentUpdate={onContentUpdate}
          defaultValue={defaultValue}
          required={required}
          templateDropdownOptions={values}
          disablePreview={disablePreview}
        />
      )
    } else if (valueType === ContentTypes.TEXT) {
      return (
        <NormalContentEditor
          value={convertContentToText(editorState)}
          onContentUpdate={onContentUpdate}
          onCancel={() => setShowEditor(false)}
          defaultValue={defaultValue}
          required={required}
          templateDropdownOptions={values}
          disablePreview={disablePreview}
        />
      )

    } else {
      return null;
    }

  }

  const renderEditor = () => {
    if (isPreviewDisabled) {
      return evaluateEditor(valueType)
    } else {
      return (
        <Dialog maxWidth="lg" data-testid="dialog" open={showEditor} title="Item Promo Message and Formatting">
          {evaluateEditor(valueType)}
        </Dialog>
      )
    }
  }

  return(
    <div>
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Typography className={clsx(classes.labelStyles, { [classes.labelStylesError]: !!error })}>
            {label}
            {required ? '*' : ''}
          </Typography>
        </Grid>
        { !isPreviewDisabled && (
          <Grid item xs={9} id="editor-preview">
            <Editor
              toolbarClassName={classes.toolbarClassName}
              wrapperClassName={clsx(classes.editorArea, { [classes.editorAreaError]: !!error })}
              editorClassName={classes.messageArea}
              editorState={editorState}
              readOnly
              toolbarHidden
              blockStyleFn={customBlockStyleFn}
            />
          </Grid>
        ) }
        {(!isViewOnly && !isPreviewDisabled) ?
          (
            <Grid item xs={1}>
              <CustomButton label='Edit' color='secondary' onClick={() => setShowEditor(currEditorOpen => !currEditorOpen)} />
            </Grid>
          )
          : null}
      </Grid>
      {!!error && (
        <Grid item xs={10}>
          <Typography
            className={clsx(classes.labelStyles, {
              [classes.labelStylesError]: !!error,
            })}
          >
            {error}
          </Typography>
        </Grid>
      )}
      {renderEditor()}
    </div>
  )
}

export default EditorPreview;
