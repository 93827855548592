import React from 'react';
import MaterialCheckbox from '@material-ui/core/Checkbox';

function Checkbox({checked, ...props}) {

  function onClick(event) {
    event.stopPropagation();
    if (props.onClick) {
      props.onClick();
    }
  }

  return (
    <MaterialCheckbox
      {...props}
      checked={!!checked}
      indeterminate={checked === 'some'}
      onClick={onClick}
    />
  )
}

export default Checkbox;
