import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function getAllBiglistValuesService(biglistName, limit = 3, offset, values, searchType) {
  try {
    const body = {
      offset: offset,
      values: values
    }
    const searchTypePath = searchType ? `${searchType}/` : ''
    return await fetch(`${baseURL}${searchTypePath}biglist/${biglistName}/search?limit=${limit}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });
  } catch (error) {
    return error;
  }
}

export default getAllBiglistValuesService
