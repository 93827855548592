const styles = () => ({
  reOrderActions: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  reOrderFontSize: {
    fontSize: '2.5rem !important'
  }
})

export default styles;
