import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import {
  withStyles
} from '@material-ui/core';
import styles from './ClubList.styles';
import Search from '../../compartments/search/search';
import FullScreenModal from '../modal/FullScreenModal';
import { DATE_ACTIONS } from '../../compartments/search/getSearchDateActions';


function ClubList({ classes, isModal = true, hasActions, open, cancel, save, noDate = true, value, ...otherProps }, ref) {
  const childSearch = useRef(null);

  useImperativeHandle(ref, () => ({
    async getSelected() {
      const { selectedRowsarray, unselectedRowsarray, globalDate, dateErrorArray, ...other } = await childSearch.current.getSelected();

      const selectedRows = processSelectedData({ selectedRowsarray, globalDate });

      return { ...other, selectedRowsarray: selectedRows, unselectedRowsarray };
    }
  }));


  const processSelectedData = ({ selectedRowsarray = [], globalDate = {} }) => {
    const selectedRows = selectedRowsarray.map(row => {
      const otherProps = DATE_ACTIONS.reduce((acc, key) => {
        return {
          ...acc,
          [key]: row[key]
        };
      }, {});
      return {
        id: row.storeNumber,
        ...otherProps
      };
    });

    return selectedRows;
  }

  const processSave = (params, type) => {
    const selectedData = processSelectedData(params);
    save({ values: selectedData, type });
  }

  const commonActionProps = {
    onClickParams: ['selectedRowsarray', 'globalDate'],
    disabledConditions: {
      ifTrue: noDate ? [] : ['dateErrorArray']
    },
    buttonProps: {
      color: 'secondary',
      variant: 'contained'
    }
  };

  const component = (
    <Search
      {...otherProps}
      ref={childSearch}
      searchType='club'
      subSearchType='storeNumber'
      dateEnabled={!noDate}
      searchActions={hasActions ? [
        {
          label: 'Add',
          onClick: params => processSave(params, 'add'),
          ...commonActionProps
        },
        ...(value ? [
          {
            label: 'Remove',
            onClick: params => processSave(params, 'remove'),
            ...commonActionProps,
            buttonProps: {
              ...commonActionProps.buttonProps,
              disabled: !value
            }
          },
          {
            label: 'Replace',
            onClick: params => processSave(params, 'replace'),
            ...commonActionProps,
            buttonProps: {
              ...commonActionProps.buttonProps,
              disabled: !value
            }
          }
        ] : []),
        {
          label: 'Cancel',
          onClick: cancel,
          buttonProps: {
            color: 'secondary'
          }
        }
      ] : undefined}
      isModal
      multiSelect
      // disableActions
      // queries={queries}
    />
  )

  if (isModal) {
    return (
      <FullScreenModal
        open={open}
        onRequestClose={cancel}
        title='Club List'
      >
        {component}
      </FullScreenModal>
    );
  } else {
    return component
  }
}

export default withStyles(styles)(forwardRef(ClubList));
