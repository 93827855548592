import exportOfferService from "../../services/promotions/exportOfferService";
import exportOfferServiceById from "../../services/promotions/exportOfferServiceById";
import { ModalOverlayMessage, ExportTypes } from "../../constants/Constants";

export async function exportOffers(rows, currentUser, setBulkWorkflowDialog, watchDownload, showExportButton, fetchAllSearchData, exportType = ExportTypes.EXPORT_BY_ITEM) {
  let response;
  if (showExportButton) {
    // collecting all offerIds from the selected rows
    const offerIds = rows && rows.map(row => row?.id) || [];
    const payload = {
      'offerIds': offerIds,
      exportType: exportType
    };
    response = await exportOfferServiceById(payload, currentUser);
  } else {
    response = await exportOfferService(rows[0], currentUser);
  }
  //if you click export by Offer
  let dialogProps;
  if (response) {
    if (response.name && response.status === 'SUCCESS') {
      watchDownload(response.name);
      dialogProps = {
        title: 'Exporting offer',
        text: ModalOverlayMessage.EXPORT_OFFERS_STARTED_MESSAGE
      }
    } else {
      dialogProps = {
        title: 'Error exporting offer',
        text: response.message
      }
    }
  } else {
    dialogProps = {
      title: 'Error exporting offer',
      text: 'Empty response'
    }
  }

  setBulkWorkflowDialog({
    ...dialogProps,
    open: true,
    actions: [
      {
        onClick: () => setBulkWorkflowDialog({ open: false }),
        label: 'ok',
        color: 'secondary',
        variant: 'contained'
      }
    ]
  });
}

