import Colors from '../../theme/Colors'

const styles = theme => ({
  fullScreenModal: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: 0,
    justifyContent: 'stretch'
  },
  itemListContainerFull: {
    position: 'relative',
    outline: 'none',
    backgroundColor: Colors.white,
    overflow: 'scroll',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  searchContainer: {
    margin: 'auto',
    width: '100%'
  },
  closeButton: {
    // position: 'absolute',
    // top: 8,
    // right: 8
    marginLeft: 'auto'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: 4,
    boxSizing: 'border-box',
    borderBottom: '1px solid #545f7a'
  }
});

export default styles;
