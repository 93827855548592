import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { SEARCH_NO_INDEX, SEARCH_SERVICE, SEARCH_ROLES, GET_ALL_BRANDING } from '../../queries/searchQueries';
import Search from './search';
import { ERROR_OVERLAY, FieldMap, RootTypes, SearchTypes, BIGLIST_EDIT_LIMIT } from '../../constants/Constants';
import getCompartmentTypes from '../../Utils/compartments/getCompartmentTypes';
import SearchOffersByItemService from '../../services/admin/SearchOfferByItemsService';
import useLoadingService from '../../hooks/useLoadingService';
import getAllBiglistValuesService from '../../services/biglist/GetAllBiglistValuesService';
import ErrorComponent from '../../widgets/error/ErrorComponent';
import getSearchFieldMap from '../../Utils/smart-search/getSearchFieldMap';


const hideFields = [];

function SearchNoIndex({ searchType, rootType, subtype, filterInput: filterInputProps, searchAll = true, filterOverride, ...otherProps }, ref) {

  const childSearch = useRef(null);
  const [dataForSearch, setDataForSearch] = useState(null);
  const type = searchType || rootType;
  const [errorMsg, setErrorMsg] = useState('');

  useImperativeHandle(ref, () => ({
    async getSelected() {
      return await childSearch.current.getSelected();
    }
  }));

  const [callSearchOffersByItemService, { loading: loadingOffers }] = useLoadingService(SearchOffersByItemService, {
    onCompleted: handleSearchOffersComplete,
    onError: handleSearchOfferError
  });

  const [callGetAllBiglistValuesService] = useLoadingService(getAllBiglistValuesService, {
    onCompleted: handleGetAllBiglistValuesComplete,
    onError: handleSearchOfferError
  });


  const filterInput = filterInputProps ? filterInputProps : {}

  useEffect(() => {
    search();
  }, [])
  const [getBrandingService, {error: brandingError}] = useMutation(GET_ALL_BRANDING, {
    onCompleted: handleOnComplete
  })

  const [searchQuery, { loading, error }] = useMutation(type === getCompartmentTypes.COUPON ? SEARCH_SERVICE : (type === SearchTypes.ROLE.toLowerCase() ? SEARCH_ROLES : SEARCH_NO_INDEX), {
    onCompleted: handleOnComplete,
    variables: {
      input: filterInput,
      rootType: type
    }
  });

  function search() {
    if(otherProps.fromViewOnly) {   // Get first 100 items to view biglist
      callGetAllBiglistValuesService(otherProps.biglistName, BIGLIST_EDIT_LIMIT, null, null, searchType)
    } else if (rootType === RootTypes.ITEMS) {
      callSearchOffersByItemService(otherProps.item.itemNumber)
    } else if(type === getCompartmentTypes.BRANDING) {
      getBrandingService();
    } else {
      searchQuery();
    }
  }

  function handleGetAllBiglistValuesComplete(res) {
    if (res && res.payload) {
      setDataForSearch({ fieldMap: getSearchFieldMap(null, null, {}, type, otherProps.fromViewOnly), rowData: res.payload });
    }
  }

  function handleSearchOffersComplete(res) {
    if (res.payload && res.payload.value) {
      setDataForSearch({ fieldMap: otherProps.fieldMap, rowData: res.payload.value });
    }
  }

  function handleOnComplete(res) {
    if (res && res.searchNoIndex && res.searchNoIndex.payload) {
      const payload = res.searchNoIndex.payload;
      const dataList = payload;
      const rowData = createRowData(dataList);
      setDataForSearch({ fieldMap: FieldMap[type], rowData, hideFields });
    }
  }

  function handleSearchOfferError(errorRes) {
    setErrorMsg(errorRes.description || ERROR_OVERLAY.TITLE)
  }

  function refresh() {
    setDataForSearch(null);
    search();
  }

  function setSelectedProperty(item) {
    if(type === getCompartmentTypes.COUPON && otherProps.subSearchType === getCompartmentTypes.COUPONID) {
      return otherProps.value && otherProps.value.indexOf(item[otherProps.subSearchType]) > -1
    }
    return false;
  }

  function createRowData(dataList) {
    return dataList.map(item => {
      return {
        ...item,
        id: item.id,
        selected: setSelectedProperty(item)
      };
    });
  }

  return (
    <Search
      {...otherProps}
      subtype={subtype}
      ref={childSearch}
      searchType={type}
      data={dataForSearch}
      dataLoading={(loading || loadingOffers) && !dataForSearch}
      skipService
      isModal
      refresh={refresh}
      filterOverride={filterOverride}
      total={dataForSearch && dataForSearch.rowData ? dataForSearch.rowData.length : 0}
      parentError={error || errorMsg || brandingError}
    />
  )
}

export default forwardRef(SearchNoIndex);
