import gql from "graphql-tag";

export const CREATE_ITEM = gql`
mutation createItem($type: String!) {
  createItem(type: $type, input: $input) @rest(type: "createItem", path: "{args.type}", method: "POST") {
    status,
    header,
    errors,
    payload
  }
}`;

export const UPDATE_ITEM = gql`
mutation updateItem($type: String!, $id: String!) {
  createItem(type: $type, id: $id, input: $input) @rest(type: "createItem", path: "{args.type}/{args.id}", method: "PUT") {
    status,
    header,
    errors,
    payload
  }
}`;

export const UPDATE_BRANDING = gql`
mutation updateItem($type: String!, $id: String!) {
  createItem(type: $type, id: $id, input: $input) @rest(type: "createItem", path: "{args.type}", method: "PUT") {
    status,
    header,
    errors,
    payload
  }
}`;

export const DELETE_ITEM = gql`
mutation deleteItem($type: String!, $id: String!) {
  deleteItem(type: $type, id: $id, input: $input) @rest(type: "deleteItem", path: "{args.type}/{args.id}", method: "DELETE") {
    status,
    header,
    errors,
    payload
  }
}`;

export const DELETE_ITEMS = gql`
mutation deleteItems($type: String!) {
  deleteItems(type: $type, input: $input) @rest(type: "deleteItems", path: "{args.type}/delete", method: "POST") {
    status,
    header,
    errors,
    payload
  }
}`;
