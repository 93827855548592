import Colors from '../../theme/Colors';

const styles = () => ({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
    gridGap: 20
  },
  inputContainer: {
    alignItems: "center",
    '& .MuiFormControl-marginNormal': {
      margin: 'auto'
    },
    '& > div > div': {
      margin: 'auto'
    },
    marginTop: 18,
    marginBottom: 16
  },
  inputContainerNoGrid: {
    '& .MuiFormControl-marginNormal': {
      marginBottom: 0
    }
  },
  searchContainer: {
    maxWidth: "67vw"
  },
  required: {
    paddingLeft: 8,
    color: Colors.red
  },
  commentNote: {
  },
  fab: {
  },
  actionsContainerWithLabel: {
    justifyContent: "flex-end",
    display: "flex",
    marginTop: '15px'
  },
  actionsContainerWithLeftAlignLabel: {
    justifyContent: "flex-start",
    display: "flex",
    marginTop: '15px',
    '& button': {
      marginRight: '15px'
    }
  },
  actionsContainerWithoutLabel: {
    padding: '0px 16px',
    margin: 'auto'
  },
  fieldsWrapper: {
    maxWidth: '100%'
  },
  gridContainer: {
    flex: 1,
    alignItems: 'center'
  },
  repeatableCellContainer: {

  },
  repeatableCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  infoCellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  popover: {
    backgroundColor: "#F5F6F7",
    borderColor: "#CCD5DF",
    borderWidth: 1,
    borderStyle: 'solid'
  },
  typography: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: 256,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "18px",
    color: "#38475D"
  }
})

export default styles;
