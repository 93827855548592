/*
  get autoforward state
*/
export default function stateAutoForward(workflow, activeState, roleAccess){
  const nextState = (+activeState >= 0) ? +activeState : +activeState + 1;
  let autoForward = false;

  const isFoundState = workflow.find((state)=> state.sequence === nextState);
  autoForward = isFoundState && roleAccess && (roleAccess.stepAccess[isFoundState.name.toLowerCase()] === 'A' || roleAccess.stepAccess[isFoundState.name.toLowerCase()] === 'O' || roleAccess.stepAccess[isFoundState.name.toLowerCase()] === 'S')?  true : false;

  return autoForward;
}
