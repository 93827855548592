const styles = theme => ({
  root: {
    display: "flex",
    flexDirecton: "row",
    "vertical-align": "super",
    alignItems: 'center'
  },
  modal: {
    position: "absolute",
    width: "90%",
    height: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`
  },
  csvContainer: {
    flexGrow: 1,
    width: '100%'
  },
  biglistContainer: {
    flexGrow: 1
  },
  searchContainer: {
    paddingRight: 16,
    display: "flex",
    alignItems: "center"
  },

  label_box: {
    width: "150px",
    display: "inline-block"
  },
  label_text: {
    marginLeft: "20px"
  },
  input_text: {
    minWidth: 150
  },
  search_button: {
    "z-index": "3"
  },
  gapContainer: {
    margin: 8
  }
});

export default styles;
