import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';
import { fontMedium } from '../../theme/theme'

const styles = {
  root: {
    width: '100%',
    display: 'flex'
  },
  text: {
    display: 'inline-block',
    height: 22,
    paddingLeft: 8,
    paddingRight: 8,
    textAlign: 'center',
    backgroundColor: Colors.white,
    color: Colors.grey900,
    border: '2px solid transparent',
    borderRadius: 3,
    fontSize: 12,
    fontWeight: fontMedium,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '22px',
    letterSpacing: 'normal',
    minWidth: 44
  },
  label: {
    color: 'rgba(36, 37, 61, 0.5)',
    display: 'block',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 500,
    transform: 'translate(8px, 2px)'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: -8
  },
  previewWrapper: {
    padding: 16,
    height: 26,
    border: `1px solid ${Colors.black}`
  }
};

export default makeStyles(styles);
