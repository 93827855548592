import React, { useState, useEffect } from "react";
import {
  withStyles,
  Button,
  Modal,
  Paper,
  Typography
} from "@material-ui/core";
import styles from "./Template.styles";
import TemplateForm from '../../../compartments/form/TemplateForm'
import FullScreenModal from "../../modal/FullScreenModal";
import getDeepValue from "../../../Utils/getDeepValue";

const TemplateModal = props => {
  const { classes, showModal, modalProps, dispatch, field, group, dataState, setTemplateModalData, isViewOnly, workflowItem, openModalFromParent } = props;
  const {
    btnName,
    rootType,
    templateId,
    templateGroupType,
    parentData,
    modalVariant,
    hasMultipleGroups,
    saveDataInParent,
    sameScreenModal
  } = modalProps;

  const autoOpenModal = getDeepValue(field, ['queries', 'autoOpenModal'])

  const [show, setShow] = useState(false);


  useEffect(() => {
    if (autoOpenModal) {
      setShow(showModal)
    }
  }, [showModal, autoOpenModal, setShow])

  const handleOpen = () => {
    setShow(true);
  }

  const handleClose = async () => {
    if(saveDataInParent){
      props.closeModalInparent()
    }
    setShow(false);
  }

  function renderBody() {
    return (
      <TemplateForm
        key={field.name}
        rootType={rootType}
        templateId={templateId}
        templateModal
        handleModalClose={handleClose}
        dispatch={dispatch}
        field={field}
        group={group}
        dataState={dataState}
        setTemplateModalData={setTemplateModalData}
        templateGroupType={templateGroupType}
        hasMultipleGroups={hasMultipleGroups}
        saveDataInParent={saveDataInParent}
        isViewOnly={isViewOnly}
        workflowItem={workflowItem}
        parentData={parentData}
        modalVariant={modalVariant}
        sameScreenModal={sameScreenModal}
      />
    )
  }

  let modalTitle = ""
  if (field && field.metadata && field.metadata.queries && field.metadata.queries.title && field.metadata.queries.title !== '') {
    modalTitle = field.metadata.queries.title
  }

  function renderFullScreenModal() {
    if (sameScreenModal) {
      return renderModalWithInPage()
    }
    return (
      <div>
        {! saveDataInParent &&  <Button color='secondary' variant='contained' style={{ marginLeft: '8px' }} onClick={handleOpen}>{btnName}</Button> }
        <FullScreenModal
          open={saveDataInParent ?openModalFromParent : show}
          onRequestClose={handleClose}
          title={modalTitle}
        >
          <div className={classes.modalBody}>
            <div className={classes.listContainer}>
              {renderBody()}
            </div>
          </div>
        </FullScreenModal>
      </div>
    );
  }


  // Convert a combinedTextFormat into text we can display the user
  function getCombinedText() {
    const combinedTextFormat = getDeepValue(field, ['metadata', 'queries', 'combinedText']);
    const parentSelectedValue = getDeepValue(field, ['metadata', 'queries', 'parentSelectedValue']);
    const values = getDeepValue(field, [parentSelectedValue]);
    if (combinedTextFormat && values) {
      let combinedText = combinedTextFormat;
      // extract out values, similar to template literals ${field} becomes values[field]
      const regex = /\${(\w+)}/g
      let match = regex.exec(combinedTextFormat);

      // each loop match another value
      while (match) {
        const [replace, field] = match;
        if (!values[field]) { // missing a field, do not return text
          return;
        }
        combinedText = combinedText.replace(new RegExp('\\' + replace, 'g'), values[field])
        match = regex.exec(combinedTextFormat);
      }

      return combinedText
    }
  }

  function renderModal(customProps) {
    return (
      <Modal
        open={saveDataInParent ? openModalFromParent : show}
        {...customProps}
        onClose={handleClose}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Paper
          classes={{
            root: classes.popoverRoot
          }}
        >
          {renderBody()}
        </Paper>
      </Modal>
    )
  }

  function renderModalWithInPage() {
    const customProps = {
      open: openModalFromParent,
      onRequestClose: handleClose,
      title: modalTitle
    }
    return (
      <div className={classes.popoverContainer}>
        {!saveDataInParent && <Button color='secondary' variant='contained' style={{ marginLeft: '8px' }} onClick={handleOpen}>{btnName}</Button>}
        {renderModal(customProps)}
      </div>
    );
  }


  function renderPopover() {
    const combinedText = getCombinedText()
    const customProps = {
      open: show,
      disableBackdropClick: true,
      disableEscapeKeyDown: true

    }
    return (
      <div className={classes.popoverContainer}>
        {combinedText ? <Typography className={classes.combinedText}>{combinedText}</Typography> : null}
        {renderModal(customProps)}
      </div>
    )
  }

  if (modalVariant === 'popover') {
    return renderPopover();
  } else {
    return renderFullScreenModal();
  }
};

export default withStyles(styles)(TemplateModal);
