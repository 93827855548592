import getDeepValue from './getDeepValue';

export default function parseItemString(item, str) {
  if (!str) {
    return '';
  }

  const regex = /{(\w|\.)*}/g;
  const matches = str.match(regex);

  if (!matches) {
    return str;
  }
  if (!item) {
    return '';
  }

  let newStr = str;
  matches.forEach((match) => {
    const keys = match.slice(1,match.length-1).split('.');
    const value = getDeepValue(item, keys);
    newStr = newStr.replace(match, value)
  });

  return newStr;
}
