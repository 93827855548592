import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './Template.styles';
import SearchInputDiff from '../../diff/inputs/SearchInputDiff';
import getDeepValue from '../../../Utils/getDeepValue';
import { TemplateSpecialCaseNames } from '../../../constants/Constants';


const TemplateSearch = props => {
  const { field, classes } = props;
  const {
    longDesc,
    name,
    mode,
    data,
    required,
    shortDesc,
    readOnly,
    searchType,
    value,
    error,
    onChange,
    queries,
    fullValue,
    initDisable
  } = field;
  const [mainSearchType, subSearchType] = searchType.split('.');

  const deepName = field.name === TemplateSpecialCaseNames.AWARD_COUPON_GROUP ? 'couponGroupName' : field.name; // Display coupon value correctly
  const fieldValue = getDeepValue(value, [deepName]) || getDeepValue(value, ['id']) || value;

  const isViewOnly = (mode === "viewOnly");
  const isDisabled = readOnly || isViewOnly || initDisable;

  return (
    <SearchInputDiff
      id={name}
      className={`${classes.root} ${classes.flex}`}
      margin='normal'
      onChange={onChange}
      InputLabelProps={{ shrink: true }}
      label={shortDesc}
      placeholder={longDesc}
      value={fieldValue}
      condition={name}
      mode={mode}
      data={data}
      disabled={isDisabled}
      required={required}
      error={error}
      searchType={mainSearchType}
      subSearchType={subSearchType}
      fullWidth
      getAllData
      queries={queries}
      fullValue={fullValue}
    />
  );
};

export default withStyles(styles)(TemplateSearch);
