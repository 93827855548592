import { makeStyles } from '@material-ui/core';
// import Colors from '../../../theme/Colors';
// import { fontLight, fontMedium, fontBold } from '../../../theme/theme';

const styles = {
  detail: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 48,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  detailValue: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '50%',
    // maxHeight: 48,
    overflow: 'auto'
  }
}

export default makeStyles(styles);
