import React from 'react';
import clsx from 'clsx';
import { Table, Grid } from 'react-virtualized';
import cellRangeRenderer from './cellRangeRender';
import './virtualized-table.css';

class VirtualizedTable extends Table {
  constructor(props) {
    super(props);

    this._cellRenderer = this._cellRenderer.bind(this);
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (prevProps.children !== this.props.children) {
      this.recomputeRowHeights();
    }
  }

  _getHeaderColumns() {
    const { headerRowRenderer, rowStyleObject, headerHeight, scrollbarWidth, rowWidth, rowClass } = this.props;

    const rendered = headerRowRenderer({
      className: clsx('ReactVirtualized__Table__headerRow', rowClass, 'ReactVirtualized__Table__headerSticky'),
      columns: super._getHeaderColumns(),
      style: {
        ...rowStyleObject,
        height: headerHeight,
        overflow: 'visibile',
        paddingRight: scrollbarWidth,
        width: rowWidth
      },
      key: 'Header'
    })
    return rendered;
  }

  _cellRenderer({rowIndex, ...args}) {
    //  if(!this.props.searchLoader){
    if(rowIndex === 0 ) {
      return this._getHeaderColumns();
    } else if(!this.props.searchLoader){
      return this._createRow({...args, rowIndex: rowIndex-1})
    }
  // }
  }

  render() {
    const {
      children,
      className,
      // disableHeader,
      gridClassName,
      gridStyle,
      // headerHeight,
      //height,
      id,
      noRowsRenderer,
      // rowClassName,
      // rowStyle,
      scrollToIndex,
      style,
      //width,
      //overlayMessage,
      overlayComponent
    } = this.props;
    const { scrollbarWidth } = this.state;

    // const availableRowsHeight = disableHeader ? height : height - headerHeight;

    // const rowClass =
    //   typeof rowClassName === 'function'
    //     ? rowClassName({ index: -1 })
    //     : rowClassName;
    // const rowStyleObject =
    //   typeof rowStyle === 'function' ? rowStyle({ index: -1 }) : rowStyle;

    // Precompute and cache column styles before rendering rows and columns to speed things up
    this._cachedColumnStyles = [];
    React.Children.toArray(children).forEach((column, index) => {
      const flexStyles = this._getFlexStyleForColumn(
        column,
        column.props.style,
      );

      this._cachedColumnStyles[index] = {
        ...flexStyles,
        overflow: 'hidden'
      };
    });

    // Note that we specify :rowCount, :scrollbarWidth, :sortBy, and :sortDirection as properties on Grid even though these have nothing to do with Grid.
    // This is done because Grid is a pure component and won't update unless its properties or state has changed.
    // Any property that should trigger a re-render of Grid then is specified here to avoid a stale display.
    return (
      <div
        aria-label={this.props['aria-label']}
        aria-labelledby={this.props['aria-labelledby']}
        aria-colcount={React.Children.toArray(children).length}
        aria-rowcount={this.props.rowCount}
        className={clsx('ReactVirtualized__Table', className)}
        id={id}
        role="grid"
        style={style}
      >
        <Grid
          {...this.props}
          cellRangeRenderer={cellRangeRenderer}
          rowCount={this.props.rowCount+1}
          width={this.props.rowWidth}
          className={clsx('ReactVirtualized__Table__Grid', gridClassName, 'TableGridOverflow')}
          cellRenderer={this._cellRenderer}
          containerStyle={{
            overflow: 'visible'
          }}
          columnWidth={this.props.rowWidth}
          columnCount={1}
          height={this.props.gridHeight}
          id={undefined}
          noContentRenderer={noRowsRenderer}
          onSectionRendered={this._onSectionRendered}
          ref={this._setRef}
          role="rowgroup"
          scrollbarWidth={scrollbarWidth}
          scrollToRow={scrollToIndex}
          isScrollingOptOut
          style={{
            ...gridStyle,
            overflowX: 'auto',
            overflowY: 'auto',
            width: 'auto',
            height: '100%'
          }}
        />
        {(this.props.rowCount === 0|| this.props.searchLoader ) && overlayComponent  ? (
          <div className='tableOverlayContainer'>
            <div className='tableOverlayContent'>
              {overlayComponent}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default VirtualizedTable;
