import React from 'react';
import {
  Divider
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import clsx from 'clsx';
import useStyles from './ButtonList.styles';
import CustomButton from '../CustomButton/CustomButton';
import MenuButton from '../MenuButton/MenuButton';

function ButtonList(props) {
  const classes = useStyles();

  const {
    items = [],
    className
  } = props;

  const menuItems = items.slice(1);

  return (
    <div className={clsx(classes.root, className)}>
      {items.length > 0 ? (
        <CustomButton labelSize='small' onClick={items[0].onClick} label={items[0].label} RightIcon={items[0].Icon} disabled={items[0].disabled} startIcon={items[0].startIcon} color='secondary' />
      ) : null}
      {items.length > 1 ? (
        <>
          <Divider orientation='vertical' className={classes.divider} />
          <MenuButton
            Icon={MoreHoriz}
            menuItems={menuItems}
            iconButtonProps={{
              className: classes.iconButton
            }}
            disabled={menuItems.filter((item) => item.disabled).length === menuItems.length}
          />

        </>
      ) : null}
    </div>
  )
}

export default ButtonList;
