import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  headerContainer: {
    padding: '0 32px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #eeeeee',
    height: 32
  },
  title: {
    color: '#6a7187',
    fontWeight: '500'
  },
  headerContainerBlue: {
    backgroundColor: Colors.primaryBlue
  },
  titleBlue: {
    color: Colors.white
  }
});

function HeaderTitle({ children, title, blue }) {
  const classes = useStyles();
  if (!title) {
    return null;
  }
  return (
    <div className={clsx(classes.headerContainer, { [classes.headerContainerBlue]: blue })}>
      <div className={clsx(classes.headerTitleContainer, { [classes.headerTitleContainerBlue]: blue })}>
        <Typography variant='h6' className={clsx(classes.title, { [classes.titleBlue]: blue })}>{title}</Typography>
      </div>
      {children ? children : null}
    </div>
  );
}

export default HeaderTitle;
