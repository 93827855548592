import { makeStyles } from '@material-ui/core';
import Colors from '../../../theme/Colors';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 36,
    marginLeft: 'auto'
  },
  divider: {
    margin: '0px 2px',
    width: 1,
    height: 24,
    backgroundColor: Colors.grey500
  },
  moreIcon: {
    color: Colors.primaryBlue
  },
  iconButton: {
    padding: 6
  }
}

export default makeStyles(styles);
