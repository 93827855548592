import React, { forwardRef } from 'react';
import Typography from '@material-ui/core/Typography'
import useStyles from './Brand.styles';

function Brand(props, ref) {
  const classes = useStyles();
  const { badgeText, foregroundColor, backgroundColor, outlineColor, cornerRadius, align = 'center', label, labelHidden, previewBackgroundColor, ...tooltipProps } = props;
  const displayText = badgeText || props.badge;
  const brandComponent = (
    <Typography
      key={`${displayText} ${backgroundColor} ${foregroundColor} ${outlineColor} ${cornerRadius}`}
      className={classes.text}
      style={{
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderColor: outlineColor,
        borderRadius: cornerRadius,
        marginLeft: label && !previewBackgroundColor ? 4 : 0
      }}
      component='span'
      noWrap
    >
      {displayText}
    </Typography>
  )

  const previewWrapperComponent = previewBackgroundColor ? (
    <div className={classes.previewWrapper} style={{ backgroundColor: previewBackgroundColor }}>
      {brandComponent}
    </div>
  ) :  brandComponent;

  const component = label ? (
    <div className={classes.labelContainer}>
      {label ? (
        <Typography className={classes.label} style={{visibility: labelHidden ? 'hidden' : 'visibile'}} component='label'>
          {label}
        </Typography>
      ) : null}
      {previewWrapperComponent}
    </div>
  ) : previewWrapperComponent;

  return (
    <div
      ref={ref}
      {...tooltipProps}
      className={classes.root}
      style={{
        justifyContent: align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : 'center',
        width: previewBackgroundColor ? 'auto' : '100%'
      }}
    >
      {component}
    </div>
  );

}


export default forwardRef(Brand);
