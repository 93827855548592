import React, { useMemo } from 'react';
import useStyles from './SearchModal.styles';
import CustomButton from '../../../widgets/buttons/CustomButton/CustomButton';
import SearchFlexible from '../search';
import { FieldTypes } from '../../../constants/Constants';
import getDeepValue from '../../../Utils/getDeepValue';

function SearchFor(props) {
  const { items, close, params, fieldMap } = props;
  const classes = useStyles();

  const item = items? ( Array.isArray(items) ? items[0] : items ) : null;

  const { searchType, searchKey, id, actions } = params.moreParams;

  const parentFilter = useMemo(() => {
    if (!item) {
      return null
    }
    const path = id.split('.');
    const selectedItem = getDeepValue(item, path);
    return {
      [searchKey]: {
        selected: [selectedItem],
        data: {
          type: FieldTypes.STRING
        }
      }
    }
  }, [item, searchKey])


  function renderButtons() {
    return (
      <div className={classes.buttonContainer}>
        <div container className={classes.gridContainer}>
          {/* <div className={classes.gridItem}>
            <CustomButton label={action === SearchModalActions.DELETE ? 'Delete' : 'Next'} onClick={nextAction} color='secondary' fullWidth variant='contained' disabled={disabledNext} />
          </div> */}
          <div className={classes.gridItem}>
            <CustomButton label='Cancel' onClick={close} color='secondary' fullWidth />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.searchRoot}>
      <div className={classes.searchContainer}>
        <SearchFlexible
          searchType={searchType}
          isModal
          disableActions={!actions}
          searchActionCompartments={actions && actions.split(',')}
          parentFilter={parentFilter}
          searchParams={params.moreParams}
          fieldMap={fieldMap}
        />
      </div>
      {renderButtons()}
    </div>
  );
}

export default React.memo(SearchFor);
