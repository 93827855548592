import React from 'react';
import PropTypes from 'prop-types';
import { getUpdatedEditorState } from '../../Utils/editor/editor-utils';

export default function ResetToDefaultOption(props) {
  const buttonStyle = {
    height: '32px',
    margin: '0px 4px'
  }
  const reset = () => {
    const { onChange, contentType } = props;
    onChange(getUpdatedEditorState(props.default, contentType));
  };

  return (
    <button style={buttonStyle} onClick={reset} type="button">Reset to Default</button>
  );
}
ResetToDefaultOption.propTypes = {
  onChange: PropTypes.func.isRequired,
  default: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired
};
