import { TemplateSpecialGroupType } from '../../constants/Constants';
import { TemplateErrors } from '../../constants/Errors';
import isRequiredValueMissing from './isRequiredValueMissing';

// updates group's required missing, setting state and returning the error for the field
export default function updateGroupRequiredMissing(newState, { field, group, currentGroupIndex, value, error, key, resetRequired }) {
  const { name: fieldName, type: fieldType, shortDesc: fieldDesc } = field.metadata;
  const { name: groupName, type: groupType } = group.metadata;
  const { groupIndex } = group;
  const { required } = field;
  const notValue = key && key !== 'value';
  let newError = error ? error : null;
  if ((required && !notValue) || resetRequired) {
    const requiredMissing = TemplateSpecialGroupType.includes(groupType)
      ? newState[groupName].requiredMissing[groupIndex || 0 || currentGroupIndex]
      : newState[groupName].requiredMissing;
    if(requiredMissing) {
      const indexOf = requiredMissing.findIndex(fieldKey => fieldKey === fieldName);
      // check if requiredValue is present
      if (!isRequiredValueMissing(field, value) || resetRequired) {
        // if has value, remove it
        requiredMissing.splice(indexOf, indexOf > -1 ? 1 : 0);
        if (resetRequired && field.error === TemplateErrors.MISSING_INPUT(fieldDesc)) {
          newError = null;
        }
      } else {
        if (indexOf < 0) {
          // if no value and not found, add it
          newError = TemplateErrors.MISSING_INPUT(fieldDesc);
          requiredMissing.push(fieldName);
        } else {
          // if found keep old error
          newError = field.error;
        }
      }
    }
  }
  return newError
}
