import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { ContentTypes } from '../../constants/Constants';
import { checkNonZeroArray } from '../helpers';

export const getStateFromHTML = (htmlContent) => {
  const blocksFromHtml = htmlToDraft(htmlContent);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return EditorState.createWithContent(contentState);
}

export const convertContentToHTML = (editorState) => {
  const rawContentState = convertToRaw(editorState.getCurrentContent())
  const html = draftToHtml(
    rawContentState
  );
  return html;
}

// given <p>Instant Savings</p>
// return Instant Savings
export const trimParagraphTags = (html) => {
  const lines = html.split('\n').filter(line => !!line);

  const linesAdjusted = lines.map((line) => {
    line = line.trim();
    // If wrapped in html tags
    const innerHtmlRegex = /^<(p|div|span)>(?<innerHTML>.*)<\/(p|div|span)>$/;
    const innerHtmlMatch = line.match(innerHtmlRegex);
    if (innerHtmlMatch && innerHtmlMatch.groups) {
      return innerHtmlMatch.groups.innerHTML
    }

    return line;
  })

  return linesAdjusted.join('<br>')
}

export const convertContentToText = (editorState) => {
  return editorState.getCurrentContent().getPlainText('\u0001')
}

export const getUpdatedEditorState = (value, contentType, defaultValue) => {
  let updatedEditorState;
  if (!value) {
    return defaultValue
  }
  if (value && contentType === ContentTypes.HTML) {
    updatedEditorState = getStateFromHTML(value);
  } else if (value && contentType === ContentTypes.TEXT) {
    updatedEditorState = EditorState.createWithText(value)
  }
  return updatedEditorState;
}
export const onEditorStateChange = (newEditorState, setEnableSave, enableSave, { required }) => {
  const { blocks } = convertToRaw(newEditorState.getCurrentContent());
  if(required && checkNonZeroArray(blocks) && !blocks[0].text.trim()) {
    setEnableSave(false);
  } else if(!enableSave) {
    setEnableSave(true);
  }
}



