import { makeStyles } from '@material-ui/core'

const styles = {
  field: {
    width: '100%'
  },
  mainCatItem: {
    '&.MuiListItem-gutters': {
      padding: '4px 16px'
    }
  },
  shortDesc: {
    fontWeight: 'bold'
  },
  rangeContainer: {
    alignItems: 'center'
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterContainer: {
    paddingLeft: 8,
    paddingRight: 8
  }
}

export default makeStyles(styles);
