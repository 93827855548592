import React, { useCallback, useState } from "react";
import { withStyles } from "@material-ui/core";
import RadioInputDiff from '../../diff/inputs/RadioInputDiff';
import TemplateModal from './TemplateModal';
import styles from "./Template.styles";

const TemplateRadio = props => {
  const { classes, field, dispatch, group, dataState, setTemplateModalData, workflowItem } = props;
  const {
    longDesc,
    name,
    mode,
    data,
    required,
    shortDesc,
    readOnly,
    value,
    error,
    onChange,
    metadata,
    parentValue,
    values,
    initDisable,
    queries
  } = field;

  const [showModal, setShowModal] = useState(false);

  const onChangeRadio = useCallback((...args) => {
    const [,value] = args;
    const parentSelectedValue = queries && queries.parentSelectedValue
    setShowModal(value === parentSelectedValue)
    onChange(...args)
  }, [onChange])

  const isViewOnly = (mode === "viewOnly");
  const isDisabled = readOnly || isViewOnly || initDisable;

  const modalProps = {
    btnName: isViewOnly ? 'View..' : 'Edit..',
    rootType: queries && queries.rootType,
    templateId: queries && queries.templateId,
    templateGroupType: queries && queries.templateGroupType,
    modalVariant: queries && queries.modalVariant,
    hasMultipleGroups: queries && queries.hasMultipleGroups,
    parentData: {parentTemplateType: queries && queries.parentTemplateType, childDefaultValues: queries && queries.childDefaultValues, parentTemplateData:dataState && dataState}
  }

  return (
    <div className={classes.root}>
      <RadioInputDiff
        id={name}
        className={classes.root}
        margin='normal'
        label={shortDesc}
        placeholder={longDesc}
        value={value}
        metadata={metadata}
        onChange={onChangeRadio}
        condition={name}
        mode={mode}
        data={data}
        required={required}
        disabled={isDisabled}
        error={error}
        parentValue={parentValue}
        viewOnlyValue={values ? values[value] : value}
      />
      {
        queries && queries.childTemplate && queries.parentSelectedValue === value
          ? (
            <TemplateModal
              modalProps={modalProps}
              classes={classes}
              dispatch={dispatch}
              field={field}
              group={group}
              dataState={dataState}
              setTemplateModalData={setTemplateModalData}
              isViewOnly={isViewOnly}
              workflowItem={workflowItem}
              showModal={showModal}
            />
          )
          : null
      }
    </div>
  );
};

export default withStyles(styles)(TemplateRadio);
