import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function exportOfferServiceById(body, user) {
  try {
    const userId = user.id;
    const response = await fetch(`${baseURL}exportoffers?user=${userId}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });

    return response;
  } catch (error) {
    return error;
  }
}


export default exportOfferServiceById;
