import { makeStyles } from '@material-ui/core'
import Colors from '../../theme/Colors';

const styles = {
  root: {

  },
  menuItem: {

  },
  menuItemSelected: {
    backgroundColor: Colors.subtleBlue,
    color: Colors.primaryBlue
  }
}

export default makeStyles(styles);
