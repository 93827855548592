import { BlobProcessStatus, BlobTypes, Roles, dateConversionFormat, low } from '../../constants/Constants';
import dateFormat from '../date/dateFormat';


export const functions = {
  concat: (...fields) => {
    return fields.join(', ')
  },

  concatMap: (fields, property) => {
    return fields.map(field => field[property]).join(', ')
  },
  disableGroup: ([templateId, ...disableGroup]) => {
    const templates = disableGroup;
    // return templates.indexOf(templateId[0]) != -1
    return templates.indexOf(templateId) != -1
  },
  whitelist: ([property, ...list]) => {
    const mapVal = val => {
      if (val === 'true') {
        return true;
      } else if (val === 'false') {
        return false;
      } else if (val === 'null') {
        return null;
      } else if (val === 'undefined') {
        return undefined;
      }

      return val;
    }

    list = list.map(mapVal);
    return !list.includes(mapVal(property));
  },
  canEditBiglist: ([status, type]) => {
    return !([BlobProcessStatus.COMPLETED].map(low).includes(low(status))
      && [BlobTypes.CLUB, BlobTypes.CLUB_OVERRIDE, BlobTypes.MEMBER, BlobTypes.ITEM].map(low).includes(low(type)))
  },
  formatDate: (dateInput, timeZone = "CST") => {
    const formatedDate = dateFormat(dateInput, dateConversionFormat, true);
    if (['INVALID DATE', 'NOT SET'].includes(formatedDate.toUpperCase())) {
      return formatedDate
    } else {
      return functions.concat(formatedDate, timeZone)
    }
  },

  sameOwner: (action, row) => {
    const { role } = action;
    const { owners = [] } = row;
    if ((owners.length > 0 && role.userOrgs && role.userOrgs.length > 0) || owners.length == 0) {
      return true;
    }
    return false;
  },
  hideExportOption: (item) => {
    return !item[0]
  },

  showSimulateForRoles: (action, row) => {
    if (!row.role) {
      return false;
    }
    const { moreParams: { showSimulateForRoles = '' } } = action;
    const { role: { name } } = row;
    const roles = showSimulateForRoles.split(',');
    return roles.filter(roleName => roleName === Roles.UNASSIGNED ? !name || roleName === name : roleName === name).length > 0;
  }
}

export function getFunctionValues(functionsString) {
  if (!functionsString) {
    return null;
  }
  const functionsSplit = functionsString.split('~');
  const functionProps = functionsSplit.map((functionString, index) => {
    const [fieldKey, func] = functionString.replace(')', '').split('=');
    const [funcName, params] = func.split('(');
    const paramsArr = params ? params.split(',').map(string => string.trim()) : [];
    const searchInProp = paramsArr.pop()
    const [fieldName, fieldProperty] = fieldKey.split('/');

    return {
      fieldKey,
      index,
      funcName: funcName.replace('$', ''),
      params: paramsArr,
      searchInProp,
      fieldProperty
    };
  })
  return functionProps;
}

export const extractFunctions = (condition, props) => {
  const [func, params] = condition.replace(')', '').split('(');
  const paramsArr = params ? params.split(',').map(string => string.trim()) : [];
  const args = paramsArr.map((item) => props && props.hasOwnProperty(item) ? props[item] : item);
  return func ? functions[func](args) : null;
}
