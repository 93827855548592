import { FieldTypes } from '../../constants/Constants';
import getDeepValue from "../getDeepValue";

export default function isRequiredValueMissing(field, value) {
  const fieldType = field?.type || field?.metadata?.type;
  const childTemplate = getDeepValue(field, ['metadata', 'queries', 'childTemplate'])
  const parentSelectedValue = getDeepValue(field, ['metadata', 'queries', 'parentSelectedValue'])
  switch (fieldType) {
    case FieldTypes.NUMBER:
      return value === '0' ? false : !(+value);
    case FieldTypes.MIX:
    case FieldTypes.TABLE:
      return !(value && (value.csv || value.biglist));
    default:
      // Custom IPM required check since custom IPM stores values in a different place
      if (childTemplate && parentSelectedValue && parentSelectedValue === value) {
        const fieldValue = getDeepValue(field, [parentSelectedValue])
        return !fieldValue;
      }
      if (value) {
        if (Array.isArray(value)) { // If array is empty, then there is no value
          return value.length === 0
        } else if (typeof value === 'object') { // If object is empty or only has null values, then there is no value
          const keys = Object.keys(value);
          return keys.length === 0 || keys.every(key => value[key] === null || value[key] === undefined || value[key] === '')
        }
      }
      return !value;
  }
}
