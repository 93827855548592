/*
Search through obj and get all instances of field with sibling fields

when passed a promotion object and item_list
returns [
  {
    "item_list": "980249744,980224203,980224204,980077078",
    "list_type": "csv",
    "item_mpq_limit": "1"
  },
  {
    "item_list": "biglistname",
    "list_type": "biglist",
    "item_mpq_limit": "2"
  }
]
*/

function getAllFieldSiblings(obj, fieldName, result = []) {
  result = result || [];
  Object.keys(obj).forEach((key) => {
    if(key === fieldName) {
      result.push(obj);
    }
    if (typeof obj[key] === 'object') {
      getAllFieldSiblings(obj[key], fieldName, result);
    }
  })

  return result;
}

export default getAllFieldSiblings;
