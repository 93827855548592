import React from 'react';
import { withStyles } from "@material-ui/core";
import styles from "./Template.styles";

const TemplateImagePreview = (props) => {
  const { field, classes } = props;
  const { value, values } = field;

  if (value.imageUrl && value.imageUrl.trim() !== "") {
    return (
      <div className={classes.previewContainer}>
        <img
          src={value.imageUrl}
          alt={value.altText}
          width={values.width}
          height={values.height}
        />
      </div>
    );
  }
  return <></>;
};

export default withStyles(styles)(TemplateImagePreview);
