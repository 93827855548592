import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function submitOffersetsService(campaign) {
  try {
    const campaignNumber = campaign?.values?.campaign_number;
    const response = await fetch(`${baseURL}offerset/campaignid/${campaignNumber}`, {
      method: 'GET',
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });

    return response;
  } catch (error) {
    return error;
  }
}


export default submitOffersetsService;
