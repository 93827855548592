import React, { useState, useMemo, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import isEqual from 'lodash/isEqual';
import TemplateInput from './TemplateInput';
import styles from './TemplateList.styles';
import CustomButton from '../buttons/CustomButton/CustomButton';
import { getFunctionValues } from "../../compartments/templates/templateReducer";
import { functions, parseFunctionParams } from '../../Utils/template/templateFieldFunction';

const TemplateCell = props => {
  const {
    classes,
    field,
    noGrid,
    cellStyle,
    // comments,
    history,
    setOthers,
    dispatch,
    group,
    dataState,
    setTemplateModalData,
    workflowItem
  } = props;

  if(field.queries && field.queries.showBorder && (field.queries.showBorder === true||field.queries.showBorder==="true")){
    cellStyle.border='thin solid lightgray'
    cellStyle.padding='5px'
  }
  const isViewOnly = field && field.mode === "viewOnly";

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const { value, error, groupName } = field;
    let result = [];
    if (field.errorCondition) {
      const functionValuesArray = getFunctionValues(field.errorCondition);
      result = functionValuesArray.map((fieldFunction) => {
        const params = fieldFunction.params
        const funcName = fieldFunction.funcName
        const funcParams = parseFunctionParams({ params, fullData: { ...dataState }, groupName, field });
        return functions[funcName] && functions[funcName](...funcParams);
      }).filter((err) => err)
      /** we filter out the valid error messages and if two messages are present we would show the first one until it resolves */
      if (result[0] && !error) {
        field.onChange({
          target: { value, error: result[0] }
        });
      }

    }

  }, [field.groupName, field.value, field.errorCondition]);
  const repeateable = field && field.queries && field.queries.repeatable;

  // Use cached field unless modified
  const repeatableJSX = useMemo(() => {
    if (repeateable) {
      const fields = [];
      const values = field.value && field.value.split('|') || [''];
      values.forEach((value, index) => {
        const onChange = (event) => {
          if (event && event.target) {
            const value = event.target.value;
            const newValues = [...values];
            newValues[index] = value;

            field.onChange({ target: { value: newValues.join('|') } })
          }
        }

        const repeatedField = { ...field, value, onChange }
        if (index != 0) {
          repeatedField.shortDesc = ''
        }

        fields.push(repeatedField)
      });

      const deleteRepeateableValue = (index) => {
        field.onChange({ target: { value: values.slice(0, index).concat(values.slice(index + 1)).join('|') } })
      }

      const addRepeateableValue = () => {
        field.onChange({ target: { value: field.value + '|' } })
      }


      return (
        <div className={classes.repeatableCellContainer}>
          {fields.map((field, index) => {
            return (
              <div key={index} className={classes.repeatableCell}>
                <span style={{ minWidth: '100%' }}>
                  <TemplateInput
                    field={field}
                    history={history}
                    setOthers={setOthers}
                    dispatch={dispatch}
                    group={group}
                    dataState={dataState}
                    setTemplateModalData={setTemplateModalData}
                    workflowItem={workflowItem}
                  />
                </span>
                {!isViewOnly ? (
                  <IconButton size='small' onClick={() => deleteRepeateableValue(index)} style={{ padding: 4, marginTop: 'auto', marginLeft: 4, visibility: index === 0 ? 'hidden' : null }}>
                    <CloseIcon fontSize='small' color='secondary' />
                  </IconButton>
                ) : null}
              </div>
            )
          })}
          {!isViewOnly ? (
            <CustomButton size='small' color='secondary' label='+ Add' onClick={addRepeateableValue} width={0} style={{ height: 'auto', marginTop: 4 }} />
          ): null}
        </div>
      )
    } else {
      return (
        <TemplateInput
          field={field}
          history={history}
          setOthers={setOthers}
          dispatch={dispatch}
          group={group}
          dataState={dataState}
          setTemplateModalData={setTemplateModalData}
          workflowItem={workflowItem}
        />
      )
    }
  }, [field, setOthers, dispatch, group, dataState, setTemplateModalData, workflowItem])

  const info = field && field.queries && field.queries.info;

  const fieldJSX = useMemo(() => {
    if (info && !isViewOnly) {
      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;
      return (
        <div className={classes.infoCellContainer}>
          <span style={{ minWidth: '100%' }}>
            {repeatableJSX}
          </span>
          <IconButton onClick={handleClick} style={{ padding: 4, marginTop: field.shortDesc ? 14 : 0,  marginBottom: 'auto', marginLeft: 4, }}>
            <InfoIcon color='secondary' />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            classes={{paper: classes.popover}}
          >
            <Typography className={classes.typography}>{info.split('\n').map((info, index) => <div style={{marginTop: index === 0 ? 0 : 16 }} key={info}>{info}</div>)}</Typography>
          </Popover>
        </div>
      );
    } else {
      return repeatableJSX;
    }
  }, [repeatableJSX, info, anchorEl])

  return (
    <div style={cellStyle}>
      <div className={noGrid ? classes.inputContainerNoGrid : classes.inputContainer}>
        <div className={field.type.toLowerCase() === 'search' ? classes.searchContainer : classes.itemContainer}>
          {fieldJSX}
          {/* {comments ? (
            <div className={classes.comments}>
              {comments.map((audit, i) => (
                <Note key={i + ''} title={audit.comment} className={classes.commentNote} spacing={8}>
                  {`by ${audit.createdByUser} on ${dateFormat(audit.createdTS)}`}
                </Note>
              ))}
            </div>
          ) : null} */}
        </div>
      </div>
    </div>
  );
};

export default React.memo(withStyles(styles)(TemplateCell), (prev, next) => {
  return isEqual({ ...prev.field, onChange: null }, { ...next.field, onChange: null });
});
