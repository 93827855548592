import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {
  List,
  Button
} from '@material-ui/core'
import useStyles from './FilterColumn.styles';
import removeKey from '../../Utils/removeKey';
import FilterCategory from './FilterCategory';
import getDeepValue from '../../Utils/getDeepValue';
import getSkeleton from '../../Utils/skeleton/getSkeleton';

/**
* Component to render
* Loader when 'props.loading' is true,
* Advanced Filter options and clear Filter button
* and handles the user selections and calls custom_table onChange event with updated filterParams.
* */
function returnNewValue(newValue, field, optionalFieldName, event, fieldMapData) {
  return optionalFieldName ?
    { ...newValue[field], [optionalFieldName]: event.target.value, data: { ...fieldMapData } }
    :
    {
      ...newValue[field], ...event.target.value, data: { ...fieldMapData }
    }
}
function FilterColumn(props) {
  const { fieldMap = {}, value, onChange, uniqueChoices, height, onClickSearch, filter /*, ignoredFieldKeys = []*/ } = props;
  const classes = useStyles();
  /**
  * @type {array} - contains object of all fields that have advancedFilter: true,
  * and add them in the order as mentioned in afOrder property
  */
  const [filterFields, setFilterFields] = useState([]);
  useEffect(() => {
    /**
    * Find fields that are available for advanced filtering, and sort them by the afOrder property.
    */
    setFilterFields(() => {
      const filterOptions = Object.keys(fieldMap).filter(key => fieldMap[key].advancedFilter);
      const orderedOptions = filterOptions.filter(key => fieldMap[key].afOrder);
      const sortedOptions = orderedOptions.sort((a, b) => fieldMap[a].afOrder - fieldMap[b].afOrder);
      const unorderedOptions = filterOptions.filter(key => !fieldMap[key].afOrder)
      return [...sortedOptions, ...unorderedOptions];
    });
  }, []);
  /**
  * Triggered when a value is selected for DATE type field.
  * @param {object} field - object containing metadata of the field selected
  * @param {string} optionalFieldName - operator selected
  */
  const handleOnChange = (field, optionalFieldName) => (event) => {
    const newValue = { ...value }
    const fieldMapData = fieldMap[field];
    newValue[field] = returnNewValue(newValue, field, optionalFieldName, event, fieldMapData)
    onChange({ target: { value: newValue } }, true);
  }

  /**
   * NOT IN USE RIGHT NOW!
   * @param {*} field
   * @param {*} isText
   * @param {*} optionalFieldName
   */
  const handleOnChangeSearch = (field, isText, optionalFieldName) => (event) => {
    const newValue = { ...value }
    const fieldMapData = field !== 'searchAll' ? fieldMap[field] : {
      siblings: Object.keys(fieldMap),
      type: 'STRING'
    };

    if (optionalFieldName) {
      newValue[field] = { ...newValue[field], [optionalFieldName]: event.target.value, data: { ...fieldMapData } };
    } else if (isText) {
      newValue[field] = { ...newValue[field], search: event.target.value, data: { ...fieldMapData } };
    } else {
      newValue[field] = { ...newValue[field], type: event.target.value, data: { ...fieldMapData } };
    }
    onChange({ target: { value: newValue } });
  }

  /**
  *
  * This function is triggered when user selects the operator which should be applied to selected/entered value.
  * e.g. in Date type of field we have option of After, Before, Range
  * @param {object} field - object containing metadata of the field selected
  * @param {boolean} clear - always true
  * @param {object} event - option selected by user (before, after ,range)
  * Update the value object for this field with the operator selected by user.
  */
  const handleOnChangeOption = (field, clear) => (event) => {
    const newValue = {
      ...value,
      [field.key]: {
        ...(clear ? { data: { type: field.type } } : field),
        option: event.target.value
      }
    };
    onChange({ target: { value: newValue } });
  }
  /**
  * Called when a vale of type STRING is selected(eg stauts, merchant),
  * @param {string} field - field for which selection is done (@example status)
  * @param {array} selected - array of selected values for this field.
  * @param {string} childKey - identifier for child (@example subCategory(used in item catalog subCategory selection))
  * @param {string} choice - value selected, not present when childKey is there.(@example Ended)
  */
  const handleOnChangeSelection = (field, selected, childKey, choice) => {
    const newValue = { ...value };
    if (!childKey && !selected.length && (value[field] && !value[field].search)) {
      onChange({ target: { value: removeKey(value, field) } }, true);
    }
    newValue[field] = childKey ? {
      ...newValue[field],
      data: { ...fieldMap[field] },
      childOptions: {
        [childKey]: {
          data: { ...(getDeepValue(fieldMap, [field, 'childOptions', childKey]) || {}) },
          selected
        }
      }
    } : { ...newValue[field], data: { ...fieldMap[field] }, selected };
    onChange({ target: { value: newValue } }, true, choice);

  }
  /**
  *
  * @param {string} key - field name
  * @param {number} index - index in filteredFields Array.
  * Render FilterCategory component for each field.
  */
  const renderFilterFields = (key, index) => {
    const field = {
      ...fieldMap[key],
      ...value[key],
      key: key,
      choices: uniqueChoices[key]
    }
    if (field.advancedFilter) {
      return (
        <FilterCategory
          key={key}
          field={field}
          choices={field.choices}
          handleOnChange={handleOnChange}
          handleOnChangeSearch={handleOnChangeSearch}
          handleOnChangeSelection={handleOnChangeSelection}
          handleOnChangeOption={handleOnChangeOption}
          showAdvanced
          expandInitial={(filter && filter[key]) || index < 1}
          onClickSearch={onClickSearch}
          value={value}
          disabled={props.searchLoading}
          childOptionsLoading={props.childOptionsLoading}
          switchValue={props.switchValue}
        />
      )
    }
  }
  return (
    <>
      <div className={classes.root} style={{ maxHeight: `calc(${height} - 30px`, height: `calc(${height} - 30px` }}>
        <List>
          {filterFields.map(renderFilterFields)}
          {props.loading ?
            <div style={{ padding: '8px', width: '180%' }}>{getSkeleton(15, 30)}</div> : null
          }
        </List>
      </div>
      <Button
        color='secondary'
        fullWidth
        onClick={props.clearSearch}
        size='small'
        disabled={!Object.keys(value).length || props.searchLoading}
      >
        Clear filter
      </Button>
    </>
  )
}
export default React.memo(FilterColumn);
FilterColumn.propTypes = {
  /**
  * @type {object} Object that contains metadata for all fields of the component
  * @example {
  * "id":{
  * "advancedFilter":false,"filterable":false,"key":true,"name":"id","searchable":false,"sortable":false,"type":"STRING","displayName":"Id","width":"","shortDesc":"Id"
  * },
  * "values.campaign_name":{
  * "advancedFilter":false,"filterable":true,"key":false,"name":"values.campaign_name","display":true,"searchable":true,"sortable":true,"type":"STRING","displayName":"Campaign Internal Name","function":"campaign_name=concat(campaign_name,values)","shortDesc":"Campaign Internal Name"}
  * }
  */
  fieldMap: PropTypes.object,
  /**
  * @type {object} Object that has objects of the fields selected bu yser for filtering.
  * Each key represent the filed selected for filtering and contains selected value for that field and metadata.
  * In example, status("Ended") and start_date("2021-01-18") are selected by user for filtering.
  * @example {"start_date":{
  * "after":"2021-01-18",
  * "data":{"afOrder":1,"advancedFilter":true,"display":false,"filterable":true,"key":false,"name":"start_date","searchable":true,"sortable":true,"type":"DATETIMEOFFSET","displayName":"Campaign Start Date","width":"200","shortDesc":"Campaign Start Date",
  * "values":[{"value":"2021-12-21T00:00:00Z","label":"2021-12-21T00:00:00Z"},{"value":"2021-01-01T00:00:00Z","label":"2021-01-01T00:00:00Z"},{"value":"2020-12-05T00:00:00Z","label":"2020-12-05T00:00:00Z"},{"value":"2020-12-17T00:00:00Z","label":"2020-12-17T00:00:00Z"},{"value":"2021-01-06T00:00:00Z","label":"2021-01-06T00:00:00Z"},{"value":"2020-10-15T18:30:00Z","label":"2020-10-15T18:30:00Z"},{"value":"2020-10-19T18:30:00Z","label":"2020-10-19T18:30:00Z"},{"value":"2020-10-29T00:00:00Z","label":"2020-10-29T00:00:00Z"},{"value":"2020-11-12T00:00:00Z","label":"2020-11-12T00:00:00Z"},{"value":"2021-01-07T00:00:00Z","label":"2021-01-07T00:00:00Z"}]}
  * },
  * "status":{
  * "data":{"advancedFilter":true,"filterable":true,"key":false,"name":"status","display":true,"searchable":true,"sortable":true,"type":"STRING","displayName":"Status","width":"180","shortDesc":"Status","values":[{"value":"Ended","label":"Ended"},{"value":"Publish Failed","label":"Publish Failed"},{"value":"Open to Offers","label":"Open to Offers"},{"value":"Live and Closed to Offers","label":"Live and Closed to Offers"},{"value":"New","label":"New"},{"value":"Live","label":"Live"},{"value":"Closed to Offers","label":"Closed to Offers"},{"value":"Reopened to Offers","label":"Reopened to Offers"},{"value":"Live and Open to Offers","label":"Live and Open to Offers"}]},
  * "selected":["Ended"]}
  * }
  */
  value: PropTypes.object,
  /**
  * @type {function} passed by custom_table, passed with updated values selected by user.
  */
  onChange: PropTypes.func,
  /**
  * @type {object} - it has objects, with uniques values available for each field for user selection except date type fields.
  * Available unique values for Status
  * @example {
  * status: [{"value":"Ended","label":"Ended"},{"value":"Publish Failed","label":"Publish Failed"},{"value":"Open to Offers","label":"Open to Offers"},{"value":"Live and Closed to Offers","label":"Live and Closed to Offers"},{"value":"New","label":"New"},{"value":"Live","label":"Live"},{"value":"Closed to Offers","label":"Closed to Offers"},{"value":"Reopened to Offers","label":"Reopened to Offers"},{"value":"Live and Open to Offers","label":"Live and Open to Offers"}]
  * }
  */
  uniqueChoices: PropTypes.object
}
