import { makeStyles } from '@material-ui/core';
import Colors from '../../../theme/Colors';
import { fontRegular, fontMedium } from '../../../theme/theme';

const styles = {
  root: {
    paddingTop: 0,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 16,
    // paddingTop: 152,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    height: '100%',
    boxSizing: 'border-box',
    width: '100%',
    margin: 'auto'
  },
  buttonContainer: {
    paddingTop: 16,
    width: '100%'
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  },
  gridItem: {
    margin: 8,
    minWidth: 150,
    border: 0
  },
  skeletonContainer: {
    width: '100%',
    marginTop: 16,
    padding: 32,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 'auto',
    boxSizing: 'border-box'
  },
  skeletonWrapper: {
    marginTop: 16,
    padding: 4
  },
  swipeView: {
    height: '100%',
    width: '100%'
  },
  indicator: {
    height: 4
  },
  tabWrapper: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: fontRegular
  },
  tabSelected: {
    '& $tabWrapper': {
      fontWeight: fontMedium
    }
  },
  tabs: {
    width: '100%',
    maxWidth: 1260,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tab: {
    borderBottom: `4px solid ${Colors.grey100}`
  },
  tabsRoot: {}
}

export default makeStyles(styles);
