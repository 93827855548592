import React, { useState,forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import throttle from 'lodash/throttle';
import useStyles from './CustomButton.styles';

function CustomButton(props, ref) {
  const classes = useStyles();
  const {
    label,
    RightIcon,
    LeftIcon,
    endIcon,
    startIcon,
    color = 'primary',
    classes: classesProps,
    className,
    width,
    children,
    iconSize,
    variant,
    style,
    onClick,
    disabled,
    onClickTimeout = 500,
    labelSize,
    ...buttonProps
  } = props;

  function _onClick(event) {
    if (onClick) {
      event.stopPropagation();
      event.preventDefault();
      onClick(event);
    }
  }

  const throttleClick = onClick && onClickTimeout ? throttle(_onClick, onClickTimeout, { trailing: false }) : _onClick;

  const iconClass = clsx({ [classes.iconSizeSmall]: iconSize === 'small', [classes.iconSizeMedium]: iconSize === 'medium', [classes.iconSizeLarge]: iconSize === 'large' })

  if (variant === 'menu') {
    return (
      <MenuItem onClick={throttleClick} disabled={disabled} ref={ref}>
        {LeftIcon ? (
          <ListItemIcon>
            <LeftIcon />
          </ListItemIcon>
        ) : null}
        <ListItemText>
          {label}
        </ListItemText>
      </MenuItem>
    );
  }

  return (
    <Button
      ref={ref}
      className={className}
      color={color}
      variant={variant}
      onClick={throttleClick}
      disabled={disabled}
      startIcon={LeftIcon ? <LeftIcon /> : startIcon}
      endIcon={RightIcon ? <RightIcon /> : endIcon}
      style={{ minWidth: width, ...style }}
      disableElevation
      classes={{
        ...classesProps,
        root: classes.root,
        label: clsx(classes.label, { [classes.labelSmall]: labelSize === 'small' }),
        iconSizeSmall: iconClass,
        iconSizeMedium: iconClass,
        iconSizeLarge: iconClass,
        text: clsx({ [classes.textError]: color === 'error' }),
        outlined: clsx({ [classes.outlinedError]: color === 'error' }),
        contained: clsx(classes.labelContained, { [classes.containedError]: color === 'error' })
      }}
      {...buttonProps}
    >
      {label}
      {children}
    </Button>
  );
}

export default forwardRef(CustomButton);
