function findForUserOrgsInOwnersList(userOrgs = [], owners = []) {
  return owners.findIndex(owner => {
    return userOrgs.find(org => {
      return org.id === owner.id
    })
  })
}

export const isDiffUser = (loggedInUser, stateData) => {
  return (stateData && stateData.owners && stateData.owners.length > 0) ? findForUserOrgsInOwnersList(loggedInUser.userOrgs, stateData.owners) === -1 : false;
}
export const isMerchant = (loggedInUser) => {
  return loggedInUser && loggedInUser.role ? loggedInUser.role.name === "ROLE_MERCHANT" : false;
}
