import React from "react";
import moment from 'moment';
import { withStyles, Button, makeStyles } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { styled } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import styles from "./Template.styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiPaper-root": {
      padding: "5px",
      margin:"20px",
      backgroundColor:"lightgray"
    },
  },
  gridRoot:{
    "&.MuiGrid-root": {
      padding: "1px",
      margin:"1px",
      width:"auto",
      backgroundColor:"white"
    },
  }
}));

const TemplateMessagePreview = (props) => {
  const styleClasses = useStyles();
  const { field } = props;
  const [show, setShow] = React.useState(false);
  const { value, values } = field;

  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: values.width,
    maxHeight: values.height,
  });

  const onClickHandler = () => {
    setShow((prevState) => !prevState);
  };

  const customDateFormat= ('MMM DD, YYYY') ;

  const isEmpty = (str) => {
    return str === null || str === undefined ||str === '' ;
  };
  const allEmpty = isEmpty(value.appText)&&isEmpty(value.htmlText)&&isEmpty(value.ctaUrl)&&isEmpty(value.ctaUrlText)&&isEmpty(value.imageUrl)
  return (
    <>
      <Button variant="contained" color="secondary" disabled={field.readOnly || field.disabled ||allEmpty} onClick={onClickHandler}>
        Preview
      </Button>
      {show === false ? (
        <></>
      ) : (
        <Dialog
          open={show}
          onClose={() => setShow(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle sx={{ m: 2, p: 4 }} id="customized-dialog-title">
            {value.title}
          </DialogTitle>
          <Paper className={styleClasses.root}>
            <Grid
              container
              className={styleClasses.gridRoot}
              spacing={2}
            >
              <Grid item>
                {value.ctaUrl ? (
                  <a
                    href={value.ctaUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {value.imageUrl && (
                      <Img src={value.imageUrl} alt={value.ctaUrlText} width={values.width} height={values.height} />
                    )}
                  </a>
                ) : (
                  value.imageUrl && (
                    <Img src={value.imageUrl} alt={value.ctaUrlText} width={values.width} height={values.height} />
                  )
                )}
              </Grid>
              <Grid item container xs={12} sm>
                <Grid item container direction="column" spacing={2}>
                  <Grid item xs>
                    {!isEmpty(value.htmlText) ?
                      (
                        <div
                          /* eslint-disable-next-line react/no-danger */
                          dangerouslySetInnerHTML={{ __html: value.htmlText }}
                        />
                      ) : (<Typography gutterBottom variant="body2" color="textPrimary">{value.appText}</Typography>)
                    }
                    {!isEmpty(value.offerDetailsHtml) ?
                      (
                        <OfferDetails offerDetails={value.offerDetailsHtml} />
                      ) : (<OfferDetails offerDetails={value.offerDetailsPlain} />)
                    }
                  </Grid>
                  <Grid item xs>
                    {value.endDate !== '' ?
                      (
                        <Typography gutterBottom variant="body2" color="textPrimary">
                          Offer ends
                          {' '}
                          {moment(value.endDate).format(customDateFormat)}
                        </Typography>
                      )
                      : value.title !== "Offer Details" && (<Typography gutterBottom variant="body2" color="textSecondary">End date is not set</Typography>)
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Dialog>
      )}
    </>
  );
};

const OfferDetails = ({offerDetails})=>{
  const styleClasses = useStyles();
  const [show, setShow] = React.useState(false);

  if(offerDetails && offerDetails !==''){
    return (
      <React.Fragment>
        <Typography
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => setShow(true)}
          id="textSeeOffer"
        >
          See Offer Details
        </Typography>
        {show === false ? (
          <></>
        ) : (
          <Dialog
            onClose={() => setShow(false)}
            aria-labelledby="customized-dialog-title"
            open={show}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Terms and Conditions
            </DialogTitle>
            <DialogContent dividers className={styleClasses.root}>
              <Grid container className={styleClasses.gridRoot} spacing={2}>
                <Grid item>
                  <div
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: offerDetails }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
  return <></>
}
export default withStyles(styles)(TemplateMessagePreview);
