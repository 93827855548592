import React, { useState, useEffect } from 'react';
import {
  Typography,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
} from '@material-ui/core';
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined'
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import clsx from 'clsx';
import CustomButton from '../../../widgets/buttons/CustomButton/CustomButton';
import { BlobActions, Status, BlobTypes, validationMessages } from '../../../constants/Constants';
import TextInput from '../../../widgets/inputs/TextInput';
import SelectInput from '../../../widgets/inputs/SelectInput';
import useStyles from './BigListUpload.styles';
import useScreen from '../../../hooks/useScreen';
import useCustomSnackbar from '../../../hooks/useCustomSnackbar';
import charWhitelist from '../../../Utils/biglist/charWhitelist';
import FileUpload from './FileUpload';
import { useFileTracker } from '../../../containers/biglist/FileTracker/FileTracker';
import { baseFolder } from '../../../constants/Endpoints';
import { getFileNameAndExtension } from '../../../Utils/helpers';

/**
 * BigListUpload component handles the uploading of files to a big list.
 * It provides functionalities to edit file names, validate files, and manage the upload process.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {Function} props.close - Function to close the modal.
 * @param {Function} props.setEnableAction - Function to enable or disable actions.
 * @param {string} props.type - The type of the file.
 *
 * @returns {JSX.Element} The rendered component.
 */
function BigListUpload(props) {
  const [editName, setEditName] = useState('');
  const [fileNameError, setFileNameError] = useState({ name: null, message: '' });
  /**
   * @type {array} List of all single member added/removed entries.
   */
  const [closeModalState, setCloseModalState] = useState(false);
  const classes = useStyles();
  const { isMobile, isTablet } = useScreen();
  const { newSnackbar } = useCustomSnackbar();

  const {
    close = () => undefined,
    isInput = false,
    type,
    setEnableAction
  } = props;

  const {
    files,
    containsBiglistName,
    removeFile,
    addFileList,
    uploadFiles: uploadFilesTracker,
    updateFile,
    isInvalidFile,
  } = useFileTracker();

  const readyFiles = files.filter((fileObj) => fileObj.status === Status.READY) || [];

  useEffect(() => {
    if (closeModalState) {
      if (readyFiles.length === 0) {
        close();
        setCloseModalState(false);
      }
    }
  }, [closeModalState, readyFiles, close])

  useEffect(() => {
    /** edit the name of file being upload on esc key*/
    function escFunction(event) {
      if (event.keyCode === 27) {
        setEditName((oldEditName) => {
          if (oldEditName) {
            return ''
          }
        })
      }
    }

    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    }
  });


  /**
   * Handles the edit name button click event.
   *
   * @param {string} [name=''] - The name to be edited.
   * @returns {Function} - An asynchronous function to handle the event.
   */
  const handleEditNameButton = (name = '') => async (event) => {
    event.stopPropagation();
    event.preventDefault();
    await setEditName(name);
    setFileNameError({ name: null, message: '' });
    if (name) {
      const element = document.getElementById('edit-name-' + name)
      if (element) {
        element.focus();
      }
    }
  }

  /**
   * Handles the file change event.
   *
   * @param {FileList} fileChange - The list of files selected by the user.
   * @returns {void}
   */
  async function handleFileChange(fileChange) {
    if (isInput) {
      const [fileNameForUpload] = getFileNameAndExtension(fileChange[0].name);
      const errorMsg = await isInvalidFile(fileNameForUpload);
      if (errorMsg) {
        setFileNameError({ name: fileNameForUpload, mesage: errorMsg });
        newSnackbar({
          title: `${errorMsg}`,
          variant: 'error'
        })
        if (setEnableAction) {
          setEnableAction(false);
        }
        return;
      }
      if (setEnableAction) {
        setEnableAction(true);
      }
      addFileList([fileChange[0]], { type, lockedType: true });
      return;
    }

    addFileList(fileChange);
  }


  /**
   * Validates the given file object based on specific criteria.
   *
   * @param {Object} fileObj - The file object to validate.
   * @param {string} fileObj.name - The name of the file.
   * @param {string} [fileObj.type] - The MIME type of the file.
   * @returns {Promise<boolean>} - Returns a promise that resolves to true if the file is valid, otherwise false.
   */
  async function validateFile(fileObj) {
    const displayName = fileObj.name && fileObj.name.split('.')[0];
    if (fileObj.name.includes('_')) {
      newSnackbar({
        title: `${validationMessages.BULK_UPLOAD_ERROR} : ${displayName}.`,
        variant: 'error'
      })
      return false;
    }
    if (await containsBiglistName(fileObj.name)) {
      setFileNameError({ name: fileObj.name, message: `${validationMessages.UPLOAD_DUPLICATE_ERROR}` });
      newSnackbar({
        title: `${validationMessages.UPLOAD_DUPLICATE_ERROR} : ${displayName}.`,
        variant: 'error'
      })
      return false;
    }
    if (!fileObj.type) {

      newSnackbar({
        title: `Missing type: ${displayName}.`,
        variant: 'error'
      })
      return false;
    }
    return true;
  }


  /**
   * Asynchronously uploads files after validating them.
   *
   * This function checks for file name errors and validates each file in the
   * readyFiles array. If all files are valid, it proceeds to upload them and
   * then closes the modal.
   *
   * @async
   * @function uploadFiles
   * @returns {Promise<null|void>} Returns null if there is a file name error,
   *                              otherwise returns a promise that resolves when
   *                              the files are uploaded and the modal is closed.
   */
  async function uploadFiles() {
    /**
     * For new files to be uploaded
    */
    if (fileNameError.name) {
      return null;
    }
    for (const fileObj of readyFiles) {
      console.log(fileObj)
      if (!(await validateFile(fileObj))) {
        console.log('invalid file')
        return;
      }
    }
    await uploadFilesTracker(baseFolder, BlobActions.NEW);

    closeModal();
  }

  /**
   * Update the type of fileObj, in Import Tab.
   * @param {object} fileObj - object whose type will be changed
   * @param {string} selectedType - Item/Member/Club
   */
  function changeType(fileObj, selectedType) {
    const newFileObj = { ...fileObj, type: selectedType }
    updateFile(newFileObj)
  }

  /**
   * Function to set the updated name of biglist in file object.
   * @param {object} fileObj
   * @param {string} name
   */
  function changeName(fileObj, name) {
    if (name) {
      const finalName = charWhitelist(name, fileObj.file?.name || '');
      const newFileObj = { ...fileObj, name: finalName }
      updateFile(newFileObj, fileObj.name)
    }
    setEditName('');
  }


  /**
   * Closes the modal by setting the close modal state to true.
   */
  function closeModal() {
    setCloseModalState(true);
  }

  /**
   * Renders the upload container component.
   *
   * @function uploadContainer
   * @returns {JSX.Element} The JSX element representing the upload container.
   */
  function uploadContainer() {
    const mobileView = isMobile || isTablet;
    return (
      <div data-testid='fileUpload' className={classes.uploadContainer}>
        <FileUpload mobileView={mobileView} onChange={handleFileChange} />
      </div>
    );
  }

  /**
   * Renders a container with a list of files, allowing users to edit file names, change file types, and delete files.
   *
   * @returns {JSX.Element} The file list container component.
   */
  function fileListContainer() {
    return (
      <div className={classes.fileListContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableSticky} variant='head'><Typography>File Name</Typography></TableCell>
              <TableCell className={classes.tableSticky} variant='head' align='right' />
            </TableRow>
          </TableHead>
          <TableBody>
            {readyFiles.map((fileObj) => {
              const name = fileObj.name;
              const period = name.lastIndexOf('.');
              const displayName = name.slice(0, period);
              return (
                <TableRow key={fileObj.name}>
                  <TableCell className={classes.entryCell} colSpan={2}>
                    <div style={{ backgroundColor: 'white' }} className={classes.expansionSummaryContainer}>
                      {/**
                       * Controls to edit the file name.
                       */}
                      {name === editName ? (
                        <TextInput
                          id={'edit-name-' + name}
                          className={classes.editField}
                          placeholder={displayName}
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                          onClick={(event) => {
                            event.stopPropagation()
                          }}
                          onBlur={(event) => {
                            changeName(fileObj, event.target.value);
                          }}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              changeName(fileObj, event.target.value);
                            }
                          }}
                        />
                      ) : (
                        <>
                          <InsertDriveFileOutlined className={classes.fileIcon} />
                          <Tooltip title={displayName} arrow>
                            <Typography noWrap color={fileNameError.name === name ? 'error' : undefined} variant='h3' className={classes.fileName}>{displayName}</Typography>
                          </Tooltip>
                          <IconButton data-testid='edit' className={classes.editButton} onClick={handleEditNameButton(name)}>
                            <Edit className={classes.editIcon} />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </TableCell>
                  {/**
                   * Change selected file type
                   */}
                  <TableCell className={classes.entryCell} align='right'>
                    {type ? null : (
                      <SelectInput
                        onChange={(event) => changeType(fileObj, event.target.value)}
                        value={fileObj.type}
                        options={[
                          { label: 'Club', value: BlobTypes.CLUB },
                          { label: 'Member', value: BlobTypes.MEMBER }
                        ]}
                        disabled={fileObj.lockedType}
                        placeholder='Type'
                        className={classes.selectInput}
                      />
                    )}
                    <IconButton
                      data-testid="delete"
                      onClick={() => {
                        if (fileObj.name === fileNameError.name) {
                          setFileNameError({ name: null, message: '' })
                        }
                        removeFile(fileObj);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }


  /**
   * Renders a container with upload and cancel buttons.
   *
   * @returns {JSX.Element} The button container component.
   */
  function buttonContainer() {
    return (
      <div className={classes.buttonContainer}>
        <div className={classes.gridContainer}>
          <div className={clsx(classes.submitButtonContainer, classes.gridItem)}>
            <CustomButton disabled={!readyFiles || readyFiles.length === 0} fullWidth variant='contained' label='Upload' onClick={uploadFiles} color='secondary' />
          </div>
          {props.close ? <div className={classes.gridItem}><CustomButton label='Cancel' onClick={close} color='secondary' fullWidth /></div> : null}
        </div>
      </div>
    )
  }

  return (
    <div
      className={classes.root}
    >
      {fileListContainer()}
      {readyFiles.length > 0 ? null : uploadContainer()}
      {isInput ? null : buttonContainer()}
    </div>

  )
}

export default React.memo(BigListUpload);
